import { Input } from 'components/Input/Input';
import { Table, TBody, Td, THead, Tr } from 'components/Table/Transparent';
import { TileTitle } from 'components/Titles/TileTitle';
import keys from 'i18n/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';
import { KosztMldPerGW, O_M_paliwoType, O_M_utrzymanieType } from 'services/slices/obliczeniaSlice/koszty/types';
import { setKosztyMldPerGW, setO_M_paliwo, setO_M_utrzymanie } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { getKoszty } from 'services/slices/obliczeniaSlice/selectors';
import { roundNumb } from 'services/utils/roundNumb';

export const EnergySourcesTable = () => {
  const {
    calculations: { wiatr, wiatrNaMorzu, fotowoltaika, atom: atomObliczenia, gazZiemny },
    koszt_mld_per_GW: { wiatr_na_ladzie, wiatr_na_morzu, PV, atom, elektrownie_gazowe },
    O_M_paliwo,
    O_M_utrzymanie,
  } = useAppSelector(getKoszty);
  const dispatch = useAppDispatch();
  const handleCostChange = (name: keyof KosztMldPerGW) => (val: string) => {
    dispatch(setKosztyMldPerGW({ name, value: Math.max(0, +val) }));
  };
  const handlePaliwoChange = (name: keyof O_M_paliwoType) => (val: string) => {
    dispatch(setO_M_paliwo({ name, value: Math.max(0, +val) }));
  };

  const handleUtrzymanieChange = (name: keyof O_M_utrzymanieType) => (val: string) => {
    dispatch(setO_M_utrzymanie({ name, value: Math.max(0, +val) }));
  };

  const { t } = useTranslation();

  return (
    <Table>
      <THead>
        <Tr cols={6}>
          <Td>
            <TileTitle variant="small">{t(keys.ELECTRIC_ENERGY_SOURCE)}</TileTitle>
          </Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.GW) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.MLD_Z_GW) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.O_M_MAINATANCE) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.O_M_FUEL) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.SUM_MLD_ZL) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.LCOE_UNIT) }}></Td>
        </Tr>
      </THead>
      <TBody>
        <Tr cols={6}>
          <Td>{t(keys.ONSHORE_WIND_COSTS_LABEL)}</Td>
          <Td>{wiatr.nowa_moc_zainstalowana}</Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleCostChange('wiatr_na_ladzie')}
              step="0.1"
              value={wiatr_na_ladzie}
            />
          </Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleUtrzymanieChange('wiatr_na_ladzie')}
              step="0.1"
              value={O_M_utrzymanie.wiatr_na_ladzie}
            />
          </Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handlePaliwoChange('wiatr_na_ladzie')}
              step="0.1"
              value={O_M_paliwo.wiatr_na_ladzie}
            />
          </Td>
          <Td>{roundNumb(wiatr.koszt)}</Td>
          <Td>{roundNumb(wiatr.jednostkoweLCOE)}</Td>
        </Tr>
        <Tr cols={6}>
          <Td>{t(keys.OFFSHORE_WIND_COSTS_LABEL)}</Td>
          <Td>{roundNumb(wiatrNaMorzu.nowa_moc_zainstalowana)}</Td>
          <Td>
            <Input hiddenSpinners withInputMask onChange={handleCostChange('wiatr_na_morzu')} value={wiatr_na_morzu} />
          </Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleUtrzymanieChange('wiatr_na_morzu')}
              step="0.1"
              value={O_M_utrzymanie.wiatr_na_morzu}
            />
          </Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handlePaliwoChange('wiatr_na_morzu')}
              step="0.1"
              value={O_M_paliwo.wiatr_na_morzu}
            />
          </Td>
          <Td>{roundNumb(wiatrNaMorzu.koszt)}</Td>
          <Td>{roundNumb(wiatrNaMorzu.jednostkoweLCOE)}</Td>
        </Tr>
        <Tr cols={6}>
          <Td>{t(keys.PHOTOVOLTAICS)}</Td>
          <Td>{roundNumb(fotowoltaika.nowa_moc_zainstalowana)}</Td>
          <Td>
            <Input hiddenSpinners withInputMask onChange={handleCostChange('PV')} value={PV} />
          </Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleUtrzymanieChange('PV')}
              step="0.1"
              value={O_M_utrzymanie.PV}
            />
          </Td>
          <Td>
            <Input hiddenSpinners withInputMask onChange={handlePaliwoChange('PV')} step="0.1" value={O_M_paliwo.PV} />
          </Td>
          <Td>{roundNumb(fotowoltaika.koszt)}</Td>
          <Td>{roundNumb(fotowoltaika.jednostkoweLCOE)}</Td>
        </Tr>
        <Tr cols={6}>
          <Td>{t(keys.ATOMIC)}</Td>
          <Td>{roundNumb(atomObliczenia.nowa_moc_zainstalowana)}</Td>
          <Td>
            <Input hiddenSpinners withInputMask onChange={handleCostChange('atom')} value={atom} />
          </Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleUtrzymanieChange('atom')}
              step="0.1"
              value={O_M_utrzymanie.atom}
            />
          </Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handlePaliwoChange('atom')}
              step="0.1"
              value={O_M_paliwo.atom}
            />
          </Td>
          <Td>{roundNumb(atomObliczenia.koszt)}</Td>
          <Td>{roundNumb(atomObliczenia.jednostkoweLCOE)}</Td>
        </Tr>
        <Tr cols={6}>
          <Td>{t(keys.NATURAL_GAS)}</Td>
          <Td>{roundNumb(gazZiemny.nowa_moc_zainstalowana)}</Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleCostChange('elektrownie_gazowe')}
              value={elektrownie_gazowe}
            />
          </Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleUtrzymanieChange('elektrownie_gazowe')}
              step="0.1"
              value={O_M_utrzymanie.elektrownie_gazowe}
            />
          </Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handlePaliwoChange('elektrownie_gazowe')}
              step="0.1"
              value={O_M_paliwo.elektrownie_gazowe}
            />
          </Td>
          <Td>{roundNumb(gazZiemny.koszt)}</Td>
          <Td>{roundNumb(gazZiemny.jednostkoweLCOE)}</Td>
        </Tr>
      </TBody>
    </Table>
  );
};
