export const keys = {
  MOBILE_NOT_SUPPORTED: 'MOBILE_NOT_SUPPORTED',
  MAIN_PAGE_DOWNLOAD_EXCEL_LINK: 'MAIN_PAGE_DOWNLOAD_EXCEL_LINK',
  MAIN_PAGE_DOWNLOAD_EXCEL: 'MAIN_PAGE_DOWNLOAD_EXCEL',
  NATURAL_GAS_EFFICENCY_TOOLTIP: 'NATURAL_GAS_EFFICENCY_TOOLTIP',
  MT: 'MT',
  MLD_Z_GWTH: 'MLD_Z_GWTH',
  INTRO_STEP_TUTORIAL: 'INTRO_STEP_TUTORIAL',
  INTRO_STEP_TUTORIAL_title: 'INTRO_STEP_TUTORIAL_title',
  PPL_CONNECTED_TO_GRID_TOOLTIP: 'PPL_CONNECTED_TO_GRID_TOOLTIP',
  PPL_NOT_CONNECTED_TO_GRID_WITH_HEAT_STORAGE_TOOLTIP: 'PPL_NOT_CONNECTED_TO_GRID_WITH_HEAT_STORAGE_TOOLTIP',
  ODBIORCY_CIEPLA_BEZ_MAGAZYNOW_ADVANCED_TOOLTIP: 'ODBIORCY_CIEPLA_BEZ_MAGAZYNOW_ADVANCED_TOOLTIP',
  TOOLTIP_ELASTIC_OF_DEMAND_ADVANCED: 'TOOLTIP_ELASTIC_OF_DEMAND_ADVANCED',
  TOOLTIP_STOPIEN_ZASTAPIENIA_NOWYC_BUD: 'TOOLTIP_STOPIEN_ZASTAPIENIA_NOWYC_BUD',
  TOOLTIP_LOSS_IN_TRANSIT_ADVANCED: 'TOOLTIP_LOSS_IN_TRANSIT_ADVANCED',
  TOOLTIP_ONSHORE_WIND_NEW_TURBINES_LABEL_ADVANCED: 'TOOLTIP_ONSHORE_WIND_NEW_TURBINES_LABEL_ADVANCED',
  TOOLTIP_ENERGY_SOURCE_TILE_PV_BIFACJA_ADVANCED: 'TOOLTIP_ENERGY_SOURCE_TILE_PV_BIFACJA_ADVANCED',
  TOOLTIP_HEAT_PUMPS_AIR_TOOLTIP: 'TOOLTIP_HEAT_PUMPS_AIR_TOOLTIP',
  TOOLTIP_HEAT_PUMPS_GROUND_TOOLTIP: 'TOOLTIP_HEAT_PUMPS_GROUND_TOOLTIP',
  TOOLTIP_HEAT_STORAGE_SIMUALTOR: 'TOOLTIP_HEAT_STORAGE_SIMUALTOR',
  TOOLTIP_HEAT_CO_ADVANCED_TOOLTIP: 'TOOLTIP_HEAT_CO_ADVANCED_TOOLTIP',
  TOOLTIP_HEAT_IN_INDUSTRY: 'TOOLTIP_HEAT_IN_INDUSTRY',
  TOOLTIP_ENERGY_STORAGE_SIMUALTOR: 'TOOLTIP_ENERGY_STORAGE_SIMUALTOR',
  TOOLTIP_TRANSPORT_SINGLE_CONTROL: 'TOOLTIP_TRANSPORT_SINGLE_CONTROL',
  TOOLTIP_TRANSPORT_CARS: 'TOOLTIP_TRANSPORT_CARS',
  TOOLTIP_TRANSPORT_PUBLIC_TITLE: 'TOOLTIP_TRANSPORT_PUBLIC_TITLE',
  TOOLTIP_TRANSPORT_GOODS_MODAL: 'TOOLTIP_TRANSPORT_GOODS_MODAL',
  TOOLTIP_TRANSPORT_OTHER_MODAL_TITLE: 'TOOLTIP_TRANSPORT_OTHER_MODAL_TITLE',
  CAPACITY_GWEL: 'CAPACITY_GWEL',
  EXISTING_GWEL: 'EXISTING_GWEL',
  EXISTING_GWTH: 'EXISTING_GWTH',
  CAPACITY_GWTH: 'CAPACITY_GWTH',
  HYDROGEN_DATA_WYST: 'HYDROGEN_DATA_WYST',
  HYDROGEN_DATA_NIE_WYST: 'HYDROGEN_DATA_NIE_WYST',
  EXISTING_GW: 'EXISTING_GW',
  INTRO_STEP_HEAT_BALANCE: 'INTRO_STEP_HEAT_BALANCE',
  HYDROGEN_DATA_BIALNS: 'HYDROGEN_DATA_BIALNS',
  INTRO_STEP_HEAT_BALANCE_title: 'INTRO_STEP_HEAT_BALANCE_title',
  INTRO_STEP_EXPORT: 'INTRO_STEP_EXPORT',
  INTRO_STEP_EXPORT_title: 'INTRO_STEP_EXPORT_title',
  INTRO_STEP_CHOOSE_SCENARIO: 'INTRO_STEP_CHOOSE_SCENARIO',
  INTRO_STEP_CHOOSE_SCENARIO_title: 'INTRO_STEP_CHOOSE_SCENARIO_title',
  INTRO_STEP_ADVANCED_DATA: 'INTRO_STEP_ADVANCED_DATA',
  INTRO_STEP_ADVANCED_DATA_title: 'INTRO_STEP_ADVANCED_DATA_title',
  INTRO_STEP_SAVE_SIMULATION: 'INTRO_STEP_SAVE_SIMULATION',
  INTRO_STEP_SAVE_SIMULATION_title: 'INTRO_STEP_SAVE_SIMULATION_title',
  INTRO_STEP_CHOOSE_YEAR: 'INTRO_STEP_CHOOSE_YEAR',
  INTRO_STEP_CHOOSE_YEAR_title: 'INTRO_STEP_CHOOSE_YEAR_title',
  INTRO_STEP_ELEKTROLYZE: 'INTRO_STEP_ELEKTROLYZE',
  INTRO_STEP_ELEKTROLYZE_title: 'INTRO_STEP_ELEKTROLYZE_title',
  HEAT_PUMPS_AIR_COSTS: 'HEAT_PUMPS_AIR_COSTS',
  ENERGY_STORAGE_SIMUALTOR: 'ENERGY_STORAGE_SIMUALTOR',
  HEAT_STORAGE_SIMUALTOR: 'HEAT_STORAGE_SIMUALTOR',
  HEAT_PUMPS_GROUND_COSTS: 'HEAT_PUMPS_GROUND_COSTS',
  HEAT_PUMPS_IN_HEATING_ADVANCED: 'HEAT_PUMPS_IN_HEATING_ADVANCED',
  PV_BIFACJA_ADVANCED: 'PV_BIFACJA_ADVANCED',
  ONSHORE_WIND_NEW_TURBINES_LABEL_ADVANCED: 'ONSHORE_WIND_NEW_TURBINES_LABEL_ADVANCED',
  CAPACITY_GWH: 'CAPACITY_GWH',
  MLD_Z_GWH: 'MLD_Z_GWH',
  ENERGY_STORAGE_GWH: 'ENERGY_STORAGE_GWH',
  BUDYNKI_Z_ODZYSKIEM_CIEPLA_TOOLTIP: 'BUDYNKI_Z_ODZYSKIEM_CIEPLA_TOOLTIP',
  INSULATION_QUALITY_TOOLTIP_CONTENT: 'INSULATION_QUALITY_TOOLTIP_CONTENT',
  STOPIEN_ZASTAPIENIA_NOWYC_BUD_TOOLTIP: 'STOPIEN_ZASTAPIENIA_NOWYC_BUD_TOOLTIP',
  MODAL_CIEPLO_SAVE_BTN: 'MODAL_CIEPLO_SAVE_BTN',
  MODAL_CIEPLO_CANCEL_BTN: 'MODAL_CIEPLO_CANCEL_BTN',
  MODAL_TRANSPORT_SAVE_BTN: 'MODAL_TRANSPORT_SAVE_BTN',
  MODAL_TRANSPORT_CANCEL_BTN: 'MODAL_TRANSPORT_CANCEL_BTN',
  MODAL_TRANSPORT_RESET_BTN: 'MODAL_TRANSPORT_RESET_BTN',
  CAPACITY_ONLY_MAGAZYN_CIEPLA: 'CAPACITY_ONLY_MAGAZYN_CIEPLA',
  MODAL_SZCZYT_POMP_TOOLTIP: 'MODAL_SZCZYT_POMP_TOOLTIP',
  CAPACITY_ONLY_MAGAZYN_PRZEYMSL_TOOLTIP: 'CAPACITY_ONLY_MAGAZYN_PRZEYMSL_TOOLTIP',
  CAPACITY_ONLY_MAGAZYN_PRZEYMSL: 'CAPACITY_ONLY_MAGAZYN_PRZEYMSL',
  MAGAZYN_PRZEMYSL_ODBIORCY_Z_MAGAZYNAMI_TOOLTIP: 'MAGAZYN_PRZEMYSL_ODBIORCY_Z_MAGAZYNAMI_TOOLTIP',
  CAPACITY_ONLY_MAGAZYN_CIEPLA_TOOLTIP: 'CAPACITY_ONLY_MAGAZYN_CIEPLA_TOOLTIP',
  SAVE_SIMULATION_TOOLTIP: 'SAVE_SIMULATION_TOOLTIP',
  CONSTRUCTION_COST_LINK: 'CONSTRUCTION_COST_LINK',
  PV_BIFACJA: 'PV_BIFACJA',
  ONSHORE_WIND_NEW_TURBINES_LABEL: 'ONSHORE_WIND_NEW_TURBINES_LABEL',
  ODBIORCY_CIEPLA_BEZ_MAGAZYNOW_ADVANCED: 'ODBIORCY_CIEPLA_BEZ_MAGAZYNOW_ADVANCED',
  ODBIORCY_CIEPLA_ADVANCED_TITLE: 'ODBIORCY_CIEPLA_ADVANCED_TITLE',
  ODBIORCY_CIEPLA_ADVANCED_TITLE_TOOLTIP: 'ODBIORCY_CIEPLA_ADVANCED_TITLE_TOOLTIP',
  ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ODB_PODLACZENI_ADVANCED:
    'ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ODB_PODLACZENI_ADVANCED',
  ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ODB_PODLACZENI_ADVANCED_TOOLTIP:
    'ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ODB_PODLACZENI_ADVANCED_TOOLTIP',
  ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED: 'ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED',
  CIEPLO_PRZEMYSLOWE_ODSETEK_CIEPLA_ODDAWANY_ADVANCED: 'CIEPLO_PRZEMYSLOWE_ODSETEK_CIEPLA_ODDAWANY_ADVANCED',
  CIEPLO_PRZEMYSLOWE_ODSETEK_CIEPLA_ODDAWANY_ADVANCED_TOOLTIP:
    'CIEPLO_PRZEMYSLOWE_ODSETEK_CIEPLA_ODDAWANY_ADVANCED_TOOLTIP',
  ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_MAGAZYNY_ADVANCED: 'ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_MAGAZYNY_ADVANCED',
  ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_MAGAZYNY_ADVANCED_TOOLTIP:
    'ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_MAGAZYNY_ADVANCED_TOOLTIP',
  ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED_TITLE: 'ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED_TITLE',
  ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED_TOOLTIP: 'ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED_TOOLTIP',
  ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED_TITLE_TOOLTIP:
    'ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED_TITLE_TOOLTIP',
  FIELDSET_ELECTRICITY_TITLE: 'FIELDSET_ELECTRICITY_TITLE',
  FIELDSET_MAGAZYN_PRZEMYSL_TITLE: 'FIELDSET_MAGAZYN_PRZEMYSL_TITLE',
  FIELDSET_MAGAZYN_CIEPLA_TITLE: 'FIELDSET_MAGAZYN_CIEPLA_TITLE',
  CIEPLO_PRZEMYSLOWE_ODSETEK_ODDAWANY_DOSIECI_ADVANCED: 'CIEPLO_PRZEMYSLOWE_ODSETEK_ODDAWANY_DOSIECI_ADVANCED',
  CIEPLO_PRZEMYSLOWE_ODSETEK_ODDAWANY_DOSIECI_ADVANCED_TOOLTIP:
    'CIEPLO_PRZEMYSLOWE_ODSETEK_ODDAWANY_DOSIECI_ADVANCED_TOOLTIP',
  YEAR_LABEL: 'YEAR_LABEL',
  MAGAZYN_PRZEMYSL_ODBIORCY_Z_MAGAZYNAMI: 'MAGAZYN_PRZEMYSL_ODBIORCY_Z_MAGAZYNAMI',
  MAGAZYN_CIEPLA_ODBIORCY_Z_MAGAZYNAMI: 'MAGAZYN_CIEPLA_ODBIORCY_Z_MAGAZYNAMI',
  MODAL_BATTERRIES: 'MODAL_BATTERRIES',
  MODAL_CAR_BATTERRIES_TOOLTIP: 'MODAL_CAR_BATTERRIES_TOOLTIP',
  MODAL_BATTERRIES_TOOLTIP: 'MODAL_BATTERRIES_TOOLTIP',
  MODAL_SZCZYT_POMP: 'MODAL_SZCZYT_POMP',
  MODAL_CAR_BATTERRIES: 'MODAL_CAR_BATTERRIES',
  CIEPLO_DO_OGRZEWANIA: 'CIEPLO_DO_OGRZEWANIA',
  KWH_PER_100KM: 'KWH_PER_100KM',
  STOPIEN_ZASTAPIENIA_NOWYC_BUD: 'STOPIEN_ZASTAPIENIA_NOWYC_BUD',
  KM_PER_YEAR: 'KM_PER_YEAR',
  TRANSPORT_GOODS_MODAL: 'TRANSPORT_GOODS_MODAL',
  TRANSPORT_OTHER_MODAL_TITLE: 'TRANSPORT_OTHER_MODAL_TITLE',
  TRANSPORT_GOODS_RAIL_SHARE_MODAL: 'TRANSPORT_GOODS_RAIL_SHARE_MODAL',
  TRANSPORT_GOODS_VOLUMEN_MODAL: 'TRANSPORT_GOODS_VOLUMEN_MODAL',
  TRANSPORT_CARS: 'TRANSPORT_CARS',
  TRANSPORT_PUBLIC_TITLE: 'TRANSPORT_PUBLIC_TITLE',
  TRANSPORT_PUBLIC_LABEL: 'TRANSPORT_PUBLIC_LABEL',
  CARS_COUNT: 'CARS_COUNT',
  CARS_AVG_ENERGY_CONSUMTION: 'CARS_AVG_ENERGY_CONSUMTION',
  CARS_AVG_DISTANCE_TRAVEL: 'CARS_AVG_DISTANCE_TRAVEL',
  CIEPLO_W_PRZEMYSLE_ZAPOTRZEBOWANIE: 'CIEPLO_W_PRZEMYSLE_ZAPOTRZEBOWANIE',
  BASIC_REQ_FOR_CENTRAL_HEAT: 'BASIC_REQ_FOR_CENTRAL_HEAT',
  CIEPLO_W_PRZEMYSLE_MAGAZYNY: 'CIEPLO_W_PRZEMYSLE_MAGAZYNY',
  CIEPLO_PRZEMYSLOWE_CHART_LABEL: 'CIEPLO_PRZEMYSLOWE_CHART_LABEL',
  LINE_TOOLTIP_MAGAZYN_CIEPLA_PRZEMYSLOWEGO: 'LINE_TOOLTIP_MAGAZYN_CIEPLA_PRZEMYSLOWEGO',
  CALCULATE_HEAT: 'CALCULATE_HEAT',
  PRODUCTION_COLUMN_TITLE: 'PRODUCTION_COLUMN_TITLE',
  PRODUCTION_COLUMN_TITLE_TOOLTIP: 'PRODUCTION_COLUMN_TITLE_TOOLTIP',
  COSTS_BTN_TOOLTIP: 'COSTS_BTN_TOOLTIP',
  ELEKTROLYZE_BTN_TOOLTIP: 'ELEKTROLYZE_BTN_TOOLTIP',
  HEAT_BALANCE_BTN_TOOLTIP: 'HEAT_BALANCE_BTN_TOOLTIP',
  HEAT_PUMP_SCOP: 'HEAT_PUMP_SCOP',
  EXPORT_PHOTOVOLTAIC_BIF_COL_NAME: 'EXPORT_PHOTOVOLTAIC_BIF_COL_NAME',
  WIATR_LAD_STARE_0: 'WIATR_LAD_STARE_0',
  WIATR_LAD_STARE_10: 'WIATR_LAD_STARE_10',
  WIATR_LAD_STARE_20: 'WIATR_LAD_STARE_20',
  WIATR_LAD_STARE_30: 'WIATR_LAD_STARE_30',
  WIATR_LAD_STARE_40: 'WIATR_LAD_STARE_40',
  WIATR_LAD_STARE_50: 'WIATR_LAD_STARE_50',
  WIATR_LAD_STARE_60: 'WIATR_LAD_STARE_60',
  WIATR_LAD_STARE_70: 'WIATR_LAD_STARE_70',
  WIATR_LAD_STARE_80: 'WIATR_LAD_STARE_80',
  WIATR_LAD_STARE_90: 'WIATR_LAD_STARE_90',
  WIATR_LAD_STARE_100: 'WIATR_LAD_STARE_100',
  GWTH: 'GWTH',
  PRAD: 'PRAD',
  ELECTROLYSIS: 'ELECTROLYSIS',
  SAVE_SIMULATION: 'SAVE_SIMULATION',
  PARAMETERS_SECTION_TITLE_SIMULATION: 'PARAMETERS_SECTION_TITLE_SIMULATION',
  RESULTS_SECTION_TITLE_SIMULATION: 'RESULTS_SECTION_TITLE_SIMULATION',
  TRANSPORT_OTHER: 'TRANSPORT_OTHER',
  TRANSPORT_OTHER_MODAL: 'TRANSPORT_OTHER_MODAL',
  RAILWAY_TRANSPORT_SHARE: 'RAILWAY_TRANSPORT_SHARE',
  FREIGHT_TRANSPORT_VOLUME: 'FREIGHT_TRANSPORT_VOLUME',
  HEAT_PUMPS_GROUND: 'HEAT_PUMPS_GROUND',
  HEAT_BALANCE: 'HEAT_BALANCE',
  HEAT_PUMPS_AIR: 'HEAT_PUMPS_AIR',
  PUBLIC_TRANSPORT: 'PUBLIC_TRANSPORT',
  KWH: 'KWH',
  CARS_ENERGY_USED_AVERAGE: 'CARS_ENERGY_USED_AVERAGE',
  CARS_AVERAGE_DISTANS: 'CARS_AVERAGE_DISTANS',
  MLN: 'MLN',
  WIND_TURBINES: 'WIND_TURBINES',
  LOSS_IN_TRANSIT: 'LOSS_IN_TRANSIT',
  PV_ORIENTATION_100_EAST_WEST: 'PV_ORIENTATION_100_EAST_WEST',
  PV_ORIENTATION_CURRENT: 'PV_ORIENTATION_CURRENT',
  PV_ORIENTATION_50_EAST_WEST: 'PV_ORIENTATION_50_EAST_WEST',
  PV_ORIENTATION_10_EAST_WEST: 'PV_ORIENTATION_10_EAST_WEST',
  PV_ORIENTATION_20_EAST_WEST: 'PV_ORIENTATION_20_EAST_WEST',
  PV_ORIENTATION_30_EAST_WEST: 'PV_ORIENTATION_30_EAST_WEST',
  PV_ORIENTATION_40_EAST_WEST: 'PV_ORIENTATION_40_EAST_WEST',
  PV_ORIENTATION_60_EAST_WEST: 'PV_ORIENTATION_60_EAST_WEST',
  PV_ORIENTATION_70_EAST_WEST: 'PV_ORIENTATION_70_EAST_WEST',
  PV_ORIENTATION_80_EAST_WEST: 'PV_ORIENTATION_80_EAST_WEST',
  PV_ORIENTATION_90_EAST_WEST: 'PV_ORIENTATION_90_EAST_WEST',
  CIEPLO_OTOCZENIA_CHART_LABEL: 'CIEPLO_OTOCZENIA_CHART_LABEL',
  LINE_TOOLTIP_CHARGING_ENERGY_STORAGE: 'LINE_TOOLTIP_CHARGING_ENERGY_STORAGE',
  LINE_TOOLTIP_CHARGING_HEAT_STORAGE: 'LINE_TOOLTIP_CHARGING_HEAT_STORAGE',
  LINE_TOOLTIP_CIEPLO_PRZEMYSLOWE: 'LINE_TOOLTIP_CIEPLO_PRZEMYSLOWE',
  LINE_TOOLTIP_CIEPLO_PRZEMYSLOWE_LADOWANIE: 'LINE_TOOLTIP_CIEPLO_PRZEMYSLOWE_LADOWANIE',
  LINE_TOOLTIP_CIEPLO_PRZEMYSLOWE_Z_MAGAZYNU: 'LINE_TOOLTIP_CIEPLO_PRZEMYSLOWE_Z_MAGAZYNU',
  LINE_TOOLTIP_ZAPOTRZ_Z_WODOREM: 'LINE_TOOLTIP_ZAPOTRZ_Z_WODOREM',
  TERMOMODERNIZACJA_NIE_UWZGLEDNIAMY: 'TERMOMODERNIZACJA_NIE_UWZGLEDNIAMY',
  TERMOMODERNIZACJA_BEZ_ZMIAN: 'TERMOMODERNIZACJA_BEZ_ZMIAN',
  TERMOMODERNIZACJA_PLYTKA: 'TERMOMODERNIZACJA_PLYTKA',
  TERMOMODERNIZACJA_GLEBOKA: 'TERMOMODERNIZACJA_GLEBOKA',
  CHANGE_YEAR_DESC_TOOLTIP: 'CHANGE_YEAR_DESC_TOOLTIP',
  LINE_TOOLTIP_DEMAND_HYDROGEN: 'LINE_TOOLTIP_DEMAND_HYDROGEN',
  MAIN_PAGE_GENESIS: 'MAIN_PAGE_GENESIS',
  VIDEO_MAIN_PAGE_FIRST: 'VIDEO_MAIN_PAGE_FIRST',
  VIDEO_ABOUT_DATA: 'VIDEO_ABOUT_DATA',
  VIDEO_MAIN_PAGE_SECOND: 'VIDEO_MAIN_PAGE_SECOND',
  MAIN_PAGE_GENESIS_title: 'MAIN_PAGE_GENESIS_title',
  ABOUT_DATA_DESCRIPTION_TEXT: 'ABOUT_DATA_DESCRIPTION_TEXT',
  ABOUT_DATA_TOP_TEXT_title: 'ABOUT_DATA_TOP_TEXT_title',
  ABOUT_DATA_TOP_TEXT: 'ABOUT_DATA_TOP_TEXT',
  INTRODUCTION_DESCRIPTION_TEXT: 'INTRODUCTION_DESCRIPTION_TEXT',
  SIDEBAR_ABOUT_DATA: 'SIDEBAR_ABOUT_DATA',
  SIDEBAR_INTRODUCTION: 'SIDEBAR_INTRODUCTION',
  DISPOSAL_ENERGY_CONSUMPTION: 'DISPOSAL_ENERGY_CONSUMPTION',
  METHANE_USAGE: 'METHANE_USAGE',
  HYDROGEN_USAGE: 'HYDROGEN_USAGE',
  COAL_USAGE: 'COAL_USAGE',
  CURRENT_EMISSIVITY: 'CURRENT_EMISSIVITY',
  DISPOSAL_ALTERNATIVE_SCENARIOS: 'DISPOSAL_ALTERNATIVE_SCENARIOS',
  NEXT: 'NEXT',
  BACK: 'BACK',
  START: 'START',
  INTRODUCTION_TOP_TEXT: 'INTRODUCTION_TOP_TEXT',
  INTRODUCTION_TOP_TEXT_title: 'INTRODUCTION_TOP_TEXT_title',
  EXACT_CALCS: 'EXACT_CALCS',
  EKSPORT: 'EKSPORT',
  BACK_TO_BASIC_DATA: 'BACK_TO_BASIC_DATA',
  REMOVE_FROM_VIEW: 'REMOVE_FROM_VIEW',
  LOOKING_FOR_ADVANCED: 'LOOKING_FOR_ADVANCED',
  ADVANCED_OPTIONS_LINK: 'ADVANCED_OPTIONS_LINK',
  ADD_CHART: 'ADD_CHART',
  EDIT: 'EDIT',
  OWN_DATA: 'OWN_DATA',
  SIDEBAR_HOME: 'SIDEBAR_HOME',
  SIDEBAR_DATA: 'SIDEBAR_DATA',
  SIDEBAR_COSTS: 'SIDEBAR_COSTS',
  SIDEBAR_CONTACT: 'SIDEBAR_CONTACT',
  SIDEBAR_INFORMATION: 'SIDEBAR_INFORMATION',
  SIDEBAR_ABOUT: 'SIDEBAR_ABOUT',
  SIDEBAR_INTRO: 'SIDEBAR_INTRO',
  INTRO_STEP_START: 'INTRO_STEP_START',
  INTRO_STEP_START_title: 'INTRO_STEP_START_title',
  INTRO_STEP_ENERGY_SOURCES: 'INTRO_STEP_ENERGY_SOURCES',
  INTRO_STEP_ENERGY_SOURCES_title: 'INTRO_STEP_ENERGY_SOURCES_title',
  INTRO_STEP_DEMAND: 'INTRO_STEP_DEMAND',
  INTRO_STEP_DEMAND_title: 'INTRO_STEP_DEMAND_title',
  INTRO_STEP_STORAGE: 'INTRO_STEP_STORAGE',
  INTRO_STEP_STORAGE_title: 'INTRO_STEP_STORAGE_title',
  INTRO_STEP_HYDROGEN: 'INTRO_STEP_HYDROGEN',
  INTRO_STEP_HYDROGEN_title: 'INTRO_STEP_HYDROGEN_title',
  INTRO_STEP_DISPOSAL_SOURCES: 'INTRO_STEP_DISPOSAL_SOURCES',
  INTRO_STEP_DISPOSAL_SOURCES_title: 'INTRO_STEP_DISPOSAL_SOURCES_title',
  INTRO_STEP_HYDROGEN_BALANCE: 'INTRO_STEP_HYDROGEN_BALANCE',
  INTRO_STEP_HYDROGEN_BALANCE_title: 'INTRO_STEP_HYDROGEN_BALANCE_title',
  INTRO_STEP_CONSTRUCTION_COSTS: 'INTRO_STEP_CONSTRUCTION_COSTS',
  INTRO_STEP_CONSTRUCTION_COSTS_title: 'INTRO_STEP_CONSTRUCTION_COSTS_title',
  INTRO_STEP_ENERGY_BALANCE: 'INTRO_STEP_ENERGY_BALANCE',
  INTRO_STEP_ENERGY_BALANCE_title: 'INTRO_STEP_ENERGY_BALANCE_title',
  INTRO_STEP_LINE_CHART: 'INTRO_STEP_LINE_CHART',
  INTRO_STEP_LINE_CHART_title: 'INTRO_STEP_LINE_CHART_title',
  INTRO_STEP_EXPORT_DATA: 'INTRO_STEP_EXPORT_DATA',
  INTRO_STEP_EXPORT_DATA_title: 'INTRO_STEP_EXPORT_DATA_title',
  INTRO_STEP_EXPORT_PDF: 'INTRO_STEP_EXPORT_PDF',
  INTRO_STEP_EXPORT_PDF_title: 'INTRO_STEP_EXPORT_PDF_title',
  INTRO_STEP_TIME_BALANCE: 'INTRO_STEP_TIME_BALANCE',
  INTRO_STEP_TIME_BALANCE_title: 'INTRO_STEP_TIME_BALANCE_title',
  SIDEBAR_SIMULATOR: 'SIDEBAR_SIMULATOR',
  NATURAL_GAS_EFFICENCY: 'NATURAL_GAS_EFFICENCY',
  DATA_PAGE_DESC: 'DATA_PAGE_DESC',
  DATA_PAGE_SUBTITLE: 'DATA_PAGE_SUBTITLE',
  O_M_MAINATANCE: 'O_M_MAINATANCE',
  O_M_FUEL: 'O_M_FUEL',
  CLOSE: 'CLOSE',
  PRODUCTION: 'PRODUCTION',
  CONSUMPTION: 'CONSUMPTION',
  BIOMETHANE: 'BIOMETHANE',
  ACCEPT: 'ACCEPT',
  RODO_TITLE: 'RODO_TITLE',
  RODO_DESC: 'RODO_DESC',
  RODO_TEXT: 'RODO_TEXT',
  PRIVACY_POLICY_TEXT: 'PRIVACY_POLICY_TEXT',
  PRIVACY_LINK_TEXT: 'PRIVACY_LINK_TEXT',
  RODO_LINK_TEXT: 'RODO_LINK_TEXT',
  DECLINE: 'DECLINE',
  SORRY_INCONVENIENCE: 'SORRY_INCONVENIENCE',
  PDF_MODAL_TITLE: 'PDF_MODAL_TITLE',
  PDF_MODAL_TEXT: 'PDF_MODAL_TEXT',
  SIMULATION_NAME: 'SIMULATION_NAME',
  DATE_OF_GENERATION: 'DATE_OF_GENERATION',
  PDF_TABLE_ELECTROLYSERS_TITLE: 'PDF_TABLE_ELECTROLYSERS_TITLE',
  PDF_TABLE_HEADER: 'PDF_TABLE_HEADER',
  BOX2_BALANCE_TOOL_TIP: 'BOX2_BALANCE_TOOL_TIP',
  PDF_FILE_NAME: 'PDF_FILE_NAME',
  PDF_PRODUCTION_TEXT2: 'PDF_PRODUCTION_TEXT2',
  PDF_PRODUCTION_TEXT: 'PDF_PRODUCTION_TEXT',
  PDF_PRODUCTION_HEADING: 'PDF_PRODUCTION_HEADING',
  PDF_ENERGY_BALANCE_BOTTOM_TEXT: 'PDF_ENERGY_BALANCE_BOTTOM_TEXT',
  PDF_ENERGY_BALANCE_BOTTOM_TEXT_ITALIC: 'PDF_ENERGY_BALANCE_BOTTOM_TEXT_ITALIC',
  PDF_AMOUNT_OF_BIOMETHANE_SUFFICENT: 'PDF_AMOUNT_OF_BIOMETHANE_SUFFICENT',
  PDF_AMOUNT_OF_BIOMETHANE_NOT_SUFFICENT: 'PDF_AMOUNT_OF_BIOMETHANE_NOT_SUFFICENT',
  PDF_AMOUNT_OF_BIOMETHANE_MEDIUM: 'PDF_AMOUNT_OF_BIOMETHANE_MEDIUM',
  PDF_ENERGY_BALANCE_TOP_TEXT: 'PDF_ENERGY_BALANCE_TOP_TEXT',
  HYDROGEN_NEEDS: 'HYDROGEN_NEEDS',
  YEARS: 'YEARS',
  YEAR: 'YEAR',
  PDF_DATA_HEADING: 'PDF_DATA_HEADING',
  PDF_KEY_RESULTS_HEADING: 'PDF_KEY_RESULTS_HEADING',
  YES: 'YES',
  NO: 'NO',
  XLS_CURRENT_DOWNLOAD_PATH: 'XLS_CURRENT_DOWNLOAD_PATH',
  XLS_HEAT_DOWNLOAD_NAME: 'XLS_HEAT_DOWNLOAD_NAME',
  XLS_ENERGY_DOWNLOAD_NAME: 'XLS_ENERGY_DOWNLOAD_NAME',
  SHOW_BIGGER: 'SHOW_BIGGER',
  SHOW_SMALLER: 'SHOW_SMALLER',
  GWEL: 'GWEL',
  DOCUMENTATION_OVERLAY: 'DOCUMENTATION_OVERLAY',
  ELASTICITY_OPTION_SMALL: 'ELASTICITY_OPTION_SMALL',
  ELASTICITY_OPTION_MEDIUM: 'ELASTICITY_OPTION_MEDIUM',
  ELASTICITY_OPTION_BIG: 'ELASTICITY_OPTION_BIG',
  COAL_CO_EMISSION: 'COAL_CO_EMISSION',
  PIE_CHART_DISPOSAL_SOURCES: 'PIE_CHART_DISPOSAL_SOURCES',
  CO_PRAD_PC: 'CO_PRAD_PC',
  CO_CIEPLO_PC: 'CO_CIEPLO_PC',
  CO_GRZALKI_KOGENERACJA: 'CO_GRZALKI_KOGENERACJA',
  CWU_PRAD_PC: 'CWU_PRAD_PC',
  CWU_CIEPLO_PC: 'CWU_CIEPLO_PC',
  CWU_GRZALKI_KOGENERACJA: 'CWU_GRZALKI_KOGENERACJA',
  PIE_CHART_PROD_CONSUMTION: 'PIE_CHART_PROD_CONSUMTION',
  PIE_CHART_OVERPRODUCTION: 'PIE_CHART_OVERPRODUCTION',
  DYSP_ENERGY_SOURCE_FOR_PLANTS_TOOLTIP: 'DYSP_ENERGY_SOURCE_FOR_PLANTS_TOOLTIP',
  EXPORT_ELECTRICITY_TITLE: 'EXPORT_ELECTRICITY_TITLE',
  EXPORT_HEAT_TOOLTIP: 'EXPORT_HEAT_TOOLTIP',
  EXPORT_HEAT_TITLE: 'EXPORT_HEAT_TITLE',
  EXPORT_ELECTRICITY_TOOLTIP: 'EXPORT_ELECTRICITY_TOOLTIP',
  DEMAND_TILE: 'DEMAND_TILE',
  DEMAND_TILE_TOOLTIP: 'DEMAND_TILE_TOOLTIP',
  MAIN_TEXT8: 'MAIN_TEXT8',
  MAIN_TEXT8_DESC: 'MAIN_TEXT8_DESC',
  INFORMATION_TEXT_1: 'INFORMATION_TEXT_1',
  INFORMATION_TITLE_1: 'INFORMATION_TITLE_1',
  INFORMATION_TITLE_2: 'INFORMATION_TITLE_2',
  INFORMATION_TEXT_2: 'INFORMATION_TEXT_2',
  PAGE_TITLE_DATA: 'PAGE_TITLE_DATA',
  DOWNLOAD_EXCEL_TEXT: 'DOWNLOAD_EXCEL_TEXT',
  DOWNLOAD_PDF_TEXT: 'DOWNLOAD_PDF_TEXT',
  PAGE_TITLE_ABOUT: 'PAGE_TITLE_ABOUT',
  PAGE_TITLE_CONTACT: 'PAGE_TITLE_CONTACT',
  PAGE_TITLE_SIMULATOR: 'PAGE_TITLE_SIMULATOR',
  PAGE_TITLE_COSTS: 'PAGE_TITLE_COSTS',
  PAGE_TITLE_INFORMATION: 'PAGE_TITLE_INFORMATION',
  PAGE_ABOUT_CONTENT: 'PAGE_ABOUT_CONTENT',
  PAGE_ABOUT_CONTENT_title: 'PAGE_ABOUT_CONTENT_title',
  ADD_DATA_FROM_FILE: 'ADD_DATA_FROM_FILE',
  ADD_DATA_FROM_FILE_TEXT: 'ADD_DATA_FROM_FILE_TEXT',
  MESSAGE_BODY_PLACEHOLDER: 'MESSAGE_BODY_PLACEHOLDER',
  YOUR_EMAIL_PLACEHOLDER: 'YOUR_EMAIL_PLACEHOLDER',
  SPECIFIC_INFORMATION_TOOLTIP: 'SPECIFIC_INFORMATION_TOOLTIP',
  ADVANCED_DATA_SETTINGS_BTN_LABEL_TOOLTIP: 'ADVANCED_DATA_SETTINGS_BTN_LABEL_TOOLTIP',
  INCLUDE_COSTS_FROM_CURRENT_INFRASTRUCTURE: 'INCLUDE_COSTS_FROM_CURRENT_INFRASTRUCTURE',
  INFRASTRUCTURE_COST: 'INFRASTRUCTURE_COST',
  MORE_DATA: 'MORE_DATA',
  SUM_MLD_ZL: 'SUM_MLD_ZL',
  COLLAPSE: 'COLLAPSE',
  INC: 'INC',
  DEC: 'DEC',
  CURR_COUNTER: 'CURR_COUNTER',
  OTHER_ELECTRICITY_CONSUMPTION_ADVANCED: 'OTHER_ELECTRICITY_CONSUMPTION_ADVANCED',
  INPUT_TOOLTIP: 'INPUT_TOOLTIP',
  PL: 'PL',
  EN: 'EN',
  SIMULATOR: 'SIMULATOR',
  ENERGETIC: 'ENERGETIC',
  ABOUT_PROJECT: 'ABOUT_PROJECT',
  ACTION_TWO: 'ACTION_TWO',
  OPEN_SIMULATOR: 'OPEN_SIMULATOR',
  PRODUCTION_AND_CONSUPTION_OF_ENERGECITY: 'PRODUCTION_AND_CONSUPTION_OF_ENERGECITY',
  CHOOSE_SCENARIO: 'CHOOSE_SCENARIO',
  CHOOSE_SCENARIO_TITLE: 'CHOOSE_SCENARIO_TITLE',
  SHOW_SCENARIOS: 'SHOW_SCENARIOS',
  DETAILS_DATA: 'DETAILS_DATA',
  EDIT_ADVANCED_DATA: 'EDIT_ADVANCED_DATA',
  OTHER_ELECTRICITY_CONSUMPTION: 'OTHER_ELECTRICITY_CONSUMPTION',
  STORAGES_GWHTH: 'STORAGES_GWHTH',
  STORAGES_GWHTH_TOOLTIP: 'STORAGES_GWHTH_TOOLTIP',
  HEAT_STORAGE: 'HEAT_STORAGE',
  ENERGY_STORAGE_ADVANCED: 'ENERGY_STORAGE_ADVANCED',
  HEAT_STORAGE_GWHTH: 'HEAT_STORAGE_GWHTH',
  HEAT_STORAGE_TITLE: 'HEAT_STORAGE_TITLE',
  HEAT_STORAGE_GWHTH_TOOLTIP: 'HEAT_STORAGE_GWHTH_TOOLTIP',
  HEAT: 'HEAT',
  HEAT_TOOLTIP: 'HEAT_TOOLTIP',
  HYDROGEN: 'HYDROGEN',
  ENERGY_NEEDED_FOR_ELECTROLYSIS: 'ENERGY_NEEDED_FOR_ELECTROLYSIS',
  TRANSPORT_AND_OTHERS: 'TRANSPORT_AND_OTHERS',
  TRANSPORT_CARS_COUNT: 'TRANSPORT_CARS_COUNT',
  TRANSPORT_AVG_ENERGY_CONSUMTION: 'TRANSPORT_AVG_ENERGY_CONSUMTION',
  TRANSPORT_AVG_DISTANCE_TRAVEL: 'TRANSPORT_AVG_DISTANCE_TRAVEL',
  TRANSPORT_PUBLIC: 'TRANSPORT_PUBLIC',
  TRANSPORT_GOODS_VOLUMEN: 'TRANSPORT_GOODS_VOLUMEN',
  TRANSPORT_GOODS_RAIL_SHARE: 'TRANSPORT_GOODS_RAIL_SHARE',
  TRANSPORT_AND_OTHERS_TOOLTIP: 'TRANSPORT_AND_OTHERS_TOOLTIP',
  TRANSPORT_AND_OTHERS_DATA_INPUT_TOOLTIP: 'TRANSPORT_AND_OTHERS_DATA_INPUT_TOOLTIP',
  SOURCE_OF_ENERGY: 'SOURCE_OF_ENERGY',
  ENERGY_SOURCE: 'ENERGY_SOURCE',
  ELECTRIC_ENERGY_SOURCE: 'ELECTRIC_ENERGY_SOURCE',
  UNITS_KW_PERSON: 'UNITS_KW_PERSON',
  UNITS_PLN_KW: 'UNITS_PLN_KW',
  SUM_IN_PLN: 'SUM_IN_PLN',
  ANNUAL_EXPENCES: 'ANNUAL_EXPENCES',
  ONSHORE_WIND_COSTS_LABEL: 'ONSHORE_WIND_COSTS_LABEL',
  OFFSHORE_WIND_COSTS_LABEL: 'OFFSHORE_WIND_COSTS_LABEL',
  OFFSHORE_WIND_LABEL: 'OFFSHORE_WIND_LABEL',
  ONSHORE_WIND_LABEL: 'ONSHORE_WIND_LABEL',
  PHOTOVOLTAICS: 'PHOTOVOLTAICS',
  PV_ORIENTATION: 'PV_ORIENTATION',
  ATOMIC: 'ATOMIC',
  NATURAL_GAS: 'NATURAL_GAS',
  ENERGY_STORAGE: 'ENERGY_STORAGE',
  CIEPLO: 'CIEPLO',
  LITHIUM_STACIONARY: 'LITHIUM_STACIONARY',
  PUMP_POWER_PLANTS: 'PUMP_POWER_PLANTS',
  HEAT_STORAGE_PTES: 'HEAT_STORAGE_PTES',
  HEAT_STORAGE_TTES: 'HEAT_STORAGE_TTES',
  HEAT_STORAGE_HOME: 'HEAT_STORAGE_HOME',
  MAGAZYN_PRZEMYSLOWY_KOSZT: 'MAGAZYN_PRZEMYSLOWY_KOSZT',
  ELECTROLYZERS: 'ELECTROLYZERS',
  TOTAL_CONSTRUCTION_COST: 'TOTAL_CONSTRUCTION_COST',
  TOTAL_CONSTRUCTION_COST_PER_PERSON: 'TOTAL_CONSTRUCTION_COST_PER_PERSON',
  MONTHLY: 'MONTHLY',
  GW: 'GW',
  MLD_Z_GW: 'MLD_Z_GW',
  SUM_MLD_PLN: 'SUM_MLD_PLN',
  EXPORT_DATA: 'EXPORT_DATA',
  IMPORT_DATA: 'IMPORT_DATA',
  RESET_DATA: 'RESET_DATA',
  CHOOSE_SCENARIO_SIMULATION: 'CHOOSE_SCENARIO_SIMULATION',
  IMPORT_DATA_ERROR: 'IMPORT_DATA_ERROR',
  IMPORT_DATA_SUCCESS: 'IMPORT_DATA_SUCCESS',
  ADVANCED_DATA_SETTINGS_BTN_LABEL: 'ADVANCED_DATA_SETTINGS_BTN_LABEL',
  CURRENT_NEED_FOR_POWER: 'CURRENT_NEED_FOR_POWER',
  CURRENT_NEED_FOR_POWER_HYDROGEN: 'CURRENT_NEED_FOR_POWER_HYDROGEN',
  DEMAND_BASIC_HYDROGEN: 'DEMAND_BASIC_HYDROGEN',
  ELASTIC_OF_DEMAND: 'ELASTIC_OF_DEMAND',
  ELASTIC_OF_DEMAND_ADVANCED: 'ELASTIC_OF_DEMAND_ADVANCED',
  INSULATION_QUALITY: 'INSULATION_QUALITY',
  BUILDINGS_WITH_RECOVERY: 'BUILDINGS_WITH_RECOVERY',
  ELASTIC_OF_DEMAND_ADVANCED_TOOLTIP: 'ELASTIC_OF_DEMAND_ADVANCED_TOOLTIP',
  HYDROGEN_PRODUCTION_ELECTROLYSIS: 'HYDROGEN_PRODUCTION_ELECTROLYSIS',
  ELASTIC_OF_DEMAND_OPTION_4: 'ELASTIC_OF_DEMAND_OPTION_4',
  ELASTIC_OF_DEMAND_OPTION_3: 'ELASTIC_OF_DEMAND_OPTION_3',
  ELASTIC_OF_DEMAND_OPTION_2: 'ELASTIC_OF_DEMAND_OPTION_2',
  ELASTIC_OF_DEMAND_OPTION_1: 'ELASTIC_OF_DEMAND_OPTION_1',
  POWER: 'POWER',
  ELECTROLYSIS_EFFICIENCY: 'ELECTROLYSIS_EFFICIENCY',
  STORAGE_EFFICIENCY: 'STORAGE_EFFICIENCY',
  INSTALLED_POWER: 'INSTALLED_POWER',
  EXISTING: 'EXISTING',
  AVAILABILITY_EFFICIENCY: 'AVAILABILITY_EFFICIENCY',
  NUCLEAR_POWER_PLANTS: 'NUCLEAR_POWER_PLANTS',
  DYSP_GAS_PLANTS: 'DYSP_GAS_PLANTS',
  WASTE_HEAT_LABEL: 'WASTE_HEAT_LABEL',
  CAPACITY: 'CAPACITY',
  CAPACITY_ONLY: 'CAPACITY_ONLY',
  EFFICIENCY: 'EFFICIENCY',
  LITHYUM_STATIONARY_BATTERIES: 'LITHYUM_STATIONARY_BATTERIES',
  CAR_BATTERIES: 'CAR_BATTERIES',
  TOOLTIP_ENERGY_STORAGE_TILE_CAR_BATTERIES: 'TOOLTIP_ENERGY_STORAGE_TILE_CAR_BATTERIES',
  PUMPED_STORAGE_POWER_PLANTS: 'PUMPED_STORAGE_POWER_PLANTS',
  SUM: 'SUM',
  CHARGIN_DISCHARGING: 'CHARGIN_DISCHARGING',
  EFFICIENCY_ENERGY_STORAGE: 'EFFICIENCY_ENERGY_STORAGE',
  PPL_CONNECTED_TO_GRID: 'PPL_CONNECTED_TO_GRID',
  PPL_NOT_CONNECTED_TO_GRID_WITH_HEAT_STORAGE: 'PPL_NOT_CONNECTED_TO_GRID_WITH_HEAT_STORAGE',
  EFFICIENCY_PERCENTAGE: 'EFFICIENCY_PERCENTAGE',
  LARGE_PTES_LABEL: 'LARGE_PTES_LABEL',
  LARGE_TTES_LABEL: 'LARGE_TTES_LABEL',
  SMALL_HOME_LABEL: 'SMALL_HOME_LABEL',
  ELECTRIFIED_HEAT: 'ELECTRIFIED_HEAT',
  BASE_REQ_FOR_CENTRAL_HEAT: 'BASIC_REQ_FOR_CENTRAL_HEAT',
  REQ_CENTRAL_HEAT_PROCENTAGE_LABEL: 'REQ_CENTRAL_HEAT_PROCENTAGE_LABEL',
  BASIC_REQ_DOMESTIC_HEAT_WATER_PRECENT: 'BASIC_REQ_DOMESTIC_HEAT_WATER_PRECENT',
  CURR_REQ_FOR_DOMESTIC_HEAT_WATER_PRECENT: 'CURR_REQ_FOR_DOMESTIC_HEAT_WATER_PRECENT',
  CURR_REQ_FOR_DOMESTIC_HEAT_WATER_PRECENT_ADVANCED: 'CURR_REQ_FOR_DOMESTIC_HEAT_WATER_PRECENT_ADVANCED',
  HEAT_PUMPS_IN_HEATING: 'HEAT_PUMPS_IN_HEATING',
  TEMP_CENTRAL_HEAT_LABEL: 'TEMP_CENTRAL_HEAT_LABEL',
  TEMP_DOMESTIC_HEAT_WATERT_LABEL: 'TEMP_DOMESTIC_HEAT_WATERT_LABEL',
  MINIMAL_DEMAND_MEET_LABEL: 'MINIMAL_DEMAND_MEET_LABEL',
  DEMAND_HYDROGEN: 'DEMAND_HYDROGEN',
  DEMAND_BASIC: 'DEMAND_BASIC',
  CARS_NUMBER: 'CARS_NUMBER',
  DEMAND_BASIC_TRANSPORT: 'DEMAND_BASIC_TRANSPORT',
  ELECTRICITY_LABEL: 'ELECTRICITY_LABEL',
  NITROGEN_AND_OTHER_PLANTS_LABEL: 'NITROGEN_AND_OTHER_PLANTS_LABEL',
  SHIPPING_LABEL: 'SHIPPING_LABEL',
  AERONAUTICS_LABEL: 'AERONAUTICS_LABEL',
  STEEL_MELTING_AND_PRODUCTION_LABEL: 'STEEL_MELTING_AND_PRODUCTION_LABEL',
  GAS_FOR_ARMY_LABEL: 'GAS_FOR_ARMY_LABEL',
  CEMENT: 'CEMENT',
  OTHER_LABEL: 'OTHER_LABEL',
  ELECTRIFIED_TRANSPORT_LABEL: 'ELECTRIFIED_TRANSPORT_LABEL',
  CONSTRUCTION_COST: 'CONSTRUCTION_COST',
  CURRENCY_MLD_LABEL: 'CURRENCY_MLD_LABEL',
  LCOE: 'LCOE',
  LCOE_UNIT: 'LCOE_UNIT',
  CURRENCY_ON_MWH: 'CURRENCY_ON_MWH',
  DISP_ENERGY_SOURCES_LABEL: 'DISP_ENERGY_SOURCES_LABEL',
  ENERGY_SOURCES_LABEL: 'ENERGY_SOURCES_LABEL',
  REQUIRMENT_LABEL: 'REQUIRMENT_LABEL',
  TWH: 'TWH',
  ELECTROLYZERS_USED_PERCENTAGE: 'ELECTROLYZERS_USED_PERCENTAGE',
  ENERGY_PRODUCTION: 'ENERGY_PRODUCTION',
  ENERGY_CONSUMPTION: 'ENERGY_CONSUMPTION',
  METHANE_CONSUMPTION: 'METHANE_CONSUMPTION',
  TABLE_METHANE_CONSUMPTION: 'TABLE_METHANE_CONSUMPTION',
  MLD: 'MLD',
  MLDM3: 'MLDM3',
  DYSP_ENERGY_SOURCE_FOR_PLANTS: 'DYSP_ENERGY_SOURCE_FOR_PLANTS',
  COLOR_B_B_B: 'COLOR_B_B_B',
  CH_FROM_BIOMETAN_LABEL: 'CH_FROM_BIOMETAN_LABEL',
  CH_FROM_NATURAL_GAS: 'CH_FROM_NATURAL_GAS',
  CO_EMMISION: 'CO_EMMISION',
  MTCO: 'MTCO',
  EMMISION_GCO_KWH: 'EMMISION_GCO_KWH',
  GCO_KWH: 'GCO_KWH',
  COAL: 'COAL',
  SPECIFIC_INFORMATION: 'SPECIFIC_INFORMATION',
  CURRENT_HYDROGEN_USE_IN_POLAND_LABEL: 'CURRENT_HYDROGEN_USE_IN_POLAND_LABEL',
  BOX2_HEADER: 'BOX2_HEADER',
  BOX2_LABEL1: 'BOX2_LABEL1',
  HYDROGEN_DATA_DESC: 'HYDROGEN_DATA_DESC',
  HYDROGEN_DATA_DOSTEPNOSC: 'HYDROGEN_DATA_DOSTEPNOSC',
  HYDROGEN_DATA_ZAPOTRZ: 'HYDROGEN_DATA_ZAPOTRZ',
  HYDROGEN_DATA_MLN_TON: 'HYDROGEN_DATA_MLN_TON',
  BALANCE: 'BALANCE',
  BALANCE_IN_TIME: 'BALANCE_IN_TIME',
  OKRES: 'OKRES',
  MAIN_PAGE_QUOTE1_TOP: 'MAIN_PAGE_QUOTE1_TOP',
  MAIN_PAGE_QUOTE1_BOTTOM: 'MAIN_PAGE_QUOTE1_BOTTOM',
  MAIN_PAGE_QUOTE2_TOP: 'MAIN_PAGE_QUOTE2_TOP',
  MAIN_PAGE_QUOTE2_BOTTOM: 'MAIN_PAGE_QUOTE2_BOTTOM',
  MAIN_PAGE_QUOTE4_TOP: 'MAIN_PAGE_QUOTE4_TOP',
  MAIN_PAGE_QUOTE4_BOTTOM: 'MAIN_PAGE_QUOTE4_BOTTOM',
  MAIN_PAGE_QUOTE3_TOP: 'MAIN_PAGE_QUOTE3_TOP',
  MAIN_PAGE_QUOTE3_BOTTOM: 'MAIN_PAGE_QUOTE3_BOTTOM',
  QUOTE3: 'QUOTE3',
  SEND_MESSAGE_CREATORS: 'SEND_MESSAGE_CREATORS',
  YOUR_EMAIL: 'YOUR_EMAIL',
  MESSAGE_BODY: 'MESSAGE_BODY',
  CANCEL: 'CANCEL',
  EDIT_INPUT_DATA: 'EDIT_INPUT_DATA',
  EDIT_INPUT_DATA_TEXT: 'EDIT_INPUT_DATA_TEXT',
  SAVE: 'SAVE',
  COSTS_TEXT_1: 'COSTS_TEXT_1',
  DISCOUNT_RATE: 'DISCOUNT_RATE',
  SUM_OF_COSTS: 'SUM_OF_COSTS',
  FOR_POLAND: 'FOR_POLAND',
  INFORMATIONS: 'INFORMATIONS',
  MAIN_TEXT1: 'MAIN_TEXT1',
  MAIN_TEXT2: 'MAIN_TEXT2',
  MAIN_TEXT_SPONSOR: 'MAIN_TEXT_SPONSOR',
  MAIN_INTRODUCTION: 'MAIN_INTRODUCTION',
  MAIN_TEXT4: 'MAIN_TEXT4',
  DOCUMENTATION: 'DOCUMENTATION',
  MAIN_TEXT5: 'MAIN_TEXT5',
  MAIN_TEXT6: 'MAIN_TEXT6',
  MAIN_TEXT7: 'MAIN_TEXT7',
  MAIN_TEXT9_MAIN: 'MAIN_TEXT9_MAIN',
  MAIN_TEXT9_BOTTOM: 'MAIN_TEXT9_BOTTOM',
  MAIN_TEXT10_MAIN: 'MAIN_TEXT10_MAIN',
  MAIN_TEXT10_BOTTOM: 'MAIN_TEXT10_BOTTOM',
  MAIN_TEXT11_MAIN: 'MAIN_TEXT11_MAIN',
  MAIN_TEXT11_BOTTOM: 'MAIN_TEXT11_BOTTOM',
  MAIN_TEXT12_MAIN: 'MAIN_TEXT12_MAIN',
  MAIN_TEXT12_BOTTOM: 'MAIN_TEXT12_BOTTOM',
  MAIN_TEXT13_MAIN: 'MAIN_TEXT13_MAIN',
  MAIN_TEXT13_BOTTOM: 'MAIN_TEXT13_BOTTOM',
  MAIN_MEET_SCENARIOS: 'MAIN_MEET_SCENARIOS',
  MAIN_EXAMPLE_SCENARIOS: 'MAIN_EXAMPLE_SCENARIOS',
  MAIN_FOOTER: 'MAIN_FOOTER',
  TOOLTIP_HEAT_STORAGE_TILE_CONTENT: 'TOOLTIP_HEAT_STORAGE_TILE_CONTENT',
  TOOLTIP_HEAT_STORAGE_TILE_CONTENT_IN_MODAL: 'TOOLTIP_HEAT_STORAGE_TILE_CONTENT_IN_MODAL',
  TOOLTIP_HEAT_STORAGE_TILE_PTES: 'TOOLTIP_HEAT_STORAGE_TILE_PTES',
  TOOLTIP_HEAT_STORAGE_TILE_TTES: 'TOOLTIP_HEAT_STORAGE_TILE_TTES',
  TOOLTIP_HEAT_STORAGE_TILE_HOME: 'TOOLTIP_HEAT_STORAGE_TILE_HOME',
  TOOLTIP_HEAT_STORAGE_TILE_SUM: 'TOOLTIP_HEAT_STORAGE_TILE_SUM',
  TOOLTIP_HYDROGEN_NEEDS_CONTENT: 'TOOLTIP_HYDROGEN_NEEDS_CONTENT',
  TOOLTIP_HYDROGEN_NEEDS_REFINES: 'TOOLTIP_HYDROGEN_NEEDS_REFINES',
  TOOLTIP_HYDROGEN_NEEDS_SHIPPING: 'TOOLTIP_HYDROGEN_NEEDS_SHIPPING',
  TOOLTIP_HYDROGEN_NEEDS_AURONAUTICS: 'TOOLTIP_HYDROGEN_NEEDS_AURONAUTICS',
  TOOLTIP_HYDROGEN_NEEDS_STEEL_MELTING: 'TOOLTIP_HYDROGEN_NEEDS_STEEL_MELTING',
  TOOLTIP_HYDROGEN_NEEDS_GAS_FOR_ARMY_LABEL: 'TOOLTIP_HYDROGEN_NEEDS_GAS_FOR_ARMY_LABEL',
  TOOLTIP_HYDROGEN_NEEDS_CEMENT: 'TOOLTIP_HYDROGEN_NEEDS_CEMENT',
  TOOLTIP_HYDROGEN_NEEDS_OTHER: 'TOOLTIP_HYDROGEN_NEEDS_OTHER',
  TOOLTIP_HYDROGEN_NEEDS_SUM: 'TOOLTIP_HYDROGEN_NEEDS_SUM',
  TOOLTIP_TRANSPORT_TILE_DEMAND_BASIC: 'TOOLTIP_TRANSPORT_TILE_DEMAND_BASIC',
  TOOLTIP_TRANSPORT_TILE_CURRENT_NEED_FOR_POWER: 'TOOLTIP_TRANSPORT_TILE_CURRENT_NEED_FOR_POWER',
  TOOLTIP_TRANSPORT_TILE_ELASTIC_OF_DEMAND: 'TOOLTIP_TRANSPORT_TILE_ELASTIC_OF_DEMAND',
  TOOLTIP_TRANSPORT_TILE_CONTENT: 'TOOLTIP_TRANSPORT_TILE_CONTENT',
  TOOLTIP_ENERGY_STORAGE_TILE_CONTENT: 'TOOLTIP_ENERGY_STORAGE_TILE_CONTENT',
  TOOLTIP_ENERGY_STORAGE_TILE_CONTENT_IN_MODAL: 'TOOLTIP_ENERGY_STORAGE_TILE_CONTENT_IN_MODAL',
  TOOLTIP_ENERGY_STORAGE_TILE_LITHYUM_STATIONARY_BATTERIES: 'TOOLTIP_ENERGY_STORAGE_TILE_LITHYUM_STATIONARY_BATTERIES',
  TOOLTIP_ENERGY_STORAGE_TILE_PUMPED_STORAGE_POWER_PLANTS: 'TOOLTIP_ENERGY_STORAGE_TILE_PUMPED_STORAGE_POWER_PLANTS',
  TOOLTIP_ENERGY_STORAGE_TILE_SUM: 'TOOLTIP_ENERGY_STORAGE_TILE_SUM',
  TOOLTIP_ENERGY_OTHER_DEMAND_TILE_CONTENT: 'TOOLTIP_ENERGY_OTHER_DEMAND_TILE_CONTENT',
  TOOLTIP_ENERGY_OTHER_DEMAND_TILE_CURRENT_NEED_FOR_POWER: 'TOOLTIP_ENERGY_OTHER_DEMAND_TILE_CURRENT_NEED_FOR_POWER',
  TOOLTIP_ENERGY_OTHER_DEMAND_TILE_ELASTIC_OF_DEMAND: 'TOOLTIP_ENERGY_OTHER_DEMAND_TILE_ELASTIC_OF_DEMAND',
  TOOLTIP_ENERGY_SOURCE_TILE_CONTENT: 'TOOLTIP_ENERGY_SOURCE_TILE_CONTENT',
  TOOLTIP_ENERGY_SOURCE_TILE_OFSHORE_WIND: 'TOOLTIP_ENERGY_SOURCE_TILE_OFSHORE_WIND',
  TOOLTIP_ENERGY_SOURCE_TILE_ONSHORE_WIND: 'TOOLTIP_ENERGY_SOURCE_TILE_ONSHORE_WIND',
  TOOLTIP_ENERGY_SOURCE_TILE_PHOTOVOLTAICS: 'TOOLTIP_ENERGY_SOURCE_TILE_PHOTOVOLTAICS',
  TOOLTIP_ENERGY_SOURCE_TILE_NUCLEAR_POWER_PLANTS: 'TOOLTIP_ENERGY_SOURCE_TILE_NUCLEAR_POWER_PLANTS',
  TOOLTIP_ENERGY_SOURCE_TILE_DYSP_GAS_PLANTS: 'TOOLTIP_ENERGY_SOURCE_TILE_DYSP_GAS_PLANTS',
  TOOLTIP_ENERGY_SOURCE_TILE_WASTE_HEAT_LABEL: 'TOOLTIP_ENERGY_SOURCE_TILE_WASTE_HEAT_LABEL',
  TOOLTIP_HEAT_TILE_CONTENT: 'TOOLTIP_HEAT_TILE_CONTENT',
  CELSIUS_TEMP: 'CELSIUS_TEMP',
  TOOLTIP_HEAT_TILE_BASE_REQ_FOR_CENTRAL_HEAT: 'TOOLTIP_HEAT_TILE_BASE_REQ_FOR_CENTRAL_HEAT',
  TOOLTIP_HEAT_TILE_REQ_CENTRAL_HEAT_PROCENTAGE: 'TOOLTIP_HEAT_TILE_REQ_CENTRAL_HEAT_PROCENTAGE',
  TOOLTIP_HEAT_TILE_BASIC_REQ_DOMESTIC_HEAT_WATER_PRECENT: 'TOOLTIP_HEAT_TILE_BASIC_REQ_DOMESTIC_HEAT_WATER_PRECENT',
  BUILDINGS_WITH_RECOVERY_ADVANCED: 'BUILDINGS_WITH_RECOVERY_ADVANCED',
  BUILDINGS_WITH_RECOVERY_ADVANCED_TOOLTIP: 'BUILDINGS_WITH_RECOVERY_ADVANCED_TOOLTIP',
  STOPIEN_ZASTAPIENIA_NOWYC_BUD_ADVANCED: 'STOPIEN_ZASTAPIENIA_NOWYC_BUD_ADVANCED',
  STOPIEN_ZASTAPIENIA_NOWYC_BUD_ADVANCED_TOOLTIP: 'STOPIEN_ZASTAPIENIA_NOWYC_BUD_ADVANCED_TOOLTIP',
  TOOLTIP_HEAT_TILE_CURR_REQ_FOR_DOMESTIC_HEAT_WATER_PRECENT:
    'TOOLTIP_HEAT_TILE_CURR_REQ_FOR_DOMESTIC_HEAT_WATER_PRECENT',
  TOOLTIP_HEAT_TILE_HEAT_PUMPS_IN_HEATING: 'TOOLTIP_HEAT_TILE_HEAT_PUMPS_IN_HEATING',
  TOOLTIP_HEAT_TILE_ELASTIC_OF_DEMAND: 'TOOLTIP_HEAT_TILE_ELASTIC_OF_DEMAND',
  TOOLTIP_HEAT_TILE_TEMP_CENTRAL_HEAT: 'TOOLTIP_HEAT_TILE_TEMP_CENTRAL_HEAT',
  TOOLTIP_HEAT_TILE_TEMP_DOMESTIC_HEAT_WATERT_LABEL: 'TOOLTIP_HEAT_TILE_TEMP_DOMESTIC_HEAT_WATERT_LABEL',
  TOOLTIP_HEAT_TILE_MINIMAL_DEMAND_MEET: 'TOOLTIP_HEAT_TILE_MINIMAL_DEMAND_MEET',
  TOOLTIP_HEAT_TILE_ELECTROLYSERS_CONTENT: 'TOOLTIP_HEAT_TILE_ELECTROLYSERS_CONTENT',
  TOOLTIP_HEAT_TILE_ELECTROLYSERS_MAIN: 'TOOLTIP_HEAT_TILE_ELECTROLYSERS_MAIN',
  TOOLTIP_CONSTRUCTION_COST_TILE: 'TOOLTIP_CONSTRUCTION_COST_TILE',
  ENERGY_SOURCES_TILE: 'ENERGY_SOURCES_TILE',
  ENERGY_SOURCES_TILE_TOOLTIP: 'ENERGY_SOURCES_TILE_TOOLTIP',
  ENERGY_SOURCES_TILE_WIND_OFF_SHORE: 'ENERGY_SOURCES_TILE_WIND_OFF_SHORE',
  ENERGY_SOURCES_TILE_WIND: 'ENERGY_SOURCES_TILE_WIND',
  ENERGY_SOURCES_TILE_PHOTOVOLTAIC: 'ENERGY_SOURCES_TILE_PHOTOVOLTAIC',
  TOOLTIP_ENERGY_SOURCE_TILE_ATOM: 'TOOLTIP_ENERGY_SOURCE_TILE_ATOM',
  TOOLTIP_ENERGY_SOURCE_TILE_GAS: 'TOOLTIP_ENERGY_SOURCE_TILE_GAS',
  HOUR: 'HOUR',
  DAY: 'DAY',
  DATA: 'DATA',
  EXPORT_ENERGY_COL_NAME: 'EXPORT_ENERGY_COL_NAME',
  EXPORT_WIND_OFFSHORE_COL_NAME: 'EXPORT_WIND_OFFSHORE_COL_NAME',
  EXPORT_WIND_COL_NAME: 'EXPORT_WIND_COL_NAME',
  EXPORT_WIND_5MW_COL_NAME: 'EXPORT_WIND_5MW_COL_NAME',
  EXPORT_PHOTOVOLTAIC_COL_NAME: 'EXPORT_PHOTOVOLTAIC_COL_NAME',
  AVG_TEMPERATURE: 'AVG_TEMPERATURE',
  HEAT_PRODUCTION_CHART_LABEL: 'HEAT_PRODUCTION_CHART_LABEL',
  TRANSPORT_CHART_LABEL: 'TRANSPORT_CHART_LABEL',
  ONSHORE_WIND_CHART_LABEL: 'ONSHORE_WIND_CHART_LABEL',
  WIND_OFFSHORE_CHART_LABEL: 'WIND_OFFSHORE_CHART_LABEL',
  ELECTROLYSERS_CHART_LABEL: 'ELECTROLYSERS_CHART_LABEL',
  PHOTOVOLTAIC_CHART_LABEL: 'PHOTOVOLTAIC_CHART_LABEL',
  REST_CHART_LABEL: 'REST_CHART_LABEL',
  ATOM_CHART_LABEL: 'ATOM_CHART_LABEL',
  UNUSED_ENERGY_CHART_LABEL: 'UNUSED_ENERGY_CHART_LABEL',
  NATURAL_GAS_CHART_LABEL: 'NATURAL_GAS_CHART_LABEL',
  LOOSES_CHART_LABEL: 'LOOSES_CHART_LABEL',
  COOGENERATION_CHART_LABEL: 'COOGENERATION_CHART_LABEL',
  STORAGE_CHART_LABEL: 'STORAGE_CHART_LABEL',
  LINE_SELECT_OPTION_1: 'LINE_SELECT_OPTION_1',
  LINE_SELECT_OPTION_2: 'LINE_SELECT_OPTION_2',
  LINE_SELECT_OPTION_3: 'LINE_SELECT_OPTION_3',
  LINE_SELECT_OPTION_4: 'LINE_SELECT_OPTION_4',
  LINE_SELECT_OPTION_5: 'LINE_SELECT_OPTION_5',
  LINE_TOOLTIP_DISP: 'LINE_TOOLTIP_DISP',
  LINE_TOOLTIP_ENERGY_STORAGE: 'LINE_TOOLTIP_ENERGY_STORAGE',
  LINE_TOOLTIP_ENERGY_ELASTICITY: 'LINE_TOOLTIP_ENERGY_ELASTICITY',
  LINE_TOOLTIP_HEAT: 'LINE_TOOLTIP_HEAT',
  LINE_TOOLTIP_HEAT_AT_STORAGE: 'LINE_TOOLTIP_HEAT_AT_STORAGE',
  LINE_TOOLTIP_HEAT_INDUSTRIAL_AT_STORAGE: 'LINE_TOOLTIP_HEAT_INDUSTRIAL_AT_STORAGE',
  LINE_TOOLTIP_HYDROGEN: 'LINE_TOOLTIP_HYDROGEN',
  LINE_TOOLTIP_NEED_WITH_ELASTICITY: 'LINE_TOOLTIP_NEED_WITH_ELASTICITY',
  LINE_TOOLTIP_TRANSPORT: 'LINE_TOOLTIP_TRANSPORT',
  LINE_TOOLTIP_ATOM: 'LINE_TOOLTIP_ATOM',
  LINE_TOOLTIP_PHOTOVOLTAIC: 'LINE_TOOLTIP_PHOTOVOLTAIC',
  LINE_TOOLTIP_PRODUCED_SUM: 'LINE_TOOLTIP_PRODUCED_SUM',
  LINE_TOOLTIP_WIND: 'LINE_TOOLTIP_WIND',
  LINE_TOOLTIP_WIND_OFFSHORE: 'LINE_TOOLTIP_WIND_OFFSHORE',
  LINE_TOOLTIP_FROM_ENERGY_STORAGE: 'LINE_TOOLTIP_FROM_ENERGY_STORAGE',
  LINE_TOOLTIP_DEMAND: 'LINE_TOOLTIP_DEMAND',
  LINE_TOOLTIP_COOGENERATION: 'LINE_TOOLTIP_COOGENERATION',
  HEAT_RATIOS_OPTION_1: 'HEAT_RATIOS_OPTION_1',
  HEAT_RATIOS_OPTION_2: 'HEAT_RATIOS_OPTION_2',
  HEAT_RATIOS_OPTION_3: 'HEAT_RATIOS_OPTION_3',
  MONTH_1: 'MONTH_1',
  MONTH_2: 'MONTH_2',
  MONTH_3: 'MONTH_3',
  MONTH_4: 'MONTH_4',
  MONTH_5: 'MONTH_5',
  MONTH_6: 'MONTH_6',
  MONTH_7: 'MONTH_7',
  MONTH_8: 'MONTH_8',
  MONTH_9: 'MONTH_9',
  MONTH_10: 'MONTH_10',
  MONTH_11: 'MONTH_11',
  MONTH_12: 'MONTH_12',
  REDUCTION_HEAT_DEMAND_TOOLTIP: 'REDUCTION_HEAT_DEMAND_TOOLTIP',
  HEAT_CWU_REDUCTION_TOOLTIP: 'HEAT_CWU_REDUCTION_TOOLTIP',
  HEAT_PUMP_PARTICIPATION_TOOLTIP: 'HEAT_PUMP_PARTICIPATION_TOOLTIP',
  ENERGY_DEMAND_TOOLTIP: 'ENERGY_DEMAND_TOOLTIP',
  ENERGY_ELECTROCITY_STORAGE_TOOLTIP: 'ENERGY_ELECTROCITY_STORAGE_TOOLTIP',
  HYDROGEN_TOOLTIP: 'HYDROGEN_TOOLTIP',
  HYDROGEN_PRODUCTION_TOOLTIP: 'HYDROGEN_PRODUCTION_TOOLTIP',
  HYDROGEN_NEEDS_TOOLTIP: 'HYDROGEN_NEEDS_TOOLTIP',
  HEAT_STORAGE_TOOLTIP: 'HEAT_STORAGE_TOOLTIP',
  ENERGY_BALANCE_TITLE: 'ENERGY_BALANCE_TITLE',
  ENERGY_BALANCE_TITLE_TOOLTIP: 'ENERGY_BALANCE_TITLE_TOOLTIP',
  HIDE_LEGEND: 'HIDE_LEGEND',
  EXPAND_LEGEND: 'EXPAND_LEGEND',
  TOOLTIP_TIME_BALANCE_TILE_IN_TIME: 'TOOLTIP_TIME_BALANCE_TILE_IN_TIME',
  DISP_ENERGY_SOURCES_LABEL_TOOLTIP: 'DISP_ENERGY_SOURCES_LABEL_TOOLTIP',
  LINE_CHART_SECTION_TOOLTIP: 'LINE_CHART_SECTION_TOOLTIP',
  OTHER_ELECTRICITY_CONSUMPTION_TOOLTIP: 'OTHER_ELECTRICITY_CONSUMPTION_TOOLTIP',
  GWh: 'GWh',
  QUATER: 'QUATER',
  CONSTS_SELECT_TOOLTIP: 'CONSTS_SELECT_TOOLTIP',
  COSTS_TABLE_CAPTION: 'COSTS_TABLE_CAPTION',
  XLS_GEOTHERMY_DOWNLOAD_PATH: 'XLS_GEOTHERMY_DOWNLOAD_PATH',
  DOWNLOAD_EXCEL_TEXT_GEOTHERMY: 'DOWNLOAD_EXCEL_TEXT_GEOTHERMY',
  DISPOSAL_SOURCES_CHART_LABEL: 'DISPOSAL_SOURCES_CHART_LABEL',
};

export default keys;
