import { useCallback, useState } from 'react';

export const useToggle = (currState?: boolean): [boolean, (e?: boolean) => void] => {
  const [open, setOpen] = useState(!!currState);

  const clickHandler = useCallback(e => {
    if (typeof e === 'boolean') {
      setOpen(e);
      return;
    }
    setOpen(prev => !prev);
  }, []);

  return [open, clickHandler];
};
