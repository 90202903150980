import keys from 'i18n/keys';
import { Step } from 'react-joyride';
import { SIMULATOR_INTRO_STEPS } from './consts';

export const TOUR_STEPS: Step[] = [
  {
    content: keys.INTRO_STEP_START,
    title: keys.INTRO_STEP_START_title,
    placement: 'center',
    target: 'body',
    disableScrolling: true,
    disableScrollParentFix: true,
    styles: {
      tooltipContent: {
        width: '1200px',
        maxWidth: '1200px',
      },
    },
  },
  {
    content: keys.INTRO_STEP_ENERGY_SOURCES,
    title: keys.INTRO_STEP_ENERGY_SOURCES_title,
    target: `#${SIMULATOR_INTRO_STEPS.ENERGY_SOURCES}`,
  },
  {
    content: keys.INTRO_STEP_DEMAND,
    title: keys.INTRO_STEP_DEMAND_title,
    target: `#${SIMULATOR_INTRO_STEPS.DEMAND}`,
  },
  {
    content: keys.INTRO_STEP_STORAGE,
    title: keys.INTRO_STEP_STORAGE_title,
    target: `#${SIMULATOR_INTRO_STEPS.STORAGE}`,
  },
  {
    content: keys.INTRO_STEP_HYDROGEN,
    title: keys.INTRO_STEP_HYDROGEN_title,
    target: `#${SIMULATOR_INTRO_STEPS.HYDROGEN}`,
  },
  {
    content: keys.INTRO_STEP_DISPOSAL_SOURCES,
    title: keys.INTRO_STEP_DISPOSAL_SOURCES_title,
    target: `#${SIMULATOR_INTRO_STEPS.DISPOSAL_SOURCES}`,
  },
  // {
  //   content: keys.INTRO_STEP_TIME_BALANCE,
  //   title: keys.INTRO_STEP_TIME_BALANCE_title,
  //   target: `#${SIMULATOR_INTRO_STEPS.TIME_BALANCE}`,
  // },
  // {
  //   content: keys.INTRO_STEP_HYDROGEN_BALANCE,
  //   title: keys.INTRO_STEP_HYDROGEN_BALANCE_title,
  //   target: `#${SIMULATOR_INTRO_STEPS.HYDROGEN_BALANCE}`,
  // },
  {
    content: keys.INTRO_STEP_CONSTRUCTION_COSTS,
    title: keys.INTRO_STEP_CONSTRUCTION_COSTS_title,
    target: `#${SIMULATOR_INTRO_STEPS.CONSTRUCTION_COSTS}`,
  },
  {
    content: keys.INTRO_STEP_HEAT_BALANCE,
    title: keys.INTRO_STEP_HEAT_BALANCE_title,
    target: `#${SIMULATOR_INTRO_STEPS.BILANS_OGRZEWANIA}`,
  },
  {
    content: keys.INTRO_STEP_ELEKTROLYZE,
    title: keys.INTRO_STEP_ELEKTROLYZE_title,
    target: `#${SIMULATOR_INTRO_STEPS.ELEKTROLIZA}`,
  },
  {
    content: keys.INTRO_STEP_ENERGY_BALANCE,
    title: keys.INTRO_STEP_ENERGY_BALANCE_title,
    target: `#${SIMULATOR_INTRO_STEPS.ENERGY_BALANCE}`,
  },
  {
    content: keys.INTRO_STEP_LINE_CHART,
    title: keys.INTRO_STEP_LINE_CHART_title,
    target: `#${SIMULATOR_INTRO_STEPS.LINE_CHART}`,
  },
  {
    content: keys.INTRO_STEP_EXPORT,
    title: keys.INTRO_STEP_EXPORT_title,
    target: `#${SIMULATOR_INTRO_STEPS.EXPORT_PDF}`,
  },
  {
    content: keys.INTRO_STEP_EXPORT_DATA,
    title: keys.INTRO_STEP_EXPORT_DATA_title,
    target: `#${SIMULATOR_INTRO_STEPS.EXPORT_DATA}`,
  },
  {
    content: keys.INTRO_STEP_CHOOSE_SCENARIO,
    title: keys.INTRO_STEP_CHOOSE_SCENARIO_title,
    target: `#${SIMULATOR_INTRO_STEPS.CHOOSE_SCENARIO}`,
  },
  {
    content: keys.INTRO_STEP_ADVANCED_DATA,
    title: keys.INTRO_STEP_ADVANCED_DATA_title,
    target: `#${SIMULATOR_INTRO_STEPS.ADVANCED_DATA}`,
  },
  {
    content: keys.INTRO_STEP_SAVE_SIMULATION,
    title: keys.INTRO_STEP_SAVE_SIMULATION_title,
    target: `#${SIMULATOR_INTRO_STEPS.SAVE_SIMULATION}`,
  },
  {
    content: keys.INTRO_STEP_CHOOSE_YEAR,
    title: keys.INTRO_STEP_CHOOSE_YEAR_title,
    target: `#${SIMULATOR_INTRO_STEPS.CHOOSE_YEAR}`,
  },
  {
    content: keys.INTRO_STEP_TUTORIAL,
    title: keys.INTRO_STEP_TUTORIAL_title,
    placement: 'center',
    target: 'body',
    disableScrolling: true,
    disableScrollParentFix: true,
    styles: {
      tooltipContent: {
        width: '1200px',
        maxWidth: '1200px',
      },
    },
  },
];
