import { ObliczeniaStateSlice } from '../obliczeniaSlice';
import { BilansEnergiiType } from './types';

export const calcBilansEnergii = (state: ObliczeniaStateSlice): BilansEnergiiType => {
  const { atom_per_year, wiatr_lad_per_year, wiatr_na_morzu_per_year, PV_per_year } =
    state.produkcjaEnergiiElektrycznej.calculations;
  const {
    źródła_dyspozycyjne_per_year,
    kogeneracja_per_year,
    energia_elektryczna_per_year,
    transport_per_year,
    cieplo_per_year,
    elektroliza_per_year,
    nadwyżka_prądu_per_year,
    cieplo_przemyslowe_per_year,
    cieplo: { CO_cieplo_PC_suma, CWU_cieplo_PC_suma },
  } = state.obliczenia.calculations;
  const returnObj: BilansEnergiiType = {
    produkcja: {
      wiatr_na_ladzie: 0,
      wiatr_na_morzu: 0,
      PV: 0,
      atom: 0,
      produkcja_suma: 0,
      ciepło_otoczenia: 0,
      kogeneracja: 0,
      gaz: 0,
      straty: 0,
    },
    zużycie: {
      ciepło_przmyslowe: 0,
      prąd_inne: 0,
      transport: 0,
      ciepło: 0,
      wodór: 0,
      nadwyżka: 0,
      magazyn: 0,
      ciepło_otoczenia: 0,
      zużycie_suma: 0,
    },
  };

  //produkcja
  const wiatr_na_ladzie = wiatr_lad_per_year / 1000;
  const wiatr_na_morzu = wiatr_na_morzu_per_year / 1000;
  const PV = PV_per_year / 1000;
  const atom = atom_per_year / 1000;
  const ciepło_otoczenia = (CO_cieplo_PC_suma + CWU_cieplo_PC_suma) / 1000;
  const kogeneracja = kogeneracja_per_year / 1000;
  const gaz = źródła_dyspozycyjne_per_year / 1000;
  const produkcja_suma = wiatr_na_ladzie + wiatr_na_morzu + PV + atom + kogeneracja + gaz;

  //zuzycie
  const prąd_inne = energia_elektryczna_per_year / 1000;
  const transport = transport_per_year / 1000;
  const ciepło = cieplo_per_year / 1000;
  const wodór = elektroliza_per_year / 1000;
  const nadwyżka = nadwyżka_prądu_per_year / 1000;
  const ciepło_przmyslowe = cieplo_przemyslowe_per_year / 1000;
  const zużycie_suma = prąd_inne + transport + ciepło + wodór + nadwyżka + ciepło_przmyslowe;
  const magazyn = zużycie_suma < produkcja_suma ? produkcja_suma - zużycie_suma : 0;
  returnObj.zużycie = {
    prąd_inne,
    transport,
    ciepło,
    wodór,
    nadwyżka,
    magazyn,
    zużycie_suma,
    ciepło_przmyslowe,
    ciepło_otoczenia,
  };

  const straty = produkcja_suma < zużycie_suma ? zużycie_suma - produkcja_suma : 0;
  returnObj.produkcja = {
    wiatr_na_ladzie,
    wiatr_na_morzu,
    PV,
    atom,
    ciepło_otoczenia,
    kogeneracja,
    gaz,
    produkcja_suma,
    straty,
  };

  return returnObj;
};
