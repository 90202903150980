import styled from '@emotion/styled';

export const Cnt = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr auto;
`;

export const DognoughtChart = styled.div`
  width: 108px;
  height: 108px;
  position: relative;
  canvas {
    width: 108px !important;
    height: 108px !important;
  }
`;

export const LegendCnt = styled.div`
  margin-top: 14px;
  border-top: 1px solid #e5e5e5;
  padding-top: 8px;
`;

export const BulletRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const SBulltetCnt = styled.div`
  display: flex;
  align-items: center;
`;

export const SBullet = styled.div<{ background: string; borderColor: string }>`
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  background: ${({ background }) => background};
  border-radius: 50%;
  margin-right: 3px;
  border: 2px solid ${({ borderColor }) => borderColor};
`;

export const SBulletText = styled.span`
  white-space: nowrap;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
`;
