import keys from 'i18n/keys';
import React from 'react';
import { ColumnWrapper } from './styles';
import { ElectrocityOtherDemand } from 'features/AdvancedOptionsTiles/ElectrocityOtherDemand';
import { ElectrolysersTile } from 'features/AdvancedOptionsTiles/ElectrolysersTile';
import { EnergySourceTile } from 'features/AdvancedOptionsTiles/EnergySourceTile';
import { EnergyStorageTile } from 'features/AdvancedOptionsTiles/EnergyStorageTile';
import { HeatStorageTile } from 'features/AdvancedOptionsTiles/HeatStorageTile';
import { HeatTile } from 'features/AdvancedOptionsTiles/HeatTile';
import { HydrogenNeeds } from 'features/AdvancedOptionsTiles/HydrogenNeeds';
import { TransportTile } from 'features/AdvancedOptionsTiles/TransportTile';
import { useTranslation } from 'react-i18next';
import { TileTitle } from 'components/Titles/TileTitle';
import { ElasticityTile } from 'features/AdvancedOptionsTiles/ElasticityTile';
import { WithSidebar } from 'components/Sidebar/Sidebar';
import { PageCnt } from 'components/Card/PageCnt';
import { SimulatorHeader } from 'components/SimulatorHeader/SimulatorHeader';
import { URL } from 'services/consts/routes';
import { Button } from 'components/Button/Button';
import { Spacer } from 'components/common';
import { CieploPrzemysloweTile } from 'features/AdvancedOptionsTiles/CieploPrzemysloweTile';
import { OdbiorcyCiepla } from 'features/AdvancedOptionsTiles/OdbiorcyCiepla';

export const EditAdvancedOptions = () => {
  const { t } = useTranslation();

  return (
    <WithSidebar wrapperBg="#F9FAFB">
      <PageCnt>
        <SimulatorHeader title={t(keys.ADVANCED_DATA_SETTINGS_BTN_LABEL)}>
          <div>
            <Button to={URL.SIMULATOR} variant="contained">
              {t(keys.BACK_TO_BASIC_DATA)}
            </Button>
          </div>
        </SimulatorHeader>
        <ColumnWrapper>
          <div>
            <Spacer mb="32px">
              <EnergySourceTile />
            </Spacer>
            <Spacer mb="32px">
              <EnergyStorageTile />
            </Spacer>
            <Spacer mb="32px">
              <HeatStorageTile />
            </Spacer>
            <Spacer mb="32px">
              <CieploPrzemysloweTile />
            </Spacer>
            <Spacer mb="32px">
              <OdbiorcyCiepla />
            </Spacer>
          </div>
          <div>
            <Spacer mb="32px">
              <ElectrocityOtherDemand />
            </Spacer>
            <Spacer mb="32px">
              <TransportTile />
            </Spacer>
            <Spacer mb="32px">
              <HeatTile />
            </Spacer>
            <Spacer mb="32px">{/* <ElectrolysersTile /> */}</Spacer>
            <Spacer>
              <HydrogenNeeds />
            </Spacer>
          </div>
        </ColumnWrapper>
      </PageCnt>
    </WithSidebar>
  );
};
