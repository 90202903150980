import { getYearHours } from 'services/consts/months';
import { ObliczeniaStateSlice } from '../obliczeniaSlice';
import { KosztyCalculations, GenericArgs } from './types';

export const calcKoszty = (state: ObliczeniaStateSlice): KosztyCalculations => {
  const {
    calculations: { wiatr_lad_per_year, wiatr_na_morzu_per_year, PV_per_year, atom_per_year },
    inputs: { wiatr_lad_zainstalowano, wiatr_offshore_zainstalowano, PV_zainstalowano, atom_zainstalowano },
  } = state.produkcjaEnergiiElektrycznej;
  const {
    koszt_mld_per_GW,
    aktualna_infrastruktura,
    nie_licz_kosztów_istniejącej_infrastruktury,
    O_M_paliwo,
    O_M_utrzymanie,
    stopa_dyskonta,
  } = state.koszty;
  const {
    calculations: {
      źródła_dyspozycyjne_per_day,
      źródło_dyspozycyjne_max_moc,
      wykorzystanie_PV_per_year,
      wykorzystanie_atomu_per_year,
      wykorzystanie_wiatru_na_ladzie_per_year,
      wykorzystanie_wiatru_na_morzu_per_year,
      magazyny_ciepla_domowe,
      magazyny_ciepla_duze,
      źródło_dyspozycyjne_per_year_ze_stratami,
    },
    inputs: {
      moc_elektrolizerow,
      baterie_litowe_stacjonarne,
      elektrownie_szczytowo_pompowe,
      pojemnosc_magazynu_ciepla_w_przemysle,
    },
    cieplo: {
      calculations: { moc_pomp_ciepla_gruntowych, moc_pomp_ciepla_powietrznych },
    },
  } = state.obliczenia;

  const getCurrentInstalled = (ile_zainstalowano: number, jaka_jest_aktualna_infrastruktura: number) => {
    if (nie_licz_kosztów_istniejącej_infrastruktury) {
      return Math.max(ile_zainstalowano - jaka_jest_aktualna_infrastruktura, 0);
    }
    return ile_zainstalowano;
  };

  const WindArgs: GenericArgs = {
    czas_do_uruchomienia: 2,
    czas_działania: 30,
    koszt_inwestycyjny: koszt_mld_per_GW.wiatr_na_ladzie,
    O_M_utrzymanie: O_M_utrzymanie.wiatr_na_ladzie,
    O_M_paliwo: O_M_paliwo.wiatr_na_ladzie,
    koszt_zastąpienia: 0.6,
    procent_maks_mocy: 0.37,
    max_TWH_rocznie: 3.504,
    prosty_koszt: 1.712,
    wyprodukowano_energii_elektrycznej_TWH: wiatr_lad_per_year / 1000,
    nowa_moc_zainstalowana: getCurrentInstalled(wiatr_lad_zainstalowano, aktualna_infrastruktura.wiatr_na_ladzie),
    moc_zainstalowana: wiatr_lad_zainstalowano,
  };

  const WindOffShoreArgs: GenericArgs = {
    czas_do_uruchomienia: 4,
    czas_działania: 30,
    koszt_inwestycyjny: koszt_mld_per_GW.wiatr_na_morzu,
    O_M_utrzymanie: O_M_utrzymanie.wiatr_na_morzu,
    O_M_paliwo: O_M_paliwo.wiatr_na_morzu,
    koszt_zastąpienia: 0.8,
    procent_maks_mocy: 0.51,
    max_TWH_rocznie: 4.5552,
    prosty_koszt: 2.74,
    wyprodukowano_energii_elektrycznej_TWH: wiatr_na_morzu_per_year / 1000,
    nowa_moc_zainstalowana: getCurrentInstalled(wiatr_offshore_zainstalowano, aktualna_infrastruktura.wiatr_na_morzu),
    moc_zainstalowana: wiatr_offshore_zainstalowano,
  };

  const PhotovoltaicArgs: GenericArgs = {
    czas_do_uruchomienia: 1,
    czas_działania: 30,
    koszt_inwestycyjny: koszt_mld_per_GW.PV,
    O_M_utrzymanie: O_M_utrzymanie.PV,
    O_M_paliwo: O_M_paliwo.PV,
    koszt_zastąpienia: 0.6,
    procent_maks_mocy: 0.11,
    max_TWH_rocznie: 0.9636,
    prosty_koszt: 2.283,
    wyprodukowano_energii_elektrycznej_TWH: PV_per_year / 1000,
    nowa_moc_zainstalowana: getCurrentInstalled(PV_zainstalowano, aktualna_infrastruktura.PV),
    moc_zainstalowana: PV_zainstalowano,
  };

  const AtomArgs: GenericArgs = {
    czas_do_uruchomienia: 10,
    czas_działania: 70,
    koszt_inwestycyjny: koszt_mld_per_GW.atom,
    O_M_utrzymanie: O_M_utrzymanie.atom,
    O_M_paliwo: O_M_paliwo.atom,
    koszt_zastąpienia: 0.95,
    procent_maks_mocy: 0.85,
    max_TWH_rocznie: 7.446,
    prosty_koszt: 4.701,
    wyprodukowano_energii_elektrycznej_TWH: atom_per_year / 1000,
    nowa_moc_zainstalowana: getCurrentInstalled(atom_zainstalowano, aktualna_infrastruktura.atom),
    moc_zainstalowana: atom_zainstalowano,
  };

  const natural_gas_srednia = źródło_dyspozycyjne_per_year_ze_stratami / getYearHours(state.obliczenia.selectedYear);
  const natural_gas_procent_maks_mocy = natural_gas_srednia / (źródło_dyspozycyjne_max_moc || 1);

  const NaturalGasArgs: GenericArgs = {
    czas_do_uruchomienia: 3,
    czas_działania: 40,
    koszt_inwestycyjny: koszt_mld_per_GW.elektrownie_gazowe,
    O_M_utrzymanie: O_M_utrzymanie.elektrownie_gazowe,
    O_M_paliwo: O_M_paliwo.elektrownie_gazowe,
    koszt_zastąpienia: 0.8,
    procent_maks_mocy: natural_gas_procent_maks_mocy,
    max_TWH_rocznie: 0.1889616,
    prosty_koszt: 0.402,
    wyprodukowano_energii_elektrycznej_TWH: źródło_dyspozycyjne_max_moc / 1000,
    nowa_moc_zainstalowana: getCurrentInstalled(
      źródło_dyspozycyjne_max_moc,
      aktualna_infrastruktura.elektrownie_gazowe,
    ),
    moc_zainstalowana: aktualna_infrastruktura.elektrownie_gazowe,
  };

  const HydrogenArgs: GenericArgs = {
    czas_do_uruchomienia: 1,
    czas_działania: 25,
    koszt_inwestycyjny: koszt_mld_per_GW.elektrolizery,
    O_M_utrzymanie: O_M_utrzymanie.elektrolizery,
    O_M_paliwo: O_M_paliwo.elektrolizery,
    koszt_zastąpienia: 0.5,
    procent_maks_mocy: 0,
    max_TWH_rocznie: 0,
    prosty_koszt: 0,
    wyprodukowano_energii_elektrycznej_TWH: 0,
    nowa_moc_zainstalowana: getCurrentInstalled(moc_elektrolizerow, aktualna_infrastruktura.elektrolizery),
    moc_zainstalowana: moc_elektrolizerow,
  };

  const LithiumBatteriesArgs: GenericArgs = {
    czas_do_uruchomienia: 1,
    czas_działania: 20,
    koszt_inwestycyjny: koszt_mld_per_GW.baterie_litowe_stacjonarne,
    O_M_utrzymanie: O_M_utrzymanie.baterie_litowe_stacjonarne,
    O_M_paliwo: O_M_paliwo.baterie_litowe_stacjonarne,
    koszt_zastąpienia: 1,
    procent_maks_mocy: 0,
    max_TWH_rocznie: 0,
    prosty_koszt: 0,
    wyprodukowano_energii_elektrycznej_TWH: 0,
    nowa_moc_zainstalowana: getCurrentInstalled(
      baterie_litowe_stacjonarne,
      aktualna_infrastruktura.baterie_litowe_stacjonarne,
    ),
    moc_zainstalowana: baterie_litowe_stacjonarne,
  };

  const PumpedStoragePowerPlantsArgs: GenericArgs = {
    czas_do_uruchomienia: 10,
    czas_działania: 100,
    koszt_inwestycyjny: koszt_mld_per_GW.elektrownie_szczytowo_pompowe,
    O_M_utrzymanie: O_M_utrzymanie.elektrownie_szczytowo_pompowe,
    O_M_paliwo: O_M_paliwo.elektrownie_szczytowo_pompowe,
    koszt_zastąpienia: 0,
    procent_maks_mocy: 0,
    max_TWH_rocznie: 0,
    prosty_koszt: 0,
    wyprodukowano_energii_elektrycznej_TWH: 0,
    nowa_moc_zainstalowana: getCurrentInstalled(
      elektrownie_szczytowo_pompowe,
      aktualna_infrastruktura.elektrownie_szczytowo_pompowe,
    ),
    moc_zainstalowana: elektrownie_szczytowo_pompowe,
  };

  const PompyCieplaPowietrzneArgs: GenericArgs = {
    czas_do_uruchomienia: 1,
    czas_działania: 25,
    koszt_inwestycyjny: koszt_mld_per_GW.pompy_ciepla_powietrzne,
    O_M_utrzymanie: O_M_utrzymanie.pompy_ciepla_powietrzne,
    O_M_paliwo: O_M_paliwo.pompy_ciepla_powietrzne,
    koszt_zastąpienia: 0,
    procent_maks_mocy: 0,
    max_TWH_rocznie: 0,
    prosty_koszt: 0,
    wyprodukowano_energii_elektrycznej_TWH: 0,
    nowa_moc_zainstalowana: getCurrentInstalled(
      moc_pomp_ciepla_powietrznych,
      aktualna_infrastruktura.pompy_ciepla_powietrzne,
    ),
    moc_zainstalowana: moc_pomp_ciepla_powietrznych,
  };

  const PompyCieplaGruntoweArgs: GenericArgs = {
    czas_do_uruchomienia: 1,
    czas_działania: 25,
    koszt_inwestycyjny: koszt_mld_per_GW.pompy_ciepla_gruntowe,
    O_M_utrzymanie: O_M_utrzymanie.pompy_ciepla_gruntowe,
    O_M_paliwo: O_M_paliwo.pompy_ciepla_gruntowe,
    koszt_zastąpienia: 0,
    procent_maks_mocy: 0,
    max_TWH_rocznie: 0,
    prosty_koszt: 0,
    wyprodukowano_energii_elektrycznej_TWH: 0,
    nowa_moc_zainstalowana: getCurrentInstalled(
      moc_pomp_ciepla_gruntowych,
      aktualna_infrastruktura.pompy_ciepla_gruntowe,
    ),
    moc_zainstalowana: moc_pomp_ciepla_gruntowych,
  };

  const resultObj = {
    wiatrWydatki: 0,
    wiatrEnergia: 0,
    wiatrNaMorzuWydatki: 0,
    wiatrNaMorzuEnergia: 0,
    fotowoltaikaWydatki: 0,
    fotowoltaikaEnergia: 0,
    atomWydatki: 0,
    atomEnergia: 0,
    gazZiemnyWydatki: 0,
    gazZiemnyEnergia: 0,
    wodórWydatki: 0,
    pompyCieplaGruntoweWydatki: 0,
    pompyCieplaPowietrzneWydatki: 0,
  };

  const procentowe_wykorzystanie_wiatru_na_ladzie =
    wiatr_lad_per_year > 0 ? wykorzystanie_wiatru_na_ladzie_per_year / wiatr_lad_per_year : 0;
  const procentowe_wykorzystanie_wiatru_na_morzu =
    wiatr_na_morzu_per_year > 0 ? wykorzystanie_wiatru_na_morzu_per_year / wiatr_na_morzu_per_year : 0;
  const procentowe_wykorzystanie_PV = PV_per_year > 0 ? wykorzystanie_PV_per_year / PV_per_year : 0;
  const procentowe_wykorzystanie_atomu = atom_per_year > 0 ? wykorzystanie_atomu_per_year / atom_per_year : 0;
  const procentowe_wykorzystanie_gazu_ziemnego = 1;

  const baterieLitoweKoszt = LithiumBatteriesArgs.nowa_moc_zainstalowana * koszt_mld_per_GW.baterie_litowe_stacjonarne;

  let kalkulacja_stopa_dyskonta = 1;
  for (let index = 1; index < 71; index++) {
    const obliczWydatkiZMoca = (genericArg: GenericArgs, aktulana_stopa_dyskonta: number) => {
      if (index > genericArg.czas_do_uruchomienia + genericArg.czas_działania) {
        return 0;
      }
      if (index <= genericArg.czas_do_uruchomienia) {
        return (genericArg.koszt_inwestycyjny / genericArg.czas_do_uruchomienia) * aktulana_stopa_dyskonta;
      }
      return (genericArg.O_M_utrzymanie + genericArg.max_TWH_rocznie * genericArg.O_M_paliwo) * aktulana_stopa_dyskonta;
    };

    const obliczEnergię = (
      genericArg: GenericArgs,
      aktulana_stopa_dyskonta: number,
      wspolczynnik_wykorzystania: number,
    ) => {
      if (
        genericArg.czas_do_uruchomienia >= index ||
        index > genericArg.czas_do_uruchomienia + genericArg.czas_działania
      ) {
        return 0;
      }

      return genericArg.max_TWH_rocznie * aktulana_stopa_dyskonta * wspolczynnik_wykorzystania;
    };
    /** -------------------- WIATR KOSZTY----------------------------- */
    resultObj.wiatrWydatki += obliczWydatkiZMoca(WindArgs, kalkulacja_stopa_dyskonta);
    // ENERGIA WIATR
    resultObj.wiatrEnergia += obliczEnergię(
      WindArgs,
      kalkulacja_stopa_dyskonta,
      procentowe_wykorzystanie_wiatru_na_ladzie,
    );

    /** -------------------- WIATR KOSZTY KONIEC----------------------------- */

    /** -------------------- WIATR NA MORZU KOSZTY----------------------------- */

    // WYDATKI Wiatr na morzu
    const calcWiatrNaMorzuWydatki = (aktulana_stopa_dyskonta: number) => {
      if (index > WindOffShoreArgs.czas_do_uruchomienia + WindOffShoreArgs.czas_działania) {
        return 0;
      }
      if (index <= WindOffShoreArgs.czas_do_uruchomienia) {
        return (WindOffShoreArgs.koszt_inwestycyjny / WindOffShoreArgs.czas_do_uruchomienia) * aktulana_stopa_dyskonta;
      }
      return (
        (WindOffShoreArgs.O_M_utrzymanie + WindOffShoreArgs.max_TWH_rocznie * WindOffShoreArgs.O_M_paliwo) *
        aktulana_stopa_dyskonta
      );
    };
    resultObj.wiatrNaMorzuWydatki += calcWiatrNaMorzuWydatki(kalkulacja_stopa_dyskonta);
    // ENERGIA Wiatr na morzu
    resultObj.wiatrNaMorzuEnergia += obliczEnergię(
      WindOffShoreArgs,
      kalkulacja_stopa_dyskonta,
      procentowe_wykorzystanie_wiatru_na_morzu,
    );

    /** -------------------- WIATR NA MORZU KOSZTY KONIEC----------------------------- */

    /** -------------------- FOTOWOLTAIKA KOSZTY----------------------------- */
    const calcWydatkiPV = (aktulana_stopa_dyskonta: number) => {
      if (index > PhotovoltaicArgs.czas_do_uruchomienia + PhotovoltaicArgs.czas_działania) {
        return 0;
      }
      if (index <= PhotovoltaicArgs.czas_do_uruchomienia) {
        return (PhotovoltaicArgs.koszt_inwestycyjny / PhotovoltaicArgs.czas_do_uruchomienia) * aktulana_stopa_dyskonta;
      }

      return aktulana_stopa_dyskonta * PhotovoltaicArgs.O_M_utrzymanie;
    };
    // WYDATKI Fotowoltaika

    resultObj.fotowoltaikaWydatki += calcWydatkiPV(kalkulacja_stopa_dyskonta);

    const calcPVEnergia = (aktulana_stopa_dyskonta: number) => {
      if (
        PhotovoltaicArgs.czas_do_uruchomienia >= index ||
        index > PhotovoltaicArgs.czas_do_uruchomienia + PhotovoltaicArgs.czas_działania
      ) {
        return 0;
      }

      return PhotovoltaicArgs.max_TWH_rocznie * aktulana_stopa_dyskonta * procentowe_wykorzystanie_PV;
    };

    resultObj.fotowoltaikaEnergia += calcPVEnergia(kalkulacja_stopa_dyskonta);
    /** -------------------- FOTOWOLTAIKA KOSZTY KONIEC----------------------------- */

    /** -------------------- ATOM KOSZTY ----------------------------- */
    //WYDATKI Atom
    resultObj.atomWydatki += obliczWydatkiZMoca(AtomArgs, kalkulacja_stopa_dyskonta);

    // ENERGIA Atom
    resultObj.atomEnergia += obliczEnergię(AtomArgs, kalkulacja_stopa_dyskonta, procentowe_wykorzystanie_atomu);
    /** -------------------- ATOM KOSZTY KONIEC----------------------------- */

    /** -------------------- Gaz ziemny KOSZTY ----------------------------- */
    const calcGazZiemnyEnergia = (aktulana_stopa_dyskonta: number) => {
      if (
        NaturalGasArgs.czas_do_uruchomienia >= index ||
        index > NaturalGasArgs.czas_do_uruchomienia + NaturalGasArgs.czas_działania
      ) {
        return 0;
      }

      return NaturalGasArgs.procent_maks_mocy * aktulana_stopa_dyskonta * procentowe_wykorzystanie_gazu_ziemnego;
    };
    const energiaGazZiemny = calcGazZiemnyEnergia(kalkulacja_stopa_dyskonta);
    resultObj.gazZiemnyEnergia += energiaGazZiemny;

    const calcGazZiemnyWydatki = (aktulana_stopa_dyskonta: number) => {
      if (index > NaturalGasArgs.czas_do_uruchomienia + NaturalGasArgs.czas_działania) {
        return 0;
      }
      if (index <= NaturalGasArgs.czas_do_uruchomienia) {
        return (NaturalGasArgs.koszt_inwestycyjny / NaturalGasArgs.czas_do_uruchomienia) * aktulana_stopa_dyskonta;
      }

      return NaturalGasArgs.O_M_utrzymanie + energiaGazZiemny * NaturalGasArgs.O_M_paliwo;
    };

    resultObj.gazZiemnyWydatki += calcGazZiemnyWydatki(kalkulacja_stopa_dyskonta);

    /** -------------------- Gaz ziemny KOSZTY KONIEC----------------------------- */

    const obliczWydatki = (genericArg: GenericArgs, aktulana_stopa_dyskonta: number) => {
      if (index > genericArg.czas_do_uruchomienia + genericArg.czas_działania) {
        return 0;
      }
      if (index <= genericArg.czas_do_uruchomienia) {
        return (genericArg.koszt_inwestycyjny / genericArg.czas_do_uruchomienia) * aktulana_stopa_dyskonta;
      }
      return genericArg.koszt_inwestycyjny * genericArg.O_M_utrzymanie * aktulana_stopa_dyskonta;
    };

    resultObj.wodórWydatki += obliczWydatki(HydrogenArgs, kalkulacja_stopa_dyskonta);
    resultObj.pompyCieplaPowietrzneWydatki += obliczWydatki(PompyCieplaPowietrzneArgs, kalkulacja_stopa_dyskonta);
    resultObj.pompyCieplaGruntoweWydatki += obliczWydatki(PompyCieplaGruntoweArgs, kalkulacja_stopa_dyskonta);

    kalkulacja_stopa_dyskonta = kalkulacja_stopa_dyskonta / (1 + stopa_dyskonta);
  }

  const magazynCiepłaDużyNowaMoc = getCurrentInstalled(
    magazyny_ciepla_duze,
    aktualna_infrastruktura.magazyny_ciepla_duze,
  );
  const magazynCiepłaDomoweNowaMoc = getCurrentInstalled(
    magazyny_ciepla_domowe,
    aktualna_infrastruktura.magazyny_ciepla_domowe,
  );
  const magazynCiepłaPrzemysloweMoc = getCurrentInstalled(
    pojemnosc_magazynu_ciepla_w_przemysle,
    aktualna_infrastruktura.magazyny_ciepla_przemyslowe,
  );

  // obliczenia wydatków
  const wiatrWydatki = resultObj.wiatrWydatki * WindArgs.nowa_moc_zainstalowana;
  const wiatrNaMorzuWydatki = resultObj.wiatrNaMorzuWydatki * WindOffShoreArgs.nowa_moc_zainstalowana;
  const atomWydatki = resultObj.atomWydatki * AtomArgs.nowa_moc_zainstalowana;
  const fotowoltaikaWydatki = resultObj.fotowoltaikaWydatki * PhotovoltaicArgs.nowa_moc_zainstalowana;
  const gazZiemnyWydatki = resultObj.gazZiemnyWydatki * NaturalGasArgs.nowa_moc_zainstalowana;
  const wodórWydatki = resultObj.wodórWydatki * HydrogenArgs.nowa_moc_zainstalowana;
  const magazynCiepłaDużyWydatki = magazynCiepłaDużyNowaMoc * koszt_mld_per_GW.magazyny_ciepla_duze;
  const magazynCiepłaDomoweWydatki = magazynCiepłaDomoweNowaMoc * koszt_mld_per_GW.magazyny_ciepla_domowe;
  const pompyCieplaGruntoweWydatki =
    resultObj.pompyCieplaGruntoweWydatki * PompyCieplaGruntoweArgs.nowa_moc_zainstalowana;
  const pompyCieplaPowietrzneWydatki =
    resultObj.pompyCieplaPowietrzneWydatki * PompyCieplaPowietrzneArgs.nowa_moc_zainstalowana;
  const baterieLitoweWydatki =
    LithiumBatteriesArgs.nowa_moc_zainstalowana * koszt_mld_per_GW.baterie_litowe_stacjonarne;
  const elektrownieSzczytowoPompoweWydatki =
    PumpedStoragePowerPlantsArgs.nowa_moc_zainstalowana * koszt_mld_per_GW.elektrownie_szczytowo_pompowe;

  const sumaWydatków =
    wiatrWydatki +
    wiatrNaMorzuWydatki +
    atomWydatki +
    fotowoltaikaWydatki +
    gazZiemnyWydatki +
    wodórWydatki +
    magazynCiepłaDużyWydatki +
    magazynCiepłaDomoweWydatki +
    pompyCieplaGruntoweWydatki +
    pompyCieplaPowietrzneWydatki +
    elektrownieSzczytowoPompoweWydatki +
    baterieLitoweWydatki;

  // Obliczenia energii
  const wiatrEnergia = resultObj.wiatrEnergia * wiatr_lad_zainstalowano;
  const wiatrNaMorzuEnergia = resultObj.wiatrNaMorzuEnergia * wiatr_offshore_zainstalowano;
  const atomEnergia = resultObj.atomEnergia * atom_zainstalowano;
  const fotowoltaikaEnergia = resultObj.fotowoltaikaEnergia * PV_zainstalowano;
  const gazZiemnyEnergia = resultObj.gazZiemnyEnergia * źródło_dyspozycyjne_max_moc;

  const sumaEnergii = wiatrEnergia + wiatrNaMorzuEnergia + atomEnergia + fotowoltaikaEnergia + gazZiemnyEnergia;

  const LCOE = (sumaWydatków / sumaEnergii) * 1000;

  const wiatrKoszt = WindArgs.nowa_moc_zainstalowana * koszt_mld_per_GW.wiatr_na_ladzie;
  const wiatrNaMorzuKoszt = WindOffShoreArgs.nowa_moc_zainstalowana * koszt_mld_per_GW.wiatr_na_morzu;
  const fotowoltaikaKoszt = PhotovoltaicArgs.nowa_moc_zainstalowana * koszt_mld_per_GW.PV;
  const atomKoszt = AtomArgs.nowa_moc_zainstalowana * koszt_mld_per_GW.atom;
  const gazZiemnyKoszt = NaturalGasArgs.nowa_moc_zainstalowana * koszt_mld_per_GW.elektrownie_gazowe;

  const elektrownieSzczytowoPompoweKoszt =
    PumpedStoragePowerPlantsArgs.nowa_moc_zainstalowana * koszt_mld_per_GW.elektrownie_szczytowo_pompowe;
  const wodórKoszt = HydrogenArgs.nowa_moc_zainstalowana * koszt_mld_per_GW.elektrolizery;

  const magazynCiepłaDużyKoszt = magazynCiepłaDużyNowaMoc * koszt_mld_per_GW.magazyny_ciepla_duze;

  const magazynCiepłaDomoweKoszt = magazynCiepłaDomoweNowaMoc * koszt_mld_per_GW.magazyny_ciepla_domowe;

  const pompyCieplaGruntoweKoszt =
    PompyCieplaGruntoweArgs.nowa_moc_zainstalowana * koszt_mld_per_GW.pompy_ciepla_gruntowe;
  const pompyCieplaPowietrzneKoszt =
    PompyCieplaPowietrzneArgs.nowa_moc_zainstalowana * koszt_mld_per_GW.pompy_ciepla_powietrzne;
  const magazynCiepłaPrzemysloweKoszt = magazynCiepłaPrzemysloweMoc * koszt_mld_per_GW.magazyny_ciepla_przemyslowe;
  const łącznyKosztBudowyInfrastruktury =
    wiatrKoszt +
    wiatrNaMorzuKoszt +
    fotowoltaikaKoszt +
    atomKoszt +
    gazZiemnyKoszt +
    baterieLitoweKoszt +
    elektrownieSzczytowoPompoweKoszt +
    wodórKoszt +
    magazynCiepłaDużyKoszt +
    magazynCiepłaDomoweKoszt +
    pompyCieplaPowietrzneKoszt +
    pompyCieplaGruntoweKoszt +
    magazynCiepłaPrzemysloweKoszt;

  return {
    LCOE,
    łącznyKosztBudowyInfrastruktury,
    wiatr: {
      nowa_moc_zainstalowana: WindArgs.nowa_moc_zainstalowana,
      koszt: wiatrKoszt,
      jednostkoweLCOE: (wiatrWydatki / wiatrEnergia) * 1000,
    },
    wiatrNaMorzu: {
      nowa_moc_zainstalowana: WindOffShoreArgs.nowa_moc_zainstalowana,
      koszt: wiatrNaMorzuKoszt,
      jednostkoweLCOE: (wiatrNaMorzuWydatki / wiatrNaMorzuEnergia) * 1000,
    },
    fotowoltaika: {
      nowa_moc_zainstalowana: PhotovoltaicArgs.nowa_moc_zainstalowana,
      koszt: fotowoltaikaKoszt,
      jednostkoweLCOE: (fotowoltaikaWydatki / fotowoltaikaEnergia) * 1000,
    },
    atom: {
      nowa_moc_zainstalowana: AtomArgs.nowa_moc_zainstalowana,
      koszt: atomKoszt,
      jednostkoweLCOE: (atomWydatki / atomEnergia) * 1000,
    },
    gazZiemny: {
      nowa_moc_zainstalowana: NaturalGasArgs.nowa_moc_zainstalowana,
      koszt: gazZiemnyKoszt,
      jednostkoweLCOE: (gazZiemnyWydatki / gazZiemnyEnergia) * 1000,
    },
    baterieLitowe: {
      nowa_moc_zainstalowana: LithiumBatteriesArgs.nowa_moc_zainstalowana,
      koszt: baterieLitoweKoszt,
    },
    elektrownieSzczytowoPompowe: {
      nowa_moc_zainstalowana: PumpedStoragePowerPlantsArgs.nowa_moc_zainstalowana,
      koszt: elektrownieSzczytowoPompoweKoszt,
    },
    magazynCiepłaDuży: {
      nowa_moc_zainstalowana: magazynCiepłaDużyNowaMoc,
      koszt: magazynCiepłaDużyKoszt,
    },
    magazynCiepłaDomowe: {
      nowa_moc_zainstalowana: magazynCiepłaDomoweNowaMoc,
      koszt: magazynCiepłaDomoweKoszt,
    },
    wodór: {
      nowa_moc_zainstalowana: HydrogenArgs.nowa_moc_zainstalowana,
      koszt: wodórKoszt,
    },
    pompyCieplaGruntowe: {
      nowa_moc_zainstalowana: PompyCieplaGruntoweArgs.nowa_moc_zainstalowana,
      koszt: pompyCieplaGruntoweKoszt,
    },
    pompyCieplaPowietrzne: {
      nowa_moc_zainstalowana: PompyCieplaPowietrzneArgs.nowa_moc_zainstalowana,
      koszt: pompyCieplaPowietrzneKoszt,
    },
    magazynCiepłaPrzemyslowy: {
      nowa_moc_zainstalowana: magazynCiepłaPrzemysloweMoc,
      koszt: magazynCiepłaPrzemysloweKoszt,
    },
  };
};
