import k from './../../i18n/keys';
import React, { memo } from 'react';
import { SAbout, SActionCnt, SLanguageSwitcherCnt, SLink, SLogoCnt, SNav, SNavCnt, STitle } from './styles';
import { ReactComponent as Logo } from 'assets/logo-main-page.svg';
import { ReactComponent as EnLogo } from 'assets/logo-main-page-en.svg';
import { Button } from 'components/Button/Button';
import { URL } from 'services/consts/routes';
import { LanguageSwitcher } from 'components/LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { useCurrentLang } from 'services/configuration/i18n/utils';

export const MainPageHeader = memo(() => {
  const { t } = useTranslation();
  const lang = useCurrentLang();
  return (
    <SNav role="navigation">
      <SNavCnt>
        <SLogoCnt>{lang === 'pl' ? <Logo /> : <EnLogo />}</SLogoCnt>

        <SActionCnt>
          <SAbout to={URL.ABOUT}>{t(k.ABOUT_PROJECT)}</SAbout>
          <SLink width="186px" dense to={URL.SIMULATOR} variant="contained">
            {t(k.OPEN_SIMULATOR)}
          </SLink>
          <SLanguageSwitcherCnt>
            <LanguageSwitcher />
          </SLanguageSwitcherCnt>
        </SActionCnt>
      </SNavCnt>
    </SNav>
  );
});
