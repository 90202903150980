import { ValueOf } from 'services/types/Values';
export const SearchPrams = {
  scenario: 'scenario',
  koszty: 'koszty',
  obliczenia: 'obliczenia',
  produkcjaEnergii: 'produkcjaEnergii',
  wodor: 'wodor',
} as const;

export const deleteAllSearchParams = (searchParams: URLSearchParams) => {
  Object.values(SearchPrams).forEach(param => {
    searchParams.delete(param);
  });
  return searchParams;
};

export const deleteSearchParams = (searchParams: URLSearchParams, param: ValueOf<typeof SearchPrams>) => {
  searchParams.delete(param);
  return searchParams;
};

export const addSearchParams = (searchParams: URLSearchParams, param: ValueOf<typeof SearchPrams>, value: string) => {
  searchParams.append(param, value);
  return searchParams;
};

export const getSearchParam = (param: ValueOf<typeof SearchPrams>) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param);
};
