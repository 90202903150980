import { Button } from 'components/Button/Button';
import keys from 'i18n/keys';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { heatExport } from './utils/heatExport';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';
import { getCieploConsts, getObliczenia, getYearsData } from 'services/slices/obliczeniaSlice/selectors';
import { setImportedHeatFromExcel, setSelectedYear } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { data } from 'data/2023';
import { heatImport } from './utils/heatImport';
import { ExcelToJSON } from 'features/DataFeatures/EnergyExportCol/utils/ExcelToJSON';
import { HeatExport } from './types';
import { ExportCard, SErrorWrapper, SSuccessWrapper } from '../dataFeatures.styles';
import { ReactComponent as DeleteIcon } from 'assets/new/delete_sweep.svg';

export const HeatExportCol = () => {
  const { selectedYear } = useAppSelector(getObliczenia);
  const { srednia_temp } = useAppSelector(getCieploConsts);
  const yearsData = useAppSelector(getYearsData);
  const [isHeatImportError, toggleHeatImportError] = useState(false);
  const [isHeatImportSuccess, toggleHeatImportSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const heatImportRef = useRef<HTMLInputElement>(null);
  const timeoutRef = useRef<any>(null);
  const { t } = useTranslation();
  const onHeatExport = () => {
    heatExport({ t, selectedYear, data: srednia_temp });
  };
  const resetHeatData = () => {
    dispatch(setImportedHeatFromExcel(yearsData[selectedYear].srednia_temp));
    toggleHeatImportSuccess(true);
    timeoutRef.current = setTimeout(() => {
      clearTimeout(timeoutRef.current);
      toggleHeatImportSuccess(false);
    }, 5000);
  };
  const openHeatFileLoader = () => {
    heatImportRef.current?.click();
  };

  const onHeatImport = async () => {
    const file = heatImportRef.current?.files?.[0];
    if (file) {
      try {
        const jsonResult = await ExcelToJSON<HeatExport>(file);
        const payload = heatImport(jsonResult, t);
        toggleHeatImportSuccess(true);
        dispatch(setImportedHeatFromExcel(payload));
      } catch (err) {
        console.log({ err });
        toggleHeatImportError(true);
        timeoutRef.current = setTimeout(() => {
          clearTimeout(timeoutRef.current);
          toggleHeatImportError(false);
        }, 5000);
      }
    }
  };
  return (
    <ExportCard>
      <h3 className="title">{t(keys.EXPORT_HEAT_TITLE)}</h3>
      <span>{t(keys.ADD_DATA_FROM_FILE_TEXT)}</span>
      <div className="buttons-cnt">
        <Button onClick={onHeatExport}>{t(keys.EXPORT_DATA)}</Button>
        <Button variant="outlined" onClick={openHeatFileLoader}>
          {t(keys.IMPORT_DATA)}
        </Button>
        <Button variant="text" onClick={resetHeatData} endIcon={<DeleteIcon />}>
          {t(keys.RESET_DATA)}
        </Button>
        <input
          ref={heatImportRef}
          type="file"
          hidden
          onChange={onHeatImport}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </div>
      {isHeatImportError && <SErrorWrapper>{t(keys.IMPORT_DATA_ERROR)}</SErrorWrapper>}
      {isHeatImportSuccess && <SSuccessWrapper>{t(keys.IMPORT_DATA_SUCCESS)}</SSuccessWrapper>}
    </ExportCard>
  );
};
