import React, { memo, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import {
  SBackIcon,
  SCloseBtn,
  SContent,
  SModal,
  SOverlay,
  SPreviewModal,
  STitle,
  StickyBtn,
  SScenarioTile,
  ScenarioTitle,
  SSelectedScenario,
  ScenarioDesc,
} from './styles';

import { EditorText } from 'components/EditorText';
import { Button } from 'components/Button/Button';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';
import keys from 'i18n/keys';
import { useTranslation } from 'react-i18next';
import { updateScenario } from 'services/slices/periodSlice';
import FocusTrap from 'focus-trap-react';
import { ScenarioData } from 'services/slices/obliczeniaSlice/types';
import { AVAILABLE_SCENARIOS } from 'services/slices/obliczeniaSlice/scenarios';
import { setScenario } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { useSearchParams } from 'react-router-dom';
import { addSearchParams, deleteAllSearchParams } from 'App/AppParams/utils';
import { Drawer } from 'components/Drawer/Drawer';

const modalsContainer = document.getElementById('modals') as HTMLElement;

type AvailableScenariosProps = {
  open: boolean;
  onClose: (arg: boolean) => void;
};

export const AvailableScenarios = memo(({ open, onClose }: AvailableScenariosProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedScenario, setSelectedScenario] = useState<ScenarioData | null>(null);

  const { currentScenario } = useAppSelector(state => state.period);
  const state = useRef({ open, selectedScenario });
  const dispatch = useAppDispatch();
  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (open) {
      document.querySelector('body')?.classList?.add('o-hidden');
    } else {
      document.querySelector('body')?.classList?.remove('o-hidden');
    }
  }, [open]);

  useEffect(() => {
    state.current = {
      open,
      selectedScenario,
    };
  }, [open, selectedScenario]);

  useEffect(() => {
    const keyUpEvent = e => {
      if (e.keyCode === 27) {
        if (!!state.current.selectedScenario) {
          setSelectedScenario(null);
          return;
        }
        if (state.current.open) {
          onClose(false);
        }
      }
    };
    window.addEventListener('keydown', keyUpEvent);
    return () => {
      window.removeEventListener('keydown', keyUpEvent);
    };
  }, []);

  const handleClose = () => {
    onClose(false);
  };
  const handlePreviewScenarioClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedScenario(null);
  };
  const preventPropagate = (e: React.MouseEvent) => {
    e.stopPropagation();
  };
  const selectScenario = (scenario: ScenarioData) => () => {
    setSelectedScenario(scenario);
  };
  const chooseScenario = () => {
    if (!selectedScenario) {
      return;
    }
    dispatch(updateScenario(selectedScenario));
    dispatch(setScenario(selectedScenario.data));
    onClose(false);
    setSelectedScenario(null);
  };
  const { t } = useTranslation();
  return (
    <>
      <Drawer open={open} onClose={onClose}>
        <SModal open={open} onClick={handleModalClick}>
          <div>
            <SCloseBtn onClick={handleClose}>
              <SBackIcon />
              <span>{t(keys.CLOSE)}</span>
            </SCloseBtn>
          </div>
          <STitle>{t(keys.CHOOSE_SCENARIO_TITLE)}</STitle>
          <SContent>
            {AVAILABLE_SCENARIOS.map(el => (
              <SScenarioTile active={currentScenario.title === el.title} key={el.title} onClick={selectScenario(el)}>
                <div>
                  <ScenarioTitle>{t(el.label) || el.label}</ScenarioTitle>
                  <ScenarioDesc>{t(el.title) || el.title}</ScenarioDesc>
                </div>
              </SScenarioTile>
            ))}
          </SContent>
        </SModal>
      </Drawer>
      <Drawer stackedDrawer open={!!selectedScenario} onClose={() => setSelectedScenario(null)}>
        <SPreviewModal onClick={preventPropagate} open={!!selectedScenario}>
          {selectedScenario && (
            <>
              <div>
                <SCloseBtn onClick={handlePreviewScenarioClose}>
                  <SBackIcon />
                  <span>{t(keys.CLOSE)}</span>
                </SCloseBtn>
              </div>
              <STitle>{t(selectedScenario.title)}</STitle>
              <EditorText
                dangerouslySetInnerHTML={{
                  __html: t(selectedScenario.description) || selectedScenario.description,
                }}
              ></EditorText>
              <StickyBtn>
                <Button onClick={chooseScenario} fullWidth variant="contained">
                  {t(keys.CHOOSE_SCENARIO)}
                </Button>
              </StickyBtn>
            </>
          )}
        </SPreviewModal>
      </Drawer>
    </>
  );
});
