import React from 'react';
import { Costs } from 'pages/Costs/Costs';
import { Data } from 'pages/Data/Data';
import { Information } from 'pages/Information/Information';
import { RouteProps } from 'react-router-dom';
import { Main } from '../../pages/Main/Main';
import { Simulator } from '../../pages/Simulator/Simulator';
import { Contact } from 'pages/Contact/Contact';
import { MobileNotSupported } from 'features/MobileNotSupported/MobileNotSupported';
import { EditAdvancedOptions } from 'pages/EditAdvancedOptions/EditAdvancedOptions';
import { Introduction } from 'pages/Introduction/Introduction';
import { About } from 'pages/About/About';
import { AboutData } from 'pages/AboutData/AboutData';

export const URL = {
  HOME: '/',
  SIMULATOR: '/simulator',
  COSTS: '/costs',
  DATA: '/data',
  CONTACT: '/contact',
  ADVANCED_OPTIONS: '/advanced',
  INFORMATION: '/information',
  ABOUT: '/about',
  ABOUT_DATA: '/aboutData',
  INTRODUCTION: '/introduction',
} as const;

export type RouteElement = RouteProps & {
  exact?: boolean;
};

export const routes: RouteElement[] = [
  {
    path: URL.HOME,
    element: <Main />,
  },
  {
    path: URL.SIMULATOR,
    element: (
      <MobileNotSupported>
        <Simulator />
      </MobileNotSupported>
    ),
  },
  {
    path: URL.DATA,
    element: (
      <MobileNotSupported>
        <Data />
      </MobileNotSupported>
    ),
  },
  {
    path: URL.COSTS,
    element: (
      <MobileNotSupported>
        <Costs />
      </MobileNotSupported>
    ),
  },
  {
    path: URL.INFORMATION,
    element: (
      <MobileNotSupported>
        <Information />
      </MobileNotSupported>
    ),
  },
  {
    path: URL.ABOUT,
    element: (
      <MobileNotSupported>
        <About />
      </MobileNotSupported>
    ),
  },
  {
    path: URL.ABOUT_DATA,
    element: (
      <MobileNotSupported>
        <AboutData />
      </MobileNotSupported>
    ),
  },
  {
    path: URL.CONTACT,
    element: (
      <MobileNotSupported>
        <Contact />
      </MobileNotSupported>
    ),
  },
  {
    path: URL.ADVANCED_OPTIONS,
    element: (
      <MobileNotSupported>
        <EditAdvancedOptions />
      </MobileNotSupported>
    ),
  },
  {
    path: URL.INTRODUCTION,
    element: (
      <MobileNotSupported>
        <Introduction />
      </MobileNotSupported>
    ),
  },
];

export const enRoutes: RouteElement[] = routes.map(el => ({ ...el, path: `/en${el.path}` }));
export const idRoutes: RouteElement[] = routes.map(el => ({ ...el, path: `/id${el.path}` }));

export const allRutes = [...routes, ...enRoutes, ...idRoutes];
