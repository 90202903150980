import classNames from 'classnames';
import React from 'react';
import { splitByComma } from 'services/utils/splitByComma';
import { InputMask } from './styles';

type AlignColumnProps = {
  value: string | number;
};

export const AlignColumn = ({ value }: AlignColumnProps) => {
  const { firstVal, secondVal } = splitByComma(value);
  return (
    <InputMask className="dummy" error={false}>
      <div
        className={classNames('firstCol', {
          'firstCol--with-space': !secondVal,
        })}
      >
        {firstVal}
        {!!secondVal && ','}
      </div>
      <div className="secondCol">{secondVal}</div>
    </InputMask>
  );
};
