import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

const SFieldset = styled.fieldset`
  position: relative;
  margin: 0;
  margin-bottom: 16px;
  padding: 16px;
  padding-top: 0;
  border: 1px solid rgba(2, 48, 71, 0.33);
`;

const SFieldsetLegend = styled.legend`
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  padding: 4px;
  margin-left: -4px;
`;

const FiledsetLegendUnit = styled.span`
  position: absolute;
  right: 16px;
  top: -24px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  background-color: #fff;
  padding: 0 8px;
`;

type FieldsetProps = {
  legend: ReactNode;
  legendUnit?: ReactNode;
  children?: ReactNode;
};

export const Fieldset = ({ legend, legendUnit, children }: FieldsetProps) => {
  return (
    <SFieldset>
      <SFieldsetLegend>{legend}</SFieldsetLegend>
      {legendUnit && <FiledsetLegendUnit>{legendUnit}</FiledsetLegendUnit>}
      {children}
    </SFieldset>
  );
};
