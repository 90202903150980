import k from './../../i18n/keys';
import { TBody, Td, Tr } from 'components/Table/Transparent';
import React from 'react';
import { useAppSelector } from 'services/configuration/store/hooks';
import { roundNumb } from 'services/utils/roundNumb';
import { useTranslation } from 'react-i18next';
import { getKosztyCalculations } from 'services/slices/obliczeniaSlice/selectors';

export const Sum = () => {
  const {
    atom,
    baterieLitowe,
    elektrownieSzczytowoPompowe,
    fotowoltaika,
    gazZiemny,
    magazynCiepłaDomowe,
    magazynCiepłaDuży,

    wiatr,
    wiatrNaMorzu,
    wodór,
  } = useAppSelector(getKosztyCalculations);
  const sum =
    atom.koszt +
    baterieLitowe.koszt +
    elektrownieSzczytowoPompowe.koszt +
    fotowoltaika.koszt +
    gazZiemny.koszt +
    magazynCiepłaDomowe.koszt +
    magazynCiepłaDuży.koszt +
    wiatr.koszt +
    wiatrNaMorzu.koszt +
    wodór.koszt;
  const { t } = useTranslation();
  return (
    <TBody>
      <Tr cols={1}>
        <Td>{t(k.TOTAL_CONSTRUCTION_COST)}</Td>
        <Td>{roundNumb(sum)}</Td>
      </Tr>
    </TBody>
  );
};
