import React from 'react';
import { SSection, SSourceWrapper } from './styles';
import { EnergySourceTile } from './Tiles/EnergySourceTile';
import { HydrogenTile } from './Tiles/HydrogenTile';
import { StorageTile } from './Tiles/Magazynowanie/Magazynowanie';
import { ConsumptionTile } from './Tiles/ConsumptionTile/ConsumptionTile';
type SpecificInformationCardProps = {
  className?: string;
};

export const SpecificInformationCard = ({ className }: SpecificInformationCardProps) => {
  return (
    <SSection className={className}>
      <div>
        <SSourceWrapper>
          <EnergySourceTile />
          <ConsumptionTile />
          <StorageTile />
          <HydrogenTile />
        </SSourceWrapper>
      </div>
    </SSection>
  );
};
