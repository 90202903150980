/** @jsx jsx */
import { keys } from 'i18n/keys';
import { PageCard } from 'components/Card/PageCard';
import { PageCnt } from 'components/Card/PageCnt';
import { WithSidebar } from 'components/Sidebar/Sidebar';
import { SimulatorHeader } from 'components/SimulatorHeader/SimulatorHeader';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { css, jsx } from '@emotion/react';
import { useTitle } from 'react-use';
import { Button } from 'components/Button/Button';
import { Footer } from 'components/Footer/Footer';
import { ReactComponent as ExcelIcon } from 'assets/microsoft_excel.svg';
import { ReactComponent as CloseIcon } from 'assets/close_icon.svg';
import { EditorText } from 'components/EditorText';
import { TileTitle } from 'components/Titles/TileTitle';

export const Information = () => {
  const [isOverlayed, setIsOverlayed] = useState(false);
  const { t } = useTranslation();
  useTitle(t(keys.PAGE_TITLE_INFORMATION));
  useEffect(() => {
    if (isOverlayed) {
      document.querySelector('body')?.classList?.add('o-hidden');
    } else {
      document.querySelector('body')?.classList?.remove('o-hidden');
    }
  }, [isOverlayed]);

  const handleClose = () => {
    setIsOverlayed(false);
  };

  const openDocs = () => {
    setIsOverlayed(true);
  };

  return (
    <div>
      <WithSidebar>
        <PageCnt>
          <SimulatorHeader title={t(keys.PAGE_TITLE_INFORMATION)} />
          <PageCard>
            {isOverlayed && (
              <div
                onClick={handleClose}
                css={css`
                  position: fixed;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background: rgba(0, 0, 0, 0.2);
                  box-shadow: 0px 2px 8px rgba(0, 150, 93, 0.16);
                  overflow: auto;
                  padding: 100px;
                `}
              >
                <div
                  onClick={e => e.stopPropagation()}
                  css={css`
                    background-color: #fff;
                    width: 100%;
                    max-width: 950px;
                    background: #ffffff;
                    box-shadow: 0px 2px 8px rgba(0, 150, 93, 0.16);
                    position: relative;
                    margin: 0 auto;
                    padding: 32px 52px;
                  `}
                >
                  <button
                    css={css`
                      cursor: pointer;
                      margin-left: auto;
                      position: absolute;
                      right: 15px;
                      top: 15px;
                    `}
                    onClick={handleClose}
                  >
                    <CloseIcon color="#297F94" />
                  </button>
                  <Footer
                    as="div"
                    css={css`
                      margin-bottom: 50px;
                    `}
                  />
                  <div>
                    <EditorText dangerouslySetInnerHTML={{ __html: t(keys.DOCUMENTATION_OVERLAY) }} />
                  </div>
                </div>
              </div>
            )}
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 100px;
              `}
            >
              <div
                css={css`
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                `}
              >
                <div>
                  <div
                    css={css`
                      width: 480px;
                    `}
                  >
                    <TileTitle
                      css={css`
                        margin-bottom: 12px;
                      `}
                    >
                      {t(keys.INFORMATION_TITLE_1)}
                    </TileTitle>
                    <p>{t(keys.INFORMATION_TEXT_1)}</p>
                    <TileTitle
                      css={css`
                        margin-top: 32px;
                        margin-bottom: 12px;
                      `}
                    >
                      {t(keys.INFORMATION_TITLE_2)}
                    </TileTitle>
                    <p
                      css={css`
                        margin-bottom: 52px;
                      `}
                    >
                      {t(keys.INFORMATION_TEXT_2)}
                    </p>
                    <Button
                      css={css`
                        margin-bottom: 32px;
                      `}
                      width="100%"
                      variant="outlined"
                      href={t(keys.XLS_CURRENT_DOWNLOAD_PATH)}
                    >
                      <ExcelIcon
                        css={css`
                          margin-right: 10px;
                        `}
                      />
                      <span>{t(keys.DOWNLOAD_EXCEL_TEXT)}</span>
                    </Button>
                    <Button
                      css={css`
                        margin-bottom: 32px;
                      `}
                      width="100%"
                      variant="outlined"
                      href={t(keys.XLS_GEOTHERMY_DOWNLOAD_PATH)}
                    >
                      <ExcelIcon
                        css={css`
                          margin-right: 10px;
                        `}
                      />
                      <span>{t(keys.DOWNLOAD_EXCEL_TEXT_GEOTHERMY)}</span>
                    </Button>
                    <Button width="100%" variant="contained" onClick={openDocs}>
                      <span>{t(keys.DOWNLOAD_PDF_TEXT)}</span>
                    </Button>
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    height: 100%;
                    padding-left: 20px;
                  `}
                >
                  <img
                    css={css`
                      margin: auto 0;
                      max-width: 100%;
                      max-height: 100%;
                      object-fit: contain;
                    `}
                    src="/images/Device - Macbook Air.png"
                    alt="Zrzut ekranu symulatora"
                    width="607px"
                    height="375px"
                  />
                </div>
              </div>
            </div>
            <Footer
              css={css`
                max-width: 835px;
                margin: 0 auto;
              `}
            ></Footer>
          </PageCard>
        </PageCnt>
      </WithSidebar>
    </div>
  );
};
