import { useState, useEffect, useRef } from 'react';
import { useAppDispatch } from 'services/configuration/store/hooks';
import { obliczeniaSlice } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { ValueOf } from 'services/types/Values';

type Action = typeof obliczeniaSlice.actions;

type UseDispatchAction<T extends { name: string; value: number }> = {
  min?: number;
  max?: number;
  action: ValueOf<Action>;
  value: T['value'];
  name: T['name'];
  percentage?: boolean;
};
export const useDispatchAction = <T extends { name: string; value: number }>({
  min = 0,
  max: maxProp,
  action,
  value: valueProp,
  name,
  percentage = false,
}: UseDispatchAction<T>) => {
  const max = maxProp !== undefined ? maxProp : percentage ? 1 : 999;
  const [value, setValue] = useState<T['value']>(valueProp);
  const [error, setError] = useState(false);
  const timeout = useRef<any>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const onChange = (val: string) => {
    clearTimeout(timeout.current);
    const numb = +(percentage ? (Number(val) / 100).toFixed(2) : Number(val));
    setValue(numb);
    timeout.current = setTimeout(() => {
      if (numb < min) {
        dispatch(
          action({
            name: name,
            value: min,
          } as never),
        );
        setError(true);
        return;
      }
      if (numb > max) {
        dispatch(
          action({
            name: name,
            value: max,
          } as never),
        );
        setError(true);
        return;
      }
      dispatch(
        action({
          name: name,
          value: numb,
        } as never),
      );
      setError(false);
    }, 400);
  };

  return {
    value: percentage ? +(value * 100).toFixed() : value,
    onChange,
    error,
    min,
    max: percentage ? max * 100 : max,
    errorMessage: error
      ? `Minimalna wartość ${percentage ? `${min * 100}%` : min}, maksymalna wartość ${
          percentage ? `${max * 100}%` : max
        }`
      : null,
  };
};
