import { getBaseLog } from 'services/utils/getBaseLog';
import { CalcCieploReturn } from './calcCieplo';
import { ObliczeniaTypeCalculations } from '../types';

type CalcCieploPoElastycznosciArgs = {
  ciepło: CalcCieploReturn;
  wspolczynnik_elastyczności: number;
  podaż_do_zapotrzebowania: number;
  moc_pomp_ciepla: number;
  procent_podlaczenia_do_magazynow_ciepla: number;
  wspolczynnik_przy_elastycznosci: number;
  index: number;
  /** @deprecated aktualizacja obliczen dla ciepla poprzez referencje */
  returnObj: ObliczeniaTypeCalculations;
};
export const calcCieploPoElastycznosci = ({
  procent_podlaczenia_do_magazynow_ciepla,
  ciepło,
  moc_pomp_ciepla,
  podaż_do_zapotrzebowania,
  wspolczynnik_elastyczności,
  wspolczynnik_przy_elastycznosci,
  index,
  returnObj,
}: CalcCieploPoElastycznosciArgs) => {
  const dataToReturn: ObliczeniaTypeCalculations['cieplo'] = {
    CO_energia_z_pc_po_elastycznosci_suma: 0,
    CO_moc_th_pc_po_elastycznosci_suma: 0,
    CO_reszta_ciepla_z_grzalek_po_elastycznosci_suma: 0,
    CWU_dostepna_moc_el_pc_po_elastycznosci_suma: 0,
    CWU_moc_th_pc_po_elastycznosci_suma: 0,
    CWU_reszta_ciepla_z_grzalek_po_elastycznosci_suma: 0,
    CWU_cieplo_PC_suma: 0,
    CO_cieplo_PC_suma: 0,
  };

  const CO_GW_ciepla_po_elastycznosci =
    (wspolczynnik_elastyczności > 0 ? 1 + getBaseLog(podaż_do_zapotrzebowania, wspolczynnik_przy_elastycznosci) : 1) *
    ciepło.CO_ciepla_GW;
  const CO_moc_el_pc_po_elastycznosci = CO_GW_ciepla_po_elastycznosci / ciepło.CO_COP_temp;
  const CO_energia_z_pc_po_elastycznosci =
    CO_moc_el_pc_po_elastycznosci < moc_pomp_ciepla ? CO_moc_el_pc_po_elastycznosci : moc_pomp_ciepla;
  dataToReturn.CO_energia_z_pc_po_elastycznosci_suma +=
    returnObj.cieplo.CO_energia_z_pc_po_elastycznosci_suma + CO_energia_z_pc_po_elastycznosci;

  const CO_moc_th_pc_po_elastycznosci = CO_energia_z_pc_po_elastycznosci * ciepło.CO_COP_temp;
  dataToReturn.CO_moc_th_pc_po_elastycznosci_suma +=
    returnObj.cieplo.CO_moc_th_pc_po_elastycznosci_suma + CO_moc_th_pc_po_elastycznosci;

  const CO_reszta_ciepla_z_grzalek_po_elastycznosci =
    (CO_moc_el_pc_po_elastycznosci - CO_energia_z_pc_po_elastycznosci) * ciepło.CO_COP_temp;
  dataToReturn.CO_reszta_ciepla_z_grzalek_po_elastycznosci_suma +=
    returnObj.cieplo.CO_reszta_ciepla_z_grzalek_po_elastycznosci_suma + CO_reszta_ciepla_z_grzalek_po_elastycznosci;
  //CWU
  //=JEŻELI(AH$3>0;(1+LOG($AH12;AH$3));1)*W12
  const CWU_GW_ciepla_po_elastycznosci =
    (wspolczynnik_elastyczności > 0 ? 1 + getBaseLog(podaż_do_zapotrzebowania, wspolczynnik_przy_elastycznosci) : 1) *
    ciepło.CWU_GW_ciepla;

  const CWU_dostepna_moc_PC_po_elastycznosci = moc_pomp_ciepla - CO_energia_z_pc_po_elastycznosci;
  const CWU_potrzebna_moc_el_pc_po_elastycznosci = CWU_GW_ciepla_po_elastycznosci / ciepło.CWU_COP_temp;
  const CWU_dostepna_moc_el_pc_po_elastycznosci = Math.min(
    CWU_potrzebna_moc_el_pc_po_elastycznosci,
    CWU_dostepna_moc_PC_po_elastycznosci,
  );
  dataToReturn.CWU_dostepna_moc_el_pc_po_elastycznosci_suma +=
    returnObj.cieplo.CWU_dostepna_moc_el_pc_po_elastycznosci_suma + CWU_dostepna_moc_el_pc_po_elastycznosci;

  const CWU_moc_th_pc_po_elastycznosci = CWU_dostepna_moc_el_pc_po_elastycznosci * ciepło.CWU_COP_temp;
  dataToReturn.CWU_moc_th_pc_po_elastycznosci_suma +=
    returnObj.cieplo.CWU_moc_th_pc_po_elastycznosci_suma + CWU_moc_th_pc_po_elastycznosci;

  const CWU_reszta_ciepla_z_grzalek_po_elastycznosci =
    (CWU_potrzebna_moc_el_pc_po_elastycznosci - CWU_dostepna_moc_el_pc_po_elastycznosci) * ciepło.CWU_COP_temp;
  dataToReturn.CWU_reszta_ciepla_z_grzalek_po_elastycznosci_suma +=
    returnObj.cieplo.CWU_reszta_ciepla_z_grzalek_po_elastycznosci_suma + CWU_reszta_ciepla_z_grzalek_po_elastycznosci;

  //rezultaty dla samego ciepla
  const wykorzystana_moc_el_pc_po_elastycznosci =
    CO_energia_z_pc_po_elastycznosci + CWU_dostepna_moc_el_pc_po_elastycznosci;
  const dostepna_dla_magazynu_moc_el_pc_po_elastycznosci =
    (moc_pomp_ciepla - wykorzystana_moc_el_pc_po_elastycznosci) * procent_podlaczenia_do_magazynow_ciepla;
  const pobor_pradu_po_elastycznosci =
    wykorzystana_moc_el_pc_po_elastycznosci +
    CO_reszta_ciepla_z_grzalek_po_elastycznosci +
    CWU_reszta_ciepla_z_grzalek_po_elastycznosci;

  const real_COP_po_elastycznosci =
    (CO_GW_ciepla_po_elastycznosci + CWU_GW_ciepla_po_elastycznosci) / pobor_pradu_po_elastycznosci;

  const CO_cieplo_PC = CO_moc_th_pc_po_elastycznosci - CO_energia_z_pc_po_elastycznosci;
  const CWU_cieplo_PC = CWU_moc_th_pc_po_elastycznosci - CWU_dostepna_moc_el_pc_po_elastycznosci;

  dataToReturn.CWU_cieplo_PC_suma += returnObj.cieplo.CWU_cieplo_PC_suma + CWU_cieplo_PC;
  dataToReturn.CO_cieplo_PC_suma += returnObj.cieplo.CO_cieplo_PC_suma + CO_cieplo_PC;

  return { pobor_pradu_po_elastycznosci, wykorzystana_moc_el_pc_po_elastycznosci, dataToReturn };
};
