import { AvailableYear, YearData, defaultYear } from 'services/consts/availableYears';
import { data } from 'data/2023';

export const REST_CALCS_INITIAL_VALUES = {
  heatStorageAtStart: 0 as number,
  heatStoragesConnectedToNetwork: 0.7 as number,
  minimalHeatConsumptionSatisfiedWithHeatPumpAndHeaters: 0.3 as number,
  energy_demand: null,
  transportDemand: 100 as number,
  heatStorageEfficiency: 0.9 as number,
  energyStorageEfficiency: 0.85 as number,
  energyStorageAtStart: 0 as number,
  reusedHeatWasteFromPowerPlants: 0.3 as number,
  naturalGasPowerStationEfficency: 0.5 as number,
} as const;

const initalData: YearData = {
  PV: [],
  PV_wsch_zach: [],
  srednia_temp: [],
  wiatr_lad: [],
  wiatr_offshore: [],
  wiatr_lad_6MGW: [],
  zuzycie_pradu: [],
};

export const yearsData: Record<AvailableYear, YearData> = {
  2015: initalData,
  2016: initalData,
  2017: initalData,
  2018: initalData,
  2019: initalData,
  2020: initalData,
  2021: initalData,
  2022: initalData,
  2023: initalData,
  [defaultYear]: data,
} as const;
