import styled from '@emotion/styled';

export const SCnt = styled.div`
  display: flex;
  /* width: 100%; */
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const SBulltetCnt = styled.div`
  display: flex;
  align-items: center;
  &:last-of-type {
    margin-right: 0;
  }
`;

export const SBullet = styled.div<{ background: string; borderColor?: string }>`
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  background: ${({ background }) => background};
  border-radius: 50%;
  margin-right: 3px;

  ${({ borderColor }) =>
    borderColor &&
    `
    border-radius: 0;
    background: ${borderColor};
    min-height: 4px;
    height: 4px;
    width: 12px;
  `}
`;

export const SBulletText = styled.span`
  white-space: nowrap;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
`;

export const SLablel = styled.p`
  font-weight: 600;
  margin-right: 10px;
  color: #636b75;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  font-weight: 600;
`;

export const WithLabelCnt = styled.div`
  width: 100%;
`;

export const WithLabelRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
