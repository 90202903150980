import { SelectOption } from 'components/Select/types';

export enum CalcValues {
  NaMieszkańca = '1',
  Kraj = '2',
}
export const selectOptions: SelectOption[] = [
  {
    label: 'COSTS_SELECT_OPTION_1',
    value: CalcValues.NaMieszkańca,
  },
  {
    label: 'COSTS_SELECT_OPTION_2',
    value: CalcValues.Kraj,
  },
];
