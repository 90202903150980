import React, { ChangeEvent, ReactNode, useContext } from 'react';
import { OtherCnt, SChangeBtn, SCnt, SInput, SLabel, SUnit } from './styles';
import { ITooltip as ReactTooltipProps } from 'react-tooltip';
import classNames from 'classnames';
import { ReactTooltip } from 'components/Tooltip/ReactTooltip';
import { ReactComponent as PlusIcon } from 'assets/new/plus-icon.svg';
import { ReactComponent as MinusIcon } from 'assets/new/minus-icon.svg';
import { DataInputContext } from './dataInputContext';
import { Box } from 'components/common';
import { Tooltip } from 'components/Tooltip/Tooltip';
type DataInputProps = {
  id: string;
  unit?: string;
  unitColor?: string;
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
  value?: string | number;
  onChange?: (arg: string) => void;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: ReactNode;
  tooltipProps?: ReactTooltipProps;
  min?: number;
  max?: number;
  step?: number;
  onClick?: (arg: any) => void;

  label?: ReactNode;
  labelTooltipId?: string;
  labelTooltipContent?: string;

  className?: string;
  editBtn?: ReactNode;

  /** dodaje visibility hidden */
  hideControls?: boolean;

  /** nie renderuje buttonow
   * @default true
   */
  renderControls?: boolean;
};

export const DataInput = ({
  label,
  id,
  unit,
  unitColor,
  inputProps,
  value,
  onChange,
  disabled,
  error = false,
  errorMessage,
  tooltipProps,
  min = 0,
  max,
  step = 1,
  onClick,
  className,
  editBtn,
  hideControls = false,
  renderControls = true,
  labelTooltipId,
  labelTooltipContent,
}: DataInputProps) => {
  const inputWidth = useContext(DataInputContext);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  const onAdd = () => {
    const newVal = +(value as string | number) + 1;
    if (max && newVal > max) {
      return;
    }
    onChange?.(newVal.toString());
  };

  const onMinus = () => {
    const newVal = +(value as string | number) - 1;
    if (newVal < min) {
      return;
    }
    onChange?.(newVal.toString());
  };

  return (
    <SCnt onClick={onClick} className={classNames('data-input', className)}>
      {label && (
        <SLabel htmlFor={id}>
          {labelTooltipId && labelTooltipContent && <Tooltip id={labelTooltipId} content={labelTooltipContent} />}
          <span>{label}</span>
        </SLabel>
      )}
      {editBtn}
      <OtherCnt inputWidth={inputWidth}>
        {renderControls && (
          <SChangeBtn
            hideControls={hideControls}
            className="change-btn"
            disabled={disabled || Number(value) === min}
            onClick={onMinus}
          >
            <MinusIcon />
          </SChangeBtn>
        )}
        <SInput
          id={id}
          name={id}
          value={value?.toString()}
          onChange={handleChange}
          disabled={disabled || hideControls}
          {...inputProps}
          className="input-field"
          error={error}
          type="number"
          min={min}
          max={max}
          step={step}
          data-tooltip-id={`${id}-input-tooltip`}
        />
        {renderControls && (
          <SChangeBtn
            hideControls={hideControls}
            className="change-btn"
            disabled={disabled || Number(value) === max}
            onClick={onAdd}
          >
            <PlusIcon />
          </SChangeBtn>
        )}
        {unit && <SUnit className="input-unit">{unit}</SUnit>}
        {errorMessage && (
          <ReactTooltip place="top" {...tooltipProps} id={`${id}-input-tooltip`}>
            {errorMessage}
          </ReactTooltip>
        )}
      </OtherCnt>
    </SCnt>
  );
};
