/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { PageCard } from 'components/Card/PageCard';
import { PageCnt } from 'components/Card/PageCnt';
import { EditorText } from 'components/EditorText';
import { Footer } from 'components/Footer/Footer';
import { WithSidebar } from 'components/Sidebar/Sidebar';
import { SimulatorHeader } from 'components/SimulatorHeader/SimulatorHeader';
import { TileTitle } from 'components/Titles/TileTitle';
import { Box } from 'components/common';
import keys from 'i18n/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'react-use';

export const About = () => {
  const { t } = useTranslation();
  useTitle(t(keys.PAGE_TITLE_ABOUT));
  return (
    <div>
      <WithSidebar>
        <PageCnt>
          <div>
            <div
              css={css`
                max-width: 835px;
                margin: 0 auto;
              `}
            >
              <Box mb="50px">
                <SimulatorHeader title={t(keys.PAGE_TITLE_ABOUT)} />
              </Box>
              <TileTitle>{t(keys['PAGE_ABOUT_CONTENT_title'])}</TileTitle>
              <EditorText dangerouslySetInnerHTML={{ __html: t(keys.PAGE_ABOUT_CONTENT) }}></EditorText>
            </div>
          </div>
        </PageCnt>
      </WithSidebar>
    </div>
  );
};
