import styled from '@emotion/styled';
import { ReactComponent as Icon } from 'assets/Pie_Line_Graph_Desktop.svg';
export const SCnt = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

export const SIcon = styled(Icon)`
  margin-bottom: 60px;
`;

export const Stext = styled.p<{ bigger?: boolean }>`
  font-weight: 500;
  font-size: ${({ bigger }) => (bigger ? '60px' : '18px')};
  line-height: ${({ bigger }) => (bigger ? '90px' : '24px')};
  &:first-of-type {
    margin-bottom: 25px;
  }
`;

export const STextCnt = styled.div`
  max-width: 450px;
`;
