import keys from 'i18n/keys';

export const getElasitcityOptions = t => {
  return [
    { label: `${t(keys.ELASTIC_OF_DEMAND_OPTION_1)}`, value: 0 },
    { label: `${t(keys.ELASTICITY_OPTION_SMALL)} 5%`, value: 0.05 },
    { label: `${t(keys.ELASTICITY_OPTION_MEDIUM)} 10%`, value: 0.1 },
    { label: `${t(keys.ELASTICITY_OPTION_BIG)} 15%`, value: 0.15 },
  ];
};
