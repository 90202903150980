import { roundNumb } from './roundNumb';

const formatToTwoDimensions = (val: string) => {
  const strVal = val.toString();
  if (val.length === 2) {
    return val;
  }
  if (strVal.length === 1) {
    return `${val}0`;
  }
  const asd = strVal.slice(1, 3);
  return `${strVal.slice(0, 1)}${roundNumb(Number(asd) / 10)}` || '00';
};

const formatToOneDimension = (val: string) => {
  if (val.length === 1) {
    return val;
  }
  const asd = +'1'.padEnd(val.length, '0');
  return roundNumb(+val / asd) || '0';
};

export const splitByComma = (str: string | number, formatToOneDimesion?: boolean) => {
  const [firstVal, secondVal] = str.toString().split('.');

  if (formatToOneDimesion) {
    return {
      firstVal,
      secondVal: secondVal ? formatToOneDimension(secondVal) : '0',
    };
  }
  return {
    firstVal,
    secondVal: secondVal ? formatToTwoDimensions(secondVal) : '00',
  };
};
