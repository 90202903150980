import i18n from 'i18next';
import keys from 'i18n/keys';
import React, { useMemo } from 'react';
import { COLORS } from 'services/consts/colors';
import { Cell, Grid, HeadRow, Row, RowGroup, STooltip, SpecificCardRow, TableTitle } from './sharedStyles';

import { Input } from 'components/Input/Input';
import { useDispatchAction } from 'hooks/useDispatchAction';

import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';

import { Select } from 'components/Select/Select';
import { HEAT_RATIOS_OPTIONS } from 'services/consts/heatOptions';
import { useTranslation } from 'react-i18next';
import { EndAdornment } from 'components/Input/styles';
import {
  getCieplo,
  getCieploInputs,
  getObliczenia,
  getObliczeniaCalculations,
} from 'services/slices/obliczeniaSlice/selectors';
import {
  CieploInputsPayload,
  CieploSelectOptionsPayload,
} from 'services/slices/obliczeniaSlice/obliczenia/cieplo/types';
import {
  setCieploInputs,
  setCieploSelectOptions,
  setObliczeniaInputs,
} from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { useSelectDispatchAction } from 'hooks/useSelectDispatchAction';
import {
  RODZAJE_TERMOMODERNIZACJI,
  TEMPERATURA_CO_CWU,
} from 'services/slices/obliczeniaSlice/obliczenia/cieplo/consts';
import { DataInput } from 'components/DataInput/DataInput';
import { ObliczeniaTypeInputsPayload } from 'services/slices/obliczeniaSlice/obliczenia/types';
import { getElasitcityOptions } from 'services/consts/elasticityOptions';
import { Box } from 'components/common';
import { roundNumb } from 'services/utils/roundNumb';
import { COCieplo } from 'features/Cards/SpecificInformationCard/Tiles/ConsumptionTile/COCieplo';

export const HeatTile = () => {
  const {
    inputs: {
      moc_pomp_ciepla,
      zapotrzebowanie_CWU_względem_2023,
      procent_pomp_ciepla_gruntowych,
      procent_pomp_ciepla_powietrznych,
      odsetek_budynkow_z_odzyskiem_ciepla,
      stopien_zastapienia_starych_budynkow_nowymi,
    },
    selectOptions: { rodzaj_termomodernizacji, temperatura_CO, temperatura_CWU },
  } = useAppSelector(getCieplo);
  const dispatch = useAppDispatch();
  const {
    inputs: { wspolczynnik_elastyczności },
  } = useAppSelector(getObliczenia);

  // const CONeededSet = useDispatchAction<CieploInputsPayload>({
  //   action: setCieploInputs,
  //   name: 'COPercentageEnergyDemand',
  //   value: COPercentageEnergyDemand,
  //   percentage: true,
  //   max: 2,
  // });

  // const CONeededConst = useDispatchAction<HeatConstsNumbPayload>({
  //   action: setHeatNumbConsts,
  //   name: 'energyNeededToCOHeat',
  //   value: energyNeededToCOHeat,
  // });

  const CWUNeededSet = useDispatchAction<CieploInputsPayload>({
    action: setCieploInputs,
    name: 'zapotrzebowanie_CWU_względem_2023',
    value: zapotrzebowanie_CWU_względem_2023,
    percentage: true,
    max: 2,
  });

  const odsetekZastapieniaBudynkowSet = useDispatchAction<CieploInputsPayload>({
    action: setCieploInputs,
    name: 'odsetek_budynkow_z_odzyskiem_ciepla',
    value: odsetek_budynkow_z_odzyskiem_ciepla,
    percentage: true,
  });

  const stopienZastapieniaBudynkowSet = useDispatchAction<CieploInputsPayload>({
    action: setCieploInputs,
    name: 'stopien_zastapienia_starych_budynkow_nowymi',
    value: stopien_zastapienia_starych_budynkow_nowymi,
    percentage: true,
  });

  // const CWUNeededConst = useDispatchAction<HeatConstsNumbPayload>({
  //   action: setHeatNumbConsts,
  //   name: 'CWUEnergyNeeded',
  //   value: CWUEnergyNeeded,
  // });
  const { t } = useTranslation();

  const heatPumpSet = useDispatchAction<CieploInputsPayload>({
    action: setCieploInputs,
    name: 'moc_pomp_ciepla',
    value: moc_pomp_ciepla,
  });

  // const pompyCieplaGruntoweSet = useDispatchAction<CieploInputsPayload>({
  //   action: setCieploInputs,
  //   name: 'procent_pomp_ciepla_gruntowych',
  //   value: procent_pomp_ciepla_gruntowych,
  //   percentage: true,
  // });

  const pompyCieplaPowietrzneSet = useDispatchAction<CieploInputsPayload>({
    action: setCieploInputs,
    name: 'procent_pomp_ciepla_powietrznych',
    value: procent_pomp_ciepla_powietrznych,
    percentage: true,
  });

  const tempCOSet = useSelectDispatchAction<CieploSelectOptionsPayload<'temperatura_CO'>>({
    action: setCieploSelectOptions,
    name: 'temperatura_CO',
    options: TEMPERATURA_CO_CWU,
    selectedValue: temperatura_CO,
  });

  const tempCWUSet = useSelectDispatchAction<CieploSelectOptionsPayload<'temperatura_CWU'>>({
    action: setCieploSelectOptions,
    options: TEMPERATURA_CO_CWU,
    name: 'temperatura_CWU',
    selectedValue: temperatura_CWU,
  });
  const rodzajTermomodernizacjiSet = useSelectDispatchAction<CieploSelectOptionsPayload<'rodzaj_termomodernizacji'>>({
    action: setCieploSelectOptions,
    options: RODZAJE_TERMOMODERNIZACJI,
    name: 'rodzaj_termomodernizacji',
    selectedValue: rodzaj_termomodernizacji,
  });

  const elastycznoscOpcje = useMemo(() => getElasitcityOptions(t), [t]);
  const elasticitySet = useSelectDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    options: elastycznoscOpcje,
    name: 'wspolczynnik_elastyczności',
    selectedValue: wspolczynnik_elastyczności,
  });

  // const heatStoragesConnectedToNetworkSet = useDispatchAction<CieploInputsPayload>({
  //   action: setCieploInputs,
  //   name: 'odsetek_budynkow_z_odzyskiem_ciepla',
  //   value: odsetek_budynkow_z_odzyskiem_ciepla,
  //   percentage: true,
  // });

  // const handleTemperatureChange = (name: HeatConstsRatioPayload['name']) => (val: HeatConstsRatioPayload['value']) => {
  //   dispatch(setHeatConstsRatio({ name, value: val }));
  // };
  return (
    <Grid>
      <HeadRow columns={1}>
        <TableTitle reverseFlex id="heat-advanced_title" tooltipContent={t(keys.TOOLTIP_HEAT_TILE_CONTENT)}>
          {t(keys.ELECTRIFIED_HEAT)}
        </TableTitle>
      </HeadRow>
      <RowGroup>
        <SpecificCardRow>
          {/* <STooltip id="CO-advanced-tooltip" content={t(keys.TOOLTIP_HEAT_CO_ADVANCED_TOOLTIP)} /> */}
          <div className="card">
            <COCieplo />
          </div>
        </SpecificCardRow>
        <Row columns={1}>
          <Cell>
            <STooltip
              id="cwu-heat-needed-cell"
              content={t(keys.TOOLTIP_HEAT_TILE_CURR_REQ_FOR_DOMESTIC_HEAT_WATER_PRECENT)}
            />
            <span>{t(keys.CURR_REQ_FOR_DOMESTIC_HEAT_WATER_PRECENT_ADVANCED)}</span>
          </Cell>
          <Cell>
            <DataInput {...CWUNeededSet} id="CWUNeededSet" />
          </Cell>
        </Row>
        <Row columns={1}>
          <Cell>
            <STooltip id="heat-pump-included-cell" content={t(keys.TOOLTIP_HEAT_TILE_HEAT_PUMPS_IN_HEATING)} />
            <span>{t(keys.HEAT_PUMPS_IN_HEATING_ADVANCED)}</span>
          </Cell>
          <Cell>
            <DataInput {...heatPumpSet} id="heatPumpSet" />
          </Cell>
        </Row>
        <Row columns={1}>
          <Cell>
            <STooltip id="heat-pumps-ground-advanced-tooltip" content={t(keys.TOOLTIP_HEAT_PUMPS_GROUND_TOOLTIP)} />
            <span>{t(keys.HEAT_PUMPS_GROUND)}</span>
          </Cell>
          <Cell>
            <DataInput
              hideControls
              value={roundNumb((1 - procent_pomp_ciepla_powietrznych) * 100)}
              id="pompyCieplaGruntoweSet"
            />
          </Cell>
        </Row>
        <Row columns={1}>
          <Cell>
            <STooltip id="heat-pumps-air-advanced-tooltip" content={t(keys.TOOLTIP_HEAT_PUMPS_AIR_TOOLTIP)} />
            <span>{t(keys.HEAT_PUMPS_AIR)}</span>
          </Cell>
          <Cell>
            <DataInput {...pompyCieplaPowietrzneSet} id="pompyCieplaPowietrzneSet" />
          </Cell>
        </Row>
        <Row columns={1} columnWidth="auto">
          <Cell>
            <STooltip id="temp-co-cell" content={t(keys.TOOLTIP_HEAT_TILE_TEMP_CENTRAL_HEAT)} />
            <span>{t(keys.TEMP_CENTRAL_HEAT_LABEL)}</span>
          </Cell>
          <Cell>
            <Select menuStickCorner="right" {...tempCOSet} />
          </Cell>
        </Row>
        <Row columns={1} columnWidth="auto">
          <Cell>
            <STooltip id="temp-co-cell" content={t(keys.TOOLTIP_HEAT_TILE_TEMP_DOMESTIC_HEAT_WATERT_LABEL)} />
            <span>{t(keys.TEMP_DOMESTIC_HEAT_WATERT_LABEL)}</span>
          </Cell>
          <Cell>
            <Select menuStickCorner="right" {...tempCWUSet} />
          </Cell>
        </Row>
        <Row columns={1} columnWidth="auto">
          <Cell>
            <STooltip id="elastic-of-demand-cell" content={t(keys.ELASTIC_OF_DEMAND_ADVANCED_TOOLTIP)} />
            <span>{t(keys.ELASTIC_OF_DEMAND_ADVANCED)}</span>
          </Cell>
          <Cell>
            <Select menuStickCorner="right" {...elasticitySet} />
          </Cell>
        </Row>
      </RowGroup>
    </Grid>
  );
};
