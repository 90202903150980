import styled from '@emotion/styled';
import { Tooltip } from 'react-tooltip';

export const SCnt = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SReactTooltip = styled(Tooltip)`
  --rt-opacity: 1;
  min-width: 330px;
  max-width: 500px;
  background: #e4f3f7;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  color: #212529;
  opacity: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 8px 21px;
  z-index: 1000;
`;
