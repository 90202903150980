import styled from '@emotion/styled';

export const SOverlay = styled.div<{ open: boolean; destroy?: boolean }>`
  position: fixed;
  top: 0;
  left: 0px;
  right: 0;
  bottom: ${({ open, destroy }) => (destroy && !open ? 'unset' : 0)};
  background: ${({ open }) => (open ? '#1e202499' : 'none')};
  z-index: ${({ open }) => (open ? 100 : -1)};
`;

export const SModal = styled.div<{ open: boolean }>`
  position: absolute;
  background-color: #fff;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  padding: 24px 32px;
  padding-bottom: 0;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(3000px)')};
  transition: 0.3s all;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
`;
