import { EnergyFromExcel } from 'services/slices/obliczeniaSlice/types';
import { ElectricityExport, ElectricityExportKeys } from '../types';

export const electricityImport = (json: ElectricityExport[]): EnergyFromExcel => {
  const initial: Record<ElectricityExportKeys, number[]> = {
    energy_demand: [],
    wind: [],
    windOffShore: [],
    photovoltaic: [],
    PV_wsch_zach: [],
    wiatr_lad_6MGW: [],
  };
  const formattedData: EnergyFromExcel = json.reduce((acc, el, index) => {
    const objectValues = Object.values(el);
    if (objectValues.length !== 8) {
      throw new Error('different keys');
    }
    acc.energy_demand[index] = +objectValues[2] || 0;
    acc.windOffShore[index] = +objectValues[3] || 0;
    acc.wind[index] = +objectValues[4] || 0;
    acc.wiatr_lad_6MGW[index] = +objectValues[5] || 0;
    acc.photovoltaic[index] = +objectValues[6] || 0;
    acc.PV_wsch_zach[index] = +objectValues[7] || 0;
    return acc;
  }, initial);
  return formattedData;
};
