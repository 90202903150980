import styled from '@emotion/styled';
import { ReactComponent as CarretDownIcon } from '../../assets/caret-down-fill.svg';
import { StylesConfig } from 'react-select';
import { appTheme } from 'services/configuration/theme/theme';

const fontStyles = {
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '16px',
  color: appTheme.primary,
};

export const SelectStyles: StylesConfig = {
  dropdownIndicator: () => ({
    // padding: '2px 4px 2px 12px',
    color: '#212529',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.2s',
    svg: {
      width: 16,
      height: 16,
      transition: 'transform 0.2s',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: (_, { isFocused }) => {
    return {
      padding: '2px 4px 2px 6px',
      background: '#EDF0F3',
      border: 'unset',
      boxShadow: 'unset',
      borderRadius: '4px',
      display: 'flex',
      outline: isFocused ? '2px solid #297F94' : '',
    };
  },
  menu: currStyles => ({
    ...currStyles,
    minWidth: '135px',
    background: '#FFFFFF',
    border: '1px solid #DFE2EA',
    boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.08), 0px 5px 10px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px',
    padding: '12px 16px',
    zIndex: 999,
  }),
  menuPortal: base => ({ ...base, zIndex: 5000000000002, minWidth: '200px' }),
  menuList: () => ({}),
  option: (currStyles, state) => {
    return {
      ...currStyles,
      //@ts-ignore
      background: state.selectProps?.value?.value === state.value ? '#E4F3F7' : '',
      backgroundColor: state.isFocused ? '#F4F5F7' : '',
      ...fontStyles,
      margin: '10px 0',
      color: '#14171F',
      borderRadius: '4px',
      padding: '8px 12px',
      '&:active': { background: '#F4F5F7' },
      '&:hover': { background: '#F4F5F7' },
    };
  },
  valueContainer: currStyles => ({
    ...currStyles,
    ...fontStyles,
    padding: 0,
  }),
  singleValue: currStyles => ({
    ...currStyles,
    ...fontStyles,
    margin: 0,
  }),
};

export const TextSelectStyles: any = {
  control: () => ({
    border: 0,
    clip: 'rect(1px 1px 1px 1px)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  }),
  menu: currStyles => ({
    ...currStyles,
    width: 'unset',
    background: '#FFFFFF',
    border: '1px solid #DFE2EA',
    boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.08), 0px 5px 10px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px',
    padding: '12px 16px',
  }),
  menuList: () => ({}),
  option: (currStyles, state) => {
    return {
      ...currStyles,
      background: state.selectProps.value.value === state.value ? '#E4F3F7' : '',
      backgroundColor: state.isFocused ? '#F4F5F7' : '',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      margin: '10px 0',
      color: '#14171F',
      borderRadius: '4px',
      padding: '8px 12px',
      '&:active': { background: '#F4F5F7' },
      '&:hover': { background: '#F4F5F7' },
    };
  },
};

type SSelectContainerProps = {
  color?: string;
  focused: boolean;
};

export const SSelectContainer = styled.div<SSelectContainerProps>`
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
  /* outline: ${({ focused }) => (focused ? '2px solid #297F94' : '')}; */
  cursor: pointer;
  padding: 0 4px;
`;

export const SCarretDownIcon = styled(CarretDownIcon)`
  margin-left: 5px;
`;
