import i18n from 'i18next';
import keys from 'i18n/keys';
import React from 'react';
import { COLORS } from 'services/consts/colors';
import {
  Cell,
  CellText,
  Grid,
  HeadRow,
  RowGroup,
  RowTitle,
  STooltip,
  Row,
  TableTitle,
  Border,
  TileView,
} from './sharedStyles';

import { Input } from 'components/Input/Input';
import { useDispatchAction } from 'hooks/useDispatchAction';

import { useAppSelector } from 'services/configuration/store/hooks';
import { useTranslation } from 'react-i18next';
import { getKoszty, getObliczenia } from 'services/slices/obliczeniaSlice/selectors';
import {
  ObliczeniaTypeConstsPayload,
  ObliczeniaTypeInputsPayload,
} from 'services/slices/obliczeniaSlice/obliczenia/types';
import {
  setKosztyAktualnaInfrastruktura,
  setObliczeniaConsts,
  setObliczeniaInputs,
} from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { DataInput } from 'components/DataInput/DataInput';
import { KosztyAktualnaInfrastrukturaPayload } from 'services/slices/obliczeniaSlice/koszty/types';
import { roundNumb } from 'services/utils/roundNumb';
import classNames from 'classnames';
type HeatStorageTileProps = {
  tileView?: TileView;
};

export const HeatStorageTile = ({ tileView = 'default' }: HeatStorageTileProps) => {
  const {
    inputs: { pojemnosc_magazynu_ciepla },
    consts: { sprawnosc_magazynow_ciepla, czas_ladowania_rozladowania_magazynu_ciepla },
    calculations: { magazyny_ciepla_domowe, magazyny_ciepla_duze },
  } = useAppSelector(getObliczenia);

  const { aktualna_infrastruktura } = useAppSelector(getKoszty);

  const efficencySet = useDispatchAction<ObliczeniaTypeConstsPayload>({
    action: setObliczeniaConsts,
    name: 'sprawnosc_magazynow_ciepla',
    value: sprawnosc_magazynow_ciepla,
    percentage: true,
  });

  const pojemnoscMagazynuCieplaSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'pojemnosc_magazynu_ciepla',
    value: pojemnosc_magazynu_ciepla,
    max: 9999,
  });

  const czasLadowaniaMagazynuCieplaSet = useDispatchAction<
    ObliczeniaTypeConstsPayload<'czas_ladowania_rozladowania_magazynu_ciepla'>
  >({
    action: setObliczeniaConsts,
    name: 'czas_ladowania_rozladowania_magazynu_ciepla',
    value: czas_ladowania_rozladowania_magazynu_ciepla,
    max: 200,
  });

  const magazynyCieplaDomoweSet = useDispatchAction<KosztyAktualnaInfrastrukturaPayload>({
    action: setKosztyAktualnaInfrastruktura,
    name: 'magazyny_ciepla_domowe',
    value: aktualna_infrastruktura.magazyny_ciepla_domowe,
    max: 9999,
  });

  const magazynyCieplaDuzeSet = useDispatchAction<KosztyAktualnaInfrastrukturaPayload>({
    action: setKosztyAktualnaInfrastruktura,
    name: 'magazyny_ciepla_duze',
    value: aktualna_infrastruktura.magazyny_ciepla_duze,
    max: 9999,
  });

  const { t } = useTranslation();
  const columns = tileView === 'default' ? 3 : 1;
  return (
    <Grid
      className={classNames({
        'modal-view': tileView === 'modal',
      })}
    >
      <HeadRow columns={columns}>
        <TableTitle reverseFlex id="heat-storage-advanced" tooltipContent={t(keys.TOOLTIP_HEAT_STORAGE_TILE_CONTENT)}>
          {t(keys.HEAT_STORAGE_GWHTH)}
        </TableTitle>
        <>
          <RowTitle color="#358F86">{t(keys.CAPACITY_GWTH)}</RowTitle>
          {tileView === 'default' && (
            <>
              <RowTitle color="#AD6800">{t(keys.EXISTING_GWTH)}</RowTitle>
              <RowTitle color="#358F86">{t(keys.EFFICIENCY_PERCENTAGE)}</RowTitle>
            </>
          )}
        </>
      </HeadRow>
      <RowGroup>
        <Row columns={columns}>
          <Cell>
            <STooltip id="big-ptes-cell" content={t(keys.TOOLTIP_HEAT_STORAGE_TILE_PTES)} />
            <span>{t(keys.LARGE_PTES_LABEL)}</span>
          </Cell>
          <Cell>
            <DataInput hideControls value={magazyny_ciepla_duze.toFixed()} id="TTESSet" />
          </Cell>
          {tileView === 'default' && (
            <>
              <Cell>
                <DataInput {...magazynyCieplaDuzeSet} id="magazynyCieplaDuzeSet" />
              </Cell>
              <Cell>
                <DataInput {...efficencySet} id="efficencySet" />
              </Cell>
            </>
          )}
        </Row>
        <Row columns={columns}>
          <Cell>
            <STooltip id="small-housing-cell" content={t(keys.TOOLTIP_HEAT_STORAGE_TILE_HOME)} />
            <span>{t(keys.SMALL_HOME_LABEL)}</span>
          </Cell>
          <Cell>
            <DataInput value={magazyny_ciepla_domowe.toFixed()} hideControls id="magazynyCieplaDomoweSet" />
          </Cell>
          {tileView === 'default' && (
            <Cell>
              <DataInput {...magazynyCieplaDomoweSet} id="magazynyCieplaDomoweSet" />
            </Cell>
          )}
        </Row>
        <Row columns={columns}>
          <div></div>
          <Border />
          {tileView === 'default' && (
            <>
              <Border />
              <Border />
            </>
          )}
        </Row>
        <Row columns={columns}>
          <Cell className="bigger-font">
            <STooltip id="heat-storage-advanced-cell" content={t(keys.TOOLTIP_HEAT_STORAGE_TILE_SUM)} />
            <span>{t(keys.SUM)}</span>
          </Cell>
          <Cell>
            <DataInput {...pojemnoscMagazynuCieplaSet} id="sumCapacity" />
          </Cell>
          {tileView === 'default' && (
            <Cell>
              <DataInput
                value={roundNumb(
                  aktualna_infrastruktura.magazyny_ciepla_domowe + aktualna_infrastruktura.magazyny_ciepla_duze,
                )}
                id="sumInfrastructure"
                hideControls
              />
            </Cell>
          )}
        </Row>
        {tileView === 'default' && (
          <>
            <Row columns={1}>
              <Cell>
                <span>{t(keys.CHARGIN_DISCHARGING)}</span>
              </Cell>
              <Cell>
                <DataInput {...czasLadowaniaMagazynuCieplaSet} id="czas-ladowania-rozladowania-magazynu-ciepla" />
              </Cell>
            </Row>
          </>
        )}
      </RowGroup>
    </Grid>
  );
};
