import { ChartData } from 'chart.js';
import { DATASET_LABELS } from 'features/Charts/LineEnergyProduction/consts';
import { COLORS, NEW_COLORS } from 'services/consts/colors';
import { HEAT_STORAGE_COLORS } from './colors';
import { ObliczeniaTypeCalculations, ObliczeniaTypeInputs } from 'services/slices/obliczeniaSlice/obliczenia/types';

type HeatStorageType = {
  filterByPeriod: (_: number, i: number) => boolean;
  obliczeniaInputs: ObliczeniaTypeInputs;
  obliczeniaCalc: ObliczeniaTypeCalculations;
};
export const heatStorage =
  ({ filterByPeriod, obliczeniaCalc, obliczeniaInputs }: HeatStorageType) =>
  (): ChartData<'line', number[], string>['datasets'] => {
    const heatStorageState = obliczeniaInputs.pojemnosc_magazynu_ciepla
      ? obliczeniaCalc.magazyn_ciepła_per_day.filter(filterByPeriod)
      : [];
    return [
      {
        data: heatStorageState,
        showLine: false,
        label: DATASET_LABELS.magazyn_ciepła_per_day,
        fill: true,
        backgroundColor: NEW_COLORS.cieplo_budynki_magazyny,
      },
    ];
  };
