import styled from '@emotion/styled';
import { Button } from 'components/Button/Button';

export const BtnRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
`;

export const Text = styled.div<{ color?: string }>`
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${({ color }) => color || 'inherit'};
`;

export const CostText = styled.div<{ color?: string }>`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ color }) => color || 'inherit'};
`;

export const Content = styled.div<{ isVisible: boolean }>`
  min-height: 108px;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

export const SButton = styled(Button, { shouldForwardProp: prop => prop !== 'isProdukcjaWodoruWystarczajaca' })<{
  isProdukcjaWodoruWystarczajaca?: boolean;
}>`
  background-color: ${({ variant }) => (variant === 'outlined' ? '#fff' : 'transparent')};
  color: ${({ isProdukcjaWodoruWystarczajaca }) => (isProdukcjaWodoruWystarczajaca === false ? '#FF6969' : '')};
`;

export const GroupDataCnt = styled.div`
  display: block;
  position: relative;
  padding: 8px 16px;
  border-radius: 6px;
  width: 100%;
  background-color: rgba(126, 136, 135, 0.07);
  border: 2px solid rgb(221, 225, 230);
`;
