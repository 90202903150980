import { useAppSelector } from 'services/configuration/store/hooks';
import { produkcjaEnergiiElektrycznej } from 'services/slices/obliczeniaSlice/produkcjaEnergiiElektrycznej/produkcjaEnergiiElektrycznej';
import { getProdukcjaEnergiiElektrycznej } from 'services/slices/obliczeniaSlice/selectors';
import { ScenarioData } from 'services/slices/obliczeniaSlice/types';
import { updatedDiff } from 'deep-object-diff';
import {
  ProdukcjaEnergiiElektrycznejConsts,
  ProdukcjaEnergiiElektrycznejInputs,
} from 'services/slices/obliczeniaSlice/produkcjaEnergiiElektrycznej/types';
import { SearchPrams, addSearchParams, deleteSearchParams } from '../AppParams/utils';
type InitialData = {
  inputs: ProdukcjaEnergiiElektrycznejInputs;
  consts: ProdukcjaEnergiiElektrycznejConsts;
};
export const useProdukcjaUpdate = (currentScenario: ScenarioData, searchParams: URLSearchParams) => {
  // const [searchParams, setSearchParams] = useSearchParams();

  const { inputs, consts } = useAppSelector(getProdukcjaEnergiiElektrycznej);

  const initialData: InitialData = {
    inputs: currentScenario.data.produkcjaEnergiiElektrycznej.inputs,
    consts: produkcjaEnergiiElektrycznej.consts,
  };
  const diff = updatedDiff(initialData, { inputs, consts });
  deleteSearchParams(searchParams, SearchPrams.produkcjaEnergii);
  if (Object.keys(diff).length) {
    addSearchParams(searchParams, SearchPrams.produkcjaEnergii, JSON.stringify(diff));
  }
};
