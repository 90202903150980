import { ITooltip as TooltipProps } from 'react-tooltip';
import React, { ClassAttributes, ElementType, HTMLAttributes, ReactNode } from 'react';
import { STileTitle, STooltip } from './styles';
import classNames from 'classnames';

export type TileTitleProps = {
  id?: string;
  tooltipContent?: string;
  tooltipProps?: TooltipProps;
  title?: ReactNode;
  as?: ElementType;
  variant?: 'medium' | 'small' | 'smaller';
  children?: ReactNode;
  border?: string;
  reverseFlex?: boolean;
} & ClassAttributes<HTMLHeadingElement> &
  HTMLAttributes<any>;

export const TileTitle = ({
  id,
  tooltipContent,
  title,
  as,
  children,
  tooltipProps,
  border,
  className,
  ...rest
}: TileTitleProps) => {
  return (
    <STileTitle border={border} as={as} {...rest} className={classNames('tile-title', className)}>
      {children}
      {!children && <span>{title}</span>}
      {id && <STooltip id={id} content={tooltipContent!} {...tooltipProps} />}
      {border && <div className="border" />}
    </STileTitle>
  );
};
