import { Button } from 'components/Button/Button';
import React, { useRef, useState } from 'react';
import { ReactComponent as DownloadIcon } from 'assets/new/downloading.svg';
import { useTranslation } from 'react-i18next';
import keys from 'i18n/keys';
import { AppChangedParams } from 'App/AppChangedParams/AppChangedParams';
import { useSearchParams } from 'react-router-dom';
import { deleteAllSearchParams } from 'App/AppParams/utils';
import { ReactTooltip } from 'components/Tooltip/ReactTooltip';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';

export const SaveSimulation = () => {
  const { t } = useTranslation();
  const [isSaveBtnClicked, setIsSaveBtnClicked] = useState(false);
  const [searchParams, setSearchPrams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <Button
        id={SIMULATOR_INTRO_STEPS.SAVE_SIMULATION}
        onClick={() => {
          deleteAllSearchParams(searchParams);

          setIsSaveBtnClicked(true);
          setTimeout(async () => {
            if (inputRef.current) {
              inputRef.current.value = window.location.href;
              inputRef.current.select();
              document.execCommand('copy');
            }
            setIsOpen(true);
            setIsSaveBtnClicked(false);
            deleteAllSearchParams(searchParams);
            setSearchPrams(searchParams);
          }, 200);
          setTimeout(() => {
            setIsOpen(false);
          }, 2000);
        }}
        variant="outlined"
        size="small"
        endIcon={<DownloadIcon style={{ transform: 'rotate(90deg)' }} />}
        data-tooltip-id="save-simulation"
        data-tooltip-html={t(keys.SAVE_SIMULATION_TOOLTIP)}
      >
        {t(keys.SAVE_SIMULATION)}
      </Button>
      {isOpen && <ReactTooltip positionStrategy="fixed" isOpen={isOpen} id="save-simulation" />}
      <input
        ref={inputRef}
        onChange={() => {}}
        value={'di[asdasl;dkasl;djas'}
        style={{ position: 'absolute', top: -100 }}
      />
      {isSaveBtnClicked && <AppChangedParams />}
    </>
  );
};
