import { useDispatchAction } from 'hooks/useDispatchAction';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'services/configuration/store/hooks';
import { ObliczeniaTypeInputsPayload } from 'services/slices/obliczeniaSlice/obliczenia/types';
import { setObliczeniaInputs } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { getObliczeniaInputs } from 'services/slices/obliczeniaSlice/selectors';
import { Cell, CellText, Grid, HeadRow, Row, STooltip, TableTitle } from './sharedStyles';
import keys from 'i18n/keys';
import { DataInput } from 'components/DataInput/DataInput';

export const CieploPrzemysloweTile = () => {
  const {
    zapotrzebowanie_na_cieplo_w_przemysle,
    pojemnosc_magazynu_ciepla_w_przemysle,
    odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle,
    odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych,
  } = useAppSelector(getObliczeniaInputs);
  const { t } = useTranslation();

  const zapotrzebowanieNaCieploWprzemysleSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'zapotrzebowanie_na_cieplo_w_przemysle',
    value: zapotrzebowanie_na_cieplo_w_przemysle,
    percentage: true,
    max: 2,
  });

  const pojemnoscMagazynuCieplaWPrzemysleSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'pojemnosc_magazynu_ciepla_w_przemysle',
    value: pojemnosc_magazynu_ciepla_w_przemysle,
    max: 9999,
  });

  const odsetekOdbiorcowPodlaczonychDoSieciWPrzemysleSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle',
    value: odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle,
    percentage: true,
  });
  const odsetekCieplaOdpadowegoZPrzemysluSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych',
    value: odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych,
    percentage: true,
  });
  return (
    <Grid>
      <HeadRow>
        <TableTitle
          reverseFlex
          id="zapotrzebowanie-na-cieplo-w-przm-title"
          tooltipContent={t(keys.ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED_TITLE_TOOLTIP)}
        >
          {t(keys.ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED_TITLE)}
        </TableTitle>
      </HeadRow>
      <Row columns={1}>
        <Cell>
          <STooltip
            id="zapotrzebowanie-na-cieplo-w-przm"
            content={t(keys.ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED_TOOLTIP)}
          />
          <CellText>{t(keys.ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED)}</CellText>
        </Cell>
        <Cell>
          <DataInput {...zapotrzebowanieNaCieploWprzemysleSet} id="zapotrzebowanieNaCieploWprzemysleSet" />
        </Cell>
      </Row>
      <Row columns={1}>
        <Cell>
          <STooltip
            id="zapotrzebowanie-na-cieplo-w-przm-magazyn-cell"
            content={t(keys.ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_MAGAZYNY_ADVANCED_TOOLTIP)}
          />
          <CellText>{t(keys.ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_MAGAZYNY_ADVANCED)}</CellText>
        </Cell>
        <Cell>
          <DataInput {...pojemnoscMagazynuCieplaWPrzemysleSet} id="pojemnoscMagazynuCieplaWPrzemysleSet" />
        </Cell>
      </Row>
      <Row columns={1}>
        <Cell>
          <STooltip
            id="cieplo-przemyslowe-odb-podlaczeni-cell"
            content={t(keys.ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ODB_PODLACZENI_ADVANCED_TOOLTIP)}
          />
          <CellText>{t(keys.ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ODB_PODLACZENI_ADVANCED)}</CellText>
        </Cell>
        <Cell>
          <DataInput
            {...odsetekOdbiorcowPodlaczonychDoSieciWPrzemysleSet}
            id="odsetekOdbiorcowPodlaczonychDoSieciWPrzemysleSet"
          />
        </Cell>
      </Row>
      <Row columns={1}>
        <Cell>
          <STooltip
            id="CIEPLO_PRZEMYSLOWE_ODSETEK_ODDAWANY_DOSIECI_ADVANCED_TOOLTIP-cell"
            content={t(keys.CIEPLO_PRZEMYSLOWE_ODSETEK_ODDAWANY_DOSIECI_ADVANCED_TOOLTIP)}
          />
          <CellText>{t(keys.CIEPLO_PRZEMYSLOWE_ODSETEK_ODDAWANY_DOSIECI_ADVANCED)}</CellText>
        </Cell>
        <Cell>
          <DataInput {...odsetekCieplaOdpadowegoZPrzemysluSet} id="odsetekCieplaOdpadowegoZPrzemysluSet" />
        </Cell>
      </Row>
    </Grid>
  );
};
