import styled from '@emotion/styled';
import { TileTitle } from 'components/Titles/TileTitle';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { Box, getMargin } from 'components/common';
const DEFAULT_COLUMN_WIDTH = 100;
const DEFAULT_COLUMNS = 3;
const DEFAULT_GRID_GAP = '8px';
export type TileView = 'modal' | 'default';
export const Grid = styled.div`
  width: 100%;
  border: 2px solid rgb(221, 225, 230);
  padding: 8px 16px;
  border-radius: 7px;

  .tooltip-icon {
    color: #8c8d8f;
  }

  .select {
    width: fit-content;
  }

  .tile-title {
    margin-bottom: 0;
    color: #8c8d8f;
  }

  &.modal-view {
    .tile-title {
      color: ${({ theme }) => theme.primary};
    }

    [role='rowheader'] {
      margin: 0 -16px;
      padding: 0 16px;
      padding-bottom: 8px;
      border-bottom: 1px solid #dde1e6;
    }

    [role='gridcell'] {
      padding-left: 0;
    }
  }
`;
Grid.defaultProps = {
  role: 'table',
};

export const RowGroup = styled.div``;
RowGroup.defaultProps = {
  role: 'rowgroup',
};

type RowProps = {
  columns?: number;
  columnWidth?: number | string;
};

const CommonRow = styled.div<RowProps>`
  display: grid;
  grid-template-columns: 1fr repeat(
      ${({ columns = DEFAULT_COLUMNS, columnWidth = DEFAULT_COLUMN_WIDTH }) => `${columns}, ${getMargin(columnWidth)}`}
    );
  grid-column-gap: ${DEFAULT_GRID_GAP};
`;

export const HeadRow = styled(CommonRow)<RowProps>`
  margin-bottom: 10px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
`;
HeadRow.defaultProps = {
  role: 'rowheader',
};

export const Row = styled(CommonRow)<RowProps>`
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
`;
Row.defaultProps = {
  role: 'row',
};

export const RowTitle = styled.h3<{ color?: string }>`
  color: ${({ color }) => color};
  text-align: center;
`;
RowTitle.defaultProps = {
  role: 'columnheader',
};

export const TableTitle = styled(TileTitle)``;
TableTitle.defaultProps = {
  role: 'columnheader',
};

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ color }) => color};

  &.bigger-font {
    font-size: 16px;
    line-height: 20px;
  }

  &:first-of-type {
    padding-left: 24px;
    justify-content: flex-start;
  }
`;
Cell.defaultProps = {
  role: 'gridcell',
};

export const CellText = styled.span`
  display: block;

  &.no-space {
    margin: 0;
  }
`;

export const STooltip = styled(Tooltip)`
  margin-right: 4px;
`;

export const Border = styled(Box)`
  border-top: 1px solid #e5e5e5;
  margin-left: -${DEFAULT_GRID_GAP};
`;

export const SpecificCardRow = styled.div`
  display: flex;
  padding-left: 24px;

  .card {
    flex-grow: 1;

    label {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }

    .input-unit {
      visibility: hidden;
      width: 11px;
      margin-left: 0;
    }
  }
`;
