import { keys } from '../../../i18n/keys';
import React, { useState } from 'react';

import { useAppSelector } from 'services/configuration/store/hooks';
import { dotToComma, roundNumb } from 'services/utils/roundNumb';
import { useTranslation } from 'react-i18next';

import { EditButton } from 'components/Button/EditButton';
import { TileTitle } from 'components/Titles/TileTitle';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';
import { DisposableEnergySourcesModal } from './DisposableEnergySourcesModal';
import { getObliczeniaCalculations } from 'services/slices/obliczeniaSlice/selectors';
import { PRZELICZNIKI } from 'services/slices/obliczeniaSlice/koszty/constans';
import { Box, Card } from 'components/common';
import {
  CH4Conumption,
  CH4ConumptionLabel,
  Demand,
  DisposableEnergySourcesCnt,
  DisposalTitle,
  MoreDataBtn,
  SEditBtn,
} from './DisposableEnergySources.styles';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { Button } from 'components/Button/Button';
import { SEditDataIcon } from 'components/Button/styles';

export const DisposableEnergySources = () => {
  const [open, setOpen] = useState(false);
  const { źródła_dyspozycyjne_per_year } = useAppSelector(getObliczeniaCalculations);

  const naturalGasTWh = źródła_dyspozycyjne_per_year / 1000;

  const { t } = useTranslation();

  const naturalGas = roundNumb(naturalGasTWh * PRZELICZNIKI.spalanieGazuDoWyprodukowania1TWHEnergii, 1);

  return (
    <DisposableEnergySourcesCnt id={SIMULATOR_INTRO_STEPS.DISPOSAL_SOURCES}>
      <Box d="flex" align="center" mb="10px">
        <Tooltip
          id="disposable-energy-sources"
          content={t(keys.DISP_ENERGY_SOURCES_LABEL_TOOLTIP)}
          place="top-start"
          style={{ width: '600px' }}
        />
        <DisposalTitle>{t(keys.DISP_ENERGY_SOURCES_LABEL)}</DisposalTitle>

        <MoreDataBtn
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
          }}
          variant="outlined"
        >
          <span>{t(keys.MORE_DATA)}</span>
          <SEditDataIcon />
        </MoreDataBtn>
      </Box>
      <Box d="grid" gridCols="1fr 1fr" align="center" gap="12px">
        <Demand>{t(keys.DISPOSAL_ENERGY_CONSUMPTION)}</Demand>
        <Demand>
          {roundNumb(naturalGasTWh, 0)} {t(keys.TWH)}
        </Demand>
        <CH4ConumptionLabel>{t(keys.METHANE_CONSUMPTION)}</CH4ConumptionLabel>
        <CH4Conumption naturalGas={naturalGas}>
          {dotToComma(naturalGas)} {t(keys.MLDM3)}
        </CH4Conumption>
      </Box>
      <DisposableEnergySourcesModal open={open} setOpen={setOpen} />
    </DisposableEnergySourcesCnt>
  );
};
