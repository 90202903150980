import keys from './../../i18n/keys';
import React, { memo, useEffect, useState, useMemo } from 'react';
import { SChangeButton, SChangeWrapper, SCnt, SPeriod, SPeriodValue, Separator } from './styles';
import { ReactComponent as ArrowLeft } from 'assets/new/chevron_left.svg';
import { ReactComponent as ArrowRight } from 'assets/new/chevron_right.svg';
import { PERIOD_OPTIONS } from 'services/consts/periodOptions';
import { useAppSelector } from 'services/configuration/store/hooks';
import { getMonths } from 'services/consts/months';
import { PeriodEnum } from 'services/types/PeriodEnum';
import { useTranslation } from 'react-i18next';
import { PeriodType } from './types';
import { getObliczenia } from 'services/slices/obliczeniaSlice/selectors';
import { Select } from 'components/Select/Select';

type PeriodProps = {
  className?: string;
  currentPeriod: PeriodType;
  updatePeriod: (period: PeriodType) => void;
};

export const Period = memo(({ className, currentPeriod, updatePeriod }: PeriodProps) => {
  const { t, i18n } = useTranslation();
  const defaultPeriod =
    useMemo(() => PERIOD_OPTIONS.find(el => el.value === currentPeriod.target), [currentPeriod.target]) ||
    PERIOD_OPTIONS[0];
  const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);
  const [index, setIndex] = useState(defaultPeriod.values.findIndex(o => o === currentPeriod.targetValue));
  const { selectedYear } = useAppSelector(getObliczenia);

  useEffect(() => {
    const targetValue = selectedPeriod.values[index];
    switch (selectedPeriod.value) {
      case PeriodEnum.QUATER: {
        const monthInQuater = getMonths(selectedYear).filter(el => el.quater === targetValue);

        updatePeriod({
          start: monthInQuater[0].duration.start,
          end: monthInQuater[2].duration.end,
          target: PeriodEnum.QUATER,
          targetValue,
        });

        break;
      }

      case PeriodEnum.MONTTH: {
        const month = getMonths(selectedYear)[index];
        updatePeriod({ start: month.duration.start, end: month.duration.end, target: PeriodEnum.MONTTH, targetValue });
        break;
      }

      default: {
        updatePeriod({ start: 0, end: 8759, target: PeriodEnum.YEAR, targetValue });
        break;
      }
    }
  }, [index, selectedPeriod, i18n.language, updatePeriod, selectedYear]);

  const handleChangePeriod = period => {
    setSelectedPeriod(period);
    setIndex(0);
  };

  const handlePrev = () => {
    const prevIndex = index - 1;
    if (prevIndex < 0) {
      return;
    }
    setIndex(prevIndex);
  };

  const handleNext = () => {
    const nextIndex = index + 1;
    if (nextIndex >= selectedPeriod.values.length) {
      return;
    }
    setIndex(nextIndex);
  };
  const gePeriodText = () => {
    if (!selectedPeriod.values[index]) {
      return '---';
    }
    if (selectedPeriod.value === PeriodEnum.MONTTH) {
      const { name } = getMonths(selectedYear)[index]!;
      return t(name);
    }
    return selectedPeriod.values[index];
  };

  return (
    <SCnt className={className}>
      <SPeriod>
        <Select
          menuStickCorner="right"
          options={PERIOD_OPTIONS.map(el => ({ ...el, label: t(el.label) || el.label }))}
          value={selectedPeriod}
          onChange={handleChangePeriod}
          displayValue={<span>{t(selectedPeriod.label) || selectedPeriod.label}</span>}
          variant="outlined"
        />
      </SPeriod>
      {selectedPeriod.value !== PeriodEnum.YEAR && (
        <>
          <Separator>–</Separator>
          <SChangeWrapper>
            <SChangeButton disabled={index - 1 < 0} onClick={handlePrev}>
              <ArrowLeft />
            </SChangeButton>
            <SPeriodValue isMonth={currentPeriod.target === PeriodEnum.MONTTH}>{gePeriodText()}</SPeriodValue>
            <SChangeButton disabled={index + 1 >= selectedPeriod.values.length} onClick={handleNext}>
              <ArrowRight />
            </SChangeButton>
          </SChangeWrapper>
        </>
      )}
    </SCnt>
  );
});
