import React, { memo } from 'react';
import { DataInput } from 'components/DataInput/DataInput';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';
import { STile } from './styles';
import { TileTitle } from 'components/Titles/TileTitle';
import { COLORS } from 'services/consts/colors';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { ReactComponent as WindOffShoreIcon } from 'assets/wind_off_shore_icon.svg';
import { ReactComponent as WindIcon } from 'assets/wind_on_land_icon.svg';
import { ReactComponent as PVIcon } from 'assets/PV.svg';
import { ReactComponent as AtomIcon } from 'assets/Atom.svg';
import { ReactComponent as GazIcon } from 'assets/Gaz.svg';
import { useDispatchAction } from 'hooks/useDispatchAction';
import { useTranslation } from 'react-i18next';
import keys from 'i18n/keys';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';
import { setProdukcjiEnergiiInputs } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { ProdukcjaEnergiiElektrycznejInputsPayload } from 'services/slices/obliczeniaSlice/produkcjaEnergiiElektrycznej/types';
import {
  getObliczeniaCalculations,
  getProdukcjaEnergiiElektrycznejInputs,
} from 'services/slices/obliczeniaSlice/selectors';
import { Spacer } from 'components/common';
import { Collapse } from 'components/Collapse/Collapse';
import { STooltip } from 'components/Titles/styles';

export const EnergySourceTile = memo(() => {
  const {
    atom_zainstalowano,
    PV_zainstalowano,
    wiatr_lad_zainstalowano,
    wiatr_offshore_zainstalowano,
    udzial_nowoczesnych_turbin_wiatrowych,
    udzial_bifacji_EW_w_PV,
  } = useAppSelector(getProdukcjaEnergiiElektrycznejInputs);

  const { źródło_dyspozycyjne_max_moc } = useAppSelector(getObliczeniaCalculations);

  const windOffShoreSet = useDispatchAction<ProdukcjaEnergiiElektrycznejInputsPayload>({
    value: wiatr_offshore_zainstalowano,
    action: setProdukcjiEnergiiInputs,
    name: 'wiatr_offshore_zainstalowano',
  });

  const windSet = useDispatchAction<ProdukcjaEnergiiElektrycznejInputsPayload>({
    value: wiatr_lad_zainstalowano,
    action: setProdukcjiEnergiiInputs,
    name: 'wiatr_lad_zainstalowano',
  });

  const photovoltaicSet = useDispatchAction<ProdukcjaEnergiiElektrycznejInputsPayload>({
    value: PV_zainstalowano,
    action: setProdukcjiEnergiiInputs,
    name: 'PV_zainstalowano',
  });

  const noweTurbinyWiatrLadSet = useDispatchAction<ProdukcjaEnergiiElektrycznejInputsPayload>({
    value: udzial_nowoczesnych_turbin_wiatrowych,
    action: setProdukcjiEnergiiInputs,
    name: 'udzial_nowoczesnych_turbin_wiatrowych',
    percentage: true,
  });

  const PVBifacjaSet = useDispatchAction<ProdukcjaEnergiiElektrycznejInputsPayload>({
    action: setProdukcjiEnergiiInputs,
    value: udzial_bifacji_EW_w_PV,
    name: 'udzial_bifacji_EW_w_PV',
    percentage: true,
  });

  const atomSet = useDispatchAction<ProdukcjaEnergiiElektrycznejInputsPayload>({
    value: atom_zainstalowano,
    action: setProdukcjiEnergiiInputs,
    name: 'atom_zainstalowano',
  });
  const { t } = useTranslation();
  return (
    <STile id={SIMULATOR_INTRO_STEPS.ENERGY_SOURCES}>
      <TileTitle id="energy-source-tile" border="#dde1e6" tooltipContent={t(keys.ENERGY_SOURCES_TILE_TOOLTIP)}>
        <span>{t(keys.ENERGY_SOURCES_TILE)}</span>
      </TileTitle>
      <Spacer mb="6px">
        <DataInput
          unitColor="#358F86"
          label={t(keys.OFFSHORE_WIND_LABEL)}
          id={'wind-off-shore'}
          labelTooltipId="TOOLTIP_ENERGY_SOURCE_TILE_OFSHORE_WIND"
          labelTooltipContent={t(keys.TOOLTIP_ENERGY_SOURCE_TILE_OFSHORE_WIND)}
          unit={t(keys.GW)}
          {...windOffShoreSet}
        />
      </Spacer>

      <Spacer mb="6px">
        <DataInput
          unitColor="#358F86"
          label={t(keys.ONSHORE_WIND_LABEL)}
          id={'wind-on-land'}
          labelTooltipId="TOOLTIP_ENERGY_SOURCE_TILE_ONSHORE_WIND"
          labelTooltipContent={t(keys.TOOLTIP_ENERGY_SOURCE_TILE_ONSHORE_WIND)}
          unit={t(keys.GW)}
          {...windSet}
        />
      </Spacer>
      <Spacer mb="6px" pl="20px">
        <DataInput
          unitColor="#358F86"
          label={<span style={{ color: '#4a4c53' }}>{t(keys.ONSHORE_WIND_NEW_TURBINES_LABEL)}</span>}
          id={'wind-on-shore-new-turbines'}
          unit="%"
          {...noweTurbinyWiatrLadSet}
        />
      </Spacer>

      <Spacer mb="6px">
        <DataInput
          unitColor="#358F86"
          label={t(keys.PHOTOVOLTAICS)}
          id={'photovoltaic'}
          labelTooltipId="TOOLTIP_ENERGY_SOURCE_TILE_PHOTOVOLTAICS"
          labelTooltipContent={t(keys.TOOLTIP_ENERGY_SOURCE_TILE_PHOTOVOLTAICS)}
          unit={t(keys.GW)}
          {...photovoltaicSet}
        />
      </Spacer>
      <Spacer mb="6px" pl="20px">
        <DataInput
          unitColor="#358F86"
          label={<span style={{ color: '#4a4c53' }}>{t(keys.PV_BIFACJA)}</span>}
          id={'PV-bifacja'}
          unit="%"
          {...PVBifacjaSet}
        />
      </Spacer>

      <Spacer mb="6px">
        <DataInput
          unitColor="#358F86"
          label={t(keys.NUCLEAR_POWER_PLANTS)}
          id={'atom'}
          labelTooltipId="TOOLTIP_ENERGY_SOURCE_TILE_NUCLEAR_POWER_PLANTS"
          labelTooltipContent={t(keys.TOOLTIP_ENERGY_SOURCE_TILE_NUCLEAR_POWER_PLANTS)}
          unit={t(keys.GW)}
          {...atomSet}
        />
      </Spacer>
      <DataInput
        unitColor="#358F86"
        label={t(keys.DYSP_GAS_PLANTS)}
        id={'natural-gas'}
        unit={t(keys.GW)}
        labelTooltipId="TOOLTIP_ENERGY_SOURCE_TILE_DYSP_GAS_PLANTS"
        labelTooltipContent={t(keys.TOOLTIP_ENERGY_SOURCE_TILE_DYSP_GAS_PLANTS)}
        value={źródło_dyspozycyjne_max_moc.toFixed()}
        onChange={() => {}}
        hideControls
      />
    </STile>
  );
});
