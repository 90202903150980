import styled from '@emotion/styled';
import { Button } from 'components/Button/Button';
export const STitle = styled.h1`
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  margin-bottom: 12px;
`;

export const STopSection = styled.section`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  column-gap: 80px;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 100px;

  max-width: 1200px;
`;

export const SExcelBtn = styled(Button)`
  margin-bottom: 32px;
  width: fit-content;
  padding-left: 40px;
  padding-right: 40px;
  margin: 0;

  svg {
    margin-right: 10px;
  }
`;

export const SImgCol = styled.div`
  display: flex;
  align-items: center;
  img {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }
`;

export const SYouTube = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .player {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
