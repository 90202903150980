import keys from 'i18n/keys';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';
import { HeatExport } from '../types';
import { AvailableYear } from 'services/consts/availableYears';
import { HEAT_EXPORT_LABELS } from './consts';

type heatExportArgs = {
  selectedYear: AvailableYear;
  t: (key: string) => string;
  data: number[];
};

export const heatExport = ({ data, selectedYear, t }: heatExportArgs) => {
  const heatExportData = data.reduce((acc, averageTemp, index) => {
    acc[index] = {
      day: index + 1,
      date: moment.utc(`01-01-${selectedYear} 00:00:00`, 'DD-MM-YYYY hh:mm:ss').add(index, 'day').format('DD-MM-YYYY'),
      avg_temp: +parseFloat(averageTemp.toFixed(10)),
    };
    return acc;
  }, [] as HeatExport[]);

  const worksheet = utils.book_new();
  const translated = HEAT_EXPORT_LABELS.map(el => t(el) || el);
  utils.sheet_add_aoa(worksheet, [translated]);
  utils.sheet_add_json(worksheet, heatExportData, { skipHeader: true, origin: 'A2' });

  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Sheet');
  worksheet['!cols'] = [{ wch: 7 }, { wch: 15 }, { wch: 20 }];
  writeFile(workbook, `${t(keys.XLS_HEAT_DOWNLOAD_NAME) || keys.XLS_HEAT_DOWNLOAD_NAME}.xlsx`);
};
