import styled from '@emotion/styled';
import { InputProps } from './Input';

export const InputCnt = styled.div<{ variant: InputProps['variant'] }>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 4px;
`;
export const EndAdornment = styled.div`
  margin-left: 8px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: ${({ theme }) => theme.addAlpha(theme.primary, 0.5)};
  align-self: center;
`;
EndAdornment.defaultProps = {
  className: 'end-adornment',
};
export const SInput = styled.input<InputProps>`
  border: none;
  background-color: ${({ error, theme }) => (error ? theme.addAlpha(theme.errorColor, 0.08) : 'transparent')};
  margin: 0 4px;
  text-align: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme, error }) => (error ? theme.errorColor : theme.addAlpha(theme.primary, 0.24))};
  padding: 0 4px;
  width: 100%;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  -moz-appearance: textfield;
  color: ${({ theme }) => theme.primary};
  font-family: inherit;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    opacity: 1;
    display: none;
  }

  &:disabled {
    border-bottom-color: transparent;
    color: ${({ theme }) => theme.disabledColor};
  }

  &.input--with-mask {
    text-align: left;
  }
`;

export const InputMask = styled.div<{ error: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.bodyBg};
  z-index: 1;
  cursor: text;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme, error }) => (error ? theme.errorColor : theme.addAlpha(theme.primary, 0.24))};
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0 8px;

  &.dummy {
    border: none;
  }

  .firstCol,
  .secondCol {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .firstCol {
    text-align: right;
    &.firstCol--with-space {
      padding-right: 4.6px;
    }
  }
  .secondCol {
    text-align: left;
  }
`;
