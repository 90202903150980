import keys from 'i18n/keys';
import { SPartnersBanner } from 'pages/Contact/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SFooter } from './styles';

export const Footer = props => {
  const { t } = useTranslation();
  return (
    <SFooter {...props}>
      <SPartnersBanner />
    </SFooter>
  );
};
