import { TextSelect } from 'components/Select/TextSelect';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { AVIALABLE_LANGUAGES } from 'services/configuration/i18n/consts';
import { getCurrentLanguage, useCurrentLang } from 'services/configuration/i18n/utils';

const options = AVIALABLE_LANGUAGES.map(lang => ({ label: lang.toUpperCase(), value: lang }));
const lang = options.find(el => el.value === getCurrentLanguage())!;
export const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const currentLang = useCurrentLang();

  const [langValue, setLangValue] = useState(lang);
  const handleChange = (val: typeof options[number]) => {
    i18n.changeLanguage(val.value);
    setLangValue(val);
    if (currentLang === 'en') {
      const path = location.pathname.replace('/en/', '/');
      navigate(path);
    } else {
      navigate(`/en${location.pathname}`);
    }
  };
  useEffect(() => {
    const lang = options.find(el => el.value === i18n.language)!;
    setLangValue(lang);
  }, [i18n.language]);

  return (
    <TextSelect
      options={options}
      value={langValue}
      onChange={handleChange}
      displayValue={langValue?.label?.toUpperCase?.()}
      menuPlacement="top"
    />
  );
};
