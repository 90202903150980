import { Card } from 'components/common';
import React, { useState } from 'react';
import { BtnRow, Content, GroupDataCnt, SButton } from './GroupedData.styles';
import { Button } from 'components/Button/Button';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { ViewType } from './types';
import keys from 'i18n/keys';
import { useTranslation } from 'react-i18next';
import { HeatBalance } from './HeatBalance/HeatBalance';
import { HydrogenPie } from './Hydrogen/Hydrogen';
import { ConstructionCost } from './ConstructionCost/ConstructionCost';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';
import { dotToComma } from 'services/utils/roundNumb';

export const GroupedData = () => {
  const [view, setView] = useState<ViewType>('costs-data');
  const [isProdukcjaWodoruWystarczajaca, setIsProdukcjaWodoruWystarczajaca] = useState(true);
  const [wodorStats, setWodorStats] = useState({
    produkcja: 0,
    zapotrzebowanie: 0,
  });
  const { t } = useTranslation();

  return (
    <GroupDataCnt>
      <BtnRow>
        <SButton
          variant={view === 'costs-data' ? 'outlined' : 'text'}
          startIcon={<Tooltip place="top" id="costs-data" content={t(keys.COSTS_BTN_TOOLTIP)} />}
          onClick={() => setView('costs-data')}
          id={SIMULATOR_INTRO_STEPS.CONSTRUCTION_COSTS}
        >
          {t(keys.INFRASTRUCTURE_COST)}
        </SButton>
        <SButton
          variant={view === 'heat-balance-data' ? 'outlined' : 'text'}
          onClick={() => setView('heat-balance-data')}
          startIcon={<Tooltip place="top" id="heat-balance-data" content={t(keys.HEAT_BALANCE_BTN_TOOLTIP)} />}
          id={SIMULATOR_INTRO_STEPS.BILANS_OGRZEWANIA}
        >
          {t(keys.HEAT_BALANCE)}
        </SButton>

        <SButton
          variant={view === 'elektrolyze-data' ? 'outlined' : 'text'}
          startIcon={<Tooltip place="top" id="elektrolyze-data" content={t(keys.ELEKTROLYZE_BTN_TOOLTIP)} />}
          onClick={() => setView('elektrolyze-data')}
          id={SIMULATOR_INTRO_STEPS.ELEKTROLIZA}
          isProdukcjaWodoruWystarczajaca={wodorStats.produkcja >= wodorStats.zapotrzebowanie}
        >
          {t(keys.ELECTROLYSIS)}
          {wodorStats.zapotrzebowanie > 0 && (
            <>
              : {dotToComma(wodorStats.produkcja)}/{dotToComma(wodorStats.zapotrzebowanie)} {t(keys.MT)}
            </>
          )}
        </SButton>
      </BtnRow>
      <Content isVisible={view === 'heat-balance-data'}>
        <HeatBalance />
      </Content>
      <Content isVisible={view === 'costs-data'}>
        <ConstructionCost />
      </Content>
      <Content isVisible={view === 'elektrolyze-data'}>
        <HydrogenPie setWodorStats={setWodorStats} />
      </Content>
    </GroupDataCnt>
  );
};
