import styled from '@emotion/styled';

export const SelectCnt = styled.div`
  &.select-menu-right-corner {
    .select__menu {
      border: 1px solid #dfe1e6;
      right: 0;
    }
  }
`;
export const StartIconCnt = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export const ControlCnt = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &.select-outlined {
    padding: 8px 12px;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #dfe1e6;
  }

  &.select-menu-right-corner {
  }

  &.select-standard {
    background-color: #edf0f3;
    padding: 2px 4px 2px 6px;
    border-radius: 8px;
  }

  &.select-standard-white {
    background-color: #fff;
    padding: 2px 4px 2px 6px;
    border-radius: 8px;
  }

  .select__indicator {
    margin-left: 8px;
  }

  &.select-fullwidth {
    width: 100%;
  }
`;
