import styled from '@emotion/styled';
export const SCnt = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
`;

export const OtherCnt = styled.div<{ inputWidth: number | null }>`
  display: flex;
  align-items: center;
  min-width: ${({ inputWidth }) => (inputWidth ? `${inputWidth}px` : '')};
  position: relative;
`;

export const SInput = styled.input<{ error: boolean }>`
  border: none;
  background-color: ${({ error, theme }) => (error ? theme.addAlpha(theme.errorColor, 0.08) : '#fff')};
  padding: 0 4px;
  width: 42px;
  height: 20px;
  text-align: center;
  font-weight: 600;
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    display: none;
  }
  &:disabled {
    color: ${({ theme }) => theme.disabledColor};
  }
`;

export const SUnit = styled.span<{ unitColor?: string }>`
  color: ${({ unitColor }) => unitColor};
  margin-left: 4px;
`;

export const SLabel = styled.label`
  padding-right: 8px;
  flex-grow: 1;
  display: flex;
  gap: 4px;
`;

export const SChangeBtn = styled.button<{ hideControls: boolean }>`
  width: 17px;
  height: 17px;
  padding: 0;
  border: none;
  /* background-color: #edf0f3; */
  background: rgba(207, 215, 223, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${({ hideControls }) => (hideControls ? 'hidden' : 'visible')};
  color: ${({ theme }) => theme.primary};

  &:disabled {
    color: #8b8d8f;
  }

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
