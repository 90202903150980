import { Input } from 'components/Input/Input';
import { Table, TBody, Td, THead, Tr } from 'components/Table/Transparent';
import { TileTitle } from 'components/Titles/TileTitle';
import keys from 'i18n/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';
import { KosztMldPerGW } from 'services/slices/obliczeniaSlice/koszty/types';
import { setKosztyMldPerGW } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { getKoszty } from 'services/slices/obliczeniaSlice/selectors';
import { roundNumb } from 'services/utils/roundNumb';

export const Storage = () => {
  const {
    calculations: {
      baterieLitowe,
      elektrownieSzczytowoPompowe,
      magazynCiepłaDuży,
      magazynCiepłaDomowe,
      magazynCiepłaPrzemyslowy,
    },
    koszt_mld_per_GW: {
      baterie_litowe_stacjonarne,
      elektrownie_szczytowo_pompowe,
      magazyny_ciepla_duze,
      magazyny_ciepla_domowe,
      magazyny_ciepla_przemyslowe,
    },
  } = useAppSelector(getKoszty);
  const dispatch = useAppDispatch();
  const handleChange = (name: keyof KosztMldPerGW) => (val: string) => {
    dispatch(setKosztyMldPerGW({ name, value: Math.max(0, +val) }));
  };
  // const handlePaliwoChange = (name: keyof O_M_paliwoType) => (val: string) => {
  //   dispatch(setO_M_paliwo({ name, value: Math.max(0, +val) }));
  // };

  // const handleUtrzymanieChange = (name: keyof O_M_utrzymanieType) => (val: string) => {
  //   dispatch(setO_M_utrzymanie({ name, value: Math.max(0, +val) }));
  // };
  const { t } = useTranslation();
  return (
    <Table>
      <THead>
        <Tr cols={6}>
          <Td>
            <TileTitle variant="small">{t(keys.ENERGY_STORAGE)}</TileTitle>
          </Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.CAPACITY_GWH) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.MLD_Z_GWH) }}></Td>
          <Td className="empty"></Td>
          <Td className="empty"></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.SUM_MLD_ZL) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.LCOE_UNIT) }}></Td>
        </Tr>
      </THead>
      <TBody>
        <Tr cols={6}>
          <Td>{t(keys.LITHIUM_STACIONARY)}</Td>
          <Td>{baterieLitowe.nowa_moc_zainstalowana}</Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleChange('baterie_litowe_stacjonarne')}
              value={baterie_litowe_stacjonarne}
            />
          </Td>
          <Td></Td>
          <Td></Td>
          <Td>{roundNumb(baterieLitowe.koszt)}</Td>
          <Td>-</Td>
        </Tr>
        <Tr cols={6}>
          <Td>{t(keys.PUMP_POWER_PLANTS)}</Td>
          <Td>{roundNumb(elektrownieSzczytowoPompowe.nowa_moc_zainstalowana)}</Td>

          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleChange('elektrownie_szczytowo_pompowe')}
              value={elektrownie_szczytowo_pompowe}
            />
          </Td>
          <Td></Td>
          <Td></Td>
          <Td>{roundNumb(elektrownieSzczytowoPompowe.koszt)}</Td>
          <Td>-</Td>
        </Tr>
        <Tr cols={6}>
          <Td>{t(keys.HEAT_STORAGE_TTES)}</Td>
          <Td>{roundNumb(magazynCiepłaDuży.nowa_moc_zainstalowana)}</Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleChange('magazyny_ciepla_duze')}
              value={magazyny_ciepla_duze}
            />
          </Td>
          <Td></Td>
          <Td></Td>
          <Td>{roundNumb(magazynCiepłaDuży.koszt)}</Td>
          <Td>-</Td>
        </Tr>
        <Tr cols={6}>
          <Td>{t(keys.HEAT_STORAGE_HOME)}</Td>
          <Td>{roundNumb(magazynCiepłaDomowe.nowa_moc_zainstalowana)}</Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleChange('magazyny_ciepla_domowe')}
              value={magazyny_ciepla_domowe}
            />
          </Td>
          <Td></Td>
          <Td></Td>
          <Td>{roundNumb(magazynCiepłaDomowe.koszt)}</Td>
          <Td>-</Td>
        </Tr>
        <Tr cols={6}>
          <Td>{t(keys.MAGAZYN_PRZEMYSLOWY_KOSZT)}</Td>
          <Td>{roundNumb(magazynCiepłaPrzemyslowy.nowa_moc_zainstalowana)}</Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleChange('magazyny_ciepla_przemyslowe')}
              value={magazyny_ciepla_przemyslowe}
            />
          </Td>
          <Td></Td>
          <Td></Td>
          <Td>{roundNumb(magazynCiepłaPrzemyslowy.koszt)}</Td>
          <Td>-</Td>
        </Tr>
      </TBody>
    </Table>
  );
};
