import { SelectOption } from 'components/Select/types';
import { useAppDispatch } from 'services/configuration/store/hooks';
import { obliczeniaSlice } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { ValueOf } from 'services/types/Values';

type SelectDispatchAction<T extends { name: string; value: string | number }> = {
  action: ValueOf<typeof obliczeniaSlice.actions>;
  options: readonly SelectOption[];
  selectedValue: T['value'];
  name: T['name'];
};

export const useSelectDispatchAction = <T extends { name: string; value: string | number }>({
  selectedValue,
  action,
  options,
  name,
}: SelectDispatchAction<T>) => {
  const dispatch = useAppDispatch();
  const value = options.find(option => option.value === selectedValue)!;
  const onChange = (val: SelectOption) => {
    dispatch(
      action({
        name: name,
        value: val.value,
      } as never),
    );
  };
  return {
    value,
    onChange,
    options,
  };
};
