import { useEffect } from 'react';
import { useAppDispatch } from 'services/configuration/store/hooks';
import { AvailableYear, YearData, availableYears, defaultYear } from 'services/consts/availableYears';
import { setYearsData } from 'services/slices/obliczeniaSlice/obliczeniaSlice';

const yearsToLoad = availableYears.filter(el => el !== defaultYear);

export const AsyncLoadData = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const loadData = async () => {
      const res: YearData[] = await Promise.all(
        yearsToLoad.map(entry => import(`data/${entry}`).then(res => res.data)),
      );
      const data = res.reduce((acc, curr, index) => {
        acc[yearsToLoad[index]] = curr;
        return acc;
      }, {} as Record<AvailableYear, YearData>);
      dispatch(setYearsData(data));
    };
    loadData();
  }, []);
  return null;
};
