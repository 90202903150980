import styled from '@emotion/styled';

export const SFooter = styled.footer`
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;

  p {
    margin-top: 10px;
    text-align: center;
    align-self: center;
    flex-grow: 1;
  }
`;
