import keys from 'i18n/keys';
import React from 'react';
import {
  Border,
  Cell,
  CellText,
  Grid,
  HeadRow,
  Row,
  RowGroup,
  RowTitle,
  STooltip,
  TableTitle,
  TileView,
} from './sharedStyles';

import { useAppSelector } from 'services/configuration/store/hooks';
import { useDispatchAction } from 'hooks/useDispatchAction';

import { useTranslation } from 'react-i18next';
import {
  setKosztyAktualnaInfrastruktura,
  setObliczeniaConsts,
  setObliczeniaInputs,
} from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import {
  ObliczeniaTypeConstsPayload,
  ObliczeniaTypeInputsPayload,
} from 'services/slices/obliczeniaSlice/obliczenia/types';
import { KosztyAktualnaInfrastrukturaPayload } from 'services/slices/obliczeniaSlice/koszty/types';
import { getKoszty, getObliczenia } from 'services/slices/obliczeniaSlice/selectors';
import { DataInput } from 'components/DataInput/DataInput';
import classNames from 'classnames';

type EnergyStorageTileProps = {
  tileView?: TileView;
};

export const EnergyStorageTile = ({ tileView = 'default' }: EnergyStorageTileProps) => {
  const {
    inputs: { baterie_litowe_stacjonarne, baterie_samochodowe, elektrownie_szczytowo_pompowe },
    calculations: { pojemnosc_magazynu_pradu },
    consts: { sprawnosc_magazynow_pradu, czas_ladowania_rozladowania_magazynu_pradu },
  } = useAppSelector(getObliczenia);

  const { aktualna_infrastruktura } = useAppSelector(getKoszty);

  const lithiumSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'baterie_litowe_stacjonarne',
    value: baterie_litowe_stacjonarne,
    max: 9999,
  });
  const lithiumInfrastructure = useDispatchAction<KosztyAktualnaInfrastrukturaPayload>({
    action: setKosztyAktualnaInfrastruktura,
    name: 'baterie_litowe_stacjonarne',
    value: aktualna_infrastruktura.baterie_litowe_stacjonarne,
    max: 9999,
  });

  const energyStorageSet = useDispatchAction<ObliczeniaTypeConstsPayload<'sprawnosc_magazynow_pradu'>>({
    action: setObliczeniaConsts,
    name: 'sprawnosc_magazynow_pradu',
    value: sprawnosc_magazynow_pradu,
    percentage: true,
  });

  const carBatteriesSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'baterie_samochodowe',
    value: baterie_samochodowe,
    max: 9999,
  });

  const pumpedStorageSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'elektrownie_szczytowo_pompowe',
    value: elektrownie_szczytowo_pompowe,
    max: 9999,
  });
  const pumpedStorageInfrastructure = useDispatchAction<KosztyAktualnaInfrastrukturaPayload>({
    action: setKosztyAktualnaInfrastruktura,
    name: 'elektrownie_szczytowo_pompowe',
    value: aktualna_infrastruktura.elektrownie_szczytowo_pompowe,
    max: 9999,
  });

  const czasLadowaniaMagazynuPraduSet = useDispatchAction<
    ObliczeniaTypeConstsPayload<'czas_ladowania_rozladowania_magazynu_pradu'>
  >({
    action: setObliczeniaConsts,
    name: 'czas_ladowania_rozladowania_magazynu_pradu',
    value: czas_ladowania_rozladowania_magazynu_pradu,
    max: 24,
  });

  const sumCapacity = pojemnosc_magazynu_pradu.toFixed();
  const sumInfrastructure = (
    aktualna_infrastruktura.baterie_litowe_stacjonarne + aktualna_infrastruktura.elektrownie_szczytowo_pompowe
  ).toFixed();
  const { t } = useTranslation();
  const columns = tileView === 'default' ? 3 : 1;
  return (
    <Grid
      className={classNames({
        'modal-view': tileView === 'modal',
      })}
    >
      <HeadRow columns={columns}>
        <TableTitle
          reverseFlex
          id="energy-storage-advanced"
          tooltipContent={t(keys.TOOLTIP_ENERGY_STORAGE_TILE_CONTENT)}
        >
          {t(keys.ENERGY_STORAGE_ADVANCED)}
        </TableTitle>
        <RowTitle color="#358F86">{t(keys.CAPACITY_GWEL)}</RowTitle>
        {tileView === 'default' && (
          <>
            <RowTitle color="#AD6800">{t(keys.EXISTING_GWEL)}</RowTitle>
            <RowTitle color="#358F86">{t(keys.EFFICIENCY)}</RowTitle>
          </>
        )}
      </HeadRow>
      <RowGroup>
        <Row columns={columns}>
          <Cell>
            <STooltip
              id="lithium-batteries-cell"
              content={t(keys.TOOLTIP_ENERGY_STORAGE_TILE_LITHYUM_STATIONARY_BATTERIES)}
            />
            <CellText className="no-space">{t(keys.LITHYUM_STATIONARY_BATTERIES)}</CellText>
          </Cell>
          <Cell>
            <DataInput {...lithiumSet} id="lithiumSet" />
          </Cell>
          {tileView === 'default' && (
            <Cell>
              <DataInput {...lithiumInfrastructure} id="lithiumInfrastructure" />
            </Cell>
          )}
        </Row>
        <Row columns={columns}>
          <Cell>
            <STooltip id="car-batteries-cell" content={t(keys.TOOLTIP_ENERGY_STORAGE_TILE_CAR_BATTERIES)} />
            <CellText className="no-space">{t(keys.CAR_BATTERIES)}</CellText>
          </Cell>
          <Cell>
            <DataInput {...carBatteriesSet} id="carBatteriesSet" />
          </Cell>
          <Cell></Cell>
          {tileView === 'default' && (
            <Cell>
              <DataInput {...energyStorageSet} id="energyStorageSet" />
            </Cell>
          )}
        </Row>
        <Row columns={columns}>
          <Cell>
            <STooltip
              id="water-pump-storage-cell"
              content={t(keys.TOOLTIP_ENERGY_STORAGE_TILE_PUMPED_STORAGE_POWER_PLANTS)}
            />
            <span>{t(keys.PUMPED_STORAGE_POWER_PLANTS)}</span>
          </Cell>
          <Cell>
            <DataInput {...pumpedStorageSet} id="pumpedStorageSet" />
          </Cell>
          {tileView === 'default' && (
            <Cell>
              <DataInput {...pumpedStorageInfrastructure} id="pumpedStorageInfrastructure" />
            </Cell>
          )}
        </Row>
        <Row>
          <div></div>
          <Border />
          <Border />
          <Border />
        </Row>
        <Row columns={columns}>
          <Cell className="bigger-font">
            <STooltip id="energy-storage-advanced-cell" content={t(keys.TOOLTIP_ENERGY_STORAGE_TILE_SUM)} />
            <CellText>{t(keys.SUM)}</CellText>
          </Cell>
          <Cell>
            <DataInput hideControls value={sumCapacity} id="sumCapacity" />
          </Cell>
          {tileView === 'default' && (
            <Cell>
              <DataInput hideControls value={sumInfrastructure} id="sumInfrastructure" />
            </Cell>
          )}
        </Row>
        {tileView === 'default' && (
          <>
            <Row columns={1}>
              <Cell>
                <CellText>{t(keys.CHARGIN_DISCHARGING)}</CellText>
              </Cell>
              <Cell>
                <DataInput {...czasLadowaniaMagazynuPraduSet} id="czas-ladowania-rozladowania-magazynu-pradu" />
              </Cell>
            </Row>
          </>
        )}
      </RowGroup>
    </Grid>
  );
};
