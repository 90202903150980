import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AVIALABLE_LANGUAGES } from './consts';
i18next.use(initReactI18next).init({
  // fallbackLng: 'pl',
  lng: 'pl',
  nsSeparator: false,
  keySeparator: false,
  supportedLngs: AVIALABLE_LANGUAGES,
  interpolation: { escapeValue: false },
  react: {
    useSuspense: false,
  },
});
