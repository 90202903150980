import React from 'react';
import { useLocation } from 'react-router';

import { SEnLogo, SLoadingCnt, SLoadingText, SText } from './styles';

export const Loading = () => {
  const { pathname } = useLocation();
  const isENLang = pathname.startsWith('/en');

  return (
    <SLoadingCnt>
      <div>
        {isENLang ? <SEnLogo /> : <SLoadingText />}

        <SText>{isENLang ? 'Portal is loading...' : 'Ładowanie portalu...'}</SText>
      </div>
    </SLoadingCnt>
  );
};
