import { cloneDeep, merge } from 'lodash';

import { ProdukcjaEnergiiElektrycznejType } from 'services/slices/obliczeniaSlice/produkcjaEnergiiElektrycznej/types';
import { QueryParamsPayload, ScenarioDataPayload } from 'services/slices/obliczeniaSlice/types';
import { getSearchParam } from './utils';

export const mergeProdukcjaEnergii = (
  produkcjaEnergii: ProdukcjaEnergiiElektrycznejType,
  scenarioData: ScenarioDataPayload,
) => {
  const clone: QueryParamsPayload['produkcjaEnergiiElektrycznej'] = cloneDeep(produkcjaEnergii);
  const produkcjaObj = getSearchParam('produkcjaEnergii') || '{}';
  const mergedWithScenario = merge(clone, scenarioData.produkcjaEnergiiElektrycznej);
  const { inputs, consts }: QueryParamsPayload['produkcjaEnergiiElektrycznej'] = merge(
    mergedWithScenario,
    JSON.parse(produkcjaObj),
  );

  return { inputs, consts };
};
