import styled from '@emotion/styled';

export const Cnt = styled.span`
  display: flex;
  align-items: center;
`;

export const SLabel = styled.label`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-left: 4px;
`;
