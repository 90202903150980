import keys from 'i18n/keys';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';
import { EXPORT_LABELS } from '../consts';
import { ElectricityExport } from '../types';
import { AvailableYear } from 'services/consts/availableYears';
// import {
//   getEnergyDemand,
//   getPhotovoltaicProduction,
//   getWindOffshoreProduction,
//   getWindProduction,
// } from 'data/old-data';

type electricityExportDataArgs = {
  selectedYear: AvailableYear;
  t: (key: string) => string;
  energy_demand: number[];
  wind: number[];
  wiatr_lad_6MGW: number[];
  windOffShore: number[];
  photovoltaic: number[];
  PV_wsch_zach: number[];
};

export const electricityExportData = ({
  selectedYear,
  t,
  energy_demand,
  photovoltaic,
  wind,
  windOffShore,
  PV_wsch_zach,
  wiatr_lad_6MGW,
}: electricityExportDataArgs) => {
  const electricityExport = energy_demand.reduce((acc, energyDemand, index) => {
    acc[index] = {
      hour: index,
      date: moment
        .utc(`01-01-${selectedYear} 00:00:00`, 'DD-MM-YYYY hh:mm:ss')
        .add(index, 'hour')
        .format('DD-MM-YYYY HH:mm'),
      energy_demand: +parseFloat(energyDemand.toFixed(3)),
      windOffShore: +parseFloat(windOffShore[index].toFixed(3)),
      wind: +parseFloat(wind[index].toFixed(3)),
      wiatr_lad_6MGW: +parseFloat(wiatr_lad_6MGW[index].toFixed(3)),
      photovoltaic: +parseFloat(photovoltaic[index].toFixed(3)),
      PV_wsch_zach: +parseFloat(PV_wsch_zach[index].toFixed(3)),
    };
    return acc;
  }, [] as ElectricityExport[]);

  const worksheet = utils.book_new();
  const translated = EXPORT_LABELS.electricity.map(el => t(el) || el);
  utils.sheet_add_aoa(worksheet, [translated]);
  utils.sheet_add_json(worksheet, electricityExport, { skipHeader: true, origin: 'A2' });

  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Sheet');
  worksheet['!cols'] = [
    { wch: 7 },
    { wch: 15 },
    { wch: 27 },
    { wch: 37 },
    { wch: 35 },
    { wch: 35 },
    { wch: 28 },
    { wch: 35 },
  ];
  writeFile(workbook, `${t(keys.XLS_ENERGY_DOWNLOAD_NAME) || keys.XLS_ENERGY_DOWNLOAD_NAME}.xlsx`);
};
