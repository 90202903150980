import keys from 'i18n/keys';
import React from 'react';
import { Cell, Grid, HeadRow, Row, RowGroup, STooltip, TableTitle, SpecificCardRow } from './sharedStyles';

import { useAppSelector } from 'services/configuration/store/hooks';
import { useDispatchAction } from 'hooks/useDispatchAction';
import { useTranslation } from 'react-i18next';
import { getObliczeniaInputs } from 'services/slices/obliczeniaSlice/selectors';
import { setTransportInputs } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { ObliczeniaTypeTransportPayload } from 'services/slices/obliczeniaSlice/obliczenia/types';
import { DataInput } from 'components/DataInput/DataInput';
import { TransportCollapsible } from 'features/Cards/SpecificInformationCard/Tiles/ConsumptionTile/TransportCollapsible';
import { Box } from 'components/common';

export const TransportTile = () => {
  const {
    transport: {
      ilość_aut_osobowych,
      udział_kolei_w_transporcie,
      inne_rodzaje_transportu,
      liczba_pasażerokilometrów,
      wolumen_transportu_towarów,
      zużycie_energii_aut_osobowych,
      średni_przejeżdżany_dystans_aut_osobowych,
    },
  } = useAppSelector(getObliczeniaInputs);

  const iloscAutSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'ilość_aut_osobowych',
    value: ilość_aut_osobowych,
  });
  const zuzycieEnergiiAutSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'zużycie_energii_aut_osobowych',
    value: zużycie_energii_aut_osobowych,
  });
  const sredniDystansAutSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'średni_przejeżdżany_dystans_aut_osobowych',
    value: średni_przejeżdżany_dystans_aut_osobowych,
  });
  const liczbaPasazeroKmSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'liczba_pasażerokilometrów',
    value: liczba_pasażerokilometrów,
    percentage: true,
    max: 200,
  });
  const transportTowarowSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'wolumen_transportu_towarów',
    value: wolumen_transportu_towarów,
    percentage: true,
    max: 200,
  });
  const udzialKoleiSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'udział_kolei_w_transporcie',
    value: udział_kolei_w_transporcie,
    percentage: true,
    max: 200,
  });
  const transportInneSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'inne_rodzaje_transportu',
    value: inne_rodzaje_transportu,
    percentage: true,
    max: 200,
  });
  const { t } = useTranslation();
  return (
    <Grid>
      <HeadRow columns={1}>
        <TableTitle
          reverseFlex
          id="electric-transport-advanced"
          tooltipContent={t(keys.TOOLTIP_TRANSPORT_TILE_CONTENT)}
        >
          {t(keys.ELECTRIFIED_TRANSPORT_LABEL)}
        </TableTitle>
      </HeadRow>
      <SpecificCardRow>
        {/* <STooltip id="transport-tile-single" content={t(keys.TOOLTIP_TRANSPORT_SINGLE_CONTROL)} /> */}
        <div className="card">
          <TransportCollapsible />
        </div>
      </SpecificCardRow>
    </Grid>
  );
};
