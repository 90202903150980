import { DataInput } from 'components/DataInput/DataInput';
import { TileTitle } from 'components/Titles/TileTitle';
import { useDispatchAction } from 'hooks/useDispatchAction';
import keys from 'i18n/keys';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';

import { Label, STile } from '../styles';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';
import { getElasitcityOptions } from 'services/consts/elasticityOptions';
import { setObliczeniaInputs } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { ObliczeniaTypeInputsPayload } from 'services/slices/obliczeniaSlice/obliczenia/types';
import { getObliczenia } from 'services/slices/obliczeniaSlice/selectors';
import { Box, Spacer } from 'components/common';
import { TransportCollapsible } from './TransportCollapsible';
import { CieploTile } from './CieploTile';
import { Select } from 'components/Select/Select';
import { WithInputContext } from 'components/DataInput/WithInputContext';
import { Tooltip } from 'components/Tooltip/Tooltip';

export const ConsumptionTile = () => {
  const {
    inputs: { mnożnik_zapotrzebowania_KSE, wspolczynnik_elastyczności, zapotrzebowanie_na_cieplo_w_przemysle },
  } = useAppSelector(getObliczenia);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const energyDemandMultiplierSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'mnożnik_zapotrzebowania_KSE',
    value: mnożnik_zapotrzebowania_KSE,
    percentage: true,
    max: 5,
  });

  const zapotrzebowanieNaCieploWPrzemysleSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'zapotrzebowanie_na_cieplo_w_przemysle',
    value: zapotrzebowanie_na_cieplo_w_przemysle,
    percentage: true,
    max: 200,
  });

  const handleChange = (option: typeof elastycznoscOpcje[0]) => {
    dispatch(
      setObliczeniaInputs({
        name: 'wspolczynnik_elastyczności',
        value: option.value,
      }),
    );
  };
  const elastycznoscOpcje = useMemo(() => getElasitcityOptions(t), [t]);

  return (
    <STile id={SIMULATOR_INTRO_STEPS.DEMAND}>
      <TileTitle border="#dde1e6" id="demand-tile" tooltipContent={t(keys.DEMAND_TILE_TOOLTIP)}>
        <span>{t(keys.DEMAND_TILE)}</span>
      </TileTitle>
      <Spacer mb="6px">
        <DataInput
          label={t(keys.OTHER_ELECTRICITY_CONSUMPTION)}
          id={'energy-demand'}
          labelTooltipId="TOOLTIP_ENERGY_OTHER_DEMAND_TILE_CURRENT_NEED_FOR_POWER"
          labelTooltipContent={t(keys.TOOLTIP_ENERGY_OTHER_DEMAND_TILE_CURRENT_NEED_FOR_POWER)}
          unit={'%'}
          unitColor="#AD6800"
          {...energyDemandMultiplierSet}
        />
      </Spacer>
      <Spacer mb="6px">
        <TransportCollapsible />
      </Spacer>
      <Spacer mb="6px">
        <DataInput
          label={t(keys.CIEPLO_W_PRZEMYSLE_ZAPOTRZEBOWANIE)}
          id={'zapotrzebowanieNaCieploWPrzemysleSet'}
          labelTooltipId="ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED_TOOLTIP"
          labelTooltipContent={t(keys.ZAPOTRZEBOWANIE_CIEPLO_PRZEMYSLOWE_ADVANCED_TOOLTIP)}
          unit={'%'}
          unitColor="#AD6800"
          {...zapotrzebowanieNaCieploWPrzemysleSet}
        />
      </Spacer>
      <Spacer mb="6px">
        <CieploTile />
      </Spacer>
      <WithInputContext
        label={
          <Box d="flex" align="center" gap="4px">
            <Tooltip id="TOOLTIP_ELASTIC_OF_DEMAND_ADVANCED" content={t(keys.TOOLTIP_ELASTIC_OF_DEMAND_ADVANCED)} />
            <span>{t(keys.ELASTIC_OF_DEMAND_ADVANCED)}</span>
          </Box>
        }
      >
        <Select
          variant="standard-white"
          options={elastycznoscOpcje}
          value={elastycznoscOpcje.find(el => el.value === wspolczynnik_elastyczności)!}
          onChange={handleChange}
        />
      </WithInputContext>
    </STile>
  );
};
