import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

import { SelectStyles } from './styles';
import { SelectProps } from './types';
import { ControlCnt, SelectCnt, StartIconCnt } from './Select.styles';

export const Select = ({
  options,
  value,
  onChange,
  className,
  startIcon,
  variant = 'standard',
  showBorder,
  menuStickCorner = 'left',
  fullWidth,
  ...rest
}: SelectProps) => {
  const { t } = useTranslation();
  const optionProps = options.map(el => ({ ...el, label: t(el.label) || el.label }));
  const valProp = { ...value, label: t(value.label) || value.label };
  return (
    <SelectCnt
      className={classNames({
        'select-menu-right-corner': menuStickCorner === 'right',
      })}
    >
      <ReactSelect
        classNamePrefix={'select'}
        styles={SelectStyles}
        options={optionProps as any}
        value={valProp}
        onChange={onChange as any}
        isClearable={false}
        isSearchable={false}
        components={{
          Control: ({ children, innerProps }) => (
            <ControlCnt
              {...innerProps}
              className={classNames(className, 'select', {
                'select-outlined': variant === 'outlined',
                'select-standard': variant === 'standard',
                'select-standard-white': variant === 'standard-white',
                'select-with-border': showBorder,
                'select-fullwidth': fullWidth,
              })}
            >
              {startIcon && <StartIconCnt>{startIcon}</StartIconCnt>}
              {children}
            </ControlCnt>
          ),
        }}
        {...rest}
      />
    </SelectCnt>
  );
};
