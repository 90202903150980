import { ChartData } from 'chart.js';
import { DATASET_LABELS } from 'features/Charts/LineEnergyProduction/consts';
import { ObliczeniaStateSlice } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { DatasetBuilder } from './datasetBuilder';
import { NEW_COLORS } from 'services/consts/colors';
type EnergyDemandVsProduction = {
  filterByPeriod: (_: number, i: number) => boolean;
  productionInputs: ObliczeniaStateSlice['produkcjaEnergiiElektrycznej']['inputs'];
  productionCalculations: ObliczeniaStateSlice['produkcjaEnergiiElektrycznej']['calculations'];
  obliczeniaInputs: ObliczeniaStateSlice['obliczenia']['inputs'];
  obliczeniaCalc: ObliczeniaStateSlice['obliczenia']['calculations'];
};
export const energyDemandVsProduction =
  ({ filterByPeriod, productionCalculations, obliczeniaCalc, obliczeniaInputs }: EnergyDemandVsProduction) =>
  (): ChartData<'line', number[], string>['datasets'] => {
    const datasetBuilder = new DatasetBuilder(filterByPeriod);

    datasetBuilder
      .addData(productionCalculations.atom_produkcja_po_stratach_per_day, {
        showLine: false,
        backgroundColor: NEW_COLORS.atom,
        label: DATASET_LABELS.atom_produkcja_po_stratach_per_day,
        order: 1,
        fill: true,
      })
      .addData(productionCalculations.wiatr_lad_produkcja_po_stratach_per_day, {
        showLine: false,
        backgroundColor: NEW_COLORS.wiatr_lad,
        label: DATASET_LABELS.wiatr_lad_produkcja_po_stratach_per_day,
        order: 2,
        fill: true,
      })
      .addData(productionCalculations.wiatr_offshore_produkcja_po_stratach_per_day, {
        showLine: false,
        backgroundColor: NEW_COLORS.wiatr_morze,
        label: DATASET_LABELS.wiatr_offshore_produkcja_po_stratach_per_day,
        order: 3,
        fill: true,
      })
      .addData(productionCalculations.PV_produkcja_po_stratach_per_day, {
        showLine: false,
        backgroundColor: NEW_COLORS.PV,
        label: DATASET_LABELS.PV_produkcja_po_stratach_per_day,
        order: 4,
        fill: true,
      })
      .addData(obliczeniaCalc.energia_z_magazynu_prądu_per_day, {
        showLine: false,
        backgroundColor: NEW_COLORS.energia_z_magazynu_pradu,
        label: DATASET_LABELS.energia_z_magazynu_prądu_per_day,
        order: 5,
        fill: true,
      })
      .addData(obliczeniaCalc.energia_z_magazynu_ciepla_przemyslowego_per_day, {
        showLine: false,
        backgroundColor: NEW_COLORS.energia_z_magazynu_przemyslowego,
        label: DATASET_LABELS.energia_z_magazynu_ciepla_przemyslowego_per_day,
        order: 6,
        fill: true,
      })
      .addData(obliczeniaCalc.źródła_dyspozycyjne_per_day, {
        showLine: false,
        backgroundColor: NEW_COLORS.zrodla_dyspozycyjne,
        label: DATASET_LABELS.źródła_dyspozycyjne_per_day,
        order: 7,
        fill: true,
      })
      .addData(obliczeniaCalc.energia_z_magazynu_ciepła_per_day, {
        showLine: false,
        backgroundColor: NEW_COLORS.cieplo_kogeneracja,
        label: DATASET_LABELS.energia_z_magazynu_ciepła_per_day,
        order: 8,
        fill: true,
      });

    const zapotrzebowanie_z_elastycznoscia_z_wodorem =
      obliczeniaInputs.moc_elektrolizerow > 0
        ? [
            {
              data: obliczeniaCalc.zapotrzebowanie_z_elastycznoscia_z_wodorem_per_day.filter(filterByPeriod),
              borderColor: NEW_COLORS.zapotrzebowanie_z_elastycznoscia_z_wodorem,
              order: 0,
              label: DATASET_LABELS.zapotrzebowanie_z_elastycznoscia_z_wodorem_per_day,
              borderWidth: 2.5,
            },
          ]
        : [];

    const zapotrzebowanie_z_elastycznoscia =
      obliczeniaInputs.wspolczynnik_elastyczności > 0
        ? [
            {
              data: obliczeniaCalc.zapotrzebowanie_na_prąd_z_elastycznością_per_day.filter(filterByPeriod),
              borderColor: NEW_COLORS.zapotrzebowanie_z_elastycznoscia,
              order: 0,
              label: DATASET_LABELS.zapotrzebowanie_na_prąd_z_elastycznością_per_day,
              borderWidth: 2.5,
            },
          ]
        : [];

    return [
      ...datasetBuilder.build(),
      ...zapotrzebowanie_z_elastycznoscia_z_wodorem,
      ...zapotrzebowanie_z_elastycznoscia,
      {
        data: obliczeniaCalc.zapotrzebowanie_na_prąd_per_day.filter(filterByPeriod),
        borderColor: NEW_COLORS.zapotrzebowanie,
        order: 0,
        label: DATASET_LABELS.zapotrzebowanie_na_prąd_per_day,
        borderWidth: 2.5,
      },
    ];
  };
