import keys from './../../../i18n/keys';
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { SButton, ScenarioTitle } from './styles';
import { ReactComponent as ChooseScenarioIcon } from 'assets/new/menu_open.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'services/configuration/store/hooks';
import { AvailableScenarios } from './AvailableScenarios/AvailableScenarios';
import { Button } from 'components/Button/Button';
import { Box } from 'components/common';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';
import { useSearchParams } from 'react-router-dom';

export const ChooseScenarioCard = () => {
  const { currentScenario } = useAppSelector(state => state.period);
  const [searchParams] = useSearchParams();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  useEffect(() => {
    const scenario = searchParams.get('scenarios');
    if (scenario) {
      setIsDrawerOpen(true);
    }
  }, []);
  const onClick = () => {
    setIsDrawerOpen(true);
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="choose-scenario">
        <SButton fullWidth variant="outlined" onClick={onClick} id={SIMULATOR_INTRO_STEPS.CHOOSE_SCENARIO}>
          <Box className="text">
            <Box d="flex" gap="4px" align="center" mb="6px">
              <Tooltip id="choose-scenario" content={t((currentScenario?.short_description as string) || ' ')} />
              <span>{t(keys.CHOOSE_SCENARIO_SIMULATION)}</span>
            </Box>
            <ScenarioTitle>{t(currentScenario.title)}</ScenarioTitle>
          </Box>
          <div className="choose-btn">
            <ChooseScenarioIcon />
          </div>
        </SButton>
      </div>
      <AvailableScenarios open={isDrawerOpen} onClose={setIsDrawerOpen} />
    </>
  );
};
