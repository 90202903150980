import { defaultYear } from 'services/consts/availableYears';
import { data } from 'data/2023';
import { cieplo } from './cieplo/cieplo';
import { transport } from './transport/transport';
import { yearsData } from './initialValues';
import { zuzycie_pradu_2023, zuzycie_pradu_2023_rok_przestepny } from './dummyData';

export const obliczenia = {
  cieplo,
  inputs: {
    pojemnosc_magazynu_ciepla: 0,
    odsetek_elektrowni_pracujacych_w_kogeneracji: 0,
    odbiorcy_z_magazynami_ciepla_z_siecia_CO: 0,
    odbiorcy_podlaczeni_do_sieci: 0,

    mnożnik_zapotrzebowania_KSE: 0.85,

    wspolczynnik_elastyczności: 0,

    moc_elektrolizerow: 0,

    elektrownie_szczytowo_pompowe: 0,
    baterie_samochodowe: 0,
    baterie_litowe_stacjonarne: 0,

    strata_przy_przesyle: 0.05,

    zapotrzebowanie_na_cieplo_w_przemysle: 0,
    pojemnosc_magazynu_ciepla_w_przemysle: 0,
    odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle: 0,
    odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych: 0,

    transport,
  },
  yearsData: yearsData,
  selectedYear: defaultYear,
  calculations: {
    transport_per_day: [] as number[],
    transport_per_year: 0,

    cieplo_przemyslowe_per_day: [] as number[],
    cieplo_przemyslowe_per_year: 0,

    magazyn_ciepła_przemyslowy_per_day: [] as number[],
    magazyn_ciepła_przemyslowy_per_year: 0,
    ładowanie_magazynu_ciepla_przemyslowego_per_day: [] as number[],
    energia_z_magazynu_ciepla_przemyslowego_per_day: [] as number[],

    cieplo_per_day: [] as number[],
    cieplo_per_year: 0,
    cieplo: {
      CWU_cieplo_PC_suma: 0,
      CO_cieplo_PC_suma: 0,

      CO_energia_z_pc_po_elastycznosci_suma: 0,
      CO_moc_th_pc_po_elastycznosci_suma: 0,
      CO_reszta_ciepla_z_grzalek_po_elastycznosci_suma: 0,

      CWU_dostepna_moc_el_pc_po_elastycznosci_suma: 0,
      CWU_moc_th_pc_po_elastycznosci_suma: 0,
      CWU_reszta_ciepla_z_grzalek_po_elastycznosci_suma: 0,
    },

    energia_elektryczna_per_day: [] as number[],
    energia_elektryczna_per_year: 0,

    elektroliza_per_day: [] as number[],
    elektroliza_per_year: 0,

    źródła_dyspozycyjne_per_day: [] as number[],
    źródła_dyspozycyjne_per_year: 0,
    źródło_dyspozycyjne_max_moc: 0,
    źródło_dyspozycyjne_per_year_ze_stratami: 0,

    magazyn_ciepła_per_day: [] as number[],
    magazyn_ciepła_per_year: 0,
    ładowanie_magazynu_ciepła_per_day: [] as number[],
    energia_z_magazynu_ciepła_per_day: [] as number[],
    max_moc_grzalek_przy_magazynach: 0,

    magazyn_prądu_per_day: [] as number[],
    magazyn_prądu_per_year: 0,
    ładowanie_magazynu_prądu_per_day: [] as number[],
    energia_z_magazynu_prądu_per_day: [] as number[],

    nadwyżka_prądu_per_year: 0,

    kogeneracja_per_year: 0,

    dostarczona_energia_per_day: [] as number[],

    zapotrzebowanie_na_prąd_per_day: [] as number[],
    zapotrzebowanie_na_prąd_z_elastycznością_per_day: [] as number[],
    zapotrzebowanie_z_elastycznoscia_z_wodorem_per_day: [] as number[],

    pojemnosc_magazynu_pradu: 0,
    procent_podlaczenia_do_magazynow_ciepla: 0,
    magazyny_ciepla_duze: 0,
    magazyny_ciepla_domowe: 0,

    wykorzystanie_wiatru_na_ladzie_per_year: 0,
    wykorzystanie_wiatru_na_morzu_per_year: 0,
    wykorzystanie_PV_per_year: 0,
    wykorzystanie_atomu_per_year: 0,

    zapotrzebowanie_na_transport: 0,
  },
  consts: {
    czas_ladowania_rozladowania_magazynu_pradu: 6,

    czas_ladowania_rozladowania_magazynu_ciepla: 50,
    sprawnosc_magazynow_ciepla: 0.98,

    sprawnosc_magazynow_pradu: 0.85,

    sprawnosc_elektrowni_gazowej: 0.5,

    roczne_zużycie_prądu_po_zamianie_silnikow_spalinowych_na_elektryczne: 52,

    czas_ladowania_rozladowania_magazynu_ciepla_w_przemysle: 50,
    sprawnosc_magazynow_ciepla_w_przemysle: 0.95,
  },
  data: {
    zuzycie_pradu: data.zuzycie_pradu,
    srednia_temp: data.srednia_temp,

    zuzycie_pradu_2023_rok_przestepny: zuzycie_pradu_2023_rok_przestepny,
    zuzycie_pradu_2023: zuzycie_pradu_2023,
  },
};
