import { getYearDays } from 'services/consts/months';
import { ObliczeniaStateSlice } from '../../obliczeniaSlice';
import { calcSredniaTemp, calcStopnioDzien } from './consts';
import { clone, cloneDeep } from 'lodash';
import { cieploProfil } from './cieploProfil';

export type CalcCieploReturn = {
  CWU_GW_ciepla: number;
  zapotrzebowanie_prądu_na_ciepło: number;
  real_COP: number;
  srednia_temp: number;
  stopnio_dzien: number;
  CO_ciepla_GW: number;
  CO_COP_temp: number;

  /**Ciepło COP magazyn */
  CWU_COP_temp: number;
  dostepna_dla_magazynu_moc_el_pc: number;
};

export const calcCieplo = (state: ObliczeniaStateSlice, procent_podlaczenia_do_magazynow_ciepla: number) => {
  const {
    zapotrzebowanie_CWU_względem_2023,
    odsetek_budynkow_z_odzyskiem_ciepla,
    moc_pomp_ciepla,
    procent_pomp_ciepla_gruntowych,
    procent_pomp_ciepla_powietrznych,
    zapotrzebowanie_CO_input,
    stopien_zastapienia_starych_budynkow_nowymi,
  } = state.obliczenia.cieplo.inputs;
  const { srednia_temp } = state.obliczenia.data;
  const { yearsData } = state.obliczenia;
  const { temperatura_CO, temperatura_CWU, rodzaj_termomodernizacji } = state.obliczenia.cieplo.selectOptions;
  const pompa_ciepla_gruntowa = moc_pomp_ciepla * procent_pomp_ciepla_gruntowych;
  state.obliczenia.cieplo.calculations.moc_pomp_ciepla_gruntowych = pompa_ciepla_gruntowa;
  const pompa_ciepla_powietrzna = moc_pomp_ciepla * procent_pomp_ciepla_powietrznych;
  state.obliczenia.cieplo.calculations.moc_pomp_ciepla_powietrznych = pompa_ciepla_powietrzna;
  const result: CalcCieploReturn[] = [];

  const calc_zuzycie_energii_koncowej_na_CO = () => {
    if (state.obliczenia.cieplo.obliczaj_CO === false) {
      return 0;
    }
    const getProcentZRodzajuTermomodernizacji = () => {
      if (rodzaj_termomodernizacji === 170) {
        return 1;
      }
      if (rodzaj_termomodernizacji === 136) {
        return 0.8;
      }
      if (rodzaj_termomodernizacji === 102) {
        return 0.6;
      }
      return 0;
    };

    const termomodernizacja =
      (1 - stopien_zastapienia_starych_budynkow_nowymi) *
      (170 * getProcentZRodzajuTermomodernizacji() - 42 * odsetek_budynkow_z_odzyskiem_ciepla);
    const stopien_zastapienia = stopien_zastapienia_starych_budynkow_nowymi * 20;
    const sum = termomodernizacja + stopien_zastapienia;
    state.obliczenia.cieplo.inputs.calculated_zapotrzebowanie_CO_input = sum / 170;
    if (typeof zapotrzebowanie_CO_input === 'number') {
      state.obliczenia.cieplo.inputs.zapotrzebowanie_CO_input_custom = zapotrzebowanie_CO_input;
      return zapotrzebowanie_CO_input * 170;
    }

    state.obliczenia.cieplo.inputs.zapotrzebowanie_CO_input = sum / 170;
    // state.obliczenia.cieplo.inputs.zapotrzebowanie_CO_input_custom = sum / 170;

    return termomodernizacja + stopien_zastapienia;
  };
  const zuzycie_energii_koncowej_na_CO = calc_zuzycie_energii_koncowej_na_CO();
  const zuzycie_energii_koncowej_na_CO_final = Math.max(zuzycie_energii_koncowej_na_CO, 0);
  state.obliczenia.cieplo.calculations.zuzycie_energii_CO = zuzycie_energii_koncowej_na_CO_final;

  const calc_zuzycie_energii_koncowej_na_CWU = () => {
    return zapotrzebowanie_CWU_względem_2023 > 0 ? 40 * zapotrzebowanie_CWU_względem_2023 : 0;
  };
  const zuzycie_energii_koncowej_na_CWU = calc_zuzycie_energii_koncowej_na_CWU();

  const srednia_temp_z_roku = calcSredniaTemp(srednia_temp);

  for (let index = 0; index < srednia_temp.length; index++) {
    const isSredniaTempLastIndex = srednia_temp.length - 1 === index;
    const parametrPomocniczyPrzy0 = index === 0 ? 0 : (srednia_temp[index - 1] - srednia_temp[index]) / 2;
    const parametrPomocniczyPrzy24 = isSredniaTempLastIndex ? 0 : (srednia_temp[index + 1] - srednia_temp[index]) / 2;
    const parametrPomocniczyPrzy12 = (-parametrPomocniczyPrzy0 - parametrPomocniczyPrzy24) / 2;
    for (let hourIndex = 0; hourIndex < 24; hourIndex++) {
      /**@deprecated only for highlight */
      const resultObj: CalcCieploReturn = {
        CWU_GW_ciepla: 0,
        zapotrzebowanie_prądu_na_ciepło: 0,
        real_COP: 0,
        srednia_temp: Number(srednia_temp[index]),
        CO_ciepla_GW: 0,
        CO_COP_temp: 0,
        CWU_COP_temp: 0,
        dostepna_dla_magazynu_moc_el_pc: 0,
        stopnio_dzien: 0,
      };
      const calcZastapienieSkokowejTemp = () => {
        if (hourIndex < 12) {
          return (
            srednia_temp[index] +
            parametrPomocniczyPrzy0 +
            (parametrPomocniczyPrzy12 - parametrPomocniczyPrzy0) * (hourIndex / 12)
          );
        }
        // =I85 + J97 + (J108 - J97) * ((H105 - 12) / 12)
        return (
          srednia_temp[index] +
          parametrPomocniczyPrzy12 +
          (parametrPomocniczyPrzy24 - parametrPomocniczyPrzy12) * ((hourIndex - 12) / 12)
        );
      };
      const zastapienie_skokowej_temp = calcZastapienieSkokowejTemp();
      //=K105+3* SIN(2*3,14159*(H105-9)/24)
      const dobowa_zmiennosc_temp = zastapienie_skokowej_temp + 3 * Math.sin((2 * 3.14159 * (hourIndex - 9)) / 24);
      let stopnio_dzien = 0;
      if (isSredniaTempLastIndex && hourIndex === 23) {
        if (dobowa_zmiennosc_temp <= 15) {
          stopnio_dzien = 18 - srednia_temp[index];
        } else {
          stopnio_dzien = 0.01;
        }
      } else {
        stopnio_dzien = calcStopnioDzien(dobowa_zmiennosc_temp);
      }
      resultObj.stopnio_dzien = stopnio_dzien;

      const temp_kaloryferow = Math.min(20 + ((temperatura_CO - 20) * stopnio_dzien) / 30, temperatura_CO);
      const temp_kaloryferow_P1 = 2.7 + (55 - temp_kaloryferow) * 0.05;
      const temp_kaloryferow_P2 = 1.5 + (55 - temp_kaloryferow) * 0.05;
      const CO_COP_powietrzna = Math.max(
        temp_kaloryferow_P2 + (temp_kaloryferow_P1 / 40) * (dobowa_zmiennosc_temp + 15),
        1,
      );
      const CO_COP_gruntowa = Math.max(
        temp_kaloryferow_P2 + (temp_kaloryferow_P1 / 40) * (srednia_temp_z_roku + 15),
        1,
      );

      const CO_COP_temp = Math.max(
        (pompa_ciepla_powietrzna * CO_COP_powietrzna + pompa_ciepla_gruntowa * CO_COP_gruntowa) /
          (pompa_ciepla_powietrzna + pompa_ciepla_gruntowa + 0.000000001),
        1,
      );

      const CO_ciepla_GW = (((zuzycie_energii_koncowej_na_CO_final * stopnio_dzien) / 3400) * 1000) / 24;
      const CO_moc_el_pc = CO_ciepla_GW / CO_COP_temp;
      const CO_energia_z_pc = CO_moc_el_pc < moc_pomp_ciepla ? CO_moc_el_pc : moc_pomp_ciepla;
      const CO_moc_th_pc = CO_energia_z_pc * CO_COP_temp;
      const CO_reszta_ciepla_z_grzalek = (CO_moc_el_pc - CO_energia_z_pc) * CO_COP_temp;

      // CWU
      const temp_CWU_P1 = 2.7 + (55 - temperatura_CWU) * 0.05;
      const temp_CWU_P2 = 1.5 + (55 - temperatura_CWU) * 0.05;
      const CWU_COP_powietrzna = Math.max(temp_CWU_P2 + (temp_CWU_P1 / 40) * (dobowa_zmiennosc_temp + 15), 1);
      const CWU_COP_gruntowa = Math.max(temp_CWU_P2 + (temp_CWU_P1 / 40) * (srednia_temp_z_roku + 15), 1);
      const CWU_COP_temp = Math.max(
        (pompa_ciepla_powietrzna * CWU_COP_powietrzna + pompa_ciepla_gruntowa * CWU_COP_gruntowa) /
          (pompa_ciepla_powietrzna + pompa_ciepla_gruntowa + 0.000000001),
        1,
      );
      const CWU_GW_ciepla = (zuzycie_energii_koncowej_na_CWU / 365) * 1000 * cieploProfil[hourIndex];
      const CWU_dostepna_moc_PC = moc_pomp_ciepla - CO_energia_z_pc;
      const CWU_potrzebna_moc_el_pc = CWU_GW_ciepla / CWU_COP_temp;
      const CWU_dostepna_moc_el_pc = Math.min(CWU_potrzebna_moc_el_pc, CWU_dostepna_moc_PC);
      const CWU_moc_th_pc = CWU_dostepna_moc_el_pc * CWU_COP_temp;
      const CWU_reszta_ciepla_z_grzalek = (CWU_potrzebna_moc_el_pc - CWU_dostepna_moc_el_pc) * CWU_COP_temp;

      //rezultaty
      const wykorzystana_moc_el_pc = CO_energia_z_pc + CWU_dostepna_moc_el_pc;
      const dostepna_dla_magazynu_moc_el_pc =
        (moc_pomp_ciepla - wykorzystana_moc_el_pc) * procent_podlaczenia_do_magazynow_ciepla;
      const pobor_pradu = CO_reszta_ciepla_z_grzalek + CWU_reszta_ciepla_z_grzalek + wykorzystana_moc_el_pc;
      const real_COP = (CO_ciepla_GW + CWU_GW_ciepla) / pobor_pradu || 1;
      resultObj.CWU_GW_ciepla = CWU_GW_ciepla;
      resultObj.zapotrzebowanie_prądu_na_ciepło = pobor_pradu;
      resultObj.real_COP = real_COP;
      resultObj.CO_ciepla_GW = CO_ciepla_GW;
      resultObj.CO_COP_temp = CO_COP_temp;
      resultObj.CWU_COP_temp = CWU_COP_temp;
      resultObj.dostepna_dla_magazynu_moc_el_pc = dostepna_dla_magazynu_moc_el_pc;

      result.push(resultObj);
    }
  }
  return result;
};
