import styled from '@emotion/styled';
import { Button } from 'components/Button/Button';
import { Footer } from 'components/Footer/Footer';

export const SMainDescCnt = styled.div`
  margin-top: 90px;
  display: flex;

  @media (max-width: 1200px) {
    max-width: 610px;
    display: block;
    margin: 0 auto;
    margin-top: 60px;

    img {
      max-width: 100%;
      object-fit: contain;

      @media (max-width: 420px) {
        height: 200px;
      }
    }
  }
`;

export const SDescCol = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 50%;
  padding-right: 20px;

  @media (max-width: 1200px) {
    max-width: unset;

    a {
      margin-bottom: 30px;
    }
  }
`;

export const SDesc = styled.p`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  color: #131c25;
  line-height: 21px;
  margin-bottom: 90px;

  @media (max-width: 1200px) {
    margin-bottom: 30px;
  }
`;

export const STitle = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: #131c25;
  margin-bottom: 20px;

  .small {
    font-size: 20px;
    line-height: 30px;

    @media (max-width: 1200px) {
      line-height: 20px;
    }
  }
`;

export const SSposnor = styled(Footer)`
  margin-top: 80px;
  margin-bottom: 150px;

  @media (max-width: 1200px) {
    margin: 40px 0;
  }
`;

export const SYouTube = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .player {
    width: 100%;
  }

  @media (max-width: 1200px) {
    margin: 0 auto;
    margin-bottom: 30px;
    width: 100%;
  }
`;

export const SWithVideoSection = styled.section`
  display: flex;
  margin-bottom: 150px;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    margin-bottom: 60px;
  }
`;

export const SIntro = styled.div`
  width: 50%;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #131c25;
  }
  p {
    color: #131c25;
  }

  @media (max-width: 1200px) {
    padding-left: unset;
    width: 100%;
    align-items: center;
    margin-bottom: 32px;
    text-align: center;
  }
`;

export const SGenesis = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 120px;
  h3 {
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
  }

  @media (max-width: 1200px) {
    padding-right: unset;
    width: 100%;
    align-items: center;
    margin-bottom: 32px;
    text-align: center;
  }
`;

export const SButtonAsLink = styled(Button)`
  margin-right: 10px;
`;

export const SSliderSection = styled.section`
  background: #27476e;
  padding: 100px 0;
  color: #fff;
  overflow: hidden;

  @media (max-width: 1200px) {
    .d-flex {
      display: block;
    }
    padding: 60px 0;
  }
`;

export const SSliderTextCol = styled.div`
  width: 50%;

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px;
  }

  .summary {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-top: 48px;
  }

  @media (max-width: 1200px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const SSliderCol = styled.div`
  width: 50%;
  padding-left: 80px;
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 30px;
    margin-top: 30px;
  }
`;

export const SDecisionSection = styled.section`
  margin-top: 100px;
  text-align: center;

  h2 {
    font-size: 32px;
    line-height: 48px;
    color: #131c25;
    font-weight: 700;
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 800px;
  }

  .simulator-desc {
    max-width: 400px;
    margin: 0 auto;
  }

  @media (max-width: 1200px) {
    margin-top: 60px;
  }
`;

export const SSimulatorArgs = styled.div`
  display: flex;
  margin-top: 70px;

  .col {
    width: 25%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  .iconWrapper {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: #f0f0f0;
    margin-bottom: 20px;
    color: #297f94;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #131c25;
    margin-bottom: 8px;
  }

  .desc {
    margin-top: auto;
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 45px;
    .col {
      min-width: 190px;

      padding: 0;
    }
  }
`;

export const SExampleScenariosSection = styled.section`
  display: flex;
  align-items: center;

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #131c25;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const SExampleScenariosAction = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const SFooter = styled.footer`
  margin-top: 150px;
  background-color: #27476e;
  line-height: 30px;
  color: #fff;
  padding: 30px;

  .footer-cnt {
    display: flex;
    align-items: center;
  }

  .text {
    font-size: 20px;
    font-weight: 700;
  }

  .rodo,
  .privacy {
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    padding: 0;
  }

  .rodo {
    margin-left: 24px;
  }
  .privacy {
    margin-left: auto;
  }

  @media (max-width: 1200px) {
    margin-top: 80px;
  }
`;

export const BtnsCnt = styled.div`
  display: flex;
  margin-top: 24px;

  @media (max-width: 420px) {
    display: block;
    a {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;
