import { Chart, ChartTypeRegistry, TooltipModel } from 'chart.js';
import { chartJsTooltip } from 'services/configuration/theme/theme';

const getOrCreateTooltip = chart => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.fontSize = chartJsTooltip.bodyFont.size + 'px';
    tooltipEl.style.background = chartJsTooltip.backgroundColor;
    tooltipEl.style.borderRadius = chartJsTooltip.cornerRadius;
    tooltipEl.style.color = chartJsTooltip.bodyColor;
    tooltipEl.style.fontFamily = chartJsTooltip.titleFont.family;
    tooltipEl.style.fontSize = chartJsTooltip.titleFont.size + 'px';
    tooltipEl.style.lineHeight = chartJsTooltip.titleFont.lineHeight;
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.zIndex = 15;
    // tooltipEl.style.transform = align ? 'translate(-100%, 0)' : 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    // tooltipEl.style.minWidth = '250px';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context: {
  chart: Chart<keyof ChartTypeRegistry, any, any>;
  tooltip: TooltipModel<any>;
}) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);

    const tableHead = document.createElement('thead');
    tableHead.style.fontWeight = chartJsTooltip.titleFont.weight.toString();
    tableHead.style.borderBottom = `${chartJsTooltip.titleMarginBottom}px solid transparent`;

    titleLines.forEach(title => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = '0';
      const th = document.createElement('th');
      th.style.borderWidth = '0';
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      span.style.background = colors.backgroundColor as string;
      span.style.borderColor = colors.borderColor as string;
      span.style.borderWidth = '2px';
      span.style.marginRight = '4px';
      span.style.height = chartJsTooltip.boxWidth + 'px';
      span.style.width = chartJsTooltip.boxWidth + 'px';
      span.style.borderRadius = '50%';

      span.style.whiteSpace = 'nowrap';

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = '0';
      tr.style.whiteSpace = 'nowrap';

      const td = document.createElement('td');
      td.style.borderWidth = '0';
      td.style.whiteSpace = 'nowrap';
      td.style.display = 'flex';
      td.style.alignItems = 'center';
      const text = document.createTextNode(body[0]);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');
    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  // const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  // tooltipEl.style.font = tooltip.options.bodyFont;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';

  // Adjust the tooltip's position if it's near the right edge of the chart's container
  const tooltipWidth = tooltipEl.offsetWidth;
  const containerWidth = chart.canvas.clientWidth;

  if (positionX + tooltip.caretX + tooltipWidth > containerWidth) {
    tooltipEl.style.left = 'unset';
    tooltipEl.style.right = positionX + 'px';
  } else {
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.right = 'unset';
  }
};
