import { keys } from '../../../i18n/keys';
import React, { useRef } from 'react';
import { useToggle } from 'hooks/useToggle';
import { electricityExportData } from './utils/electricityExportData';
import { electricityImport } from './utils/electricityImport';
import { ExcelToJSON } from './utils/ExcelToJSON';
import { ElectricityExport } from './types';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Button/Button';

import {
  getCieploConsts,
  getObliczenia,
  getProdukcjaEnergiiElektrycznejData,
  getYearsData,
} from 'services/slices/obliczeniaSlice/selectors';
import { setImportedEnergyFromExcel, setSelectedYear } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { data } from 'data/2023';
import { ReactComponent as DeleteIcon } from 'assets/new/delete_sweep.svg';
import { ExportCard, SErrorWrapper, SSuccessWrapper } from '../dataFeatures.styles';

export const EnergyExportCol = () => {
  const importElectricityRef = useRef<HTMLInputElement>(null);
  const electricityTimeoutRef = useRef<any>(null);
  const [isElectricityImportError, toggleElectricityImportError] = useToggle(false);
  const [isElectricityImportSuccess, toggleElectricityImportSuccess] = useToggle(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { selectedYear } = useAppSelector(getObliczenia);
  const { srednia_temp } = useAppSelector(getCieploConsts);
  const { zuzycie_pradu } = useAppSelector(state => state.obliczenia.obliczenia.data);
  const { PV, PV_wsch_zach, wiatr_lad, wiatr_lad_6MGW, wiatr_offshore } = useAppSelector(
    getProdukcjaEnergiiElektrycznejData,
  );
  const yearsData = useAppSelector(getYearsData);

  const openElectricityFileLoader = () => {
    importElectricityRef.current?.click();
  };

  const onElectricityImport = async () => {
    const file = importElectricityRef.current?.files?.[0];
    if (file) {
      try {
        const jsonResult = await ExcelToJSON<ElectricityExport>(file);
        const importedData = electricityImport(jsonResult);
        toggleElectricityImportSuccess(true);
        dispatch(setImportedEnergyFromExcel(importedData));
      } catch (err) {
        toggleElectricityImportError(true);
        electricityTimeoutRef.current = setTimeout(() => {
          clearTimeout(electricityTimeoutRef.current);
          toggleElectricityImportError(false);
        }, 5000);
      }
    }
  };

  const onElectricityExportData = () => {
    electricityExportData({
      t,
      selectedYear,
      energy_demand: zuzycie_pradu,
      photovoltaic: PV,
      wind: wiatr_lad,
      windOffShore: wiatr_offshore,
      PV_wsch_zach,
      wiatr_lad_6MGW,
    });
  };

  const resetEnergyData = () => {
    dispatch(
      setImportedEnergyFromExcel({
        energy_demand: yearsData[selectedYear].zuzycie_pradu,
        photovoltaic: yearsData[selectedYear].PV,
        PV_wsch_zach: yearsData[selectedYear].PV_wsch_zach,
        wind: yearsData[selectedYear].wiatr_lad,
        wiatr_lad_6MGW: yearsData[selectedYear].wiatr_lad_6MGW,
        windOffShore: yearsData[selectedYear].wiatr_offshore,
      }),
    );
    dispatch(setSelectedYear(selectedYear));
  };
  return (
    <ExportCard>
      <h3 className="title">{t(keys.EXPORT_ELECTRICITY_TITLE)}</h3>
      <span>{t(keys.EDIT_INPUT_DATA_TEXT)}</span>
      <div className="buttons-cnt">
        <Button onClick={onElectricityExportData}>{t(keys.EXPORT_DATA)}</Button>
        <Button onClick={openElectricityFileLoader} variant="outlined">
          {t(keys.IMPORT_DATA)}
        </Button>
        <Button variant="text" onClick={resetEnergyData} endIcon={<DeleteIcon />}>
          {t(keys.RESET_DATA)}
        </Button>
        <input
          ref={importElectricityRef}
          type="file"
          hidden
          onChange={onElectricityImport}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </div>
      {isElectricityImportError && <SErrorWrapper>{t(keys.IMPORT_DATA_ERROR)}</SErrorWrapper>}
      {isElectricityImportSuccess && <SSuccessWrapper>{t(keys.IMPORT_DATA_SUCCESS)}</SSuccessWrapper>}
    </ExportCard>
  );
};
