import styled from '@emotion/styled';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  width: 100%;
  display: block;
`;

export const Caption = styled.caption`
  width: 100%;
  display: block;
  color: #6a7f85;
  padding: 3px 6px;
  border: 1px solid #e6e8e8;
  text-align: left;
  background: #f2f9fc;
`;

export const THead = styled.thead`
  display: block;
  width: 100%;

  td {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    background-color: #f2f6f7;
    border-radius: 4px;
    padding: 3px 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-of-type {
      font-weight: 600;
      font-size: 16px;
    }
  }
  .empty {
    background-color: transparent;
  }
`;

export const TBody = styled.tbody`
  display: block;
  width: 100%;
`;

export const Tr = styled.tr<{ cols?: number; asHead?: boolean }>`
  display: grid;
  grid-template-columns: 2fr repeat(${({ cols = 3 }) => cols}, 1fr);
  grid-gap: 10px;
  margin-bottom: 10px;

  td:first-of-type {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    background: transparent;
    justify-content: flex-start;
    padding: 0;
    align-items: flex-end;
  }

  &.scale-to-other {
    grid-template-columns: 5fr 1fr;
  }
`;

export const Td = styled.td<{ noOverflow?: boolean }>`
  position: relative;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;
