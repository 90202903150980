import styled from '@emotion/styled';
import { Button } from 'components/Button/Button';
import { Link } from 'components/Link/Link';

export const SNav = styled.nav`
  padding: 12px;
  background-color: #fff;
`;

export const SNavCnt = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
`;

export const STitle = styled.h2`
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: #131c25;
`;

export const SActionCnt = styled.div`
  display: flex;
  align-items: center;
`;

export const SAbout = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-right: 30px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const SLink = styled(Button)`
  margin: 0 30px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const SLanguageSwitcherCnt = styled.div`
  color: #297f94;
`;

export const SLogoCnt = styled.div`
  min-height: 62px;
`;
