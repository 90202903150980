import { cloneDeep, merge, mergeWith } from 'lodash';
import { QueryParamsPayload, ScenarioDataPayload } from 'services/slices/obliczeniaSlice/types';
import { getSearchParam } from './utils';
import { Wodor } from 'services/slices/obliczeniaSlice/wodor/types';

export const mergeWodor = (wodor: Wodor, scenarioData: ScenarioDataPayload) => {
  const clone: QueryParamsPayload['wodor'] = cloneDeep(wodor);
  const wodorObj = getSearchParam('wodor') || '{}';
  const dataFromScenario: Partial<QueryParamsPayload['wodor']> = {
    procentowe_zapotrzebowanie: scenarioData.wodór,
  };
  const mergedWithScenario = mergeWith(clone, dataFromScenario);
  const { bazowe_zapotrzebowanie, procentowe_zapotrzebowanie }: QueryParamsPayload['wodor'] = merge(
    mergedWithScenario,
    JSON.parse(wodorObj),
  );
  return { bazowe_zapotrzebowanie, procentowe_zapotrzebowanie };
};
