import { getYearHours } from 'services/consts/months';
import { ObliczeniaStateSlice } from '../obliczeniaSlice';
import { ObliczeniaProdukcjaEnergiiElektrycznej } from './types';

export const calcStableProductionEnergySources = (
  state: ObliczeniaStateSlice,
): ObliczeniaProdukcjaEnergiiElektrycznej => {
  const { inputs, data } = state.produkcjaEnergiiElektrycznej;
  const { strata_przy_przesyle } = state.obliczenia.inputs;
  const returnObj: ObliczeniaProdukcjaEnergiiElektrycznej = {
    atom_produkcja_po_stratach_per_day: [],
    atom_po_stratach_per_year: 0,
    atom_per_year: 0,
    PV_produkcja_po_stratach_per_day: [],
    PV_per_year: 0,
    PV_po_stratach_per_year: 0,
    produkcja_pradu_per_day_po_stratach: [],
    produkcja_pradu_w_calym_roku: 0,
    wiatr_lad_produkcja_po_stratach_per_day: [],
    wiatr_lad_po_stratach_per_year: 0,
    wiatr_lad_per_year: 0,
    wiatr_offshore_produkcja_po_stratach_per_day: [],
    wiatr_na_morzu_po_stratach_per_year: 0,
    wiatr_na_morzu_per_year: 0,
    atom_produkcja_per_day: [],
    PV_produkcja_per_day: [],
    wiatr_lad_produkcja_per_day: [],
    wiatr_offshore_produkcja_per_day: [],
  };

  const wspolczynnik = 1 - strata_przy_przesyle;

  for (let index = 0; index < getYearHours(state.obliczenia.selectedYear); index++) {
    //wind calculations
    const getWiatrLad = () => {
      //=(1-F$5)*D13+F$5*E13
      return (
        (1 - inputs.udzial_nowoczesnych_turbin_wiatrowych) * data.wiatr_lad[index] +
        inputs.udzial_nowoczesnych_turbin_wiatrowych * data.wiatr_lad_6MGW[index]
      );
    };
    const wiatr_lad_dzien = inputs.wiatr_lad_zainstalowano * getWiatrLad();
    returnObj.wiatr_lad_produkcja_per_day[index] = wiatr_lad_dzien;

    returnObj.wiatr_lad_per_year = returnObj.wiatr_lad_per_year + wiatr_lad_dzien;
    const wiatr_lad_dzien_dostarczono = wiatr_lad_dzien * wspolczynnik;
    returnObj.wiatr_lad_produkcja_po_stratach_per_day[index] = wiatr_lad_dzien_dostarczono;
    returnObj.wiatr_lad_po_stratach_per_year = returnObj.wiatr_lad_po_stratach_per_year + wiatr_lad_dzien_dostarczono;

    //wind off shore calcs
    const wiatr_offshore_dzien = inputs.wiatr_offshore_zainstalowano * data.wiatr_offshore[index];
    returnObj.wiatr_offshore_produkcja_per_day[index] = wiatr_offshore_dzien;

    returnObj.wiatr_na_morzu_per_year = returnObj.wiatr_na_morzu_per_year + wiatr_offshore_dzien;
    const wiatr_offshore_dzien_dostarczono = wiatr_offshore_dzien * wspolczynnik;
    returnObj.wiatr_offshore_produkcja_po_stratach_per_day[index] = wiatr_offshore_dzien_dostarczono;
    returnObj.wiatr_na_morzu_po_stratach_per_year =
      returnObj.wiatr_na_morzu_po_stratach_per_year + wiatr_offshore_dzien_dostarczono;

    // photovoltaics calcs
    const getPvWspolczynnik = () => {
      //=(1-J$5)*H16+J$5*I16
      return (
        (1 - inputs.udzial_bifacji_EW_w_PV) * data.PV[index] + inputs.udzial_bifacji_EW_w_PV * data.PV_wsch_zach[index]
      );
    };
    const pv_dzien = inputs.PV_zainstalowano * getPvWspolczynnik();
    returnObj.PV_produkcja_per_day[index] = pv_dzien;
    returnObj.PV_per_year = returnObj.PV_per_year + pv_dzien;
    const pv_dzien_dostarczono = pv_dzien * wspolczynnik;
    returnObj.PV_produkcja_po_stratach_per_day[index] = pv_dzien_dostarczono;
    returnObj.PV_po_stratach_per_year = returnObj.PV_po_stratach_per_year + pv_dzien_dostarczono;

    // nuclear power plants calcs
    const atom_dzien =
      inputs.atom_zainstalowano *
      (0.85 + 0.1 * Math.cos((index / getYearHours(state.obliczenia.selectedYear)) * 2 * 3.14159265));
    returnObj.atom_produkcja_per_day[index] = atom_dzien;
    returnObj.atom_per_year = returnObj.atom_per_year + atom_dzien;
    const atom_dzien_dostarczono = atom_dzien * wspolczynnik;
    returnObj.atom_produkcja_po_stratach_per_day[index] = atom_dzien_dostarczono;
    returnObj.atom_po_stratach_per_year = returnObj.atom_po_stratach_per_year + atom_dzien;

    //sum
    returnObj.produkcja_pradu_per_day_po_stratach[index] =
      wiatr_lad_dzien_dostarczono + wiatr_offshore_dzien_dostarczono + pv_dzien_dostarczono + atom_dzien_dostarczono;
    returnObj.produkcja_pradu_w_calym_roku =
      returnObj.produkcja_pradu_w_calym_roku + wiatr_lad_dzien + wiatr_offshore_dzien + pv_dzien + atom_dzien;
  }
  return returnObj;
};
