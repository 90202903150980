import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { useCurrentLang } from 'services/configuration/i18n/utils';

export const Link = ({ children, to, ...props }: LinkProps) => {
  const lang = useCurrentLang();
  const modifiedTo = lang === 'en' ? `/en${to}` : lang === 'id' ? `/id${to}` : to;
  return (
    <RouterLink to={modifiedTo} {...props}>
      {children}
    </RouterLink>
  );
};
