export const koszty = {
  nie_licz_kosztów_istniejącej_infrastruktury: false,
  stopa_dyskonta: 0.05,
  aktualna_infrastruktura: {
    wiatr_na_morzu: 0,
    wiatr_na_ladzie: 7,
    PV: 15,
    atom: 0,
    elektrownie_gazowe: 10,
    magazyny_ciepla_duze: 0,
    magazyny_ciepla_domowe: 0,
    magazyny_ciepla_przemyslowe: 0,
    baterie_litowe_stacjonarne: 0,
    elektrownie_szczytowo_pompowe: 10,
    elektrolizery: 0,
    pompy_ciepla_powietrzne: 0,
    pompy_ciepla_gruntowe: 0,
  },
  koszt_mld_per_GW: {
    wiatr_na_morzu: 12,
    wiatr_na_ladzie: 5.4,
    PV: 2.2,
    atom: 60,
    elektrownie_gazowe: 4,
    elektrolizery: 4,

    magazyny_ciepla_duze: 0.01,
    magazyny_ciepla_domowe: 0.03,
    magazyny_ciepla_przemyslowe: 0.15,
    baterie_litowe_stacjonarne: 0.4,
    elektrownie_szczytowo_pompowe: 1,
    pompy_ciepla_powietrzne: 25,
    pompy_ciepla_gruntowe: 40,
  },
  O_M_paliwo: {
    wiatr_na_ladzie: 0.007,
    wiatr_na_morzu: 0.017,
    PV: 0,
    atom: 0.016,
    elektrownie_gazowe: 0.7,
    elektrolizery: 0,

    baterie_litowe_stacjonarne: 0,
    elektrownie_szczytowo_pompowe: 0,
    //
    magazyny_ciepla_duze: 0,
    magazyny_ciepla_domowe: 0,
    pompy_ciepla_powietrzne: 0,
    pompy_ciepla_gruntowe: 350,
  },
  O_M_utrzymanie: {
    wiatr_na_ladzie: 0.09,
    wiatr_na_morzu: 0.28,
    PV: 0.052,
    atom: 0.5,
    elektrownie_gazowe: 0.06,
    elektrolizery: 0.02,

    baterie_litowe_stacjonarne: 0,
    elektrownie_szczytowo_pompowe: 0.0017,

    magazyny_ciepla_duze: 0,
    magazyny_ciepla_domowe: 0,

    pompy_ciepla_powietrzne: 0.015,
    pompy_ciepla_gruntowe: 0.015,
  },

  calculations: {
    LCOE: 0,
    łącznyKosztBudowyInfrastruktury: 0,
    wiatr: {
      nowa_moc_zainstalowana: 0,
      koszt: 0,
      jednostkoweLCOE: 0,
    },
    wiatrNaMorzu: {
      nowa_moc_zainstalowana: 0,
      koszt: 0,
      jednostkoweLCOE: 0,
    },
    fotowoltaika: {
      nowa_moc_zainstalowana: 0,
      koszt: 0,
      jednostkoweLCOE: 0,
    },
    atom: {
      nowa_moc_zainstalowana: 0,
      koszt: 0,
      jednostkoweLCOE: 0,
    },
    gazZiemny: {
      nowa_moc_zainstalowana: 0,
      koszt: 0,
      jednostkoweLCOE: 0,
    },
    baterieLitowe: {
      nowa_moc_zainstalowana: 0,
      koszt: 0,
    },
    elektrownieSzczytowoPompowe: {
      nowa_moc_zainstalowana: 0,
      koszt: 0,
    },
    magazynCiepłaDuży: {
      nowa_moc_zainstalowana: 0,
      koszt: 0,
    },
    magazynCiepłaDomowe: {
      nowa_moc_zainstalowana: 0,
      koszt: 0,
    },
    magazynCiepłaPrzemyslowy: {
      nowa_moc_zainstalowana: 0,
      koszt: 0,
    },
    wodór: {
      nowa_moc_zainstalowana: 0,
      koszt: 0,
    },
    pompyCieplaGruntowe: {
      nowa_moc_zainstalowana: 0,
      koszt: 0,
    },
    pompyCieplaPowietrzne: {
      nowa_moc_zainstalowana: 0,
      koszt: 0,
    },
  },
};
