import React from 'react';
import { ITooltip as ReactTooltipProps } from 'react-tooltip';
import { SReactTooltip } from './styles';

export const ReactTooltip = ({ className, children, ...props }: ReactTooltipProps) => {
  return (
    <SReactTooltip disableStyleInjection clickable {...props} className={`custom-react-tooltip ${className ?? ''}`}>
      {children}
    </SReactTooltip>
  );
};
