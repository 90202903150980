import styled from '@emotion/styled';

export const Content = styled.div`
  padding: 16px;
  border-radius: 12px;
  background-color: #fff;
`;

export const CollapsibleContent = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #fff;
`;

export const CollapsibleTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SPageCard = styled.div`
  padding: 30px 20px 30px 40px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 150, 93, 0.16);
  flex-grow: 1;
`;

export const SPageCnt = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
