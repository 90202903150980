import { useTheme } from '@emotion/react';
import { PeriodType } from 'features/Period/types';
import keys from 'i18n/keys';
import moment from 'moment';
import { ChartProps } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'services/configuration/store/hooks';
import { getMonths } from 'services/consts/months';
import { PeriodEnum } from 'services/types/PeriodEnum';
import { dotToComma, roundNumb } from 'services/utils/roundNumb';
import { DATASET_LABELS } from '../../consts';
import { SelectOption, SelectOptionsValues } from '../../types';
import { getObliczeniaReducer } from 'services/slices/obliczeniaSlice/selectors';
import { externalTooltipHandler } from './externalTooltipHandler';

const entries = Object.entries(DATASET_LABELS);
type UseLineOptionsType = {
  period: PeriodType;
  isInModalView: boolean;
  selectedOption: SelectOption;
  isTooltipVisible: boolean;
};
export const useLineOptions = ({
  period,
  selectedOption,
  isInModalView,
  isTooltipVisible,
}: UseLineOptionsType): ChartProps<'line'>['options'] => {
  const {
    produkcjaEnergiiElektrycznej: {
      calculations: {
        atom_produkcja_po_stratach_per_day,
        PV_produkcja_po_stratach_per_day,
        produkcja_pradu_per_day_po_stratach,
        wiatr_lad_produkcja_po_stratach_per_day,
        wiatr_offshore_produkcja_po_stratach_per_day,
      },
    },
    obliczenia: {
      calculations: {
        źródła_dyspozycyjne_per_day,
        magazyn_prądu_per_day,
        energia_elektryczna_per_day,
        cieplo_per_day,
        magazyn_ciepła_per_day,
        elektroliza_per_day,
        zapotrzebowanie_na_prąd_z_elastycznością_per_day,
        transport_per_day,
        zapotrzebowanie_na_prąd_per_day,
        energia_z_magazynu_prądu_per_day,
        energia_z_magazynu_ciepła_per_day,
        ładowanie_magazynu_ciepła_per_day,
        ładowanie_magazynu_prądu_per_day,
        magazyn_ciepła_przemyslowy_per_day,
        cieplo_przemyslowe_per_day,
        ładowanie_magazynu_ciepla_przemyslowego_per_day,
        energia_z_magazynu_ciepla_przemyslowego_per_day,
        zapotrzebowanie_z_elastycznoscia_z_wodorem_per_day,
      },
      selectedYear,
      inputs: { moc_elektrolizerow },
    },
  } = useAppSelector(getObliczeniaReducer);
  const { t } = useTranslation();
  const filterByPeriod = (_, i) => i >= period.start && i <= period.end;

  const possibleMergedOptions: Record<keyof typeof DATASET_LABELS, number[]> = {
    źródła_dyspozycyjne_per_day,
    magazyn_prądu_per_day,
    energia_elektryczna_per_day,
    cieplo_per_day,
    magazyn_ciepła_per_day,
    elektroliza_per_day,
    zapotrzebowanie_na_prąd_z_elastycznością_per_day,
    transport_per_day,
    atom_produkcja_po_stratach_per_day,
    PV_produkcja_po_stratach_per_day,
    produkcja_pradu_per_day_po_stratach,
    wiatr_lad_produkcja_po_stratach_per_day,
    wiatr_offshore_produkcja_po_stratach_per_day,
    zapotrzebowanie_na_prąd_per_day,
    energia_z_magazynu_prądu_per_day,
    energia_z_magazynu_ciepła_per_day,
    ładowanie_magazynu_ciepła_per_day,
    ładowanie_magazynu_prądu_per_day,
    magazyn_ciepła_przemyslowy_per_day,
    cieplo_przemyslowe_per_day,
    ładowanie_magazynu_ciepla_przemyslowego_per_day,
    energia_z_magazynu_ciepla_przemyslowego_per_day,
    zapotrzebowanie_z_elastycznoscia_z_wodorem_per_day,
  };
  const monthsHours = getMonths(selectedYear).map(el =>
    roundNumb(el.duration.end - (el.duration.end - el.duration.start), 0),
  );
  const isStorageView = [SelectOptionsValues.EnergyStorage, SelectOptionsValues.HeatStorage].includes(
    selectedOption.value,
  );

  const { chartJsTooltip } = useTheme();
  const getAutoSkipPadding = () => {
    if (period.target === PeriodEnum.MONTTH || isInModalView) {
      return 12;
    }
    if (period.target === PeriodEnum.QUATER) {
      return 12;
    }

    return 0;
  };

  const getLabelOffset = () => {
    if (period.target === PeriodEnum.MONTTH || isInModalView) {
      return -12;
    }
    if (period.target === PeriodEnum.QUATER) {
      return -12;
    }
    return 0;
  };

  const options: ChartProps<'line'>['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    spanGaps: true,
    plugins: {
      tooltip: {
        ...chartJsTooltip,
        enabled: isTooltipVisible,

        // external: externalTooltipHandler,
        position: 'nearest',

        itemSort: function (a, b) {
          return b.dataset!.order! - a.dataset!.order!;
        },
        callbacks: {
          labelPointStyle(tooltipItem) {
            const hasBorderColor = !!tooltipItem.dataset?.borderColor;
            if (hasBorderColor) {
              return {
                pointStyle: 'line',
                rotation: 0,
              };
            }
            return {
              pointStyle: 'circle',
              rotation: 0,
            };
          },
          label: function (tooltipItem: any) {
            const {
              dataset: { label },
              dataIndex,
            } = tooltipItem;
            const option: string = entries.find(el => el[1] === label)![0];
            const currentScope = possibleMergedOptions[option].filter(filterByPeriod);
            return `${t(label) || label} ${dotToComma(roundNumb(currentScope[dataIndex], 2)) ?? 0} ${
              isStorageView ? t(keys.GWh) : t(keys.GW)
            }`;
          },
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    normalized: true,
    animation: false,
    elements: {
      point: {
        radius: 0,
        backgroundColor: 'none',
      },
      line: {
        tension: 0,
        backgroundColor: 'none',
        stepped: false,
        borderDash: [],
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 16,
          },
          callback: function (value, index, ticks) {
            return `${value} ${isStorageView ? t(keys.GWh) : t(keys.GW)}`;
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#7F857A',
          align: 'start',
          autoSkip: true,
          includeBounds: true,
          autoSkipPadding: getAutoSkipPadding(),
          labelOffset: getLabelOffset(),
          maxRotation: 0,
          callback: function (label, index, labels) {
            if (period.target === PeriodEnum.YEAR) {
              if (monthsHours.includes(index)) {
                const found = getMonths(selectedYear).find(
                  el => el.duration.start <= index && el.duration.end >= index,
                )!;
                return t(found.name) || found.name;
              }
              return;
            }
            if (period.target === PeriodEnum.QUATER && (index + 1) % 24 === 0) {
              const momObj = moment
                .utc(`01-01-${selectedYear}`, 'DD-MM-YYYY')
                .add(period.start, 'hour')
                .add(index, 'hour');

              return momObj.format('DD-MM');
            }
            if (period.target === PeriodEnum.MONTTH && (index + 12) % 24 === 0) {
              const momObj = moment
                .utc(`01-01-${selectedYear}`, 'DD-MM-YYYY')
                .add(period.start, 'hour')
                .add(index, 'hour');

              return momObj.format('DD-MM');
            }
          },
        },
      },
    },
  };

  return options;
};
