import React, { useState } from 'react';
import { LineEnergyProduction } from './LineEnergyProduction';
import { Modal } from 'components/Modal/Modal';
import { Button } from 'components/Button/Button';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import keys from 'i18n/keys';
import { useTranslation } from 'react-i18next';
import { ModalCharts, ModalContent, SAddBtnCnt } from './styles';

type LineEnergyProductionModalProps = {
  open: boolean;
  onCloseModal: () => void;
};
export const LineEnergyProductionModal = ({ open, onCloseModal }: LineEnergyProductionModalProps) => {
  const [modals, setModals] = useState([1]);
  const handleAddChart = () => {
    setModals([...modals, modals[modals.length - 1] + 1]);
  };
  const handleClose = () => {
    onCloseModal();
    setModals([1]);
  };
  const removeFromModals = (id: number) => {
    setModals(modals.filter(modal => modal !== id));
    if (id === 1) {
      handleClose();
    }
  };
  const { t } = useTranslation();
  return (
    <Modal open={open} onClose={handleClose} getMaxPosition>
      <ModalContent>
        <ModalCharts>
          {modals.map(el => (
            <LineEnergyProduction key={el} currNumb={el} isInModalView onCloseModal={removeFromModals} />
          ))}
        </ModalCharts>
        {modals.length < 4 && (
          <SAddBtnCnt>
            <Button variant="outlined" onClick={handleAddChart}>
              <PlusIcon />
              <span>{t(keys.ADD_CHART)}</span>
            </Button>
          </SAddBtnCnt>
        )}
      </ModalContent>
    </Modal>
  );
};
