import { DataInput } from 'components/DataInput/DataInput';
import { Spacer } from 'components/common';
import { useDispatchAction } from 'hooks/useDispatchAction';
import keys from 'i18n/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'services/configuration/store/hooks';

import { setCieploInputs } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { getObliczenia } from 'services/slices/obliczeniaSlice/selectors';

import { CieploInputsPayload } from 'services/slices/obliczeniaSlice/obliczenia/cieplo/types';
import { COCieplo } from './COCieplo';

export const CieploTile = () => {
  const { t } = useTranslation();
  const {
    cieplo: {
      inputs: { moc_pomp_ciepla, zapotrzebowanie_CWU_względem_2023 },
    },
  } = useAppSelector(getObliczenia);

  const CWUNeededSet = useDispatchAction<CieploInputsPayload>({
    action: setCieploInputs,
    name: 'zapotrzebowanie_CWU_względem_2023',
    value: zapotrzebowanie_CWU_względem_2023,
    percentage: true,
    max: 2,
  });

  const heatPumpSet = useDispatchAction<CieploInputsPayload>({
    action: setCieploInputs,
    name: 'moc_pomp_ciepla',
    value: moc_pomp_ciepla,
  });

  return (
    <div>
      <Spacer mb="6px">
        <COCieplo />
      </Spacer>
      <Spacer mb="6px">
        <DataInput
          label={t(keys.CURR_REQ_FOR_DOMESTIC_HEAT_WATER_PRECENT)}
          id={'CWUNeededSet'}
          labelTooltipId="TOOLTIP_HEAT_TILE_CURR_REQ_FOR_DOMESTIC_HEAT_WATER_PRECENT"
          labelTooltipContent={t(keys.TOOLTIP_HEAT_TILE_CURR_REQ_FOR_DOMESTIC_HEAT_WATER_PRECENT)}
          unit={'%'}
          unitColor="#AD6800"
          {...CWUNeededSet}
        />
      </Spacer>
      <Spacer mb="6px">
        <DataInput
          label={t(keys.HEAT_PUMPS_IN_HEATING)}
          id={'heatPumpSet'}
          labelTooltipId="TOOLTIP_HEAT_TILE_HEAT_PUMPS_IN_HEATING"
          labelTooltipContent={t(keys.TOOLTIP_HEAT_TILE_HEAT_PUMPS_IN_HEATING)}
          unit={t(keys.GWEL)}
          unitColor="#AD6800"
          {...heatPumpSet}
        />
      </Spacer>
    </div>
  );
};
