import { cloneDeep, merge } from 'lodash';
import { koszty } from 'services/slices/obliczeniaSlice/koszty/koszty';
import { Koszty } from 'services/slices/obliczeniaSlice/koszty/types';
import { QueryParamsPayload } from 'services/slices/obliczeniaSlice/types';
import { getSearchParam } from './utils';

export const mergeKoszty = (): QueryParamsPayload['koszty'] => {
  const { calculations, ...rest }: Koszty = cloneDeep(koszty);

  const kosztyObj = getSearchParam('koszty') || '{}';
  const kosztyMerged: QueryParamsPayload['koszty'] = merge(rest, JSON.parse(kosztyObj));

  return kosztyMerged;
};
