import { keys } from 'i18n/keys';
import React from 'react';
import { Cell, CellText, Grid, HeadRow, Row, RowGroup, STooltip, TableTitle } from './sharedStyles';

import { useAppSelector } from 'services/configuration/store/hooks';
import { useDispatchAction } from 'hooks/useDispatchAction';
import { useTranslation } from 'react-i18next';
import { getObliczeniaInputs } from 'services/slices/obliczeniaSlice/selectors';
import { setObliczeniaInputs } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { ObliczeniaTypeInputsPayload } from 'services/slices/obliczeniaSlice/obliczenia/types';
import { DataInput } from 'components/DataInput/DataInput';

export const ElectrocityOtherDemand = () => {
  const { mnożnik_zapotrzebowania_KSE } = useAppSelector(getObliczeniaInputs);

  const energyDemandMultiplierSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'mnożnik_zapotrzebowania_KSE',
    value: mnożnik_zapotrzebowania_KSE,
    percentage: true,
    max: 5,
  });
  // const energyDemandElasticitySet = useDispatchAction<ObliczeniaTypeInputsPayload>({
  //   action: setObliczeniaInputs,
  //   name: 'wspolczynnik_elastyczności',
  //   value: wspolczynnik_elastyczności,
  //   percentage: true,
  //   max: 0.3,
  // });
  const { t } = useTranslation();
  return (
    <Grid>
      <HeadRow columns={1}>
        <TableTitle
          reverseFlex
          id="electrocity-other-demand-advanced"
          tooltipContent={t(keys.TOOLTIP_ENERGY_OTHER_DEMAND_TILE_CONTENT)}
        >
          {t(keys.OTHER_ELECTRICITY_CONSUMPTION_ADVANCED)}
        </TableTitle>
      </HeadRow>
      <RowGroup>
        <Row columns={1}>
          <Cell>
            <STooltip
              id="energy-demand-ksu-cell"
              content={t(keys.TOOLTIP_ENERGY_OTHER_DEMAND_TILE_CURRENT_NEED_FOR_POWER)}
            />
            <CellText>{t(keys.CURRENT_NEED_FOR_POWER)}</CellText>
          </Cell>
          <Cell>
            <DataInput {...energyDemandMultiplierSet} id="energyDemandMultiplierSet" />
          </Cell>
        </Row>
      </RowGroup>
    </Grid>
  );
};
