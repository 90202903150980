import { ChartData } from 'chart.js';
import { DATASET_LABELS } from 'features/Charts/LineEnergyProduction/consts';
import { sumArrays } from 'services/utils/sumArrays';
import { CieploInputs } from 'services/slices/obliczeniaSlice/obliczenia/cieplo/types';
import { ObliczeniaTypeCalculations, ObliczeniaTypeInputs } from 'services/slices/obliczeniaSlice/obliczenia/types';
import { ObliczeniaProdukcjaEnergiiElektrycznej } from 'services/slices/obliczeniaSlice/produkcjaEnergiiElektrycznej/types';
import { DatasetBuilder } from './datasetBuilder';
import { NEW_COLORS } from 'services/consts/colors';
type ConsumtpionVsProductionDataType = {
  heatInputs: CieploInputs;
  obliczeniaInputs: ObliczeniaTypeInputs;
  obliczeniaCalc: ObliczeniaTypeCalculations;
  productionCalculations: ObliczeniaProdukcjaEnergiiElektrycznej;
  filterByPeriod: (_: number, i: number) => boolean;
};

export const consumtpionVsProductionData =
  ({ obliczeniaCalc, productionCalculations, filterByPeriod }: ConsumtpionVsProductionDataType) =>
  (): ChartData<'line', number[], string>['datasets'] => {
    const datasetBuilder = new DatasetBuilder(filterByPeriod);
    datasetBuilder
      .addData(obliczeniaCalc.energia_elektryczna_per_day, {
        label: DATASET_LABELS.energia_elektryczna_per_day,
        backgroundColor: NEW_COLORS.energia_elektryczna,
        showLine: false,
        fill: true,
        order: 1,
      })
      .addData(obliczeniaCalc.transport_per_day, {
        backgroundColor: NEW_COLORS.transport,
        label: DATASET_LABELS.transport_per_day,
        fill: true,
        order: 2,
        showLine: false,
      })
      .addData(obliczeniaCalc.cieplo_przemyslowe_per_day, {
        label: DATASET_LABELS.cieplo_przemyslowe_per_day,
        backgroundColor: NEW_COLORS.cieplo_przemyslowe,
        showLine: false,
        fill: true,
        order: 3,
      })
      .addData(obliczeniaCalc.cieplo_per_day, {
        label: DATASET_LABELS.cieplo_per_day,
        fill: true,
        backgroundColor: NEW_COLORS.cieplo_budynki,
        order: 4,
        showLine: false,
      })
      .addData(obliczeniaCalc.ładowanie_magazynu_prądu_per_day, {
        label: DATASET_LABELS.ładowanie_magazynu_prądu_per_day,
        backgroundColor: NEW_COLORS.energia_z_magazynu_pradu,
        showLine: false,
        fill: true,
        order: 5,
      })
      .addData(obliczeniaCalc.ładowanie_magazynu_ciepla_przemyslowego_per_day, {
        label: DATASET_LABELS.ładowanie_magazynu_ciepla_przemyslowego_per_day,
        backgroundColor: NEW_COLORS.cieplo_przemyslowe_magazyny,
        showLine: false,
        fill: true,
        order: 6,
      })
      .addData(obliczeniaCalc.ładowanie_magazynu_ciepła_per_day, {
        label: DATASET_LABELS.ładowanie_magazynu_ciepła_per_day,
        backgroundColor: NEW_COLORS.cieplo_budynki_magazyny,
        showLine: false,
        fill: true,
        order: 7,
      })
      .addData(obliczeniaCalc.elektroliza_per_day, {
        label: DATASET_LABELS.elektroliza_per_day,
        backgroundColor: NEW_COLORS.wodor,
        showLine: false,
        fill: true,
        order: 8,
      });

    return [
      ...datasetBuilder.build(),
      {
        data: productionCalculations.produkcja_pradu_per_day_po_stratach.filter(filterByPeriod),
        borderColor: NEW_COLORS.produkcja_energii,
        label: DATASET_LABELS.produkcja_pradu_per_day_po_stratach,
        borderWidth: 2.5,
        order: 0,
      },
    ];
  };
