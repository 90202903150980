import styled from '@emotion/styled';

export const ColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 1540px) {
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const Spacer = styled.div<{ mb?: number }>`
  margin-bottom: ${({ mb = 20 }) => mb}px;
`;
