import React, { useEffect, useState } from 'react';
import { LineEnergyProduction } from '../../features/Charts/LineEnergyProduction/LineEnergyProduction';
import { WithSidebar } from '../../components/Sidebar/Sidebar';
import { ChooseScenarioCard } from '../../features/Cards/ChooseScenarioCard/ChooseScenarioCard';
import { Cnt, FirstCol, ScenarioWithExport, SecondCol, SPartnersBanner, SPartnersCnt, SWrapper } from './styles';
import { EnergyBalance } from '../../features/Charts/EnergyBalance/EnergyBalance';
import { EnergyPies } from '../../features/EnergyPies/EnergyPies';
import { AvailableScenarios } from '../../features/Cards/ChooseScenarioCard/AvailableScenarios/AvailableScenarios';
import { useToggle } from '../../hooks/useToggle';
import { SpecificInformationCard } from 'features/Cards/SpecificInformationCard/SpecificInformationCard';
import { SPageCnt } from 'components/Card/styles';
import { useTitle, useSetState } from 'react-use';
import keys from 'i18n/keys';
import { useTranslation } from 'react-i18next';
import { LineEnergyProductionModal } from 'features/Charts/LineEnergyProduction/LineEnergyProductionModal';
import { Link } from 'components/Link/Link';
import { URL } from 'services/consts/routes';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { TOUR_STEPS } from './TourSteps/TourSteps';
import { TourTooltip } from './TourSteps/TooltipComponent';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';
import { updateSimulatorTour } from 'services/slices/periodSlice';
import { Box, Spacer } from 'components/common';
import { PdfGenerator } from 'features/PdfGenerator/PdfGenerator';
import { Button } from 'components/Button/Button';
import { SimulatorHeader } from 'features/Simulator/SimulatorHeader/SimulatorHeader';
import { SimulatorHeader as CommonSimulatorHeader } from 'components/SimulatorHeader/SimulatorHeader';
import { SaveSimulation } from 'features/Simulator/SaveSimulation/SaveSimulation';
import { DataInputContext } from 'components/DataInput/dataInputContext';
import { SIMULATOR_INTRO_STEPS } from './TourSteps/consts';

export const Simulator = () => {
  const { shouldRunSimulatorTour } = useAppSelector(state => state.period);
  const [isLineChartInModal, setIsLineChartInModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [{ run, steps }, setState] = useSetState<{ run: boolean; steps: Step[] }>({
    run: shouldRunSimulatorTour,
    steps: TOUR_STEPS,
  });

  useTitle(t(keys.PAGE_TITLE_SIMULATOR));

  useEffect(() => {
    if (shouldRunSimulatorTour) {
      setState({ run: true });
    }
  }, [shouldRunSimulatorTour, setState]);

  const onCloseModal = () => {
    setIsLineChartInModal(false);
  };

  const handleOpenModal = () => {
    setIsLineChartInModal(true);
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) || action === 'close') {
      setState({ run: false });
      dispatch(updateSimulatorTour(false));
    }
  };

  return (
    <Cnt>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        disableOverlayClose
        // scrollToFirstStep
        steps={steps}
        tooltipComponent={TourTooltip}
        styles={{
          options: {
            zIndex: 10000,
          },
          beacon: {
            display: 'none',
          },
          tooltipContent: {
            padding: '',
          },
        }}
      />
      <WithSidebar>
        <SPageCnt>
          <LineEnergyProductionModal open={isLineChartInModal} onCloseModal={onCloseModal} />
          <div>
            <SWrapper>
              <FirstCol>
                <DataInputContext.Provider value={128}>
                  <CommonSimulatorHeader title={t(keys.PARAMETERS_SECTION_TITLE_SIMULATION)} />

                  <ScenarioWithExport>
                    <div className="choose-scenario">
                      <ChooseScenarioCard />
                    </div>
                  </ScenarioWithExport>

                  <Spacer mb="20">
                    <SpecificInformationCard />
                  </Spacer>
                  <Box mb="20px" d="flex" justify="space-between" align="center">
                    <Button variant="outlined" to={URL.ADVANCED_OPTIONS} id={SIMULATOR_INTRO_STEPS.ADVANCED_DATA}>
                      {t(keys.ADVANCED_OPTIONS_LINK)}
                    </Button>
                    <SaveSimulation />
                  </Box>
                </DataInputContext.Provider>
              </FirstCol>
              <SecondCol>
                <SimulatorHeader />
                <Spacer mb="16px">
                  <LineEnergyProduction index={0} onOpenInModalMode={handleOpenModal} />
                </Spacer>
                <Spacer mb="16px">
                  <EnergyBalance />
                </Spacer>
                <EnergyPies />
              </SecondCol>
            </SWrapper>
            <SPartnersCnt>
              <SPartnersBanner />
            </SPartnersCnt>
          </div>
        </SPageCnt>
      </WithSidebar>
    </Cnt>
  );
};
