import styled from '@emotion/styled';

export const SOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1e202499;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000000000001;
  padding: 50px;
`;

export const SModal = styled.div<{ getMaxPosition?: boolean }>`
  padding: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  ${({ getMaxPosition }) =>
    getMaxPosition && {
      width: '100%',
      height: '100%',
      maxHeight: 'none',
    }}
`;
