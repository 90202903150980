import React from 'react';
import { SImg, SImgCnt } from './styles';

export const PartnersBanner = ({ className, ...props }: any) => {
  return (
    <SImgCnt className={`sponsors ${className}`} aria-describedby="Obrazy sponsorów" role="banner" {...props}>
      <SImg
        id="fundusze-europejskie-pomoc-techniczna"
        src="/images/FE.svg"
        alt="Fundusze Europejskie Pomoc Techniczna"
        className="bigger"
      />
      <SImg id="Rzeczpospolita-Polska" src="/images/PL.svg" alt="Rzeczpospolita Polska" />
      <SImg id="ncbir" ncbir src="/images/ncbir.svg" alt="Narodowe Centrum Badań i Rozwoju" />
      <SImg id="europejski-fundusz-rozwoju-regionalnego" src="/images/Frame 77.svg" alt="UE" />
    </SImgCnt>
  );
};
