import { CieploInputsPayload, CieploSelectOptionsPayload, NullableCieploInputsPayload } from './types';

type Key = CieploInputsPayload['name'] | CieploSelectOptionsPayload['name'] | NullableCieploInputsPayload['name'];

export const shouldUpdateObliczajCO = (key: Key) => {
  return (
    [
      'odsetek_budynkow_z_odzyskiem_ciepla',
      'stopien_zastapienia_starych_budynkow_nowymi',
      'rodzaj_termomodernizacji',
      'zapotrzebowanie_CO_input',
      'zapotrzebowanie_CO_input_custom',
    ] as Key[]
  ).includes(key);
};
