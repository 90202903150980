import { useSearchParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { useAppSelector } from 'services/configuration/store/hooks';
import { getObliczenia } from 'services/slices/obliczeniaSlice/selectors';
import { QueryParamsPayload, ScenarioData } from 'services/slices/obliczeniaSlice/types';
import { updatedDiff } from 'deep-object-diff';
import { obliczenia } from 'services/slices/obliczeniaSlice/obliczenia/obliczenia';
import { cloneDeep, merge } from 'lodash';
import { cieplo } from 'services/slices/obliczeniaSlice/obliczenia/cieplo/cieplo';
import { addSearchParams, deleteSearchParams } from '../AppParams/utils';
import { useEffect } from 'react';

export const useObliczeniaUpdate = (currentScenario: ScenarioData, searchParams: URLSearchParams) => {
  // const [searchParams, setSearchParams] = useSearchParams();

  const {
    inputs,
    consts,
    cieplo: { calculations: cieploCalc, ...cieploValues },
  } = useAppSelector(getObliczenia);

  const initialData: QueryParamsPayload['obliczenia'] = {
    inputs: cloneDeep(currentScenario.data.obliczenia),
    consts: cloneDeep(obliczenia.consts),
    cieplo: merge(cloneDeep(cieplo), currentScenario.data.cieplo),
  };
  const dataToCheck: QueryParamsPayload['obliczenia'] = {
    inputs: cloneDeep(inputs),
    consts: cloneDeep(consts),
    cieplo: cloneDeep(cieploValues),
  };
  const diff = updatedDiff(initialData, dataToCheck);

  deleteSearchParams(searchParams, 'obliczenia');
  if (Object.keys(diff).length) {
    addSearchParams(searchParams, 'obliczenia', JSON.stringify(diff));
  }
};
