import styled from '@emotion/styled';
import { Button } from 'components/Button/Button';
import { Period } from 'features/Period/Period';
import { Select } from '../../../components/Select/Select';

export const SCnt = styled.section`
  display: flex;
  flex-direction: column;
`;
export const ChartWrapper = styled.div`
  position: relative;
  height: 420px;
  canvas {
    max-width: 100%;
  }
`;
export const SChooseVariant = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const Sdiv = styled.div`
  width: 100%;
`;

export const SPeriod = styled(Period)`
  margin-left: auto;
`;

export const SExpandBtn = styled.button`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(2, 48, 71, 0.06), rgba(2, 48, 71, 0.06)), #ffffff;
  position: absolute;
  bottom: 60px;
  right: 30px;
`;

export const ShrinkBtn = styled(Button)`
  margin-left: 24px;
  svg {
    margin-left: 4px;
  }
`;
export const CloseBtn = styled(Button)`
  margin-left: 24px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    width: 10px;
    height: 10px;
  }
`;
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SAddBtnCnt = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  svg {
    margin-right: 8px;
  }
`;

export const ModalCharts = styled.div`
  flex-grow: 1;
  display: grid;
  grid-auto-rows: 1fr;
  grid-row-gap: 20px;

  .line-section {
    margin-bottom: 0;
  }

  .line-chart-wrapper {
    flex-grow: 1;
  }

  .chart-cnt {
    height: 100%;
    min-height: 250px;
  }

  canvas {
    max-height: 100% !important;
    height: 100% !important;
  }
`;

export const SToggleTooltipBtn = styled.button<{ tooltipVisible: boolean }>`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${({ tooltipVisible }) =>
    tooltipVisible ? '#297F94' : 'linear-gradient(0deg, rgba(2, 48, 71, 0.06), rgba(2, 48, 71, 0.06)), #ffffff'};
  position: absolute;
  bottom: 60px;
  right: 30px;
`;
