import React from 'react';
import { YearLabel } from './SimulatorHeader.styles';
import keys from 'i18n/keys';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/Select/Select';
import { availableYears } from 'services/consts/availableYears';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';
import { getObliczenia } from 'services/slices/obliczeniaSlice/selectors';
import { setSelectedYear } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { ReactComponent as CalendarMonthIcon } from 'assets/new/calendar_month.svg';
import { Box } from 'components/common';
import { PdfGenerator } from 'features/PdfGenerator/PdfGenerator';
import { Button } from 'components/Button/Button';
import { ReactComponent as DownloadIcon } from 'assets/pdf_icon.svg';
import { SimulatorHeader as CommonSimulatorHeader } from 'components/SimulatorHeader/SimulatorHeader';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';

export const SimulatorHeader = () => {
  const { t } = useTranslation();
  const { selectedYear } = useAppSelector(getObliczenia);
  const dispatch = useAppDispatch();
  return (
    <CommonSimulatorHeader title={t(keys.RESULTS_SECTION_TITLE_SIMULATION)}>
      <Box d="flex" align="center">
        <Box ml="auto" />
        <YearLabel>{t(keys.YEAR_LABEL)}</YearLabel>
        <Select
          id={SIMULATOR_INTRO_STEPS.CHOOSE_YEAR}
          menuStickCorner="right"
          showBorder
          variant="outlined"
          options={availableYears.map(el => ({ value: el, label: el.toString() }))}
          value={{ value: selectedYear, label: selectedYear.toString() }}
          onChange={({ value }) => {
            dispatch(setSelectedYear(value));
          }}
          startIcon={<CalendarMonthIcon />}
        />
        <Box ml="12px">
          <PdfGenerator>
            <Button id={SIMULATOR_INTRO_STEPS.EXPORT_PDF} variant="outlined" size="small">
              {/* <span>{t(keys.EKSPORT)}</span> */}
              <DownloadIcon style={{ marginLeft: '4px', height: 20, width: 20 }} />
            </Button>
          </PdfGenerator>
        </Box>
      </Box>
    </CommonSimulatorHeader>
  );
};
