import { HYDROGEN_BASIC_NEED_INITIAL_VALUES, HYDROGEN_CONSTS_INITIAL_VALUES } from './initialValues';

export const wodór = {
  /** Bazowe zapotrzebowanie */
  bazowe_zapotrzebowanie: {
    lotnictwo: HYDROGEN_BASIC_NEED_INITIAL_VALUES.lotnictwo,
    rafinerie: HYDROGEN_BASIC_NEED_INITIAL_VALUES.rafinerie,
    hutnictwo: HYDROGEN_BASIC_NEED_INITIAL_VALUES.hutnictwo,
    żegluga: HYDROGEN_BASIC_NEED_INITIAL_VALUES.żegluga,
    wojsko: HYDROGEN_BASIC_NEED_INITIAL_VALUES.wojsko,
    cement: HYDROGEN_BASIC_NEED_INITIAL_VALUES.cement,
    inne: HYDROGEN_BASIC_NEED_INITIAL_VALUES.inne,
  },

  procentowe_zapotrzebowanie: {
    lotnictwo: 0,
    rafinerie: 0,
    hutnictwo: 0,
    żegluga: 0,
    wojsko: 0,
    cement: 0,
    inne: 0,
  },

  /**Suma key z `bazowe_zapotrzebowanie` * `procentowe_zapotrzebowanie` */
  suma_zapotrzebowania: 0,

  consts: {
    /**Ilość prądu potrzebna na uzyskanie 1 mln ton wodoru */
    energyNeededToProduce1MlnTonOFHydrogen: HYDROGEN_CONSTS_INITIAL_VALUES.energyNeededToProduce1MlnTonOFHydrogen,

    /**Sprawność elektrolizerów przetwarzania energii prądu w energię H2 */
    electrolysersEfficency: HYDROGEN_CONSTS_INITIAL_VALUES.electrolysersEfficency,

    /**Energia 1 mln ton wodoru */
    energy1MlnTonOfHydrogen: HYDROGEN_CONSTS_INITIAL_VALUES.energy1MlnTonOfHydrogen,

    /**Sprawność magazynowania i produkcji prądu z wodoru */
    hydrogenStorageEfficency: HYDROGEN_CONSTS_INITIAL_VALUES.hydrogenStorageEfficency,
  },
};
