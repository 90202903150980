import { ChartData } from 'chart.js';
import { sumArrays } from 'services/utils/sumArrays';

type AddDataArgs = {
  label: string;
  backgroundColor: string;
  showLine: boolean;
  fill: boolean;
  order: number;
};
export class DatasetBuilder {
  private datasets: ChartData<'line', number[], string>['datasets'] = [];
  private nextArray: number[] = [];

  constructor(private filterByPeriod: (_: number, i: number) => boolean) {}

  addData(data: number[], addDataArgs: AddDataArgs) {
    const incomingData = data.filter(this.filterByPeriod);
    if (incomingData.length === 0 || incomingData.every(el => el === 0)) {
      return this;
    }
    this.nextArray = sumArrays(incomingData, this.nextArray);
    this.datasets.push(this.createDataset(this.nextArray, addDataArgs));
    return this;
  }

  build() {
    return this.datasets;
  }

  private createDataset(data: number[], { backgroundColor, fill, label, order, showLine }: AddDataArgs) {
    return {
      data,
      label,
      backgroundColor,
      showLine,
      fill,
      order,
    };
  }
}
