import keys from '../../../i18n/keys';
import { Table, TBody, Td, THead, Tr } from 'components/Table/Transparent';
import React, { useState } from 'react';
import { useAppSelector } from 'services/configuration/store/hooks';
import { roundNumb } from 'services/utils/roundNumb';
import { years } from './consts';
import { calcKwPerPerson, calcZłPerKw } from './helpers';
import { Spacer, SPeriod } from './styles';
import { useTranslation } from 'react-i18next';
import { AlignColumn } from 'components/Input/AlignColumn';
import { getKoszty, getProdukcjaEnergiiElektrycznejInputs } from 'services/slices/obliczeniaSlice/selectors';

export const EachCitizenCalc = () => {
  const [index, setIndex] = useState(4);

  const yearsToCalc = years[index].value;

  const {
    calculations: {
      wiatr,
      wiatrNaMorzu,
      fotowoltaika,
      atom: atomObliczenia,
      gazZiemny,
      baterieLitowe,
      elektrownieSzczytowoPompowe,
      magazynCiepłaDuży,
      magazynCiepłaDomowe,
      wodór,
    },
    koszt_mld_per_GW: {
      wiatr_na_ladzie,
      wiatr_na_morzu,
      PV,
      elektrownie_gazowe,
      baterie_litowe_stacjonarne,
      elektrownie_szczytowo_pompowe,
      magazyny_ciepla_duze,
      magazyny_ciepla_domowe,
      elektrolizery,
    },
  } = useAppSelector(getKoszty);
  const { atom_zainstalowano } = useAppSelector(getProdukcjaEnergiiElektrycznejInputs);
  const windKwPerPerson = roundNumb(calcKwPerPerson(wiatr.nowa_moc_zainstalowana), 1);
  const windZłPerKw = calcZłPerKw(wiatr_na_ladzie);
  const windSum = roundNumb(calcKwPerPerson(wiatr.nowa_moc_zainstalowana) * windZłPerKw, 0);
  const windSpendingPerYear = roundNumb(windSum / yearsToCalc, 0);

  const windOffShoreKwPerPerson = roundNumb(calcKwPerPerson(wiatrNaMorzu.nowa_moc_zainstalowana), 1);
  const windOffShoreZłPerKw = calcZłPerKw(wiatr_na_morzu);
  const windOffShoreSum = roundNumb(calcKwPerPerson(wiatrNaMorzu.nowa_moc_zainstalowana) * windOffShoreZłPerKw, 0);
  const windOffShoreSpendingPerYear = roundNumb(windOffShoreSum / yearsToCalc, 0);

  const photovoltaicKwPerPerson = roundNumb(calcKwPerPerson(fotowoltaika.nowa_moc_zainstalowana), 1);
  const photovoltaicZłPerKw = calcZłPerKw(PV);
  const photovoltaicSum = roundNumb(calcKwPerPerson(fotowoltaika.nowa_moc_zainstalowana) * photovoltaicZłPerKw, 0);
  const photovoltaicSpendingPerYear = roundNumb(photovoltaicSum / yearsToCalc, 0);

  const atomKwPerPerson = roundNumb(calcKwPerPerson(atomObliczenia.nowa_moc_zainstalowana), 1);
  const atomZłPerKw = calcZłPerKw(atom_zainstalowano);
  const atomSum = roundNumb(calcKwPerPerson(atomObliczenia.nowa_moc_zainstalowana) * atomZłPerKw, 0);
  const atomSpendingPerYear = roundNumb(atomSum / yearsToCalc, 0);

  const naturalGasKwPerPerson = roundNumb(calcKwPerPerson(gazZiemny.nowa_moc_zainstalowana), 1);
  const naturalGasZłPerKw = calcZłPerKw(elektrownie_gazowe);
  const naturalGasSum = roundNumb(calcKwPerPerson(gazZiemny.nowa_moc_zainstalowana) * naturalGasZłPerKw, 0);
  const naturalGasSpendingPerYear = roundNumb(naturalGasSum / yearsToCalc, 0);

  const lithiumBatteriesKwPerPerson = roundNumb(calcKwPerPerson(baterieLitowe.nowa_moc_zainstalowana), 1);
  const lithiumBatteriesZłPerKw = calcZłPerKw(baterie_litowe_stacjonarne);
  const lithiumBatteriesSum = roundNumb(
    calcKwPerPerson(baterieLitowe.nowa_moc_zainstalowana) * lithiumBatteriesZłPerKw,
    0,
  );

  const lithiumBatteriesSpendingPerYear = roundNumb(lithiumBatteriesSum / yearsToCalc, 0);

  const pumpsKwPerPerson = roundNumb(calcKwPerPerson(elektrownieSzczytowoPompowe.nowa_moc_zainstalowana), 1);
  const pumpsZłPerKw = calcZłPerKw(elektrownie_szczytowo_pompowe);
  const pumpsSum = roundNumb(calcKwPerPerson(elektrownieSzczytowoPompowe.nowa_moc_zainstalowana) * pumpsZłPerKw, 0);
  const pumpsSpendingPerYear = roundNumb(pumpsSum / yearsToCalc, 0);

  const PTESKwPerPerson = roundNumb(calcKwPerPerson(magazynCiepłaDuży.nowa_moc_zainstalowana), 1);
  const PTESZłPerKw = calcZłPerKw(magazyny_ciepla_duze);
  const PTESSum = roundNumb(calcKwPerPerson(magazynCiepłaDuży.nowa_moc_zainstalowana) * PTESZłPerKw, 0);
  const PTESSpendingPerYear = roundNumb(PTESSum / yearsToCalc, 0);

  const houseHeatStorageKwPerPerson = roundNumb(calcKwPerPerson(magazynCiepłaDomowe.nowa_moc_zainstalowana), 1);
  const houseHeatStorageZłPerKw = calcZłPerKw(magazyny_ciepla_domowe);
  const houseHeatStorageSum = roundNumb(
    calcKwPerPerson(magazynCiepłaDomowe.nowa_moc_zainstalowana) * houseHeatStorageZłPerKw,
    0,
  );

  const houseHeatStorageSpendingPerYear = roundNumb(houseHeatStorageSum / yearsToCalc, 0);

  const hydrogenKwPerPerson = roundNumb(calcKwPerPerson(wodór.nowa_moc_zainstalowana), 1);
  const hydrogenZłPerKw = calcZłPerKw(elektrolizery);
  const hydrogenSum = roundNumb(calcKwPerPerson(wodór.nowa_moc_zainstalowana) * hydrogenZłPerKw, 0);
  const hydrogenSpendingPerYear = roundNumb(hydrogenSum / yearsToCalc, 0);

  const sumAllSources =
    windSum +
    windOffShoreSum +
    photovoltaicSum +
    atomSum +
    naturalGasSum +
    lithiumBatteriesSum +
    pumpsSum +
    PTESSum +
    houseHeatStorageSum +
    hydrogenSum;

  const { t } = useTranslation();

  const sumPerYear = roundNumb(sumAllSources / yearsToCalc);
  return (
    <div>
      <SPeriod
        index={index}
        onNext={setIndex}
        onPrev={setIndex}
        max={years.length}
        value={`${years[index].value} ${t(keys.YEARS)}`}
      />

      <div id="costs-table">
        <Spacer>
          <Table>
            <THead>
              <Tr cols={4}>
                <Td>{t(keys.SOURCE_OF_ENERGY)}</Td>
                <Td>{t(keys.UNITS_KW_PERSON)}</Td>
                <Td>{t(keys.UNITS_PLN_KW)}</Td>
                <Td>{t(keys.SUM_IN_PLN)}</Td>
                <Td>{t(keys.ANNUAL_EXPENCES)}</Td>
              </Tr>
            </THead>
            <TBody>
              <Tr cols={4}>
                <Td>{t(keys.ONSHORE_WIND_COSTS_LABEL)}</Td>
                <Td>
                  <AlignColumn value={windKwPerPerson} />
                </Td>
                <Td>
                  <AlignColumn value={wiatr_na_ladzie} />
                </Td>
                <Td>
                  <AlignColumn value={windSum} />
                </Td>
                <Td>
                  <AlignColumn value={windSpendingPerYear} />
                </Td>
              </Tr>
              <Tr cols={4}>
                <Td>{t(keys.OFFSHORE_WIND_COSTS_LABEL)}</Td>
                <Td>
                  <AlignColumn value={windOffShoreKwPerPerson} />
                </Td>
                <Td>
                  <AlignColumn value={wiatr_na_morzu} />
                </Td>
                <Td>
                  <AlignColumn value={windOffShoreSum} />
                </Td>
                <Td>
                  <AlignColumn value={windOffShoreSpendingPerYear} />
                </Td>
              </Tr>
              <Tr cols={4}>
                <Td>{t(keys.PHOTOVOLTAICS)}</Td>
                <Td>
                  <AlignColumn value={photovoltaicKwPerPerson} />
                </Td>
                <Td>
                  <AlignColumn value={PV} />
                </Td>
                <Td>
                  <AlignColumn value={photovoltaicSum} />
                </Td>
                <Td>
                  <AlignColumn value={photovoltaicSpendingPerYear} />
                </Td>
              </Tr>
              <Tr cols={4}>
                <Td>{t(keys.ATOMIC)}</Td>
                <Td>
                  <AlignColumn value={atomKwPerPerson} />
                </Td>
                <Td>
                  <AlignColumn value={roundNumb(atomZłPerKw / 1000, 3)} />
                </Td>
                <Td>
                  <AlignColumn value={atomSum} />
                </Td>
                <Td>
                  <AlignColumn value={atomSpendingPerYear} />
                </Td>
              </Tr>
              <Tr cols={4}>
                <Td>{t(keys.NATURAL_GAS)}</Td>
                <Td>
                  <AlignColumn value={naturalGasKwPerPerson} />
                </Td>
                <Td>
                  <AlignColumn value={elektrownie_gazowe} />
                </Td>
                <Td>
                  <AlignColumn value={naturalGasSum} />
                </Td>
                <Td>
                  <AlignColumn value={naturalGasSpendingPerYear} />
                </Td>
              </Tr>
            </TBody>
          </Table>
        </Spacer>
        <Spacer>
          <Table>
            <THead>
              <Tr cols={4}>
                <Td>{t(keys.ENERGY_STORAGE)}</Td>
                <Td>{t(keys.UNITS_KW_PERSON)}</Td>
                <Td>{t(keys.UNITS_PLN_KW)}</Td>
                <Td>{t(keys.SUM_IN_PLN)}</Td>
                <Td>{t(keys.ANNUAL_EXPENCES)}</Td>
              </Tr>
            </THead>
            <TBody>
              <Tr cols={4}>
                <Td>{t(keys.LITHIUM_STACIONARY)}</Td>
                <Td>
                  <AlignColumn value={lithiumBatteriesKwPerPerson} />
                </Td>
                <Td>
                  <AlignColumn value={baterie_litowe_stacjonarne} />
                </Td>
                <Td>
                  <AlignColumn value={lithiumBatteriesSum} />
                </Td>
                <Td>
                  <AlignColumn value={lithiumBatteriesSpendingPerYear} />
                </Td>
              </Tr>
              <Tr cols={4}>
                <Td>{t(keys.PUMP_POWER_PLANTS)}</Td>
                <Td>
                  <AlignColumn value={pumpsKwPerPerson} />
                </Td>
                <Td>
                  <AlignColumn value={elektrownie_szczytowo_pompowe} />
                </Td>
                <Td>
                  <AlignColumn value={pumpsSum} />
                </Td>
                <Td>
                  <AlignColumn value={pumpsSpendingPerYear} />
                </Td>
              </Tr>
              <Tr cols={4}>
                <Td>{t(keys.HEAT_STORAGE_PTES)}</Td>
                <Td>
                  <AlignColumn value={PTESKwPerPerson} />
                </Td>
                <Td>
                  <AlignColumn value={magazyny_ciepla_duze} />
                </Td>
                <Td>
                  <AlignColumn value={PTESSum} />
                </Td>
                <Td>
                  <AlignColumn value={PTESSpendingPerYear} />
                </Td>
              </Tr>
              <Tr cols={4}>
                <Td>{t(keys.HEAT_STORAGE_HOME)}</Td>
                <Td>
                  <AlignColumn value={houseHeatStorageKwPerPerson} />
                </Td>
                <Td>
                  <AlignColumn value={magazyny_ciepla_domowe} />
                </Td>
                <Td>
                  <AlignColumn value={houseHeatStorageSum} />
                </Td>
                <Td>
                  <AlignColumn value={houseHeatStorageSpendingPerYear} />
                </Td>
              </Tr>
            </TBody>
          </Table>
        </Spacer>
        <Spacer>
          <Table>
            <THead>
              <Tr cols={4}>
                <Td>{t(keys.ELECTROLYZERS)}</Td>
                <Td>{t(keys.UNITS_KW_PERSON)}</Td>
                <Td>{t(keys.UNITS_PLN_KW)}</Td>
                <Td>{t(keys.SUM_IN_PLN)}</Td>
                <Td>{t(keys.ANNUAL_EXPENCES)}</Td>
              </Tr>
            </THead>
            <TBody>
              <Tr cols={4}>
                <Td>{t(keys.ELECTROLYZERS)}</Td>
                <Td>
                  <AlignColumn value={hydrogenKwPerPerson} />
                </Td>
                <Td>
                  <AlignColumn value={elektrolizery} />
                </Td>
                <Td>
                  <AlignColumn value={hydrogenSum} />
                </Td>
                <Td>
                  <AlignColumn value={hydrogenSpendingPerYear} />
                </Td>
              </Tr>
            </TBody>
          </Table>
        </Spacer>
        <Table>
          <TBody>
            <Tr cols={2} className="scale-to-other">
              <Td>{t(keys.TOTAL_CONSTRUCTION_COST_PER_PERSON)}</Td>
              <Td>{roundNumb(sumPerYear / 12)}</Td>
            </Tr>
          </TBody>
        </Table>
      </div>
    </div>
  );
};
