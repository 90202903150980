import styled from '@emotion/styled';
import { ReactComponent as CloseIcon } from 'assets/close_icon.svg';
export const SOverlay = styled.div<{ open: boolean; destroy?: boolean }>`
  position: fixed;
  top: 0;
  left: 0px;
  right: 0;
  bottom: ${({ open, destroy }) => (destroy && !open ? 'unset' : 0)};
  background: ${({ open }) => (open ? '#1e202499' : 'none')};
  z-index: ${({ open }) => (open ? 100 : -1)};
`;

export const SModal = styled.div<{ open: boolean }>`
  width: 650px;
  @media (min-width: 1440px) {
    width: calc(100vw - 576px);
  }
  @media (min-width: 1661px) {
    width: calc(100vw - 660px);
  }
`;

export const SCloseBtn = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.addAlpha(theme.primary, 0.06)};
  border-radius: 4px;
  font-weight: 500;
  margin-bottom: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.addAlpha(theme.primary, 0.03)};
  }
`;

export const SBackIcon = styled(CloseIcon)`
  margin-right: 4px;
  width: 16px;
  height: 16px;
  padding: 4px;
`;

export const STitle = styled.h2`
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 24px;
`;

export const SScenarioTile = styled.button<{ active: boolean }>`
  cursor: pointer;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px;
  border-top: 1px solid #deeaf0;
  width: 100%;

  &:last-of-type {
    border-bottom: 1px solid #deeaf0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.addAlpha(theme.primary, 0.06)};
  }
`;

export const ScenarioTitle = styled.p`
  color: ${({ theme }) => theme.addAlpha(theme.primary, 0.5)};
  font-weight: 700;
`;

export const ScenarioDesc = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const SContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SPreviewModal = styled.div<{ open: boolean }>`
  height: 100%;
  width: 700px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

export const StickyBtn = styled.div`
  position: sticky;
  bottom: 0;
  background: #ffffff;
  margin: 0 -32px;
  padding: 12px 32px;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.25);
  margin-top: auto;
`;

export const SSelectedScenario = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
