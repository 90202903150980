import { ChartData } from 'chart.js';
import keys from 'i18n/keys';
import { useAppSelector } from 'services/configuration/store/hooks';
import { COLORS, NEW_COLORS } from 'services/consts/colors';
import { getBilansEnergii } from 'services/slices/obliczeniaSlice/selectors';
const borderRadius = 4;
const borderRadiusAllCorners = {
  topLeft: borderRadius,
  topRight: borderRadius,
  bottomLeft: borderRadius,
  bottomRight: borderRadius,
};

const fillIfNotZero = (arg: number, reutrnArg: string) => {
  if (arg > 0) {
    return reutrnArg;
  }
  return '';
};

export const useBarDataset = (): ChartData<'bar', number[], string>['datasets'] => {
  const { produkcja, zużycie } = useAppSelector(getBilansEnergii);

  const initialStructure = [
    {
      data: [zużycie.prąd_inne, produkcja.wiatr_na_ladzie],
      backgroundColor: [
        fillIfNotZero(zużycie.prąd_inne, NEW_COLORS.energia_elektryczna),
        fillIfNotZero(produkcja.wiatr_na_ladzie, NEW_COLORS.wiatr_lad),
      ],
      label: `${keys.REST_CHART_LABEL}|${keys.ONSHORE_WIND_CHART_LABEL}`,
      borderRadius: borderRadiusAllCorners,
      borderWidth: 0,
      barThickness: 16,
    },

    {
      data: [zużycie.transport, produkcja.wiatr_na_morzu],
      backgroundColor: [
        fillIfNotZero(zużycie.transport, NEW_COLORS.transport),
        fillIfNotZero(produkcja.wiatr_na_morzu, NEW_COLORS.wiatr_morze),
      ],
      label: `${fillIfNotZero(zużycie.transport, keys.TRANSPORT_CHART_LABEL)}|${fillIfNotZero(
        produkcja.wiatr_na_morzu,
        keys.WIND_OFFSHORE_CHART_LABEL,
      )}`,
      borderRadius: borderRadiusAllCorners,
      borderWidth: 0,
      barThickness: 16,
    },

    {
      data: [zużycie.ciepło_przmyslowe, 0],
      backgroundColor: [fillIfNotZero(zużycie.ciepło_przmyslowe, NEW_COLORS.cieplo_przemyslowe), 'transparent'],
      label: `${fillIfNotZero(zużycie.ciepło_przmyslowe, keys.CIEPLO_PRZEMYSLOWE_CHART_LABEL)}|`,
      borderRadius: borderRadiusAllCorners,
      borderWidth: 0,
      barThickness: 16,
    },

    {
      data: [zużycie.ciepło, produkcja.PV],
      backgroundColor: [
        fillIfNotZero(zużycie.ciepło, NEW_COLORS.cieplo_budynki),
        fillIfNotZero(produkcja.PV, NEW_COLORS.PV),
      ],
      label: `${fillIfNotZero(zużycie.ciepło, keys.HEAT_PRODUCTION_CHART_LABEL)}|${fillIfNotZero(
        produkcja.PV,
        keys.PHOTOVOLTAIC_CHART_LABEL,
      )}`,
      borderRadius: borderRadiusAllCorners,
      borderWidth: 0,
      barThickness: 16,
    },

    {
      data: [zużycie.ciepło_otoczenia, produkcja.atom],
      backgroundColor: [
        fillIfNotZero(zużycie.ciepło_otoczenia, NEW_COLORS.cieplo_budynki_cieplo_otoczenia),
        fillIfNotZero(produkcja.atom, NEW_COLORS.atom),
      ],
      label: `${fillIfNotZero(zużycie.ciepło_otoczenia, keys.CIEPLO_OTOCZENIA_CHART_LABEL)}|${fillIfNotZero(
        produkcja.atom,
        keys.ATOM_CHART_LABEL,
      )}`,
      borderRadius: borderRadiusAllCorners,
      borderWidth: 0,
      barThickness: 16,
    },

    {
      data: [zużycie.wodór, produkcja.gaz],
      backgroundColor: [
        fillIfNotZero(zużycie.wodór, NEW_COLORS.wodor),
        fillIfNotZero(produkcja.gaz, NEW_COLORS.zrodla_dyspozycyjne),
      ],
      label: `${fillIfNotZero(zużycie.wodór, keys.ELECTROLYSERS_CHART_LABEL)}|${fillIfNotZero(
        produkcja.gaz,
        keys.DISPOSAL_SOURCES_CHART_LABEL,
      )}`,
      borderRadius: borderRadiusAllCorners,
      borderWidth: 0,
      barThickness: 16,
    },

    {
      data: [zużycie.nadwyżka, produkcja.kogeneracja],
      backgroundColor: [
        fillIfNotZero(zużycie.nadwyżka, NEW_COLORS.niewykorzystana_energia),
        fillIfNotZero(produkcja.kogeneracja, NEW_COLORS.cieplo_kogeneracja),
      ],
      label: `${fillIfNotZero(zużycie.nadwyżka, keys.UNUSED_ENERGY_CHART_LABEL)}|${fillIfNotZero(
        produkcja.kogeneracja,
        keys.COOGENERATION_CHART_LABEL,
      )}`,
      borderRadius: borderRadiusAllCorners,
      borderWidth: 0,
      barThickness: 16,
    },
    {
      data: [zużycie.magazyn, produkcja.ciepło_otoczenia],
      backgroundColor: [
        fillIfNotZero(zużycie.magazyn, NEW_COLORS.straty),
        fillIfNotZero(produkcja.ciepło_otoczenia, NEW_COLORS.cieplo_otoczenia_z_produckji),
      ],
      label: `${fillIfNotZero(zużycie.magazyn, keys.LOOSES_CHART_LABEL)}|${fillIfNotZero(
        produkcja.ciepło_otoczenia,
        keys.CIEPLO_OTOCZENIA_CHART_LABEL,
      )}`,
      borderRadius: borderRadiusAllCorners,
      borderWidth: 0,
      barThickness: 16,
    },
  ];

  return initialStructure;
};
