import { useLocation } from 'react-router';
import { AVIALABLE_LANGUAGES, DEFAULT_LANGUAGE, Language } from './consts';

export const getCurrentLanguage = (): Language => {
  const storageItem = localStorage.getItem('currentLanguage') as Language;
  const isAvailable = AVIALABLE_LANGUAGES.includes(storageItem);
  if (isAvailable) {
    return storageItem;
  }
  return DEFAULT_LANGUAGE;
};

export const useCurrentLang = (): Language | 'id' => {
  const location = useLocation();
  if (location.pathname.startsWith('/id')) {
    return 'id';
  }
  if (location.pathname.startsWith('/en')) {
    return 'en';
  }
  return 'pl';
};

export const setLang = (language: Language) => localStorage.setItem('currentLanguage', language);
