import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname, search } = useLocation();
  useEffect(() => {
    window.scrollTo({
      //@ts-ignore
      behavior: 'instant',
      top: 0,
    });
  }, [pathname]);

  return null;
}
