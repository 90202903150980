import styled from '@emotion/styled';
import { Button } from 'components/Button/Button';

export const TooltipTitle = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const SCurrStep = styled.span`
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  color: ${({ theme }) => theme.addAlpha(theme.primary, 0.75)};
  margin-left: 8px;
  white-space: nowrap;
`;

export const TooltipBody = styled.div`
  max-height: 80vh;
  background-color: #fff;
  padding: 20px;
  overflow-y: auto;
  max-width: 500px;
`;
export const TooltipFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  button {
    margin: 0 10px;
  }
`;

export const CloseButton = styled(Button)`
  text-decoration: underline;
  margin-right: auto;
`;
