import { useAppSelector } from 'services/configuration/store/hooks';
import { getObliczenia } from 'services/slices/obliczeniaSlice/selectors';
import { dotToComma, roundNumb } from 'services/utils/roundNumb';

export const usePompaCieplaSCOP = () => {
  const {
    cieplo: {
      inputs: { moc_pomp_ciepla },
    },
    calculations: {
      cieplo: {
        CO_energia_z_pc_po_elastycznosci_suma,
        CO_moc_th_pc_po_elastycznosci_suma,
        CWU_dostepna_moc_el_pc_po_elastycznosci_suma,
        CWU_moc_th_pc_po_elastycznosci_suma,
      },
    },
  } = useAppSelector(getObliczenia);
  const pc_el = CO_energia_z_pc_po_elastycznosci_suma + CWU_dostepna_moc_el_pc_po_elastycznosci_suma;
  const pc_th = CO_moc_th_pc_po_elastycznosci_suma + CWU_moc_th_pc_po_elastycznosci_suma;
  const pompaCieplaSCOP = pc_el > 0 ? pc_th / pc_el : 0;
  const moc_z_moca_pomp_ciepla = moc_pomp_ciepla * Number(pompaCieplaSCOP);

  return {
    pompaCieplaSCOP: pompaCieplaSCOP > 0 ? dotToComma(pompaCieplaSCOP.toFixed(1)) : 0,
    moc_z_moca_pomp_ciepla: dotToComma(roundNumb(moc_z_moca_pomp_ciepla, 1)),
  };
};
