import styled from '@emotion/styled';

export const SImgCnt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;

    img,
    img:last-of-type,
    img:first-of-type {
      margin: 0 auto;
    }
  }
  @media (max-width: 420px) {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;

    img,
    img:last-of-type,
    img:first-of-type {
      margin: 0 auto;
    }
  }
`;

export const SImg = styled.img<{ ncbir?: boolean }>`
  margin: 0 22px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  height: 40px;
  &:last-of-type {
    margin-right: 0;
  }
  &:first-of-type {
    margin-left: 0;
  }

  &.bigger {
    height: 50px;
  }
`;
