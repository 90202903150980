/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { PageCard } from 'components/Card/PageCard';
import { PageCnt } from 'components/Card/PageCnt';
import { EditorText } from 'components/EditorText';
import { Footer } from 'components/Footer/Footer';
import { WithSidebar } from 'components/Sidebar/Sidebar';
import { SimulatorHeader } from 'components/SimulatorHeader/SimulatorHeader';
import { TileTitle } from 'components/Titles/TileTitle';
import keys from 'i18n/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'react-use';
import { SExcelBtn, SImgCol, STitle, STopSection, SYouTube } from './styles';
import { ReactComponent as ExcelIcon } from 'assets/microsoft_excel.svg';
import YouTube from 'react-youtube';

export const AboutData = () => {
  const { t } = useTranslation();
  useTitle(t(keys.ABOUT_DATA_TOP_TEXT_title));
  return (
    <div>
      <WithSidebar>
        <PageCnt>
          <SimulatorHeader title={''} />
          <div
            css={css`
              padding-left: 100px;
            `}
          >
            <STopSection>
              <div>
                <STitle>{t(keys['ABOUT_DATA_TOP_TEXT_title'])}</STitle>
                <EditorText
                  css={css`
                    margin-bottom: 52px;
                  `}
                  dangerouslySetInnerHTML={{ __html: t(keys.ABOUT_DATA_TOP_TEXT) }}
                ></EditorText>
                {/* <SExcelBtn variant="primary" href={t(keys.XLS_GEOTHERMY_DOWNLOAD_PATH)}>
                  <ExcelIcon />
                  <span>{t(keys.DOWNLOAD_EXCEL_TEXT_GEOTHERMY)}</span>
                </SExcelBtn> */}
              </div>
              <SYouTube>
                <YouTube
                  className="player"
                  videoId={t(keys.VIDEO_ABOUT_DATA)}
                  opts={{
                    height: '90%',
                    width: '100%',
                  }}
                />
              </SYouTube>
            </STopSection>
            <div
              css={css`
                max-width: 700px;
                margin: 0 auto;
              `}
            >
              <EditorText dangerouslySetInnerHTML={{ __html: t(keys.ABOUT_DATA_DESCRIPTION_TEXT) }}></EditorText>
            </div>
          </div>
        </PageCnt>
      </WithSidebar>
    </div>
  );
};
