import styled from '@emotion/styled';
import { Button } from 'components/Button/Button';
import { EditButton } from 'components/Button/EditButton';

export const DisposableEnergySourcesCnt = styled.div`
  display: block;
  position: relative;
  padding: 8px 16px;
  border-radius: 6px;
  width: 100%;
  background-color: rgba(126, 136, 135, 0.07);
  border: 2px solid rgb(221, 225, 230);
`;

export const SDisposalModal = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 16px;
  background: #ffffff;
  border: 1px solid rgba(151, 146, 133, 0.4);
  min-width: 545px;
  z-index: 50;
`;

export const SHydrogenUse = styled.p`
  color: #046494;
  margin-top: 16px;
  font-style: italic;
  font-size: 14px;
  line-height: 20px;
`;

export const SHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .tile-title {
    margin-bottom: 0;
    margin-right: 8px;
  }

  button {
    margin-left: auto;
  }
`;

export const SSSliderLabels = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SSliderCnt = styled.div`
  .horizontal-slider {
    margin-top: -9px;
  }
`;

export const SSliderCalcs = styled.div`
  margin-top: -8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const BiomethaneCol = styled.div`
  text-align: right;

  .d-flex {
    justify-content: flex-end;
  }
`;

export const DisposalTitle = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  margin-left: 4px;
`;

export const SEditBtn = styled(EditButton)`
  margin-left: auto;
  background-color: #fff;
  padding: 6px;
  border-radius: 8px;
`;

export const MoreDataBtn = styled(Button)`
  margin-left: auto;
`;

export const CH4ConumptionLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`;

export const CH4Conumption = styled.div<{ naturalGas: number }>`
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: ${({ naturalGas }) => {
    if (naturalGas < 1.5) {
      return '#87A771';
    }
    if (naturalGas < 3) {
      return '#b2de84';
    }
    if (naturalGas < 5) {
      return '#FFD34C';
    }
    if (naturalGas < 10) {
      return '#FFAC33';
    }
    return ' #b31c1c';
  }};
`;

export const Demand = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  span {
    color: #667085;
  }
`;
