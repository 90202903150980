import styled from '@emotion/styled';

export const PageGrid = styled.div`
  display: grid;
  gap: 24px;
  padding-bottom: 24px;
  @media (min-width: 1024px) {
    grid-template-columns: 420px 1fr 1fr;
  }
`;

export const Subtitle = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  padding-right: 56px;
`;
