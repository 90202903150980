import styled from '@emotion/styled';

export const EditorText = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  h1,
  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 24px;
    font-weight: bold;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: bold;
  }
  h6 {
    font-size: 12px;
    line-height: 18px;
  }

  p {
    margin-bottom: 24px;
  }

  ol {
    list-style-type: decimal;
    padding-left: 25px;
  }
  ul {
    list-style-type: disc;
    padding-left: 25px;
  }

  img {
    display: block;
    margin: 0 auto;
    object-fit: contain;
    max-width: 90%;
  }

  a {
    color: #219ebc;
    text-decoration: underline;
  }

  table {
    width: 100%;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }
  thead {
    color: #6a7f85;
    background: #f2f9fc;
  }
  tbody {
    background: #fafeff;
    color: #303638;
  }
  td {
    border-color: #e6e8e8;
    border-style: solid;
    padding: 3px 6px;
    word-wrap: break-word;
    overflow: hidden;
  }

  .editor-text {
    font-size: 14px;
    line-height: 21px;
    h1,
    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 24px;
      font-weight: bold;
    }

    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
      font-weight: bold;
    }

    p {
      margin-bottom: 24px;
    }

    ol {
      list-style-type: decimal;
      padding-left: 25px;
    }
    ul {
      list-style-type: disc;
      padding-left: 25px;
    }

    img {
      display: block;
      margin: 0 auto;
      object-fit: contain;
      max-width: 90%;
    }

    a {
      color: #219ebc;
      text-decoration: underline;
    }

    table {
      width: 100%;
      margin-bottom: 30px;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
    }
    thead {
      color: #6a7f85;
      background: #f2f9fc;
    }
    tbody {
      background: #fafeff;
      color: #303638;
    }
    td {
      border-color: #e6e8e8;
      border-style: solid;
      padding: 3px 6px;
      word-wrap: break-word;
      overflow: hidden;
    }
  }
`;

EditorText.defaultProps = {
  className: 'editor-text',
};
