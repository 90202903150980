import React from 'react';
// import keys from 'i18n/keys';
// import { useTranslation } from 'react-i18next';
// import { useWindowSize } from 'react-use';
// import { SCnt, SIcon, Stext, STextCnt } from './styles';

export const MobileNotSupported = ({ children }: { children: JSX.Element }): JSX.Element => {
  // const { width } = useWindowSize();
  // const { t } = useTranslation();
  // if (width < 600) {
  //   return (
  //     <SCnt>
  //       <SIcon />
  //       <STextCnt>
  //         <Stext>{t(keys.MOBILE_NOT_SUPPORTED)}</Stext>
  //         <Stext>{t(keys.SORRY_INCONVENIENCE)}</Stext>
  //       </STextCnt>
  //     </SCnt>
  //   );
  // }

  return children;
};
