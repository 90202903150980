import { cloneDeep, merge, mergeWith } from 'lodash';
import {
  RODZAJE_TERMOMODERNIZACJI,
  TEMPERATURA_CO_CWU,
} from 'services/slices/obliczeniaSlice/obliczenia/cieplo/consts';
import { obliczenia } from 'services/slices/obliczeniaSlice/obliczenia/obliczenia';
import { ObliczeniaType } from 'services/slices/obliczeniaSlice/obliczenia/types';
import { QueryParamsPayload, ScenarioDataPayload } from 'services/slices/obliczeniaSlice/types';
import { getSearchParam } from './utils';

function customizer(objValue, srcValue, key: keyof ObliczeniaType['cieplo']['selectOptions']) {
  if (key === 'rodzaj_termomodernizacji') {
    if (RODZAJE_TERMOMODERNIZACJI.find(({ value }) => value === srcValue)) {
      return srcValue;
    }
    return objValue;
  }
  if (key === 'temperatura_CO' || key === 'temperatura_CWU') {
    if (TEMPERATURA_CO_CWU.find(({ value }) => value === srcValue)) {
      return srcValue;
    }
    return objValue;
  }
  if (key === 'obliczaj_CO') {
    return objValue || srcValue;
  }
}

export const mergeObliczenia = (
  { cieplo: { calculations, ...rest }, consts, inputs, selectedYear }: ObliczeniaType,
  scenarioData: ScenarioDataPayload,
): QueryParamsPayload['obliczenia'] => {
  const clone: QueryParamsPayload['obliczenia'] = cloneDeep({ cieplo: rest, consts, inputs, selectedYear });
  const obliczeniaObj = getSearchParam('obliczenia') || '{}';
  const mergedWithScenario = merge(
    clone,
    cloneDeep({
      inputs: scenarioData.obliczenia,
      cieplo: scenarioData.cieplo,
      consts: obliczenia.consts,
    }),
  );
  const obliczeniaMerged: QueryParamsPayload['obliczenia'] = mergeWith(
    mergedWithScenario,
    JSON.parse(obliczeniaObj),
    customizer,
  );
  return obliczeniaMerged;
};
