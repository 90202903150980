import { SelectOptionsValues } from './types';
import keys from 'i18n/keys';
export const selectOptions = [
  { label: keys.LINE_SELECT_OPTION_1, value: SelectOptionsValues.EnergyDemandVsProduction },
  { label: keys.LINE_SELECT_OPTION_2, value: SelectOptionsValues.EnergyProductionVsDemand },
  { label: keys.LINE_SELECT_OPTION_3, value: SelectOptionsValues.EnergyStorage },
  { label: keys.LINE_SELECT_OPTION_4, value: SelectOptionsValues.HeatStorage },
  { label: keys.LINE_SELECT_OPTION_5, value: SelectOptionsValues.MagazynCieploPrzemyslowe },
];

export const DATASET_LABELS = {
  źródła_dyspozycyjne_per_day: keys.LINE_TOOLTIP_DISP,
  magazyn_prądu_per_day: keys.LINE_TOOLTIP_ENERGY_STORAGE,
  energia_elektryczna_per_day: keys.LINE_TOOLTIP_ENERGY_ELASTICITY,
  cieplo_per_day: keys.LINE_TOOLTIP_HEAT,
  magazyn_ciepła_per_day: keys.LINE_TOOLTIP_HEAT_AT_STORAGE,
  magazyn_ciepła_przemyslowy_per_day: keys.LINE_TOOLTIP_MAGAZYN_CIEPLA_PRZEMYSLOWEGO,
  elektroliza_per_day: keys.LINE_TOOLTIP_HYDROGEN,
  zapotrzebowanie_na_prąd_z_elastycznością_per_day: keys.LINE_TOOLTIP_NEED_WITH_ELASTICITY,
  transport_per_day: keys.LINE_TOOLTIP_TRANSPORT,
  atom_produkcja_po_stratach_per_day: keys.LINE_TOOLTIP_ATOM,
  PV_produkcja_po_stratach_per_day: keys.LINE_TOOLTIP_PHOTOVOLTAIC,
  wiatr_lad_produkcja_po_stratach_per_day: keys.LINE_TOOLTIP_WIND,
  wiatr_offshore_produkcja_po_stratach_per_day: keys.LINE_TOOLTIP_WIND_OFFSHORE,
  energia_z_magazynu_prądu_per_day: keys.LINE_TOOLTIP_FROM_ENERGY_STORAGE,
  zapotrzebowanie_na_prąd_per_day: keys.LINE_TOOLTIP_DEMAND,
  energia_z_magazynu_ciepła_per_day: keys.LINE_TOOLTIP_COOGENERATION,
  // moc_elektrolizerow: keys.LINE_TOOLTIP_DEMAND_HYDROGEN,
  produkcja_pradu_per_day_po_stratach: keys.LINE_TOOLTIP_PRODUCED_SUM,
  ładowanie_magazynu_prądu_per_day: keys.LINE_TOOLTIP_CHARGING_ENERGY_STORAGE,
  ładowanie_magazynu_ciepła_per_day: keys.LINE_TOOLTIP_CHARGING_HEAT_STORAGE,
  cieplo_przemyslowe_per_day: keys.LINE_TOOLTIP_CIEPLO_PRZEMYSLOWE,
  ładowanie_magazynu_ciepla_przemyslowego_per_day: keys.LINE_TOOLTIP_CIEPLO_PRZEMYSLOWE_LADOWANIE,
  energia_z_magazynu_ciepla_przemyslowego_per_day: keys.LINE_TOOLTIP_CIEPLO_PRZEMYSLOWE_Z_MAGAZYNU,
  zapotrzebowanie_z_elastycznoscia_z_wodorem_per_day: keys.LINE_TOOLTIP_ZAPOTRZ_Z_WODOREM,
};
