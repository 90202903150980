import styled from '@emotion/styled';
import { Text } from '../GroupedData.styles';

export const HydrogenText = styled(Text)`
  font-weight: normal;
`;

export const Grid = styled.div<{ border?: boolean }>`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 8px;
  border-bottom: ${({ border }) => (border ? '1px solid #dfe1e6' : '')};
  padding-bottom: 6px;
  margin-bottom: 6px;
  max-width: 350px;
`;
