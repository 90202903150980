import styled from '@emotion/styled';
import { Card } from 'components/common';

export const ExportCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;

  .title {
    background-color: #fff;
    border-radius: 8px;
    font-size: 31px;
    line-height: 31px;
    width: fit-content;
  }

  .buttons-cnt {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const SErrorWrapper = styled.p`
  background: linear-gradient(0deg, rgba(255, 22, 84, 0.04), rgba(255, 22, 84, 0.04)), #ffffff;
  border: 1px solid #ff1654;
  border-radius: 4px;
  color: #ff1654;
  padding: 6px 12px;
  text-align: center;
`;
export const SSuccessWrapper = styled.p`
  background: linear-gradient(0deg, rgba(32, 245, 114, 0.04), rgba(32, 245, 114, 0.04)), #ffffff;
  border: 1px solid #1eeb33;
  border-radius: 4px;
  color: #1eeb33;
  padding: 6px 12px;
  text-align: center;
`;
