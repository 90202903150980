import { useAppSelector } from 'services/configuration/store/hooks';
import { getObliczenia } from 'services/slices/obliczeniaSlice/selectors';

export const useHeatSum = () => {
  const {
    calculations: {
      cieplo: {
        CO_energia_z_pc_po_elastycznosci_suma,
        CO_moc_th_pc_po_elastycznosci_suma,
        CO_reszta_ciepla_z_grzalek_po_elastycznosci_suma,
        CWU_dostepna_moc_el_pc_po_elastycznosci_suma,
        CWU_moc_th_pc_po_elastycznosci_suma,
        CWU_reszta_ciepla_z_grzalek_po_elastycznosci_suma,
      },
    },
  } = useAppSelector(getObliczenia);

  const bilans_pradu =
    CO_energia_z_pc_po_elastycznosci_suma +
    CO_reszta_ciepla_z_grzalek_po_elastycznosci_suma +
    CWU_reszta_ciepla_z_grzalek_po_elastycznosci_suma +
    CWU_dostepna_moc_el_pc_po_elastycznosci_suma;

  const bilans_ciepla =
    CO_moc_th_pc_po_elastycznosci_suma -
    CO_energia_z_pc_po_elastycznosci_suma +
    CO_energia_z_pc_po_elastycznosci_suma +
    CO_reszta_ciepla_z_grzalek_po_elastycznosci_suma +
    CWU_dostepna_moc_el_pc_po_elastycznosci_suma +
    CWU_moc_th_pc_po_elastycznosci_suma -
    CWU_dostepna_moc_el_pc_po_elastycznosci_suma +
    CWU_reszta_ciepla_z_grzalek_po_elastycznosci_suma;

  return { bilans_pradu, bilans_ciepla };
};
