import { configureStore } from '@reduxjs/toolkit';
import { period } from 'services/slices/periodSlice';
import { obliczeniaSlice } from 'services/slices/obliczeniaSlice/obliczeniaSlice';

export const store = configureStore({
  reducer: {
    period,
    // calculations,
    [obliczeniaSlice.name]: obliczeniaSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
