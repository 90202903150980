import { ScenarioData } from './types';
export const AVAILABLE_SCENARIOS: ScenarioData[] = [
  {
    label: 'SCENARIO_0_label',
    title: 'SCENARIO_0_title',
    description: 'SCENARIO_0',
    short_description: 'SCENARIO_0_short_description',
    data: {
      produkcjaEnergiiElektrycznej: {
        inputs: {
          wiatr_lad_zainstalowano: 9,
          wiatr_offshore_zainstalowano: 0,
          PV_zainstalowano: 13,
          atom_zainstalowano: 0,
          udzial_bifacji_EW_w_PV: 0,

          udzial_nowoczesnych_turbin_wiatrowych: 0,
        },
      },
      cieplo: {
        obliczaj_CO: false,
        inputs: {
          odsetek_budynkow_z_odzyskiem_ciepla: 0,
          moc_pomp_ciepla: 0,
          zapotrzebowanie_CWU_względem_2023: 0,
          stopien_zastapienia_starych_budynkow_nowymi: 0,
        },
        selectOptions: {
          rodzaj_termomodernizacji: 170,
        },
      },
      obliczenia: {
        baterie_litowe_stacjonarne: 0,
        baterie_samochodowe: 0,
        elektrownie_szczytowo_pompowe: 0,
        mnożnik_zapotrzebowania_KSE: 1,
        moc_elektrolizerow: 0,
        odbiorcy_podlaczeni_do_sieci: 0.35,
        odsetek_elektrowni_pracujacych_w_kogeneracji: 0.4,
        odbiorcy_z_magazynami_ciepla_z_siecia_CO: 0.35,
        pojemnosc_magazynu_ciepla: 0,
        wspolczynnik_elastyczności: 0,
        strata_przy_przesyle: 0.05,

        zapotrzebowanie_na_cieplo_w_przemysle: 0,
        pojemnosc_magazynu_ciepla_w_przemysle: 0,
        odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle: 0,
        odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych: 0,

        transport: {
          ilość_aut_osobowych: 0,
          inne_rodzaje_transportu: 0,
          liczba_pasażerokilometrów: 0,
          udział_kolei_w_transporcie: 0,
          wolumen_transportu_towarów: 0,
          zużycie_energii_aut_osobowych: 0,
          średni_przejeżdżany_dystans_aut_osobowych: 0,
        },
      },
      wodór: {
        cement: 0,
        hutnictwo: 0,
        inne: 0,
        lotnictwo: 0,
        rafinerie: 0,
        wojsko: 0,
        żegluga: 0,
      },
    },
  },
  {
    label: 'SCENARIO_1_label',
    title: 'SCENARIO_1_title',
    description: 'SCENARIO_1',
    short_description: 'SCENARIO_1_short_description',
    data: {
      produkcjaEnergiiElektrycznej: {
        inputs: {
          PV_zainstalowano: 50,
          wiatr_offshore_zainstalowano: 10,
          wiatr_lad_zainstalowano: 30,
          atom_zainstalowano: 0,
          udzial_bifacji_EW_w_PV: 0.2,
          udzial_nowoczesnych_turbin_wiatrowych: 0.6,
        },
      },
      cieplo: {
        obliczaj_CO: false,
        inputs: {
          odsetek_budynkow_z_odzyskiem_ciepla: 0,
          moc_pomp_ciepla: 0,
          zapotrzebowanie_CWU_względem_2023: 0,
          stopien_zastapienia_starych_budynkow_nowymi: 0,
        },
        selectOptions: {
          rodzaj_termomodernizacji: 170,
        },
      },
      obliczenia: {
        baterie_litowe_stacjonarne: 0,
        baterie_samochodowe: 0,
        elektrownie_szczytowo_pompowe: 0,
        mnożnik_zapotrzebowania_KSE: 0.86,
        moc_elektrolizerow: 0,
        odbiorcy_podlaczeni_do_sieci: 0.35,
        odsetek_elektrowni_pracujacych_w_kogeneracji: 0.4,
        odbiorcy_z_magazynami_ciepla_z_siecia_CO: 0.35,
        pojemnosc_magazynu_ciepla: 0,
        wspolczynnik_elastyczności: 0,
        strata_przy_przesyle: 0.05,

        zapotrzebowanie_na_cieplo_w_przemysle: 0,
        pojemnosc_magazynu_ciepla_w_przemysle: 0,
        odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle: 0,
        odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych: 0,

        transport: {
          ilość_aut_osobowych: 0,
          inne_rodzaje_transportu: 0,
          liczba_pasażerokilometrów: 0,
          udział_kolei_w_transporcie: 0,
          wolumen_transportu_towarów: 0,
          zużycie_energii_aut_osobowych: 0,
          średni_przejeżdżany_dystans_aut_osobowych: 0,
        },
      },
      wodór: {
        cement: 0,
        hutnictwo: 0,
        inne: 0,
        lotnictwo: 0,
        rafinerie: 0,
        wojsko: 0,
        żegluga: 0,
      },
    },
  },
  {
    label: 'SCENARIO_2_label',
    title: 'SCENARIO_2_title',
    description: 'SCENARIO_2',
    short_description: 'SCENARIO_2_short_description',
    data: {
      produkcjaEnergiiElektrycznej: {
        inputs: {
          PV_zainstalowano: 50,
          wiatr_offshore_zainstalowano: 10,
          wiatr_lad_zainstalowano: 30,
          atom_zainstalowano: 0,
          udzial_bifacji_EW_w_PV: 0.2,
          udzial_nowoczesnych_turbin_wiatrowych: 0.6,
        },
      },
      cieplo: {
        obliczaj_CO: false,
        inputs: {
          odsetek_budynkow_z_odzyskiem_ciepla: 0,
          moc_pomp_ciepla: 0,
          zapotrzebowanie_CWU_względem_2023: 0,
          stopien_zastapienia_starych_budynkow_nowymi: 0,
        },
        selectOptions: {
          rodzaj_termomodernizacji: 170,
        },
      },
      obliczenia: {
        baterie_litowe_stacjonarne: 120,
        baterie_samochodowe: 0,
        elektrownie_szczytowo_pompowe: 30,
        mnożnik_zapotrzebowania_KSE: 0.86,
        moc_elektrolizerow: 0,
        odbiorcy_podlaczeni_do_sieci: 0.35,
        odsetek_elektrowni_pracujacych_w_kogeneracji: 0.4,
        odbiorcy_z_magazynami_ciepla_z_siecia_CO: 0.35,
        pojemnosc_magazynu_ciepla: 0,
        wspolczynnik_elastyczności: 0,
        strata_przy_przesyle: 0.05,

        zapotrzebowanie_na_cieplo_w_przemysle: 0,
        pojemnosc_magazynu_ciepla_w_przemysle: 0,
        odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle: 0,
        odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych: 0,

        transport: {
          ilość_aut_osobowych: 0,
          inne_rodzaje_transportu: 0,
          liczba_pasażerokilometrów: 0,
          udział_kolei_w_transporcie: 0,
          wolumen_transportu_towarów: 0,
          zużycie_energii_aut_osobowych: 0,
          średni_przejeżdżany_dystans_aut_osobowych: 0,
        },
      },
      wodór: {
        cement: 0,
        hutnictwo: 0,
        inne: 0,
        lotnictwo: 0,
        rafinerie: 0,
        wojsko: 0,
        żegluga: 0,
      },
    },
  },
  {
    label: 'SCENARIO_3_label',
    title: 'SCENARIO_3_title',
    description: 'SCENARIO_3',
    short_description: 'SCENARIO_3_short_description',
    data: {
      produkcjaEnergiiElektrycznej: {
        inputs: {
          PV_zainstalowano: 50,
          wiatr_offshore_zainstalowano: 10,
          wiatr_lad_zainstalowano: 30,
          atom_zainstalowano: 0,
          udzial_bifacji_EW_w_PV: 0.2,
          udzial_nowoczesnych_turbin_wiatrowych: 0.6,
        },
      },
      cieplo: {
        obliczaj_CO: true,
        inputs: {
          odsetek_budynkow_z_odzyskiem_ciepla: 0,
          moc_pomp_ciepla: 0,
          zapotrzebowanie_CWU_względem_2023: 1,
          stopien_zastapienia_starych_budynkow_nowymi: 0,
        },
        selectOptions: {
          rodzaj_termomodernizacji: 170,
        },
      },
      obliczenia: {
        baterie_litowe_stacjonarne: 120,
        baterie_samochodowe: 0,
        elektrownie_szczytowo_pompowe: 30,
        mnożnik_zapotrzebowania_KSE: 0.86,
        moc_elektrolizerow: 0,
        odbiorcy_podlaczeni_do_sieci: 0.35,
        odsetek_elektrowni_pracujacych_w_kogeneracji: 0.4,
        odbiorcy_z_magazynami_ciepla_z_siecia_CO: 0.35,
        pojemnosc_magazynu_ciepla: 0,
        wspolczynnik_elastyczności: 0,
        strata_przy_przesyle: 0.05,

        zapotrzebowanie_na_cieplo_w_przemysle: 1,
        pojemnosc_magazynu_ciepla_w_przemysle: 0,
        odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle: 0,
        odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych: 0,

        transport: {
          ilość_aut_osobowych: 23,
          zużycie_energii_aut_osobowych: 22,
          średni_przejeżdżany_dystans_aut_osobowych: 10,
          liczba_pasażerokilometrów: 1,
          wolumen_transportu_towarów: 1,
          udział_kolei_w_transporcie: 0.12,
          inne_rodzaje_transportu: 1,
        },
      },
      wodór: {
        cement: 0,
        hutnictwo: 0,
        inne: 0,
        lotnictwo: 0,
        rafinerie: 0,
        wojsko: 0,
        żegluga: 0,
      },
    },
  },
  {
    label: 'SCENARIO_4_label',
    title: 'SCENARIO_4_title',
    description: 'SCENARIO_4',
    short_description: 'SCENARIO_4_short_description',
    data: {
      produkcjaEnergiiElektrycznej: {
        inputs: {
          PV_zainstalowano: 175,
          wiatr_offshore_zainstalowano: 30,
          wiatr_lad_zainstalowano: 100,
          atom_zainstalowano: 10,
          udzial_bifacji_EW_w_PV: 0.25,
          udzial_nowoczesnych_turbin_wiatrowych: 0.8,
        },
      },
      cieplo: {
        obliczaj_CO: true,
        inputs: {
          odsetek_budynkow_z_odzyskiem_ciepla: 0,
          moc_pomp_ciepla: 0,
          zapotrzebowanie_CWU_względem_2023: 1,
          stopien_zastapienia_starych_budynkow_nowymi: 0,
        },
        selectOptions: {
          rodzaj_termomodernizacji: 170,
        },
      },
      obliczenia: {
        baterie_litowe_stacjonarne: 120,
        baterie_samochodowe: 0,
        elektrownie_szczytowo_pompowe: 30,
        mnożnik_zapotrzebowania_KSE: 0.86,
        moc_elektrolizerow: 0,
        odbiorcy_podlaczeni_do_sieci: 0.35,
        odsetek_elektrowni_pracujacych_w_kogeneracji: 0.4,
        odbiorcy_z_magazynami_ciepla_z_siecia_CO: 0.35,
        pojemnosc_magazynu_ciepla: 0,
        wspolczynnik_elastyczności: 0,
        strata_przy_przesyle: 0.05,

        zapotrzebowanie_na_cieplo_w_przemysle: 1,
        pojemnosc_magazynu_ciepla_w_przemysle: 0,
        odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle: 0,
        odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych: 0,

        transport: {
          ilość_aut_osobowych: 23,
          zużycie_energii_aut_osobowych: 22,
          średni_przejeżdżany_dystans_aut_osobowych: 10,
          liczba_pasażerokilometrów: 1,
          wolumen_transportu_towarów: 1,
          udział_kolei_w_transporcie: 0.12,
          inne_rodzaje_transportu: 1,
        },
      },
      wodór: {
        cement: 0,
        hutnictwo: 0,
        inne: 0,
        lotnictwo: 0,
        rafinerie: 0,
        wojsko: 0,
        żegluga: 0,
      },
    },
  },
  {
    label: 'SCENARIO_5_label',
    title: 'SCENARIO_5_title',
    description: 'SCENARIO_5',
    short_description: 'SCENARIO_5_short_description',
    data: {
      produkcjaEnergiiElektrycznej: {
        inputs: {
          PV_zainstalowano: 175,
          wiatr_offshore_zainstalowano: 30,
          wiatr_lad_zainstalowano: 100,
          atom_zainstalowano: 10,
          udzial_bifacji_EW_w_PV: 0.25,
          udzial_nowoczesnych_turbin_wiatrowych: 0.8,
        },
      },
      cieplo: {
        obliczaj_CO: true,
        inputs: {
          odsetek_budynkow_z_odzyskiem_ciepla: 0.67,
          moc_pomp_ciepla: 4,
          zapotrzebowanie_CWU_względem_2023: 0.7,
          stopien_zastapienia_starych_budynkow_nowymi: 0.1,
        },
        selectOptions: {
          rodzaj_termomodernizacji: 102,
        },
      },
      obliczenia: {
        baterie_litowe_stacjonarne: 120,
        baterie_samochodowe: 0,
        elektrownie_szczytowo_pompowe: 30,
        mnożnik_zapotrzebowania_KSE: 0.65,
        moc_elektrolizerow: 0,
        odbiorcy_podlaczeni_do_sieci: 0.35,
        odsetek_elektrowni_pracujacych_w_kogeneracji: 0.4,
        odbiorcy_z_magazynami_ciepla_z_siecia_CO: 0.35,
        pojemnosc_magazynu_ciepla: 0,
        wspolczynnik_elastyczności: 0,
        strata_przy_przesyle: 0.05,

        zapotrzebowanie_na_cieplo_w_przemysle: 0.7,
        pojemnosc_magazynu_ciepla_w_przemysle: 0,
        odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle: 0,
        odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych: 0.1,

        transport: {
          ilość_aut_osobowych: 12,
          zużycie_energii_aut_osobowych: 12,
          średni_przejeżdżany_dystans_aut_osobowych: 8,
          liczba_pasażerokilometrów: 2.5,
          wolumen_transportu_towarów: 1,
          udział_kolei_w_transporcie: 0.6,
          inne_rodzaje_transportu: 0.9,
        },
      },
      wodór: {
        cement: 0,
        hutnictwo: 0,
        inne: 0,
        lotnictwo: 0,
        rafinerie: 0,
        wojsko: 0,
        żegluga: 0,
      },
    },
  },
  {
    label: 'SCENARIO_6_label',
    title: 'SCENARIO_6_title',
    description: 'SCENARIO_6',
    short_description: 'SCENARIO_6_short_description',
    data: {
      produkcjaEnergiiElektrycznej: {
        inputs: {
          PV_zainstalowano: 175,
          wiatr_offshore_zainstalowano: 30,
          wiatr_lad_zainstalowano: 100,
          atom_zainstalowano: 0,
          udzial_bifacji_EW_w_PV: 0.25,
          udzial_nowoczesnych_turbin_wiatrowych: 0.8,
        },
      },
      cieplo: {
        obliczaj_CO: true,
        inputs: {
          odsetek_budynkow_z_odzyskiem_ciepla: 0.67,
          moc_pomp_ciepla: 4,
          zapotrzebowanie_CWU_względem_2023: 0.7,
          stopien_zastapienia_starych_budynkow_nowymi: 0.1,
        },
        selectOptions: {
          rodzaj_termomodernizacji: 102,
        },
      },
      obliczenia: {
        baterie_litowe_stacjonarne: 120,
        baterie_samochodowe: 0,
        elektrownie_szczytowo_pompowe: 30,
        mnożnik_zapotrzebowania_KSE: 0.65,
        moc_elektrolizerow: 0,
        odbiorcy_podlaczeni_do_sieci: 0.35,
        odsetek_elektrowni_pracujacych_w_kogeneracji: 0.4,
        odbiorcy_z_magazynami_ciepla_z_siecia_CO: 0.35,
        pojemnosc_magazynu_ciepla: 0,
        wspolczynnik_elastyczności: 0,
        strata_przy_przesyle: 0.05,

        zapotrzebowanie_na_cieplo_w_przemysle: 0.7,
        pojemnosc_magazynu_ciepla_w_przemysle: 0,
        odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle: 0,
        odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych: 0.1,

        transport: {
          ilość_aut_osobowych: 12,
          zużycie_energii_aut_osobowych: 12,
          średni_przejeżdżany_dystans_aut_osobowych: 8,
          liczba_pasażerokilometrów: 2.5,
          wolumen_transportu_towarów: 1,
          udział_kolei_w_transporcie: 0.6,
          inne_rodzaje_transportu: 0.9,
        },
      },
      wodór: {
        cement: 0,
        hutnictwo: 0,
        inne: 0,
        lotnictwo: 0,
        rafinerie: 0,
        wojsko: 0,
        żegluga: 0,
      },
    },
  },
  {
    label: 'SCENARIO_7A_label',
    title: 'SCENARIO_7A_title',
    description: 'SCENARIO_7A',
    short_description: 'SCENARIO_7A_short_description',
    data: {
      produkcjaEnergiiElektrycznej: {
        inputs: {
          PV_zainstalowano: 80,
          wiatr_offshore_zainstalowano: 30,
          wiatr_lad_zainstalowano: 60,
          atom_zainstalowano: 0,
          udzial_bifacji_EW_w_PV: 0.25,
          udzial_nowoczesnych_turbin_wiatrowych: 0.8,
        },
      },
      cieplo: {
        obliczaj_CO: true,
        inputs: {
          odsetek_budynkow_z_odzyskiem_ciepla: 0.67,
          moc_pomp_ciepla: 4,
          zapotrzebowanie_CWU_względem_2023: 0.7,
          stopien_zastapienia_starych_budynkow_nowymi: 0.1,
        },
        selectOptions: {
          rodzaj_termomodernizacji: 102,
        },
      },
      obliczenia: {
        baterie_litowe_stacjonarne: 80,
        baterie_samochodowe: 50,
        elektrownie_szczytowo_pompowe: 20,
        mnożnik_zapotrzebowania_KSE: 0.65,
        moc_elektrolizerow: 35,
        odbiorcy_podlaczeni_do_sieci: 0.35,
        odsetek_elektrowni_pracujacych_w_kogeneracji: 0.4,
        odbiorcy_z_magazynami_ciepla_z_siecia_CO: 0.65,
        pojemnosc_magazynu_ciepla: 800,
        wspolczynnik_elastyczności: 0.1,
        strata_przy_przesyle: 0.04,

        zapotrzebowanie_na_cieplo_w_przemysle: 0.7,
        pojemnosc_magazynu_ciepla_w_przemysle: 200,
        odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle: 0.6,
        odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych: 0.1,

        transport: {
          ilość_aut_osobowych: 12,
          zużycie_energii_aut_osobowych: 12,
          średni_przejeżdżany_dystans_aut_osobowych: 8,
          liczba_pasażerokilometrów: 2.5,
          wolumen_transportu_towarów: 1,
          udział_kolei_w_transporcie: 0.6,
          inne_rodzaje_transportu: 0.9,
        },
      },
      wodór: {
        rafinerie: 0.45,
        hutnictwo: 0.5,
        żegluga: 0.5,
        lotnictwo: 0.5,
        wojsko: 2,
        cement: 0.8,
        inne: 1,
      },
    },
  },
  {
    label: 'SCENARIO_7B_label',
    title: 'SCENARIO_7B_title',
    description: 'SCENARIO_7B',
    short_description: 'SCENARIO_7B_short_description',
    data: {
      produkcjaEnergiiElektrycznej: {
        inputs: {
          PV_zainstalowano: 0,
          wiatr_offshore_zainstalowano: 0,
          wiatr_lad_zainstalowano: 0,
          atom_zainstalowano: 50,
          udzial_bifacji_EW_w_PV: 0.25,
          udzial_nowoczesnych_turbin_wiatrowych: 0.8,
        },
      },
      cieplo: {
        obliczaj_CO: true,
        inputs: {
          odsetek_budynkow_z_odzyskiem_ciepla: 0.67,
          moc_pomp_ciepla: 4,
          zapotrzebowanie_CWU_względem_2023: 0.7,
          stopien_zastapienia_starych_budynkow_nowymi: 0.1,
        },
        selectOptions: {
          rodzaj_termomodernizacji: 102,
        },
      },
      obliczenia: {
        baterie_litowe_stacjonarne: 0,
        baterie_samochodowe: 0,
        elektrownie_szczytowo_pompowe: 0,
        mnożnik_zapotrzebowania_KSE: 0.65,
        moc_elektrolizerow: 17,
        odbiorcy_podlaczeni_do_sieci: 0.35,
        odsetek_elektrowni_pracujacych_w_kogeneracji: 0.4,
        odbiorcy_z_magazynami_ciepla_z_siecia_CO: 0.35,
        pojemnosc_magazynu_ciepla: 0,
        wspolczynnik_elastyczności: 0,
        strata_przy_przesyle: 0.05,

        zapotrzebowanie_na_cieplo_w_przemysle: 0.7,
        pojemnosc_magazynu_ciepla_w_przemysle: 0,
        odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle: 0.6,
        odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych: 0.1,

        transport: {
          ilość_aut_osobowych: 12,
          zużycie_energii_aut_osobowych: 12,
          średni_przejeżdżany_dystans_aut_osobowych: 8,
          liczba_pasażerokilometrów: 2.5,
          wolumen_transportu_towarów: 1,
          udział_kolei_w_transporcie: 0.6,
          inne_rodzaje_transportu: 0.9,
        },
      },
      wodór: {
        rafinerie: 0.45,
        hutnictwo: 0.5,
        żegluga: 0.5,
        lotnictwo: 0.5,
        wojsko: 2,
        cement: 0.8,
        inne: 1,
      },
    },
  },
  {
    label: 'SCENARIO_7C_label',
    title: 'SCENARIO_7C_title',
    description: 'SCENARIO_7C',
    short_description: 'SCENARIO_7C_short_description',
    data: {
      produkcjaEnergiiElektrycznej: {
        inputs: {
          PV_zainstalowano: 70,
          wiatr_offshore_zainstalowano: 30,
          wiatr_lad_zainstalowano: 55,
          atom_zainstalowano: 3,
          udzial_bifacji_EW_w_PV: 0.25,
          udzial_nowoczesnych_turbin_wiatrowych: 0.8,
        },
      },
      cieplo: {
        obliczaj_CO: true,
        inputs: {
          odsetek_budynkow_z_odzyskiem_ciepla: 0.67,
          moc_pomp_ciepla: 4,
          zapotrzebowanie_CWU_względem_2023: 0.7,
          stopien_zastapienia_starych_budynkow_nowymi: 0.1,
        },
        selectOptions: {
          rodzaj_termomodernizacji: 102,
        },
      },
      obliczenia: {
        baterie_litowe_stacjonarne: 80,
        baterie_samochodowe: 50,
        elektrownie_szczytowo_pompowe: 20,
        mnożnik_zapotrzebowania_KSE: 0.65,
        moc_elektrolizerow: 35,
        odbiorcy_podlaczeni_do_sieci: 0.35,
        odsetek_elektrowni_pracujacych_w_kogeneracji: 0.4,
        odbiorcy_z_magazynami_ciepla_z_siecia_CO: 0.65,
        pojemnosc_magazynu_ciepla: 800,
        wspolczynnik_elastyczności: 0.1,
        strata_przy_przesyle: 0.04,

        zapotrzebowanie_na_cieplo_w_przemysle: 0.7,
        pojemnosc_magazynu_ciepla_w_przemysle: 200,
        odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle: 0.6,
        odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych: 0.1,

        transport: {
          ilość_aut_osobowych: 12,
          zużycie_energii_aut_osobowych: 12,
          średni_przejeżdżany_dystans_aut_osobowych: 8,
          liczba_pasażerokilometrów: 2.5,
          wolumen_transportu_towarów: 1,
          udział_kolei_w_transporcie: 0.6,
          inne_rodzaje_transportu: 0.9,
        },
      },
      wodór: {
        rafinerie: 0.45,
        hutnictwo: 0.5,
        żegluga: 0.5,
        lotnictwo: 0.5,
        wojsko: 2,
        cement: 0.8,
        inne: 1,
      },
    },
  },
];

export const defaultScenario = AVAILABLE_SCENARIOS[0];
