import classNames from 'classnames';
import { SChangeBtn } from 'components/DataInput/styles';
import React, { ChangeEvent, ReactNode, useRef, useState } from 'react';
import { splitByComma } from 'services/utils/splitByComma';
import { EndAdornment, InputCnt, InputMask, SInput } from './styles';
import { ReactTooltip } from 'components/Tooltip/ReactTooltip';
export type InputProps = {
  onChange?: (arg: string) => void;
  error?: boolean;
  id?: string;
  errorMessage?: ReactNode;
  variant?: 'default' | 'table';
  endAdornment?: ReactNode;
  hiddenSpinners?: boolean;
  withInputMask?: boolean;
  oneDimensionMask?: boolean;
  alignChangeBtnToText?: boolean;
} & React.ClassAttributes<HTMLInputElement> &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    css?: any;
  };
export const Input = ({
  type = 'number',
  css,
  onChange,
  min,
  max,
  error = false,
  errorMessage,
  id,
  variant = 'default',
  disabled,
  value,
  endAdornment,
  hiddenSpinners,
  withInputMask,
  oneDimensionMask,
  alignChangeBtnToText = false,
  ...rest
}: InputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  const onAdd = () => {
    const newVal = +(value as string | number) + 1;
    if (max !== undefined && newVal > Number(max)) {
      return;
    }
    onChange?.(newVal.toString());
  };

  const onMinus = () => {
    const newVal = +(value as string | number) - 1;
    if (min !== undefined && newVal < Number(min)) {
      return;
    }
    onChange?.(newVal.toString());
  };

  const onInputMask = () => {
    setIsFocused(true);
    inputRef.current?.focus();
  };

  const { firstVal, secondVal } = splitByComma(value as string, oneDimensionMask);
  return (
    <InputCnt className={classNames('input-cnt')} variant={variant} data-tooltip-id={`${id}-input-tooltip`}>
      {withInputMask && !isFocused && (
        <InputMask onClick={onInputMask} error={error}>
          <div
            className={classNames('firstCol', {
              'firstCol--with-space': !secondVal,
            })}
          >
            {firstVal}
            {!!secondVal && ','}
          </div>
          <div className="secondCol">{secondVal}</div>
        </InputMask>
      )}
      {!hiddenSpinners && (
        <SChangeBtn
          className={classNames('change-btn', {
            'align-to-text': alignChangeBtnToText,
          })}
          hideControls={false}
          disabled={disabled}
          onClick={onMinus}
        >
          -
        </SChangeBtn>
      )}
      <SInput
        id={id}
        error={error}
        type={type}
        variant={variant}
        step="1"
        className={classNames('input', {
          'input--with-mask': withInputMask,
        })}
        disabled={disabled}
        value={value?.toString()}
        {...rest}
        ref={inputRef}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={handleChange as any}
        min={min}
        max={max}
        lang="pl"
      />
      {!hiddenSpinners && (
        <SChangeBtn
          className={classNames('change-btn', {
            'align-to-text': alignChangeBtnToText,
          })}
          hideControls={false}
          disabled={disabled}
          onClick={onAdd}
        >
          +
        </SChangeBtn>
      )}
      {endAdornment && <EndAdornment className="end-adornment">{endAdornment}</EndAdornment>}
      {errorMessage && id && (
        <ReactTooltip place="top" id={`${id}-input-tooltip`}>
          {errorMessage}
        </ReactTooltip>
      )}
    </InputCnt>
  );
};
