export const HYDROGEN_BASIC_NEED_INITIAL_VALUES = {
  lotnictwo: 30 as number,
  rafinerie: 57 as number,
  hutnictwo: 30 as number,
  żegluga: 50 as number,
  wojsko: 5 as number,
  cement: 12 as number,
  inne: 10 as number,
} as const;

export const HYDROGEN_CONSTS_INITIAL_VALUES = {
  energyNeededToProduce1MlnTonOFHydrogen: 43.8 as number,
  electrolysersEfficency: 0.76 as number,
  energy1MlnTonOfHydrogen: 33.3 as number,
  hydrogenStorageEfficency: 0.5 as number,
} as const;
