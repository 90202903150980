import styled from '@emotion/styled';

export const SCnt = styled.div`
  color: ${({ theme }) => theme.dsDark};
  display: flex;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  align-items: center;
`;
export const SPeriod = styled.div`
  display: flex;
`;

export const SChangeWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  padding: 7px;
  min-width: 150px;
  border: 1px solid #dfe1e6;
`;

export const SChangeButton = styled.button`
  width: 18px;
  height: 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:disabled {
    path {
      fill: ${({ theme }) => theme.disabledColor};
    }
  }
`;

export const SPeriodValue = styled.div<{ isMonth?: boolean }>`
  margin: 0 8px;
  text-align: center;
  ${({ isMonth }) => isMonth && 'min-width: 74px;'}
  flex-grow: 1;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
`;

export const Separator = styled.span`
  color: #667085;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0 6px;
`;
