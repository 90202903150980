import { TimeBalance } from 'features/Charts/TimeBalance/TimeBalance';
import React from 'react';
import { DisposableEnergySources } from './DisposableEnergySources/DisposableEnergySources';
import { HydrogenPie } from './GroupedData/Hydrogen/Hydrogen';
import { SPiesCnt } from './styles';
import { GroupedData } from './GroupedData/GroupedData';

export const EnergyPies = () => {
  return (
    <SPiesCnt>
      <DisposableEnergySources />
      <GroupedData />
      {/* <SPie>
        <TimeBalance />
      </SPie> */}
      {/* <HydrogenPie />
      <ConstructionCost /> */}
    </SPiesCnt>
  );
};
