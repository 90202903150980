export const sumArrays = (arr1: number[] = [], arr2: number[]) => {
  const summedArr = [] as number[];
  if (arr1.length === 0) {
    return arr2;
  }
  for (let index = 0; index < arr1.length; index++) {
    summedArr[index] = (arr1[index] ?? 0) + (arr2[index] ?? 0);
  }

  return summedArr;
};
