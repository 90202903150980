import { RootState } from 'services/configuration/store/store';

export const getObliczeniaReducer = (state: RootState) => state.obliczenia;

export const getObliczenia = (state: RootState) => state.obliczenia.obliczenia;
export const getObliczeniaInputs = (state: RootState) => state.obliczenia.obliczenia.inputs;
export const getObliczeniaCalculations = (state: RootState) => state.obliczenia.obliczenia.calculations;
export const getObliczeniaConsts = (state: RootState) => state.obliczenia.obliczenia.consts;

export const getCieplo = (state: RootState) => state.obliczenia.obliczenia.cieplo;
export const getCieploInputs = (state: RootState) => state.obliczenia.obliczenia.cieplo.inputs;
export const getCieploSelectOptions = (state: RootState) => state.obliczenia.obliczenia.cieplo.selectOptions;
export const getCieploConsts = (state: RootState) => state.obliczenia.obliczenia.data;

export const getProdukcjaEnergiiElektrycznej = (state: RootState) => state.obliczenia.produkcjaEnergiiElektrycznej;
export const getProdukcjaEnergiiElektrycznejInputs = (state: RootState) =>
  state.obliczenia.produkcjaEnergiiElektrycznej.inputs;
export const getProdukcjaEnergiiElektrycznejCalculations = (state: RootState) =>
  state.obliczenia.produkcjaEnergiiElektrycznej.calculations;
export const getProdukcjaEnergiiElektrycznejConsts = (state: RootState) =>
  state.obliczenia.produkcjaEnergiiElektrycznej.consts;
export const getProdukcjaEnergiiElektrycznejData = (state: RootState) =>
  state.obliczenia.produkcjaEnergiiElektrycznej.data;

export const getKoszty = (state: RootState) => state.obliczenia.koszty;
export const getKosztyInfrastuktra = (state: RootState) => state.obliczenia.koszty.aktualna_infrastruktura;
export const getKosztyCalculations = (state: RootState) => state.obliczenia.koszty.calculations;

export const getBilansEnergii = (state: RootState) => state.obliczenia.bilansEnergii;
// export const get

export const getWodor = (state: RootState) => state.obliczenia.wodór;

export const getYearsData = (state: RootState) => state.obliczenia.obliczenia.yearsData;
