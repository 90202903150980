import { Button } from 'components/Button/Button';
import { EditorText } from 'components/EditorText';
import keys from 'i18n/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipRenderProps } from 'react-joyride';
import { CloseButton, SCurrStep, TooltipBody, TooltipFooter, TooltipTitle } from './styles';

export const TourTooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  size,
  skipProps,
}: TooltipRenderProps) => {
  const { styles } = step;
  const { t } = useTranslation();
  return (
    <TooltipBody style={styles?.tooltipContent}>
      <div {...tooltipProps}></div>
      <TooltipTitle>
        <span>{t(step.title as string)}</span>
        <SCurrStep>
          {index + 1} / {size}
        </SCurrStep>
      </TooltipTitle>
      <EditorText style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: t(step.content as string) }} />
      <TooltipFooter>
        {index > 0 && (
          <CloseButton {...closeProps} variant="text">
            {t(keys.CLOSE)}
          </CloseButton>
        )}
        {index > 0 && <Button {...backProps}>{t(keys.BACK)}</Button>}
        {continuous && (
          <Button variant="contained" {...primaryProps}>
            {index > 0 ? t(keys.NEXT) : t(keys.START)}
          </Button>
        )}
        {!continuous && <Button {...closeProps}>{t(keys.CLOSE)}</Button>}
      </TooltipFooter>
    </TooltipBody>
  );
};
