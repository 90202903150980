import k from './../../i18n/keys';
import React, { ReactNode } from 'react';
import { SChangeButton, SChangeWrapper, SCnt, SPeriodValue } from './styles';
import { ReactComponent as ArrowLeft } from '../../assets/arrow-left-short.svg';
import { ReactComponent as ArrowRight } from '../../assets/arrow-right-short.svg';
import { useTranslation } from 'react-i18next';
type ControlledPeriodProps = {
  onPrev: (index: number) => void;
  onNext: (index: number) => void;
  value: ReactNode;
  index: number;
  max: number;
  min?: number;
  className?: string;
};

export const ControlledPeriod = ({ onNext, onPrev, value, index, max, min = 0, className }: ControlledPeriodProps) => {
  const handlePrev = () => {
    const prevIndex = index - 1;
    if (prevIndex < min) {
      return;
    }
    onPrev(prevIndex);
  };
  const handleNext = () => {
    const nextIndex = index + 1;
    if (nextIndex >= max) {
      return;
    }
    onNext(nextIndex);
  };
  const { t } = useTranslation();
  return (
    <SCnt className={className}>
      <p>{t(k.OKRES)}</p>
      <SChangeWrapper>
        <SChangeButton onClick={handlePrev}>
          <ArrowLeft />
        </SChangeButton>
        <SPeriodValue>{value}</SPeriodValue>
        <SChangeButton onClick={handleNext}>
          <ArrowRight />
        </SChangeButton>
      </SChangeWrapper>
    </SCnt>
  );
};
