import keys from '../../../../i18n/keys';
import React from 'react';
import { useAppSelector } from 'services/configuration/store/hooks';
import { roundNumb } from 'services/utils/roundNumb';
import { useTranslation } from 'react-i18next';
import { TileTitle } from 'components/Titles/TileTitle';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';
import { URL } from 'services/consts/routes';
import { getKosztyCalculations } from 'services/slices/obliczeniaSlice/selectors';
import { CostText, Text } from '../GroupedData.styles';
import { Box } from 'components/common';
import { SLink } from './ConstructionCost.styles';

export const ConstructionCost = () => {
  const { LCOE, łącznyKosztBudowyInfrastruktury } = useAppSelector(getKosztyCalculations);
  const { t } = useTranslation();
  return (
    <div>
      <Box d="grid" gridCols="150px 1fr" gap="8px">
        <CostText>
          <span>{t(keys.INFRASTRUCTURE_COST)}</span>
        </CostText>
        <CostText>
          {roundNumb(łącznyKosztBudowyInfrastruktury, 0)} {t(keys.CURRENCY_MLD_LABEL)}
        </CostText>
        <CostText>
          <Box>
            <span>{t(keys.LCOE)}</span>
          </Box>
        </CostText>
        <CostText>
          {roundNumb(LCOE, 0)} {t(keys.CURRENCY_ON_MWH)}
        </CostText>
      </Box>
      <Box mt="24px">
        <SLink to={URL.COSTS}>
          <Text>{t(keys.CONSTRUCTION_COST_LINK)}</Text>
        </SLink>
      </Box>
    </div>
  );
};
