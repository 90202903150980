import { EditorText } from 'components/EditorText';
import { Modal } from 'components/Modal/Modal';
import keys from 'i18n/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFooter, SContent, SFooter } from './styles';

import { Button } from 'components/Button/Button';
import { AvialableTexts } from './CookiesRodo';
type CookiesRodoModalContentProps = {
  type: AvialableTexts;
  onClose: () => void;
};
export const CookiesRodoModal = ({ type, onClose }: CookiesRodoModalContentProps) => {
  const { t } = useTranslation();
  return (
    <Modal open={!!type} onClose={onClose}>
      <SContent>
        <SFooter as="div" />
        <EditorText
          dangerouslySetInnerHTML={{
            __html: type === 'rodo' ? t(keys.RODO_TEXT) : t(keys.PRIVACY_POLICY_TEXT),
          }}
        />
      </SContent>
    </Modal>
  );
};
