import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'services/configuration/store/hooks';
import { getWodor } from 'services/slices/obliczeniaSlice/selectors';
import { QueryParamsPayload, ScenarioData } from 'services/slices/obliczeniaSlice/types';
import { updatedDiff } from 'deep-object-diff';

import { addSearchParams, deleteSearchParams } from '../AppParams/utils';
import { wodór } from 'services/slices/obliczeniaSlice/wodor/wodór';
import { useEffect } from 'react';

export const useWodorUpdate = (currentScenario: ScenarioData, searchParams: URLSearchParams) => {
  const { bazowe_zapotrzebowanie, procentowe_zapotrzebowanie } = useAppSelector(getWodor);

  const initialData: QueryParamsPayload['wodor'] = {
    bazowe_zapotrzebowanie: wodór.bazowe_zapotrzebowanie,
    procentowe_zapotrzebowanie: currentScenario.data.wodór,
  };
  const diff = updatedDiff(initialData, { bazowe_zapotrzebowanie, procentowe_zapotrzebowanie });
  deleteSearchParams(searchParams, 'wodor');
  if (Object.keys(diff).length) {
    addSearchParams(searchParams, 'wodor', JSON.stringify(diff));
  }
};
