import { keys } from 'i18n/keys';

export const calcStopnioDzien = (averageDayTemperature: number) => {
  if (averageDayTemperature <= 15) {
    return 18 - averageDayTemperature;
  }
  return 0.01;
};

export const calcSredniaTemp = (data: number[]) => data.reduce((a, b) => a + b, 0) / data.length;

export const TEMPERATURA_CO_CWU = [
  { label: keys.HEAT_RATIOS_OPTION_1, value: 35 },
  { label: keys.HEAT_RATIOS_OPTION_2, value: 45 },
  { label: keys.HEAT_RATIOS_OPTION_3, value: 55 },
] as const;

export const RODZAJE_TERMOMODERNIZACJI = [
  { label: keys.TERMOMODERNIZACJA_BEZ_ZMIAN, value: 170 },
  { label: keys.TERMOMODERNIZACJA_PLYTKA, value: 136 },
  { label: keys.TERMOMODERNIZACJA_GLEBOKA, value: 102 },
] as const;

export const STOPNIE_ZASTAPIENIA_STARYCH_BUD_OPTIONS = [
  { label: '0%', value: 0 },
  { label: '10%', value: 0.1 },
  { label: '20%', value: 0.2 },
  { label: '30%', value: 0.3 },
  { label: '40%', value: 0.4 },
  { label: '50%', value: 0.5 },
] as const;
