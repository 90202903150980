import styled from '@emotion/styled';
import { NavLink } from 'components/Link/NavLink';

export const WithSideBarCnt = styled.div<{ wrapperBg: string }>`
  padding: 8px;
  padding-left: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: ${({ theme }) => theme.simulatorBg};
  min-height: 100vh;

  .wrapper {
    padding: 24px;
    background-color: ${({ wrapperBg }) => wrapperBg};
  }
`;

export const SidebarCnt = styled.div`
  height: 100%;
`;

export const SContent = styled.div`
  position: sticky;
  top: 0;
  padding: 32px 16px;
  min-height: calc(100vh - 16px);
`;

export const ContentFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  height: 100%;
`;

export const STextSelectCnt = styled.span`
  display: flex;
  justify-content: center;
  color: #fff;
  padding-bottom: 16px;

  .select-container {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
`;

export const SidebarBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  .icon {
    padding: 12px;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    color: #aaafb6;
    display: flex;
    justify-content: center;
    border: 1px solid rgba(185, 237, 255, 0.2);

    &.small-pad {
      padding: 6px;
    }
  }

  .text {
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    color: #aaafb6;
    margin-top: 2px;
    max-width: 50px;
  }

  &:hover {
    .icon {
      color: #fff;
      background-color: rgb(7, 67, 96);
    }
    .text {
      color: #fff;
    }
  }

  &.active {
    .icon {
      color: #0e0f11;
      background-color: rgb(185, 237, 255);
    }
    .text {
      color: #fff;
    }
  }
`;

export const SidebarLink = SidebarBtn.withComponent(NavLink);
