import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useAppSelector } from 'services/configuration/store/hooks';
import { getObliczenia } from 'services/slices/obliczeniaSlice/selectors';
import { useChartData } from './useChartData';
import { BulletRow, Cnt, DognoughtChart, LegendCnt, SBullet, SBulletText, SBulltetCnt } from './HeatBalance.styles';
import { Box } from 'components/common';
import { Text } from '../GroupedData.styles';
import keys from 'i18n/keys';
import { useTranslation } from 'react-i18next';
import { useHeatSum } from './useHeatSum';
import { roundNumb } from 'services/utils/roundNumb';
import { usePompaCieplaSCOP } from './usePompaCieplaSCOP';
import { Legend } from 'components/Legend/Legend';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const HeatBalance = () => {
  const { t } = useTranslation();

  const { data, legendOptions, options } = useChartData();
  const { bilans_ciepla, bilans_pradu } = useHeatSum();
  const { pompaCieplaSCOP, moc_z_moca_pomp_ciepla } = usePompaCieplaSCOP();
  return (
    <Cnt>
      <Box pt="16px">
        <Box d="flex" gap="16px" mb="8px">
          <Box d="flex">
            <Text>{t(keys.PRAD)}</Text>
            <Text color="#358F86">&nbsp;[{t(keys.TWH)}]</Text>
            <Text>:&nbsp;&nbsp;</Text>
            <Text>{roundNumb(bilans_pradu / 1000)}</Text>
          </Box>
          <Box d="flex">
            <Text>{t(keys.HEAT)}</Text>
            <Text color="#358F86">&nbsp;[{t(keys.TWH)}]</Text>
            <Text>:&nbsp;&nbsp;</Text>
            <Text>{roundNumb(bilans_ciepla / 1000)}</Text>
          </Box>
        </Box>
        <Text color="#667085">
          {t(keys.HEAT_PUMP_SCOP)} = {pompaCieplaSCOP}; {t(keys.POWER)} [{keys.GWTH}] = {moc_z_moca_pomp_ciepla}
        </Text>
        <LegendCnt>
          <BulletRow>
            {legendOptions
              .filter((el, i) => i < 3)
              .map((el, i) => (
                <SBulltetCnt key={i}>
                  <SBullet background={el.color} borderColor={el.borderColor} />
                  <SBulletText>{el.name}</SBulletText>
                </SBulltetCnt>
              ))}
          </BulletRow>
          <BulletRow>
            {legendOptions
              .filter((el, i) => i > 2)
              .map((el, i) => (
                <SBulltetCnt key={i}>
                  <SBullet background={el.color} borderColor={el.borderColor} />
                  <SBulletText>{el.name}</SBulletText>
                </SBulltetCnt>
              ))}
          </BulletRow>
        </LegendCnt>
      </Box>
      <DognoughtChart>
        <Doughnut
          data={data}
          options={options}
          //@ts-ignore
          plugins={[ChartDataLabels]}
        />
      </DognoughtChart>
    </Cnt>
  );
};
