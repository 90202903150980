export const zuzycie_pradu_2023 = [
  11.617526606, 10.822868777, 10.150886903, 9.779169651, 9.798449544, 9.959669616, 10.132187845, 10.292207469,
  10.590369285, 11.234180734, 11.910500832, 12.529150236, 13.324729302, 13.695229302, 13.963129302, 14.365929302,
  15.380529302, 15.317050769, 15.326904336, 15.156723221, 14.703950814, 13.906105213, 13.026816956, 11.978148718,
  11.188126912, 10.714485712, 10.44559679, 10.442328907, 10.864765245, 11.940517137, 14.886441423, 16.989308045,
  18.144760699, 18.852099563, 18.992706526, 19.151330455, 19.314989015, 19.389669232, 19.126616655, 19.422247063,
  20.216283033, 20.213390357, 19.846453557, 19.506182064, 18.932606862, 17.541234857, 16.000988459, 14.57887017,
  13.571338947, 12.83941947, 12.448940041, 12.423720328, 12.821819699, 13.690495196, 16.061992467, 17.927219197,
  18.717994505, 19.400451598, 19.502710004, 19.662728773, 19.777289258, 19.817687422, 19.66483396, 19.858415597,
  20.684926964, 20.658593355, 20.330099386, 20.012619102, 19.175113316, 17.771568862, 16.149665593, 14.7158196,
  13.533297468, 12.87121408, 12.40777468, 12.331429651, 12.746680047, 13.604250577, 16.172777847, 18.131818887,
  18.887276177, 19.142366255, 18.879194858, 19.010893729, 19.374958815, 19.839525182, 19.770687884, 19.806377604,
  20.4697676, 20.241455386, 19.877120076, 19.570876441, 18.978166819, 17.48173855, 15.851961538, 14.458297267,
  13.356670118, 12.786830093, 12.555070342, 12.650868791, 13.010978258, 13.975330482, 16.459487999, 18.237363951,
  19.317675332, 19.996933031, 20.081717125, 20.43903859, 20.525834351, 20.566979179, 20.311131499, 20.225826562,
  20.713114358, 20.558335253, 20.134817897, 19.723829026, 18.867175247, 17.437217466, 15.861022527, 14.447243095,
  13.20936745, 12.438162877, 11.843745589, 11.590300909, 11.480627663, 11.480967723, 11.757206303, 12.050451723,
  12.801993876, 13.974720473, 14.842554883, 15.412720254, 15.64366793, 16.070055319, 16.045574203, 15.958527331,
  16.433136594, 16.280188819, 16.090844213, 15.789523492, 15.166961064, 14.32286995, 13.4813229, 12.352612933,
  11.41184766, 10.751569049, 10.387160564, 10.178663398, 10.44046499, 10.952098484, 12.174616156, 13.07411951,
  14.19442127, 15.36519854, 15.927537076, 16.399364603, 16.585562356, 16.87007116, 16.751939639, 16.794592041,
  17.42637485, 17.509788928, 17.184008896, 16.845790732, 16.167439549, 15.112468784, 14.047492903, 12.701357697,
  11.6147573, 10.846136716, 10.37355519, 10.2153175, 10.304591631, 10.461529879, 10.955961288, 11.432845819,
  12.143772702, 13.348918297, 14.080517849, 14.766851446, 15.124833464, 15.457745008, 15.330959219, 15.31400326,
  15.946907038, 16.150723068, 16.160228916, 16.135219531, 15.521367556, 14.560788127, 13.629654418, 12.426270526,
  11.566051592, 11.108323556, 10.868560519, 10.992755698, 11.374591371, 12.736269022, 15.989505044, 18.20652722,
  19.270400114, 20.057191642, 20.232333657, 20.792877033, 20.965382425, 21.250559243, 20.797735279, 20.591391948,
  20.933659562, 20.83718621, 20.378399794, 20.225614941, 19.553107119, 17.954534925, 16.312001973, 14.849861235,
  13.812959456, 13.176780173, 12.748128148, 12.864133204, 13.116629953, 14.12455839, 16.884477578, 18.933430665,
  19.882042307, 20.544763332, 20.630767499, 21.116152096, 21.339433983, 21.463093611, 21.044304704, 20.863143745,
  21.327999929, 21.204409449, 20.766330928, 20.588962451, 19.899821425, 18.267017561, 16.615560106, 15.165252334,
  13.989221508, 13.186673211, 12.832982098, 12.93249694, 13.338945145, 14.30131238, 16.964741921, 18.935798183,
  19.812680744, 20.192907446, 20.196423372, 20.275087303, 20.26902507, 20.499683523, 20.520040449, 20.603667322,
  21.164764871, 21.199973267, 20.71086374, 20.49697474, 19.933926066, 18.398451983, 16.702652721, 15.329222547,
  14.100804267, 13.454306943, 13.078965687, 13.206755722, 13.644516476, 14.590723772, 17.255269592, 19.13698127,
  19.981405727, 20.278369589, 19.951317812, 20.223132402, 20.31352475, 20.455205808, 20.262904092, 20.418536388,
  21.060197561, 21.271898253, 20.89910948, 20.73141774, 20.124013681, 18.523544839, 16.886322226, 15.3597808,
  14.188787931, 13.56942434, 13.324836351, 13.24420215, 13.690224643, 14.615636317, 17.235548506, 19.217529651,
  20.087391572, 20.544648379, 20.628354542, 20.939727405, 21.28130089, 21.272561103, 20.960719352, 20.919068606,
  21.230110242, 21.258450453, 20.820411126, 20.613752565, 19.857274902, 18.196723078, 16.726880547, 15.386148556,
  14.176353605, 13.521363781, 13.125510117, 12.951639331, 12.940504988, 13.114932531, 14.000932642, 14.881455797,
  15.99017428, 17.122961912, 17.720831473, 17.905436079, 17.994179617, 18.008934801, 17.651860893, 17.433682696,
  18.107966431, 18.288420328, 18.053296941, 17.561499958, 16.84386819, 15.641817354, 14.636479968, 13.281194467,
  11.996938952, 10.969354002, 10.507498266, 10.248998026, 10.337476865, 10.642321003, 11.328996997, 11.906109544,
  12.814080678, 14.119316262, 15.097466371, 15.788583712, 16.285174497, 16.618717324, 16.218589406, 15.833253369,
  16.213591565, 16.536845516, 16.583255898, 16.47514748, 15.961924091, 14.966498175, 14.011691175, 12.950454217,
  12.012705294, 11.556346148, 11.344779181, 11.340801428, 11.804079048, 12.958243904, 15.969609365, 18.240702976,
  19.347055253, 20.074315673, 20.185154317, 20.329801693, 20.294915988, 20.452804941, 20.130734639, 20.05283751,
  20.563298441, 20.845338552, 20.389973814, 20.099155116, 19.343817755, 17.809777414, 16.224219963, 14.718193339,
  13.749903381, 13.000651052, 12.753990617, 12.670834425, 12.976664558, 13.982437842, 16.540916253, 18.424259548,
  19.489509018, 20.09162452, 20.290626545, 20.406491904, 20.43554149, 20.290913594, 20.035193675, 20.144905752,
  20.706029762, 21.095722628, 20.779518223, 20.567504138, 19.706645069, 18.042132873, 16.429455467, 14.986689876,
  13.812570842, 13.158185097, 12.762941234, 12.561133509, 12.85585179, 13.855965013, 16.625439956, 18.687588188,
  19.752924909, 20.352604967, 20.638989037, 21.209437554, 21.423617864, 21.515677389, 21.039785677, 20.839150065,
  21.206160416, 21.303775086, 20.830019312, 20.410548658, 19.50315274, 17.971934973, 16.399566046, 14.869621333,
  13.715007475, 13.003289992, 12.64420775, 12.556897274, 13.026275183, 13.910389051, 16.636663704, 18.621533271,
  19.83908978, 20.458960363, 20.568412875, 20.999984608, 21.195667078, 21.430301524, 21.054808446, 20.90840416,
  21.252989082, 21.278141379, 20.770365596, 20.454348778, 19.695099687, 18.138607782, 16.468670996, 15.018204244,
  13.775685744, 13.132873588, 12.737583273, 12.70308391, 13.043769739, 14.024388005, 16.790170978, 18.694663939,
  19.887380661, 20.608748395, 20.965441259, 21.485395017, 21.635633856, 21.756899818, 21.247306257, 21.030958735,
  21.247730715, 21.232244891, 20.647251215, 20.208610766, 19.318017691, 17.81095191, 16.104516576, 14.696025682,
  13.437950549, 12.656514873, 12.255316368, 12.080035395, 12.210898073, 12.396179168, 13.468056026, 14.396648451,
  15.704719953, 17.00109768, 17.726906969, 18.243512673, 18.468099172, 18.516350736, 18.086533246, 17.777772934,
  18.182823261, 18.304330558, 17.880507019, 17.571516044, 16.763247643, 15.60697208, 14.457571617, 13.147962302,
  11.990212145, 11.272596378, 10.853314277, 10.637877006, 10.749327021, 10.951281662, 11.584022599, 12.013117335,
  13.165349756, 14.460768707, 15.526002183, 16.168780811, 16.413803785, 16.591510975, 16.212126494, 15.718971415,
  16.050537513, 16.555161033, 16.496857115, 16.469118814, 15.866207249, 14.938019853, 14.083935408, 13.018946807,
  12.003737136, 11.562201574, 11.288347245, 11.280611153, 11.843596652, 13.117253914, 16.240954751, 18.433399378,
  19.722783469, 20.694885689, 20.939324298, 21.529227157, 21.799548583, 21.956620031, 21.426401145, 21.074961219,
  21.297393849, 21.357490422, 20.938224029, 20.653997792, 19.925684204, 18.220392504, 16.567362847, 15.038798269,
  13.737690098, 13.159093244, 12.892662326, 12.854385379, 13.1455118, 14.14533894, 16.996042962, 18.951440988,
  20.147062365, 20.815288802, 21.150911717, 21.640000976, 21.914918572, 22.042320049, 21.585431731, 21.240022517,
  21.410411104, 21.544420615, 21.120543201, 20.91641568, 20.085880134, 18.306014387, 16.592879696, 15.075896102,
  13.813701359, 13.112405116, 12.697085478, 12.660890572, 13.066535866, 14.078945432, 16.797743566, 18.768437625,
  19.962072959, 20.735171768, 20.94765499, 21.423190863, 21.705101844, 21.757875838, 21.299911402, 21.046081286,
  21.276535791, 21.403422085, 20.939003787, 20.628236037, 19.74997447, 18.222157385, 16.524512422, 15.028051422,
  13.764011531, 13.14179204, 12.694564984, 12.702512291, 13.114156739, 14.068526378, 16.797972936, 18.866732573,
  19.934007318, 20.718076659, 20.946686432, 21.461507235, 21.598793265, 21.731936964, 21.316217498, 21.00667538,
  21.206987216, 21.396781899, 20.93065671, 20.719848854, 19.919741193, 18.267791736, 16.592388476, 15.044143353,
  13.87943354, 13.151335616, 12.797929312, 12.767636831, 13.129412031, 14.096179418, 16.73767608, 18.79093265,
  19.878069659, 20.486567205, 20.685354113, 21.252658558, 21.433801488, 21.499568237, 21.097339241, 20.846768845,
  20.982124733, 21.168890135, 20.655868436, 20.286542089, 19.34336108, 17.840097474, 16.275465288, 14.760268057,
  13.548231885, 12.773699771, 12.298843151, 12.188386341, 12.159221398, 12.421754209, 13.378107405, 14.233169953,
  15.742372966, 17.215951966, 17.952992958, 18.402554249, 18.420244058, 18.514267918, 18.140226425, 17.657585435,
  17.762566078, 18.155215584, 17.837772976, 17.315985153, 16.556752411, 15.314893644, 14.22433372, 12.88877802,
  11.757733536, 10.986278221, 10.557853536, 10.243682832, 10.350611932, 10.508555816, 11.145205171, 11.609732352,
  12.88732583, 14.222512683, 15.178315806, 15.701086953, 15.859094901, 16.14938298, 16.045530477, 15.79629819,
  16.144965328, 16.760742379, 16.650128492, 16.431324458, 15.813985435, 14.757207867, 13.843455973, 12.604844156,
  11.639534501, 11.071413855, 10.803791879, 10.90830788, 11.384294305, 12.738945688, 15.822387708, 18.02007983,
  19.508975674, 20.328297462, 20.675469917, 21.320505631, 21.717174196, 21.811318049, 21.215748024, 20.814943418,
  20.847633807, 21.204978997, 20.711460589, 20.439593226, 19.606085723, 18.005741974, 16.387352238, 14.87883647,
  13.805044759, 13.046173925, 12.729667094, 12.745338889, 13.268090513, 14.280744546, 17.009865592, 18.6684499,
  19.815008769, 20.393553732, 20.559581417, 20.873053377, 21.296806891, 21.273270343, 20.947981866, 20.69719133,
  20.776516679, 21.243902623, 20.929904811, 20.709703672, 19.911373631, 18.294792857, 16.704989535, 15.161982193,
  14.104215956, 13.348197555, 12.941464344, 12.824140529, 13.33367771, 14.352425362, 16.937228541, 18.750838017,
  19.795510126, 20.439407032, 20.471748001, 20.924059432, 21.223661791, 21.258035876, 20.863918488, 20.527526517,
  20.658038495, 21.130593434, 20.811419672, 20.613293703, 19.74995054, 18.152977044, 16.568481217, 15.04024296,
  13.941297298, 13.286474486, 12.869575507, 12.934171347, 13.353566991, 14.223276855, 16.837930069, 18.541244889,
  19.677503094, 20.278437729, 20.451236407, 20.99805822, 21.147151424, 21.460289725, 20.917924271, 20.487620456,
  20.609714088, 21.065229246, 20.65206744, 20.419066937, 19.656303405, 18.175163644, 16.614936484, 15.188128145,
  13.927305289, 13.233701822, 12.87845856, 12.957460112, 13.266489101, 14.196712608, 16.708360382, 18.496436855,
  19.537149501, 20.164667353, 20.460711033, 20.915920183, 21.110082152, 21.363757546, 21.056209549, 20.718706726,
  20.616770338, 20.934438633, 20.401636849, 19.872103409, 18.989096022, 17.466012723, 15.979075726, 14.638989388,
  13.371679308, 12.599730928, 12.138557886, 12.042403771, 12.272302589, 12.410725243, 13.253131261, 14.102315727,
  15.713781145, 16.903844728, 17.320328853, 17.723976968, 17.814675424, 17.866943576, 17.541400452, 17.153942342,
  17.337209143, 17.935754647, 17.590109118, 17.111430398, 16.267864282, 14.95019786, 13.796604043, 12.37829075,
  11.262615375, 10.598391433, 10.144940652, 9.988090869, 10.057518538, 10.287795724, 10.595815586, 11.166643935,
  12.4234819, 13.543402777, 14.271460225, 14.794656441, 15.11394411, 15.240016458, 14.931478654, 14.728840556,
  14.969295366, 15.863571755, 16.056699553, 15.894198867, 15.361577801, 14.338786435, 13.505087719, 12.362168558,
  11.445408041, 11.000055904, 10.721472866, 10.692923188, 11.124404284, 12.339946352, 15.517998211, 17.774236446,
  19.148322886, 19.855264667, 20.000067296, 19.843115138, 19.866601914, 19.952909098, 19.638061541, 19.5718557,
  19.852037336, 20.807010791, 20.504017081, 20.244039532, 19.569620782, 17.84363726, 16.197290238, 14.648835275,
  13.575765737, 12.906438728, 12.543987888, 12.52192727, 12.83904858, 13.854303843, 16.453418986, 18.489272722,
  19.820213649, 20.382218481, 20.575181443, 20.887969883, 21.073720245, 21.244119245, 20.612397204, 20.446874504,
  20.412036279, 21.14485145, 20.797769069, 20.582099097, 19.735009344, 18.130032351, 16.38018887, 14.790247376,
  13.522683312, 12.890214867, 12.460870981, 12.505394311, 12.916944912, 13.872951124, 16.550834009, 18.523388593,
  19.8116943, 20.20960763, 20.037677923, 20.196622718, 20.231088881, 20.366659562, 20.010079429, 19.846086786,
  20.191382051, 20.908148589, 20.807557721, 20.49900953, 19.609635696, 18.02115312, 16.242820876, 14.777917069,
  13.725397914, 12.981751765, 12.555201412, 12.59959669, 12.933996828, 13.810814759, 16.460439368, 18.164478781,
  19.226305709, 19.492764631, 18.945635843, 18.885644075, 18.81638598, 19.123751455, 18.913062517, 18.986937988,
  19.393622611, 20.375851246, 20.234236534, 20.000347481, 19.172170383, 17.51325406, 16.002252055, 14.637584493,
  13.510683639, 12.963818559, 12.697403278, 12.711815244, 13.18856745, 14.331406437, 16.634622587, 18.369402386,
  19.258892539, 19.703627845, 19.83111398, 20.329203508, 20.528794495, 20.796823408, 20.404715921, 20.453634354,
  20.460513144, 21.117663262, 20.645731703, 20.330427048, 19.502844504, 17.914433374, 16.37126035, 15.000482471,
  13.926586309, 13.253296193, 12.896869444, 12.911962335, 12.847851268, 13.068727465, 14.009327963, 14.889630261,
  16.332530165, 17.450756476, 18.089317399, 18.479822531, 18.812666774, 19.007566927, 18.632682009, 18.220653754,
  18.061767585, 18.584867787, 18.342552798, 17.785799006, 16.918683206, 15.651883933, 14.580503861, 13.30761838,
  12.367901426, 11.670249621, 11.328293184, 11.049229824, 11.176979486, 11.348519422, 11.791852674, 12.458552795,
  13.539918674, 14.437655018, 14.699081821, 14.85137089, 15.078895365, 15.243838795, 15.026259817, 15.005305327,
  15.40312081, 16.526424013, 16.807597608, 16.778663657, 16.221423939, 15.189658595, 14.251084746, 13.12533609,
  12.31881647, 11.910683667, 11.616682545, 11.55492908, 12.18865705, 13.371250808, 16.316948293, 18.449944077,
  19.772615347, 20.374178416, 20.590674976, 21.292886227, 21.323815261, 21.491518677, 20.925692871, 20.757804698,
  20.558173962, 21.153627888, 20.888449442, 20.690067794, 19.998610016, 18.379192344, 16.634190521, 15.159141121,
  13.978771508, 13.329636237, 12.947559168, 12.963678606, 13.333787475, 14.44032298, 16.960623517, 18.950341792,
  20.126345561, 20.606520806, 20.623231761, 20.901488734, 21.14051417, 21.170739306, 20.70616138, 20.45336518,
  20.371112199, 21.035454405, 20.960882783, 20.717908809, 19.874348434, 18.31783802, 16.736323382, 15.314025916,
  14.242435755, 13.538276791, 13.164959887, 13.178427706, 13.424018631, 14.407231444, 16.836069801, 18.693961569,
  19.762039197, 20.099598804, 19.792743168, 19.721158032, 19.70345843, 19.601051888, 19.418030776, 19.564969528,
  19.930108566, 20.76082935, 20.764647139, 20.488529471, 19.711320135, 18.197107677, 16.590787901, 15.109230559,
  14.121603847, 13.407237734, 13.173356399, 13.056636867, 13.391262657, 14.289935523, 16.864919856, 18.439960096,
  19.3250528, 19.407387176, 19.066606266, 19.220536487, 19.256771805, 19.429319227, 19.461031036, 19.47062384,
  19.652016563, 20.431824721, 20.435378842, 20.183282222, 19.422993926, 17.878380544, 16.250738608, 15.029698036,
  14.07990814, 13.522074813, 13.214633379, 13.313298681, 13.698663811, 14.729362778, 17.04679383, 18.782131197,
  19.907696891, 20.478999904, 20.524892776, 21.227495422, 21.471379254, 21.544875359, 21.286937481, 20.807378841,
  20.784598334, 21.196296546, 20.839849639, 20.383041922, 19.551427543, 17.824849635, 16.234705067, 14.866804,
  13.746768261, 12.969588434, 12.555376993, 12.538400244, 12.744558451, 13.05049747, 13.675617095, 14.652859891,
  15.966758586, 17.201405736, 17.578751306, 17.89848272, 18.118883603, 18.211685524, 17.820961097, 17.816732905,
  17.910372855, 18.496668856, 18.336051791, 17.879973791, 17.06824914, 15.635178507, 14.501189185, 13.253837737,
  12.205469032, 11.493902137, 11.007504507, 10.718762958, 10.874329458, 11.038883755, 11.241960993, 11.963495547,
  13.068123928, 14.124771015, 14.836380763, 15.091295664, 15.0636751, 15.247239901, 14.984361154, 14.729845763,
  14.770429762, 15.812655717, 16.214576993, 16.293200712, 15.835652003, 14.940900209, 14.153446678, 13.06333311,
  12.167121508, 11.501028618, 11.322520033, 11.401605579, 11.866843661, 13.167046026, 16.027899169, 17.991039927,
  19.280615463, 19.867291493, 20.228612577, 20.413315249, 20.581534698, 20.547328576, 20.140966791, 20.003798641,
  20.052172087, 20.531753845, 20.439592544, 20.212786157, 19.479084613, 17.863515614, 16.340979917, 14.951871082,
  13.94681679, 13.374921871, 13.093554764, 13.149489971, 13.656570573, 14.610316723, 17.045478717, 18.780059325,
  19.951325332, 20.382865526, 20.480746564, 20.596019688, 20.726875755, 20.883943983, 20.500375966, 20.309933821,
  20.529694942, 21.067245632, 21.216995747, 20.971647121, 20.162918853, 18.507442613, 16.840754409, 15.453324839,
  14.340369645, 13.477093511, 13.075833936, 13.139790388, 13.462798222, 14.494303742, 16.761905388, 18.500865798,
  19.396340671, 19.723051176, 19.640712583, 19.930373948, 19.981108418, 20.048865421, 19.746522807, 19.637664418,
  19.659018751, 20.280280257, 20.458800043, 20.271254231, 19.539169819, 17.930597025, 16.323077348, 14.800367227,
  13.808470422, 13.192942012, 12.873922774, 12.852095868, 13.261389789, 14.170067531, 16.452147316, 18.183947467,
  19.141689197, 19.191722949, 18.77353183, 18.912210596, 19.080706623, 19.450434897, 19.520598565, 19.495216347,
  19.596527105, 20.340303766, 20.454388828, 20.17436499, 19.458645447, 17.96377649, 16.486253932, 15.020063934,
  13.927000528, 13.360062804, 12.883901639, 12.892703684, 13.324011167, 14.280791832, 16.448924017, 18.384973525,
  19.656332685, 20.262227554, 20.444091822, 20.963006063, 20.829744259, 20.907003156, 20.662295194, 20.202536479,
  20.21178148, 20.530049661, 20.560152265, 20.255110132, 19.274924449, 17.703072417, 16.23731236, 14.907547038,
  13.749291664, 12.984798407, 12.484442463, 12.433180144, 12.614503875, 12.771065547, 13.300322891, 14.388003708,
  15.762324305, 16.809380547, 17.390517827, 17.616330892, 17.74378203, 17.698504867, 17.238522297, 16.711964749,
  16.840333427, 17.551872018, 17.732809405, 17.311921916, 16.553985974, 15.317902687, 13.995038342, 12.799838094,
  11.597716154, 10.912905733, 10.433183898, 10.259509969, 10.278245163, 10.423902377, 10.63188997, 11.497694912,
  12.827490683, 13.986335953, 14.501866611, 14.91092733, 15.337225629, 15.466507338, 15.264808415, 14.811401212,
  14.70652187, 15.407247086, 16.090421572, 16.114890692, 15.431115965, 14.424412134, 13.487106634, 12.323334871,
  11.493529308, 10.994986974, 10.749327222, 10.766093167, 11.229515967, 12.536167239, 15.456766479, 17.818732894,
  19.210960896, 19.487179381, 19.492242281, 19.815942635, 20.036630728, 20.219990889, 20.010110707, 19.72539009,
  19.620036207, 20.208914761, 20.497797338, 20.252157324, 19.424490631, 17.777149079, 16.087537144, 14.536136125,
  13.373164989, 12.780486419, 12.439813293, 12.466595285, 12.911479017, 13.947071838, 16.302369438, 18.286339692,
  19.142744066, 19.153307745, 18.4583358, 18.413566264, 18.442688124, 18.620104427, 18.571905853, 18.769027843,
  19.099737623, 19.80809587, 20.448005252, 20.367807655, 19.599565472, 17.952672923, 16.12590467, 14.634069985,
  13.657683522, 13.132636898, 12.782157636, 12.867955653, 13.232580795, 14.29569771, 16.767567477, 18.58531833,
  19.41257816, 19.152373441, 18.580338123, 18.748322718, 18.843834198, 18.887767969, 18.776235756, 19.086370611,
  19.409515613, 20.054501061, 20.644576, 20.574548212, 19.751959827, 18.056536476, 16.361110006, 14.818027198,
  13.741501636, 13.20253479, 12.935169702, 12.958987808, 13.374240141, 14.454641361, 16.825363782, 18.71656728,
  19.595641385, 19.690867719, 19.323099386, 19.565249906, 19.627673817, 19.76645658, 19.513784092, 19.593983108,
  19.74555057, 20.184092396, 20.70751852, 20.501497058, 19.760343467, 18.048281425, 16.325474441, 14.887689891,
  13.955102231, 13.256182993, 12.975621416, 12.928204305, 13.398912495, 14.298016485, 16.524623727, 18.559917894,
  19.668070449, 20.307185775, 20.035978455, 20.336425953, 20.374678033, 20.472827651, 20.176474197, 20.092596032,
  19.992578194, 20.292182682, 20.507820352, 20.162984245, 19.390676806, 17.608123539, 16.077626479, 14.699571857,
  13.47999946, 12.850198281, 12.359475303, 12.302307372, 12.33378218, 12.541904068, 13.193392822, 14.427805982,
  16.105006632, 17.156881773, 17.565706527, 18.114447162, 18.115987324, 18.172110802, 17.795298904, 17.403576304,
  17.21930153, 17.337582079, 17.805306703, 17.424439534, 16.709643707, 15.400313675, 14.195958956, 12.817496618,
  11.505207016, 10.904330765, 10.415186948, 10.217809277, 10.366806564, 10.583854461, 10.845040416, 11.75600717,
  13.020879613, 13.937739778, 14.469750819, 15.05727402, 15.120509383, 15.496614313, 15.359180854, 15.127619934,
  14.979604152, 15.501720487, 16.357615266, 16.395488854, 15.768621068, 14.77536936, 13.828393742, 12.57607449,
  11.813682578, 11.284046174, 11.004386304, 11.124018646, 11.576428306, 12.808517769, 15.609914825, 18.021629911,
  19.490791787, 19.758771022, 19.368987844, 19.504695444, 19.623317964, 19.876752238, 19.946975875, 19.868034561,
  19.769491012, 20.042539712, 20.599016124, 20.373711292, 19.600675835, 17.913157622, 16.171028453, 14.613216051,
  13.514900549, 12.881067881, 12.604810513, 12.682687886, 13.088347157, 14.16269619, 16.666526767, 18.661671061,
  19.473865187, 19.655325849, 19.335085248, 19.547276161, 19.734299475, 20.116711704, 19.959756946, 19.883680123,
  19.748013752, 19.973874547, 20.36792136, 20.222280978, 19.508870537, 17.970255349, 16.153891963, 14.770167849,
  13.731792935, 13.024653511, 12.67097823, 12.651880197, 13.05587822, 14.139424764, 16.476422344, 18.49296189,
  19.351908136, 19.539590019, 19.378543723, 19.511203138, 19.845123074, 20.02897252, 19.856549769, 19.94223438,
  20.035008508, 20.329396626, 20.727426272, 20.565261394, 19.849381941, 18.261993516, 16.589562617, 15.071985426,
  13.991443303, 13.234721173, 12.869793009, 12.893758069, 13.145213904, 14.033484439, 16.272886988, 18.351831231,
  19.531333855, 19.974713812, 20.071620612, 20.280492463, 20.549778748, 21.064617592, 20.706043212, 20.40299367,
  20.223056399, 20.51225714, 20.92696335, 20.89091861, 20.212845751, 18.572464503, 16.82452805, 15.3992914,
  14.341365463, 13.761631225, 13.550797317, 13.460668217, 13.986191808, 14.850057904, 17.009564198, 18.81677338,
  19.779028999, 19.90588353, 19.750335316, 20.037421796, 20.125760579, 20.173776069, 19.876371438, 19.979748431,
  19.815072346, 19.939509154, 20.474913736, 20.278859048, 19.380817068, 17.75021256, 16.275733459, 14.953096151,
  13.70291198, 12.883778032, 12.498755399, 12.370016762, 12.483520365, 12.516470475, 13.093451808, 14.43633386,
  15.913282511, 17.077548048, 17.595636317, 18.004563996, 18.127936159, 18.151546524, 17.750254561, 17.426333505,
  17.24322631, 17.377433789, 17.854522716, 17.586055703, 16.8075168, 15.46811694, 14.345825077, 13.079233295,
  12.007559271, 11.357309316, 10.829199482, 10.637586208, 10.592293073, 10.671013217, 10.91785456, 11.909627772,
  13.028066717, 13.436900967, 13.497785574, 13.674185605, 13.509948387, 13.946281219, 14.048398041, 13.998197484,
  14.383755275, 15.120764772, 16.14493318, 16.425860056, 15.854695674, 14.899183768, 14.176749856, 13.051602437,
  12.162602528, 11.674331739, 11.490564744, 11.593801022, 12.078937808, 13.165478611, 15.975104763, 18.156983162,
  19.177777532, 19.24961682, 19.105317909, 19.471160957, 20.15636011, 20.569508265, 20.099942995, 19.931865615,
  19.772701281, 19.903967745, 20.611309409, 20.61150618, 19.930284972, 18.369167653, 16.7450966, 15.339537326,
  14.307049804, 13.738519986, 13.432839311, 13.589574347, 14.214377063, 15.023930986, 17.279017769, 19.102972464,
  19.834943293, 19.846961599, 19.848774742, 20.31104909, 20.907097356, 20.975269146, 20.621806169, 20.541339845,
  20.429647802, 20.648236989, 21.294727074, 21.183155962, 20.530248511, 18.747099544, 16.833236488, 15.433444304,
  14.413137901, 13.829574847, 13.466263235, 13.338997465, 13.728515922, 14.444664278, 16.771111548, 18.582030633,
  19.459846576, 19.565036259, 18.991348342, 19.267802982, 19.429213868, 19.795965901, 19.362532471, 19.357327859,
  19.235179994, 19.611781444, 20.44071332, 20.487347201, 19.726851849, 17.852740903, 16.192758528, 14.879111367,
  13.872449243, 13.259391924, 12.822560758, 12.865224451, 13.202613182, 13.88445157, 16.173675929, 17.984383548,
  18.51525227, 18.41434945, 18.142685969, 18.411364277, 18.467555815, 18.953579087, 18.621425079, 18.884270987,
  19.043750875, 19.216722642, 20.287747049, 20.440742828, 19.814103531, 18.116170375, 16.407114673, 14.98069276,
  13.917028045, 13.352071388, 13.073916047, 13.079697334, 13.453211735, 14.298138307, 16.408138372, 18.299968482,
  18.854833761, 18.667892007, 18.320608902, 18.656361769, 18.798429771, 18.928108866, 18.87567702, 19.022133654,
  19.057808515, 19.220019721, 20.042266726, 20.14752122, 19.338749095, 17.755353946, 16.103790821, 14.871307578,
  13.833812831, 13.214774055, 12.948076588, 12.831195561, 12.962307446, 13.076046251, 13.663012434, 15.13851356,
  16.080660017, 16.521625192, 16.641722169, 16.72094903, 16.768047759, 16.723462699, 16.432963097, 16.428576886,
  16.574886337, 16.81560879, 17.7123432, 17.724986026, 16.916088861, 15.724587068, 14.605637466, 13.347063038,
  12.404146521, 11.737988084, 11.468612577, 11.39168079, 11.547810402, 11.603881803, 11.89837926, 12.944452649,
  13.837979997, 14.472397147, 14.770403127, 14.940398775, 15.06560768, 15.139230304, 15.028788111, 14.816092092,
  14.923309718, 15.405106191, 16.670760854, 16.98976056, 16.423240078, 15.354546304, 14.389411579, 13.322831537,
  12.571737106, 12.191330224, 12.024670553, 12.037544789, 12.546263323, 13.430792007, 16.271612937, 18.433002302,
  19.503308542, 19.523550055, 19.490193692, 19.627023689, 19.813101548, 20.036252461, 19.942117259, 19.830869975,
  19.61339046, 19.707027643, 20.498989914, 20.624312143, 19.806717243, 18.201447068, 16.58994646, 15.202344864,
  14.163074798, 13.554281986, 13.285213662, 13.319342658, 13.736976649, 14.307428752, 16.771170563, 18.889811214,
  20.00854056, 20.004809419, 19.932660264, 20.40576853, 20.549143721, 20.697691194, 20.3504024, 20.039731396,
  19.981225467, 20.131309909, 20.814342519, 21.086945393, 20.238339855, 18.678766315, 17.049228427, 15.511359531,
  14.455252056, 13.949362907, 13.625390684, 13.566056503, 14.025088632, 14.733440381, 17.214735692, 19.115531879,
  19.796284182, 19.77878262, 19.419223348, 19.43062482, 19.538089524, 19.907302232, 19.627105491, 19.637230666,
  19.752876743, 19.902336524, 20.819555057, 20.983432523, 20.294600657, 18.592656266, 16.904391137, 15.656414455,
  14.616404984, 14.052780368, 13.852473117, 13.837460296, 14.090250673, 14.69722777, 17.029646767, 19.065764729,
  19.85513132, 19.755456245, 19.636665028, 19.896506716, 20.211971247, 20.201675312, 20.483929302, 20.584629302,
  20.546629302, 20.299341626, 20.913432855, 21.209448241, 20.296682606, 18.735035618, 17.0958287, 15.741078122,
  14.842704338, 14.321812594, 13.980604829, 13.942487647, 14.333240316, 14.915994504, 17.129305759, 19.136553251,
  19.867402178, 19.939551942, 19.870937587, 20.245752519, 20.271702889, 20.482029302, 20.354729302, 20.355679302,
  20.021580581, 19.990606965, 20.553692282, 20.689845653, 20.028681253, 18.2171562, 16.627751233, 15.372277588,
  14.224490778, 13.631434695, 13.178976154, 13.013885071, 13.174043622, 12.995272305, 13.67176573, 14.892837883,
  16.146422057, 16.691755991, 16.921823016, 17.013266068, 17.012329223, 16.983613136, 16.946363584, 16.420847731,
  16.54816722, 16.642626164, 17.198630803, 17.606269509, 16.963939993, 15.711647271, 14.422554941, 13.153682923,
  12.211689882, 11.395635684, 11.118372073, 11.074226299, 11.145652163, 11.267077946, 11.733932058, 12.533687797,
  13.632013585, 14.506650597, 15.351280953, 15.546897189, 15.258273623, 14.850039701, 14.537794917, 14.434327549,
  14.626021819, 14.719754449, 16.114609926, 16.327951955, 15.408358595, 14.321838027, 13.095454231, 11.991956192,
  11.341602741, 10.974212801, 10.897612033, 11.265709978, 12.316643032, 14.891981863, 17.074849963, 18.287055391,
  18.689829547, 18.718698365, 19.190198212, 19.292691866, 19.209025633, 18.923616983, 18.763720128, 18.478918018,
  18.258617919, 18.364384618, 19.32959701, 19.380475317, 17.729998443, 16.001091751, 14.564128088, 13.197448509,
  12.389655499, 11.919290988, 11.700187638, 11.948003489, 12.899443404, 15.139525078, 17.164991695, 17.922524384,
  18.207396775, 18.138843124, 18.324246051, 18.382091227, 18.568220702, 18.451081306, 18.280112362, 18.284650188,
  18.165484869, 18.429403229, 19.23487526, 19.462051857, 17.880364253, 16.278674766, 14.803237886, 13.529189849,
  13.026575722, 12.663992529, 12.478666428, 12.861497959, 13.842401608, 16.138801794, 18.001022302, 18.964755361,
  18.683887491, 18.147041367, 17.954731768, 17.689679786, 17.607569137, 17.636427401, 17.71789115, 18.067139509,
  18.510116469, 18.62385308, 19.705711024, 19.73651989, 18.258900704, 16.635624915, 15.274364879, 13.977598474,
  13.333369645, 13.011279793, 13.040150607, 13.409623996, 14.320681597, 16.665285239, 18.619218602, 19.725251128,
  19.877040429, 19.592276849, 19.709968548, 19.619617694, 20.347283786, 20.028094303, 20.061737604, 19.955532377,
  19.806035425, 19.665256688, 20.252302466, 20.192950889, 18.621081584, 17.017137543, 15.62940511, 14.462507282,
  13.86364908, 13.525514375, 13.525938503, 13.825890656, 14.637158991, 16.857289609, 18.626244137, 19.461704609,
  19.704551026, 19.529325239, 19.57998957, 19.888690116, 19.953078647, 20.073352056, 19.723448496, 19.842614837,
  19.640938961, 19.684942621, 20.305620866, 20.199518334, 18.756700626, 17.117486074, 15.719563391, 14.409682931,
  13.740506599, 13.144745135, 12.999294502, 13.014729403, 13.064175353, 13.624735847, 14.618743589, 16.020629466,
  16.852947977, 17.109483206, 17.329850364, 17.537304997, 17.396911257, 17.066722684, 16.935181485, 16.794325015,
  16.842040598, 16.986672687, 17.373551156, 17.290981743, 16.078859244, 14.855072727, 13.398483413, 12.230819086,
  11.424426335, 10.78583708, 10.628058438, 10.520347465, 10.763228358, 10.8566826, 11.745191866, 12.967533287,
  14.175069277, 14.938306609, 15.234537377, 15.634068606, 15.654483962, 15.419681647, 15.148234723, 15.034638415,
  15.078445373, 15.498436421, 16.163726915, 16.26060614, 15.240928592, 14.166485924, 12.886350179, 11.906791936,
  11.259723119, 10.994970325, 10.988513649, 11.268995434, 12.273864143, 14.95440986, 17.37213087, 18.422579052,
  18.369522635, 18.002745812, 18.271741529, 18.571097171, 18.660209331, 18.319734068, 18.692358752, 18.511427158,
  18.516900143, 18.518827369, 19.25183007, 19.235548964, 17.634333972, 16.02331277, 14.512086886, 13.226016224,
  12.436197492, 12.12448526, 12.043556153, 12.427121618, 13.306586437, 15.689633927, 17.770006711, 18.561174927,
  18.319423941, 18.452224764, 19.028737673, 19.159994016, 19.078239055, 18.864858227, 19.039041263, 18.99156912,
  18.794703813, 18.867690772, 19.641465793, 19.89917306, 18.189923517, 16.493883252, 15.045393029, 13.810431907,
  13.111531886, 12.739308675, 12.675860837, 12.963704155, 13.719431256, 15.933283027, 17.813867689, 18.798065724,
  18.471249302, 18.506776404, 18.72775792, 18.738391387, 18.830993935, 18.699855679, 18.788486942, 18.721537095,
  18.709154958, 18.851476639, 19.669721098, 19.844401073, 18.231379438, 16.433511072, 14.879671374, 13.548883877,
  12.766253045, 12.360237271, 12.363716129, 12.718443046, 13.386167723, 15.442705216, 17.538548096, 18.793460493,
  19.135033517, 19.074063329, 19.181551286, 19.521721046, 19.461786352, 19.262188198, 19.383638273, 19.363303034,
  19.295328534, 19.133334403, 19.677423309, 19.905721858, 18.426090063, 16.739344646, 15.16440006, 13.8692898,
  13.119971648, 12.730391325, 12.49579826, 12.832407365, 13.263698603, 14.9671725, 16.93107454, 18.475716182,
  19.479013582, 20.006500303, 20.546362398, 20.626293871, 20.470863388, 20.009866442, 19.756076571, 19.478380966,
  19.108738646, 18.779114481, 19.059063986, 19.125323334, 17.671228845, 15.927847913, 14.291312752, 12.93310862,
  12.095512117, 11.690696778, 11.426928912, 11.446036632, 11.547501773, 11.899751986, 13.120302946, 14.588486022,
  15.220940704, 15.291286226, 15.412978192, 15.570304343, 15.984364653, 15.967460871, 15.758465878, 15.611145172,
  15.515657466, 15.390313799, 15.608897869, 15.824803581, 14.870614715, 13.768808445, 12.380972836, 11.225587939,
  10.310513329, 9.874725975, 9.735266254, 9.74489566, 9.901563187, 9.90450254, 10.901616587, 12.252734961, 12.641375518,
  12.316176211, 12.18865595, 12.143111683, 12.27875023, 12.247430443, 12.073988685, 11.981978915, 12.077464201,
  12.459079459, 13.106017711, 13.70064451, 13.138613343, 12.497811109, 11.469120765, 10.64449758, 10.055948865,
  9.806904569, 9.692552346, 9.709777604, 9.807958446, 9.944772869, 10.818981949, 11.76512488, 12.403907332,
  12.543751161, 12.593316143, 12.341603617, 12.305493493, 12.16886246, 12.391451915, 12.60389001, 12.983178856,
  13.439396003, 14.129779426, 14.709496129, 13.982412393, 13.21019004, 11.968341647, 11.014787753, 10.38235374,
  10.231606783, 10.241007561, 10.553992249, 11.622819858, 14.18454081, 16.605088673, 18.007484843, 18.388891403,
  18.13073349, 18.245904384, 18.448264749, 18.37668794, 18.054185886, 18.159899506, 18.458301466, 18.600016598,
  18.645181348, 19.451184271, 19.802087113, 18.280494177, 16.580444249, 15.060408201, 13.875235769, 13.205189826,
  12.892765066, 12.840425993, 13.172118842, 13.967184068, 16.524477542, 18.694320084, 19.531419222, 19.758651795,
  19.663131087, 19.641722829, 19.835221198, 19.354585107, 18.942490148, 18.917906632, 18.796818701, 18.717795839,
  18.811982044, 19.404558355, 19.805635606, 18.24785234, 16.645108658, 15.104324302, 14.105217966, 13.421197618,
  13.087593278, 13.208159764, 13.629807377, 14.224187198, 16.510977963, 18.415147027, 19.260023391, 19.355216289,
  18.669799193, 18.940571324, 19.027407486, 19.025298003, 18.664675395, 19.280129893, 19.471044506, 19.553609323,
  19.53336794, 20.019198886, 20.231437303, 18.689059475, 17.069758215, 15.584344365, 14.444068289, 13.835098359,
  13.463673042, 13.486859566, 13.865022407, 14.418093252, 16.637001722, 18.66991859, 19.542046213, 19.363887533,
  18.665612623, 18.866535444, 19.028813567, 19.024175944, 18.7157618, 18.851429757, 18.741684908, 18.735270714,
  18.801698968, 19.242977382, 19.47228103, 18.047631166, 16.38480102, 15.15872685, 14.103613119, 13.445666192,
  12.971966432, 12.914726196, 13.104892229, 12.961333697, 13.483634034, 14.65124757, 16.043663974, 16.78926927,
  16.76547829, 16.994105002, 17.087634859, 17.172453013, 16.552168609, 16.209866745, 16.056442137, 16.271729059,
  16.451305582, 16.867283467, 17.259283395, 16.102363013, 14.939271545, 13.58671327, 12.589712316, 11.886965926,
  11.473742327, 11.312134235, 11.269431305, 11.141785767, 11.373041907, 12.276938673, 13.161978916, 14.044453913,
  14.255097295, 14.390583491, 14.327933005, 14.49227616, 14.414529021, 14.10639823, 14.036504889, 14.483705304,
  14.89666725, 15.633423884, 16.291558297, 15.462489774, 14.483239069, 13.298158201, 12.450619859, 11.82910537,
  11.620176942, 11.586763083, 12.035053786, 12.781090659, 15.495054516, 17.883729697, 19.029337549, 19.107576503,
  18.932891583, 19.345937242, 19.463594747, 19.559844882, 19.391752151, 19.433971761, 19.178027623, 18.947935997,
  18.913923723, 19.290074155, 19.644859343, 18.282034561, 16.59372661, 15.180554214, 14.034526854, 13.371360638,
  12.982772791, 12.997203491, 13.380462615, 14.020565024, 16.474597937, 18.450552548, 19.341000952, 19.403496299,
  18.976212203, 19.196331887, 19.318048574, 19.448932035, 19.173006272, 19.1909757, 19.217544617, 18.971987243,
  19.206042571, 19.609407079, 19.902665329, 18.368116278, 16.612460246, 15.1816216, 14.055294549, 13.248396747,
  13.008421136, 12.944549582, 13.173911635, 13.75204496, 16.13831413, 18.29025697, 19.302162192, 19.568575962,
  19.562868535, 19.819380439, 20.186661809, 20.009454583, 19.99938516, 19.759191841, 19.440251763, 19.442448299,
  19.541792443, 19.977160528, 20.262916972, 18.87940825, 17.090168596, 15.667133126, 14.435109681, 13.802350257,
  13.474667906, 13.507102511, 13.829022906, 14.365158978, 16.716006189, 18.738491396, 19.700627731, 19.601583632,
  19.232830008, 19.349924872, 19.373696888, 18.92958377, 18.712455392, 19.324077653, 18.943877814, 18.988631553,
  18.935820208, 19.447396727, 20.017007828, 18.668336267, 16.870732287, 15.488461054, 14.335752663, 13.692236258,
  13.280929859, 13.294374465, 13.604739568, 14.167240942, 16.368873504, 18.22264431, 19.070846016, 18.757758058,
  18.09825038, 18.280357526, 18.28869003, 18.058790993, 17.820299272, 18.356160943, 18.301081805, 18.458449525,
  18.545504285, 18.885414958, 19.477562046, 18.406694932, 16.710085753, 15.271059204, 14.268586468, 13.529925388,
  13.122161271, 13.049272212, 13.196009669, 13.080856411, 13.839328883, 15.106329394, 16.264241393, 16.455407812,
  16.306619494, 16.245833114, 15.913936259, 15.89221741, 15.62661148, 15.498303314, 15.641711056, 15.800717118,
  16.06375967, 16.422129422, 17.150230529, 16.326430109, 14.994674327, 13.716954277, 12.653711646, 11.904165867,
  11.479026728, 11.307929248, 11.297306408, 11.044417304, 11.437911325, 12.370578086, 13.443166827, 14.08235756,
  14.157555758, 14.151027807, 14.566379302, 14.160729302, 14.082829302, 14.441929302, 14.514129302, 14.510329302,
  15.145879302, 16.014179302, 16.807429302, 15.787295113, 14.774205898, 13.738748957, 12.886364037, 12.298936474,
  12.067720591, 12.169445999, 12.476326662, 13.205143139, 15.87749011, 17.895601211, 18.78463718, 18.721272256,
  18.18335345, 18.481329302, 18.567779302, 18.544979302, 18.749229302, 19.078879302, 19.021879302, 19.196679302,
  19.153929302, 19.601379302, 19.813600157, 18.527354455, 16.720380903, 15.246847955, 14.144084887, 13.536218604,
  13.126473849, 13.084526918, 13.372149689, 13.860379949, 16.272805552, 18.253904118, 19.204592322, 19.201184254,
  19.266343167, 19.715250447, 19.805860607, 19.777097772, 19.431420629, 19.392573802, 19.44557, 19.151361856,
  19.022893078, 19.259878632, 19.627559695, 18.300947711, 16.688229272, 15.140513358, 13.869064041, 12.997607693,
  12.524801474, 12.570775214, 12.757792614, 13.17454452, 15.675332533, 17.336641495, 17.966652243, 17.83393937,
  17.720031855, 18.436102719, 18.458398113, 18.426102773, 18.217387781, 18.132191819, 18.133341115, 18.328685241,
  18.271765456, 18.787784788, 19.331885721, 18.238310959, 16.496780185, 15.01917469, 13.804881686, 13.073193516,
  12.677611568, 12.580500948, 12.864469792, 13.211903143, 15.533275142, 17.461811792, 17.888997232, 17.813159723,
  18.135857353, 18.349273091, 18.940674913, 18.927735537, 18.558479396, 18.65488684, 18.550363009, 18.376023643,
  18.511410715, 19.018310844, 19.64188732, 18.670905651, 17.053239086, 15.533348332, 14.437988127, 13.715185375,
  13.312664065, 13.308583881, 13.640306023, 14.096794011, 16.472960062, 18.205951163, 18.561460631, 18.121945582,
  17.810416447, 17.649056586, 17.891578625, 17.679465428, 17.476787786, 17.761630277, 18.119635427, 18.215664369,
  18.39399725, 18.811957356, 19.102869528, 17.983031093, 16.396378793, 14.863891798, 13.786178565, 13.124386986,
  12.76597631, 12.698871991, 12.640695458, 12.401289123, 13.268614843, 14.443816819, 15.562477019, 16.113488298,
  15.987559017, 16.273508053, 16.167761001, 16.075502648, 16.115532508, 16.456673497, 16.603080362, 16.262937969,
  16.142622474, 16.34426535, 16.37661313, 15.696992879, 14.522949791, 13.16633242, 12.112712446, 11.339422228,
  10.883937445, 10.73804618, 10.698700478, 10.398749874, 10.926357036, 11.853628272, 12.681354424, 13.279953179,
  13.474589504, 14.211941918, 14.600829281, 14.23635174, 13.799722281, 12.951296797, 12.975044775, 13.251291458,
  13.676227092, 14.030250375, 14.667950225, 14.346747449, 13.387773563, 12.180521578, 11.311612552, 10.708015166,
  10.430762177, 10.318876652, 10.372513741, 10.130299831, 10.415119165, 11.317519091, 12.066327155, 12.353118696,
  12.36670943, 12.189138843, 12.015007659, 12.049322227, 12.174723897, 12.287137652, 12.557830473, 13.077521641,
  13.47615813, 14.066726747, 14.568664934, 14.282948751, 13.256990769, 12.110736159, 11.167002002, 10.605545936,
  10.36775659, 10.278795044, 10.371227396, 10.619952484, 11.88637273, 13.124863455, 14.173817949, 14.567443773,
  14.373975628, 14.530216284, 14.920419903, 15.622779302, 15.813729302, 16.027479302, 16.017029302, 16.053129302,
  15.665228882, 15.720649907, 16.053046584, 15.520373278, 14.305169441, 13.034958743, 11.965867894, 11.273494957,
  10.858718777, 10.656246798, 10.570746525, 10.189972757, 10.678673874, 11.658957498, 12.748067186, 13.472967123,
  13.553666826, 13.598757722, 13.601428959, 13.708467068, 13.56083602, 13.537451024, 13.602913928, 13.762875761,
  14.064819871, 14.592428099, 15.179886675, 14.836505864, 13.788482709, 12.492743214, 11.528208646, 11.142773341,
  10.994320737, 11.005403803, 11.196782257, 11.70625599, 14.143082035, 16.217906814, 16.900154234, 16.60440615,
  16.330948164, 16.590545885, 16.67946509, 16.833539722, 16.599358562, 16.91006016, 17.120420214, 17.296840441,
  17.567389949, 17.947087491, 18.45977983, 17.64318843, 16.029001683, 14.465146832, 13.299334085, 12.645208464,
  12.41870385, 12.393821631, 12.501261764, 12.81447446, 14.881353689, 16.547086643, 17.178093203, 17.324986578,
  17.121174459, 17.221614241, 17.019486436, 17.364493121, 17.345502467, 17.520526762, 17.378433407, 17.522199737,
  17.6016866, 17.72461535, 18.043739821, 17.342772206, 15.926628188, 14.547966159, 13.237356977, 12.627717489,
  12.236126078, 12.008681962, 11.850876774, 11.596244822, 12.54880271, 13.650955928, 14.774070236, 15.695450845,
  16.360807555, 16.311574321, 16.664597978, 16.72774905, 16.506843736, 16.469026426, 16.41596027, 16.259729167,
  16.055022419, 16.168347433, 16.26562265, 15.71722097, 14.453190304, 13.166486256, 11.97871174, 11.205902425,
  10.732549915, 10.531648705, 10.288177586, 10.058611092, 10.544197992, 11.385017528, 12.467006626, 13.195396155,
  13.48397951, 13.936777644, 14.179761681, 14.251183585, 14.135008497, 14.009774293, 14.042036985, 14.399607105,
  14.647212821, 14.868536873, 15.25988189, 15.065536854, 14.116973682, 12.972410991, 11.881791342, 11.430011823,
  11.229354749, 11.365257104, 11.617530196, 12.191603761, 15.137526755, 17.336396224, 18.204705329, 18.010196619,
  17.48934217, 17.816213549, 18.175152164, 17.836040551, 17.858960535, 18.228053959, 18.398795244, 18.395047843,
  18.500127288, 18.70204253, 19.080374547, 18.416718691, 16.778872462, 15.253807147, 14.153619626, 13.621752783,
  13.165736197, 13.147417136, 13.236589376, 13.738737822, 16.151508325, 18.117692518, 18.575063218, 18.122342343,
  17.561270598, 17.599441559, 17.891612492, 17.971096291, 17.871765585, 18.248155131, 18.221601782, 18.291876501,
  18.385708952, 18.720410374, 19.189500975, 18.519464449, 16.856856597, 15.27715129, 14.153214793, 13.46329207,
  13.145704477, 13.144873711, 13.321999898, 13.802763726, 16.249169215, 18.112383613, 18.580559255, 18.283569891,
  17.701082593, 17.884680873, 17.594355376, 17.475021247, 17.774529302, 18.274229302, 18.349279302, 18.702679302,
  18.881279302, 18.901725895, 19.425084219, 18.777856373, 17.059117159, 15.524935752, 14.423960601, 13.794702471,
  13.445031521, 13.473191871, 13.500142607, 13.979614771, 16.271966429, 18.107274384, 18.614451842, 18.448679321,
  17.683574618, 18.148271749, 18.515678667, 18.759679302, 18.819529302, 18.918329302, 19.223279302, 19.223279302,
  19.220429302, 19.510179302, 19.669995886, 18.936380692, 17.313276386, 15.740061475, 14.547661364, 13.877757449,
  13.508267372, 13.423206471, 13.519810334, 13.990482204, 16.211754558, 18.361280389, 18.984893403, 18.753274875,
  17.700503874, 17.93446205, 18.202979302, 18.443329302, 18.459479302, 18.637129302, 18.537379302, 18.633329302,
  18.836629302, 19.210929302, 19.523479302, 18.654238229, 17.150523083, 15.661082158, 14.462458383, 13.726579815,
  13.25780201, 13.153243496, 12.952155263, 12.775922701, 13.499749847, 14.746520653, 15.959129311, 16.404370148,
  16.368216268, 15.971210077, 15.986629302, 16.040779302, 15.958129302, 16.179479302, 16.028429302, 16.213679302,
  16.290629302, 16.553779302, 17.118079302, 16.5608859, 15.469977257, 14.13744054, 13.018240303, 12.306856644,
  11.810926242, 11.643757591, 11.441976752, 11.090926117, 11.487297257, 12.568185287, 13.545552622, 14.025439168,
  14.149695752, 14.096129302, 14.026779302, 14.262379302, 14.137929302, 14.188279302, 14.362129302, 14.565429302,
  15.229479302, 15.768129302, 16.194679302, 16.056929302, 14.76366182, 13.638336304, 12.675975247, 12.068568594,
  11.88731745, 11.866405366, 12.045197329, 12.754519565, 15.667994126, 17.825802046, 18.920921727, 19.443837169,
  19.438198457, 19.868859681, 19.859017731, 20.043064994, 20.050729302, 19.761929302, 19.591879302, 19.343929302,
  19.447479302, 19.750529302, 19.48940087, 18.694068464, 16.964338931, 15.397396923, 14.328095301, 13.583805151,
  13.239082475, 13.128861101, 13.310053107, 13.794605639, 16.168058338, 18.046582959, 18.813844617, 18.869672375,
  18.67646573, 18.955907768, 19.414755925, 19.749579302, 19.520629302, 19.824629302, 19.607079302, 19.557679302,
  19.698279302, 19.642677847, 19.716142372, 18.820246945, 17.105063463, 15.492252492, 14.327653867, 13.625644684,
  13.194991328, 13.107237061, 13.122105346, 13.565089484, 16.131751734, 18.159419868, 19.228479681, 19.555058427,
  19.47443046, 19.742267622, 19.928953815, 19.803171743, 19.495030367, 19.425991694, 19.456907617, 19.294668202,
  19.151391968, 19.356963087, 19.328615609, 18.555531768, 16.907876945, 15.388251644, 14.270250394, 13.622061922,
  13.17528682, 13.065884266, 13.056614273, 13.55653497, 16.095660594, 17.998531419, 18.733792102, 18.603320593,
  18.489785395, 18.783299964, 18.801789815, 18.816826689, 18.769038398, 18.86509243, 18.989053464, 18.931083394,
  19.007607717, 19.266219654, 19.26647988, 18.525786692, 16.859696245, 15.361328913, 14.124755664, 13.429800575,
  13.020764614, 12.975146887, 12.959320831, 13.583296847, 16.16198037, 18.224789228, 19.269622207, 19.658166953,
  19.727922013, 20.084949913, 20.478926467, 20.305141675, 19.95909076, 19.958419073, 19.782643955, 19.696998817,
  19.388887654, 19.224690433, 19.226786854, 18.462981177, 16.886863132, 15.620893389, 14.414806571, 13.710214135,
  13.22010512, 13.093229612, 12.883685952, 12.905211861, 13.88148305, 15.189987535, 16.489302109, 17.306997333,
  18.109879302, 17.795429302, 17.264379302, 16.654479302, 15.891629302, 15.947679302, 15.749129302, 15.848879302,
  16.211779302, 16.465429302, 16.591779302, 16.326729302, 15.372929302, 14.042929302, 12.629547812, 11.861157319,
  11.394604931, 11.247623546, 11.036059519, 11.123492051, 11.538072765, 12.778479302, 13.665779302, 14.174979302,
  14.152179302, 14.142679302, 14.036279302, 14.059079302, 13.984029302, 13.828229302, 13.782629302, 14.326979302,
  14.740229302, 15.245629302, 15.545829302, 15.745329302, 14.884629302, 13.757929302, 12.938079302, 12.104750206,
  11.956852032, 11.915913282, 12.162168678, 12.798143586, 15.820563619, 18.050665237, 18.989453894, 19.038029302,
  18.580129302, 18.671329302, 18.599129302, 18.584879302, 18.468029302, 18.548779302, 18.771079302, 18.950629302,
  18.932579302, 19.188129302, 19.374329302, 18.889829302, 17.244429302, 15.700679302, 14.304900406, 13.743440449,
  13.405908836, 13.445579106, 13.294691859, 13.91223845, 16.380570904, 18.379265529, 19.232231745, 19.445579302,
  19.047529302, 19.170079302, 19.424679302, 19.732479302, 19.278379302, 19.194779302, 19.688779302, 19.617529302,
  19.504479302, 19.755279302, 19.943379302, 19.350579302, 17.785929302, 16.127229302, 14.605853752, 13.876538615,
  13.458532431, 13.419137903, 13.32701782, 13.912734246, 16.32555163, 18.289950985, 19.221101503, 19.473435065,
  19.758129302, 19.946229302, 19.836979302, 19.499729302, 19.355329302, 19.423729302, 19.284079302, 19.285029302,
  19.353429302, 19.669779302, 19.792329302, 19.194779302, 17.680479302, 15.885083671, 14.71099681, 14.027099349,
  13.623686748, 13.463530047, 13.466556965, 14.009598346, 16.442930278, 18.449781186, 19.310996197, 19.472850301,
  19.527279302, 19.604229302, 19.651729302, 19.618479302, 19.106429302, 19.119729302, 19.119729302, 19.042779302,
  19.105479302, 19.394279302, 19.625129302, 19.176729302, 17.380585759, 15.877340236, 14.570978786, 13.889489205,
  13.48621385, 13.425588593, 13.290293197, 13.894117089, 16.30225707, 18.142732558, 18.86535996, 18.678776805,
  18.105045746, 18.505079302, 18.722629302, 18.596279302, 18.405329302, 18.332179302, 18.378729302, 18.441429302,
  18.221979302, 18.575379302, 18.577279302, 17.954054522, 16.695930099, 15.249991899, 14.04196697, 13.336169382,
  12.900028002, 12.755243116, 12.361106038, 12.313856195, 13.180675594, 14.34561546, 15.425755295, 15.756341909,
  15.546142993, 15.406291538, 15.118844483, 15.047867425, 14.753187299, 15.273179302, 15.357729302, 15.515429302,
  15.716829302, 15.528355177, 15.646388013, 15.616225998, 14.756166143, 13.34372158, 12.203007262, 11.449091993,
  10.926081618, 10.758125844, 10.399823064, 10.262114138, 10.80859035, 11.690333026, 12.746074222, 13.264166247,
  13.400186624, 13.366649381, 13.556529302, 13.548929302, 13.472929302, 13.395979302, 13.574579302, 13.940329302,
  14.447629302, 14.953979302, 15.325429302, 15.201143523, 14.408992824, 13.270898407, 12.315929026, 11.979842511,
  11.700122233, 11.631343784, 11.727447187, 12.504590126, 15.285079056, 17.479971869, 18.459890136, 18.455028122,
  17.911235493, 18.387279302, 18.368279302, 18.554479302, 18.226729302, 18.470879302, 18.441429302, 18.451879302,
  18.626679302, 19.065579302, 19.266029302, 18.866079302, 16.978302579, 15.407255268, 14.22998466, 13.51816714,
  13.104676353, 13.03180888, 12.966783686, 13.500138949, 15.976379682, 17.969392274, 18.727617541, 18.45117308,
  17.937655116, 18.464229302, 18.365429302, 18.228629302, 18.013929302, 18.226729302, 18.353079302, 18.393929302,
  18.435729302, 18.872729302, 19.029479302, 18.673229302, 17.260579302, 15.416246338, 14.233816254, 13.463664831,
  13.082130523, 13.040679194, 13.025573769, 13.613184633, 16.029377255, 18.048614792, 18.644918404, 18.368127203,
  18.204879302, 18.211529302, 18.089929302, 17.996829302, 17.867629302, 18.276129302, 18.558279302, 18.515529302,
  18.553529302, 18.978179302, 19.211879302, 18.971529302, 17.503779302, 15.963829302, 14.382121396, 13.679703846,
  13.358169315, 13.358081803, 13.247834908, 13.655084267, 15.996791327, 17.963271162, 19.026629302, 18.908829302,
  18.383479302, 18.423379302, 18.302729302, 18.348329302, 18.121279302, 18.456629302, 18.518379302, 18.563029302,
  18.528829302, 18.952529302, 19.177679302, 18.795779302, 17.499029302, 15.665428521, 14.305162633, 13.640384943,
  13.248632763, 13.093711725, 12.938915885, 13.361644332, 15.818574372, 17.607007655, 18.378692257, 18.460221457,
  18.281247097, 18.615786998, 18.567952598, 18.313010877, 18.264729302, 18.494629302, 18.358779302, 18.366379302,
  17.858550426, 17.942148987, 17.850481588, 17.483183334, 16.289523315, 14.884426542, 13.657353449, 12.869416621,
  12.404189554, 12.181228511, 11.81610815, 11.711569619, 12.546985489, 13.802221265, 14.75389534, 15.182235458,
  15.042565571, 14.927097481, 14.715747675, 14.641487307, 14.446019066, 14.460623801, 14.472352202, 14.756604805,
  14.997668352, 15.346051956, 15.497187627, 15.33962523, 14.418504808, 13.07554348, 11.994579452, 11.312605894,
  10.863185352, 10.599091028, 10.247143848, 10.148414737, 10.686142511, 11.73698564, 12.653791411, 13.126464681,
  13.277208151, 13.418351628, 13.662929302, 13.717079302, 13.453929302, 13.394079302, 13.520429302, 13.943179302,
  14.387779302, 15.038529302, 15.428979302, 15.577179302, 14.734673224, 13.583177846, 12.597623335, 12.085991068,
  11.851739018, 11.805151503, 11.744002378, 12.609793243, 15.359910602, 17.485245284, 18.387960457, 18.677979302,
  18.117479302, 18.221029302, 18.221979302, 18.112729302, 17.772629302, 18.457579302, 18.682729302, 18.758729302,
  18.859429302, 19.247029302, 19.218529302, 18.691279302, 17.291929302, 15.800429302, 14.637629302, 13.949829302,
  13.316152516, 13.207471772, 13.265730809, 13.939264439, 16.711479302, 18.508879302, 19.141579302, 19.159629302,
  18.620029302, 18.800529302, 18.985779302, 19.061779302, 19.053229302, 19.103579302, 19.034229302, 19.093129302,
  19.329679302, 19.556729302, 19.517779302, 18.978179302, 17.654829302, 16.159529302, 15.005279302, 14.339329302,
  13.973579302, 13.858629302, 13.793079302, 14.426729302, 16.804579302, 18.712179302, 19.356279302, 19.319229302,
  18.717879302, 19.031379302, 19.101679302, 19.133029302, 18.982929302, 19.071279302, 19.043729302, 19.114029302,
  19.091229302, 19.149179302, 19.067479302, 18.594379302, 17.443929302, 15.869779302, 14.673729302, 13.975479302,
  13.543229302, 13.238279302, 12.812679302, 12.378529302, 12.468779302, 13.260129302, 14.115129302, 14.382079302,
  14.272829302, 14.268079302, 14.364979302, 14.296579302, 14.317479302, 14.288029302, 14.432429302, 14.593929302,
  14.931179302, 15.295029302, 15.572429302, 15.827979302, 15.199079302, 13.954579302, 12.922879302, 12.442179302,
  12.193279302, 12.068829302, 11.957679302, 12.467829302, 14.116079302, 15.583829302, 16.560429302, 16.849229302,
  16.837829302, 16.829279302, 17.130429302, 17.461979302, 17.346079302, 17.523729302, 17.477179302, 17.438229302,
  17.448679302, 17.529429302, 17.322329302, 17.142779302, 16.319129302, 14.877979302, 13.822529302, 13.150879302,
  12.697729302, 12.440279302, 12.160979302, 12.215129302, 13.041629302, 14.054329302, 15.072729302, 15.705429302,
  15.586679302, 15.420429302, 15.725379302, 15.546779302, 15.010979302, 15.039479302, 15.352979302, 15.797579302,
  15.747229302, 15.750079302, 15.807079302, 15.673129302, 15.105029302, 13.872879302, 12.821229302, 12.063129302,
  11.582429302, 11.338279302, 10.683692092, 10.575122973, 11.388629302, 12.165729302, 12.988429302, 13.369379302,
  13.707579302, 13.553679302, 13.580279302, 13.599279302, 13.420679302, 13.525179302, 13.633479302, 13.962179302,
  14.460929302, 14.902679302, 15.131629302, 15.234229302, 14.948279302, 13.857679302, 12.844979302, 11.976746796,
  11.677899771, 11.629227271, 11.539870086, 12.397804338, 15.202246667, 17.293064488, 18.251887956, 18.684629302,
  18.083279302, 18.221029302, 18.133629302, 18.244779302, 18.204879302, 18.418629302, 18.428129302, 18.501279302,
  18.621929302, 18.939229302, 19.062729302, 18.601029302, 17.055433049, 15.433664459, 14.115138606, 13.492952817,
  13.143005409, 13.028645602, 13.0176223, 13.451647122, 15.757828257, 17.587679432, 18.330605789, 18.735816184,
  18.532698605, 18.850487162, 19.174196063, 19.094834128, 19.093129302, 19.306879302, 19.086479302, 19.038979302,
  19.048479302, 19.379079302, 19.0127302, 18.397735521, 17.192012319, 15.684084904, 14.360376537, 13.702192561,
  13.364005387, 13.275904687, 13.252240522, 13.771215349, 16.073460489, 18.0298355, 19.028988831, 19.454967137,
  19.608200252, 20.130529302, 20.221729302, 19.722029302, 19.532979302, 19.364829302, 19.257479302, 19.044679302,
  19.036129302, 19.277429302, 19.283129302, 18.715979302, 17.497129302, 15.72951826, 14.565646604, 13.986264868,
  13.598733668, 13.483974968, 13.393404004, 14.004641517, 16.4624459, 18.381407431, 19.206593292, 19.400431847,
  19.293579302, 19.764779302, 19.400929302, 19.287879302, 18.925929302, 19.209029302, 19.407579302, 19.326829302,
  19.160579302, 19.492129302, 19.720129302, 19.247979302, 17.959779302, 16.492029302, 15.005309423, 14.317408227,
  13.953257312, 13.789127726, 13.663904042, 14.156586135, 16.42739894, 18.274608335, 19.087584485, 19.504479302,
  19.449379302, 19.588079302, 19.776179302, 19.525379302, 19.328729302, 19.490229302, 19.525379302, 19.351529302,
  19.156779302, 19.337279302, 19.243229302, 18.667529302, 17.551279302, 16.312479302, 15.157279302, 14.083778064,
  13.633320328, 13.413451244, 13.038804576, 12.986737973, 13.687940556, 14.983292777, 16.470179302, 17.235879302,
  17.388829302, 17.651029302, 17.650079302, 17.046829302, 16.507229302, 16.199429302, 16.244079302, 16.300129302,
  16.271629302, 16.247879302, 16.202279302, 16.116779302, 15.562929302, 14.351679302, 13.387429302, 12.385293258,
  11.904776437, 11.604454375, 11.231945907, 11.265616757, 11.609029027, 12.408188541, 13.545129302, 14.193029302,
  14.580629302, 15.075579302, 15.275079302, 15.227579302, 14.820979302, 14.289929302, 14.114179302, 14.500829302,
  14.972979302, 15.398579302, 15.592379302, 15.705429302, 15.374829302, 14.223429302, 13.327579302, 12.832629302,
  12.639779302, 12.574229302, 12.584679302, 13.485279302, 16.188029302, 18.353079302, 19.266979302, 19.328729302,
  19.034229302, 19.165329302, 19.408529302, 19.488329302, 19.209029302, 19.604229302, 19.571929302, 19.581429302,
  19.465529302, 19.926279302, 19.780929302, 19.316379302, 18.037679302, 16.441679302, 15.215229302, 14.529329302,
  14.241479302, 14.262379302, 14.187329302, 14.661379302, 17.071529302, 19.007629302, 19.843629302, 20.012729302,
  19.612779302, 19.859779302, 19.859779302, 19.993729302, 20.016529302, 20.417429302, 20.346179302, 20.381329302,
  20.259729302, 20.375629302, 20.303429302, 19.721079302, 18.501279302, 16.900529302, 15.531579302, 14.852329302,
  14.540729302, 14.422929302, 14.212029302, 14.775379302, 17.175079302, 19.075079302, 20.073529302, 20.434529302,
  20.450679302, 21.161279302, 21.376929302, 21.058679302, 20.679629302, 20.522879302, 20.314829302, 20.371829302,
  20.302479302, 20.396529302, 20.220779302, 19.610879302, 18.405329302, 16.872979302, 15.732029302, 15.223779302,
  14.863729302, 14.754479302, 14.655679302, 15.181979302, 17.181729302, 19.055129302, 20.237879302, 20.273979302,
  20.049779302, 20.036479302, 20.267329302, 20.204629302, 20.180879302, 20.481079302, 20.365179302, 20.397479302,
  20.381329302, 20.618829302, 20.669179302, 20.128629302, 18.993379302, 17.271979302, 15.933429302, 15.157279302,
  14.846629302, 14.564479302, 14.436229302, 15.029979302, 17.233029302, 19.171979302, 20.401279302, 20.826879302,
  20.410779302, 20.438329302, 20.326229302, 20.313879302, 19.992779302, 20.026979302, 20.037429302, 19.855029302,
  19.626079302, 19.608029302, 19.405679302, 18.713129302, 17.592129302, 16.274479302, 15.281729302, 14.649029302,
  14.131279302, 13.879529302, 13.563179302, 13.486229302, 14.363079302, 15.472679302, 16.660179302, 17.268179302,
  17.536079302, 17.681429302, 17.614929302, 17.486679302, 17.098129302, 16.730479302, 16.602229302, 16.494879302,
  16.407479302, 16.284929302, 16.264029302, 15.951479302, 15.478379302, 14.440029302, 13.515679302, 12.773729302,
  12.372829302, 12.103029302, 11.782879302, 11.690729302, 12.232229302, 13.107179302, 14.002079302, 14.492279302,
  14.878929302, 14.927379302, 14.614829302, 14.827629302, 14.643329302, 14.898879302, 15.166779302, 15.302629302,
  15.538229302, 15.892579302, 16.000879302, 16.006579302, 15.770029302, 14.674679302, 13.770279302, 13.314279302,
  13.058729302, 12.971329302, 12.862079302, 13.465329302, 16.171879302, 18.461379302, 19.532979302, 19.903479302,
  19.661229302, 19.861679302, 19.940529302, 19.788529302, 19.502579302, 19.661229302, 19.758129302, 19.841729302,
  19.893979302, 20.003229302, 19.879729302, 19.240379302, 18.005379302, 16.616479302, 15.462229302, 14.974879302,
  14.590129302, 14.425779302, 14.211079302, 14.729779302, 16.898629302, 18.600079302, 19.047529302, 19.092179302,
  18.923079302, 19.414229302, 19.373379302, 19.055129302, 18.925929302, 18.924979302, 18.772029302, 18.730229302,
  18.616229302, 18.906929302, 19.000979302, 18.405329302, 17.294779302, 15.931529302, 14.621314075, 14.009370271,
  13.462108806, 13.338321106, 13.149240854, 13.521541846, 15.545270975, 17.286540895, 18.0830855, 18.10628002,
  18.356465503, 19.079829302, 19.223279302, 19.038029302, 18.604829302, 18.679879302, 18.847079302, 18.804329302,
  18.772979302, 19.079829302, 19.005729302, 18.301779302, 17.138979302, 15.922979302, 14.861829302, 14.289929302,
  13.658406046, 13.580596462, 13.553549555, 14.372579302, 16.678229302, 18.469929302, 19.219479302, 19.187179302,
  18.894579302, 18.880329302, 18.876529302, 19.087429302, 18.917379302, 19.097879302, 19.170079302, 19.192879302,
  19.077929302, 19.455079302, 19.507329302, 19.057029302, 18.085179302, 16.656379302, 15.463179302, 14.788679302,
  14.350729302, 14.155029302, 14.128429302, 14.650929302, 16.815979302, 18.418629302, 19.437029302, 19.867379302,
  19.721079302, 19.976629302, 20.170429302, 20.253079302, 19.770479302, 19.791379302, 19.813229302, 19.683079302,
  19.620379302, 19.513979302, 19.261279302, 18.513629302, 17.554129302, 16.254529302, 15.091729302, 14.487529302,
  14.036279302, 13.829179302, 13.548929302, 13.463429302, 14.209179302, 15.251329302, 16.358079302, 17.144679302,
  17.216879302, 17.085779302, 16.687729302, 16.790329302, 16.241229302, 16.389429302, 16.411279302, 16.357129302,
  16.232679302, 16.332429302, 16.264029302, 16.067379302, 15.508779302, 14.319379302, 13.195529302, 12.586579302,
  12.249329302, 12.034629302, 11.684079302, 11.555829302, 11.918729302, 12.587529302, 13.343729302, 14.010629302,
  14.035329302, 13.600229302, 13.897579302, 13.433029302, 13.400729302, 13.003629302, 12.852579302, 13.785479302,
  14.577779302, 15.146829302, 15.105979302, 15.188629302, 15.036629302, 13.967879302, 13.006479302, 12.451679302,
  12.246479302, 12.287329302, 12.298729302, 13.071079302, 15.439429302, 17.518029302, 18.497479302, 18.727379302,
  18.661829302, 18.734979302, 18.822379302, 18.920229302, 18.304629302, 18.591529302, 18.753029302, 18.852779302,
  19.107379302, 19.327779302, 19.325879302, 18.827129302, 17.806829302, 16.472079302, 15.151579302, 14.577779302,
  14.165479302, 14.092329302, 13.852929302, 14.218679302, 16.382779302, 18.191579302, 18.867979302, 19.025679302,
  18.705529302, 18.974379302, 19.008579302, 18.905029302, 18.820479302, 19.051329302, 19.155829302, 19.230879302,
  19.340129302, 19.665029302, 19.576679302, 18.999079302, 17.852429302, 16.485379302, 15.283629302, 14.732629302,
  14.339329302, 14.258579302, 14.157879302, 14.520779302, 16.549029302, 18.269479302, 19.191929302, 19.412329302,
  19.125429302, 19.284079302, 19.355329302, 19.776179302, 19.661229302, 19.952879302, 20.029829302, 19.847429302,
  19.813229302, 19.941479302, 19.718229302, 18.754929302, 17.560779302, 16.311529302, 15.228529302, 14.556879302,
  14.230079302, 14.078079302, 14.053379302, 14.419129302, 16.624079302, 18.530729302, 19.570029302, 19.752429302,
  19.867379302, 20.101079302, 20.027929302, 19.717279302, 19.240379302, 19.508279302, 19.497829302, 19.275529302,
  19.504479302, 19.805629302, 19.868329302, 19.046579302, 17.897079302, 16.545229302, 15.327329302, 14.656629302,
  14.326029302, 14.230079302, 14.124629302, 14.841879302, 17.055379302, 18.975329302, 19.683079302, 19.666929302,
  19.350579302, 19.591879302, 19.659329302, 19.561479302, 19.030429302, 19.224229302, 19.458879302, 19.398079302,
  19.330629302, 19.434179302, 19.328729302, 18.751129302, 17.758379302, 16.406529302, 15.166779302, 14.383029302,
  13.972629302, 13.718029302, 13.466279302, 13.355129302, 14.174979302, 15.362479302, 16.621229302, 17.081029302,
  17.070579302, 16.882479302, 16.822629302, 16.606029302, 16.249779302, 16.226029302, 16.185179302, 16.468279302,
  16.617429302, 16.710529302, 16.535729302, 16.231729302, 15.586679302, 14.363079302, 13.236379302, 12.669229302,
  12.248379302, 12.034629302, 11.749629302, 11.614729302, 12.126779302, 13.020729302, 13.957429302, 14.558779302,
  14.560679302, 14.412479302, 14.423879302, 14.548329302, 14.419129302, 14.597729302, 14.635729302, 15.070829302,
  15.354879302, 15.689279302, 15.857429302, 15.924879302, 15.501179302, 14.405829302, 13.393129302, 12.759479302,
  12.604629302, 12.510579302, 12.535279302, 13.214529302, 15.877379302, 18.032929302, 19.237529302, 19.663129302,
  19.524429302, 19.761929302, 19.944329302, 20.090629302, 20.205579302, 20.219829302, 20.163779302, 19.924379302,
  19.920579302, 19.997529302, 19.943379302, 19.360079302, 18.057629302, 16.372329302, 15.461279302, 14.864679302,
  14.307029302, 14.292779302, 14.264279302, 14.709829302, 16.904329302, 18.696029302, 19.502579302, 19.513979302,
  19.513029302, 19.509229302, 19.719179302, 19.725829302, 19.418029302, 19.605179302, 19.621329302, 19.884479302,
  19.790429302, 19.917729302, 19.935779302, 19.414229302, 18.183979302, 16.777979302, 15.413779302, 14.696529302,
  14.360229302, 14.139829302, 14.090429302, 14.565429302, 16.733329302, 18.629529302, 19.602329302, 19.881629302,
  20.330029302, 20.607429302, 20.910479302, 21.173629302, 20.863929302, 20.854429302, 20.385129302, 20.102029302,
  19.865479302, 20.079229302, 20.033629302, 19.539629302, 18.290379302, 16.864429302, 15.716829302, 15.048979302,
  14.682279302, 14.648079302, 14.550229302, 14.963479302, 17.214029302, 19.059879302, 20.168529302, 20.398429302,
  20.286329302, 20.874379302, 21.405429302, 21.214479302, 20.559929302, 20.172329302, 19.817979302, 19.458879302,
  19.199529302, 19.499729302, 19.364829302, 18.973429302, 17.727979302, 16.387529302, 15.257029302, 14.687979302,
  14.325079302, 14.213929302, 13.998279302, 14.327929302, 16.416979302, 18.301779302, 19.180529302, 19.329679302,
  19.189079302, 19.001929302, 19.029479302, 19.230879302, 19.173879302, 19.369579302, 19.469329302, 19.424679302,
  19.332529302, 19.456029302, 19.278379302, 18.827129302, 17.773579302, 16.444529302, 15.238029302, 14.499879302,
  13.859579302, 13.782629302, 13.470079302, 13.240179302, 13.993529302, 15.134479302, 16.362829302, 16.892929302,
  16.915729302, 16.828329302, 16.678229302, 16.599379302, 16.324829302, 16.503429302, 16.743779302, 16.926179302,
  17.154179302, 17.217829302, 17.090529302, 16.777979302, 16.108229302, 14.886529302, 13.733229302, 12.958029302,
  12.577079302, 12.300629302, 12.106829302, 11.807579302, 12.284479302, 13.176529302, 14.098029302, 14.631929302,
  14.738329302, 15.119279302, 15.484079302, 15.389079302, 15.332079302, 15.350129302, 15.623729302, 15.704479302,
  15.993279302, 16.280179302, 16.284929302, 16.430279302, 15.988529302, 14.863729302, 13.871929302, 13.473879302,
  13.298129302, 13.224029302, 13.310479302, 13.813979302, 16.244079302, 18.523129302, 19.877829302, 20.363279302,
  20.123879302, 20.259729302, 20.297729302, 20.848729302, 20.615029302, 20.389879302, 20.197979302, 19.678329302,
  19.580479302, 19.889229302, 19.855029302, 19.330629302, 17.921779302, 16.521479302, 15.247529302, 14.688929302,
  14.405829302, 14.157879302, 14.098029302, 14.312729302, 16.368529302, 18.185879302, 18.962029302, 19.133029302,
  18.751129302, 18.771079302, 18.813829302, 18.854679302, 18.547829302, 18.725479302, 18.865129302, 18.953479302,
  19.108329302, 19.279329302, 19.406629302, 19.105479302, 17.932229302, 16.517679302, 15.381479302, 14.751629302,
  14.307029302, 14.229129302, 14.169279302, 14.473279302, 16.492979302, 18.163079302, 19.183379302, 19.440829302,
  19.387629302, 19.660279302, 19.846479302, 19.988979302, 19.793279302, 19.558629302, 19.768579302, 19.656479302,
  19.402829302, 19.565279302, 19.610879302, 19.213779302, 17.962629302, 16.521479302, 15.403329302, 14.755429302,
  14.410579302, 13.954931638, 13.816837415, 14.240895123, 16.411004828, 18.08362653, 19.271729302, 19.392379302,
  19.389529302, 19.628929302, 19.509229302, 19.109279302, 18.698879302, 19.169129302, 19.072229302, 18.872729302,
  18.948729302, 19.121629302, 19.170079302, 18.778679302, 17.496179302, 16.206079302, 15.006229302, 14.040794591,
  13.665962719, 13.566449854, 13.587588524, 13.854202401, 15.897071732, 18.070929302, 19.057029302, 19.153929302,
  19.020929302, 19.345829302, 19.432279302, 19.161529302, 18.985779302, 19.128279302, 19.065579302, 19.087429302,
  18.987679302, 19.000979302, 18.959179302, 18.372079302, 17.098129302, 15.892579302, 14.891279302, 14.188279302,
  13.385698473, 13.210151127, 12.918099747, 12.707709228, 13.415529453, 14.765879302, 15.723479302, 16.083529302,
  16.313429302, 16.363779302, 15.851729302, 16.120579302, 15.756729302, 15.754829302, 15.616129302, 15.794729302,
  15.898279302, 15.974279302, 16.031279302, 16.074979302, 15.275079302, 14.082829302, 12.954229302, 12.329129302,
  11.954829302, 11.861729302, 11.474129302, 11.203379302, 11.611879302, 12.311079302, 13.224979302, 14.203479302,
  14.382079302, 14.405829302, 14.534079302, 14.625279302, 14.515079302, 14.495129302, 14.462829302, 14.734529302,
  15.286479302, 15.460329302, 15.694029302, 15.900179302, 15.107879302, 14.092329302, 13.179379302, 12.615079302,
  12.427929302, 12.363329302, 12.483029302, 12.957079302, 15.278879302, 17.188379302, 18.429079302, 18.981029302,
  18.869879302, 18.977229302, 19.337279302, 19.335379302, 19.114029302, 19.364829302, 19.537729302, 19.585229302,
  19.387629302, 19.516829302, 19.266979302, 18.742579302, 17.368879302, 16.037929302, 14.936879302, 14.390629302,
  13.909929302, 13.770279302, 13.722779302, 14.038179302, 15.958129302, 17.679529302, 18.788179302, 18.886979302,
  18.574429302, 19.261279302, 19.655529302, 19.928179302, 19.679279302, 19.613729302, 19.488329302, 19.327779302,
  19.109279302, 19.007629302, 18.898379302, 18.427179302, 17.077229302, 15.691179302, 14.668029302, 14.021079302,
  13.340029001, 13.383838092, 13.288319514, 13.497123378, 15.645121085, 17.375297346, 18.375599629, 18.746751803,
  19.167229302, 19.575729302, 19.704929302, 19.601379302, 19.344879302, 19.161529302, 18.845179302, 18.557329302,
  18.349279302, 18.480379302, 18.421479302, 18.036729302, 16.637379302, 14.930331495, 13.764212929, 13.152350825,
  12.790166123, 12.759421379, 12.711133624, 13.071555921, 15.025765163, 16.808159488, 17.746358449, 18.023748642,
  17.950279302, 18.098479302, 18.369229302, 18.208679302, 18.176379302, 18.437629302, 18.427179302, 18.106079302,
  18.135529302, 18.480379302, 18.582029302, 18.215329302, 16.789379302, 15.492629302, 14.194274227, 13.470766913,
  13.185343395, 12.989123523, 13.09707235, 13.499118212, 15.609119377, 17.151283079, 18.514579302, 19.208079302,
  19.158679302, 19.464579302, 19.210929302, 18.782479302, 18.482279302, 18.390129302, 18.277079302, 18.478479302,
  18.343579302, 18.430979302, 18.498429302, 18.118429302, 16.860629302, 15.555329302, 14.588229302, 13.976429302,
  13.616379302, 13.438729302, 13.211679302, 12.966579302, 13.689529302, 14.802929302, 15.904929302, 16.483479302,
  16.465429302, 16.608879302, 16.542379302, 16.362829302, 16.215579302, 16.204179302, 16.141479302, 16.375179302,
  16.490129302, 16.476829302, 16.435029302, 16.266879302, 15.359629302, 13.990679302, 13.009329302, 12.424129302,
  12.099229302, 11.990929302, 11.827529302, 11.457029302, 11.876929302, 12.584679302, 13.277229302, 13.866229302,
  14.239579302, 14.453329302, 14.582529302, 14.740229302, 14.416279302, 14.360229302, 14.569229302, 14.628129302,
  14.935929302, 15.288379302, 15.564829302, 15.492629302, 14.785829302, 13.657229302, 12.814579302, 12.288279302,
  12.123929302, 12.204679302, 12.386129302, 12.723379302, 15.002429302, 17.036379302, 17.822979302, 18.084229302,
  17.985429302, 18.038629302, 18.232429302, 18.307479302, 18.074729302, 18.408179302, 18.102279302, 18.181129302,
  18.030079302, 18.212479302, 18.420529302, 17.902779302, 16.501529302, 15.267479302, 14.239579302, 13.718979302,
  13.481479302, 13.395979302, 13.460579302, 13.784529302, 15.586679302, 17.241579302, 18.261879302, 18.740679302,
  18.836629302, 19.189079302, 19.390479302, 19.437029302, 18.923079302, 18.809079302, 18.887929302, 18.737829302,
  18.525029302, 18.615279302, 18.575379302, 17.898979302, 16.539529302, 15.252279302, 14.208229302, 13.584079302,
  13.216429302, 13.210729302, 13.308579302, 13.573629302, 15.409029302, 17.087679302, 18.031979302, 18.136479302,
  17.949329302, 17.956929302, 17.809679302, 17.980679302, 17.930329302, 17.876179302, 18.140279302, 18.174479302,
  18.058579302, 18.403429302, 18.518379302, 17.914179302, 16.557579302, 15.138279302, 14.141729302, 13.579329302,
  13.252529302, 13.354179302, 13.426379302, 13.730379302, 15.739629302, 17.565529302, 18.499379302, 19.064629302,
  19.104529302, 19.315429302, 19.499729302, 19.476929302, 18.907879302, 18.867979302, 18.577279302, 18.269479302,
  17.988279302, 18.188729302, 18.453779302, 18.087079302, 16.705779302, 15.387179302, 14.347879302, 13.724679302,
  13.379829302, 13.309529302, 13.405479302, 13.704729302, 15.554379302, 17.350829302, 18.395829302, 18.440479302,
  18.177329302, 18.442379302, 18.468979302, 18.575379302, 18.398679302, 18.697929302, 18.710279302, 18.667529302,
  18.603879302, 18.823329302, 18.927829302, 18.406279302, 17.096229302, 15.778579302, 14.646179302, 13.965029302,
  13.661029302, 13.438729302, 13.224029302, 13.034979302, 13.843429302, 14.821929302, 15.962879302, 16.850179302,
  17.410679302, 17.429679302, 17.298579302, 17.133279302, 17.005029302, 16.986029302, 16.701029302, 16.493929302,
  16.501529302, 16.500579302, 16.485379302, 15.997079302, 14.899829302, 13.691429302, 12.599879302, 12.177129302,
  11.942479302, 11.838929302, 11.729679302, 11.370579302, 11.877879302, 12.627429302, 13.637279302, 14.449529302,
  14.884629302, 15.157279302, 15.305479302, 15.144929302, 15.081279302, 14.563529302, 14.461879302, 14.643329302,
  14.750679302, 14.992929302, 15.230429302, 15.052779302, 14.295629302, 13.307629302, 12.434579302, 11.677999392,
  11.443689251, 11.33838753, 11.513450371, 11.944698095, 14.291208047, 16.242011403, 17.423796595, 17.867571131,
  18.192529302, 18.318879302, 18.497479302, 18.518379302, 18.435729302, 17.992079302, 17.921779302, 18.054779302,
  18.217229302, 18.439529302, 18.665629302, 17.931279302, 16.501529302, 14.667961113, 13.554487615, 12.981666456,
  12.534069699, 12.558419975, 12.631859678, 12.888198101, 14.883083387, 16.47373989, 17.350565131, 17.58177957,
  17.580311532, 18.166879302, 18.216279302, 18.086129302, 17.643429302, 18.062379302, 18.186829302, 18.143129302,
  18.135529302, 18.514579302, 18.774879302, 18.184929302, 16.421375568, 14.9565579, 13.768859312, 13.290015099,
  12.850589654, 12.705140447, 12.847878128, 13.205484207, 15.24993712, 17.008406568, 17.838659683, 18.121321466,
  18.233070995, 19.055129302, 19.419929302, 19.191929302, 18.847079302, 18.784379302, 18.774879302, 18.545929302,
  18.486079302, 18.794829302, 19.126379302, 18.491779302, 16.583755849, 15.275671323, 14.184400976, 13.489737268,
  13.137105926, 12.961364279, 13.194900728, 13.366099246, 15.340042453, 17.111479419, 18.040049498, 18.222402763,
  18.117479302, 18.352129302, 18.658029302, 18.490829302, 18.306529302, 18.207729302, 18.295129302, 18.489879302,
  18.510779302, 18.926879302, 19.288829302, 18.696979302, 17.124729302, 15.845079302, 14.372824196, 13.719336014,
  13.294378453, 13.260391953, 13.331732373, 13.61327253, 15.576770501, 17.276943347, 18.140186847, 18.564929302,
  17.892329302, 18.088979302, 18.253329302, 18.166879302, 17.812529302, 18.158329302, 18.321729302, 18.536429302,
  18.584879302, 18.801479302, 19.070329302, 18.472779302, 17.028779302, 15.650329302, 14.573979302, 13.819679302,
  13.344679302, 13.186029302, 13.042579302, 12.808879302, 13.380779302, 14.450479302, 15.587629302, 16.129129302,
  15.987579302, 15.649379302, 15.403329302, 15.385279302, 15.373879302, 15.672179302, 15.827979302, 16.179479302,
  16.429329302, 16.446429302, 16.668729302, 16.110129302, 14.966329302, 13.790229302, 12.991279302, 12.460229302,
  12.203729302, 11.991879302, 11.846529302, 11.463679302, 11.699279302, 12.663529302, 13.607829302, 13.906129302,
  14.035329302, 13.890929302, 13.907079302, 13.960279302, 14.000179302, 14.040079302, 14.231979302, 14.632879302,
  15.220929302, 15.529679302, 16.041729302, 15.976179302, 15.002429302, 13.834879302, 12.885829302, 12.372829302,
  12.113479302, 12.047929302, 12.244579302, 12.414629302, 13.696179302, 15.212379302, 16.352379302, 17.027829302,
  17.005029302, 16.862529302, 16.808379302, 16.964179302, 16.948029302, 17.160829302, 17.533229302, 17.912279302,
  17.992079302, 18.084229302, 18.240029302, 17.677629302, 16.427429302, 15.086029302, 13.922279302, 13.238279302,
  12.896279302, 12.472579302, 12.350029302, 12.008979302, 12.312979302, 13.077729302, 14.064779302, 14.646179302,
  14.705079302, 14.562579302, 14.421979302, 14.570179302, 14.732629302, 14.803879302, 15.151579302, 15.703529302,
  16.022729302, 16.300129302, 16.701029302, 16.558529302, 15.578129302, 14.271879302, 13.350379302, 12.929529302,
  12.711029302, 12.635029302, 12.877279302, 13.354179302, 15.748179302, 18.037679302, 19.404729302, 19.936729302,
  19.817029302, 20.072579302, 20.235979302, 20.376579302, 20.433579302, 20.710029302, 20.646379302, 20.720479302,
  20.627379302, 20.714779302, 20.984579302, 20.055479302, 18.301779302, 16.841629302, 15.554379302, 14.831429302,
  14.573029302, 14.630029302, 14.812429302, 15.176279302, 17.214979302, 18.987679302, 20.046929302, 20.347129302,
  20.179929302, 20.533329302, 20.674879302, 20.879129302, 20.817379302, 20.957979302, 20.833529302, 20.871529302,
  20.633079302, 20.769879302, 21.024479302, 19.937679302, 18.327429302, 16.878679302, 15.782379302, 15.260829302,
  14.904579302, 14.785829302, 15.003379302, 15.368179302, 17.271029302, 19.079829302, 20.346179302, 20.518129302,
  20.364229302, 20.623579302, 20.764179302, 20.589379302, 20.294879302, 20.450679302, 20.549479302, 20.356629302,
  20.368029302, 20.503879302, 20.723329302, 19.705879302, 18.181129302, 16.887229302, 15.766229302, 15.115479302,
  14.697479302, 14.577779302, 14.577779302, 14.326029302, 14.946379302, 16.017029302, 17.171279302, 17.735579302,
  17.788779302, 17.813479302, 17.846729302, 17.769779302, 17.849579302, 18.002529302, 18.072829302, 18.035779302,
  18.008229302, 18.112729302, 18.459479302, 17.867629302, 16.589879302, 15.338729302, 14.303229302, 13.678129302,
  13.267729302, 13.014079302, 12.875379302, 12.578979302, 12.845929302, 13.568879302, 14.508429302, 15.140179302,
  15.265579302, 15.348229302, 15.381479302, 15.328279302, 15.397629302, 15.392879302, 15.780479302, 16.057879302,
  16.492029302, 16.929979302, 17.408779302, 17.073429302, 16.136729302, 14.971079302, 14.128429302, 13.653429302,
  13.395979302, 13.345629302, 13.665779302, 14.205379302, 16.718129302, 18.945879302, 20.233129302, 20.728079302,
  20.417429302, 20.539979302, 20.693879302, 20.326229302, 20.061179302, 20.404129302, 20.391779302, 20.514329302,
  20.446879302, 20.695779302, 21.122329302, 19.997529302, 18.218179302, 16.796029302, 15.734879302, 15.256079302,
  14.950179302, 14.910279302, 15.109779302, 15.404279302, 17.352729302, 19.272679302, 20.164729302, 20.177079302,
  20.030779302, 20.163779302, 20.109629302, 20.248329302, 20.224579302, 20.632129302, 20.886729302, 20.786029302,
  20.590329302, 20.770829302, 21.204979302, 20.097279302, 18.454729302, 17.055379302, 15.989479302, 15.373879302,
  15.045179302, 15.052779302, 15.230429302, 15.522079302, 17.379329302, 19.172929302, 20.204629302, 20.385129302,
  19.998479302, 20.107729302, 20.214129302, 20.109629302, 20.080179302, 20.318629302, 20.381329302, 20.344279302,
  20.366129302, 20.661579302, 21.127079302, 19.908229302, 18.239079302, 16.809329302, 15.786179302, 15.190529302,
  14.767779302, 14.752579302, 15.005279302, 15.346329302, 17.311879302, 19.142529302, 20.061179302, 20.244529302,
  19.931979302, 19.949079302, 20.201779302, 20.100129302, 19.911079302, 20.139079302, 20.240729302, 20.454479302,
  20.487729302, 20.748029302, 21.235379302, 20.027929302, 18.214379302, 16.780829302, 15.564829302, 14.943529302,
  14.646179302, 14.554979302, 14.945429302, 15.205729302, 17.169379302, 19.092179302, 20.319579302, 20.656829302,
  20.672029302, 20.919979302, 20.896229302, 20.794579302, 20.531429302, 20.505779302, 20.716679302, 20.701479302,
  20.583679302, 20.740429302, 20.890529302, 19.612779302, 18.037679302, 16.797929302, 15.810879302, 15.131629302,
  14.706029302, 14.468529302, 14.514129302, 14.354529302, 14.954929302, 16.239329302, 17.274829302, 17.972129302,
  18.177329302, 18.112729302, 18.344529302, 18.265679302, 17.966429302, 17.671929302, 17.385029302, 17.345129302,
  17.158929302, 17.533229302, 17.832479302, 16.908129302, 15.763379302, 14.725979302, 13.773129302, 13.225929302,
  12.802229302, 12.670179302, 12.643579302, 12.446929302, 12.737629302, 13.448229302, 14.310829302, 15.004329302,
  15.650329302, 15.743429302, 15.982829302, 16.119629302, 15.975229302, 15.869779302, 15.917279302, 16.282079302,
  16.536679302, 17.029729302, 17.335629302, 16.444529302, 15.647479302, 14.552129302, 13.712329302, 13.300979302,
  13.120479302, 13.081529302, 13.422579302, 13.952679302, 16.426479302, 18.453779302, 19.663129302, 20.159979302,
  20.300579302, 20.589379302, 20.614079302, 20.614079302, 20.575129302, 20.254029302, 20.122929302, 19.871179302,
  19.823679302, 20.434529302, 20.717629302, 19.302129302, 17.545579302, 16.195629302, 15.287429302, 14.822879302,
  14.411529302, 14.357379302, 14.704129302, 15.094579302, 17.077229302, 18.912629302, 20.016529302, 20.487729302,
  20.356629302, 20.717629302, 21.110929302, 21.099529302, 21.010229302, 21.072929302, 20.924729302, 20.677729302,
  20.475379302, 20.881029302, 20.980779302, 19.407579302, 17.807779302, 16.675379302, 15.650329302, 15.105979302,
  14.434675573, 14.391053072, 14.462586573, 14.936922935, 16.960489869, 19.243229302, 20.401279302, 20.793629302,
  20.922829302, 21.359829302, 21.285729302, 21.036829302, 20.621679302, 20.473479302, 20.145729302, 19.988029302,
  20.001329302, 20.305329302, 20.615029302, 19.273629302, 17.738429302, 16.587029302, 15.213230498, 14.621723658,
  14.155671975, 14.058913701, 14.186963663, 14.66144267, 16.567078384, 18.422663548, 19.41361845, 19.715953825,
  19.690679302, 19.815129302, 19.683079302, 19.532029302, 19.168179302, 19.368629302, 19.390479302, 19.337279302,
  19.270779302, 19.780929302, 20.099179302, 18.678929302, 17.126629302, 15.555072724, 14.491409655, 13.738000104,
  13.37010013, 13.385444797, 13.630213957, 14.086422558, 15.838719302, 17.715401053, 18.655539389, 18.759714494,
  18.460790313, 18.772029302, 18.785329302, 18.736879302, 18.647579302, 18.839479302, 19.032329302, 19.192879302,
  19.122579302, 19.591879302, 19.926279302, 18.472779302, 17.014529302, 15.574594206, 14.505865121, 13.809196638,
  13.419543042, 13.285955927, 13.383699171, 13.325136606, 13.768397333, 14.913847826, 16.227456809, 17.012629302,
  16.841629302, 16.691529302, 16.707679302, 16.358079302, 16.124379302, 15.938179302, 16.365679302, 16.631679302,
  16.791279302, 17.109529302, 17.379329302, 16.339079302, 15.438479302, 14.429579302, 13.101593107, 12.467480149,
  12.059975914, 11.770185855, 11.757443396, 11.696682764, 11.792760734, 12.397834682, 13.297641874, 14.495129302,
  14.789629302, 14.529329302, 14.516029302, 14.564479302, 14.317479302, 14.515079302, 14.482779302, 14.632879302,
  15.124979302, 16.165229302, 16.569929302, 15.731079302, 14.802929302, 13.648679302, 12.633690662, 12.192604068,
  12.110483164, 12.127545535, 12.495097743, 13.224168734, 15.742055066, 17.884172777, 18.706508568, 18.845179302,
  18.473729302, 18.629529302, 18.520279302, 18.620979302, 18.677029302, 18.965829302, 19.302129302, 19.436079302,
  19.616579302, 20.347129302, 20.697679302, 19.100729302, 17.309029302, 15.992329302, 15.067029302, 14.183966022,
  13.81219228, 13.69565123, 13.963720392, 14.672758568, 16.841379823, 18.605128128, 19.684979302, 19.580479302,
  19.009529302, 18.846129302, 18.873679302, 18.826179302, 18.686529302, 19.328729302, 19.759079302, 19.885429302,
  19.898729302, 20.494379302, 20.688179302, 18.972479302, 17.189329302, 15.869779302, 14.922629302, 14.353579302,
  13.826683113, 13.816795166, 14.110248008, 14.862139035, 17.382179302, 19.107379302, 19.925329302, 19.826529302,
  19.328729302, 19.247029302, 19.379079302, 19.267929302, 19.361029302, 19.730579302, 19.948129302, 20.191329302,
  20.195129302, 20.937079302, 21.088129302, 19.323029302, 17.586429302, 16.355229302, 15.262729302, 14.717429302,
  14.465679302, 14.439079302, 14.453131573, 15.386229302, 17.439179302, 19.285029302, 20.126729302, 19.982329302,
  19.511129302, 19.403779302, 19.550079302, 19.507329302, 19.334429302, 19.774279302, 20.093479302, 20.359479302,
  20.150479302, 20.894329302, 20.955129302, 19.237529302, 17.528479302, 16.133879302, 15.200979302, 14.707929302,
  14.367829302, 14.377329302, 14.660429302, 15.374829302, 17.420179302, 19.349629302, 20.191329302, 20.114379302,
  19.647929302, 19.533929302, 19.582379302, 19.578579302, 19.486429302, 20.043129302, 20.225529302, 20.397479302,
  20.275879302, 20.773679302, 20.729029302, 19.028529302, 17.449629302, 16.256429302, 15.208579302, 14.673729302,
  14.327929302, 14.185429302, 14.288029302, 14.222479302, 14.788679302, 15.894479302, 16.901479302, 17.356529302,
  17.327079302, 17.283379302, 17.079129302, 17.059179302, 16.900529302, 17.070579302, 17.173179302, 17.385979302,
  17.367929302, 17.783079302, 17.734629302, 16.480629302, 15.348229302, 14.155979302, 13.359879302, 12.741429302,
  12.347179302, 12.261679302, 12.291129302, 12.335779302, 12.406079302, 13.347529302, 14.400129302, 15.084129302,
  15.106929302, 14.924529302, 14.785829302, 14.728829302, 14.848529302, 15.013829302, 15.462229302, 15.806129302,
  16.186129302, 17.059179302, 17.186479302, 16.262129302, 15.379579302, 14.381129302, 13.637279302, 13.246829302,
  13.085329302, 13.090079302, 13.481479302, 14.395379302, 16.891979302, 19.029479302, 20.080179302, 20.067829302,
  19.779979302, 19.898729302, 20.062129302, 20.099179302, 20.133379302, 20.579879302, 20.772729302, 20.901929302,
  20.686279302, 21.568829302, 21.546029302, 19.830329302, 17.881879302, 16.576579302, 15.624679302, 15.200029302,
  14.947329302, 14.851379302, 15.159179302, 15.936279302, 17.993029302, 19.837929302, 20.469679302, 20.657779302,
  20.411729302, 20.602679302, 20.697679302, 20.626429302, 20.647329302, 21.072929302, 21.222079302, 21.139429302,
  21.074829302, 21.812979302, 21.709429302, 19.960479302, 18.035779302, 16.777979302, 15.750079302, 15.215229302,
  14.943529302, 15.005279302, 15.255129302, 15.790929302, 17.433479302, 19.571929302, 20.486779302, 20.501979302,
  20.400329302, 20.632129302, 20.747079302, 20.900979302, 20.791729302, 20.857279302, 21.006429302, 20.834479302,
  20.597929302, 21.485229302, 21.206879302, 19.569079302, 17.850529302, 16.622179302, 15.613279302, 14.999579302,
  14.777279302, 14.707929302, 14.834279302, 15.665529302, 17.785929302, 19.670729302, 20.672979302, 20.793629302,
  20.553279302, 20.726179302, 20.748979302, 20.628329302, 20.092529302, 20.254029302, 20.418379302, 20.150479302,
  20.026029302, 21.058679302, 20.851579302, 19.227079302, 17.518029302, 16.378029302, 14.986056707, 14.398943651,
  13.98168364, 13.932774847, 14.157863782, 14.835312976, 16.858525756, 18.538636285, 19.325318369, 19.14936472,
  18.712525955, 18.685690476, 19.034229302, 18.950629302, 19.133029302, 19.600429302, 19.681179302, 19.879729302,
  19.688779302, 20.423129302, 19.938629302, 18.164979302, 16.377009153, 15.138996785, 14.048560451, 13.36628215,
  13.025632724, 12.84710419, 12.957765112, 13.03239488, 13.430376934, 14.638309769, 15.992027689, 16.308422323,
  16.043995271, 16.199429302, 16.041729302, 16.086379302, 16.207979302, 16.260229302, 16.567079302, 16.810279302,
  16.890079302, 17.566479302, 17.249179302, 15.982829302, 14.970129302, 13.995429302, 12.797856883, 12.188049286,
  11.743466338, 11.541209331, 11.583801606, 11.770322058, 11.837338676, 13.155629302, 14.370679302, 15.012879302,
  14.867529302, 14.748779302, 14.839979302, 14.959679302, 15.139229302, 15.213329302, 15.545829302, 15.660779302,
  16.065479302, 17.238729302, 17.001229302, 15.838429302, 14.814329302, 13.956479302, 13.281029302, 12.891529302,
  12.770879302, 12.818379302, 13.221179302, 14.259529302, 16.741879302, 18.825229302, 19.771429302, 19.977579302,
  19.525379302, 19.552929302, 19.596629302, 19.665029302, 19.582379302, 19.836979302, 20.110579302, 20.226479302,
  20.106779302, 21.221129302, 20.832579302, 19.129229302, 17.349879302, 15.996129302, 15.185779302, 14.748779302,
  14.537879302, 14.484679302, 14.830479302, 15.618029302, 17.831529302, 19.676429302, 20.702429302, 20.987429302,
  20.754679302, 21.048229302, 21.272429302, 20.956079302, 20.583679302, 20.617879302, 20.365179302, 20.236929302,
  20.237879302, 21.164129302, 20.695779302, 19.106429302, 17.463879302, 16.192779302, 15.195279302, 14.380877907,
  13.90121534, 13.826333223, 14.003355271, 14.789763802, 16.824119203, 18.633181914, 19.515787176, 19.610879302,
  19.133979302, 19.171979302, 19.174829302, 19.306879302, 19.209979302, 19.552929302, 19.852179302, 19.917729302,
  20.023179302, 21.130879302, 20.715729302, 19.028529302, 17.230179302, 15.922029302, 14.888429302, 14.459979302,
  14.198729302, 13.908645577, 14.159167079, 15.300729302, 17.522779302, 19.311629302, 20.349979302, 20.082079302,
  19.570029302, 19.558629302, 19.380029302, 19.418029302, 19.553879302, 19.974729302, 20.350929302, 20.268279302,
  20.345229302, 21.184079302, 20.592229302, 18.949679302, 17.440129302, 16.175679302, 15.112629302, 14.744029302,
  14.351679302, 14.326029302, 14.591079302, 15.265579302, 17.416379302, 19.201429302, 20.261629302, 20.282529302,
  19.877829302, 19.943379302, 19.806579302, 19.802779302, 19.805629302, 19.931029302, 20.178979302, 20.154279302,
  20.270179302, 20.904779302, 20.257829302, 18.664679302, 17.196929302, 16.163329302, 15.121179302, 14.555929302,
  13.837125129, 13.703199775, 13.763133516, 13.869033991, 14.364104649, 15.578357683, 17.290029302, 18.076629302,
  18.318879302, 18.491779302, 18.424329302, 18.181129302, 17.917029302, 17.613029302, 17.279579302, 17.044929302,
  17.207379302, 17.826779302, 17.319479302, 16.057879302, 14.783779664, 13.657618172, 12.485957009, 11.907853848,
  11.470704613, 11.244845433, 11.222579296, 11.358152924, 11.526524913, 12.294907914, 13.543038292, 14.437524258,
  14.799010508, 15.203838838, 15.047680731, 15.043405644, 14.924529302, 15.062279302, 15.194329302, 15.329229302,
  15.770029302, 16.831179302, 16.088904799, 15.146000713, 14.232794409, 13.18943612, 12.472908226, 12.071458048,
  11.864097332, 11.808865048, 12.213308841, 13.140634352, 15.731864511, 17.873172532, 19.240178532, 19.59184858,
  19.294124214, 19.826529302, 19.721079302, 19.734379302, 19.840779302, 20.082079302, 19.940529302, 19.914879302,
  20.183729302, 21.026379302, 20.402229302, 18.740679302, 17.101929302, 15.636831977, 14.73108796, 14.159386733,
  13.87977018, 13.813266344, 14.247531798, 15.10448845, 17.367054312, 19.353578955, 20.565629302, 20.772729302,
  20.389879302, 20.220779302, 19.796129302, 19.779979302, 19.760979302, 20.012729302, 20.244529302, 20.112479302,
  20.253079302, 21.169829302, 20.536179302, 18.953479302, 17.290979302, 16.055029302, 15.079379302, 14.580629302,
  14.287079302, 13.895901463, 14.263701894, 15.062986465, 17.414479302, 19.204279302, 20.002279302, 20.078279302,
  19.700179302, 19.683079302, 19.847429302, 19.811329302, 19.729629302, 20.190379302, 20.539979302, 20.403179302,
  20.473479302, 21.227779302, 20.421229302, 18.821429302, 17.072479302, 15.968579302, 14.973929302, 14.418179302,
  14.158829302, 13.804571776, 14.099151575, 15.038141446, 17.560779302, 19.341079302, 20.200829302, 20.192279302,
  19.572879302, 19.668829302, 19.798979302, 19.763829302, 19.810379302, 20.268279302, 20.598879302, 20.330979302,
  20.534279302, 21.474779302, 20.637829302, 19.051329302, 17.389779302, 16.214629302, 15.186729302, 14.608179302,
  14.047169047, 14.011556852, 14.231967384, 15.045983463, 17.157879767, 18.962029302, 19.994679302, 19.987079302,
  19.604229302, 19.646979302, 19.662179302, 19.626079302, 19.623229302, 19.929129302, 20.229329302, 19.996579302,
  20.168529302, 20.749929302, 19.961429302, 18.514579302, 16.937579302, 15.913479302, 14.456977492, 13.764313665,
  13.388374751, 13.144343986, 13.159631517, 13.325186528, 13.748328633, 15.006589119, 16.27591055, 17.315202293,
  18.014879302, 17.922729302, 17.767879302, 17.573129302, 17.100979302, 16.804579302, 16.656379302, 16.658279302,
  17.221629302, 17.810629302, 17.204529302, 15.72610739, 14.612807154, 13.199079581, 12.301966576, 11.674550927,
  11.246360444, 11.058545527, 11.061704534, 11.153068128, 11.29202169, 11.940109081, 13.016670177, 14.091711752,
  14.56084802, 14.778688409, 14.643096622, 14.777332888, 14.649212115, 14.643178684, 14.904579302, 15.206679302,
  16.177579302, 16.670154438, 16.165233385, 15.159766054, 14.188055403, 13.023012726, 12.347851137, 11.998860806,
  11.777110626, 11.814424619, 12.259545838, 13.431987903, 16.195166514, 18.178555679, 19.246656418, 19.522164631,
  19.011864405, 19.418979302, 19.379079302, 19.401879302, 19.274579302, 19.818929302, 19.999429302, 19.983279302,
  20.524779302, 21.197379302, 20.438329302, 18.751129302, 17.081029302, 15.710179302, 14.413173613, 13.900504269,
  13.733680025, 13.712850598, 13.932820986, 14.84373846, 17.599729302, 19.266979302, 20.007029302, 19.850279302,
  19.170079302, 19.222329302, 19.325879302, 19.327779302, 19.334429302, 19.679279302, 20.012729302, 19.963329302,
  20.631179302, 21.414929302, 20.614079302, 19.053229302, 17.354629302, 16.115829302, 14.746804504, 14.180336061,
  13.831705248, 13.682556545, 13.827848765, 14.760687169, 17.059364981, 18.755970361, 19.432415495, 19.287492375,
  19.213896847, 19.394103691, 19.607871474, 19.779029302, 19.598529302, 19.669779302, 19.665029302, 19.924379302,
  20.480129302, 20.791202967, 19.98049537, 18.401878723, 16.544685339, 15.231643105, 14.212571066, 13.583916835,
  13.105693592, 13.185565263, 13.393063981, 14.31294997, 16.548317498, 18.30114042, 18.951605812, 19.040131226,
  18.800747849, 18.649473619, 19.047297553, 19.265535315, 19.523663261, 19.933637129, 19.78879602, 19.576470199,
  20.180768986, 20.567525739, 19.869854224, 18.458236516, 16.739066759, 15.275331332, 14.130763925, 13.562607059,
  13.220145203, 13.190666063, 13.566803524, 14.509150001, 16.962267123, 18.700090438, 19.473771031, 19.579194189,
  19.418302644, 19.287344767, 19.076466641, 18.865707329, 19.002059954, 19.41945524, 19.64822953, 19.716340168,
  20.296406291, 20.594861326, 19.777225553, 18.096122369, 16.550515001, 15.371445473, 14.397914189, 13.697480281,
  13.221770621, 13.046708567, 13.170111765, 13.432680439, 14.102139213, 15.132655538, 16.497261789, 17.560107837,
  18.040529302, 17.962629302, 17.854329302, 17.768829302, 17.718479302, 17.775479302, 17.657679302, 17.790679302,
  18.364479302, 18.391079302, 17.562306792, 16.234164023, 14.926827455, 13.607785937, 12.50994774, 11.763232078,
  11.24614422, 10.968625462, 10.850046216, 11.051577956, 11.42473389, 12.06626573, 13.28742474, 14.181390227,
  14.044652795, 13.739428154, 13.062021132, 13.278844861, 13.113055432, 13.60909512, 14.280337304, 14.687888928,
  15.811372391, 16.390631387, 15.771308715, 14.726012776, 13.72744684, 12.631034742, 11.784987783, 11.332103037,
  10.947088324, 10.89001837, 11.27549416, 12.340619475, 15.370811341, 17.5728026, 18.877339929, 19.391423606,
  19.289367893, 19.485648584, 19.770997797, 19.861791978, 19.581210373, 19.627180999, 19.544206087, 19.532080774,
  20.241425797, 20.626669609, 19.909844157, 18.263827598, 16.452373389, 15.090822356, 14.005251988, 13.384858257,
  13.002690298, 12.930940754, 13.195771415, 14.062582322, 16.843203193, 18.66927584, 19.621868513, 19.749657181,
  19.380893577, 19.402020391, 19.249341137, 19.482141879, 19.231888114, 19.586582788, 19.649540309, 19.759624118,
  20.646336984, 20.996733639, 20.25608171, 18.73916336, 16.930212718, 15.534087351, 14.459325346, 13.739826912,
  13.336365875, 13.364088081, 13.758428423, 14.61660057, 17.335699812, 19.178295647, 20.027929506, 19.954582846,
  19.275823256, 19.371479302, 19.551029302, 19.539629302, 19.639379302, 20.094429302, 19.950029302, 19.896829302,
  20.702429302, 21.190729302, 20.610279302, 19.211879302, 17.064707231, 15.71353831, 14.631686187, 14.132456467,
  13.78333764, 13.768808483, 14.109434389, 14.876955901, 17.510748187, 19.236849624, 19.929599784, 20.063526251,
  19.8357092, 20.399379302, 20.591279302, 20.795529302, 20.736629302, 21.018779302, 20.935179302, 20.888629302,
  21.429179302, 21.563129302, 20.950379302, 19.556729302, 17.641755068, 16.205335775, 15.002564687, 14.436136566,
  14.057119399, 14.014436864, 14.284827418, 15.216337095, 17.722739724, 19.319383533, 20.182012363, 20.377557658,
  19.722735089, 19.729672209, 19.627041419, 19.706829302, 19.795179302, 20.195129302, 20.191329302, 20.266379302,
  20.822129302, 21.086229302, 20.451629302, 18.670417279, 17.154429403, 15.887493982, 14.739180188, 14.087868165,
  13.698141643, 13.504782411, 13.509803764, 13.797691935, 14.53248416, 15.542107933, 16.975579302, 17.531329302,
  17.384079302, 17.301429302, 17.691879302, 17.827729302, 17.499029302, 17.398329302, 17.214029302, 17.375529302,
  18.131729302, 18.227679302, 17.708979302, 16.304229268, 15.076377927, 13.78056438, 12.691158381, 11.90762157,
  11.369978281, 11.183080473, 11.164446665, 11.288956001, 11.65029101, 12.060035679, 12.91364642, 13.539307747,
  14.104717092, 14.462929514, 14.535084281, 14.632967787, 14.535912845, 14.553605713, 14.404027725, 14.791685504,
  15.924527375, 16.133675153, 15.752526228, 14.823749641, 14.070356546, 13.035483886, 11.963839687, 11.396101859,
  11.024521491, 11.009558613, 11.463170324, 12.550433534, 15.572192627, 17.650995536, 18.740013548, 19.301434065,
  19.182949724, 19.654807003, 19.777923457, 19.466271086, 19.260137837, 19.422815531, 19.238506274, 19.619241956,
  20.343157858, 20.49067401, 19.673134405, 18.025523854, 16.246540313, 14.811860464, 13.693347644, 12.993646181,
  12.647535718, 12.589482722, 12.99538345, 13.905000485, 16.735905399, 18.491574049, 19.308029125, 19.182267564,
  18.598693841, 18.751719204, 18.943732537, 18.966386678, 18.933551463, 19.401344374, 19.670294793, 19.70093196,
  20.441409435, 20.572875665, 19.803346406, 18.33690474, 16.586912262, 15.132124335, 13.844962488, 13.197740597,
  12.741359805, 12.798728703, 13.14347067, 14.119783157, 16.838789748, 18.644497085, 19.580345441, 19.484385035,
  18.769644461, 18.468147875, 18.412634771, 18.56047602, 18.58690089, 18.923190701, 19.323963498, 19.808555574,
  20.722658102, 20.857404601, 20.070663446, 18.411409405, 16.660406019, 15.178640008, 14.072059337, 13.240010496,
  12.822071741, 12.785126819, 13.087193624, 13.99281279, 16.737732724, 18.550247362, 19.454977195, 19.594127501,
  19.436891731, 19.90515808, 20.123923928, 20.39631231, 20.205457145, 20.095660969, 20.056807754, 20.353980364,
  20.881274541, 20.747616584, 20.020195634, 18.458340485, 16.743877315, 15.235512424, 14.02814246, 13.456386579,
  13.068066613, 13.085580255, 13.214478549, 14.142430455, 16.896768281, 18.691421802, 19.684177042, 20.229540359,
  20.381324261, 20.803963234, 20.900966519, 21.001355991, 20.658254509, 20.558243133, 20.401258403, 20.752106901,
  21.147832743, 20.955560927, 20.129830096, 18.53834549, 17.04608748, 15.821366903, 14.60707511, 13.802867489,
  13.357519657, 13.282175868, 13.350221905, 13.59834424, 14.64559963, 15.576292284, 16.959035232, 17.975170196,
  18.400809614, 18.335310263, 18.202990556, 17.951648495, 17.399637741, 17.846729302, 18.030079302, 18.409129302,
  18.896141648, 18.658774141, 18.042002271, 16.754554763, 15.576772487, 14.366625124, 13.259228427, 12.656592469,
  12.114660196, 11.944637732, 12.049858162, 12.272348366, 12.799176281, 13.076894074, 14.146466533, 15.402021279,
  16.240295423, 16.618396759, 16.913947189, 17.153067425, 16.930535127, 16.454989445, 16.186693162, 16.678737351,
  17.451214049, 17.497747963, 17.083283197, 16.037387522, 15.043742096, 13.879842617, 13.029748072, 12.607908636,
  12.369178002, 12.397345921, 12.799683408, 13.909004206, 17.048097866, 18.8280666, 19.910910683, 20.259653492,
  20.255783821, 20.41533489, 20.415917139, 20.245661426, 19.983376562, 20.232547941, 20.258319118, 20.674794876,
  21.510734914, 21.543742658, 20.746849113, 19.205908774, 17.477080285, 16.064322444, 14.867045868, 14.203405083,
  13.745728774, 13.57377529, 13.891940753, 14.777581882, 17.444191674, 19.092638375, 19.735762425, 19.956521768,
  19.725511821, 19.839427701, 19.707433548, 19.965043094, 20.225084203, 20.367229805, 20.278193591, 20.531662604,
  21.084739196, 20.936020581, 20.328921465, 18.791212357, 17.165401379, 15.68874446, 14.637877213, 14.087654853,
  13.729558531, 13.748268077, 14.014030734, 14.978712761, 17.545408456, 19.427409626, 20.355022453, 20.817965305,
  20.966518406, 21.30138957, 21.415759728, 21.102413721, 20.84234141, 20.631880343, 20.719264888, 20.950783567,
  21.484148094, 21.416859692, 20.807220014, 19.317201175, 17.546545352, 16.129322819, 14.941237632, 14.254965354,
  13.795240132, 13.627360959, 13.981221974, 14.932103349, 17.681917161, 19.617566864, 20.379363072, 20.629034254,
  20.325757135, 20.767873347, 20.875406661, 20.993335002, 20.802702753, 20.769705422, 20.733075136, 21.18426055,
  21.577279996, 21.466961939, 20.856972371, 19.343296504, 17.588898249, 16.183689471, 15.02090106, 14.314642592,
  13.856798041, 13.891648805, 14.151469324, 15.087816649, 17.785720078, 19.569241848, 20.396701604, 20.622652708,
  20.63718418, 21.043763378, 21.240782011, 21.311557735, 21.025031668, 20.982019812, 20.954661334, 21.277675821,
  21.603761553, 21.369223653, 20.644532828, 19.106783532, 17.361376851, 15.982462038, 14.755330736, 14.066352425,
  13.527678703, 13.328663442, 13.305530445, 13.591908429, 14.593786588, 15.504898836, 16.558032079, 17.275298443,
  17.552396191, 17.645524352, 17.795112458, 17.80736545, 17.749414197, 17.668467833, 17.771560143, 18.127413561,
  18.540826737, 18.247735334, 17.654857898, 16.462756199, 15.389294544, 14.075738889, 13.047093898, 12.236867473,
  11.750393747, 11.356172556, 11.338585683, 11.509979674, 11.836496078, 12.306367791, 13.007715974, 13.788678413,
  14.110487652, 14.162960729, 14.444462391, 14.852195936, 15.155910054, 15.364377753, 15.61359597, 16.402444222,
  17.156102717, 17.085869287, 16.909881966, 16.326470301, 15.421362722, 14.55558341, 13.517679673, 12.766513305,
  12.450883755, 12.181113358, 12.346410873, 12.925026725, 14.125315585, 16.805993976, 18.844176018, 19.781068382,
  19.897894578, 19.64886373, 19.76545029, 19.977579302, 20.356629302, 20.356629302, 20.531429302, 21.251529302,
  21.937429302, 21.277200371, 20.881797179, 20.027024054, 18.390927994, 16.660424692, 15.3102419, 14.368502471,
  13.774557438, 13.43713264, 13.489813198, 13.818788606, 14.94900176, 17.462132856, 19.283253377, 20.205833898,
  20.657928763, 20.875755795, 21.140379302, 21.096679302, 21.159379302, 20.879129302, 20.932329302, 21.358879302,
  21.817729302, 20.826502343, 20.452385403, 19.554577474, 17.973477048, 16.160796013, 14.64058413, 13.449844325,
  12.552291561, 12.135959558, 11.851507615, 11.857298099, 11.833784753, 11.824363949, 12.428846708, 13.343353913,
  13.817470834, 13.752585321, 13.675177619, 13.940265027, 13.999605829, 13.872728958, 14.031610649, 14.761459718,
  15.372967913, 15.343283043, 15.341265514, 14.995256679, 14.164342627, 13.25745376, 12.14704812, 11.39186819,
  11.023211591, 10.78579863, 10.779224598, 11.298890525, 12.533633044, 15.023924391, 17.172731523, 18.15936901,
  18.391066826, 17.953716402, 18.078556882, 18.336304339, 18.819520274, 19.012950917, 19.359072141, 19.966078385,
  20.270473888, 20.005201913, 19.780367439, 18.997375969, 17.521166233, 15.916721618, 14.713353559, 13.592593131,
  12.932508741, 12.69312469, 12.787020352, 13.139331167, 14.150205898, 16.361721939, 18.273741203, 19.527586279,
  20.038054816, 20.213984538, 20.696832187, 20.911322809, 21.087867285, 20.773522793, 20.79454092, 21.237826383,
  21.169735542, 20.666477083, 20.22080418, 19.28995503, 17.762805621, 16.035908603, 14.822154502, 13.799038933,
  13.175549186, 12.708051293, 12.581697379, 12.716784203, 13.027709084, 13.674898347, 14.671258917, 15.946898167,
  16.537141509, 16.583416554, 16.726117894, 16.994553772, 17.086890205, 16.807319995, 16.995003724, 17.777920697,
  18.182272971, 17.755159442, 17.389425744, 16.75136403, 15.667893011, 14.404806607, 13.188438669, 12.248484478,
  11.557971114, 11.010515536, 10.846862221, 10.961946513, 11.247528702, 11.570609213, 12.155167309, 13.255560428,
  14.257206383, 15.032461478, 15.515904731, 15.894827837, 16.117379688, 15.957447192, 15.728277621, 16.362878358,
  16.823179033, 16.718267173, 16.522156804, 15.922967267, 14.882344917, 13.939367286, 12.932327251, 12.117936809,
  11.732918591, 11.457933848, 11.565765858, 12.035710439, 13.391285269, 16.351956878, 18.435866881, 19.494785583,
  19.614008874, 19.837408507, 19.962346561, 20.16930808, 20.459702089, 20.395120805, 20.626294492, 21.193000635,
  21.514517752, 21.203024585, 20.940838839, 20.086857204, 18.63006502, 17.019493214, 15.588111408, 14.441792282,
  13.941553479, 13.552727547, 13.551999531, 14.024308701, 14.886278552, 17.387467041, 18.986830787, 19.520292221,
  19.472034344, 19.212788858, 19.233430671, 19.749281163, 20.259218495, 20.220431043, 20.371037887, 21.148187226,
  21.510923756, 21.114884713, 20.780973566, 19.964928706, 18.460911297, 16.938195589, 15.440655598, 14.331137106,
  13.82871809, 13.342802922, 13.399283505, 13.731751552, 14.776465065, 17.375246503, 19.157706703, 19.951683093,
  19.929165581, 19.578973896, 20.216846791, 20.594442487, 20.656262186, 20.300752965, 20.547224828, 21.159455086,
  21.346681597, 20.905613488, 20.725161575, 20.009909512, 18.396903759, 16.744049396, 15.298009056, 14.188246521,
  13.633742314, 13.303416525, 13.35461019, 13.802053224, 14.785440265, 17.339195117, 18.740166404, 19.502691187,
  19.410797878, 19.021360211, 19.044660695, 19.446211252, 19.876931437, 19.823365371, 20.216731501, 21.01590848,
  21.240537222, 20.894836924, 20.734141472, 20.007031381, 18.539792945, 16.675494459, 15.258278582, 14.226462982,
  13.618927155, 13.257813453, 13.25687465, 13.603391116, 14.501841464, 16.873006111, 18.56148777, 19.289278172,
  19.583586741, 19.513589656, 19.863607113, 20.237358605, 20.517416477, 20.298413848, 20.410073848, 20.833931089,
  20.786912164, 20.377391712, 19.939617258, 19.117365121, 17.615647674, 16.082252886, 14.807659474, 13.515117715,
  12.879514533, 12.389303111, 12.121577935, 12.155195131, 12.129595908, 12.316624035, 13.203284519, 14.538134011,
  15.567226961, 16.115480551, 16.256066649, 16.38697668, 16.743340253, 16.619150529, 16.655451024, 17.130052362,
  17.123310658, 16.74500738, 16.335635619, 15.713317402, 14.802834302, 13.784612794, 12.68851601, 11.632287235,
  11.003431309, 10.590107074, 10.480310799, 10.60592821, 10.872586397, 11.387006141, 11.972393813, 13.167155614,
  14.214151368, 14.746295598, 15.210260783, 15.460077652, 15.810109436, 15.664538968, 15.686909198, 16.365557753,
  16.652188458, 16.624846317, 16.378596636, 15.836778845, 14.858513832, 13.940603688, 12.813824037, 11.849854097,
  11.392944277, 11.197145023, 11.152555536, 11.678091582, 12.971567787, 16.167036652, 18.07732958, 19.339587842,
  19.698904419, 19.388036363, 19.397242545, 19.76393949, 19.98401715, 19.858008531, 20.29553246, 21.007170461,
  21.139793837, 20.692035801, 20.468823798, 19.774006389, 18.1783654, 16.489361223, 15.14316673, 14.015636159,
  13.577697726, 13.234121752, 13.125830599, 13.387779695, 14.540189836, 17.275218052, 19.157594698, 20.23279989,
  20.322839534, 20.243475055, 20.648961893, 20.881095567, 21.224521456, 21.054011027, 21.286959862, 21.765500985,
  21.714358252, 21.398778789, 21.14069, 20.255095284, 18.601680018, 16.919159227, 15.504309994, 14.467577663,
  13.840554584, 13.535367683, 13.563530728, 13.952624176, 15.012020412, 17.644792341, 19.404143633, 20.408343836,
  20.864437196, 20.958285755, 21.369405413, 21.626994331, 21.597059698, 21.404306277, 21.476175674, 21.852743144,
  21.865636473, 21.335272901, 21.036754224, 20.378480125, 18.710548741, 17.018525286, 15.60207309, 14.330489207,
  13.605309425, 13.200001361, 13.196120896, 13.53718642, 14.564048589, 17.24876794, 18.837701526, 19.742991784,
  19.968082801, 19.885126411, 19.981384053, 20.151166383, 20.64252289, 20.367589732, 20.696511406, 21.288424878,
  21.243706717, 20.825451647, 20.454587332, 19.676501238, 18.227014419, 16.652497383, 15.252834341, 13.958786193,
  13.401786492, 13.050826029, 13.036284179, 13.335936255, 14.326579734, 16.882182668, 18.748594083, 19.917048261,
  20.532727719, 20.78018881, 21.309049942, 21.583531525, 21.67477046, 21.383056441, 21.205876858, 21.623536708,
  21.389121055, 20.787136158, 20.339878053, 19.435899151, 17.951753574, 16.315413872, 14.922314653, 13.579474555,
  12.767591554, 12.220197184, 12.133910109, 12.18401386, 12.581423862, 13.535239608, 14.608526378, 16.158811707,
  17.390113087, 18.086150722, 18.44044335, 18.531672508, 18.52037917, 18.090475335, 18.119721465, 18.548455636,
  18.314884172, 17.832734724, 17.385928747, 16.597702549, 15.450915874, 14.198447916, 12.817191987, 11.68240378,
  10.977613954, 10.481853909, 10.35642559, 10.358043489, 10.701083909, 11.117374135, 11.703512476, 12.746400854,
  13.85355323, 14.859830998, 15.486551465, 16.038553785, 16.276079006, 16.030261896, 16.087426112, 16.54161317,
  16.714588327, 16.648261924, 16.441691028, 15.81134746, 14.780796629, 13.822890488, 12.774186915, 11.922599244,
  11.516016449, 11.317042405, 11.365427188, 12.036500688, 13.298112308, 16.497089613, 18.762840404, 20.189766317,
  20.784491766, 21.049554955, 21.68325818, 21.885418546, 22.062374857, 21.717702653, 21.684892897, 22.055033419,
  21.734153917, 21.240269263, 20.827510412, 20.061853473, 18.466367972, 16.732318379, 15.189067692, 13.983530168,
  13.292204976, 12.950439414, 12.919933245, 13.163661067, 14.231654579, 17.095961446, 18.946774434, 20.105865902,
  20.6393447, 20.639240549, 21.263865721, 21.674741683, 21.692143764, 21.328919272, 21.304055823, 21.77796776,
  21.481383048, 21.034846324, 20.689930811, 19.931474958, 18.16072169, 16.439252139, 14.928020971, 13.73074817,
  12.944608409, 12.528974503, 12.373523746, 12.687113759, 13.674229561, 16.337843342, 18.336245337, 19.521791835,
  19.95589354, 19.667250002, 19.88617489, 19.871539225, 20.308180977, 20.257992434, 20.440999851, 21.044315772,
  21.018826346, 20.535703242, 20.39161963, 19.775222542, 18.079522396, 16.40716824, 15.10153391, 13.845328131,
  13.19368517, 12.881366304, 12.833279641, 13.344711718, 14.460852141, 17.201858737, 19.154982583, 20.418264903,
  20.954452947, 21.105424898, 21.469764836, 21.745323607, 21.931125905, 21.481773639, 21.486740193, 21.923806707,
  21.754024073, 21.160061134, 20.921387264, 20.064258889, 18.414489214, 16.656142086, 15.181403661, 14.06578143,
  13.523382827, 13.187244849, 13.172092236, 13.594818668, 14.659723886, 17.245024088, 19.17976951, 20.215545623,
  20.460418205, 20.59022301, 20.93094842, 21.210450335, 21.352239509, 21.070823121, 21.318317219, 21.841085469,
  21.680414545, 21.122122082, 20.707939627, 19.810835149, 18.261550555, 16.677092923, 15.018932613, 13.909559104,
  13.183784599, 12.623219068, 12.43239598, 12.482564153, 12.913576363, 13.943168405, 15.031775297, 16.592821531,
  17.672352859, 18.064163569, 18.397366569, 18.521190077, 18.481197342, 18.169693473, 18.320320042, 18.750294426,
  18.589311769, 18.172048512, 17.561119492, 16.744863982, 15.447347403, 14.300328134, 13.041302027, 11.857532593,
  11.152472705, 10.719407637, 10.418100382, 10.544231509, 10.785918711, 11.296544993, 11.911887747, 13.215430306,
  14.355817737, 15.075605104, 15.557590271, 15.923060607, 16.145411735, 16.056694113, 16.033124504, 16.638584904,
  16.674224218, 16.5700486, 16.494603568, 15.782275117, 14.77719911, 13.840131348, 12.744394206, 11.789960926,
  11.241015284, 10.970337339, 10.97266451, 11.400122367, 12.934259882, 16.104254463, 18.32762663, 19.777842943,
  20.46936566, 20.460544017, 20.811486031, 20.998037468, 21.079434225, 20.77505595, 21.020455725, 21.589020799,
  21.333232149, 20.815304071, 20.453906846, 19.603750279, 17.908318492, 16.091760002, 14.543500964, 13.275194838,
  12.64631593, 12.344940905, 12.360267138, 12.729499654, 13.67903601, 16.509229892, 18.695222558, 20.062369532,
  20.896369274, 21.194286708, 21.687058243, 21.82635266, 21.86434368, 21.41620533, 21.527494971, 21.890772567,
  21.643566203, 21.155409155, 20.710351681, 19.836376035, 18.0681563, 16.271868748, 14.791822619, 13.56032667,
  12.83967601, 12.395548465, 12.343012875, 12.727235906, 13.749179136, 16.513534912, 18.568442908, 19.835816614,
  20.46593725, 20.612907458, 20.844449729, 20.77102108, 20.956099814, 20.854753472, 20.999129026, 21.426466518,
  21.221072376, 20.695504188, 20.330429722, 19.532048178, 17.818473786, 16.107444289, 14.658678767, 13.486802083,
  12.769501484, 12.306629475, 12.338448726, 12.715146842, 13.914965197, 16.80330911, 18.931619336, 20.225523683,
  20.638068311, 20.631016399, 21.183698097, 21.302936137, 21.490487967, 21.246085445, 21.35684368, 21.592087355,
  21.414890059, 21.068993302, 20.745855428, 19.854255705, 18.123191891, 16.368123977, 14.839031391, 13.619097203,
  12.860012239, 12.553384897, 12.410358646, 12.768586892, 13.832791274, 16.592753749, 18.790533708, 20.168689111,
  20.718159295, 20.701652429, 21.035873576, 21.279366256, 21.405850566, 21.075265688, 21.19881339, 21.60464627,
  21.360752866, 20.879209703, 20.343061502, 19.473615699, 17.799149514, 16.165890246, 14.816241609, 13.724772135,
  13.013515994, 12.64132586, 12.530385697, 12.680268077, 13.069785435, 14.112420213, 15.137316691, 16.722956075,
  18.098616838, 18.887259902, 19.35092382, 19.396904743, 19.420034413, 19.022010816, 18.791821761, 19.180401934,
  18.9454202, 18.509597583, 17.970611049, 17.095512732, 15.887806434, 14.583236371, 13.309806366, 12.16824246,
  11.447876519, 10.899121509, 10.695799959, 10.836514248, 11.11964807, 11.739390559, 12.479216352, 13.663294041,
  15.109276219, 16.030062122, 16.600619597, 16.998342789, 17.209356812, 16.846946695, 16.475799679, 16.96703487,
  17.05576719, 16.900904623, 16.653726339, 15.984964292, 14.962574157, 13.961096254, 12.681372306, 11.657880822,
  11.10324358, 10.792716453, 10.796716366, 11.325783025, 12.689316203, 15.928547341, 18.129188734, 19.588640984,
  20.294017786, 20.369231923, 20.636778728, 20.611289627, 20.859545597, 20.656344547, 20.726197095, 21.094389495,
  20.914970095, 20.480997552, 20.142607524, 19.386530288, 17.639654859, 15.807548994, 14.163608097, 13.07450058,
  12.418261292, 11.923947489, 11.973486527, 12.466514865, 13.562897184, 16.316688829, 18.455281022, 19.666605497,
  20.252703606, 20.187000127, 20.527315092, 20.557284169, 20.831144281, 20.536335023, 20.714983443, 21.206574448,
  21.062041052, 20.675563935, 20.365341624, 19.630718156, 18.059267524, 16.28769714, 14.808694931, 13.607687986,
  13.048792456, 12.699168197, 12.709344969, 13.180788813, 14.180778485, 17.076569457, 19.041579924, 20.282521316,
  21.01812538, 21.296406637, 21.634343713, 21.899651958, 21.918987637, 21.5580198, 21.579979064, 21.991781921,
  21.657249064, 21.148046212, 20.932109813, 20.022794449, 18.477240272, 16.854420696, 15.290892109, 14.136060175,
  13.455894814, 13.120407851, 13.227810636, 13.69063272, 14.721887331, 17.578405948, 19.761519423, 21.030103038,
  21.819484836, 22.200805949, 22.699219152, 22.840203393, 22.807523114, 22.418173196, 22.385389668, 22.669434296,
  22.316090577, 21.853654889, 21.524271141, 20.563081345, 18.878776987, 17.089349939, 15.515256181, 14.249452118,
  13.623637396, 13.204504637, 13.169372587, 13.474864713, 14.464522056, 17.259536266, 19.317444785, 20.418468674,
  21.165103087, 21.310656279, 21.726976435, 21.6303427, 21.698925034, 21.374735791, 21.526461282, 21.882547661,
  21.561185456, 21.04822409, 20.695326601, 19.737748904, 18.145789312, 16.58731602, 15.216642081, 13.940260704,
  13.099458922, 12.69688437, 12.610029895, 12.761661961, 13.166051751, 14.112673934, 15.201008718, 16.779272139,
  18.113430199, 18.72819002, 19.086506434, 19.269881541, 19.240596465, 18.697980285, 18.798839783, 19.192888469,
  18.843421475, 18.45553152, 17.997676191, 17.207180107, 15.973016232, 14.801871936, 13.411366738, 12.242231824,
  11.455912561, 10.954664196, 10.759116868, 10.97333584, 11.32376583, 12.110531086, 12.795726556, 14.02827566,
  15.510811637, 16.483727762, 16.88818756, 17.122725688, 17.265170093, 17.168597444, 17.168839241, 17.56697551,
  17.586044602, 17.506609301, 17.273194414, 16.62073294, 15.518166713, 14.589906795, 13.419368121, 12.30253441,
  11.909162785, 11.704161136, 11.757055833, 12.339830377, 13.715845175, 17.003835998, 19.203766662, 20.608204427,
  21.225171415, 21.264017523, 21.618110019, 21.635778334, 21.816644106, 21.69177311, 21.926176205, 22.363816499,
  22.137757901, 21.649154562, 21.368630315, 20.560718136, 19.042438957, 17.37216032, 15.891387651, 14.609736481,
  13.8715271, 13.470213112, 13.377266355, 13.783979344, 14.847516855, 17.670686281, 19.814064417, 20.974460855,
  21.502431659, 21.507746802, 22.008011673, 22.232232808, 22.280042792, 22.037144296, 22.212244252, 22.597135407,
  22.185384151, 21.724154262, 21.43351711, 20.606770018, 18.912043792, 17.286041627, 15.736063616, 14.519816368,
  13.744462171, 13.359559059, 13.339753569, 13.708477933, 14.7627702, 17.569467654, 19.642613823, 20.66371136,
  21.299435803, 21.423614202, 21.782668453, 22.019312109, 22.085478916, 21.840380437, 21.886183099, 22.259756834,
  21.936712806, 21.469669058, 21.26994527, 20.364259461, 18.697609264, 17.078881776, 15.634548291, 14.307295337,
  13.678997919, 13.29806064, 13.203970537, 13.571347752, 14.635502588, 17.389073757, 19.647988693, 20.782778234,
  21.378010064, 21.518893723, 22.080555316, 22.238768599, 22.318400628, 22.006153441, 21.918339995, 22.2809089,
  22.022126016, 21.596075359, 21.15033183, 20.245930361, 18.5984162, 16.980072645, 15.461734812, 14.309442943,
  13.473792105, 13.058317384, 12.963397859, 13.329910645, 14.426739, 17.248570736, 19.40608177, 20.365490053,
  20.97879599, 20.968612412, 21.385981017, 21.508409251, 21.522468078, 21.187601748, 21.334906172, 21.803084628,
  21.531328067, 21.084356538, 20.789539343, 20.039682872, 18.591633207, 16.899399061, 15.499759008, 14.253107652,
  13.489720947, 13.113524951, 12.996531045, 13.207636358, 13.563066909, 14.658492446, 15.795191709, 17.11983986,
  18.261774695, 18.953739954, 19.407203314, 19.468389101, 19.385621922, 19.04549644, 19.28476636, 19.752424331,
  19.398819601, 19.147122973, 18.653095382, 17.966886248, 16.733245425, 15.587841699, 14.275690761, 13.137888952,
  12.385543363, 11.880142731, 11.713812719, 11.889950392, 12.202354301, 12.970904279, 13.641222729, 14.68784343,
  15.906549674, 16.542935791, 16.83234546, 17.138732364, 17.590979571, 17.619955979, 17.80287921, 18.344008959,
  18.329775036, 18.185618823, 18.080297382, 17.406568797, 16.315635139, 15.382629348, 14.094643558, 13.191541586,
  12.663976685, 12.390819328, 12.412550303, 12.820714842, 14.196516228, 17.34510528, 19.724406032, 20.722611736,
  21.040119909, 20.934215559, 21.121558867, 21.213123244, 21.537250784, 21.531475622, 21.918521066, 22.421269372,
  22.158806841, 21.707076602, 21.410991956, 20.685532525, 19.045703081, 17.356594245, 15.807444765, 14.56904931,
  13.882138315, 13.567889453, 13.546882958, 14.112785312, 15.082768945, 17.938177954, 19.927777647, 20.84056978,
  21.230640274, 21.042797292, 21.207605313, 21.559511298, 21.796975842, 21.595365356, 21.762454331, 22.076657523,
  21.862684181, 21.455650619, 21.197248287, 20.529784363, 18.837120393, 17.132695148, 15.697279953, 14.418110582,
  13.673511972, 13.340117643, 13.328853266, 13.571214987, 14.593659384, 17.381999356, 19.358000569, 20.402615827,
  21.048527916, 21.183710383, 21.550561134, 21.711601818, 21.884421463, 21.449661254, 21.539257242, 22.049938502,
  21.70201735, 21.230521641, 20.906873734, 20.174341064, 18.741591567, 16.960596991, 15.376687875, 14.219307875,
  13.42340391, 13.052163728, 13.007485441, 13.381755291, 14.364458444, 16.973999912, 18.924173107, 20.072706643,
  20.553054063, 20.489731536, 20.866655816, 21.121374855, 21.336516901, 21.186889833, 21.297516085, 21.626209562,
  21.321042154, 20.846604069, 20.361705103, 19.599034936, 18.067659903, 16.443645192, 14.849004117, 13.426372194,
  12.719563117, 12.275956003, 12.140580952, 12.509915309, 13.478836266, 15.849343748, 17.623045819, 18.741940826,
  19.248713138, 19.588248001, 20.263816488, 20.29396997, 20.253846726, 20.110864899, 20.28734219, 20.841676367,
  20.653219382, 20.067228516, 19.585092341, 18.673301318, 17.056493208, 15.491465761, 13.895962395, 12.512282729,
  11.564117773, 11.051505649, 10.826617961, 10.921364571, 11.280969335, 12.130885607, 13.07414949, 14.40360896,
  15.914897378, 16.865056165, 17.666554377, 17.968089083, 18.033008376, 17.938107685, 18.098812582, 18.720035465,
  18.665509849, 18.240330079, 17.762259396, 17.050741431, 15.633009461, 14.254247699, 12.651968823, 11.240214088,
  10.324055509, 9.806022097, 9.425486052, 9.522333395, 9.826327493, 10.428311451, 11.090439019, 12.303864644,
  13.695155101, 14.402923422, 14.891531171, 15.081243828, 15.386291518, 15.341802878, 15.980123996, 15.979769997,
  14.520828089, 13.608533025, 13.150389012, 12.90658218, 12.621033942, 12.223452008, 11.450591819, 10.747779878,
  10.479109855, 10.404920739, 10.432669342, 10.478830308, 10.719636819, 11.119467146, 11.543043757, 12.071257616,
  13.118518785, 13.827580495, 14.289637524, 14.635596624, 14.724522035, 14.324680172, 14.125327345, 14.500348241,
  14.56678939, 14.468877062, 14.283061802, 13.96620036, 13.322593367, 12.71360749, 11.750023095, 10.841545315,
  10.185785438, 9.724433589, 9.505900974, 9.711527745, 9.999823579, 10.576489731, 10.955509557, 11.669389621,
  12.893919559, 13.775810898, 14.228123691, 14.581130936, 14.788628774, 14.449309452, 14.401788018, 14.716298918,
  14.745093117, 14.638905705, 14.55128126, 14.11271416, 13.455020415, 12.748619847, 11.65482179, 10.665305985,
  9.982214209, 9.593651053, 9.533226179, 9.854216687, 10.796280149, 12.758029023, 14.20867274, 15.351375128,
  16.332855679, 16.550199021, 16.8834289, 16.946887336, 17.350641491, 17.485773694, 17.893643667, 18.522938319,
  18.412181277, 18.008667232, 17.751184791, 17.18191229, 15.937278903, 14.698642023, 13.151990789, 12.041777215,
  11.375924096, 10.970569141, 10.877842589, 11.174026557, 12.099862655, 13.908875826, 15.231401714, 16.207997577,
  16.733350481, 17.324936542, 17.557482048, 17.892317289, 18.182656157, 17.885362468, 18.059778713, 18.622234336,
  18.547268144, 18.168544436, 17.859473755, 17.278168901, 16.000223936, 14.679412154, 13.376057855, 12.222978832,
  11.583981122, 11.19827143, 11.177640904, 11.427373557, 12.25500514, 14.001178326, 15.410373467, 16.380538967,
  17.069581693, 17.311420868, 17.479509076, 17.639917032, 18.00471399, 18.009446621, 18.240732552, 18.680323265,
  18.527407721, 18.151985048, 17.536252594, 16.963724907, 15.762607116, 14.511606448, 13.353177435, 12.16074231,
  11.440315514, 11.0966421, 10.773825856, 10.848205826, 11.288072406, 12.12612636, 12.953969172, 14.053807344,
  15.251238295, 15.952576641, 16.597377331, 16.815952508, 17.131224707, 17.086066022, 17.268317915, 17.730402205,
  17.653037066, 17.183711239, 16.766357727, 16.140438639, 15.038462322, 13.855616007, 12.465510864, 11.235683984,
  10.457981622, 9.897236275, 9.69032921, 9.708680795, 9.934011664, 10.363520734, 10.785481321, 11.46059803,
  12.436498439, 13.222220357, 13.725948492, 14.151190177, 14.529107208, 14.709596157, 15.00266885, 15.674727145,
  15.671632849, 15.100150855, 14.330373577, 13.314756093, 12.342450329, 11.799636726, 11.366024808,
];

export const zuzycie_pradu_2023_rok_przestepny = [
  11.617526606, 10.822868777, 10.150886903, 9.779169651, 9.798449544, 9.959669616, 10.132187845, 10.292207469,
  10.590369285, 11.234180734, 11.910500832, 12.529150236, 13.324729302, 13.695229302, 13.963129302, 14.365929302,
  15.380529302, 15.317050769, 15.326904336, 15.156723221, 14.703950814, 13.906105213, 13.026816956, 11.978148718,
  11.188126912, 10.714485712, 10.44559679, 10.442328907, 10.864765245, 11.940517137, 14.886441423, 16.989308045,
  18.144760699, 18.852099563, 18.992706526, 19.151330455, 19.314989015, 19.389669232, 19.126616655, 19.422247063,
  20.216283033, 20.213390357, 19.846453557, 19.506182064, 18.932606862, 17.541234857, 16.000988459, 14.57887017,
  13.571338947, 12.83941947, 12.448940041, 12.423720328, 12.821819699, 13.690495196, 16.061992467, 17.927219197,
  18.717994505, 19.400451598, 19.502710004, 19.662728773, 19.777289258, 19.817687422, 19.66483396, 19.858415597,
  20.684926964, 20.658593355, 20.330099386, 20.012619102, 19.175113316, 17.771568862, 16.149665593, 14.7158196,
  13.533297468, 12.87121408, 12.40777468, 12.331429651, 12.746680047, 13.604250577, 16.172777847, 18.131818887,
  18.887276177, 19.142366255, 18.879194858, 19.010893729, 19.374958815, 19.839525182, 19.770687884, 19.806377604,
  20.4697676, 20.241455386, 19.877120076, 19.570876441, 18.978166819, 17.48173855, 15.851961538, 14.458297267,
  13.356670118, 12.786830093, 12.555070342, 12.650868791, 13.010978258, 13.975330482, 16.459487999, 18.237363951,
  19.317675332, 19.996933031, 20.081717125, 20.43903859, 20.525834351, 20.566979179, 20.311131499, 20.225826562,
  20.713114358, 20.558335253, 20.134817897, 19.723829026, 18.867175247, 17.437217466, 15.861022527, 14.447243095,
  13.20936745, 12.438162877, 11.843745589, 11.590300909, 11.480627663, 11.480967723, 11.757206303, 12.050451723,
  12.801993876, 13.974720473, 14.842554883, 15.412720254, 15.64366793, 16.070055319, 16.045574203, 15.958527331,
  16.433136594, 16.280188819, 16.090844213, 15.789523492, 15.166961064, 14.32286995, 13.4813229, 12.352612933,
  11.41184766, 10.751569049, 10.387160564, 10.178663398, 10.44046499, 10.952098484, 12.174616156, 13.07411951,
  14.19442127, 15.36519854, 15.927537076, 16.399364603, 16.585562356, 16.87007116, 16.751939639, 16.794592041,
  17.42637485, 17.509788928, 17.184008896, 16.845790732, 16.167439549, 15.112468784, 14.047492903, 12.701357697,
  11.6147573, 10.846136716, 10.37355519, 10.2153175, 10.304591631, 10.461529879, 10.955961288, 11.432845819,
  12.143772702, 13.348918297, 14.080517849, 14.766851446, 15.124833464, 15.457745008, 15.330959219, 15.31400326,
  15.946907038, 16.150723068, 16.160228916, 16.135219531, 15.521367556, 14.560788127, 13.629654418, 12.426270526,
  11.566051592, 11.108323556, 10.868560519, 10.992755698, 11.374591371, 12.736269022, 15.989505044, 18.20652722,
  19.270400114, 20.057191642, 20.232333657, 20.792877033, 20.965382425, 21.250559243, 20.797735279, 20.591391948,
  20.933659562, 20.83718621, 20.378399794, 20.225614941, 19.553107119, 17.954534925, 16.312001973, 14.849861235,
  13.812959456, 13.176780173, 12.748128148, 12.864133204, 13.116629953, 14.12455839, 16.884477578, 18.933430665,
  19.882042307, 20.544763332, 20.630767499, 21.116152096, 21.339433983, 21.463093611, 21.044304704, 20.863143745,
  21.327999929, 21.204409449, 20.766330928, 20.588962451, 19.899821425, 18.267017561, 16.615560106, 15.165252334,
  13.989221508, 13.186673211, 12.832982098, 12.93249694, 13.338945145, 14.30131238, 16.964741921, 18.935798183,
  19.812680744, 20.192907446, 20.196423372, 20.275087303, 20.26902507, 20.499683523, 20.520040449, 20.603667322,
  21.164764871, 21.199973267, 20.71086374, 20.49697474, 19.933926066, 18.398451983, 16.702652721, 15.329222547,
  14.100804267, 13.454306943, 13.078965687, 13.206755722, 13.644516476, 14.590723772, 17.255269592, 19.13698127,
  19.981405727, 20.278369589, 19.951317812, 20.223132402, 20.31352475, 20.455205808, 20.262904092, 20.418536388,
  21.060197561, 21.271898253, 20.89910948, 20.73141774, 20.124013681, 18.523544839, 16.886322226, 15.3597808,
  14.188787931, 13.56942434, 13.324836351, 13.24420215, 13.690224643, 14.615636317, 17.235548506, 19.217529651,
  20.087391572, 20.544648379, 20.628354542, 20.939727405, 21.28130089, 21.272561103, 20.960719352, 20.919068606,
  21.230110242, 21.258450453, 20.820411126, 20.613752565, 19.857274902, 18.196723078, 16.726880547, 15.386148556,
  14.176353605, 13.521363781, 13.125510117, 12.951639331, 12.940504988, 13.114932531, 14.000932642, 14.881455797,
  15.99017428, 17.122961912, 17.720831473, 17.905436079, 17.994179617, 18.008934801, 17.651860893, 17.433682696,
  18.107966431, 18.288420328, 18.053296941, 17.561499958, 16.84386819, 15.641817354, 14.636479968, 13.281194467,
  11.996938952, 10.969354002, 10.507498266, 10.248998026, 10.337476865, 10.642321003, 11.328996997, 11.906109544,
  12.814080678, 14.119316262, 15.097466371, 15.788583712, 16.285174497, 16.618717324, 16.218589406, 15.833253369,
  16.213591565, 16.536845516, 16.583255898, 16.47514748, 15.961924091, 14.966498175, 14.011691175, 12.950454217,
  12.012705294, 11.556346148, 11.344779181, 11.340801428, 11.804079048, 12.958243904, 15.969609365, 18.240702976,
  19.347055253, 20.074315673, 20.185154317, 20.329801693, 20.294915988, 20.452804941, 20.130734639, 20.05283751,
  20.563298441, 20.845338552, 20.389973814, 20.099155116, 19.343817755, 17.809777414, 16.224219963, 14.718193339,
  13.749903381, 13.000651052, 12.753990617, 12.670834425, 12.976664558, 13.982437842, 16.540916253, 18.424259548,
  19.489509018, 20.09162452, 20.290626545, 20.406491904, 20.43554149, 20.290913594, 20.035193675, 20.144905752,
  20.706029762, 21.095722628, 20.779518223, 20.567504138, 19.706645069, 18.042132873, 16.429455467, 14.986689876,
  13.812570842, 13.158185097, 12.762941234, 12.561133509, 12.85585179, 13.855965013, 16.625439956, 18.687588188,
  19.752924909, 20.352604967, 20.638989037, 21.209437554, 21.423617864, 21.515677389, 21.039785677, 20.839150065,
  21.206160416, 21.303775086, 20.830019312, 20.410548658, 19.50315274, 17.971934973, 16.399566046, 14.869621333,
  13.715007475, 13.003289992, 12.64420775, 12.556897274, 13.026275183, 13.910389051, 16.636663704, 18.621533271,
  19.83908978, 20.458960363, 20.568412875, 20.999984608, 21.195667078, 21.430301524, 21.054808446, 20.90840416,
  21.252989082, 21.278141379, 20.770365596, 20.454348778, 19.695099687, 18.138607782, 16.468670996, 15.018204244,
  13.775685744, 13.132873588, 12.737583273, 12.70308391, 13.043769739, 14.024388005, 16.790170978, 18.694663939,
  19.887380661, 20.608748395, 20.965441259, 21.485395017, 21.635633856, 21.756899818, 21.247306257, 21.030958735,
  21.247730715, 21.232244891, 20.647251215, 20.208610766, 19.318017691, 17.81095191, 16.104516576, 14.696025682,
  13.437950549, 12.656514873, 12.255316368, 12.080035395, 12.210898073, 12.396179168, 13.468056026, 14.396648451,
  15.704719953, 17.00109768, 17.726906969, 18.243512673, 18.468099172, 18.516350736, 18.086533246, 17.777772934,
  18.182823261, 18.304330558, 17.880507019, 17.571516044, 16.763247643, 15.60697208, 14.457571617, 13.147962302,
  11.990212145, 11.272596378, 10.853314277, 10.637877006, 10.749327021, 10.951281662, 11.584022599, 12.013117335,
  13.165349756, 14.460768707, 15.526002183, 16.168780811, 16.413803785, 16.591510975, 16.212126494, 15.718971415,
  16.050537513, 16.555161033, 16.496857115, 16.469118814, 15.866207249, 14.938019853, 14.083935408, 13.018946807,
  12.003737136, 11.562201574, 11.288347245, 11.280611153, 11.843596652, 13.117253914, 16.240954751, 18.433399378,
  19.722783469, 20.694885689, 20.939324298, 21.529227157, 21.799548583, 21.956620031, 21.426401145, 21.074961219,
  21.297393849, 21.357490422, 20.938224029, 20.653997792, 19.925684204, 18.220392504, 16.567362847, 15.038798269,
  13.737690098, 13.159093244, 12.892662326, 12.854385379, 13.1455118, 14.14533894, 16.996042962, 18.951440988,
  20.147062365, 20.815288802, 21.150911717, 21.640000976, 21.914918572, 22.042320049, 21.585431731, 21.240022517,
  21.410411104, 21.544420615, 21.120543201, 20.91641568, 20.085880134, 18.306014387, 16.592879696, 15.075896102,
  13.813701359, 13.112405116, 12.697085478, 12.660890572, 13.066535866, 14.078945432, 16.797743566, 18.768437625,
  19.962072959, 20.735171768, 20.94765499, 21.423190863, 21.705101844, 21.757875838, 21.299911402, 21.046081286,
  21.276535791, 21.403422085, 20.939003787, 20.628236037, 19.74997447, 18.222157385, 16.524512422, 15.028051422,
  13.764011531, 13.14179204, 12.694564984, 12.702512291, 13.114156739, 14.068526378, 16.797972936, 18.866732573,
  19.934007318, 20.718076659, 20.946686432, 21.461507235, 21.598793265, 21.731936964, 21.316217498, 21.00667538,
  21.206987216, 21.396781899, 20.93065671, 20.719848854, 19.919741193, 18.267791736, 16.592388476, 15.044143353,
  13.87943354, 13.151335616, 12.797929312, 12.767636831, 13.129412031, 14.096179418, 16.73767608, 18.79093265,
  19.878069659, 20.486567205, 20.685354113, 21.252658558, 21.433801488, 21.499568237, 21.097339241, 20.846768845,
  20.982124733, 21.168890135, 20.655868436, 20.286542089, 19.34336108, 17.840097474, 16.275465288, 14.760268057,
  13.548231885, 12.773699771, 12.298843151, 12.188386341, 12.159221398, 12.421754209, 13.378107405, 14.233169953,
  15.742372966, 17.215951966, 17.952992958, 18.402554249, 18.420244058, 18.514267918, 18.140226425, 17.657585435,
  17.762566078, 18.155215584, 17.837772976, 17.315985153, 16.556752411, 15.314893644, 14.22433372, 12.88877802,
  11.757733536, 10.986278221, 10.557853536, 10.243682832, 10.350611932, 10.508555816, 11.145205171, 11.609732352,
  12.88732583, 14.222512683, 15.178315806, 15.701086953, 15.859094901, 16.14938298, 16.045530477, 15.79629819,
  16.144965328, 16.760742379, 16.650128492, 16.431324458, 15.813985435, 14.757207867, 13.843455973, 12.604844156,
  11.639534501, 11.071413855, 10.803791879, 10.90830788, 11.384294305, 12.738945688, 15.822387708, 18.02007983,
  19.508975674, 20.328297462, 20.675469917, 21.320505631, 21.717174196, 21.811318049, 21.215748024, 20.814943418,
  20.847633807, 21.204978997, 20.711460589, 20.439593226, 19.606085723, 18.005741974, 16.387352238, 14.87883647,
  13.805044759, 13.046173925, 12.729667094, 12.745338889, 13.268090513, 14.280744546, 17.009865592, 18.6684499,
  19.815008769, 20.393553732, 20.559581417, 20.873053377, 21.296806891, 21.273270343, 20.947981866, 20.69719133,
  20.776516679, 21.243902623, 20.929904811, 20.709703672, 19.911373631, 18.294792857, 16.704989535, 15.161982193,
  14.104215956, 13.348197555, 12.941464344, 12.824140529, 13.33367771, 14.352425362, 16.937228541, 18.750838017,
  19.795510126, 20.439407032, 20.471748001, 20.924059432, 21.223661791, 21.258035876, 20.863918488, 20.527526517,
  20.658038495, 21.130593434, 20.811419672, 20.613293703, 19.74995054, 18.152977044, 16.568481217, 15.04024296,
  13.941297298, 13.286474486, 12.869575507, 12.934171347, 13.353566991, 14.223276855, 16.837930069, 18.541244889,
  19.677503094, 20.278437729, 20.451236407, 20.99805822, 21.147151424, 21.460289725, 20.917924271, 20.487620456,
  20.609714088, 21.065229246, 20.65206744, 20.419066937, 19.656303405, 18.175163644, 16.614936484, 15.188128145,
  13.927305289, 13.233701822, 12.87845856, 12.957460112, 13.266489101, 14.196712608, 16.708360382, 18.496436855,
  19.537149501, 20.164667353, 20.460711033, 20.915920183, 21.110082152, 21.363757546, 21.056209549, 20.718706726,
  20.616770338, 20.934438633, 20.401636849, 19.872103409, 18.989096022, 17.466012723, 15.979075726, 14.638989388,
  13.371679308, 12.599730928, 12.138557886, 12.042403771, 12.272302589, 12.410725243, 13.253131261, 14.102315727,
  15.713781145, 16.903844728, 17.320328853, 17.723976968, 17.814675424, 17.866943576, 17.541400452, 17.153942342,
  17.337209143, 17.935754647, 17.590109118, 17.111430398, 16.267864282, 14.95019786, 13.796604043, 12.37829075,
  11.262615375, 10.598391433, 10.144940652, 9.988090869, 10.057518538, 10.287795724, 10.595815586, 11.166643935,
  12.4234819, 13.543402777, 14.271460225, 14.794656441, 15.11394411, 15.240016458, 14.931478654, 14.728840556,
  14.969295366, 15.863571755, 16.056699553, 15.894198867, 15.361577801, 14.338786435, 13.505087719, 12.362168558,
  11.445408041, 11.000055904, 10.721472866, 10.692923188, 11.124404284, 12.339946352, 15.517998211, 17.774236446,
  19.148322886, 19.855264667, 20.000067296, 19.843115138, 19.866601914, 19.952909098, 19.638061541, 19.5718557,
  19.852037336, 20.807010791, 20.504017081, 20.244039532, 19.569620782, 17.84363726, 16.197290238, 14.648835275,
  13.575765737, 12.906438728, 12.543987888, 12.52192727, 12.83904858, 13.854303843, 16.453418986, 18.489272722,
  19.820213649, 20.382218481, 20.575181443, 20.887969883, 21.073720245, 21.244119245, 20.612397204, 20.446874504,
  20.412036279, 21.14485145, 20.797769069, 20.582099097, 19.735009344, 18.130032351, 16.38018887, 14.790247376,
  13.522683312, 12.890214867, 12.460870981, 12.505394311, 12.916944912, 13.872951124, 16.550834009, 18.523388593,
  19.8116943, 20.20960763, 20.037677923, 20.196622718, 20.231088881, 20.366659562, 20.010079429, 19.846086786,
  20.191382051, 20.908148589, 20.807557721, 20.49900953, 19.609635696, 18.02115312, 16.242820876, 14.777917069,
  13.725397914, 12.981751765, 12.555201412, 12.59959669, 12.933996828, 13.810814759, 16.460439368, 18.164478781,
  19.226305709, 19.492764631, 18.945635843, 18.885644075, 18.81638598, 19.123751455, 18.913062517, 18.986937988,
  19.393622611, 20.375851246, 20.234236534, 20.000347481, 19.172170383, 17.51325406, 16.002252055, 14.637584493,
  13.510683639, 12.963818559, 12.697403278, 12.711815244, 13.18856745, 14.331406437, 16.634622587, 18.369402386,
  19.258892539, 19.703627845, 19.83111398, 20.329203508, 20.528794495, 20.796823408, 20.404715921, 20.453634354,
  20.460513144, 21.117663262, 20.645731703, 20.330427048, 19.502844504, 17.914433374, 16.37126035, 15.000482471,
  13.926586309, 13.253296193, 12.896869444, 12.911962335, 12.847851268, 13.068727465, 14.009327963, 14.889630261,
  16.332530165, 17.450756476, 18.089317399, 18.479822531, 18.812666774, 19.007566927, 18.632682009, 18.220653754,
  18.061767585, 18.584867787, 18.342552798, 17.785799006, 16.918683206, 15.651883933, 14.580503861, 13.30761838,
  12.367901426, 11.670249621, 11.328293184, 11.049229824, 11.176979486, 11.348519422, 11.791852674, 12.458552795,
  13.539918674, 14.437655018, 14.699081821, 14.85137089, 15.078895365, 15.243838795, 15.026259817, 15.005305327,
  15.40312081, 16.526424013, 16.807597608, 16.778663657, 16.221423939, 15.189658595, 14.251084746, 13.12533609,
  12.31881647, 11.910683667, 11.616682545, 11.55492908, 12.18865705, 13.371250808, 16.316948293, 18.449944077,
  19.772615347, 20.374178416, 20.590674976, 21.292886227, 21.323815261, 21.491518677, 20.925692871, 20.757804698,
  20.558173962, 21.153627888, 20.888449442, 20.690067794, 19.998610016, 18.379192344, 16.634190521, 15.159141121,
  13.978771508, 13.329636237, 12.947559168, 12.963678606, 13.333787475, 14.44032298, 16.960623517, 18.950341792,
  20.126345561, 20.606520806, 20.623231761, 20.901488734, 21.14051417, 21.170739306, 20.70616138, 20.45336518,
  20.371112199, 21.035454405, 20.960882783, 20.717908809, 19.874348434, 18.31783802, 16.736323382, 15.314025916,
  14.242435755, 13.538276791, 13.164959887, 13.178427706, 13.424018631, 14.407231444, 16.836069801, 18.693961569,
  19.762039197, 20.099598804, 19.792743168, 19.721158032, 19.70345843, 19.601051888, 19.418030776, 19.564969528,
  19.930108566, 20.76082935, 20.764647139, 20.488529471, 19.711320135, 18.197107677, 16.590787901, 15.109230559,
  14.121603847, 13.407237734, 13.173356399, 13.056636867, 13.391262657, 14.289935523, 16.864919856, 18.439960096,
  19.3250528, 19.407387176, 19.066606266, 19.220536487, 19.256771805, 19.429319227, 19.461031036, 19.47062384,
  19.652016563, 20.431824721, 20.435378842, 20.183282222, 19.422993926, 17.878380544, 16.250738608, 15.029698036,
  14.07990814, 13.522074813, 13.214633379, 13.313298681, 13.698663811, 14.729362778, 17.04679383, 18.782131197,
  19.907696891, 20.478999904, 20.524892776, 21.227495422, 21.471379254, 21.544875359, 21.286937481, 20.807378841,
  20.784598334, 21.196296546, 20.839849639, 20.383041922, 19.551427543, 17.824849635, 16.234705067, 14.866804,
  13.746768261, 12.969588434, 12.555376993, 12.538400244, 12.744558451, 13.05049747, 13.675617095, 14.652859891,
  15.966758586, 17.201405736, 17.578751306, 17.89848272, 18.118883603, 18.211685524, 17.820961097, 17.816732905,
  17.910372855, 18.496668856, 18.336051791, 17.879973791, 17.06824914, 15.635178507, 14.501189185, 13.253837737,
  12.205469032, 11.493902137, 11.007504507, 10.718762958, 10.874329458, 11.038883755, 11.241960993, 11.963495547,
  13.068123928, 14.124771015, 14.836380763, 15.091295664, 15.0636751, 15.247239901, 14.984361154, 14.729845763,
  14.770429762, 15.812655717, 16.214576993, 16.293200712, 15.835652003, 14.940900209, 14.153446678, 13.06333311,
  12.167121508, 11.501028618, 11.322520033, 11.401605579, 11.866843661, 13.167046026, 16.027899169, 17.991039927,
  19.280615463, 19.867291493, 20.228612577, 20.413315249, 20.581534698, 20.547328576, 20.140966791, 20.003798641,
  20.052172087, 20.531753845, 20.439592544, 20.212786157, 19.479084613, 17.863515614, 16.340979917, 14.951871082,
  13.94681679, 13.374921871, 13.093554764, 13.149489971, 13.656570573, 14.610316723, 17.045478717, 18.780059325,
  19.951325332, 20.382865526, 20.480746564, 20.596019688, 20.726875755, 20.883943983, 20.500375966, 20.309933821,
  20.529694942, 21.067245632, 21.216995747, 20.971647121, 20.162918853, 18.507442613, 16.840754409, 15.453324839,
  14.340369645, 13.477093511, 13.075833936, 13.139790388, 13.462798222, 14.494303742, 16.761905388, 18.500865798,
  19.396340671, 19.723051176, 19.640712583, 19.930373948, 19.981108418, 20.048865421, 19.746522807, 19.637664418,
  19.659018751, 20.280280257, 20.458800043, 20.271254231, 19.539169819, 17.930597025, 16.323077348, 14.800367227,
  13.808470422, 13.192942012, 12.873922774, 12.852095868, 13.261389789, 14.170067531, 16.452147316, 18.183947467,
  19.141689197, 19.191722949, 18.77353183, 18.912210596, 19.080706623, 19.450434897, 19.520598565, 19.495216347,
  19.596527105, 20.340303766, 20.454388828, 20.17436499, 19.458645447, 17.96377649, 16.486253932, 15.020063934,
  13.927000528, 13.360062804, 12.883901639, 12.892703684, 13.324011167, 14.280791832, 16.448924017, 18.384973525,
  19.656332685, 20.262227554, 20.444091822, 20.963006063, 20.829744259, 20.907003156, 20.662295194, 20.202536479,
  20.21178148, 20.530049661, 20.560152265, 20.255110132, 19.274924449, 17.703072417, 16.23731236, 14.907547038,
  13.749291664, 12.984798407, 12.484442463, 12.433180144, 12.614503875, 12.771065547, 13.300322891, 14.388003708,
  15.762324305, 16.809380547, 17.390517827, 17.616330892, 17.74378203, 17.698504867, 17.238522297, 16.711964749,
  16.840333427, 17.551872018, 17.732809405, 17.311921916, 16.553985974, 15.317902687, 13.995038342, 12.799838094,
  11.597716154, 10.912905733, 10.433183898, 10.259509969, 10.278245163, 10.423902377, 10.63188997, 11.497694912,
  12.827490683, 13.986335953, 14.501866611, 14.91092733, 15.337225629, 15.466507338, 15.264808415, 14.811401212,
  14.70652187, 15.407247086, 16.090421572, 16.114890692, 15.431115965, 14.424412134, 13.487106634, 12.323334871,
  11.493529308, 10.994986974, 10.749327222, 10.766093167, 11.229515967, 12.536167239, 15.456766479, 17.818732894,
  19.210960896, 19.487179381, 19.492242281, 19.815942635, 20.036630728, 20.219990889, 20.010110707, 19.72539009,
  19.620036207, 20.208914761, 20.497797338, 20.252157324, 19.424490631, 17.777149079, 16.087537144, 14.536136125,
  13.373164989, 12.780486419, 12.439813293, 12.466595285, 12.911479017, 13.947071838, 16.302369438, 18.286339692,
  19.142744066, 19.153307745, 18.4583358, 18.413566264, 18.442688124, 18.620104427, 18.571905853, 18.769027843,
  19.099737623, 19.80809587, 20.448005252, 20.367807655, 19.599565472, 17.952672923, 16.12590467, 14.536136125,
  13.373164989, 12.780486419, 12.439813293, 12.466595285, 12.911479017, 13.947071838, 16.302369438, 18.286339692,
  19.142744066, 19.153307745, 18.4583358, 18.413566264, 18.442688124, 18.620104427, 18.571905853, 18.769027843,
  19.099737623, 19.80809587, 20.448005252, 20.367807655, 19.599565472, 17.952672923, 16.12590467, 14.634069985,
  13.657683522, 13.132636898, 12.782157636, 12.867955653, 13.232580795, 14.29569771, 16.767567477, 18.58531833,
  19.41257816, 19.152373441, 18.580338123, 18.748322718, 18.843834198, 18.887767969, 18.776235756, 19.086370611,
  19.409515613, 20.054501061, 20.644576, 20.574548212, 19.751959827, 18.056536476, 16.361110006, 14.818027198,
  13.741501636, 13.20253479, 12.935169702, 12.958987808, 13.374240141, 14.454641361, 16.825363782, 18.71656728,
  19.595641385, 19.690867719, 19.323099386, 19.565249906, 19.627673817, 19.76645658, 19.513784092, 19.593983108,
  19.74555057, 20.184092396, 20.70751852, 20.501497058, 19.760343467, 18.048281425, 16.325474441, 14.887689891,
  13.955102231, 13.256182993, 12.975621416, 12.928204305, 13.398912495, 14.298016485, 16.524623727, 18.559917894,
  19.668070449, 20.307185775, 20.035978455, 20.336425953, 20.374678033, 20.472827651, 20.176474197, 20.092596032,
  19.992578194, 20.292182682, 20.507820352, 20.162984245, 19.390676806, 17.608123539, 16.077626479, 14.699571857,
  13.47999946, 12.850198281, 12.359475303, 12.302307372, 12.33378218, 12.541904068, 13.193392822, 14.427805982,
  16.105006632, 17.156881773, 17.565706527, 18.114447162, 18.115987324, 18.172110802, 17.795298904, 17.403576304,
  17.21930153, 17.337582079, 17.805306703, 17.424439534, 16.709643707, 15.400313675, 14.195958956, 12.817496618,
  11.505207016, 10.904330765, 10.415186948, 10.217809277, 10.366806564, 10.583854461, 10.845040416, 11.75600717,
  13.020879613, 13.937739778, 14.469750819, 15.05727402, 15.120509383, 15.496614313, 15.359180854, 15.127619934,
  14.979604152, 15.501720487, 16.357615266, 16.395488854, 15.768621068, 14.77536936, 13.828393742, 12.57607449,
  11.813682578, 11.284046174, 11.004386304, 11.124018646, 11.576428306, 12.808517769, 15.609914825, 18.021629911,
  19.490791787, 19.758771022, 19.368987844, 19.504695444, 19.623317964, 19.876752238, 19.946975875, 19.868034561,
  19.769491012, 20.042539712, 20.599016124, 20.373711292, 19.600675835, 17.913157622, 16.171028453, 14.613216051,
  13.514900549, 12.881067881, 12.604810513, 12.682687886, 13.088347157, 14.16269619, 16.666526767, 18.661671061,
  19.473865187, 19.655325849, 19.335085248, 19.547276161, 19.734299475, 20.116711704, 19.959756946, 19.883680123,
  19.748013752, 19.973874547, 20.36792136, 20.222280978, 19.508870537, 17.970255349, 16.153891963, 14.770167849,
  13.731792935, 13.024653511, 12.67097823, 12.651880197, 13.05587822, 14.139424764, 16.476422344, 18.49296189,
  19.351908136, 19.539590019, 19.378543723, 19.511203138, 19.845123074, 20.02897252, 19.856549769, 19.94223438,
  20.035008508, 20.329396626, 20.727426272, 20.565261394, 19.849381941, 18.261993516, 16.589562617, 15.071985426,
  13.991443303, 13.234721173, 12.869793009, 12.893758069, 13.145213904, 14.033484439, 16.272886988, 18.351831231,
  19.531333855, 19.974713812, 20.071620612, 20.280492463, 20.549778748, 21.064617592, 20.706043212, 20.40299367,
  20.223056399, 20.51225714, 20.92696335, 20.89091861, 20.212845751, 18.572464503, 16.82452805, 15.3992914,
  14.341365463, 13.761631225, 13.550797317, 13.460668217, 13.986191808, 14.850057904, 17.009564198, 18.81677338,
  19.779028999, 19.90588353, 19.750335316, 20.037421796, 20.125760579, 20.173776069, 19.876371438, 19.979748431,
  19.815072346, 19.939509154, 20.474913736, 20.278859048, 19.380817068, 17.75021256, 16.275733459, 14.953096151,
  13.70291198, 12.883778032, 12.498755399, 12.370016762, 12.483520365, 12.516470475, 13.093451808, 14.43633386,
  15.913282511, 17.077548048, 17.595636317, 18.004563996, 18.127936159, 18.151546524, 17.750254561, 17.426333505,
  17.24322631, 17.377433789, 17.854522716, 17.586055703, 16.8075168, 15.46811694, 14.345825077, 13.079233295,
  12.007559271, 11.357309316, 10.829199482, 10.637586208, 10.592293073, 10.671013217, 10.91785456, 11.909627772,
  13.028066717, 13.436900967, 13.497785574, 13.674185605, 13.509948387, 13.946281219, 14.048398041, 13.998197484,
  14.383755275, 15.120764772, 16.14493318, 16.425860056, 15.854695674, 14.899183768, 14.176749856, 13.051602437,
  12.162602528, 11.674331739, 11.490564744, 11.593801022, 12.078937808, 13.165478611, 15.975104763, 18.156983162,
  19.177777532, 19.24961682, 19.105317909, 19.471160957, 20.15636011, 20.569508265, 20.099942995, 19.931865615,
  19.772701281, 19.903967745, 20.611309409, 20.61150618, 19.930284972, 18.369167653, 16.7450966, 15.339537326,
  14.307049804, 13.738519986, 13.432839311, 13.589574347, 14.214377063, 15.023930986, 17.279017769, 19.102972464,
  19.834943293, 19.846961599, 19.848774742, 20.31104909, 20.907097356, 20.975269146, 20.621806169, 20.541339845,
  20.429647802, 20.648236989, 21.294727074, 21.183155962, 20.530248511, 18.747099544, 16.833236488, 15.433444304,
  14.413137901, 13.829574847, 13.466263235, 13.338997465, 13.728515922, 14.444664278, 16.771111548, 18.582030633,
  19.459846576, 19.565036259, 18.991348342, 19.267802982, 19.429213868, 19.795965901, 19.362532471, 19.357327859,
  19.235179994, 19.611781444, 20.44071332, 20.487347201, 19.726851849, 17.852740903, 16.192758528, 14.879111367,
  13.872449243, 13.259391924, 12.822560758, 12.865224451, 13.202613182, 13.88445157, 16.173675929, 17.984383548,
  18.51525227, 18.41434945, 18.142685969, 18.411364277, 18.467555815, 18.953579087, 18.621425079, 18.884270987,
  19.043750875, 19.216722642, 20.287747049, 20.440742828, 19.814103531, 18.116170375, 16.407114673, 14.98069276,
  13.917028045, 13.352071388, 13.073916047, 13.079697334, 13.453211735, 14.298138307, 16.408138372, 18.299968482,
  18.854833761, 18.667892007, 18.320608902, 18.656361769, 18.798429771, 18.928108866, 18.87567702, 19.022133654,
  19.057808515, 19.220019721, 20.042266726, 20.14752122, 19.338749095, 17.755353946, 16.103790821, 14.871307578,
  13.833812831, 13.214774055, 12.948076588, 12.831195561, 12.962307446, 13.076046251, 13.663012434, 15.13851356,
  16.080660017, 16.521625192, 16.641722169, 16.72094903, 16.768047759, 16.723462699, 16.432963097, 16.428576886,
  16.574886337, 16.81560879, 17.7123432, 17.724986026, 16.916088861, 15.724587068, 14.605637466, 13.347063038,
  12.404146521, 11.737988084, 11.468612577, 11.39168079, 11.547810402, 11.603881803, 11.89837926, 12.944452649,
  13.837979997, 14.472397147, 14.770403127, 14.940398775, 15.06560768, 15.139230304, 15.028788111, 14.816092092,
  14.923309718, 15.405106191, 16.670760854, 16.98976056, 16.423240078, 15.354546304, 14.389411579, 13.322831537,
  12.571737106, 12.191330224, 12.024670553, 12.037544789, 12.546263323, 13.430792007, 16.271612937, 18.433002302,
  19.503308542, 19.523550055, 19.490193692, 19.627023689, 19.813101548, 20.036252461, 19.942117259, 19.830869975,
  19.61339046, 19.707027643, 20.498989914, 20.624312143, 19.806717243, 18.201447068, 16.58994646, 15.202344864,
  14.163074798, 13.554281986, 13.285213662, 13.319342658, 13.736976649, 14.307428752, 16.771170563, 18.889811214,
  20.00854056, 20.004809419, 19.932660264, 20.40576853, 20.549143721, 20.697691194, 20.3504024, 20.039731396,
  19.981225467, 20.131309909, 20.814342519, 21.086945393, 20.238339855, 18.678766315, 17.049228427, 15.511359531,
  14.455252056, 13.949362907, 13.625390684, 13.566056503, 14.025088632, 14.733440381, 17.214735692, 19.115531879,
  19.796284182, 19.77878262, 19.419223348, 19.43062482, 19.538089524, 19.907302232, 19.627105491, 19.637230666,
  19.752876743, 19.902336524, 20.819555057, 20.983432523, 20.294600657, 18.592656266, 16.904391137, 15.656414455,
  14.616404984, 14.052780368, 13.852473117, 13.837460296, 14.090250673, 14.69722777, 17.029646767, 19.065764729,
  19.85513132, 19.755456245, 19.636665028, 19.896506716, 20.211971247, 20.201675312, 20.483929302, 20.584629302,
  20.546629302, 20.299341626, 20.913432855, 21.209448241, 20.296682606, 18.735035618, 17.0958287, 15.741078122,
  14.842704338, 14.321812594, 13.980604829, 13.942487647, 14.333240316, 14.915994504, 17.129305759, 19.136553251,
  19.867402178, 19.939551942, 19.870937587, 20.245752519, 20.271702889, 20.482029302, 20.354729302, 20.355679302,
  20.021580581, 19.990606965, 20.553692282, 20.689845653, 20.028681253, 18.2171562, 16.627751233, 15.372277588,
  14.224490778, 13.631434695, 13.178976154, 13.013885071, 13.174043622, 12.995272305, 13.67176573, 14.892837883,
  16.146422057, 16.691755991, 16.921823016, 17.013266068, 17.012329223, 16.983613136, 16.946363584, 16.420847731,
  16.54816722, 16.642626164, 17.198630803, 17.606269509, 16.963939993, 15.711647271, 14.422554941, 13.153682923,
  12.211689882, 11.395635684, 11.118372073, 11.074226299, 11.145652163, 11.267077946, 11.733932058, 12.533687797,
  13.632013585, 14.506650597, 15.351280953, 15.546897189, 15.258273623, 14.850039701, 14.537794917, 14.434327549,
  14.626021819, 14.719754449, 16.114609926, 16.327951955, 15.408358595, 14.321838027, 13.095454231, 11.991956192,
  11.341602741, 10.974212801, 10.897612033, 11.265709978, 12.316643032, 14.891981863, 17.074849963, 18.287055391,
  18.689829547, 18.718698365, 19.190198212, 19.292691866, 19.209025633, 18.923616983, 18.763720128, 18.478918018,
  18.258617919, 18.364384618, 19.32959701, 19.380475317, 17.729998443, 16.001091751, 14.564128088, 13.197448509,
  12.389655499, 11.919290988, 11.700187638, 11.948003489, 12.899443404, 15.139525078, 17.164991695, 17.922524384,
  18.207396775, 18.138843124, 18.324246051, 18.382091227, 18.568220702, 18.451081306, 18.280112362, 18.284650188,
  18.165484869, 18.429403229, 19.23487526, 19.462051857, 17.880364253, 16.278674766, 14.803237886, 13.529189849,
  13.026575722, 12.663992529, 12.478666428, 12.861497959, 13.842401608, 16.138801794, 18.001022302, 18.964755361,
  18.683887491, 18.147041367, 17.954731768, 17.689679786, 17.607569137, 17.636427401, 17.71789115, 18.067139509,
  18.510116469, 18.62385308, 19.705711024, 19.73651989, 18.258900704, 16.635624915, 15.274364879, 13.977598474,
  13.333369645, 13.011279793, 13.040150607, 13.409623996, 14.320681597, 16.665285239, 18.619218602, 19.725251128,
  19.877040429, 19.592276849, 19.709968548, 19.619617694, 20.347283786, 20.028094303, 20.061737604, 19.955532377,
  19.806035425, 19.665256688, 20.252302466, 20.192950889, 18.621081584, 17.017137543, 15.62940511, 14.462507282,
  13.86364908, 13.525514375, 13.525938503, 13.825890656, 14.637158991, 16.857289609, 18.626244137, 19.461704609,
  19.704551026, 19.529325239, 19.57998957, 19.888690116, 19.953078647, 20.073352056, 19.723448496, 19.842614837,
  19.640938961, 19.684942621, 20.305620866, 20.199518334, 18.756700626, 17.117486074, 15.719563391, 14.409682931,
  13.740506599, 13.144745135, 12.999294502, 13.014729403, 13.064175353, 13.624735847, 14.618743589, 16.020629466,
  16.852947977, 17.109483206, 17.329850364, 17.537304997, 17.396911257, 17.066722684, 16.935181485, 16.794325015,
  16.842040598, 16.986672687, 17.373551156, 17.290981743, 16.078859244, 14.855072727, 13.398483413, 12.230819086,
  11.424426335, 10.78583708, 10.628058438, 10.520347465, 10.763228358, 10.8566826, 11.745191866, 12.967533287,
  14.175069277, 14.938306609, 15.234537377, 15.634068606, 15.654483962, 15.419681647, 15.148234723, 15.034638415,
  15.078445373, 15.498436421, 16.163726915, 16.26060614, 15.240928592, 14.166485924, 12.886350179, 11.906791936,
  11.259723119, 10.994970325, 10.988513649, 11.268995434, 12.273864143, 14.95440986, 17.37213087, 18.422579052,
  18.369522635, 18.002745812, 18.271741529, 18.571097171, 18.660209331, 18.319734068, 18.692358752, 18.511427158,
  18.516900143, 18.518827369, 19.25183007, 19.235548964, 17.634333972, 16.02331277, 14.512086886, 13.226016224,
  12.436197492, 12.12448526, 12.043556153, 12.427121618, 13.306586437, 15.689633927, 17.770006711, 18.561174927,
  18.319423941, 18.452224764, 19.028737673, 19.159994016, 19.078239055, 18.864858227, 19.039041263, 18.99156912,
  18.794703813, 18.867690772, 19.641465793, 19.89917306, 18.189923517, 16.493883252, 15.045393029, 13.810431907,
  13.111531886, 12.739308675, 12.675860837, 12.963704155, 13.719431256, 15.933283027, 17.813867689, 18.798065724,
  18.471249302, 18.506776404, 18.72775792, 18.738391387, 18.830993935, 18.699855679, 18.788486942, 18.721537095,
  18.709154958, 18.851476639, 19.669721098, 19.844401073, 18.231379438, 16.433511072, 14.879671374, 13.548883877,
  12.766253045, 12.360237271, 12.363716129, 12.718443046, 13.386167723, 15.442705216, 17.538548096, 18.793460493,
  19.135033517, 19.074063329, 19.181551286, 19.521721046, 19.461786352, 19.262188198, 19.383638273, 19.363303034,
  19.295328534, 19.133334403, 19.677423309, 19.905721858, 18.426090063, 16.739344646, 15.16440006, 13.8692898,
  13.119971648, 12.730391325, 12.49579826, 12.832407365, 13.263698603, 14.9671725, 16.93107454, 18.475716182,
  19.479013582, 20.006500303, 20.546362398, 20.626293871, 20.470863388, 20.009866442, 19.756076571, 19.478380966,
  19.108738646, 18.779114481, 19.059063986, 19.125323334, 17.671228845, 15.927847913, 14.291312752, 12.93310862,
  12.095512117, 11.690696778, 11.426928912, 11.446036632, 11.547501773, 11.899751986, 13.120302946, 14.588486022,
  15.220940704, 15.291286226, 15.412978192, 15.570304343, 15.984364653, 15.967460871, 15.758465878, 15.611145172,
  15.515657466, 15.390313799, 15.608897869, 15.824803581, 14.870614715, 13.768808445, 12.380972836, 11.225587939,
  10.310513329, 9.874725975, 9.735266254, 9.74489566, 9.901563187, 9.90450254, 10.901616587, 12.252734961, 12.641375518,
  12.316176211, 12.18865595, 12.143111683, 12.27875023, 12.247430443, 12.073988685, 11.981978915, 12.077464201,
  12.459079459, 13.106017711, 13.70064451, 13.138613343, 12.497811109, 11.469120765, 10.64449758, 10.055948865,
  9.806904569, 9.692552346, 9.709777604, 9.807958446, 9.944772869, 10.818981949, 11.76512488, 12.403907332,
  12.543751161, 12.593316143, 12.341603617, 12.305493493, 12.16886246, 12.391451915, 12.60389001, 12.983178856,
  13.439396003, 14.129779426, 14.709496129, 13.982412393, 13.21019004, 11.968341647, 11.014787753, 10.38235374,
  10.231606783, 10.241007561, 10.553992249, 11.622819858, 14.18454081, 16.605088673, 18.007484843, 18.388891403,
  18.13073349, 18.245904384, 18.448264749, 18.37668794, 18.054185886, 18.159899506, 18.458301466, 18.600016598,
  18.645181348, 19.451184271, 19.802087113, 18.280494177, 16.580444249, 15.060408201, 13.875235769, 13.205189826,
  12.892765066, 12.840425993, 13.172118842, 13.967184068, 16.524477542, 18.694320084, 19.531419222, 19.758651795,
  19.663131087, 19.641722829, 19.835221198, 19.354585107, 18.942490148, 18.917906632, 18.796818701, 18.717795839,
  18.811982044, 19.404558355, 19.805635606, 18.24785234, 16.645108658, 15.104324302, 14.105217966, 13.421197618,
  13.087593278, 13.208159764, 13.629807377, 14.224187198, 16.510977963, 18.415147027, 19.260023391, 19.355216289,
  18.669799193, 18.940571324, 19.027407486, 19.025298003, 18.664675395, 19.280129893, 19.471044506, 19.553609323,
  19.53336794, 20.019198886, 20.231437303, 18.689059475, 17.069758215, 15.584344365, 14.444068289, 13.835098359,
  13.463673042, 13.486859566, 13.865022407, 14.418093252, 16.637001722, 18.66991859, 19.542046213, 19.363887533,
  18.665612623, 18.866535444, 19.028813567, 19.024175944, 18.7157618, 18.851429757, 18.741684908, 18.735270714,
  18.801698968, 19.242977382, 19.47228103, 18.047631166, 16.38480102, 15.15872685, 14.103613119, 13.445666192,
  12.971966432, 12.914726196, 13.104892229, 12.961333697, 13.483634034, 14.65124757, 16.043663974, 16.78926927,
  16.76547829, 16.994105002, 17.087634859, 17.172453013, 16.552168609, 16.209866745, 16.056442137, 16.271729059,
  16.451305582, 16.867283467, 17.259283395, 16.102363013, 14.939271545, 13.58671327, 12.589712316, 11.886965926,
  11.473742327, 11.312134235, 11.269431305, 11.141785767, 11.373041907, 12.276938673, 13.161978916, 14.044453913,
  14.255097295, 14.390583491, 14.327933005, 14.49227616, 14.414529021, 14.10639823, 14.036504889, 14.483705304,
  14.89666725, 15.633423884, 16.291558297, 15.462489774, 14.483239069, 13.298158201, 12.450619859, 11.82910537,
  11.620176942, 11.586763083, 12.035053786, 12.781090659, 15.495054516, 17.883729697, 19.029337549, 19.107576503,
  18.932891583, 19.345937242, 19.463594747, 19.559844882, 19.391752151, 19.433971761, 19.178027623, 18.947935997,
  18.913923723, 19.290074155, 19.644859343, 18.282034561, 16.59372661, 15.180554214, 14.034526854, 13.371360638,
  12.982772791, 12.997203491, 13.380462615, 14.020565024, 16.474597937, 18.450552548, 19.341000952, 19.403496299,
  18.976212203, 19.196331887, 19.318048574, 19.448932035, 19.173006272, 19.1909757, 19.217544617, 18.971987243,
  19.206042571, 19.609407079, 19.902665329, 18.368116278, 16.612460246, 15.1816216, 14.055294549, 13.248396747,
  13.008421136, 12.944549582, 13.173911635, 13.75204496, 16.13831413, 18.29025697, 19.302162192, 19.568575962,
  19.562868535, 19.819380439, 20.186661809, 20.009454583, 19.99938516, 19.759191841, 19.440251763, 19.442448299,
  19.541792443, 19.977160528, 20.262916972, 18.87940825, 17.090168596, 15.667133126, 14.435109681, 13.802350257,
  13.474667906, 13.507102511, 13.829022906, 14.365158978, 16.716006189, 18.738491396, 19.700627731, 19.601583632,
  19.232830008, 19.349924872, 19.373696888, 18.92958377, 18.712455392, 19.324077653, 18.943877814, 18.988631553,
  18.935820208, 19.447396727, 20.017007828, 18.668336267, 16.870732287, 15.488461054, 14.335752663, 13.692236258,
  13.280929859, 13.294374465, 13.604739568, 14.167240942, 16.368873504, 18.22264431, 19.070846016, 18.757758058,
  18.09825038, 18.280357526, 18.28869003, 18.058790993, 17.820299272, 18.356160943, 18.301081805, 18.458449525,
  18.545504285, 18.885414958, 19.477562046, 18.406694932, 16.710085753, 15.271059204, 14.268586468, 13.529925388,
  13.122161271, 13.049272212, 13.196009669, 13.080856411, 13.839328883, 15.106329394, 16.264241393, 16.455407812,
  16.306619494, 16.245833114, 15.913936259, 15.89221741, 15.62661148, 15.498303314, 15.641711056, 15.800717118,
  16.06375967, 16.422129422, 17.150230529, 16.326430109, 14.994674327, 13.716954277, 12.653711646, 11.904165867,
  11.479026728, 11.307929248, 11.297306408, 11.044417304, 11.437911325, 12.370578086, 13.443166827, 14.08235756,
  14.157555758, 14.151027807, 14.566379302, 14.160729302, 14.082829302, 14.441929302, 14.514129302, 14.510329302,
  15.145879302, 16.014179302, 16.807429302, 15.787295113, 14.774205898, 13.738748957, 12.886364037, 12.298936474,
  12.067720591, 12.169445999, 12.476326662, 13.205143139, 15.87749011, 17.895601211, 18.78463718, 18.721272256,
  18.18335345, 18.481329302, 18.567779302, 18.544979302, 18.749229302, 19.078879302, 19.021879302, 19.196679302,
  19.153929302, 19.601379302, 19.813600157, 18.527354455, 16.720380903, 15.246847955, 14.144084887, 13.536218604,
  13.126473849, 13.084526918, 13.372149689, 13.860379949, 16.272805552, 18.253904118, 19.204592322, 19.201184254,
  19.266343167, 19.715250447, 19.805860607, 19.777097772, 19.431420629, 19.392573802, 19.44557, 19.151361856,
  19.022893078, 19.259878632, 19.627559695, 18.300947711, 16.688229272, 15.140513358, 13.869064041, 12.997607693,
  12.524801474, 12.570775214, 12.757792614, 13.17454452, 15.675332533, 17.336641495, 17.966652243, 17.83393937,
  17.720031855, 18.436102719, 18.458398113, 18.426102773, 18.217387781, 18.132191819, 18.133341115, 18.328685241,
  18.271765456, 18.787784788, 19.331885721, 18.238310959, 16.496780185, 15.01917469, 13.804881686, 13.073193516,
  12.677611568, 12.580500948, 12.864469792, 13.211903143, 15.533275142, 17.461811792, 17.888997232, 17.813159723,
  18.135857353, 18.349273091, 18.940674913, 18.927735537, 18.558479396, 18.65488684, 18.550363009, 18.376023643,
  18.511410715, 19.018310844, 19.64188732, 18.670905651, 17.053239086, 15.533348332, 14.437988127, 13.715185375,
  13.312664065, 13.308583881, 13.640306023, 14.096794011, 16.472960062, 18.205951163, 18.561460631, 18.121945582,
  17.810416447, 17.649056586, 17.891578625, 17.679465428, 17.476787786, 17.761630277, 18.119635427, 18.215664369,
  18.39399725, 18.811957356, 19.102869528, 17.983031093, 16.396378793, 14.863891798, 13.786178565, 13.124386986,
  12.76597631, 12.698871991, 12.640695458, 12.401289123, 13.268614843, 14.443816819, 15.562477019, 16.113488298,
  15.987559017, 16.273508053, 16.167761001, 16.075502648, 16.115532508, 16.456673497, 16.603080362, 16.262937969,
  16.142622474, 16.34426535, 16.37661313, 15.696992879, 14.522949791, 13.16633242, 12.112712446, 11.339422228,
  10.883937445, 10.73804618, 10.698700478, 10.398749874, 10.926357036, 11.853628272, 12.681354424, 13.279953179,
  13.474589504, 14.211941918, 14.600829281, 14.23635174, 13.799722281, 12.951296797, 12.975044775, 13.251291458,
  13.676227092, 14.030250375, 14.667950225, 14.346747449, 13.387773563, 12.180521578, 11.311612552, 10.708015166,
  10.430762177, 10.318876652, 10.372513741, 10.130299831, 10.415119165, 11.317519091, 12.066327155, 12.353118696,
  12.36670943, 12.189138843, 12.015007659, 12.049322227, 12.174723897, 12.287137652, 12.557830473, 13.077521641,
  13.47615813, 14.066726747, 14.568664934, 14.282948751, 13.256990769, 12.110736159, 11.167002002, 10.605545936,
  10.36775659, 10.278795044, 10.371227396, 10.619952484, 11.88637273, 13.124863455, 14.173817949, 14.567443773,
  14.373975628, 14.530216284, 14.920419903, 15.622779302, 15.813729302, 16.027479302, 16.017029302, 16.053129302,
  15.665228882, 15.720649907, 16.053046584, 15.520373278, 14.305169441, 13.034958743, 11.965867894, 11.273494957,
  10.858718777, 10.656246798, 10.570746525, 10.189972757, 10.678673874, 11.658957498, 12.748067186, 13.472967123,
  13.553666826, 13.598757722, 13.601428959, 13.708467068, 13.56083602, 13.537451024, 13.602913928, 13.762875761,
  14.064819871, 14.592428099, 15.179886675, 14.836505864, 13.788482709, 12.492743214, 11.528208646, 11.142773341,
  10.994320737, 11.005403803, 11.196782257, 11.70625599, 14.143082035, 16.217906814, 16.900154234, 16.60440615,
  16.330948164, 16.590545885, 16.67946509, 16.833539722, 16.599358562, 16.91006016, 17.120420214, 17.296840441,
  17.567389949, 17.947087491, 18.45977983, 17.64318843, 16.029001683, 14.465146832, 13.299334085, 12.645208464,
  12.41870385, 12.393821631, 12.501261764, 12.81447446, 14.881353689, 16.547086643, 17.178093203, 17.324986578,
  17.121174459, 17.221614241, 17.019486436, 17.364493121, 17.345502467, 17.520526762, 17.378433407, 17.522199737,
  17.6016866, 17.72461535, 18.043739821, 17.342772206, 15.926628188, 14.547966159, 13.237356977, 12.627717489,
  12.236126078, 12.008681962, 11.850876774, 11.596244822, 12.54880271, 13.650955928, 14.774070236, 15.695450845,
  16.360807555, 16.311574321, 16.664597978, 16.72774905, 16.506843736, 16.469026426, 16.41596027, 16.259729167,
  16.055022419, 16.168347433, 16.26562265, 15.71722097, 14.453190304, 13.166486256, 11.97871174, 11.205902425,
  10.732549915, 10.531648705, 10.288177586, 10.058611092, 10.544197992, 11.385017528, 12.467006626, 13.195396155,
  13.48397951, 13.936777644, 14.179761681, 14.251183585, 14.135008497, 14.009774293, 14.042036985, 14.399607105,
  14.647212821, 14.868536873, 15.25988189, 15.065536854, 14.116973682, 12.972410991, 11.881791342, 11.430011823,
  11.229354749, 11.365257104, 11.617530196, 12.191603761, 15.137526755, 17.336396224, 18.204705329, 18.010196619,
  17.48934217, 17.816213549, 18.175152164, 17.836040551, 17.858960535, 18.228053959, 18.398795244, 18.395047843,
  18.500127288, 18.70204253, 19.080374547, 18.416718691, 16.778872462, 15.253807147, 14.153619626, 13.621752783,
  13.165736197, 13.147417136, 13.236589376, 13.738737822, 16.151508325, 18.117692518, 18.575063218, 18.122342343,
  17.561270598, 17.599441559, 17.891612492, 17.971096291, 17.871765585, 18.248155131, 18.221601782, 18.291876501,
  18.385708952, 18.720410374, 19.189500975, 18.519464449, 16.856856597, 15.27715129, 14.153214793, 13.46329207,
  13.145704477, 13.144873711, 13.321999898, 13.802763726, 16.249169215, 18.112383613, 18.580559255, 18.283569891,
  17.701082593, 17.884680873, 17.594355376, 17.475021247, 17.774529302, 18.274229302, 18.349279302, 18.702679302,
  18.881279302, 18.901725895, 19.425084219, 18.777856373, 17.059117159, 15.524935752, 14.423960601, 13.794702471,
  13.445031521, 13.473191871, 13.500142607, 13.979614771, 16.271966429, 18.107274384, 18.614451842, 18.448679321,
  17.683574618, 18.148271749, 18.515678667, 18.759679302, 18.819529302, 18.918329302, 19.223279302, 19.223279302,
  19.220429302, 19.510179302, 19.669995886, 18.936380692, 17.313276386, 15.740061475, 14.547661364, 13.877757449,
  13.508267372, 13.423206471, 13.519810334, 13.990482204, 16.211754558, 18.361280389, 18.984893403, 18.753274875,
  17.700503874, 17.93446205, 18.202979302, 18.443329302, 18.459479302, 18.637129302, 18.537379302, 18.633329302,
  18.836629302, 19.210929302, 19.523479302, 18.654238229, 17.150523083, 15.661082158, 14.462458383, 13.726579815,
  13.25780201, 13.153243496, 12.952155263, 12.775922701, 13.499749847, 14.746520653, 15.959129311, 16.404370148,
  16.368216268, 15.971210077, 15.986629302, 16.040779302, 15.958129302, 16.179479302, 16.028429302, 16.213679302,
  16.290629302, 16.553779302, 17.118079302, 16.5608859, 15.469977257, 14.13744054, 13.018240303, 12.306856644,
  11.810926242, 11.643757591, 11.441976752, 11.090926117, 11.487297257, 12.568185287, 13.545552622, 14.025439168,
  14.149695752, 14.096129302, 14.026779302, 14.262379302, 14.137929302, 14.188279302, 14.362129302, 14.565429302,
  15.229479302, 15.768129302, 16.194679302, 16.056929302, 14.76366182, 13.638336304, 12.675975247, 12.068568594,
  11.88731745, 11.866405366, 12.045197329, 12.754519565, 15.667994126, 17.825802046, 18.920921727, 19.443837169,
  19.438198457, 19.868859681, 19.859017731, 20.043064994, 20.050729302, 19.761929302, 19.591879302, 19.343929302,
  19.447479302, 19.750529302, 19.48940087, 18.694068464, 16.964338931, 15.397396923, 14.328095301, 13.583805151,
  13.239082475, 13.128861101, 13.310053107, 13.794605639, 16.168058338, 18.046582959, 18.813844617, 18.869672375,
  18.67646573, 18.955907768, 19.414755925, 19.749579302, 19.520629302, 19.824629302, 19.607079302, 19.557679302,
  19.698279302, 19.642677847, 19.716142372, 18.820246945, 17.105063463, 15.492252492, 14.327653867, 13.625644684,
  13.194991328, 13.107237061, 13.122105346, 13.565089484, 16.131751734, 18.159419868, 19.228479681, 19.555058427,
  19.47443046, 19.742267622, 19.928953815, 19.803171743, 19.495030367, 19.425991694, 19.456907617, 19.294668202,
  19.151391968, 19.356963087, 19.328615609, 18.555531768, 16.907876945, 15.388251644, 14.270250394, 13.622061922,
  13.17528682, 13.065884266, 13.056614273, 13.55653497, 16.095660594, 17.998531419, 18.733792102, 18.603320593,
  18.489785395, 18.783299964, 18.801789815, 18.816826689, 18.769038398, 18.86509243, 18.989053464, 18.931083394,
  19.007607717, 19.266219654, 19.26647988, 18.525786692, 16.859696245, 15.361328913, 14.124755664, 13.429800575,
  13.020764614, 12.975146887, 12.959320831, 13.583296847, 16.16198037, 18.224789228, 19.269622207, 19.658166953,
  19.727922013, 20.084949913, 20.478926467, 20.305141675, 19.95909076, 19.958419073, 19.782643955, 19.696998817,
  19.388887654, 19.224690433, 19.226786854, 18.462981177, 16.886863132, 15.620893389, 14.414806571, 13.710214135,
  13.22010512, 13.093229612, 12.883685952, 12.905211861, 13.88148305, 15.189987535, 16.489302109, 17.306997333,
  18.109879302, 17.795429302, 17.264379302, 16.654479302, 15.891629302, 15.947679302, 15.749129302, 15.848879302,
  16.211779302, 16.465429302, 16.591779302, 16.326729302, 15.372929302, 14.042929302, 12.629547812, 11.861157319,
  11.394604931, 11.247623546, 11.036059519, 11.123492051, 11.538072765, 12.778479302, 13.665779302, 14.174979302,
  14.152179302, 14.142679302, 14.036279302, 14.059079302, 13.984029302, 13.828229302, 13.782629302, 14.326979302,
  14.740229302, 15.245629302, 15.545829302, 15.745329302, 14.884629302, 13.757929302, 12.938079302, 12.104750206,
  11.956852032, 11.915913282, 12.162168678, 12.798143586, 15.820563619, 18.050665237, 18.989453894, 19.038029302,
  18.580129302, 18.671329302, 18.599129302, 18.584879302, 18.468029302, 18.548779302, 18.771079302, 18.950629302,
  18.932579302, 19.188129302, 19.374329302, 18.889829302, 17.244429302, 15.700679302, 14.304900406, 13.743440449,
  13.405908836, 13.445579106, 13.294691859, 13.91223845, 16.380570904, 18.379265529, 19.232231745, 19.445579302,
  19.047529302, 19.170079302, 19.424679302, 19.732479302, 19.278379302, 19.194779302, 19.688779302, 19.617529302,
  19.504479302, 19.755279302, 19.943379302, 19.350579302, 17.785929302, 16.127229302, 14.605853752, 13.876538615,
  13.458532431, 13.419137903, 13.32701782, 13.912734246, 16.32555163, 18.289950985, 19.221101503, 19.473435065,
  19.758129302, 19.946229302, 19.836979302, 19.499729302, 19.355329302, 19.423729302, 19.284079302, 19.285029302,
  19.353429302, 19.669779302, 19.792329302, 19.194779302, 17.680479302, 15.885083671, 14.71099681, 14.027099349,
  13.623686748, 13.463530047, 13.466556965, 14.009598346, 16.442930278, 18.449781186, 19.310996197, 19.472850301,
  19.527279302, 19.604229302, 19.651729302, 19.618479302, 19.106429302, 19.119729302, 19.119729302, 19.042779302,
  19.105479302, 19.394279302, 19.625129302, 19.176729302, 17.380585759, 15.877340236, 14.570978786, 13.889489205,
  13.48621385, 13.425588593, 13.290293197, 13.894117089, 16.30225707, 18.142732558, 18.86535996, 18.678776805,
  18.105045746, 18.505079302, 18.722629302, 18.596279302, 18.405329302, 18.332179302, 18.378729302, 18.441429302,
  18.221979302, 18.575379302, 18.577279302, 17.954054522, 16.695930099, 15.249991899, 14.04196697, 13.336169382,
  12.900028002, 12.755243116, 12.361106038, 12.313856195, 13.180675594, 14.34561546, 15.425755295, 15.756341909,
  15.546142993, 15.406291538, 15.118844483, 15.047867425, 14.753187299, 15.273179302, 15.357729302, 15.515429302,
  15.716829302, 15.528355177, 15.646388013, 15.616225998, 14.756166143, 13.34372158, 12.203007262, 11.449091993,
  10.926081618, 10.758125844, 10.399823064, 10.262114138, 10.80859035, 11.690333026, 12.746074222, 13.264166247,
  13.400186624, 13.366649381, 13.556529302, 13.548929302, 13.472929302, 13.395979302, 13.574579302, 13.940329302,
  14.447629302, 14.953979302, 15.325429302, 15.201143523, 14.408992824, 13.270898407, 12.315929026, 11.979842511,
  11.700122233, 11.631343784, 11.727447187, 12.504590126, 15.285079056, 17.479971869, 18.459890136, 18.455028122,
  17.911235493, 18.387279302, 18.368279302, 18.554479302, 18.226729302, 18.470879302, 18.441429302, 18.451879302,
  18.626679302, 19.065579302, 19.266029302, 18.866079302, 16.978302579, 15.407255268, 14.22998466, 13.51816714,
  13.104676353, 13.03180888, 12.966783686, 13.500138949, 15.976379682, 17.969392274, 18.727617541, 18.45117308,
  17.937655116, 18.464229302, 18.365429302, 18.228629302, 18.013929302, 18.226729302, 18.353079302, 18.393929302,
  18.435729302, 18.872729302, 19.029479302, 18.673229302, 17.260579302, 15.416246338, 14.233816254, 13.463664831,
  13.082130523, 13.040679194, 13.025573769, 13.613184633, 16.029377255, 18.048614792, 18.644918404, 18.368127203,
  18.204879302, 18.211529302, 18.089929302, 17.996829302, 17.867629302, 18.276129302, 18.558279302, 18.515529302,
  18.553529302, 18.978179302, 19.211879302, 18.971529302, 17.503779302, 15.963829302, 14.382121396, 13.679703846,
  13.358169315, 13.358081803, 13.247834908, 13.655084267, 15.996791327, 17.963271162, 19.026629302, 18.908829302,
  18.383479302, 18.423379302, 18.302729302, 18.348329302, 18.121279302, 18.456629302, 18.518379302, 18.563029302,
  18.528829302, 18.952529302, 19.177679302, 18.795779302, 17.499029302, 15.665428521, 14.305162633, 13.640384943,
  13.248632763, 13.093711725, 12.938915885, 13.361644332, 15.818574372, 17.607007655, 18.378692257, 18.460221457,
  18.281247097, 18.615786998, 18.567952598, 18.313010877, 18.264729302, 18.494629302, 18.358779302, 18.366379302,
  17.858550426, 17.942148987, 17.850481588, 17.483183334, 16.289523315, 14.884426542, 13.657353449, 12.869416621,
  12.404189554, 12.181228511, 11.81610815, 11.711569619, 12.546985489, 13.802221265, 14.75389534, 15.182235458,
  15.042565571, 14.927097481, 14.715747675, 14.641487307, 14.446019066, 14.460623801, 14.472352202, 14.756604805,
  14.997668352, 15.346051956, 15.497187627, 15.33962523, 14.418504808, 13.07554348, 11.994579452, 11.312605894,
  10.863185352, 10.599091028, 10.247143848, 10.148414737, 10.686142511, 11.73698564, 12.653791411, 13.126464681,
  13.277208151, 13.418351628, 13.662929302, 13.717079302, 13.453929302, 13.394079302, 13.520429302, 13.943179302,
  14.387779302, 15.038529302, 15.428979302, 15.577179302, 14.734673224, 13.583177846, 12.597623335, 12.085991068,
  11.851739018, 11.805151503, 11.744002378, 12.609793243, 15.359910602, 17.485245284, 18.387960457, 18.677979302,
  18.117479302, 18.221029302, 18.221979302, 18.112729302, 17.772629302, 18.457579302, 18.682729302, 18.758729302,
  18.859429302, 19.247029302, 19.218529302, 18.691279302, 17.291929302, 15.800429302, 14.637629302, 13.949829302,
  13.316152516, 13.207471772, 13.265730809, 13.939264439, 16.711479302, 18.508879302, 19.141579302, 19.159629302,
  18.620029302, 18.800529302, 18.985779302, 19.061779302, 19.053229302, 19.103579302, 19.034229302, 19.093129302,
  19.329679302, 19.556729302, 19.517779302, 18.978179302, 17.654829302, 16.159529302, 15.005279302, 14.339329302,
  13.973579302, 13.858629302, 13.793079302, 14.426729302, 16.804579302, 18.712179302, 19.356279302, 19.319229302,
  18.717879302, 19.031379302, 19.101679302, 19.133029302, 18.982929302, 19.071279302, 19.043729302, 19.114029302,
  19.091229302, 19.149179302, 19.067479302, 18.594379302, 17.443929302, 15.869779302, 14.673729302, 13.975479302,
  13.543229302, 13.238279302, 12.812679302, 12.378529302, 12.468779302, 13.260129302, 14.115129302, 14.382079302,
  14.272829302, 14.268079302, 14.364979302, 14.296579302, 14.317479302, 14.288029302, 14.432429302, 14.593929302,
  14.931179302, 15.295029302, 15.572429302, 15.827979302, 15.199079302, 13.954579302, 12.922879302, 12.442179302,
  12.193279302, 12.068829302, 11.957679302, 12.467829302, 14.116079302, 15.583829302, 16.560429302, 16.849229302,
  16.837829302, 16.829279302, 17.130429302, 17.461979302, 17.346079302, 17.523729302, 17.477179302, 17.438229302,
  17.448679302, 17.529429302, 17.322329302, 17.142779302, 16.319129302, 14.877979302, 13.822529302, 13.150879302,
  12.697729302, 12.440279302, 12.160979302, 12.215129302, 13.041629302, 14.054329302, 15.072729302, 15.705429302,
  15.586679302, 15.420429302, 15.725379302, 15.546779302, 15.010979302, 15.039479302, 15.352979302, 15.797579302,
  15.747229302, 15.750079302, 15.807079302, 15.673129302, 15.105029302, 13.872879302, 12.821229302, 12.063129302,
  11.582429302, 11.338279302, 10.683692092, 10.575122973, 11.388629302, 12.165729302, 12.988429302, 13.369379302,
  13.707579302, 13.553679302, 13.580279302, 13.599279302, 13.420679302, 13.525179302, 13.633479302, 13.962179302,
  14.460929302, 14.902679302, 15.131629302, 15.234229302, 14.948279302, 13.857679302, 12.844979302, 11.976746796,
  11.677899771, 11.629227271, 11.539870086, 12.397804338, 15.202246667, 17.293064488, 18.251887956, 18.684629302,
  18.083279302, 18.221029302, 18.133629302, 18.244779302, 18.204879302, 18.418629302, 18.428129302, 18.501279302,
  18.621929302, 18.939229302, 19.062729302, 18.601029302, 17.055433049, 15.433664459, 14.115138606, 13.492952817,
  13.143005409, 13.028645602, 13.0176223, 13.451647122, 15.757828257, 17.587679432, 18.330605789, 18.735816184,
  18.532698605, 18.850487162, 19.174196063, 19.094834128, 19.093129302, 19.306879302, 19.086479302, 19.038979302,
  19.048479302, 19.379079302, 19.0127302, 18.397735521, 17.192012319, 15.684084904, 14.360376537, 13.702192561,
  13.364005387, 13.275904687, 13.252240522, 13.771215349, 16.073460489, 18.0298355, 19.028988831, 19.454967137,
  19.608200252, 20.130529302, 20.221729302, 19.722029302, 19.532979302, 19.364829302, 19.257479302, 19.044679302,
  19.036129302, 19.277429302, 19.283129302, 18.715979302, 17.497129302, 15.72951826, 14.565646604, 13.986264868,
  13.598733668, 13.483974968, 13.393404004, 14.004641517, 16.4624459, 18.381407431, 19.206593292, 19.400431847,
  19.293579302, 19.764779302, 19.400929302, 19.287879302, 18.925929302, 19.209029302, 19.407579302, 19.326829302,
  19.160579302, 19.492129302, 19.720129302, 19.247979302, 17.959779302, 16.492029302, 15.005309423, 14.317408227,
  13.953257312, 13.789127726, 13.663904042, 14.156586135, 16.42739894, 18.274608335, 19.087584485, 19.504479302,
  19.449379302, 19.588079302, 19.776179302, 19.525379302, 19.328729302, 19.490229302, 19.525379302, 19.351529302,
  19.156779302, 19.337279302, 19.243229302, 18.667529302, 17.551279302, 16.312479302, 15.157279302, 14.083778064,
  13.633320328, 13.413451244, 13.038804576, 12.986737973, 13.687940556, 14.983292777, 16.470179302, 17.235879302,
  17.388829302, 17.651029302, 17.650079302, 17.046829302, 16.507229302, 16.199429302, 16.244079302, 16.300129302,
  16.271629302, 16.247879302, 16.202279302, 16.116779302, 15.562929302, 14.351679302, 13.387429302, 12.385293258,
  11.904776437, 11.604454375, 11.231945907, 11.265616757, 11.609029027, 12.408188541, 13.545129302, 14.193029302,
  14.580629302, 15.075579302, 15.275079302, 15.227579302, 14.820979302, 14.289929302, 14.114179302, 14.500829302,
  14.972979302, 15.398579302, 15.592379302, 15.705429302, 15.374829302, 14.223429302, 13.327579302, 12.832629302,
  12.639779302, 12.574229302, 12.584679302, 13.485279302, 16.188029302, 18.353079302, 19.266979302, 19.328729302,
  19.034229302, 19.165329302, 19.408529302, 19.488329302, 19.209029302, 19.604229302, 19.571929302, 19.581429302,
  19.465529302, 19.926279302, 19.780929302, 19.316379302, 18.037679302, 16.441679302, 15.215229302, 14.529329302,
  14.241479302, 14.262379302, 14.187329302, 14.661379302, 17.071529302, 19.007629302, 19.843629302, 20.012729302,
  19.612779302, 19.859779302, 19.859779302, 19.993729302, 20.016529302, 20.417429302, 20.346179302, 20.381329302,
  20.259729302, 20.375629302, 20.303429302, 19.721079302, 18.501279302, 16.900529302, 15.531579302, 14.852329302,
  14.540729302, 14.422929302, 14.212029302, 14.775379302, 17.175079302, 19.075079302, 20.073529302, 20.434529302,
  20.450679302, 21.161279302, 21.376929302, 21.058679302, 20.679629302, 20.522879302, 20.314829302, 20.371829302,
  20.302479302, 20.396529302, 20.220779302, 19.610879302, 18.405329302, 16.872979302, 15.732029302, 15.223779302,
  14.863729302, 14.754479302, 14.655679302, 15.181979302, 17.181729302, 19.055129302, 20.237879302, 20.273979302,
  20.049779302, 20.036479302, 20.267329302, 20.204629302, 20.180879302, 20.481079302, 20.365179302, 20.397479302,
  20.381329302, 20.618829302, 20.669179302, 20.128629302, 18.993379302, 17.271979302, 15.933429302, 15.157279302,
  14.846629302, 14.564479302, 14.436229302, 15.029979302, 17.233029302, 19.171979302, 20.401279302, 20.826879302,
  20.410779302, 20.438329302, 20.326229302, 20.313879302, 19.992779302, 20.026979302, 20.037429302, 19.855029302,
  19.626079302, 19.608029302, 19.405679302, 18.713129302, 17.592129302, 16.274479302, 15.281729302, 14.649029302,
  14.131279302, 13.879529302, 13.563179302, 13.486229302, 14.363079302, 15.472679302, 16.660179302, 17.268179302,
  17.536079302, 17.681429302, 17.614929302, 17.486679302, 17.098129302, 16.730479302, 16.602229302, 16.494879302,
  16.407479302, 16.284929302, 16.264029302, 15.951479302, 15.478379302, 14.440029302, 13.515679302, 12.773729302,
  12.372829302, 12.103029302, 11.782879302, 11.690729302, 12.232229302, 13.107179302, 14.002079302, 14.492279302,
  14.878929302, 14.927379302, 14.614829302, 14.827629302, 14.643329302, 14.898879302, 15.166779302, 15.302629302,
  15.538229302, 15.892579302, 16.000879302, 16.006579302, 15.770029302, 14.674679302, 13.770279302, 13.314279302,
  13.058729302, 12.971329302, 12.862079302, 13.465329302, 16.171879302, 18.461379302, 19.532979302, 19.903479302,
  19.661229302, 19.861679302, 19.940529302, 19.788529302, 19.502579302, 19.661229302, 19.758129302, 19.841729302,
  19.893979302, 20.003229302, 19.879729302, 19.240379302, 18.005379302, 16.616479302, 15.462229302, 14.974879302,
  14.590129302, 14.425779302, 14.211079302, 14.729779302, 16.898629302, 18.600079302, 19.047529302, 19.092179302,
  18.923079302, 19.414229302, 19.373379302, 19.055129302, 18.925929302, 18.924979302, 18.772029302, 18.730229302,
  18.616229302, 18.906929302, 19.000979302, 18.405329302, 17.294779302, 15.931529302, 14.621314075, 14.009370271,
  13.462108806, 13.338321106, 13.149240854, 13.521541846, 15.545270975, 17.286540895, 18.0830855, 18.10628002,
  18.356465503, 19.079829302, 19.223279302, 19.038029302, 18.604829302, 18.679879302, 18.847079302, 18.804329302,
  18.772979302, 19.079829302, 19.005729302, 18.301779302, 17.138979302, 15.922979302, 14.861829302, 14.289929302,
  13.658406046, 13.580596462, 13.553549555, 14.372579302, 16.678229302, 18.469929302, 19.219479302, 19.187179302,
  18.894579302, 18.880329302, 18.876529302, 19.087429302, 18.917379302, 19.097879302, 19.170079302, 19.192879302,
  19.077929302, 19.455079302, 19.507329302, 19.057029302, 18.085179302, 16.656379302, 15.463179302, 14.788679302,
  14.350729302, 14.155029302, 14.128429302, 14.650929302, 16.815979302, 18.418629302, 19.437029302, 19.867379302,
  19.721079302, 19.976629302, 20.170429302, 20.253079302, 19.770479302, 19.791379302, 19.813229302, 19.683079302,
  19.620379302, 19.513979302, 19.261279302, 18.513629302, 17.554129302, 16.254529302, 15.091729302, 14.487529302,
  14.036279302, 13.829179302, 13.548929302, 13.463429302, 14.209179302, 15.251329302, 16.358079302, 17.144679302,
  17.216879302, 17.085779302, 16.687729302, 16.790329302, 16.241229302, 16.389429302, 16.411279302, 16.357129302,
  16.232679302, 16.332429302, 16.264029302, 16.067379302, 15.508779302, 14.319379302, 13.195529302, 12.586579302,
  12.249329302, 12.034629302, 11.684079302, 11.555829302, 11.918729302, 12.587529302, 13.343729302, 14.010629302,
  14.035329302, 13.600229302, 13.897579302, 13.433029302, 13.400729302, 13.003629302, 12.852579302, 13.785479302,
  14.577779302, 15.146829302, 15.105979302, 15.188629302, 15.036629302, 13.967879302, 13.006479302, 12.451679302,
  12.246479302, 12.287329302, 12.298729302, 13.071079302, 15.439429302, 17.518029302, 18.497479302, 18.727379302,
  18.661829302, 18.734979302, 18.822379302, 18.920229302, 18.304629302, 18.591529302, 18.753029302, 18.852779302,
  19.107379302, 19.327779302, 19.325879302, 18.827129302, 17.806829302, 16.472079302, 15.151579302, 14.577779302,
  14.165479302, 14.092329302, 13.852929302, 14.218679302, 16.382779302, 18.191579302, 18.867979302, 19.025679302,
  18.705529302, 18.974379302, 19.008579302, 18.905029302, 18.820479302, 19.051329302, 19.155829302, 19.230879302,
  19.340129302, 19.665029302, 19.576679302, 18.999079302, 17.852429302, 16.485379302, 15.283629302, 14.732629302,
  14.339329302, 14.258579302, 14.157879302, 14.520779302, 16.549029302, 18.269479302, 19.191929302, 19.412329302,
  19.125429302, 19.284079302, 19.355329302, 19.776179302, 19.661229302, 19.952879302, 20.029829302, 19.847429302,
  19.813229302, 19.941479302, 19.718229302, 18.754929302, 17.560779302, 16.311529302, 15.228529302, 14.556879302,
  14.230079302, 14.078079302, 14.053379302, 14.419129302, 16.624079302, 18.530729302, 19.570029302, 19.752429302,
  19.867379302, 20.101079302, 20.027929302, 19.717279302, 19.240379302, 19.508279302, 19.497829302, 19.275529302,
  19.504479302, 19.805629302, 19.868329302, 19.046579302, 17.897079302, 16.545229302, 15.327329302, 14.656629302,
  14.326029302, 14.230079302, 14.124629302, 14.841879302, 17.055379302, 18.975329302, 19.683079302, 19.666929302,
  19.350579302, 19.591879302, 19.659329302, 19.561479302, 19.030429302, 19.224229302, 19.458879302, 19.398079302,
  19.330629302, 19.434179302, 19.328729302, 18.751129302, 17.758379302, 16.406529302, 15.166779302, 14.383029302,
  13.972629302, 13.718029302, 13.466279302, 13.355129302, 14.174979302, 15.362479302, 16.621229302, 17.081029302,
  17.070579302, 16.882479302, 16.822629302, 16.606029302, 16.249779302, 16.226029302, 16.185179302, 16.468279302,
  16.617429302, 16.710529302, 16.535729302, 16.231729302, 15.586679302, 14.363079302, 13.236379302, 12.669229302,
  12.248379302, 12.034629302, 11.749629302, 11.614729302, 12.126779302, 13.020729302, 13.957429302, 14.558779302,
  14.560679302, 14.412479302, 14.423879302, 14.548329302, 14.419129302, 14.597729302, 14.635729302, 15.070829302,
  15.354879302, 15.689279302, 15.857429302, 15.924879302, 15.501179302, 14.405829302, 13.393129302, 12.759479302,
  12.604629302, 12.510579302, 12.535279302, 13.214529302, 15.877379302, 18.032929302, 19.237529302, 19.663129302,
  19.524429302, 19.761929302, 19.944329302, 20.090629302, 20.205579302, 20.219829302, 20.163779302, 19.924379302,
  19.920579302, 19.997529302, 19.943379302, 19.360079302, 18.057629302, 16.372329302, 15.461279302, 14.864679302,
  14.307029302, 14.292779302, 14.264279302, 14.709829302, 16.904329302, 18.696029302, 19.502579302, 19.513979302,
  19.513029302, 19.509229302, 19.719179302, 19.725829302, 19.418029302, 19.605179302, 19.621329302, 19.884479302,
  19.790429302, 19.917729302, 19.935779302, 19.414229302, 18.183979302, 16.777979302, 15.413779302, 14.696529302,
  14.360229302, 14.139829302, 14.090429302, 14.565429302, 16.733329302, 18.629529302, 19.602329302, 19.881629302,
  20.330029302, 20.607429302, 20.910479302, 21.173629302, 20.863929302, 20.854429302, 20.385129302, 20.102029302,
  19.865479302, 20.079229302, 20.033629302, 19.539629302, 18.290379302, 16.864429302, 15.716829302, 15.048979302,
  14.682279302, 14.648079302, 14.550229302, 14.963479302, 17.214029302, 19.059879302, 20.168529302, 20.398429302,
  20.286329302, 20.874379302, 21.405429302, 21.214479302, 20.559929302, 20.172329302, 19.817979302, 19.458879302,
  19.199529302, 19.499729302, 19.364829302, 18.973429302, 17.727979302, 16.387529302, 15.257029302, 14.687979302,
  14.325079302, 14.213929302, 13.998279302, 14.327929302, 16.416979302, 18.301779302, 19.180529302, 19.329679302,
  19.189079302, 19.001929302, 19.029479302, 19.230879302, 19.173879302, 19.369579302, 19.469329302, 19.424679302,
  19.332529302, 19.456029302, 19.278379302, 18.827129302, 17.773579302, 16.444529302, 15.238029302, 14.499879302,
  13.859579302, 13.782629302, 13.470079302, 13.240179302, 13.993529302, 15.134479302, 16.362829302, 16.892929302,
  16.915729302, 16.828329302, 16.678229302, 16.599379302, 16.324829302, 16.503429302, 16.743779302, 16.926179302,
  17.154179302, 17.217829302, 17.090529302, 16.777979302, 16.108229302, 14.886529302, 13.733229302, 12.958029302,
  12.577079302, 12.300629302, 12.106829302, 11.807579302, 12.284479302, 13.176529302, 14.098029302, 14.631929302,
  14.738329302, 15.119279302, 15.484079302, 15.389079302, 15.332079302, 15.350129302, 15.623729302, 15.704479302,
  15.993279302, 16.280179302, 16.284929302, 16.430279302, 15.988529302, 14.863729302, 13.871929302, 13.473879302,
  13.298129302, 13.224029302, 13.310479302, 13.813979302, 16.244079302, 18.523129302, 19.877829302, 20.363279302,
  20.123879302, 20.259729302, 20.297729302, 20.848729302, 20.615029302, 20.389879302, 20.197979302, 19.678329302,
  19.580479302, 19.889229302, 19.855029302, 19.330629302, 17.921779302, 16.521479302, 15.247529302, 14.688929302,
  14.405829302, 14.157879302, 14.098029302, 14.312729302, 16.368529302, 18.185879302, 18.962029302, 19.133029302,
  18.751129302, 18.771079302, 18.813829302, 18.854679302, 18.547829302, 18.725479302, 18.865129302, 18.953479302,
  19.108329302, 19.279329302, 19.406629302, 19.105479302, 17.932229302, 16.517679302, 15.381479302, 14.751629302,
  14.307029302, 14.229129302, 14.169279302, 14.473279302, 16.492979302, 18.163079302, 19.183379302, 19.440829302,
  19.387629302, 19.660279302, 19.846479302, 19.988979302, 19.793279302, 19.558629302, 19.768579302, 19.656479302,
  19.402829302, 19.565279302, 19.610879302, 19.213779302, 17.962629302, 16.521479302, 15.403329302, 14.755429302,
  14.410579302, 13.954931638, 13.816837415, 14.240895123, 16.411004828, 18.08362653, 19.271729302, 19.392379302,
  19.389529302, 19.628929302, 19.509229302, 19.109279302, 18.698879302, 19.169129302, 19.072229302, 18.872729302,
  18.948729302, 19.121629302, 19.170079302, 18.778679302, 17.496179302, 16.206079302, 15.006229302, 14.040794591,
  13.665962719, 13.566449854, 13.587588524, 13.854202401, 15.897071732, 18.070929302, 19.057029302, 19.153929302,
  19.020929302, 19.345829302, 19.432279302, 19.161529302, 18.985779302, 19.128279302, 19.065579302, 19.087429302,
  18.987679302, 19.000979302, 18.959179302, 18.372079302, 17.098129302, 15.892579302, 14.891279302, 14.188279302,
  13.385698473, 13.210151127, 12.918099747, 12.707709228, 13.415529453, 14.765879302, 15.723479302, 16.083529302,
  16.313429302, 16.363779302, 15.851729302, 16.120579302, 15.756729302, 15.754829302, 15.616129302, 15.794729302,
  15.898279302, 15.974279302, 16.031279302, 16.074979302, 15.275079302, 14.082829302, 12.954229302, 12.329129302,
  11.954829302, 11.861729302, 11.474129302, 11.203379302, 11.611879302, 12.311079302, 13.224979302, 14.203479302,
  14.382079302, 14.405829302, 14.534079302, 14.625279302, 14.515079302, 14.495129302, 14.462829302, 14.734529302,
  15.286479302, 15.460329302, 15.694029302, 15.900179302, 15.107879302, 14.092329302, 13.179379302, 12.615079302,
  12.427929302, 12.363329302, 12.483029302, 12.957079302, 15.278879302, 17.188379302, 18.429079302, 18.981029302,
  18.869879302, 18.977229302, 19.337279302, 19.335379302, 19.114029302, 19.364829302, 19.537729302, 19.585229302,
  19.387629302, 19.516829302, 19.266979302, 18.742579302, 17.368879302, 16.037929302, 14.936879302, 14.390629302,
  13.909929302, 13.770279302, 13.722779302, 14.038179302, 15.958129302, 17.679529302, 18.788179302, 18.886979302,
  18.574429302, 19.261279302, 19.655529302, 19.928179302, 19.679279302, 19.613729302, 19.488329302, 19.327779302,
  19.109279302, 19.007629302, 18.898379302, 18.427179302, 17.077229302, 15.691179302, 14.668029302, 14.021079302,
  13.340029001, 13.383838092, 13.288319514, 13.497123378, 15.645121085, 17.375297346, 18.375599629, 18.746751803,
  19.167229302, 19.575729302, 19.704929302, 19.601379302, 19.344879302, 19.161529302, 18.845179302, 18.557329302,
  18.349279302, 18.480379302, 18.421479302, 18.036729302, 16.637379302, 14.930331495, 13.764212929, 13.152350825,
  12.790166123, 12.759421379, 12.711133624, 13.071555921, 15.025765163, 16.808159488, 17.746358449, 18.023748642,
  17.950279302, 18.098479302, 18.369229302, 18.208679302, 18.176379302, 18.437629302, 18.427179302, 18.106079302,
  18.135529302, 18.480379302, 18.582029302, 18.215329302, 16.789379302, 15.492629302, 14.194274227, 13.470766913,
  13.185343395, 12.989123523, 13.09707235, 13.499118212, 15.609119377, 17.151283079, 18.514579302, 19.208079302,
  19.158679302, 19.464579302, 19.210929302, 18.782479302, 18.482279302, 18.390129302, 18.277079302, 18.478479302,
  18.343579302, 18.430979302, 18.498429302, 18.118429302, 16.860629302, 15.555329302, 14.588229302, 13.976429302,
  13.616379302, 13.438729302, 13.211679302, 12.966579302, 13.689529302, 14.802929302, 15.904929302, 16.483479302,
  16.465429302, 16.608879302, 16.542379302, 16.362829302, 16.215579302, 16.204179302, 16.141479302, 16.375179302,
  16.490129302, 16.476829302, 16.435029302, 16.266879302, 15.359629302, 13.990679302, 13.009329302, 12.424129302,
  12.099229302, 11.990929302, 11.827529302, 11.457029302, 11.876929302, 12.584679302, 13.277229302, 13.866229302,
  14.239579302, 14.453329302, 14.582529302, 14.740229302, 14.416279302, 14.360229302, 14.569229302, 14.628129302,
  14.935929302, 15.288379302, 15.564829302, 15.492629302, 14.785829302, 13.657229302, 12.814579302, 12.288279302,
  12.123929302, 12.204679302, 12.386129302, 12.723379302, 15.002429302, 17.036379302, 17.822979302, 18.084229302,
  17.985429302, 18.038629302, 18.232429302, 18.307479302, 18.074729302, 18.408179302, 18.102279302, 18.181129302,
  18.030079302, 18.212479302, 18.420529302, 17.902779302, 16.501529302, 15.267479302, 14.239579302, 13.718979302,
  13.481479302, 13.395979302, 13.460579302, 13.784529302, 15.586679302, 17.241579302, 18.261879302, 18.740679302,
  18.836629302, 19.189079302, 19.390479302, 19.437029302, 18.923079302, 18.809079302, 18.887929302, 18.737829302,
  18.525029302, 18.615279302, 18.575379302, 17.898979302, 16.539529302, 15.252279302, 14.208229302, 13.584079302,
  13.216429302, 13.210729302, 13.308579302, 13.573629302, 15.409029302, 17.087679302, 18.031979302, 18.136479302,
  17.949329302, 17.956929302, 17.809679302, 17.980679302, 17.930329302, 17.876179302, 18.140279302, 18.174479302,
  18.058579302, 18.403429302, 18.518379302, 17.914179302, 16.557579302, 15.138279302, 14.141729302, 13.579329302,
  13.252529302, 13.354179302, 13.426379302, 13.730379302, 15.739629302, 17.565529302, 18.499379302, 19.064629302,
  19.104529302, 19.315429302, 19.499729302, 19.476929302, 18.907879302, 18.867979302, 18.577279302, 18.269479302,
  17.988279302, 18.188729302, 18.453779302, 18.087079302, 16.705779302, 15.387179302, 14.347879302, 13.724679302,
  13.379829302, 13.309529302, 13.405479302, 13.704729302, 15.554379302, 17.350829302, 18.395829302, 18.440479302,
  18.177329302, 18.442379302, 18.468979302, 18.575379302, 18.398679302, 18.697929302, 18.710279302, 18.667529302,
  18.603879302, 18.823329302, 18.927829302, 18.406279302, 17.096229302, 15.778579302, 14.646179302, 13.965029302,
  13.661029302, 13.438729302, 13.224029302, 13.034979302, 13.843429302, 14.821929302, 15.962879302, 16.850179302,
  17.410679302, 17.429679302, 17.298579302, 17.133279302, 17.005029302, 16.986029302, 16.701029302, 16.493929302,
  16.501529302, 16.500579302, 16.485379302, 15.997079302, 14.899829302, 13.691429302, 12.599879302, 12.177129302,
  11.942479302, 11.838929302, 11.729679302, 11.370579302, 11.877879302, 12.627429302, 13.637279302, 14.449529302,
  14.884629302, 15.157279302, 15.305479302, 15.144929302, 15.081279302, 14.563529302, 14.461879302, 14.643329302,
  14.750679302, 14.992929302, 15.230429302, 15.052779302, 14.295629302, 13.307629302, 12.434579302, 11.677999392,
  11.443689251, 11.33838753, 11.513450371, 11.944698095, 14.291208047, 16.242011403, 17.423796595, 17.867571131,
  18.192529302, 18.318879302, 18.497479302, 18.518379302, 18.435729302, 17.992079302, 17.921779302, 18.054779302,
  18.217229302, 18.439529302, 18.665629302, 17.931279302, 16.501529302, 14.667961113, 13.554487615, 12.981666456,
  12.534069699, 12.558419975, 12.631859678, 12.888198101, 14.883083387, 16.47373989, 17.350565131, 17.58177957,
  17.580311532, 18.166879302, 18.216279302, 18.086129302, 17.643429302, 18.062379302, 18.186829302, 18.143129302,
  18.135529302, 18.514579302, 18.774879302, 18.184929302, 16.421375568, 14.9565579, 13.768859312, 13.290015099,
  12.850589654, 12.705140447, 12.847878128, 13.205484207, 15.24993712, 17.008406568, 17.838659683, 18.121321466,
  18.233070995, 19.055129302, 19.419929302, 19.191929302, 18.847079302, 18.784379302, 18.774879302, 18.545929302,
  18.486079302, 18.794829302, 19.126379302, 18.491779302, 16.583755849, 15.275671323, 14.184400976, 13.489737268,
  13.137105926, 12.961364279, 13.194900728, 13.366099246, 15.340042453, 17.111479419, 18.040049498, 18.222402763,
  18.117479302, 18.352129302, 18.658029302, 18.490829302, 18.306529302, 18.207729302, 18.295129302, 18.489879302,
  18.510779302, 18.926879302, 19.288829302, 18.696979302, 17.124729302, 15.845079302, 14.372824196, 13.719336014,
  13.294378453, 13.260391953, 13.331732373, 13.61327253, 15.576770501, 17.276943347, 18.140186847, 18.564929302,
  17.892329302, 18.088979302, 18.253329302, 18.166879302, 17.812529302, 18.158329302, 18.321729302, 18.536429302,
  18.584879302, 18.801479302, 19.070329302, 18.472779302, 17.028779302, 15.650329302, 14.573979302, 13.819679302,
  13.344679302, 13.186029302, 13.042579302, 12.808879302, 13.380779302, 14.450479302, 15.587629302, 16.129129302,
  15.987579302, 15.649379302, 15.403329302, 15.385279302, 15.373879302, 15.672179302, 15.827979302, 16.179479302,
  16.429329302, 16.446429302, 16.668729302, 16.110129302, 14.966329302, 13.790229302, 12.991279302, 12.460229302,
  12.203729302, 11.991879302, 11.846529302, 11.463679302, 11.699279302, 12.663529302, 13.607829302, 13.906129302,
  14.035329302, 13.890929302, 13.907079302, 13.960279302, 14.000179302, 14.040079302, 14.231979302, 14.632879302,
  15.220929302, 15.529679302, 16.041729302, 15.976179302, 15.002429302, 13.834879302, 12.885829302, 12.372829302,
  12.113479302, 12.047929302, 12.244579302, 12.414629302, 13.696179302, 15.212379302, 16.352379302, 17.027829302,
  17.005029302, 16.862529302, 16.808379302, 16.964179302, 16.948029302, 17.160829302, 17.533229302, 17.912279302,
  17.992079302, 18.084229302, 18.240029302, 17.677629302, 16.427429302, 15.086029302, 13.922279302, 13.238279302,
  12.896279302, 12.472579302, 12.350029302, 12.008979302, 12.312979302, 13.077729302, 14.064779302, 14.646179302,
  14.705079302, 14.562579302, 14.421979302, 14.570179302, 14.732629302, 14.803879302, 15.151579302, 15.703529302,
  16.022729302, 16.300129302, 16.701029302, 16.558529302, 15.578129302, 14.271879302, 13.350379302, 12.929529302,
  12.711029302, 12.635029302, 12.877279302, 13.354179302, 15.748179302, 18.037679302, 19.404729302, 19.936729302,
  19.817029302, 20.072579302, 20.235979302, 20.376579302, 20.433579302, 20.710029302, 20.646379302, 20.720479302,
  20.627379302, 20.714779302, 20.984579302, 20.055479302, 18.301779302, 16.841629302, 15.554379302, 14.831429302,
  14.573029302, 14.630029302, 14.812429302, 15.176279302, 17.214979302, 18.987679302, 20.046929302, 20.347129302,
  20.179929302, 20.533329302, 20.674879302, 20.879129302, 20.817379302, 20.957979302, 20.833529302, 20.871529302,
  20.633079302, 20.769879302, 21.024479302, 19.937679302, 18.327429302, 16.878679302, 15.782379302, 15.260829302,
  14.904579302, 14.785829302, 15.003379302, 15.368179302, 17.271029302, 19.079829302, 20.346179302, 20.518129302,
  20.364229302, 20.623579302, 20.764179302, 20.589379302, 20.294879302, 20.450679302, 20.549479302, 20.356629302,
  20.368029302, 20.503879302, 20.723329302, 19.705879302, 18.181129302, 16.887229302, 15.766229302, 15.115479302,
  14.697479302, 14.577779302, 14.577779302, 14.326029302, 14.946379302, 16.017029302, 17.171279302, 17.735579302,
  17.788779302, 17.813479302, 17.846729302, 17.769779302, 17.849579302, 18.002529302, 18.072829302, 18.035779302,
  18.008229302, 18.112729302, 18.459479302, 17.867629302, 16.589879302, 15.338729302, 14.303229302, 13.678129302,
  13.267729302, 13.014079302, 12.875379302, 12.578979302, 12.845929302, 13.568879302, 14.508429302, 15.140179302,
  15.265579302, 15.348229302, 15.381479302, 15.328279302, 15.397629302, 15.392879302, 15.780479302, 16.057879302,
  16.492029302, 16.929979302, 17.408779302, 17.073429302, 16.136729302, 14.971079302, 14.128429302, 13.653429302,
  13.395979302, 13.345629302, 13.665779302, 14.205379302, 16.718129302, 18.945879302, 20.233129302, 20.728079302,
  20.417429302, 20.539979302, 20.693879302, 20.326229302, 20.061179302, 20.404129302, 20.391779302, 20.514329302,
  20.446879302, 20.695779302, 21.122329302, 19.997529302, 18.218179302, 16.796029302, 15.734879302, 15.256079302,
  14.950179302, 14.910279302, 15.109779302, 15.404279302, 17.352729302, 19.272679302, 20.164729302, 20.177079302,
  20.030779302, 20.163779302, 20.109629302, 20.248329302, 20.224579302, 20.632129302, 20.886729302, 20.786029302,
  20.590329302, 20.770829302, 21.204979302, 20.097279302, 18.454729302, 17.055379302, 15.989479302, 15.373879302,
  15.045179302, 15.052779302, 15.230429302, 15.522079302, 17.379329302, 19.172929302, 20.204629302, 20.385129302,
  19.998479302, 20.107729302, 20.214129302, 20.109629302, 20.080179302, 20.318629302, 20.381329302, 20.344279302,
  20.366129302, 20.661579302, 21.127079302, 19.908229302, 18.239079302, 16.809329302, 15.786179302, 15.190529302,
  14.767779302, 14.752579302, 15.005279302, 15.346329302, 17.311879302, 19.142529302, 20.061179302, 20.244529302,
  19.931979302, 19.949079302, 20.201779302, 20.100129302, 19.911079302, 20.139079302, 20.240729302, 20.454479302,
  20.487729302, 20.748029302, 21.235379302, 20.027929302, 18.214379302, 16.780829302, 15.564829302, 14.943529302,
  14.646179302, 14.554979302, 14.945429302, 15.205729302, 17.169379302, 19.092179302, 20.319579302, 20.656829302,
  20.672029302, 20.919979302, 20.896229302, 20.794579302, 20.531429302, 20.505779302, 20.716679302, 20.701479302,
  20.583679302, 20.740429302, 20.890529302, 19.612779302, 18.037679302, 16.797929302, 15.810879302, 15.131629302,
  14.706029302, 14.468529302, 14.514129302, 14.354529302, 14.954929302, 16.239329302, 17.274829302, 17.972129302,
  18.177329302, 18.112729302, 18.344529302, 18.265679302, 17.966429302, 17.671929302, 17.385029302, 17.345129302,
  17.158929302, 17.533229302, 17.832479302, 16.908129302, 15.763379302, 14.725979302, 13.773129302, 13.225929302,
  12.802229302, 12.670179302, 12.643579302, 12.446929302, 12.737629302, 13.448229302, 14.310829302, 15.004329302,
  15.650329302, 15.743429302, 15.982829302, 16.119629302, 15.975229302, 15.869779302, 15.917279302, 16.282079302,
  16.536679302, 17.029729302, 17.335629302, 16.444529302, 15.647479302, 14.552129302, 13.712329302, 13.300979302,
  13.120479302, 13.081529302, 13.422579302, 13.952679302, 16.426479302, 18.453779302, 19.663129302, 20.159979302,
  20.300579302, 20.589379302, 20.614079302, 20.614079302, 20.575129302, 20.254029302, 20.122929302, 19.871179302,
  19.823679302, 20.434529302, 20.717629302, 19.302129302, 17.545579302, 16.195629302, 15.287429302, 14.822879302,
  14.411529302, 14.357379302, 14.704129302, 15.094579302, 17.077229302, 18.912629302, 20.016529302, 20.487729302,
  20.356629302, 20.717629302, 21.110929302, 21.099529302, 21.010229302, 21.072929302, 20.924729302, 20.677729302,
  20.475379302, 20.881029302, 20.980779302, 19.407579302, 17.807779302, 16.675379302, 15.650329302, 15.105979302,
  14.434675573, 14.391053072, 14.462586573, 14.936922935, 16.960489869, 19.243229302, 20.401279302, 20.793629302,
  20.922829302, 21.359829302, 21.285729302, 21.036829302, 20.621679302, 20.473479302, 20.145729302, 19.988029302,
  20.001329302, 20.305329302, 20.615029302, 19.273629302, 17.738429302, 16.587029302, 15.213230498, 14.621723658,
  14.155671975, 14.058913701, 14.186963663, 14.66144267, 16.567078384, 18.422663548, 19.41361845, 19.715953825,
  19.690679302, 19.815129302, 19.683079302, 19.532029302, 19.168179302, 19.368629302, 19.390479302, 19.337279302,
  19.270779302, 19.780929302, 20.099179302, 18.678929302, 17.126629302, 15.555072724, 14.491409655, 13.738000104,
  13.37010013, 13.385444797, 13.630213957, 14.086422558, 15.838719302, 17.715401053, 18.655539389, 18.759714494,
  18.460790313, 18.772029302, 18.785329302, 18.736879302, 18.647579302, 18.839479302, 19.032329302, 19.192879302,
  19.122579302, 19.591879302, 19.926279302, 18.472779302, 17.014529302, 15.574594206, 14.505865121, 13.809196638,
  13.419543042, 13.285955927, 13.383699171, 13.325136606, 13.768397333, 14.913847826, 16.227456809, 17.012629302,
  16.841629302, 16.691529302, 16.707679302, 16.358079302, 16.124379302, 15.938179302, 16.365679302, 16.631679302,
  16.791279302, 17.109529302, 17.379329302, 16.339079302, 15.438479302, 14.429579302, 13.101593107, 12.467480149,
  12.059975914, 11.770185855, 11.757443396, 11.696682764, 11.792760734, 12.397834682, 13.297641874, 14.495129302,
  14.789629302, 14.529329302, 14.516029302, 14.564479302, 14.317479302, 14.515079302, 14.482779302, 14.632879302,
  15.124979302, 16.165229302, 16.569929302, 15.731079302, 14.802929302, 13.648679302, 12.633690662, 12.192604068,
  12.110483164, 12.127545535, 12.495097743, 13.224168734, 15.742055066, 17.884172777, 18.706508568, 18.845179302,
  18.473729302, 18.629529302, 18.520279302, 18.620979302, 18.677029302, 18.965829302, 19.302129302, 19.436079302,
  19.616579302, 20.347129302, 20.697679302, 19.100729302, 17.309029302, 15.992329302, 15.067029302, 14.183966022,
  13.81219228, 13.69565123, 13.963720392, 14.672758568, 16.841379823, 18.605128128, 19.684979302, 19.580479302,
  19.009529302, 18.846129302, 18.873679302, 18.826179302, 18.686529302, 19.328729302, 19.759079302, 19.885429302,
  19.898729302, 20.494379302, 20.688179302, 18.972479302, 17.189329302, 15.869779302, 14.922629302, 14.353579302,
  13.826683113, 13.816795166, 14.110248008, 14.862139035, 17.382179302, 19.107379302, 19.925329302, 19.826529302,
  19.328729302, 19.247029302, 19.379079302, 19.267929302, 19.361029302, 19.730579302, 19.948129302, 20.191329302,
  20.195129302, 20.937079302, 21.088129302, 19.323029302, 17.586429302, 16.355229302, 15.262729302, 14.717429302,
  14.465679302, 14.439079302, 14.453131573, 15.386229302, 17.439179302, 19.285029302, 20.126729302, 19.982329302,
  19.511129302, 19.403779302, 19.550079302, 19.507329302, 19.334429302, 19.774279302, 20.093479302, 20.359479302,
  20.150479302, 20.894329302, 20.955129302, 19.237529302, 17.528479302, 16.133879302, 15.200979302, 14.707929302,
  14.367829302, 14.377329302, 14.660429302, 15.374829302, 17.420179302, 19.349629302, 20.191329302, 20.114379302,
  19.647929302, 19.533929302, 19.582379302, 19.578579302, 19.486429302, 20.043129302, 20.225529302, 20.397479302,
  20.275879302, 20.773679302, 20.729029302, 19.028529302, 17.449629302, 16.256429302, 15.208579302, 14.673729302,
  14.327929302, 14.185429302, 14.288029302, 14.222479302, 14.788679302, 15.894479302, 16.901479302, 17.356529302,
  17.327079302, 17.283379302, 17.079129302, 17.059179302, 16.900529302, 17.070579302, 17.173179302, 17.385979302,
  17.367929302, 17.783079302, 17.734629302, 16.480629302, 15.348229302, 14.155979302, 13.359879302, 12.741429302,
  12.347179302, 12.261679302, 12.291129302, 12.335779302, 12.406079302, 13.347529302, 14.400129302, 15.084129302,
  15.106929302, 14.924529302, 14.785829302, 14.728829302, 14.848529302, 15.013829302, 15.462229302, 15.806129302,
  16.186129302, 17.059179302, 17.186479302, 16.262129302, 15.379579302, 14.381129302, 13.637279302, 13.246829302,
  13.085329302, 13.090079302, 13.481479302, 14.395379302, 16.891979302, 19.029479302, 20.080179302, 20.067829302,
  19.779979302, 19.898729302, 20.062129302, 20.099179302, 20.133379302, 20.579879302, 20.772729302, 20.901929302,
  20.686279302, 21.568829302, 21.546029302, 19.830329302, 17.881879302, 16.576579302, 15.624679302, 15.200029302,
  14.947329302, 14.851379302, 15.159179302, 15.936279302, 17.993029302, 19.837929302, 20.469679302, 20.657779302,
  20.411729302, 20.602679302, 20.697679302, 20.626429302, 20.647329302, 21.072929302, 21.222079302, 21.139429302,
  21.074829302, 21.812979302, 21.709429302, 19.960479302, 18.035779302, 16.777979302, 15.750079302, 15.215229302,
  14.943529302, 15.005279302, 15.255129302, 15.790929302, 17.433479302, 19.571929302, 20.486779302, 20.501979302,
  20.400329302, 20.632129302, 20.747079302, 20.900979302, 20.791729302, 20.857279302, 21.006429302, 20.834479302,
  20.597929302, 21.485229302, 21.206879302, 19.569079302, 17.850529302, 16.622179302, 15.613279302, 14.999579302,
  14.777279302, 14.707929302, 14.834279302, 15.665529302, 17.785929302, 19.670729302, 20.672979302, 20.793629302,
  20.553279302, 20.726179302, 20.748979302, 20.628329302, 20.092529302, 20.254029302, 20.418379302, 20.150479302,
  20.026029302, 21.058679302, 20.851579302, 19.227079302, 17.518029302, 16.378029302, 14.986056707, 14.398943651,
  13.98168364, 13.932774847, 14.157863782, 14.835312976, 16.858525756, 18.538636285, 19.325318369, 19.14936472,
  18.712525955, 18.685690476, 19.034229302, 18.950629302, 19.133029302, 19.600429302, 19.681179302, 19.879729302,
  19.688779302, 20.423129302, 19.938629302, 18.164979302, 16.377009153, 15.138996785, 14.048560451, 13.36628215,
  13.025632724, 12.84710419, 12.957765112, 13.03239488, 13.430376934, 14.638309769, 15.992027689, 16.308422323,
  16.043995271, 16.199429302, 16.041729302, 16.086379302, 16.207979302, 16.260229302, 16.567079302, 16.810279302,
  16.890079302, 17.566479302, 17.249179302, 15.982829302, 14.970129302, 13.995429302, 12.797856883, 12.188049286,
  11.743466338, 11.541209331, 11.583801606, 11.770322058, 11.837338676, 13.155629302, 14.370679302, 15.012879302,
  14.867529302, 14.748779302, 14.839979302, 14.959679302, 15.139229302, 15.213329302, 15.545829302, 15.660779302,
  16.065479302, 17.238729302, 17.001229302, 15.838429302, 14.814329302, 13.956479302, 13.281029302, 12.891529302,
  12.770879302, 12.818379302, 13.221179302, 14.259529302, 16.741879302, 18.825229302, 19.771429302, 19.977579302,
  19.525379302, 19.552929302, 19.596629302, 19.665029302, 19.582379302, 19.836979302, 20.110579302, 20.226479302,
  20.106779302, 21.221129302, 20.832579302, 19.129229302, 17.349879302, 15.996129302, 15.185779302, 14.748779302,
  14.537879302, 14.484679302, 14.830479302, 15.618029302, 17.831529302, 19.676429302, 20.702429302, 20.987429302,
  20.754679302, 21.048229302, 21.272429302, 20.956079302, 20.583679302, 20.617879302, 20.365179302, 20.236929302,
  20.237879302, 21.164129302, 20.695779302, 19.106429302, 17.463879302, 16.192779302, 15.195279302, 14.380877907,
  13.90121534, 13.826333223, 14.003355271, 14.789763802, 16.824119203, 18.633181914, 19.515787176, 19.610879302,
  19.133979302, 19.171979302, 19.174829302, 19.306879302, 19.209979302, 19.552929302, 19.852179302, 19.917729302,
  20.023179302, 21.130879302, 20.715729302, 19.028529302, 17.230179302, 15.922029302, 14.888429302, 14.459979302,
  14.198729302, 13.908645577, 14.159167079, 15.300729302, 17.522779302, 19.311629302, 20.349979302, 20.082079302,
  19.570029302, 19.558629302, 19.380029302, 19.418029302, 19.553879302, 19.974729302, 20.350929302, 20.268279302,
  20.345229302, 21.184079302, 20.592229302, 18.949679302, 17.440129302, 16.175679302, 15.112629302, 14.744029302,
  14.351679302, 14.326029302, 14.591079302, 15.265579302, 17.416379302, 19.201429302, 20.261629302, 20.282529302,
  19.877829302, 19.943379302, 19.806579302, 19.802779302, 19.805629302, 19.931029302, 20.178979302, 20.154279302,
  20.270179302, 20.904779302, 20.257829302, 18.664679302, 17.196929302, 16.163329302, 15.121179302, 14.555929302,
  13.837125129, 13.703199775, 13.763133516, 13.869033991, 14.364104649, 15.578357683, 17.290029302, 18.076629302,
  18.318879302, 18.491779302, 18.424329302, 18.181129302, 17.917029302, 17.613029302, 17.279579302, 17.044929302,
  17.207379302, 17.826779302, 17.319479302, 16.057879302, 14.783779664, 13.657618172, 12.485957009, 11.907853848,
  11.470704613, 11.244845433, 11.222579296, 11.358152924, 11.526524913, 12.294907914, 13.543038292, 14.437524258,
  14.799010508, 15.203838838, 15.047680731, 15.043405644, 14.924529302, 15.062279302, 15.194329302, 15.329229302,
  15.770029302, 16.831179302, 16.088904799, 15.146000713, 14.232794409, 13.18943612, 12.472908226, 12.071458048,
  11.864097332, 11.808865048, 12.213308841, 13.140634352, 15.731864511, 17.873172532, 19.240178532, 19.59184858,
  19.294124214, 19.826529302, 19.721079302, 19.734379302, 19.840779302, 20.082079302, 19.940529302, 19.914879302,
  20.183729302, 21.026379302, 20.402229302, 18.740679302, 17.101929302, 15.636831977, 14.73108796, 14.159386733,
  13.87977018, 13.813266344, 14.247531798, 15.10448845, 17.367054312, 19.353578955, 20.565629302, 20.772729302,
  20.389879302, 20.220779302, 19.796129302, 19.779979302, 19.760979302, 20.012729302, 20.244529302, 20.112479302,
  20.253079302, 21.169829302, 20.536179302, 18.953479302, 17.290979302, 16.055029302, 15.079379302, 14.580629302,
  14.287079302, 13.895901463, 14.263701894, 15.062986465, 17.414479302, 19.204279302, 20.002279302, 20.078279302,
  19.700179302, 19.683079302, 19.847429302, 19.811329302, 19.729629302, 20.190379302, 20.539979302, 20.403179302,
  20.473479302, 21.227779302, 20.421229302, 18.821429302, 17.072479302, 15.968579302, 14.973929302, 14.418179302,
  14.158829302, 13.804571776, 14.099151575, 15.038141446, 17.560779302, 19.341079302, 20.200829302, 20.192279302,
  19.572879302, 19.668829302, 19.798979302, 19.763829302, 19.810379302, 20.268279302, 20.598879302, 20.330979302,
  20.534279302, 21.474779302, 20.637829302, 19.051329302, 17.389779302, 16.214629302, 15.186729302, 14.608179302,
  14.047169047, 14.011556852, 14.231967384, 15.045983463, 17.157879767, 18.962029302, 19.994679302, 19.987079302,
  19.604229302, 19.646979302, 19.662179302, 19.626079302, 19.623229302, 19.929129302, 20.229329302, 19.996579302,
  20.168529302, 20.749929302, 19.961429302, 18.514579302, 16.937579302, 15.913479302, 14.456977492, 13.764313665,
  13.388374751, 13.144343986, 13.159631517, 13.325186528, 13.748328633, 15.006589119, 16.27591055, 17.315202293,
  18.014879302, 17.922729302, 17.767879302, 17.573129302, 17.100979302, 16.804579302, 16.656379302, 16.658279302,
  17.221629302, 17.810629302, 17.204529302, 15.72610739, 14.612807154, 13.199079581, 12.301966576, 11.674550927,
  11.246360444, 11.058545527, 11.061704534, 11.153068128, 11.29202169, 11.940109081, 13.016670177, 14.091711752,
  14.56084802, 14.778688409, 14.643096622, 14.777332888, 14.649212115, 14.643178684, 14.904579302, 15.206679302,
  16.177579302, 16.670154438, 16.165233385, 15.159766054, 14.188055403, 13.023012726, 12.347851137, 11.998860806,
  11.777110626, 11.814424619, 12.259545838, 13.431987903, 16.195166514, 18.178555679, 19.246656418, 19.522164631,
  19.011864405, 19.418979302, 19.379079302, 19.401879302, 19.274579302, 19.818929302, 19.999429302, 19.983279302,
  20.524779302, 21.197379302, 20.438329302, 18.751129302, 17.081029302, 15.710179302, 14.413173613, 13.900504269,
  13.733680025, 13.712850598, 13.932820986, 14.84373846, 17.599729302, 19.266979302, 20.007029302, 19.850279302,
  19.170079302, 19.222329302, 19.325879302, 19.327779302, 19.334429302, 19.679279302, 20.012729302, 19.963329302,
  20.631179302, 21.414929302, 20.614079302, 19.053229302, 17.354629302, 16.115829302, 14.746804504, 14.180336061,
  13.831705248, 13.682556545, 13.827848765, 14.760687169, 17.059364981, 18.755970361, 19.432415495, 19.287492375,
  19.213896847, 19.394103691, 19.607871474, 19.779029302, 19.598529302, 19.669779302, 19.665029302, 19.924379302,
  20.480129302, 20.791202967, 19.98049537, 18.401878723, 16.544685339, 15.231643105, 14.212571066, 13.583916835,
  13.105693592, 13.185565263, 13.393063981, 14.31294997, 16.548317498, 18.30114042, 18.951605812, 19.040131226,
  18.800747849, 18.649473619, 19.047297553, 19.265535315, 19.523663261, 19.933637129, 19.78879602, 19.576470199,
  20.180768986, 20.567525739, 19.869854224, 18.458236516, 16.739066759, 15.275331332, 14.130763925, 13.562607059,
  13.220145203, 13.190666063, 13.566803524, 14.509150001, 16.962267123, 18.700090438, 19.473771031, 19.579194189,
  19.418302644, 19.287344767, 19.076466641, 18.865707329, 19.002059954, 19.41945524, 19.64822953, 19.716340168,
  20.296406291, 20.594861326, 19.777225553, 18.096122369, 16.550515001, 15.371445473, 14.397914189, 13.697480281,
  13.221770621, 13.046708567, 13.170111765, 13.432680439, 14.102139213, 15.132655538, 16.497261789, 17.560107837,
  18.040529302, 17.962629302, 17.854329302, 17.768829302, 17.718479302, 17.775479302, 17.657679302, 17.790679302,
  18.364479302, 18.391079302, 17.562306792, 16.234164023, 14.926827455, 13.607785937, 12.50994774, 11.763232078,
  11.24614422, 10.968625462, 10.850046216, 11.051577956, 11.42473389, 12.06626573, 13.28742474, 14.181390227,
  14.044652795, 13.739428154, 13.062021132, 13.278844861, 13.113055432, 13.60909512, 14.280337304, 14.687888928,
  15.811372391, 16.390631387, 15.771308715, 14.726012776, 13.72744684, 12.631034742, 11.784987783, 11.332103037,
  10.947088324, 10.89001837, 11.27549416, 12.340619475, 15.370811341, 17.5728026, 18.877339929, 19.391423606,
  19.289367893, 19.485648584, 19.770997797, 19.861791978, 19.581210373, 19.627180999, 19.544206087, 19.532080774,
  20.241425797, 20.626669609, 19.909844157, 18.263827598, 16.452373389, 15.090822356, 14.005251988, 13.384858257,
  13.002690298, 12.930940754, 13.195771415, 14.062582322, 16.843203193, 18.66927584, 19.621868513, 19.749657181,
  19.380893577, 19.402020391, 19.249341137, 19.482141879, 19.231888114, 19.586582788, 19.649540309, 19.759624118,
  20.646336984, 20.996733639, 20.25608171, 18.73916336, 16.930212718, 15.534087351, 14.459325346, 13.739826912,
  13.336365875, 13.364088081, 13.758428423, 14.61660057, 17.335699812, 19.178295647, 20.027929506, 19.954582846,
  19.275823256, 19.371479302, 19.551029302, 19.539629302, 19.639379302, 20.094429302, 19.950029302, 19.896829302,
  20.702429302, 21.190729302, 20.610279302, 19.211879302, 17.064707231, 15.71353831, 14.631686187, 14.132456467,
  13.78333764, 13.768808483, 14.109434389, 14.876955901, 17.510748187, 19.236849624, 19.929599784, 20.063526251,
  19.8357092, 20.399379302, 20.591279302, 20.795529302, 20.736629302, 21.018779302, 20.935179302, 20.888629302,
  21.429179302, 21.563129302, 20.950379302, 19.556729302, 17.641755068, 16.205335775, 15.002564687, 14.436136566,
  14.057119399, 14.014436864, 14.284827418, 15.216337095, 17.722739724, 19.319383533, 20.182012363, 20.377557658,
  19.722735089, 19.729672209, 19.627041419, 19.706829302, 19.795179302, 20.195129302, 20.191329302, 20.266379302,
  20.822129302, 21.086229302, 20.451629302, 18.670417279, 17.154429403, 15.887493982, 14.739180188, 14.087868165,
  13.698141643, 13.504782411, 13.509803764, 13.797691935, 14.53248416, 15.542107933, 16.975579302, 17.531329302,
  17.384079302, 17.301429302, 17.691879302, 17.827729302, 17.499029302, 17.398329302, 17.214029302, 17.375529302,
  18.131729302, 18.227679302, 17.708979302, 16.304229268, 15.076377927, 13.78056438, 12.691158381, 11.90762157,
  11.369978281, 11.183080473, 11.164446665, 11.288956001, 11.65029101, 12.060035679, 12.91364642, 13.539307747,
  14.104717092, 14.462929514, 14.535084281, 14.632967787, 14.535912845, 14.553605713, 14.404027725, 14.791685504,
  15.924527375, 16.133675153, 15.752526228, 14.823749641, 14.070356546, 13.035483886, 11.963839687, 11.396101859,
  11.024521491, 11.009558613, 11.463170324, 12.550433534, 15.572192627, 17.650995536, 18.740013548, 19.301434065,
  19.182949724, 19.654807003, 19.777923457, 19.466271086, 19.260137837, 19.422815531, 19.238506274, 19.619241956,
  20.343157858, 20.49067401, 19.673134405, 18.025523854, 16.246540313, 14.811860464, 13.693347644, 12.993646181,
  12.647535718, 12.589482722, 12.99538345, 13.905000485, 16.735905399, 18.491574049, 19.308029125, 19.182267564,
  18.598693841, 18.751719204, 18.943732537, 18.966386678, 18.933551463, 19.401344374, 19.670294793, 19.70093196,
  20.441409435, 20.572875665, 19.803346406, 18.33690474, 16.586912262, 15.132124335, 13.844962488, 13.197740597,
  12.741359805, 12.798728703, 13.14347067, 14.119783157, 16.838789748, 18.644497085, 19.580345441, 19.484385035,
  18.769644461, 18.468147875, 18.412634771, 18.56047602, 18.58690089, 18.923190701, 19.323963498, 19.808555574,
  20.722658102, 20.857404601, 20.070663446, 18.411409405, 16.660406019, 15.178640008, 14.072059337, 13.240010496,
  12.822071741, 12.785126819, 13.087193624, 13.99281279, 16.737732724, 18.550247362, 19.454977195, 19.594127501,
  19.436891731, 19.90515808, 20.123923928, 20.39631231, 20.205457145, 20.095660969, 20.056807754, 20.353980364,
  20.881274541, 20.747616584, 20.020195634, 18.458340485, 16.743877315, 15.235512424, 14.02814246, 13.456386579,
  13.068066613, 13.085580255, 13.214478549, 14.142430455, 16.896768281, 18.691421802, 19.684177042, 20.229540359,
  20.381324261, 20.803963234, 20.900966519, 21.001355991, 20.658254509, 20.558243133, 20.401258403, 20.752106901,
  21.147832743, 20.955560927, 20.129830096, 18.53834549, 17.04608748, 15.821366903, 14.60707511, 13.802867489,
  13.357519657, 13.282175868, 13.350221905, 13.59834424, 14.64559963, 15.576292284, 16.959035232, 17.975170196,
  18.400809614, 18.335310263, 18.202990556, 17.951648495, 17.399637741, 17.846729302, 18.030079302, 18.409129302,
  18.896141648, 18.658774141, 18.042002271, 16.754554763, 15.576772487, 14.366625124, 13.259228427, 12.656592469,
  12.114660196, 11.944637732, 12.049858162, 12.272348366, 12.799176281, 13.076894074, 14.146466533, 15.402021279,
  16.240295423, 16.618396759, 16.913947189, 17.153067425, 16.930535127, 16.454989445, 16.186693162, 16.678737351,
  17.451214049, 17.497747963, 17.083283197, 16.037387522, 15.043742096, 13.879842617, 13.029748072, 12.607908636,
  12.369178002, 12.397345921, 12.799683408, 13.909004206, 17.048097866, 18.8280666, 19.910910683, 20.259653492,
  20.255783821, 20.41533489, 20.415917139, 20.245661426, 19.983376562, 20.232547941, 20.258319118, 20.674794876,
  21.510734914, 21.543742658, 20.746849113, 19.205908774, 17.477080285, 16.064322444, 14.867045868, 14.203405083,
  13.745728774, 13.57377529, 13.891940753, 14.777581882, 17.444191674, 19.092638375, 19.735762425, 19.956521768,
  19.725511821, 19.839427701, 19.707433548, 19.965043094, 20.225084203, 20.367229805, 20.278193591, 20.531662604,
  21.084739196, 20.936020581, 20.328921465, 18.791212357, 17.165401379, 15.68874446, 14.637877213, 14.087654853,
  13.729558531, 13.748268077, 14.014030734, 14.978712761, 17.545408456, 19.427409626, 20.355022453, 20.817965305,
  20.966518406, 21.30138957, 21.415759728, 21.102413721, 20.84234141, 20.631880343, 20.719264888, 20.950783567,
  21.484148094, 21.416859692, 20.807220014, 19.317201175, 17.546545352, 16.129322819, 14.941237632, 14.254965354,
  13.795240132, 13.627360959, 13.981221974, 14.932103349, 17.681917161, 19.617566864, 20.379363072, 20.629034254,
  20.325757135, 20.767873347, 20.875406661, 20.993335002, 20.802702753, 20.769705422, 20.733075136, 21.18426055,
  21.577279996, 21.466961939, 20.856972371, 19.343296504, 17.588898249, 16.183689471, 15.02090106, 14.314642592,
  13.856798041, 13.891648805, 14.151469324, 15.087816649, 17.785720078, 19.569241848, 20.396701604, 20.622652708,
  20.63718418, 21.043763378, 21.240782011, 21.311557735, 21.025031668, 20.982019812, 20.954661334, 21.277675821,
  21.603761553, 21.369223653, 20.644532828, 19.106783532, 17.361376851, 15.982462038, 14.755330736, 14.066352425,
  13.527678703, 13.328663442, 13.305530445, 13.591908429, 14.593786588, 15.504898836, 16.558032079, 17.275298443,
  17.552396191, 17.645524352, 17.795112458, 17.80736545, 17.749414197, 17.668467833, 17.771560143, 18.127413561,
  18.540826737, 18.247735334, 17.654857898, 16.462756199, 15.389294544, 14.075738889, 13.047093898, 12.236867473,
  11.750393747, 11.356172556, 11.338585683, 11.509979674, 11.836496078, 12.306367791, 13.007715974, 13.788678413,
  14.110487652, 14.162960729, 14.444462391, 14.852195936, 15.155910054, 15.364377753, 15.61359597, 16.402444222,
  17.156102717, 17.085869287, 16.909881966, 16.326470301, 15.421362722, 14.55558341, 13.517679673, 12.766513305,
  12.450883755, 12.181113358, 12.346410873, 12.925026725, 14.125315585, 16.805993976, 18.844176018, 19.781068382,
  19.897894578, 19.64886373, 19.76545029, 19.977579302, 20.356629302, 20.356629302, 20.531429302, 21.251529302,
  21.937429302, 21.277200371, 20.881797179, 20.027024054, 18.390927994, 16.660424692, 15.3102419, 14.368502471,
  13.774557438, 13.43713264, 13.489813198, 13.818788606, 14.94900176, 17.462132856, 19.283253377, 20.205833898,
  20.657928763, 20.875755795, 21.140379302, 21.096679302, 21.159379302, 20.879129302, 20.932329302, 21.358879302,
  21.817729302, 20.826502343, 20.452385403, 19.554577474, 17.973477048, 16.160796013, 14.64058413, 13.449844325,
  12.552291561, 12.135959558, 11.851507615, 11.857298099, 11.833784753, 11.824363949, 12.428846708, 13.343353913,
  13.817470834, 13.752585321, 13.675177619, 13.940265027, 13.999605829, 13.872728958, 14.031610649, 14.761459718,
  15.372967913, 15.343283043, 15.341265514, 14.995256679, 14.164342627, 13.25745376, 12.14704812, 11.39186819,
  11.023211591, 10.78579863, 10.779224598, 11.298890525, 12.533633044, 15.023924391, 17.172731523, 18.15936901,
  18.391066826, 17.953716402, 18.078556882, 18.336304339, 18.819520274, 19.012950917, 19.359072141, 19.966078385,
  20.270473888, 20.005201913, 19.780367439, 18.997375969, 17.521166233, 15.916721618, 14.713353559, 13.592593131,
  12.932508741, 12.69312469, 12.787020352, 13.139331167, 14.150205898, 16.361721939, 18.273741203, 19.527586279,
  20.038054816, 20.213984538, 20.696832187, 20.911322809, 21.087867285, 20.773522793, 20.79454092, 21.237826383,
  21.169735542, 20.666477083, 20.22080418, 19.28995503, 17.762805621, 16.035908603, 14.822154502, 13.799038933,
  13.175549186, 12.708051293, 12.581697379, 12.716784203, 13.027709084, 13.674898347, 14.671258917, 15.946898167,
  16.537141509, 16.583416554, 16.726117894, 16.994553772, 17.086890205, 16.807319995, 16.995003724, 17.777920697,
  18.182272971, 17.755159442, 17.389425744, 16.75136403, 15.667893011, 14.404806607, 13.188438669, 12.248484478,
  11.557971114, 11.010515536, 10.846862221, 10.961946513, 11.247528702, 11.570609213, 12.155167309, 13.255560428,
  14.257206383, 15.032461478, 15.515904731, 15.894827837, 16.117379688, 15.957447192, 15.728277621, 16.362878358,
  16.823179033, 16.718267173, 16.522156804, 15.922967267, 14.882344917, 13.939367286, 12.932327251, 12.117936809,
  11.732918591, 11.457933848, 11.565765858, 12.035710439, 13.391285269, 16.351956878, 18.435866881, 19.494785583,
  19.614008874, 19.837408507, 19.962346561, 20.16930808, 20.459702089, 20.395120805, 20.626294492, 21.193000635,
  21.514517752, 21.203024585, 20.940838839, 20.086857204, 18.63006502, 17.019493214, 15.588111408, 14.441792282,
  13.941553479, 13.552727547, 13.551999531, 14.024308701, 14.886278552, 17.387467041, 18.986830787, 19.520292221,
  19.472034344, 19.212788858, 19.233430671, 19.749281163, 20.259218495, 20.220431043, 20.371037887, 21.148187226,
  21.510923756, 21.114884713, 20.780973566, 19.964928706, 18.460911297, 16.938195589, 15.440655598, 14.331137106,
  13.82871809, 13.342802922, 13.399283505, 13.731751552, 14.776465065, 17.375246503, 19.157706703, 19.951683093,
  19.929165581, 19.578973896, 20.216846791, 20.594442487, 20.656262186, 20.300752965, 20.547224828, 21.159455086,
  21.346681597, 20.905613488, 20.725161575, 20.009909512, 18.396903759, 16.744049396, 15.298009056, 14.188246521,
  13.633742314, 13.303416525, 13.35461019, 13.802053224, 14.785440265, 17.339195117, 18.740166404, 19.502691187,
  19.410797878, 19.021360211, 19.044660695, 19.446211252, 19.876931437, 19.823365371, 20.216731501, 21.01590848,
  21.240537222, 20.894836924, 20.734141472, 20.007031381, 18.539792945, 16.675494459, 15.258278582, 14.226462982,
  13.618927155, 13.257813453, 13.25687465, 13.603391116, 14.501841464, 16.873006111, 18.56148777, 19.289278172,
  19.583586741, 19.513589656, 19.863607113, 20.237358605, 20.517416477, 20.298413848, 20.410073848, 20.833931089,
  20.786912164, 20.377391712, 19.939617258, 19.117365121, 17.615647674, 16.082252886, 14.807659474, 13.515117715,
  12.879514533, 12.389303111, 12.121577935, 12.155195131, 12.129595908, 12.316624035, 13.203284519, 14.538134011,
  15.567226961, 16.115480551, 16.256066649, 16.38697668, 16.743340253, 16.619150529, 16.655451024, 17.130052362,
  17.123310658, 16.74500738, 16.335635619, 15.713317402, 14.802834302, 13.784612794, 12.68851601, 11.632287235,
  11.003431309, 10.590107074, 10.480310799, 10.60592821, 10.872586397, 11.387006141, 11.972393813, 13.167155614,
  14.214151368, 14.746295598, 15.210260783, 15.460077652, 15.810109436, 15.664538968, 15.686909198, 16.365557753,
  16.652188458, 16.624846317, 16.378596636, 15.836778845, 14.858513832, 13.940603688, 12.813824037, 11.849854097,
  11.392944277, 11.197145023, 11.152555536, 11.678091582, 12.971567787, 16.167036652, 18.07732958, 19.339587842,
  19.698904419, 19.388036363, 19.397242545, 19.76393949, 19.98401715, 19.858008531, 20.29553246, 21.007170461,
  21.139793837, 20.692035801, 20.468823798, 19.774006389, 18.1783654, 16.489361223, 15.14316673, 14.015636159,
  13.577697726, 13.234121752, 13.125830599, 13.387779695, 14.540189836, 17.275218052, 19.157594698, 20.23279989,
  20.322839534, 20.243475055, 20.648961893, 20.881095567, 21.224521456, 21.054011027, 21.286959862, 21.765500985,
  21.714358252, 21.398778789, 21.14069, 20.255095284, 18.601680018, 16.919159227, 15.504309994, 14.467577663,
  13.840554584, 13.535367683, 13.563530728, 13.952624176, 15.012020412, 17.644792341, 19.404143633, 20.408343836,
  20.864437196, 20.958285755, 21.369405413, 21.626994331, 21.597059698, 21.404306277, 21.476175674, 21.852743144,
  21.865636473, 21.335272901, 21.036754224, 20.378480125, 18.710548741, 17.018525286, 15.60207309, 14.330489207,
  13.605309425, 13.200001361, 13.196120896, 13.53718642, 14.564048589, 17.24876794, 18.837701526, 19.742991784,
  19.968082801, 19.885126411, 19.981384053, 20.151166383, 20.64252289, 20.367589732, 20.696511406, 21.288424878,
  21.243706717, 20.825451647, 20.454587332, 19.676501238, 18.227014419, 16.652497383, 15.252834341, 13.958786193,
  13.401786492, 13.050826029, 13.036284179, 13.335936255, 14.326579734, 16.882182668, 18.748594083, 19.917048261,
  20.532727719, 20.78018881, 21.309049942, 21.583531525, 21.67477046, 21.383056441, 21.205876858, 21.623536708,
  21.389121055, 20.787136158, 20.339878053, 19.435899151, 17.951753574, 16.315413872, 14.922314653, 13.579474555,
  12.767591554, 12.220197184, 12.133910109, 12.18401386, 12.581423862, 13.535239608, 14.608526378, 16.158811707,
  17.390113087, 18.086150722, 18.44044335, 18.531672508, 18.52037917, 18.090475335, 18.119721465, 18.548455636,
  18.314884172, 17.832734724, 17.385928747, 16.597702549, 15.450915874, 14.198447916, 12.817191987, 11.68240378,
  10.977613954, 10.481853909, 10.35642559, 10.358043489, 10.701083909, 11.117374135, 11.703512476, 12.746400854,
  13.85355323, 14.859830998, 15.486551465, 16.038553785, 16.276079006, 16.030261896, 16.087426112, 16.54161317,
  16.714588327, 16.648261924, 16.441691028, 15.81134746, 14.780796629, 13.822890488, 12.774186915, 11.922599244,
  11.516016449, 11.317042405, 11.365427188, 12.036500688, 13.298112308, 16.497089613, 18.762840404, 20.189766317,
  20.784491766, 21.049554955, 21.68325818, 21.885418546, 22.062374857, 21.717702653, 21.684892897, 22.055033419,
  21.734153917, 21.240269263, 20.827510412, 20.061853473, 18.466367972, 16.732318379, 15.189067692, 13.983530168,
  13.292204976, 12.950439414, 12.919933245, 13.163661067, 14.231654579, 17.095961446, 18.946774434, 20.105865902,
  20.6393447, 20.639240549, 21.263865721, 21.674741683, 21.692143764, 21.328919272, 21.304055823, 21.77796776,
  21.481383048, 21.034846324, 20.689930811, 19.931474958, 18.16072169, 16.439252139, 14.928020971, 13.73074817,
  12.944608409, 12.528974503, 12.373523746, 12.687113759, 13.674229561, 16.337843342, 18.336245337, 19.521791835,
  19.95589354, 19.667250002, 19.88617489, 19.871539225, 20.308180977, 20.257992434, 20.440999851, 21.044315772,
  21.018826346, 20.535703242, 20.39161963, 19.775222542, 18.079522396, 16.40716824, 15.10153391, 13.845328131,
  13.19368517, 12.881366304, 12.833279641, 13.344711718, 14.460852141, 17.201858737, 19.154982583, 20.418264903,
  20.954452947, 21.105424898, 21.469764836, 21.745323607, 21.931125905, 21.481773639, 21.486740193, 21.923806707,
  21.754024073, 21.160061134, 20.921387264, 20.064258889, 18.414489214, 16.656142086, 15.181403661, 14.06578143,
  13.523382827, 13.187244849, 13.172092236, 13.594818668, 14.659723886, 17.245024088, 19.17976951, 20.215545623,
  20.460418205, 20.59022301, 20.93094842, 21.210450335, 21.352239509, 21.070823121, 21.318317219, 21.841085469,
  21.680414545, 21.122122082, 20.707939627, 19.810835149, 18.261550555, 16.677092923, 15.018932613, 13.909559104,
  13.183784599, 12.623219068, 12.43239598, 12.482564153, 12.913576363, 13.943168405, 15.031775297, 16.592821531,
  17.672352859, 18.064163569, 18.397366569, 18.521190077, 18.481197342, 18.169693473, 18.320320042, 18.750294426,
  18.589311769, 18.172048512, 17.561119492, 16.744863982, 15.447347403, 14.300328134, 13.041302027, 11.857532593,
  11.152472705, 10.719407637, 10.418100382, 10.544231509, 10.785918711, 11.296544993, 11.911887747, 13.215430306,
  14.355817737, 15.075605104, 15.557590271, 15.923060607, 16.145411735, 16.056694113, 16.033124504, 16.638584904,
  16.674224218, 16.5700486, 16.494603568, 15.782275117, 14.77719911, 13.840131348, 12.744394206, 11.789960926,
  11.241015284, 10.970337339, 10.97266451, 11.400122367, 12.934259882, 16.104254463, 18.32762663, 19.777842943,
  20.46936566, 20.460544017, 20.811486031, 20.998037468, 21.079434225, 20.77505595, 21.020455725, 21.589020799,
  21.333232149, 20.815304071, 20.453906846, 19.603750279, 17.908318492, 16.091760002, 14.543500964, 13.275194838,
  12.64631593, 12.344940905, 12.360267138, 12.729499654, 13.67903601, 16.509229892, 18.695222558, 20.062369532,
  20.896369274, 21.194286708, 21.687058243, 21.82635266, 21.86434368, 21.41620533, 21.527494971, 21.890772567,
  21.643566203, 21.155409155, 20.710351681, 19.836376035, 18.0681563, 16.271868748, 14.791822619, 13.56032667,
  12.83967601, 12.395548465, 12.343012875, 12.727235906, 13.749179136, 16.513534912, 18.568442908, 19.835816614,
  20.46593725, 20.612907458, 20.844449729, 20.77102108, 20.956099814, 20.854753472, 20.999129026, 21.426466518,
  21.221072376, 20.695504188, 20.330429722, 19.532048178, 17.818473786, 16.107444289, 14.658678767, 13.486802083,
  12.769501484, 12.306629475, 12.338448726, 12.715146842, 13.914965197, 16.80330911, 18.931619336, 20.225523683,
  20.638068311, 20.631016399, 21.183698097, 21.302936137, 21.490487967, 21.246085445, 21.35684368, 21.592087355,
  21.414890059, 21.068993302, 20.745855428, 19.854255705, 18.123191891, 16.368123977, 14.839031391, 13.619097203,
  12.860012239, 12.553384897, 12.410358646, 12.768586892, 13.832791274, 16.592753749, 18.790533708, 20.168689111,
  20.718159295, 20.701652429, 21.035873576, 21.279366256, 21.405850566, 21.075265688, 21.19881339, 21.60464627,
  21.360752866, 20.879209703, 20.343061502, 19.473615699, 17.799149514, 16.165890246, 14.816241609, 13.724772135,
  13.013515994, 12.64132586, 12.530385697, 12.680268077, 13.069785435, 14.112420213, 15.137316691, 16.722956075,
  18.098616838, 18.887259902, 19.35092382, 19.396904743, 19.420034413, 19.022010816, 18.791821761, 19.180401934,
  18.9454202, 18.509597583, 17.970611049, 17.095512732, 15.887806434, 14.583236371, 13.309806366, 12.16824246,
  11.447876519, 10.899121509, 10.695799959, 10.836514248, 11.11964807, 11.739390559, 12.479216352, 13.663294041,
  15.109276219, 16.030062122, 16.600619597, 16.998342789, 17.209356812, 16.846946695, 16.475799679, 16.96703487,
  17.05576719, 16.900904623, 16.653726339, 15.984964292, 14.962574157, 13.961096254, 12.681372306, 11.657880822,
  11.10324358, 10.792716453, 10.796716366, 11.325783025, 12.689316203, 15.928547341, 18.129188734, 19.588640984,
  20.294017786, 20.369231923, 20.636778728, 20.611289627, 20.859545597, 20.656344547, 20.726197095, 21.094389495,
  20.914970095, 20.480997552, 20.142607524, 19.386530288, 17.639654859, 15.807548994, 14.163608097, 13.07450058,
  12.418261292, 11.923947489, 11.973486527, 12.466514865, 13.562897184, 16.316688829, 18.455281022, 19.666605497,
  20.252703606, 20.187000127, 20.527315092, 20.557284169, 20.831144281, 20.536335023, 20.714983443, 21.206574448,
  21.062041052, 20.675563935, 20.365341624, 19.630718156, 18.059267524, 16.28769714, 14.808694931, 13.607687986,
  13.048792456, 12.699168197, 12.709344969, 13.180788813, 14.180778485, 17.076569457, 19.041579924, 20.282521316,
  21.01812538, 21.296406637, 21.634343713, 21.899651958, 21.918987637, 21.5580198, 21.579979064, 21.991781921,
  21.657249064, 21.148046212, 20.932109813, 20.022794449, 18.477240272, 16.854420696, 15.290892109, 14.136060175,
  13.455894814, 13.120407851, 13.227810636, 13.69063272, 14.721887331, 17.578405948, 19.761519423, 21.030103038,
  21.819484836, 22.200805949, 22.699219152, 22.840203393, 22.807523114, 22.418173196, 22.385389668, 22.669434296,
  22.316090577, 21.853654889, 21.524271141, 20.563081345, 18.878776987, 17.089349939, 15.515256181, 14.249452118,
  13.623637396, 13.204504637, 13.169372587, 13.474864713, 14.464522056, 17.259536266, 19.317444785, 20.418468674,
  21.165103087, 21.310656279, 21.726976435, 21.6303427, 21.698925034, 21.374735791, 21.526461282, 21.882547661,
  21.561185456, 21.04822409, 20.695326601, 19.737748904, 18.145789312, 16.58731602, 15.216642081, 13.940260704,
  13.099458922, 12.69688437, 12.610029895, 12.761661961, 13.166051751, 14.112673934, 15.201008718, 16.779272139,
  18.113430199, 18.72819002, 19.086506434, 19.269881541, 19.240596465, 18.697980285, 18.798839783, 19.192888469,
  18.843421475, 18.45553152, 17.997676191, 17.207180107, 15.973016232, 14.801871936, 13.411366738, 12.242231824,
  11.455912561, 10.954664196, 10.759116868, 10.97333584, 11.32376583, 12.110531086, 12.795726556, 14.02827566,
  15.510811637, 16.483727762, 16.88818756, 17.122725688, 17.265170093, 17.168597444, 17.168839241, 17.56697551,
  17.586044602, 17.506609301, 17.273194414, 16.62073294, 15.518166713, 14.589906795, 13.419368121, 12.30253441,
  11.909162785, 11.704161136, 11.757055833, 12.339830377, 13.715845175, 17.003835998, 19.203766662, 20.608204427,
  21.225171415, 21.264017523, 21.618110019, 21.635778334, 21.816644106, 21.69177311, 21.926176205, 22.363816499,
  22.137757901, 21.649154562, 21.368630315, 20.560718136, 19.042438957, 17.37216032, 15.891387651, 14.609736481,
  13.8715271, 13.470213112, 13.377266355, 13.783979344, 14.847516855, 17.670686281, 19.814064417, 20.974460855,
  21.502431659, 21.507746802, 22.008011673, 22.232232808, 22.280042792, 22.037144296, 22.212244252, 22.597135407,
  22.185384151, 21.724154262, 21.43351711, 20.606770018, 18.912043792, 17.286041627, 15.736063616, 14.519816368,
  13.744462171, 13.359559059, 13.339753569, 13.708477933, 14.7627702, 17.569467654, 19.642613823, 20.66371136,
  21.299435803, 21.423614202, 21.782668453, 22.019312109, 22.085478916, 21.840380437, 21.886183099, 22.259756834,
  21.936712806, 21.469669058, 21.26994527, 20.364259461, 18.697609264, 17.078881776, 15.634548291, 14.307295337,
  13.678997919, 13.29806064, 13.203970537, 13.571347752, 14.635502588, 17.389073757, 19.647988693, 20.782778234,
  21.378010064, 21.518893723, 22.080555316, 22.238768599, 22.318400628, 22.006153441, 21.918339995, 22.2809089,
  22.022126016, 21.596075359, 21.15033183, 20.245930361, 18.5984162, 16.980072645, 15.461734812, 14.309442943,
  13.473792105, 13.058317384, 12.963397859, 13.329910645, 14.426739, 17.248570736, 19.40608177, 20.365490053,
  20.97879599, 20.968612412, 21.385981017, 21.508409251, 21.522468078, 21.187601748, 21.334906172, 21.803084628,
  21.531328067, 21.084356538, 20.789539343, 20.039682872, 18.591633207, 16.899399061, 15.499759008, 14.253107652,
  13.489720947, 13.113524951, 12.996531045, 13.207636358, 13.563066909, 14.658492446, 15.795191709, 17.11983986,
  18.261774695, 18.953739954, 19.407203314, 19.468389101, 19.385621922, 19.04549644, 19.28476636, 19.752424331,
  19.398819601, 19.147122973, 18.653095382, 17.966886248, 16.733245425, 15.587841699, 14.275690761, 13.137888952,
  12.385543363, 11.880142731, 11.713812719, 11.889950392, 12.202354301, 12.970904279, 13.641222729, 14.68784343,
  15.906549674, 16.542935791, 16.83234546, 17.138732364, 17.590979571, 17.619955979, 17.80287921, 18.344008959,
  18.329775036, 18.185618823, 18.080297382, 17.406568797, 16.315635139, 15.382629348, 14.094643558, 13.191541586,
  12.663976685, 12.390819328, 12.412550303, 12.820714842, 14.196516228, 17.34510528, 19.724406032, 20.722611736,
  21.040119909, 20.934215559, 21.121558867, 21.213123244, 21.537250784, 21.531475622, 21.918521066, 22.421269372,
  22.158806841, 21.707076602, 21.410991956, 20.685532525, 19.045703081, 17.356594245, 15.807444765, 14.56904931,
  13.882138315, 13.567889453, 13.546882958, 14.112785312, 15.082768945, 17.938177954, 19.927777647, 20.84056978,
  21.230640274, 21.042797292, 21.207605313, 21.559511298, 21.796975842, 21.595365356, 21.762454331, 22.076657523,
  21.862684181, 21.455650619, 21.197248287, 20.529784363, 18.837120393, 17.132695148, 15.697279953, 14.418110582,
  13.673511972, 13.340117643, 13.328853266, 13.571214987, 14.593659384, 17.381999356, 19.358000569, 20.402615827,
  21.048527916, 21.183710383, 21.550561134, 21.711601818, 21.884421463, 21.449661254, 21.539257242, 22.049938502,
  21.70201735, 21.230521641, 20.906873734, 20.174341064, 18.741591567, 16.960596991, 15.376687875, 14.219307875,
  13.42340391, 13.052163728, 13.007485441, 13.381755291, 14.364458444, 16.973999912, 18.924173107, 20.072706643,
  20.553054063, 20.489731536, 20.866655816, 21.121374855, 21.336516901, 21.186889833, 21.297516085, 21.626209562,
  21.321042154, 20.846604069, 20.361705103, 19.599034936, 18.067659903, 16.443645192, 14.849004117, 13.426372194,
  12.719563117, 12.275956003, 12.140580952, 12.509915309, 13.478836266, 15.849343748, 17.623045819, 18.741940826,
  19.248713138, 19.588248001, 20.263816488, 20.29396997, 20.253846726, 20.110864899, 20.28734219, 20.841676367,
  20.653219382, 20.067228516, 19.585092341, 18.673301318, 17.056493208, 15.491465761, 13.895962395, 12.512282729,
  11.564117773, 11.051505649, 10.826617961, 10.921364571, 11.280969335, 12.130885607, 13.07414949, 14.40360896,
  15.914897378, 16.865056165, 17.666554377, 17.968089083, 18.033008376, 17.938107685, 18.098812582, 18.720035465,
  18.665509849, 18.240330079, 17.762259396, 17.050741431, 15.633009461, 14.254247699, 12.651968823, 11.240214088,
  10.324055509, 9.806022097, 9.425486052, 9.522333395, 9.826327493, 10.428311451, 11.090439019, 12.303864644,
  13.695155101, 14.402923422, 14.891531171, 15.081243828, 15.386291518, 15.341802878, 15.980123996, 15.979769997,
  14.520828089, 13.608533025, 13.150389012, 12.90658218, 12.621033942, 12.223452008, 11.450591819, 10.747779878,
  10.479109855, 10.404920739, 10.432669342, 10.478830308, 10.719636819, 11.119467146, 11.543043757, 12.071257616,
  13.118518785, 13.827580495, 14.289637524, 14.635596624, 14.724522035, 14.324680172, 14.125327345, 14.500348241,
  14.56678939, 14.468877062, 14.283061802, 13.96620036, 13.322593367, 12.71360749, 11.750023095, 10.841545315,
  10.185785438, 9.724433589, 9.505900974, 9.711527745, 9.999823579, 10.576489731, 10.955509557, 11.669389621,
  12.893919559, 13.775810898, 14.228123691, 14.581130936, 14.788628774, 14.449309452, 14.401788018, 14.716298918,
  14.745093117, 14.638905705, 14.55128126, 14.11271416, 13.455020415, 12.748619847, 11.65482179, 10.665305985,
  9.982214209, 9.593651053, 9.533226179, 9.854216687, 10.796280149, 12.758029023, 14.20867274, 15.351375128,
  16.332855679, 16.550199021, 16.8834289, 16.946887336, 17.350641491, 17.485773694, 17.893643667, 18.522938319,
  18.412181277, 18.008667232, 17.751184791, 17.18191229, 15.937278903, 14.698642023, 13.151990789, 12.041777215,
  11.375924096, 10.970569141, 10.877842589, 11.174026557, 12.099862655, 13.908875826, 15.231401714, 16.207997577,
  16.733350481, 17.324936542, 17.557482048, 17.892317289, 18.182656157, 17.885362468, 18.059778713, 18.622234336,
  18.547268144, 18.168544436, 17.859473755, 17.278168901, 16.000223936, 14.679412154, 13.376057855, 12.222978832,
  11.583981122, 11.19827143, 11.177640904, 11.427373557, 12.25500514, 14.001178326, 15.410373467, 16.380538967,
  17.069581693, 17.311420868, 17.479509076, 17.639917032, 18.00471399, 18.009446621, 18.240732552, 18.680323265,
  18.527407721, 18.151985048, 17.536252594, 16.963724907, 15.762607116, 14.511606448, 13.353177435, 12.16074231,
  11.440315514, 11.0966421, 10.773825856, 10.848205826, 11.288072406, 12.12612636, 12.953969172, 14.053807344,
  15.251238295, 15.952576641, 16.597377331, 16.815952508, 17.131224707, 17.086066022, 17.268317915, 17.730402205,
  17.653037066, 17.183711239, 16.766357727, 16.140438639, 15.038462322, 13.855616007, 12.465510864, 11.235683984,
  10.457981622, 9.897236275, 9.69032921, 9.708680795, 9.934011664, 10.363520734, 10.785481321, 11.46059803,
  12.436498439, 13.222220357, 13.725948492, 14.151190177, 14.529107208, 14.709596157, 15.00266885, 15.674727145,
  15.671632849, 15.100150855, 14.330373577, 13.314756093, 12.342450329, 11.799636726, 11.366024808,
];
