import { Button } from 'components/Button/Button';
import { Container } from 'components/Container/Container';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BtnCol, SCnt, SGrid, SLink, STextCnt, STitle, TextCol } from './styles';
import keys from 'i18n/keys';
import { useWindowSize } from 'react-use';
import { CookiesRodoModal } from './CookiesRodoModal';

export type AvialableTexts = 'privacy' | 'rodo' | null;

export const CookiesRodo = ({ onClick }: { onClick: (arg: boolean) => void }) => {
  const [currentOverlay, setCurrentOverlay] = useState<AvialableTexts>(null);
  const { t } = useTranslation();
  const { width } = useWindowSize();
  useEffect(() => {
    if (!!currentOverlay) {
      document.querySelector('body')?.classList?.add('o-hidden');
    } else {
      document.querySelector('body')?.classList?.remove('o-hidden');
    }
  }, [currentOverlay]);

  const handleClose = () => {
    setCurrentOverlay(null);
  };

  const openRodo = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setCurrentOverlay('rodo');
  };
  const openPrivacy = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setCurrentOverlay('privacy');
  };
  if (width < 1200) {
    return null;
  }
  const handleClick = () => {
    localStorage.setItem('cookie', 'true');
    onClick(true);
  };
  return (
    <>
      <CookiesRodoModal type={currentOverlay} onClose={handleClose} />
      <SCnt>
        <Container>
          <SGrid>
            <TextCol>
              <STextCnt>
                <STitle>{t(keys.RODO_TITLE)}</STitle>
                <p>{t(keys.RODO_DESC)}</p>
              </STextCnt>
            </TextCol>
            <div>
              <SLink onClick={openPrivacy}>{t(keys.PRIVACY_LINK_TEXT)}</SLink>
              <SLink onClick={openRodo}>{t(keys.RODO_LINK_TEXT)}</SLink>
            </div>
            <BtnCol>
              <Button variant="contained" onClick={handleClick}>
                {t(keys.ACCEPT)}
              </Button>
              <Button variant="contained" onClick={handleClick}>
                {t(keys.DECLINE)}
              </Button>
            </BtnCol>
          </SGrid>
        </Container>
      </SCnt>
    </>
  );
};
