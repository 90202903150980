import styled from '@emotion/styled';
import { Footer } from 'components/Footer/Footer';
import { TileTitle } from 'components/Titles/TileTitle';

export const SCnt = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px;
  background-color: #fff;
  z-index: 500000000000;
`;

export const SGrid = styled.div`
  display: flex;
`;

export const TextCol = styled.div`
  flex-grow: 1;
`;

export const BtnCol = styled.div`
  margin-left: 130px;
  display: flex;
  flex-direction: column;

  button:first-of-type {
    margin-bottom: 16px;
  }
`;

export const SLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
  display: block;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const STitle = styled(TileTitle)`
  font-weight: 700;
  margin-bottom: 8px;
`;

export const STextCnt = styled.div`
  max-width: 500px;
`;

export const Soverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 500000000001;
  overflow: auto;
  padding: 100px;
`;

export const SContent = styled.div`
  max-width: 950px;
  position: relative;
  margin: 0 auto;
  padding: 32px 52px;
`;

export const SFooter = styled(Footer)`
  margin-bottom: 50px;
`;

export const SCloseBtn = styled.button`
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  right: 15px;
  top: 15px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
`;
