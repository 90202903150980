import keys from 'i18n/keys';

export const EXPORT_LABELS = {
  electricity: [
    keys.HOUR,
    keys.DAY,
    keys.EXPORT_ENERGY_COL_NAME,
    keys.EXPORT_WIND_OFFSHORE_COL_NAME,
    keys.EXPORT_WIND_COL_NAME,
    keys.EXPORT_WIND_5MW_COL_NAME,
    keys.EXPORT_PHOTOVOLTAIC_COL_NAME,
    keys.EXPORT_PHOTOVOLTAIC_BIF_COL_NAME,
  ],
} as const;
