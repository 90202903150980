import styled from '@emotion/styled';
import { Button } from 'components/Button/Button';

export const ScenarioTitle = styled.div`
  padding-left: 4px;
  padding-right: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;

export const SButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  text-align: left;
  gap: 0;

  .text {
    max-width: 90%;
  }
`;
