/** @jsx jsx */
import { keys } from './../../i18n/keys';
import { jsx } from '@emotion/react';
import { PageCnt } from 'components/Card/PageCnt';
import { WithSidebar } from 'components/Sidebar/Sidebar';
import { SimulatorHeader } from 'components/SimulatorHeader/SimulatorHeader';
import { TileTitle } from 'components/Titles/TileTitle';
import { EachCitizenCalc } from 'features/Costs/EachCitizenCalc/EachCitizenCalc';
import { EnergySourcesTable } from 'features/Costs/EnergySourcesTable';
import { Hydrogen } from 'features/Costs/Hydrogen';
import { Storage } from 'features/Costs/Storage';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CostsPageCnt, SAllCost, Spacer, SPartnersBanner, SSelect, SStopaDyskontowa } from './styles';
import { CalcValues, selectOptions } from './consts';
import { useTitle } from 'react-use';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';
import { roundNumb } from 'services/utils/roundNumb';
import { Input } from 'components/Input/Input';
import { useDispatchAction } from 'hooks/useDispatchAction';
import { getKoszty } from 'services/slices/obliczeniaSlice/selectors';
import { setNieLiczKosztowInfrastruktury, setStopaDyskonta } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { Box } from 'components/common';
import { CieploCosts } from 'features/Costs/CieploCosts';

export const Costs = () => {
  const { t } = useTranslation();
  const [currOption, setCurrOption] = useState(selectOptions[1]);
  useTitle(t(keys.PAGE_TITLE_COSTS));
  const {
    calculations: { łącznyKosztBudowyInfrastruktury },
    stopa_dyskonta,
    nie_licz_kosztów_istniejącej_infrastruktury,
  } = useAppSelector(getKoszty);

  const dispatch = useAppDispatch();
  const checkboxChange = () => {
    dispatch(setNieLiczKosztowInfrastruktury(!nie_licz_kosztów_istniejącej_infrastruktury));
  };

  const stopaDyskontaSet = useDispatchAction({
    action: setStopaDyskonta,
    name: 'stopa_dyskonta',
    value: stopa_dyskonta,
    percentage: true,
    max: 0.1,
  });

  return (
    <div>
      <WithSidebar>
        <SimulatorHeader title={t(keys.PAGE_TITLE_COSTS)} />
        <PageCnt>
          <TileTitle>{t(keys.COSTS_TEXT_1)}</TileTitle>
          <Box maxWidth="300px">
            <SSelect options={selectOptions} value={currOption} onChange={setCurrOption} />
          </Box>

          <SAllCost>
            <span className="cost-text">
              <span className="text">{t(keys.SUM_OF_COSTS)} </span>
              <span className="price-cnt">
                <span className="price">{roundNumb(łącznyKosztBudowyInfrastruktury, 0)} </span>
                <span className="currency">{t(keys.CURRENCY_MLD_LABEL)}</span>
              </span>
            </span>
            <Checkbox
              id="include-costs"
              name="include-costs"
              label={t(keys.INCLUDE_COSTS_FROM_CURRENT_INFRASTRUCTURE)}
              value={nie_licz_kosztów_istniejącej_infrastruktury}
              onChange={checkboxChange}
            />
          </SAllCost>
          <CostsPageCnt>
            <div>
              {currOption.value === CalcValues.Kraj ? (
                <div>
                  <SStopaDyskontowa>
                    <TileTitle>{t(keys.DISCOUNT_RATE)}</TileTitle>
                    <Input {...stopaDyskontaSet} endAdornment="%" />
                  </SStopaDyskontowa>
                  <Spacer>
                    <EnergySourcesTable />
                  </Spacer>
                  <Spacer>
                    <Storage />
                  </Spacer>
                  <Spacer>
                    <CieploCosts />
                  </Spacer>
                  <Hydrogen />
                  {/* <Sum /> */}
                </div>
              ) : (
                <EachCitizenCalc />
              )}
            </div>
          </CostsPageCnt>
          <div>
            <SPartnersBanner />
          </div>
        </PageCnt>
      </WithSidebar>
    </div>
  );
};
