import { read, utils } from 'xlsx';

export const ExcelToJSON = <T>(file: File): Promise<T[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e!.target!.result;
      const workbook = read(data, {
        type: 'binary',
      });
      workbook.SheetNames.forEach(function (sheetName) {
        const XL_row_object = utils.sheet_to_json<T>(workbook.Sheets[sheetName]);
        resolve(XL_row_object);
      });
    };

    reader.onerror = function (ex) {
      reject(ex);
    };

    reader.readAsBinaryString(file);
  });
};
