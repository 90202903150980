export const COLORS = {
  WIND_OFF_SHORE: '#78cfba',
  WIND_ON_LAND: '#87a771',
  PHOTOVOLTAIC: '#ffd34c',
  ATOM: '#FF658E',
  NATURAL_GAS: '#70ad47',
  HYDROGEN: '#b2de84',
  HYDROGEN_FROM_ELECTROLYSIS: '#C7BDF3',
  COGENERATION: '#F2ADA0',
  ENERGY_OTHER_DEMAND: '#7DD9F0',
  CWU_HEAT: '#f8cbad',
  HEAT_PUMP: '#f8cbad',
  TRANSPORT: '#c69f5a',
  ELECTROCITY_STORAGE: '#bdd7ee',
  HEAT_STORAGE: '#F2ADA0',
  CO_HEAT: '#f8cbad',
  DISPOSAL_SOURCES: '#808080',
  SUM_DEMAND: '#2e75b6',
  SUM_DEMAND_WITH_ELASTICITY: '#636363',
  SUM_PRODUCTION: '#548235',
  UNUSED_ENERGY: '#DBDBDB',
  ENERGY_LOOSES: '#ff3333',
} as const;

export const NEW_COLORS = {
  energia_elektryczna: '#388CD6CF',
  energia_elektryczna_magazyny: '#81c5f0',
  cieplo_przemyslowe: '#f7986f',
  cieplo_przemyslowe_magazyny: 'rgb(245, 200, 170)',
  cieplo_budynki: '#ffb44e',
  cieplo_budynki_cieplo_otoczenia: 'rgb(255, 245, 215)',
  cieplo_budynki_magazyny: '#ffe1aa',
  transport: '#d5bb82',
  wodor: '#b4e274',
  niewykorzystana_energia: 'rgb(240, 240, 240)',
  niewykorzystana_energia_border: 'rgb(210, 210, 210)',
  straty: 'rgb(255, 170, 170)',

  wiatr_lad: '#689b4c',
  wiatr_morze: '#90dbc8',
  PV: '#ffde6b',
  atom: '#ff9190',
  cieplo_otoczenia_z_produckji: 'rgb(255, 245, 215)',
  zrodla_dyspozycyjne: '#94a5a1',
  cieplo_kogeneracja: '#f0b9b3',
  energia_z_magazynu_pradu: '#8ccaf1',
  energia_z_magazynu_przemyslowego: 'rgb(245, 200, 170)',
  energia_z_magazynu_ciepla: 'rgb(250, 225, 180)',

  produkcja_energii: '#08354a',

  energia_w_magazynie_pradu: 'rgb(180, 215, 240)',
  zapotrzebowanie: '#023f5f',
  zapotrzebowanie_z_elastycznoscia: '#2e8db9',
  zapotrzebowanie_z_elastycznoscia_z_wodorem: '#a9f950',
};
