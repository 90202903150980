import React, { useRef, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { SModal, SOverlay } from './styles';
import { ReactComponent as Icon } from 'assets/loader.svg';
import { useTranslation } from 'react-i18next';
import keys from 'i18n/keys';
import FocusTrap from 'focus-trap-react';
import { Button } from 'components/Button/Button';
import { Spacer } from 'components/common';
const modalsContainer = document.getElementById('modals') as HTMLElement;
type ModalProps = {
  open: boolean;
  children?: ReactNode;
  onClose?: () => void;
  getMaxPosition?: boolean;
  footer?: ReactNode;
};
export const Modal = ({ open, onClose, children, getMaxPosition, footer }: ModalProps) => {
  const modalContent = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useEffect(() => {
    const keyUpEvent = e => {
      if (e.keyCode === 27) {
        onClose?.();
      }
    };
    window.addEventListener('keydown', keyUpEvent);
    return () => {
      window.removeEventListener('keydown', keyUpEvent);
    };
  }, []);
  useEffect(() => {
    if (open) {
      document.querySelector('body')?.classList?.add('o-hidden');
      setTimeout(() => {
        if (modalContent.current) {
          modalContent.current.scrollTop = 0;
        }
      }, 10);
    } else {
      document.querySelector('body')?.classList?.remove('o-hidden');
    }
  }, [open]);
  if (!open) {
    return null;
  }
  const handleClose = () => {
    onClose?.();
  };
  const handlePrevent = e => {
    e.stopPropagation();
  };
  return ReactDOM.createPortal(
    <SOverlay onClick={handleClose}>
      <FocusTrap
        focusTrapOptions={{
          allowOutsideClick: true,
        }}
      >
        <SModal ref={modalContent} getMaxPosition={getMaxPosition} onClick={handlePrevent}>
          <div>{children}</div>
          <Spacer mt="auto" pt="24px">
            {footer || (
              <Button fullWidth onClick={handleClose}>
                {t(keys.CLOSE)}
              </Button>
            )}
          </Spacer>
        </SModal>
      </FocusTrap>
    </SOverlay>,
    modalsContainer,
  );
};
