import React, { memo } from 'react';
import { Tooltip as IconTooltip } from '../../../components/Tooltip/Tooltip';
import { SBarChartCnt, SHead, SSection } from './styles';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Legend as LegendCnt } from '../../../components/Legend/Legend';
import { useToggle } from '../../../hooks/useToggle';
import { useBarDataset } from './hooks/useBarDataset';
import { useBarOptions } from './hooks/useBarOptions';
import keys from 'i18n/keys';
import { useTranslation } from 'react-i18next';
import { roundNumb } from 'services/utils/roundNumb';
import { TileTitle } from 'components/Titles/TileTitle';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';
import { LegendButton } from 'components/Legend/LegendButton';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const labels = [keys.CONSUMPTION, keys.PRODUCTION];

export const EnergyBalance = memo(({ id, legendExpanded }: { id?: string; legendExpanded?: boolean }) => {
  const [isLegendExpanded, toggleLegend] = useToggle(legendExpanded);
  const { t } = useTranslation();

  const { options, datasets } = useBarOptions();
  const data: ChartData<'bar', number[], string> = {
    labels: labels.map(o => t(o)),
    datasets,
  };

  const legendOptions = datasets.reduce(
    (acc, el, index) => {
      const labels = el.label?.split('|');
      if (!labels || !el.backgroundColor) {
        console.error('Labels empty');
        return acc;
      }
      labels[0] &&
        acc[0].options.push({ color: el.backgroundColor[0], name: t(labels[0]), val: roundNumb(el.data[0], 0) });
      labels[1] &&
        acc[1].options.push({ color: el.backgroundColor[1], name: t(labels[1]), val: roundNumb(el.data[1], 0) });
      return acc;
    },
    [
      { label: t(keys.ENERGY_CONSUMPTION), options: [] },
      { label: t(keys.ENERGY_PRODUCTION), options: [] },
    ] as any,
  );

  const describedby = `${legendOptions[0].label}- ${legendOptions[0].options
    .map(o => `${o.name}: ${o.val} TWh`)
    .join(',')}
    ${legendOptions[1].label}- ${legendOptions[1].options.map(o => `${o.name}: ${o.val} TWh`).join(',')}
    `;
  return (
    <SSection id={SIMULATOR_INTRO_STEPS.ENERGY_BALANCE}>
      <SHead>
        <TileTitle id="energyBalance" tooltipContent={t(keys.ENERGY_BALANCE_TITLE_TOOLTIP)}>
          {t(keys.ENERGY_BALANCE_TITLE)}
        </TileTitle>
        <LegendButton expanded={isLegendExpanded} onClick={toggleLegend} />
      </SHead>
      <div className="bar-chart" id={id}>
        <SBarChartCnt className="bar-chart-cnt">
          <Bar
            aria-label="Wykres przedstawiający stosunek zużycia energii do produkcji energii"
            aria-describedby={describedby}
            role="img"
            options={options}
            data={data}
            plugins={[ChartDataLabels]}
          />
        </SBarChartCnt>
        {isLegendExpanded && <LegendCnt optionsWithLabel={legendOptions} />}
      </div>
    </SSection>
  );
});
