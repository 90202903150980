export enum PeriodEnum {
  QUATER = 'quater',
  MONTTH = 'month',
  YEAR = 'year',
}

export const PeriodEnumValues = {
  [PeriodEnum.QUATER]: ['I', 'II', 'III', 'IV'],
  [PeriodEnum.MONTTH]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [PeriodEnum.YEAR]: [],
} as const;

export enum PeriodLabels {
  QUATER = 'QUATER',
  MONTTH = 'MONTTH',
  YEAR = 'YEAR',
}
