import styled from '@emotion/styled';
import { Button } from 'components/Button/Button';

export const SChartWrapper = styled.div`
  position: fixed;
  width: 0;
  top: 0;
  z-index: -1;
  height: 0;
  clip-path: inset(0 100% 0 0);

  .bar-chart-cnt {
    width: 840px;
    padding-left: 10px;
  }
  #costs-table {
    width: 840px;
  }

  .line-chart-wrapper {
    height: unset;
    width: 840px;

    .chart-cnt {
      height: 250px;
    }
    canvas {
      width: 840px !important;
      height: 250px !important;
    }
  }

  #energy-balance {
    width: 840px !important;
  }
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
  }
`;

export const Btn = styled(Button)`
  margin: 0 auto;
  margin-top: 16px;
`;
