import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';
import { setStateFromQueryParams } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { AVAILABLE_SCENARIOS, defaultScenario } from 'services/slices/obliczeniaSlice/scenarios';

import { mergeProdukcjaEnergii } from './mergeProdukcjaEnergii';
import { getObliczeniaReducer } from 'services/slices/obliczeniaSlice/selectors';
import { mergeObliczenia } from './mergeObliczenia';
import { mergeKoszty } from './mergeKoszty';
import { getSearchParam } from './utils';
import { mergeWodor } from './mergeWodor';
import { updateScenario } from 'services/slices/periodSlice';

export const AppParams = () => {
  const { obliczenia, produkcjaEnergiiElektrycznej, wodór } = useAppSelector(getObliczeniaReducer);

  const dispatch = useAppDispatch();
  const selectedScenario =
    AVAILABLE_SCENARIOS.find((el, i) => i === Number(getSearchParam('scenario'))) || defaultScenario;

  useEffect(() => {
    const produkcjaEnergiiElektrycznejMerged = mergeProdukcjaEnergii(
      produkcjaEnergiiElektrycznej,
      selectedScenario.data,
    );

    const obliczeniaMerged = mergeObliczenia(obliczenia, selectedScenario.data);
    const kosztyMerged = mergeKoszty();
    const wodorMerged = mergeWodor(wodór, selectedScenario.data);
    dispatch(updateScenario(selectedScenario));
    dispatch(
      setStateFromQueryParams({
        produkcjaEnergiiElektrycznej: produkcjaEnergiiElektrycznejMerged,
        obliczenia: obliczeniaMerged,
        koszty: kosztyMerged,
        wodor: wodorMerged,
      }),
    );
  }, []);

  return null;
};
