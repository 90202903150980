import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultScenario } from './obliczeniaSlice/scenarios';
import { ScenarioData } from './obliczeniaSlice/types';

const initialState = {
  currentScenario: defaultScenario,
  shouldRunSimulatorTour: !localStorage.getItem('shouldRunSimulatorTour'),
};

const periodSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    updateScenario(state, action: PayloadAction<ScenarioData>) {
      state.currentScenario = action.payload;
    },
    updateSimulatorTour(state, action: PayloadAction<boolean>) {
      state.shouldRunSimulatorTour = action.payload;
      localStorage.setItem('shouldRunSimulatorTour', 'false');
    },
  },
});

export const { updateScenario, updateSimulatorTour } = periodSlice.actions;
export const period = periodSlice.reducer;
