import React from 'react';
import { SCnt, SIcon, Stext, STextCnt } from './styles';
export const NotLoadedTranslation = () => {
  return (
    <SCnt>
      <SIcon />
      <STextCnt>
        <Stext bigger>404</Stext>
      </STextCnt>
    </SCnt>
  );
};
