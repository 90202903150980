import { Button } from 'components/Button/Button';
import { EditButton } from 'components/Button/EditButton';
import { Collapse } from 'components/Collapse/Collapse';
import { DataInput } from 'components/DataInput/DataInput';
import { Fieldset } from 'components/Fieldset/Fieldset';
import { Modal } from 'components/Modal/Modal';
import { Box, Spacer } from 'components/common';
import { TransportTile } from 'features/AdvancedOptionsTiles/TransportTile';
import { useDispatchAction } from 'hooks/useDispatchAction';
import keys from 'i18n/keys';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';

import {
  ObliczeniaTypeProcentoweZapotrzebowanieNaTransportPayload,
  ObliczeniaTypeTransportPayload,
} from 'services/slices/obliczeniaSlice/obliczenia/types';
import {
  setAllTransportInputs,
  setProcentoweZapotrzebowanieNaTransport,
  setScenario,
  setTransportInputs,
} from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { getObliczenia } from 'services/slices/obliczeniaSlice/selectors';
import { ModalTitle } from '../styles';
import { roundNumb } from 'services/utils/roundNumb';
import { DataInputContext } from 'components/DataInput/dataInputContext';
import { Tooltip } from 'components/Tooltip/Tooltip';

export const TransportCollapsible = () => {
  const { t } = useTranslation();
  const {
    inputs: {
      transport: {
        ilość_aut_osobowych,
        inne_rodzaje_transportu,
        liczba_pasażerokilometrów,
        udział_kolei_w_transporcie,
        wolumen_transportu_towarów,
        zużycie_energii_aut_osobowych,
        średni_przejeżdżany_dystans_aut_osobowych,
        procentowe_zapotrzebowanie_na_transport,
        procentowe_zapotrzebowanie_na_transport_custom,
        calculations,
      },
    },
    calculations: { zapotrzebowanie_na_transport },
  } = useAppSelector(getObliczenia);
  const { currentScenario } = useAppSelector(state => state.period);
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const iloscAutSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'ilość_aut_osobowych',
    value: ilość_aut_osobowych,
  });
  const zuzycieEnergiiAutSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'zużycie_energii_aut_osobowych',
    value: zużycie_energii_aut_osobowych,
  });
  const sredniDystansAutSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'średni_przejeżdżany_dystans_aut_osobowych',
    value: średni_przejeżdżany_dystans_aut_osobowych,
  });
  const liczbaPasazeroKmSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'liczba_pasażerokilometrów',
    value: liczba_pasażerokilometrów,
    percentage: true,
    max: 999,
  });
  const transportTowarowSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'wolumen_transportu_towarów',
    value: wolumen_transportu_towarów,
    percentage: true,
    max: 999,
  });
  const udzialKoleiSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'udział_kolei_w_transporcie',
    value: udział_kolei_w_transporcie,
    percentage: true,
    max: 999,
  });
  const transportInneSet = useDispatchAction<ObliczeniaTypeTransportPayload>({
    action: setTransportInputs,
    name: 'inne_rodzaje_transportu',
    value: inne_rodzaje_transportu,
    percentage: true,
    max: 999,
  });

  const procentoweZapotrzebowanieNaTransportSet =
    //@ts-ignore
    useDispatchAction<ObliczeniaTypeProcentoweZapotrzebowanieNaTransportPayload>({
      action: setProcentoweZapotrzebowanieNaTransport,
      name: 'procentowe_zapotrzebowanie_na_transport',
      value: procentowe_zapotrzebowanie_na_transport ?? 0,
      percentage: true,
      max: 999,
    });

  const handleClose = () => {
    if (procentowe_zapotrzebowanie_na_transport_custom === null) {
      dispatch(setAllTransportInputs(currentScenario.data.obliczenia.transport));
    } else {
      dispatch(
        setProcentoweZapotrzebowanieNaTransport({
          name: 'procentowe_zapotrzebowanie_na_transport',
          value: procentowe_zapotrzebowanie_na_transport_custom,
        }),
      );
    }
    setModalOpen(false);
  };

  return (
    <>
      <DataInput
        label={t(keys.TRANSPORT_AND_OTHERS)}
        id={'transport'}
        unit={'%'}
        unitColor="#AD6800"
        labelTooltipId="TOOLTIP_TRANSPORT_SINGLE_CONTROL"
        labelTooltipContent={t(keys.TOOLTIP_TRANSPORT_SINGLE_CONTROL)}
        editBtn={<EditButton onClick={() => setModalOpen(true)}> </EditButton>}
        {...procentoweZapotrzebowanieNaTransportSet}
      />
      <Modal
        open={modalOpen}
        onClose={handleClose}
        footer={
          <Box d="flex" justify="space-around">
            <Button
              onClick={() => {
                dispatch(
                  setProcentoweZapotrzebowanieNaTransport({
                    name: 'procentowe_zapotrzebowanie_na_transport_custom',
                    value: procentowe_zapotrzebowanie_na_transport,
                  }),
                );
                setModalOpen(false);
              }}
            >
              <Tooltip id="procentowe_zapotrzebowanie_na_transport-save" content={t(keys.MODAL_TRANSPORT_SAVE_BTN)} />
              <Box ml="4px" as="span">
                {t(keys.SAVE)} (
                {roundNumb((calculations.calculated_procentowe_zapotrzebowanie_na_transport ?? 0) * 100)}%)
              </Box>
            </Button>
            <Button
              onClick={() => {
                dispatch(setAllTransportInputs(currentScenario.data.obliczenia.transport));
                // setModalOpen(false);
              }}
            >
              <Tooltip id="procentowe_zapotrzebowanie_na_transport-save" content={t(keys.MODAL_TRANSPORT_RESET_BTN)} />
              <Box ml="4px" as="span">
                {t(keys.RESET_DATA)}
              </Box>
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              <Tooltip
                id="procentowe_zapotrzebowanie_na_transport_custom-cancel"
                content={t(keys.MODAL_TRANSPORT_CANCEL_BTN)}
              />
              <Box ml="4px" as="span">
                {t(keys.CANCEL)} (
                {roundNumb(
                  (procentowe_zapotrzebowanie_na_transport_custom === null
                    ? procentowe_zapotrzebowanie_na_transport ?? 0
                    : procentowe_zapotrzebowanie_na_transport_custom) * 100,
                )}
                %)
              </Box>
            </Button>
          </Box>
        }
      >
        <DataInputContext.Provider value={180}>
          <Fieldset
            legendUnit={
              <span>
                {roundNumb(calculations.auta_osobowe)} {t(keys.TWH)}
              </span>
            }
            legend={
              <Box d="flex" gap="4px">
                <Tooltip id="wind-offshore-cell" content={t(keys.TOOLTIP_TRANSPORT_CARS)} />
                <span>{t(keys.TRANSPORT_CARS)}</span>
              </Box>
            }
          >
            <Spacer mb="6px">
              <DataInput unit={t(keys.MLN)} label={t(keys.CARS_COUNT)} id={'ilość_aut_osobowych'} {...iloscAutSet} />
            </Spacer>
            <Spacer mb="6px">
              <DataInput
                label={t(keys.CARS_AVG_ENERGY_CONSUMTION)}
                unit={t(keys.KWH_PER_100KM)}
                id={'zuzycieEnergiiAutSet'}
                {...zuzycieEnergiiAutSet}
              />
            </Spacer>
            <Spacer>
              <DataInput
                label={t(keys.CARS_AVG_DISTANCE_TRAVEL)}
                unit={t(keys.KM_PER_YEAR)}
                id={'sredniDystansAutSet'}
                {...sredniDystansAutSet}
              />
            </Spacer>
          </Fieldset>
          <Fieldset
            legendUnit={
              <span>
                {roundNumb(calculations.transport_zbiorowy)} {t(keys.TWH)}
              </span>
            }
            legend={
              <Box d="flex" gap="4px">
                <Tooltip id="wind-offshore-cell" content={t(keys.TOOLTIP_TRANSPORT_PUBLIC_TITLE)} />
                <span>{t(keys.TRANSPORT_PUBLIC_TITLE)}</span>
              </Box>
            }
          >
            <Spacer>
              <DataInput
                label={t(keys.TRANSPORT_PUBLIC_LABEL)}
                unitColor="#AD6800"
                unit="%"
                id={'liczbaPasazeroKmSet'}
                {...liczbaPasazeroKmSet}
              />
            </Spacer>
          </Fieldset>
          <Fieldset
            legendUnit={
              <span>
                {roundNumb(calculations.transport_towarowy)} {t(keys.TWH)}
              </span>
            }
            legend={
              <Box d="flex" gap="4px">
                <Tooltip id="wind-offshore-cell" content={t(keys.TOOLTIP_TRANSPORT_GOODS_MODAL)} />
                <span>{t(keys.TRANSPORT_GOODS_MODAL)}</span>
              </Box>
            }
          >
            <Spacer mb="6px">
              <DataInput
                label={t(keys.TRANSPORT_GOODS_VOLUMEN_MODAL)}
                unitColor="#AD6800"
                unit="%"
                id={'transportTowarowSet'}
                {...transportTowarowSet}
              />
            </Spacer>
            <Spacer mb="6px">
              <DataInput
                label={t(keys.TRANSPORT_GOODS_RAIL_SHARE_MODAL)}
                unitColor="#AD6800"
                unit="%"
                id={'udzialKoleiSet'}
                {...udzialKoleiSet}
              />
            </Spacer>
          </Fieldset>
          <Fieldset
            legendUnit={
              <span>
                {roundNumb(calculations.inny_transport)} {t(keys.TWH)}
              </span>
            }
            legend={
              <Box d="flex" gap="4px">
                <Tooltip id="wind-offshore-cell" content={t(keys.TOOLTIP_TRANSPORT_OTHER_MODAL_TITLE)} />
                <span>{t(keys.TRANSPORT_OTHER_MODAL_TITLE)}</span>
              </Box>
            }
          >
            <Spacer>
              <DataInput
                label={t(keys.TRANSPORT_OTHER_MODAL)}
                unitColor="#AD6800"
                unit="%"
                id={'transportInneSet'}
                {...transportInneSet}
              />
            </Spacer>
          </Fieldset>
        </DataInputContext.Provider>
      </Modal>
    </>
  );
};
