import styled from '@emotion/styled';
import { ControlledPeriod } from 'features/Period/ControlledPeriod';

export const SPeriod = styled(ControlledPeriod)`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

export const Spacer = styled.div`
  margin-bottom: 40px;
`;
