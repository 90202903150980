import styled from '@emotion/styled';
import { PartnersBanner } from 'components/PartnersBanner/PartnersBanner';
import { Select } from 'components/Select/Select';

export const SSelect = styled(Select)`
  background: #e6eef0;
  border: 1px solid rgba(151, 146, 133, 0.12);
  box-shadow: 0px 0px 0px #cbdafc;
  border-radius: 4px;
  margin-bottom: 8px;
`;

export const SAllCost = styled.p`
  display: flex;
  align-items: center;

  .cost-text {
    margin-right: 48px;
    min-width: 200px;
  }

  .text {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin-right: 20px;
  }

  .price {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin-right: 8px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: 5px;
      left: 0;
      background-color: ${({ theme }) => theme.addAlpha(theme.primary, 0.3)};
    }
  }

  .price-cnt {
    display: inline-block;
    min-width: 130px;
  }

  .currency {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: ${({ theme }) => theme.addAlpha(theme.primary, 0.5)};
  }
`;

export const Spacer = styled.div`
  margin-bottom: 40px;
`;

export const SStopaDyskontowa = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .tile-title {
    margin-bottom: 0;
  }

  .input-cnt {
    width: 150px;
    margin-left: 16px;
    margin-bottom: 0;
  }
`;

export const SPartnersBanner = styled(PartnersBanner)`
  margin-top: 24px;
`;

export const CostsPageCnt = styled.div`
  .tile-title {
    font-size: 16px;
  }
`;
