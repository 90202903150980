import { CieploZExcela } from 'services/slices/obliczeniaSlice/types';
import { HeatExport } from '../types';

export const heatImport = (json: HeatExport[], t): CieploZExcela => {
  // if (json.length !== 365) {
  //   throw new Error('Not enough length');
  // }
  const formattedData = json.reduce((acc, el, index) => {
    const objectValues = Object.values(el);
    if (objectValues.length !== 3) {
      throw new Error('different keys');
    }

    acc[index] = +objectValues[2] as number;
    return acc;
  }, [] as number[]);
  return formattedData;
};
