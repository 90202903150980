import React, { ReactNode } from 'react';
import { SHeader, Title } from './styles';
export type SimulatorHeaderProps = {
  title: ReactNode;
  children?: ReactNode;
  font?: 'big' | 'medium';
};

export const SimulatorHeader = ({ title, children, font }: SimulatorHeaderProps) => {
  return (
    <SHeader>
      <Title font={font}>{title}</Title>
      {children}
    </SHeader>
  );
};
