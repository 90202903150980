import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Navigate, Route, Routes } from 'react-router-dom';
import { allRutes } from '../services/consts/routes';
import { ScenarioTranslateResponse, TranslateResponse } from 'services/types/TranslateResponse';
import { useCurrentLang } from 'services/configuration/i18n/utils';
import { Loading } from 'features/Loading/Loading';
import { useAppDispatch } from 'services/configuration/store/hooks';
import { CookiesRodo } from 'App/CookiesRodo/CookiesRodo';
import { NotLoadedTranslation } from 'features/MobileNotSupported/404';
import ScrollToTop from 'App/ScrollToTop/ScrollToTop';
import { setScenario } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { AVAILABLE_SCENARIOS } from 'services/slices/obliczeniaSlice/scenarios';
import { AppParams } from 'App/AppParams/AppParams';
import { AsyncLoadData } from 'App/AsyncLoadData/AsyncLoadData';
function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isCookieAccepted, setIsCookieAccepted] = useState(!!localStorage.getItem('cookie'));
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const lang = useCurrentLang();
  useEffect(() => {
    // dispatch(setScenario(AVAILABLE_SCENARIOS[0].data));
    const getTranslations = async () => {
      try {
        i18n.changeLanguage(lang);
        const [scenarioResponse, translations] = await Promise.all([
          axios.get<ScenarioTranslateResponse[]>(process.env.REACT_APP_SCENARIOS_URL as string, {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN}` as string,
            },
          }),
          axios.get<TranslateResponse[]>(process.env.REACT_APP_TRANSLATIONS_URL as string, {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN}` as string,
            },
          }),
        ]);
        const en = {};
        const pl = {};
        scenarioResponse.data.forEach(el => {
          en[`${el.id}_title`] = el.title_en;
          en[`${el.id}_label`] = el.label_en;
          en[`${el.id}_short_description`] = el.short_description_en;
          en[`${el.id}`] = el.content_en;
          pl[`${el.id}_title`] = el.title_pl;
          pl[`${el.id}_label`] = el.label_pl;
          pl[`${el.id}_short_description`] = el.short_description_pl;
          pl[`${el.id}`] = el.content_pl;
        });
        translations.data.forEach(({ id, text_en, text_pl }) => {
          en[id] = text_en;
          pl[id] = text_pl;
        });
        i18n.addResourceBundle('en', 'translation', en, true, true);
        i18n.addResourceBundle('pl', 'translation', pl, true, true);
        setIsLoaded(true);
      } catch (error) {
        setIsLoaded(true);
        setIsError(true);
        console.log(error);
      }
    };
    getTranslations();
  }, []);

  const renderContent = () => {
    if (isError) {
      return <NotLoadedTranslation />;
    }

    if (!isLoaded) {
      return <Loading />;
    }

    return (
      <div>
        <Routes>
          {allRutes.map((route, i) => (
            <Route key={i} {...route} />
          ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        {!isCookieAccepted && <CookiesRodo onClick={setIsCookieAccepted} />}
      </div>
    );
  };

  return (
    <div>
      <AsyncLoadData />
      <AppParams />
      <ScrollToTop />
      {renderContent()}
    </div>
  );
}

export default App;
