import React, { ReactNode } from 'react';
import { ITooltip as ReactTooltipProps } from 'react-tooltip';
import { ReactComponent as InfoIcon } from 'assets/new/info.svg';
import { SCnt } from './styles';
import classNames from 'classnames';
import { ReactTooltip } from './ReactTooltip';

type TooltipProps = {
  id: string;
  content: string;
  iconProps?: React.SVGProps<SVGSVGElement>;
  customIcon?: ReactNode;
  iconSize?: 'small' | 'medium' | 'large';
  iconColor?: string;
} & Omit<ReactTooltipProps, 'content'>;

export const Tooltip = ({
  id,
  content,
  place = 'right',
  className,
  iconProps,
  customIcon,
  iconColor = '#1E2024',
  iconSize = 'small',
  ...rest
}: TooltipProps) => {
  const sizes: Record<NonNullable<TooltipProps['iconSize']>, any> = {
    large: {
      width: 24,
      height: 24,
    },
    medium: {
      width: 24,
      height: 24,
    },
    small: {
      width: 16,
      height: 16,
    },
  };
  const icon = customIcon || (
    <InfoIcon
      {...sizes[iconSize]}
      className="tooltip-icon"
      color={iconColor}
      data-tooltip-id={id}
      data-tooltip-html={content}
      {...iconProps}
    />
  );
  return (
    <SCnt className={classNames(className, 'c-tooltip')}>
      {icon}
      <ReactTooltip positionStrategy="fixed" place={place} id={id} {...rest} />
    </SCnt>
  );
};
