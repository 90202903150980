import { useTheme } from '@emotion/react';
import keys from 'i18n/keys';
import { ChartProps } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { roundNumb } from 'services/utils/roundNumb';
import { useBarDataset } from './useBarDataset';

export const useBarOptions = () => {
  const { t } = useTranslation();
  const datasets = useBarDataset();

  const structure = datasets.map(dataset => {
    return dataset.data.map((data, i) => {
      return {
        data,
        label: dataset.label?.split('|')?.[i],
      };
    });
  });

  const { chartJsTooltip, primary } = useTheme();
  const options: ChartProps<'bar'>['options'] = {
    indexAxis: 'y' as const,
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    elements: {
      bar: {
        borderSkipped: false,
      },
    },
    plugins: {
      datalabels: {
        color: primary,
        formatter: (value: number) => {
          if (value > 4) {
            return `${roundNumb(value)}`;
          }
          return '';
        },
      },
      tooltip: {
        ...chartJsTooltip,
        enabled: true,
        position: 'nearest',
        callbacks: {
          label: function (tooltipItem: any) {
            const {
              dataset: { label },
              dataIndex,
              datasetIndex,
            } = tooltipItem;
            const option = structure[datasetIndex][dataIndex];
            return `${t(option.label as any)}: ${roundNumb(option.data, 0)} ${t(keys.TWH)}`;
          } as any,
        },
        // external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          // borderWidth: 0,
        },
        labels: ['asd'],
      },
      y: {
        stacked: true,
        grid: {
          // borderColor: 'red',
          // borderWidth: 0,
          display: false,
          lineWidth: 4,
        },
      },
    },
  };
  return { options, datasets };
};
