import React from 'react';
import { useTranslation } from 'react-i18next';
import { SBullet, SBulletText, SBulltetCnt, SCnt, SLablel, WithLabelCnt, WithLabelRow } from './styles';

export type LegendOption = {
  name: string;
  color: string;
  borderColor?: string;
};
type LegendProps = {
  options?: LegendOption[];
  optionsWithLabel?: Array<{ label: string; options: LegendOption[] }>;
};

export const Legend = ({ options, optionsWithLabel }: LegendProps) => {
  const { t } = useTranslation();

  if (optionsWithLabel) {
    return (
      <WithLabelCnt>
        {optionsWithLabel.map(el => (
          <WithLabelRow key={el.label}>
            <SLablel>{t(el.label)}</SLablel>
            <SCnt>
              {el.options?.map(({ color, name }, index) => (
                <SBulltetCnt key={index} className="bullet">
                  <SBullet background={color} />
                  <SBulletText>{name}</SBulletText>
                </SBulltetCnt>
              ))}
            </SCnt>
          </WithLabelRow>
        ))}
      </WithLabelCnt>
    );
  }

  return (
    <div>
      <SCnt className="legend-cnt">
        {options &&
          options?.map(({ color, name, borderColor }, index) => (
            <SBulltetCnt className="bullet" key={index}>
              <SBullet background={color} borderColor={borderColor} />
              <SBulletText>{name}</SBulletText>
            </SBulltetCnt>
          ))}
      </SCnt>
    </div>
  );
};
