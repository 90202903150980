// TODO: PDF
import React, { useState, useRef } from 'react';
import { LineEnergyProduction } from 'features/Charts/LineEnergyProduction/LineEnergyProduction';
import { Btn, SChartWrapper, Title } from './styles';
import * as pdfMake from 'pdfmake/build/pdfmake';
import standardPageSizes from 'pdfmake/src/standardPageSizes';

import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import 'pdfmake/build/vfs_fonts';
import { StyleDictionary, TDocumentDefinitions } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';
import keys from 'i18n/keys';
import { useAppSelector } from 'services/configuration/store/hooks';
import moment from 'moment';
import { dotToComma, roundNumb } from 'services/utils/roundNumb';
import { EnergyBalance } from 'features/Charts/EnergyBalance/EnergyBalance';
import html2canvas from 'html2canvas';
import { EnergySourcesTable } from 'features/Costs/EnergySourcesTable';
import { Storage } from 'features/Costs/Storage';
import { Hydrogen } from 'features/Costs/Hydrogen';
import { Sum } from 'features/Costs/Sum';
import { Modal } from 'components/Modal/Modal';
import { ReactComponent as Icon } from 'assets/loader.svg';
import { getElasitcityOptions } from 'services/consts/elasticityOptions';
import { toPng } from './utils';
import {
  getCieplo,
  getCieploInputs,
  getKoszty,
  getObliczenia,
  getProdukcjaEnergiiElektrycznejInputs,
  getWodor,
} from 'services/slices/obliczeniaSlice/selectors';
import { PRZELICZNIKI } from 'services/slices/obliczeniaSlice/koszty/constans';
//@ts-ignore
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const PdfGenerator = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const state = useRef({ shouldStop: false });

  const handleClose = () => {
    setIsModalOpen(false);
    state.current.shouldStop = true;
  };
  const { t } = useTranslation();
  const { atom_zainstalowano, PV_zainstalowano, wiatr_lad_zainstalowano, wiatr_offshore_zainstalowano } =
    useAppSelector(getProdukcjaEnergiiElektrycznejInputs);
  const {
    inputs: { moc_elektrolizerow, mnożnik_zapotrzebowania_KSE, wspolczynnik_elastyczności, pojemnosc_magazynu_ciepla },
    calculations: { źródła_dyspozycyjne_per_year, elektroliza_per_year, pojemnosc_magazynu_pradu },
  } = useAppSelector(getObliczenia);
  const { suma_zapotrzebowania } = useAppSelector(getWodor);
  const {
    inputs: { odsetek_budynkow_z_odzyskiem_ciepla, zapotrzebowanie_CWU_względem_2023, moc_pomp_ciepla },
  } = useAppSelector(getCieplo);
  const { stopa_dyskonta } = useAppSelector(getKoszty);
  const { currentScenario } = useAppSelector(state => state.period);
  const { selectedYear } = useAppSelector(getObliczenia);

  const generatePDF = () => {
    setIsModalOpen(true);
    state.current.shouldStop = false;
    setTimeout(() => {
      const sumCapacityEnergyStorage = pojemnosc_magazynu_pradu.toFixed();
      const sumCapacityHeatStorage = pojemnosc_magazynu_ciepla.toFixed();
      setTimeout(async () => {
        const [
          zapotrzebowanie1,
          zapotrzebowanie2,
          zapotrzebowanie3,
          zapotrzebowanie4,
          zapotrzebowanie5,
          zapotrzebowanie6,
          zapotrzebowanie7,
          zapotrzebowanie8,
          zapotrzebowanie9,
          zapotrzebowanie10,
          zapotrzebowanie11,
          zapotrzebowanie12,
          produkcja1,
          produkcja2,
          produkcja3,
          produkcja4,
          produkcja5,
          produkcja6,
          produkcja7,
          produkcja8,
          produkcja9,
          produkcja10,
          produkcja11,
          produkcja12,
          energyBalance,
          costsTable,
          sponsors,
        ] = await Promise.all([
          html2canvas(document.querySelector('#zapotrzebowanie-1')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#zapotrzebowanie-2')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#zapotrzebowanie-3')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#zapotrzebowanie-4')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#zapotrzebowanie-5')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#zapotrzebowanie-6')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#zapotrzebowanie-7')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#zapotrzebowanie-8')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#zapotrzebowanie-9')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#zapotrzebowanie-10')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#zapotrzebowanie-11')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#zapotrzebowanie-12')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#produkcja-1')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#produkcja-2')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#produkcja-3')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#produkcja-4')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#produkcja-5')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#produkcja-6')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#produkcja-7')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#produkcja-8')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#produkcja-9')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#produkcja-10')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#produkcja-11')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#produkcja-12')!, { imageTimeout: 0 }),
          html2canvas(document.querySelector('#energy-balance')!, {
            imageTimeout: 0,
          }),
          html2canvas(document.querySelector('#costs-table')!, {
            imageTimeout: 0,
            onclone(document, element) {
              const allInputs = element.querySelectorAll('input');
              allInputs.forEach(el => {
                el.outerHTML = `<div style="padding: 2px 6px;">${dotToComma(+el.value || 0)}</div>`;
              });
            },
          }),
          html2canvas(document.querySelector('.sponsors')!, { imageTimeout: 0 }),
        ]);
        if (state.current.shouldStop) {
          return;
        }

        // const energyBalance = document.querySelector('#energy-balance canvas')! as HTMLCanvasElement;
        const energyBalanceImg = energyBalance!.toDataURL('image/png', 1.0);

        const sponsorsImg = sponsors!.toDataURL('image/png', 1.0);

        const costsTableImg = costsTable!.toDataURL('image/png', 1.0);
        if (state.current.shouldStop) {
          return;
        }

        const table = {
          style: 'tableExample',
          color: '#444',
          table: {
            widths: ['star', 'auto', 'star', 'auto'],
            body: [
              [{ text: t(keys.PDF_TABLE_HEADER), style: 'tableHeader', colSpan: 4, alignment: 'center' }, {}, {}, {}],
              [
                { text: t(keys.OFFSHORE_WIND_LABEL), style: 'tableBody' },
                { text: `${wiatr_offshore_zainstalowano} ${t(keys.GW)}`, style: 'tableBody', alignment: 'right' },
                { text: t(keys.DEMAND_BASIC_TRANSPORT), style: 'tableBody' },
                { text: `${0 * 100}%`, style: 'tableBody' },
              ],
              [
                { text: t(keys.ONSHORE_WIND_LABEL), style: 'tableBody' },
                { text: `${wiatr_lad_zainstalowano} ${t(keys.GW)}`, alignment: 'right', style: 'tableBody' },
                { text: t(keys.REQ_CENTRAL_HEAT_PROCENTAGE_LABEL), style: 'tableBody' },
                { text: `${zapotrzebowanie_CWU_względem_2023 * 100}%`, style: 'tableBody' },
              ],
              [
                { text: t(keys.PHOTOVOLTAICS), style: 'tableBody' },
                { text: `${PV_zainstalowano} ${t(keys.GW)}`, alignment: 'right', style: 'tableBody' },
                { text: t(keys.CURR_REQ_FOR_DOMESTIC_HEAT_WATER_PRECENT), style: 'tableBody' },
                { text: `${odsetek_budynkow_z_odzyskiem_ciepla * 100}%`, style: 'tableBody' },
              ],
              [
                { text: t(keys.NUCLEAR_POWER_PLANTS), style: 'tableBody' },
                { text: `${atom_zainstalowano} ${t(keys.GW)}`, alignment: 'right', style: 'tableBody' },
                { text: t(keys.HEAT_PUMPS_IN_HEATING), style: 'tableBody' },
                { text: `${moc_pomp_ciepla * 100}%`, style: 'tableBody' },
              ],
              [
                { text: t(keys.ELECTROLYZERS), style: 'tableBody' },
                { text: `${moc_elektrolizerow} ${t(keys.GW)}`, alignment: 'right', style: 'tableBody' },
                { text: t(keys.OTHER_ELECTRICITY_CONSUMPTION), style: 'tableBody' },
                { text: `${mnożnik_zapotrzebowania_KSE * 100}%`, style: 'tableBody' },
              ],
              [
                { text: t(keys.ENERGY_STORAGE), style: 'tableBody' },
                {
                  text: `${roundNumb(+sumCapacityEnergyStorage)}  ${t(keys.GWh)}`,
                  alignment: 'right',
                  style: 'tableBody',
                },
                { text: t(keys.HYDROGEN_NEEDS), style: 'tableBody' },
                { text: `${roundNumb(suma_zapotrzebowania)} ${t(keys.TWH)}`, style: 'tableBody' },
              ],
              [
                { text: t(keys.HEAT_STORAGE_TITLE), style: 'tableBody' },
                {
                  text: `${roundNumb(+sumCapacityHeatStorage)} ${t(keys.GWh)}`,
                  alignment: 'right',
                  style: 'tableBody',
                },
                { text: t(keys.ELASTIC_OF_DEMAND), style: 'tableBody' },
                {
                  text: getElasitcityOptions(t).find(el => el.value === wspolczynnik_elastyczności)?.label,
                  style: 'tableBody',
                },
              ],
            ],
          },
        };

        const naturalGasTWh = źródła_dyspozycyjne_per_year / 1000;
        const methaneConsumptionNeeded = roundNumb(
          naturalGasTWh * PRZELICZNIKI.spalanieGazuDoWyprodukowania1TWHEnergii,
          1,
        );
        const keyResuls = [
          {
            alignment: 'justify',
            columns: [
              [
                { text: `${t(keys.DISP_ENERGY_SOURCES_LABEL)}` },
                { text: ` ` },
                {
                  ul: [
                    {
                      text: `${t(keys.REQUIRMENT_LABEL)}: ${roundNumb(naturalGasTWh, 0)} ${t(keys.TWH)} `,
                      style: 'ulOwn',
                    },
                    {
                      text: `${t(keys.TABLE_METHANE_CONSUMPTION)}: ${methaneConsumptionNeeded} ${t(keys.MLDM3)}`,
                      style: 'ulOwn',
                    },
                  ],
                },
              ],
              [
                { text: `${t(keys.PDF_TABLE_ELECTROLYSERS_TITLE)}` },
                { text: ` ` },
                {
                  ul: [
                    {
                      text: `${t(keys.BOX2_LABEL1)}: ${(elektroliza_per_year / 1000).toFixed()} ${t(keys.TWH)}`,
                      style: 'ulOwn',
                    },
                    {
                      text: `${t(keys.REQUIRMENT_LABEL)}: ${suma_zapotrzebowania.toFixed()} ${t(keys.TWH)}`,
                      style: 'ulOwn',
                    },
                    {
                      text: `${t(keys.BALANCE)}: ${(
                        Number(elektroliza_per_year / 1000) - Number(suma_zapotrzebowania)
                      ).toFixed()} ${t(keys.TWH)}`,
                      style: 'ulOwn',
                    },
                  ],
                },
              ],
            ],
          },
        ];

        const styles: StyleDictionary = {
          title: {
            fontSize: 16,
            bold: true,
          },
          tableHeader: {
            bold: true,
            fontSize: 12,
            color: 'black',
          },
          tableBody: {
            fontSize: 10,
          },
          heading: { fontSize: 14, bold: true, margin: [0, 20, 0, 20] },
          headingNoMargin: { fontSize: 14, bold: true },
          headingSmallMargin: { fontSize: 14, bold: true, margin: [0, 5, 0, 5] },
          image: {
            margin: [0, 20, 0, 0],
            alignment: 'center',
          },
          imageSmall: {
            margin: [0, 10, 0, 0],
          },
          mb: {
            margin: [0, 0, 0, 20],
          },
          italic: {
            italics: true,
          },
          ulOwn: {
            fontSize: 11,
          },
        };

        if (state.current.shouldStop) {
          return;
        }
        const fullWidth: number = standardPageSizes.A4[0] - 60 - 60;

        const zapotrzebowanieCanvasArr = toPng(
          [
            zapotrzebowanie1,
            zapotrzebowanie2,
            zapotrzebowanie3,
            zapotrzebowanie4,
            zapotrzebowanie5,
            zapotrzebowanie6,
            zapotrzebowanie7,
            zapotrzebowanie8,
            zapotrzebowanie9,
            zapotrzebowanie10,
            zapotrzebowanie11,
            zapotrzebowanie12,
          ],
          {
            width: fullWidth,
            style: 'image',
          },
        );

        const produkcjaCanvasArr = toPng(
          [
            produkcja1,
            produkcja2,
            produkcja3,
            produkcja4,
            produkcja5,
            produkcja6,
            produkcja7,
            produkcja8,
            produkcja9,
            produkcja10,
            produkcja11,
            produkcja12,
          ],
          {
            width: fullWidth,
            style: 'image',
          },
        );
        const docDefinition: TDocumentDefinitions = {
          pageSize: 'A4',
          pageMargins: [40, 40, 40, 40],
          content: [
            {
              image: sponsorsImg,
              width: fullWidth,
              style: 'mb',
            },
            {
              text: `${t(keys.SIMULATION_NAME)}: ${t(currentScenario.title)}`,
              style: 'title',
            },
            {
              text: `${t(keys.DATE_OF_GENERATION)}: ${moment().format('DD-MM-YYYY HH:mm')}`,
            },
            { text: t(keys.PDF_DATA_HEADING) as string, style: 'heading' },
            table,
            { text: t(keys.PDF_KEY_RESULTS_HEADING) as string, style: 'heading' },
            ...keyResuls,
            { text: `${t(keys.ENERGY_BALANCE_TITLE)}`, style: 'heading' },
            { text: `${t(keys.PDF_ENERGY_BALANCE_TOP_TEXT)}` },
            { image: energyBalanceImg, width: fullWidth, style: 'imageSmall' },
            { text: ` ` },
            {
              text: `${t(keys.PDF_ENERGY_BALANCE_BOTTOM_TEXT, {
                sumDisposalSources: roundNumb(naturalGasTWh, 0),
              })}`,
            },
            {
              text: `${t(keys.PDF_ENERGY_BALANCE_BOTTOM_TEXT_ITALIC, {
                methaneNeeded: roundNumb(methaneConsumptionNeeded, 0),
              })}`,
              style: 'italic',
            },
            { text: `${t(keys.PDF_PRODUCTION_HEADING)}`, style: 'headingSmallMargin', pageBreak: 'before' },
            { text: `${t(keys.PDF_PRODUCTION_TEXT, { rok: selectedYear })}` },
            ...zapotrzebowanieCanvasArr,
            { text: `${t(keys.PDF_PRODUCTION_TEXT2, { rok: selectedYear })}`, pageBreak: 'before' },
            ...produkcjaCanvasArr,
            { text: `${t(keys.PAGE_TITLE_COSTS)}`, style: 'heading', pageBreak: 'before' },
            { text: `${t(keys.DISCOUNT_RATE)}: ${stopa_dyskonta * 100}%`, alignment: 'right' },
            { text: ` ` },
            {
              image: costsTableImg,
              width: fullWidth,
            },
          ],
          styles,
        };
        if (state.current.shouldStop) {
          return;
        }
        pdfMake.createPdf(docDefinition).download(t(keys.PDF_FILE_NAME));
        setIsModalOpen(false);
      }, 0);
    }, 5000);
  };

  return (
    <div>
      {isModalOpen && (
        <SChartWrapper>
          <LineEnergyProduction
            id="zapotrzebowanie-1"
            isInModalView
            isPdfView
            legendExpanded
            index={0}
            period={{ start: 0, end: 743 }}
          />
          <LineEnergyProduction
            id="zapotrzebowanie-2"
            isInModalView
            isPdfView
            legendExpanded
            index={0}
            period={{ start: 745, end: 1415 }}
          />
          <LineEnergyProduction
            id="zapotrzebowanie-3"
            isInModalView
            isPdfView
            legendExpanded
            index={0}
            period={{ start: 1416, end: 2159 }}
          />
          <LineEnergyProduction
            id="zapotrzebowanie-4"
            isInModalView
            isPdfView
            legendExpanded
            index={0}
            period={{ start: 2160, end: 2879 }}
          />
          <LineEnergyProduction
            id="zapotrzebowanie-5"
            isInModalView
            isPdfView
            legendExpanded
            index={0}
            period={{ start: 2880, end: 3623 }}
          />
          <LineEnergyProduction
            id="zapotrzebowanie-6"
            isInModalView
            isPdfView
            legendExpanded
            index={0}
            period={{ start: 3624, end: 4343 }}
          />
          <LineEnergyProduction
            id="zapotrzebowanie-7"
            isInModalView
            isPdfView
            legendExpanded
            index={0}
            period={{ start: 4344, end: 5087 }}
          />
          <LineEnergyProduction
            id="zapotrzebowanie-8"
            isInModalView
            isPdfView
            legendExpanded
            index={0}
            period={{ start: 5088, end: 5831 }}
          />
          <LineEnergyProduction
            id="zapotrzebowanie-9"
            isInModalView
            isPdfView
            legendExpanded
            index={0}
            period={{ start: 5832, end: 6551 }}
          />
          <LineEnergyProduction
            id="zapotrzebowanie-10"
            isInModalView
            isPdfView
            legendExpanded
            index={0}
            period={{ start: 6552, end: 7295 }}
          />
          <LineEnergyProduction
            id="zapotrzebowanie-11"
            isInModalView
            isPdfView
            legendExpanded
            index={0}
            period={{ start: 7296, end: 8015 }}
          />
          <LineEnergyProduction
            id="zapotrzebowanie-12"
            isInModalView
            isPdfView
            legendExpanded
            index={0}
            period={{ start: 8016, end: 8759 }}
          />

          <LineEnergyProduction
            id="produkcja-1"
            isInModalView
            isPdfView
            legendExpanded
            index={1}
            period={{ start: 0, end: 743 }}
          />
          <LineEnergyProduction
            id="produkcja-2"
            isInModalView
            isPdfView
            legendExpanded
            index={1}
            period={{ start: 745, end: 1415 }}
          />
          <LineEnergyProduction
            id="produkcja-3"
            isInModalView
            isPdfView
            legendExpanded
            index={1}
            period={{ start: 1416, end: 2159 }}
          />
          <LineEnergyProduction
            id="produkcja-4"
            isInModalView
            isPdfView
            legendExpanded
            index={1}
            period={{ start: 2160, end: 2879 }}
          />
          <LineEnergyProduction
            id="produkcja-5"
            isInModalView
            isPdfView
            legendExpanded
            index={1}
            period={{ start: 2880, end: 3623 }}
          />
          <LineEnergyProduction
            id="produkcja-6"
            isInModalView
            isPdfView
            legendExpanded
            index={1}
            period={{ start: 3624, end: 4343 }}
          />
          <LineEnergyProduction
            id="produkcja-7"
            isInModalView
            isPdfView
            legendExpanded
            index={1}
            period={{ start: 4344, end: 5087 }}
          />
          <LineEnergyProduction
            id="produkcja-8"
            isInModalView
            isPdfView
            legendExpanded
            index={1}
            period={{ start: 5088, end: 5831 }}
          />
          <LineEnergyProduction
            id="produkcja-9"
            isInModalView
            isPdfView
            legendExpanded
            index={1}
            period={{ start: 5832, end: 6551 }}
          />
          <LineEnergyProduction
            id="produkcja-10"
            isInModalView
            isPdfView
            legendExpanded
            index={1}
            period={{ start: 6552, end: 7295 }}
          />
          <LineEnergyProduction
            id="produkcja-11"
            isInModalView
            isPdfView
            legendExpanded
            index={1}
            period={{ start: 7296, end: 8015 }}
          />
          <LineEnergyProduction
            id="produkcja-12"
            isInModalView
            isPdfView
            legendExpanded
            index={1}
            period={{ start: 8016, end: 8759 }}
          />

          <EnergyBalance id="energy-balance" legendExpanded />
          <div id="costs-table">
            <EnergySourcesTable />
            <Storage />
            <Hydrogen />
            <Sum />
          </div>
        </SChartWrapper>
      )}
      <div onClick={generatePDF}>{children}</div>
      <Modal open={isModalOpen} footer={<></>}>
        <Title>
          <Icon width={40} height={40} />
          <span>{t(keys.PDF_MODAL_TITLE)}</span>
        </Title>
        <p>{t(keys.PDF_MODAL_TEXT)}</p>
        <Btn onClick={handleClose} variant="outlined" width="136px">
          {t(keys.CANCEL)}
        </Btn>
      </Modal>
    </div>
  );
};
