import { useDispatchAction } from 'hooks/useDispatchAction';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'services/configuration/store/hooks';
import { ObliczeniaTypeInputsPayload } from 'services/slices/obliczeniaSlice/obliczenia/types';
import { setObliczeniaInputs } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { getObliczeniaInputs } from 'services/slices/obliczeniaSlice/selectors';
import { Cell, CellText, Grid, HeadRow, Row, STooltip, TableTitle } from './sharedStyles';
import keys from 'i18n/keys';
import { DataInput } from 'components/DataInput/DataInput';
import { roundNumb } from 'services/utils/roundNumb';

export const OdbiorcyCiepla = () => {
  const { odbiorcy_z_magazynami_ciepla_z_siecia_CO, odbiorcy_podlaczeni_do_sieci } =
    useAppSelector(getObliczeniaInputs);
  const { t } = useTranslation();

  const odbiorcyCieplaPodlaczeniDoSieciSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'odbiorcy_podlaczeni_do_sieci',
    value: odbiorcy_podlaczeni_do_sieci,
    percentage: true,
  });

  const odbiorcyCieplaPozaSieciaZMagazynamiCieplaSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'odbiorcy_z_magazynami_ciepla_z_siecia_CO',
    value: odbiorcy_z_magazynami_ciepla_z_siecia_CO,
    percentage: true,
  });

  return (
    <Grid>
      <HeadRow>
        <TableTitle
          reverseFlex
          id="odbiorcy-ciepla-title"
          tooltipContent={t(keys.ODBIORCY_CIEPLA_ADVANCED_TITLE_TOOLTIP)}
        >
          {t(keys.ODBIORCY_CIEPLA_ADVANCED_TITLE)}
        </TableTitle>
      </HeadRow>
      <Row columns={1}>
        <Cell>
          <STooltip id="odbiorcy-ciepla-podlaczeni-do-sieci-tooltip" content={t(keys.PPL_CONNECTED_TO_GRID_TOOLTIP)} />
          <span>{t(keys.PPL_CONNECTED_TO_GRID)}</span>
        </Cell>
        <Cell>
          <DataInput {...odbiorcyCieplaPodlaczeniDoSieciSet} id="odbiorcy-ciepla-podlaczeni-do-sieci" />
        </Cell>
      </Row>
      <Row columns={1}>
        <Cell>
          <STooltip
            id="odbiorcy-ciepla-nie-podlaczeni-do-sieci-z-magazynem-ciepla-tooltip"
            content={t(keys.PPL_NOT_CONNECTED_TO_GRID_WITH_HEAT_STORAGE_TOOLTIP)}
          />
          <span>{t(keys.PPL_NOT_CONNECTED_TO_GRID_WITH_HEAT_STORAGE)}</span>
        </Cell>
        <Cell>
          <DataInput
            {...odbiorcyCieplaPozaSieciaZMagazynamiCieplaSet}
            id="odbiorcy-ciepla-nie-podlaczeni-do-sieci-z-magazynem-ciepla"
          />
        </Cell>
      </Row>
      <Row columns={1}>
        <Cell>
          <STooltip
            id="odbioryc-bez-magazynow-tooltip"
            content={t(keys.ODBIORCY_CIEPLA_BEZ_MAGAZYNOW_ADVANCED_TOOLTIP)}
          />
          <span>{t(keys.ODBIORCY_CIEPLA_BEZ_MAGAZYNOW_ADVANCED)}</span>
        </Cell>
        <Cell>
          <DataInput
            value={roundNumb((1 - odbiorcy_z_magazynami_ciepla_z_siecia_CO) * 100)}
            hideControls
            id="odbiorcy-ciepla-nie-podlaczeni-do-sieci-z-magazynem-ciepla"
          />
        </Cell>
      </Row>
    </Grid>
  );
};
