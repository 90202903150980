import styled from '@emotion/styled';
import { SimulatorHeaderProps } from './SimulatorHeader';
export const SHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  min-height: 38px;
`;

export const Title = styled.h1<{ font: SimulatorHeaderProps['font'] }>`
  font-weight: 700;
  min-width: 300px;
  margin-right: 16px;

  ${({ font }) => {
    if (font === 'big') {
      return `
      font-size: 36px;
      line-height: 44px;
    `;
    }
    return `font-size: 24px;
  line-height: 32px;`;
  }}
`;
