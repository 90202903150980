import styled from '@emotion/styled';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { TileTitleProps } from './TileTitle';

export const STileTitle = styled.h3<TileTitleProps>`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: ${({ theme }) => theme.primary};
  padding-bottom: ${({ border }) => (border ? '8px' : '0')};

  ${({ reverseFlex }) =>
    reverseFlex &&
    `
  flex-direction: row-reverse; 
  justify-content: flex-end;

  .c-tooltip {
    margin-left: 0;
    margin-right: 8px;
  }
  `}

  .border {
    width: 100%;
    height: 1px;
    background-color: ${({ border }) => border};
    position: absolute;
    bottom: 0px;
    left: 0;
  }
`;

export const SSectionTitle = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #023047;
  display: flex;
`;

export const STooltip = styled(Tooltip)`
  margin-left: 8px;
  color: #297f94;
`;
