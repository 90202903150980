import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { calcStableProductionEnergySources } from './produkcjaEnergiiElektrycznej/calcProdukcjaEnergiiElektrycznej';
import { produkcjaEnergiiElektrycznej } from './produkcjaEnergiiElektrycznej/produkcjaEnergiiElektrycznej';
import { calcObliczenia } from './obliczenia/calcObliczenia';
import { obliczenia } from './obliczenia/obliczenia';
import { calcBilansEnergii } from './bilansEnergii/calcBilansEnergii';
import { bilansEnergii } from './bilansEnergii/bilansEnergii';
import { EnergyFromExcel, CieploZExcela, ScenarioDataPayload, QueryParamsPayload } from './types';
import {
  CieploCODefaultModalPayload,
  CieploInputsPayload,
  CieploSelectOptions,
  CieploSelectOptionsPayload,
  NullableCieploInputsPayload,
} from './obliczenia/cieplo/types';
import { ProdukcjaEnergiiElektrycznejInputsPayload } from './produkcjaEnergiiElektrycznej/types';
import {
  ObliczeniaTypeConstsPayload,
  ObliczeniaTypeInputsPayload,
  ObliczeniaTypeProcentoweZapotrzebowanieNaTransportPayload,
  ObliczeniaTypeTransportConsts,
  ObliczeniaTypeTransportPayload,
} from './obliczenia/types';
import { wodór } from './wodor/wodór';
import { calcWodór } from './wodor/calcWodór';
import { koszty } from './koszty/koszty';
import { KosztMldPerGWPayload, KosztyAktualnaInfrastrukturaPayload } from './koszty/types';
import {
  BazoweZapotrzebowanieNaWodorPayload,
  WodorConstsPayload,
  ProcentoweZapotrzebowanieNaWodorPayload,
} from './wodor/types';
import { calcKoszty } from './koszty/calcKoszty';
import { AvailableYear, YearData } from 'services/consts/availableYears';
import { cloneDeep, merge } from 'lodash';
import { shouldUpdateObliczajCO } from './obliczenia/cieplo/shouldUpdateObliczajCO';

const initialState = {
  /** Źródła energii elektrycznej */
  produkcjaEnergiiElektrycznej,

  /** Reszta obliczeń zgrupowana w jedno, ze względu na wydajność i zależność od współczynników */
  obliczenia,

  /**Wykres Bilans energetyczny */
  bilansEnergii,

  /**wodór */
  wodór,

  /**Koszty */
  koszty,
};

export type ObliczeniaStateSlice = typeof initialState;

export const obliczeniaSlice = createSlice({
  name: 'obliczenia',
  initialState,
  reducers: {
    setObliczeniaConsts(state, { payload }: PayloadAction<ObliczeniaTypeConstsPayload>) {
      state.obliczenia.consts[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    obliczAll(state) {
      state.produkcjaEnergiiElektrycznej.calculations = calcStableProductionEnergySources(state);

      state.obliczenia.calculations = calcObliczenia(state);

      state.bilansEnergii = calcBilansEnergii(state);

      state.wodór.suma_zapotrzebowania = calcWodór(state);

      state.koszty.calculations = calcKoszty(state);
    },
    setScenario(
      state,
      { payload: { produkcjaEnergiiElektrycznej, cieplo, wodór, obliczenia } }: PayloadAction<ScenarioDataPayload>,
    ) {
      state.produkcjaEnergiiElektrycznej.inputs = merge(
        cloneDeep(initialState.produkcjaEnergiiElektrycznej.inputs),
        produkcjaEnergiiElektrycznej.inputs,
      );

      state.obliczenia.cieplo = cloneDeep(initialState.obliczenia.cieplo);
      state.obliczenia.inputs = cloneDeep(initialState.obliczenia.inputs);
      state.obliczenia.consts = cloneDeep(initialState.obliczenia.consts);
      state.obliczenia.calculations = cloneDeep(initialState.obliczenia.calculations);
      state.obliczenia.cieplo = merge(cloneDeep(state.obliczenia.cieplo), cieplo);
      state.obliczenia.inputs = merge(cloneDeep(state.obliczenia.inputs), obliczenia);

      state.wodór = cloneDeep(initialState.wodór);
      state.wodór.procentowe_zapotrzebowanie = wodór;

      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setStateFromQueryParams(
      state,
      { payload: { produkcjaEnergiiElektrycznej, obliczenia, koszty, wodor } }: PayloadAction<QueryParamsPayload>,
    ) {
      state.produkcjaEnergiiElektrycznej.inputs = produkcjaEnergiiElektrycznej.inputs;
      state.produkcjaEnergiiElektrycznej.consts = produkcjaEnergiiElektrycznej.consts;

      state.obliczenia.inputs = merge(cloneDeep(state.obliczenia.inputs), obliczenia.inputs);
      state.obliczenia.consts = obliczenia.consts;
      state.obliczenia.cieplo.inputs = obliczenia.cieplo.inputs;
      state.obliczenia.cieplo.selectOptions = obliczenia.cieplo.selectOptions;
      state.obliczenia.cieplo.obliczaj_CO = obliczenia.cieplo.obliczaj_CO;

      state.koszty.aktualna_infrastruktura = koszty.aktualna_infrastruktura;
      state.koszty.O_M_paliwo = koszty.O_M_paliwo;
      state.koszty.O_M_utrzymanie = koszty.O_M_utrzymanie;
      state.koszty.koszt_mld_per_GW = koszty.koszt_mld_per_GW;
      state.koszty.nie_licz_kosztów_istniejącej_infrastruktury = koszty.nie_licz_kosztów_istniejącej_infrastruktury;
      state.koszty.stopa_dyskonta = koszty.stopa_dyskonta;

      state.wodór.bazowe_zapotrzebowanie = wodor.bazowe_zapotrzebowanie;
      state.wodór.procentowe_zapotrzebowanie = wodor.procentowe_zapotrzebowanie;

      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setImportedEnergyFromExcel(state, action: PayloadAction<EnergyFromExcel>) {
      const { energy_demand, photovoltaic, wind, windOffShore, PV_wsch_zach, wiatr_lad_6MGW } = action.payload;
      state.obliczenia.data.zuzycie_pradu = energy_demand;
      state.produkcjaEnergiiElektrycznej.data.PV = photovoltaic;
      state.produkcjaEnergiiElektrycznej.data.PV_wsch_zach = PV_wsch_zach;
      state.produkcjaEnergiiElektrycznej.data.wiatr_lad = wind;
      state.produkcjaEnergiiElektrycznej.data.wiatr_lad_6MGW = wiatr_lad_6MGW;
      state.produkcjaEnergiiElektrycznej.data.wiatr_offshore = windOffShore;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setImportedHeatFromExcel(state, action: PayloadAction<CieploZExcela>) {
      state.obliczenia.data.srednia_temp = action.payload;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setCieploInputs(state, { payload }: PayloadAction<CieploInputsPayload>) {
      const shouldCOBeUpdated = shouldUpdateObliczajCO(payload.name);
      state.obliczenia.cieplo.obliczaj_CO = state.obliczenia.cieplo.obliczaj_CO || shouldCOBeUpdated;
      if (shouldCOBeUpdated) {
        state.obliczenia.cieplo.inputs.zapotrzebowanie_CO_input = null;
      }
      state.obliczenia.cieplo.inputs[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setCieploNullableInputs(state, { payload }: PayloadAction<NullableCieploInputsPayload>) {
      const shouldCOBeUpdated = shouldUpdateObliczajCO(payload.name);
      state.obliczenia.cieplo.obliczaj_CO = state.obliczenia.cieplo.obliczaj_CO || shouldCOBeUpdated;
      if (shouldCOBeUpdated) {
        state.obliczenia.cieplo.inputs.zapotrzebowanie_CO_input = null;
      }
      state.obliczenia.cieplo.inputs[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setCOCieploDefaultValues(state, { payload }: PayloadAction<CieploCODefaultModalPayload>) {
      state.obliczenia.cieplo.obliczaj_CO = payload.obliczaj_CO;
      state.obliczenia.cieplo.inputs.calculated_zapotrzebowanie_CO_input = 0;
      state.obliczenia.cieplo.inputs.zapotrzebowanie_CO_input = null;
      state.obliczenia.cieplo.inputs.zapotrzebowanie_CO_input_custom = null;
      state.obliczenia.cieplo.selectOptions.rodzaj_termomodernizacji = payload.rodzaj_termomodernizacji;
      state.obliczenia.cieplo.inputs.odsetek_budynkow_z_odzyskiem_ciepla = payload.odsetek_budynkow_z_odzyskiem_ciepla;
      state.obliczenia.cieplo.inputs.stopien_zastapienia_starych_budynkow_nowymi =
        payload.stopien_zastapienia_starych_budynkow_nowymi;

      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setCieploSelectOptions<T extends keyof CieploSelectOptions>(
      state,
      { payload }: PayloadAction<CieploSelectOptionsPayload<T>>,
    ) {
      const shouldCOBeUpdated = shouldUpdateObliczajCO(payload.name);
      state.obliczenia.cieplo.obliczaj_CO = state.obliczenia.cieplo.obliczaj_CO || shouldCOBeUpdated;
      if (shouldCOBeUpdated) {
        state.obliczenia.cieplo.inputs.zapotrzebowanie_CO_input = null;
      }
      state.obliczenia.cieplo.selectOptions[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setProdukcjiEnergiiInputs(state, { payload }: PayloadAction<ProdukcjaEnergiiElektrycznejInputsPayload>) {
      state.produkcjaEnergiiElektrycznej.inputs[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setObliczeniaInputs(state, { payload }: PayloadAction<ObliczeniaTypeInputsPayload>) {
      state.obliczenia.inputs[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setTransportInputs(state, { payload }: PayloadAction<ObliczeniaTypeTransportPayload>) {
      state.obliczenia.inputs.transport.procentowe_zapotrzebowanie_na_transport = null;
      state.obliczenia.inputs.transport[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setAllTransportInputs(state, { payload }: PayloadAction<ObliczeniaTypeTransportConsts>) {
      state.obliczenia.inputs.transport = {
        ...state.obliczenia.inputs.transport,
        procentowe_zapotrzebowanie_na_transport: null,
        procentowe_zapotrzebowanie_na_transport_custom: null,
        ...payload,
      };
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setProcentoweZapotrzebowanieNaTransport(
      state,
      { payload }: PayloadAction<ObliczeniaTypeProcentoweZapotrzebowanieNaTransportPayload>,
    ) {
      state.obliczenia.inputs.transport[payload.name] = payload.value;

      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setKosztyAktualnaInfrastruktura(state, { payload }: PayloadAction<KosztyAktualnaInfrastrukturaPayload>) {
      state.koszty.aktualna_infrastruktura[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setWodorConsts(state, { payload }: PayloadAction<WodorConstsPayload>) {
      state.wodór.consts[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setWodorZapotrzebowanie(state, { payload }: PayloadAction<BazoweZapotrzebowanieNaWodorPayload>) {
      state.wodór.bazowe_zapotrzebowanie[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setProcentoweZapotrzebowanieNaWodor(state, { payload }: PayloadAction<ProcentoweZapotrzebowanieNaWodorPayload>) {
      state.wodór.procentowe_zapotrzebowanie[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setKosztyMldPerGW(state, { payload }: PayloadAction<KosztMldPerGWPayload>) {
      state.koszty.koszt_mld_per_GW[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setO_M_utrzymanie(state, { payload }: PayloadAction<KosztMldPerGWPayload>) {
      state.koszty.O_M_utrzymanie[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setO_M_paliwo(state, { payload }: PayloadAction<KosztMldPerGWPayload>) {
      state.koszty.O_M_paliwo[payload.name] = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setNieLiczKosztowInfrastruktury(state, { payload }: PayloadAction<boolean>) {
      state.koszty.nie_licz_kosztów_istniejącej_infrastruktury = payload;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setStopaDyskonta(state, { payload }: PayloadAction<{ value: number }>) {
      state.koszty.stopa_dyskonta = payload.value;
      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setSelectedYear(state, { payload }: PayloadAction<AvailableYear>) {
      state.obliczenia.selectedYear = payload;
      const yearData = cloneDeep(state.obliczenia.yearsData[payload]);

      state.obliczenia.data.srednia_temp = cloneDeep(yearData.srednia_temp);
      state.produkcjaEnergiiElektrycznej.data.PV = cloneDeep(yearData.PV);
      state.produkcjaEnergiiElektrycznej.data.PV_wsch_zach = cloneDeep(yearData.PV_wsch_zach);
      state.produkcjaEnergiiElektrycznej.data.wiatr_offshore = cloneDeep(yearData.wiatr_offshore);
      state.produkcjaEnergiiElektrycznej.data.wiatr_lad_6MGW = cloneDeep(yearData.wiatr_lad_6MGW);
      state.produkcjaEnergiiElektrycznej.data.wiatr_lad = cloneDeep(yearData.wiatr_lad);
      state.obliczenia.data.zuzycie_pradu = cloneDeep(yearData.zuzycie_pradu);

      obliczeniaSlice.caseReducers.obliczAll(state);
    },
    setYearsData(state, { payload }: PayloadAction<Record<AvailableYear, YearData>>) {
      state.obliczenia.yearsData = { ...state.obliczenia.yearsData, ...payload };
    },
  },
});

export const {
  obliczAll,
  setAllTransportInputs,
  setProcentoweZapotrzebowanieNaTransport,
  setImportedEnergyFromExcel,
  setImportedHeatFromExcel,
  setCieploInputs,
  setProdukcjiEnergiiInputs,
  setKosztyAktualnaInfrastruktura,
  setWodorConsts,
  setWodorZapotrzebowanie,
  setProcentoweZapotrzebowanieNaWodor,
  setKosztyMldPerGW,
  setScenario,
  setNieLiczKosztowInfrastruktury,
  setO_M_paliwo,
  setO_M_utrzymanie,
  setStopaDyskonta,
  setSelectedYear,
  setObliczeniaConsts,
  setObliczeniaInputs,
  setCieploSelectOptions,
  setTransportInputs,
  setStateFromQueryParams,
  setYearsData,
  setCOCieploDefaultValues,
  setCieploNullableInputs,
} = obliczeniaSlice.actions;
