import React, { useState } from 'react';
import { ContentFlex, SContent, SidebarBtn, SidebarCnt, SidebarLink, STextSelectCnt, WithSideBarCnt } from './styles';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as EnLogo } from 'assets/en-logo.svg';
import { ReactComponent as HouseIcon } from 'assets/house_icon.svg';
import { ReactComponent as DataFileSearchIcon } from 'assets/data_file_search.svg';
import { ReactComponent as InformationIcon } from 'assets/information_icon.svg';
import { ReactComponent as CoinsIcon } from 'assets/coins_icon.svg';
import { ReactComponent as MessageIcon } from 'assets/message_icon.svg';
import { ReactComponent as IntroIcon } from 'assets/intro_icon.svg';
import { ReactComponent as RenewableEnergyAccIcon } from 'assets/renewable_energy_accumulator.svg';
import { ReactComponent as BrandMarkIcon } from 'assets/new/Brandmark.svg';
import { ReactComponent as DeblurIcon } from 'assets/new/deblur.svg';
import { ReactComponent as BlurLinearIcon } from 'assets/new/blur_linear.svg';
import { ReactComponent as BlurOnIcon } from 'assets/new/blur_on.svg';
import { ReactComponent as AlternateMailIcon } from 'assets/new/alternate_email.svg';
import { ReactComponent as TimelapseIcon } from 'assets/new/timelapse.svg';
import { LanguageSwitcher } from 'components/LanguageSwitcher/LanguageSwitcher';
import { routes, URL } from 'services/consts/routes';
import { useTranslation } from 'react-i18next';
import keys from 'i18n/keys';
import { useLocation, useNavigate } from 'react-router';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';
import { updateSimulatorTour } from 'services/slices/periodSlice';
import classNames from 'classnames';
import { PdfGenerator } from 'features/PdfGenerator/PdfGenerator';
import { CookiesRodoModal } from 'App/CookiesRodo/CookiesRodoModal';
import { AvialableTexts } from 'App/CookiesRodo/CookiesRodo';
import { Spacer } from 'components/common';

export const Sidebar = () => {
  const [cookieRodoModal, setCookieRodoModal] = useState<AvialableTexts>(null);
  const navigate = useNavigate();

  const handleCookieRodoModalClose = () => {
    setCookieRodoModal(null);
  };
  const openRodo = () => {
    setCookieRodoModal('rodo');
  };
  const openPrivacy = () => {
    setCookieRodoModal('privacy');
  };
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { shouldRunSimulatorTour } = useAppSelector(state => state.period);

  const startIntro = () => {
    dispatch(updateSimulatorTour(true));
    navigate(URL.SIMULATOR);
  };
  return (
    <SidebarCnt>
      <SContent>
        <ContentFlex>
          <SidebarLink to={URL.HOME}>
            <div className="icon small-pad">
              <RenewableEnergyAccIcon />
            </div>
          </SidebarLink>
          <SidebarLink to={URL.SIMULATOR}>
            <div className="icon">
              <HouseIcon />
            </div>
            <div className="text">
              <span>{t(keys.SIDEBAR_SIMULATOR)}</span>
            </div>
          </SidebarLink>
          {/* {shouldShowAdditionalLinks && (
          <>
            <PdfGenerator>
              <SButton id={SIMULATOR_INTRO_STEPS.EXPORT_PDF} className="empty">
                <span>{t(keys.EKSPORT)}</span>
              </SButton>
            </PdfGenerator>
            <SSmallLink to={URL.COSTS}>
              <CoinsIcon />
              <span>{t(keys.SIDEBAR_COSTS)}</span>
            </SSmallLink>
          </>
        )} */}
          {/* {pathNameWithoutEn === URL.SIMULATOR && (
          <SButton className={classNames({ active: shouldRunSimulatorTour })} onClick={startIntro}>
            <IntroIcon />
            <span>{t(keys.SIDEBAR_INTRO)}</span>
          </SButton>
        )} */}

          <SidebarLink to={URL.DATA} id={SIMULATOR_INTRO_STEPS.EXPORT_DATA}>
            <div className="icon">
              <DataFileSearchIcon />
            </div>
            <div className="text">
              <span>{t(keys.SIDEBAR_DATA)}</span>
            </div>
          </SidebarLink>

          {/* {shouldShowInformations && (
            <>
              <SLink to={URL.INTRODUCTION} className="empty">
                <span>{t(keys.SIDEBAR_INTRODUCTION)}</span>
              </SLink>
              <SLink to={URL.ABOUT} className="empty">
                <span>{t(keys.SIDEBAR_ABOUT)}</span>
              </SLink>
              <SLink to={URL.ABOUT_DATA} className="empty">
                <span>{t(keys.SIDEBAR_ABOUT_DATA)}</span>
              </SLink>
            </>
          )} */}
          <SidebarLink to={URL.COSTS}>
            <div className="icon">
              <CoinsIcon />
            </div>
            <div className="text">
              <span>{t(keys.SIDEBAR_COSTS)}</span>
            </div>
          </SidebarLink>

          <SidebarBtn className={classNames({ active: shouldRunSimulatorTour })} onClick={startIntro}>
            <div className="icon">
              <IntroIcon />
            </div>
            <div className="text">
              <span>{t(keys.SIDEBAR_INTRO)}</span>
            </div>
          </SidebarBtn>
          <SidebarLink to={URL.INTRODUCTION}>
            <div className="icon">
              <InformationIcon />
            </div>
            <div className="text">
              <span>{t(keys.SIDEBAR_INFORMATION)}</span>
            </div>
          </SidebarLink>
          <SidebarLink to={URL.CONTACT}>
            <div className="icon">
              <MessageIcon />
            </div>
            <div className="text">
              <span>{t(keys.SIDEBAR_CONTACT)}</span>
            </div>
          </SidebarLink>
          <STextSelectCnt>
            <LanguageSwitcher />
          </STextSelectCnt>
          <SidebarBtn onClick={openPrivacy}>
            <span className="text">{t(keys.PRIVACY_LINK_TEXT)}</span>
          </SidebarBtn>
          <SidebarBtn onClick={openRodo} className="rodo">
            <span className="text">{t(keys.RODO_LINK_TEXT)}</span>
          </SidebarBtn>
        </ContentFlex>
      </SContent>
      <CookiesRodoModal type={cookieRodoModal} onClose={handleCookieRodoModalClose} />
    </SidebarCnt>
  );
};

type WithSidebarProps = {
  children: React.ReactNode;
  wrapperBg?: string;
};

export const WithSidebar = ({ children, wrapperBg = '#fff' }: WithSidebarProps) => {
  return (
    <WithSideBarCnt wrapperBg={wrapperBg}>
      <Sidebar />
      <div className="wrapper">{children}</div>
    </WithSideBarCnt>
  );
};
