import { ObliczeniaStateSlice } from '../obliczeniaSlice';

export const calcWodór = (state: ObliczeniaStateSlice): number => {
  const { procentowe_zapotrzebowanie, bazowe_zapotrzebowanie } = state.wodór;

  const sumNeeded =
    bazowe_zapotrzebowanie.lotnictwo * procentowe_zapotrzebowanie.lotnictwo +
    bazowe_zapotrzebowanie.wojsko * procentowe_zapotrzebowanie.wojsko +
    bazowe_zapotrzebowanie.cement * procentowe_zapotrzebowanie.cement +
    bazowe_zapotrzebowanie.hutnictwo * procentowe_zapotrzebowanie.hutnictwo +
    bazowe_zapotrzebowanie.rafinerie * procentowe_zapotrzebowanie.rafinerie +
    bazowe_zapotrzebowanie.inne * procentowe_zapotrzebowanie.inne +
    bazowe_zapotrzebowanie.żegluga * procentowe_zapotrzebowanie.żegluga;

  return sumNeeded;
};
