import styled from '@emotion/styled';
type SpacerProps = {
  mb?: number | string;
  mt?: number | string;
  ml?: number | string;
  mr?: number | string;
  pb?: number | string;
  pt?: number | string;
  pl?: number | string;
  pr?: number | string;
};

export const getMargin = (value?: number | string) => {
  //@ts-ignore
  if (isNaN(value)) {
    return value;
  }
  return `${value}px`;
};

export const Spacer = styled.div<SpacerProps>`
  margin-bottom: ${({ mb }) => getMargin(mb)};
  margin-top: ${({ mt }) => getMargin(mt)};
  margin-left: ${({ ml }) => getMargin(ml)};
  margin-right: ${({ mr }) => getMargin(mr)};
  padding-bottom: ${({ pb }) => getMargin(pb)};
  padding-top: ${({ pt }) => getMargin(pt)};
  padding-left: ${({ pl }) => getMargin(pl)};
  padding-right: ${({ pr }) => getMargin(pr)};
`;

type BoxProps = {
  d?: 'block' | 'inline-block' | 'flex' | 'inline-flex' | 'grid' | 'inline-grid' | 'none';
  align?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  minWidth?: string | number;
  minHeight?: string | number;
  pos?: 'relative' | 'absolute' | 'fixed' | 'sticky';
  gap?: string;
  gridCols?: string;
  textAlign?: 'center' | 'left' | 'right';
  background?: string;
  border?: string;
  flexGrow?: string;
  maxWidth?: string;
};

export const Box = styled(Spacer)<BoxProps & SpacerProps>`
  display: ${({ d }) => d || 'block'};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  min-width: ${({ minWidth }) => getMargin(minWidth)};
  min-height: ${({ minHeight }) => getMargin(minHeight)};
  position: ${({ pos }) => pos};
  gap: ${({ gap }) => gap};
  grid-template-columns: ${({ gridCols }) => gridCols};
  text-align: ${({ textAlign }) => textAlign};
  background-color: ${({ background }) => background};
  border: ${({ border }) => border};
  flex-grow: ${({ flexGrow }) => flexGrow};
  max-width: ${({ maxWidth }) => getMargin(maxWidth)};
`;

export const Card = styled(Box)`
  padding: 8px 16px;
  border-radius: 24px;
  width: 100%;
`;
