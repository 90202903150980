import keys from './../../i18n/keys';
import React, { useState } from 'react';

import { Button } from 'components/Button/Button';
import { Container } from 'components/Container/Container';
import { MainPageHeader } from 'components/MainPageHeader/MainPageHeader';
import YouTube from 'react-youtube';
import { URL } from '../../services/consts/routes';
import {
  SButtonAsLink,
  SDesc,
  SDescCol,
  SIntro,
  SMainDescCnt,
  SWithVideoSection,
  SSposnor,
  STitle,
  SYouTube,
  SExampleScenariosSection,
  SExampleScenariosAction,
  SFooter,
  SGenesis,
  BtnsCnt,
} from './styles';

import { useTranslation } from 'react-i18next';
import { useTitle } from 'react-use';
import { CookiesRodoModal } from 'App/CookiesRodo/CookiesRodoModal';
import { AvialableTexts } from 'App/CookiesRodo/CookiesRodo';
import { EditorText } from 'components/EditorText';

export const Main = () => {
  const [cookieRodoModal, setCookieRodoModal] = useState<AvialableTexts>(null);
  const { t } = useTranslation();
  useTitle(`${t(keys.SIMULATOR)} ${t(keys.MAIN_TEXT1)}`);
  const handleCookieRodoModalClose = () => {
    setCookieRodoModal(null);
  };
  const openRodo = () => {
    setCookieRodoModal('rodo');
  };
  const openPrivacy = () => {
    setCookieRodoModal('privacy');
  };
  return (
    <main>
      <MainPageHeader />
      <Container>
        <SMainDescCnt>
          <SDescCol>
            <div>
              <STitle>
                <span>{t(keys.SIMULATOR)}</span> <br />
                <span className="small">{t(keys.MAIN_TEXT1)}</span>
              </STitle>
              <SDesc>{t(keys.MAIN_TEXT2)}</SDesc>
              <Button to={URL.SIMULATOR} variant="contained" width="186px">
                {t(keys.OPEN_SIMULATOR)}
              </Button>
            </div>
          </SDescCol>
          <img src="/images/Device - Macbook Air.png" width="607px" height="375px" alt="Zrzut ekranu symulatora" />
        </SMainDescCnt>
        <SSposnor as="div" />
        <SWithVideoSection>
          <SGenesis>
            <h3>{t(keys.MAIN_PAGE_GENESIS_title)}</h3>
            <EditorText dangerouslySetInnerHTML={{ __html: t(keys.MAIN_PAGE_GENESIS) }} />
            <BtnsCnt>
              <SButtonAsLink to={URL.INTRODUCTION} variant="contained" width="100%">
                {t(keys.SIDEBAR_INTRODUCTION)}
              </SButtonAsLink>
            </BtnsCnt>
          </SGenesis>
          <SYouTube>
            <YouTube
              className="player"
              videoId={t(keys.VIDEO_MAIN_PAGE_FIRST)}
              opts={{
                height: 276,
                width: '100%',
              }}
            />
          </SYouTube>
        </SWithVideoSection>
        <SWithVideoSection>
          <SYouTube>
            <YouTube
              className="player"
              videoId={t(keys.VIDEO_MAIN_PAGE_SECOND)}
              opts={{
                height: 276,
                width: '100%',
              }}
            />
          </SYouTube>
          <SIntro>
            <h2>{t(keys.MAIN_INTRODUCTION)}</h2>
            <p>{t(keys.MAIN_TEXT4)}</p>
            <BtnsCnt>
              <SButtonAsLink className="docs" to={`${URL.ABOUT_DATA}`} variant="contained" width="186px">
                {t(keys.DOCUMENTATION)}
              </SButtonAsLink>
              <SButtonAsLink href={t(keys.MAIN_PAGE_DOWNLOAD_EXCEL_LINK)} variant="contained" width="240px">
                {t(keys.MAIN_PAGE_DOWNLOAD_EXCEL)}
              </SButtonAsLink>
            </BtnsCnt>
          </SIntro>
        </SWithVideoSection>
      </Container>
      {/* <SSliderSection>
        <Container>
          <div className="d-flex">
            <SSliderTextCol>
              <h2>{t(keys.MAIN_TEXT5)}</h2>
              <p>{t(keys.MAIN_TEXT6)}</p>
              <p className="summary">{t(keys.MAIN_TEXT7)}</p>
            </SSliderTextCol>
            <SSliderCol>
              <QuotesSlider />
            </SSliderCol>
          </div>
        </Container>
      </SSliderSection> */}
      {/* <SDecisionSection>
        <Container>
          <h2>{t(keys.MAIN_TEXT8)}</h2>
          <p className="simulator-desc">{t(keys.MAIN_TEXT8_DESC)}</p>
          <SSimulatorArgs>
            <div className="col">
              <div className="iconWrapper">
                <GraphStatisticCOffeIcon />
              </div>
              <h3 className="title">{t(keys.MAIN_TEXT9_MAIN)}</h3>
              <p className="desc">{t(keys.MAIN_TEXT9_BOTTOM)}</p>
            </div>
            <div className="col">
              <div className="iconWrapper">
                <OnlineLearningIcon />
              </div>
              <h3 className="title">{t(keys.MAIN_TEXT10_MAIN)}</h3>
              <p className="desc">{t(keys.MAIN_TEXT10_BOTTOM)}</p>
            </div>
            <div className="col">
              <div className="iconWrapper">
                <TaskListIcon />
              </div>
              <h3 className="title">{t(keys.MAIN_TEXT11_MAIN)}</h3>
              <p className="desc">{t(keys.MAIN_TEXT11_BOTTOM)}</p>
            </div>
            <div className="col">
              <div className="iconWrapper">
                <AnalyticsGraphIcon />
              </div>
              <h3 className="title">{t(keys.MAIN_TEXT12_MAIN)}</h3>
              <p className="desc">{t(keys.MAIN_TEXT12_BOTTOM)}</p>
            </div>
          </SSimulatorArgs>
        </Container>
      </SDecisionSection> */}
      <Container>
        <SExampleScenariosSection>
          <h2>{t(keys.MAIN_MEET_SCENARIOS)}</h2>
          <SExampleScenariosAction>
            <SButtonAsLink className="open-simulator" to={URL.SIMULATOR} variant="outlined" width="186px">
              {t(keys.OPEN_SIMULATOR)}
            </SButtonAsLink>
            <SButtonAsLink to={`${URL.SIMULATOR}?scenarios=true`} variant="outlined" width="240px">
              {t(keys.MAIN_EXAMPLE_SCENARIOS)}
            </SButtonAsLink>
          </SExampleScenariosAction>
        </SExampleScenariosSection>
      </Container>
      <SFooter>
        <Container>
          <div className="footer-cnt">
            <p className="text">{t(keys.MAIN_FOOTER)}</p>
            <button className="privacy" onClick={openPrivacy}>
              {t(keys.PRIVACY_LINK_TEXT)}
            </button>
            <button onClick={openRodo} className="rodo">
              {t(keys.RODO_LINK_TEXT)}
            </button>
          </div>
        </Container>
      </SFooter>
      <CookiesRodoModal type={cookieRodoModal} onClose={handleCookieRodoModalClose} />
    </main>
  );
};
