/** @jsx jsx */
import keys from './../../i18n/keys';
import React, { useState, useRef } from 'react';
import { PageCnt } from 'components/Card/PageCnt';
import { WithSidebar } from 'components/Sidebar/Sidebar';
import { SimulatorHeader } from 'components/SimulatorHeader/SimulatorHeader';
import { SForm, SH2, SInput, SLabel, SPageCard, STextarea } from './styles';
import { Button } from 'components/Button/Button';
import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Footer } from 'components/Footer/Footer';
import { useTitle } from 'react-use';
import ReCAPTCHA from 'react-google-recaptcha';
import { Container } from 'components/Container/Container';

export const Contact = () => {
  const { t } = useTranslation();
  useTitle(t(keys.PAGE_TITLE_CONTACT));
  const [isCaptchaAccepted, setIsCaptchaAccepted] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const captchaRef = useRef<any>(null);
  const onChange = value => {
    setIsCaptchaAccepted(!!value);
  };
  const clearForm = () => {
    document.forms[0].reset();
    captchaRef.current.reset();
  };
  const captchaKey = process.env.REACT_APP_CAPTCHA_KEY as string;
  return (
    <div>
      <WithSidebar>
        <PageCnt>
          <SimulatorHeader title={t(keys.PAGE_TITLE_CONTACT)} />
          <SPageCard>
            <div
              css={css`
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 150px;
                margin-top: auto;

                @media (max-width: 1400px) {
                  grid-gap: 20px;
                }
              `}
            >
              <div>
                <SH2>{t(keys.SEND_MESSAGE_CREATORS)}</SH2>
                <SForm action="https://formspree.io/f/xeqdgzbz" method="POST">
                  <div
                    css={css`
                      margin-bottom: 24px;
                    `}
                  >
                    <SLabel htmlFor="email" id="email-label">
                      {t(keys.YOUR_EMAIL)}
                      <SInput
                        aria-invalid="false"
                        aria-required="true"
                        required
                        id="email"
                        type="email"
                        name="email"
                        placeholder={t(keys.YOUR_EMAIL_PLACEHOLDER)}
                      />
                    </SLabel>
                  </div>
                  <div
                    css={css`
                      margin-bottom: 25px;
                    `}
                  >
                    <SLabel htmlFor="message" id="message-label">
                      {t(keys.MESSAGE_BODY)}
                      <STextarea
                        aria-invalid="false"
                        aria-required="true"
                        required
                        id="message"
                        placeholder={t(keys.MESSAGE_BODY_PLACEHOLDER)}
                        rows={5}
                        name="message"
                      />
                    </SLabel>
                  </div>
                  <div>
                    <ReCAPTCHA ref={captchaRef} sitekey={captchaKey} onChange={onChange} />
                  </div>
                  <div
                    css={css`
                      margin-top: 20px;
                      display: flex;
                      justify-content: center;
                    `}
                  >
                    <Button
                      css={css`
                        margin-right: 10px;
                      `}
                      dense
                      type="button"
                      onClick={clearForm}
                    >
                      {t(keys.CANCEL)}
                    </Button>
                    <Button variant="outlined" type="submit" dense disabled={!isCaptchaAccepted}>
                      {t(keys.SAVE)}
                    </Button>
                  </div>
                </SForm>
              </div>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <img
                  css={css`
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                  `}
                  src="/images/Device - Macbook Air.png"
                  width="607px"
                  height="375px"
                  alt="Zrzut ekranu symulatora"
                />
              </div>
            </div>
            <div
              css={css`
                height: 40px;
              `}
            ></div>
            <Container>
              <Footer />
            </Container>
          </SPageCard>
        </PageCnt>
      </WithSidebar>
    </div>
  );
};
