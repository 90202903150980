import { useTheme } from '@emotion/react';
import keys from 'i18n/keys';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'services/configuration/store/hooks';
import { roundNumb } from 'services/utils/roundNumb';
import FocusTrap from 'focus-trap-react';
import ReactSlider from 'react-slider';
import { TileTitle } from 'components/Titles/TileTitle';
import { ReactComponent as CloseIcon } from 'assets/close_icon.svg';
import {
  SDisposalModal,
  SHeading,
  SHydrogenUse,
  SSliderCalcs,
  SSliderCnt,
  SSSliderLabels,
} from './DisposableEnergySources.styles';
import { getBilansEnergii, getObliczeniaCalculations, getWodor } from 'services/slices/obliczeniaSlice/selectors';
import { PRZELICZNIKI } from 'services/slices/obliczeniaSlice/koszty/constans';
import { Text } from '../GroupedData/GroupedData.styles';
import { Fieldset } from 'components/Fieldset/Fieldset';

export const DisposableEnergySourcesModal = ({ setOpen, open }) => {
  const [sliderVal, setSliderVal] = useState(100);

  const { źródła_dyspozycyjne_per_year } = useAppSelector(getObliczeniaCalculations);
  const {
    produkcja: { produkcja_suma, gaz, atom, wiatr_na_ladzie, wiatr_na_morzu, PV },
  } = useAppSelector(getBilansEnergii);
  const {
    consts: { hydrogenStorageEfficency },
  } = useAppSelector(getWodor);
  const naturalGasTWh = źródła_dyspozycyjne_per_year / 1000;
  const ilośćCO2ZGazuZiemnego = (((naturalGasTWh * PRZELICZNIKI.emisyjnośćElektrowniGazowej) / 1000) * sliderVal) / 100;
  const ilośćCO2NaKwh = (1000 * ilośćCO2ZGazuZiemnego) / produkcja_suma || 0;
  const ilośćWodoru = naturalGasTWh / PRZELICZNIKI.sprawnośćElektrolizerów / hydrogenStorageEfficency;
  // const biomethaneVal =
  //   (naturalGasTWh * PRZELICZNIKI.spalanieGazuDoWyprodukowania1TWHEnergii * (100 - sliderVal)) / 100;
  // const naturalGasVal = (naturalGasTWh * PRZELICZNIKI.spalanieGazuDoWyprodukowania1TWHEnergii * sliderVal) / 100;

  const mnożnik = 3 - 0.6 * (gaz / (gaz + atom + wiatr_na_ladzie + wiatr_na_morzu + PV));

  const ilośćCO2ZWęgla = roundNumb(
    ((naturalGasTWh * PRZELICZNIKI.emisyjnośćElektrowniGazowej) / produkcja_suma) * mnożnik,
  );

  useEffect(() => {
    const clickOutsideHandler = () => {
      setOpen(false);
    };
    const keyUpEvent = e => {
      if (e.keyCode === 27) {
        setOpen(false);
      }
    };
    window.addEventListener('keydown', keyUpEvent);
    window.addEventListener('click', clickOutsideHandler);
    return () => {
      window.removeEventListener('click', clickOutsideHandler);
      window.removeEventListener('keydown', keyUpEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { t } = useTranslation();

  const handleSliderChange = (val: number) => {
    setSliderVal(val);
  };

  return (
    <div>
      {open && (
        <FocusTrap
          focusTrapOptions={{
            allowOutsideClick: true,
          }}
        >
          <SDisposalModal onClick={e => e.stopPropagation()}>
            <SHeading>
              <TileTitle
                id="DYSP_ENERGY_SOURCE_FOR_PLANTS"
                tooltipContent={t(keys.DYSP_ENERGY_SOURCE_FOR_PLANTS_TOOLTIP)}
              >
                {t(keys.DYSP_ENERGY_SOURCE_FOR_PLANTS)}
              </TileTitle>
              <button onClick={() => setOpen(false)}>
                <CloseIcon />
              </button>
            </SHeading>
            <Text>{t(keys.DISPOSAL_ALTERNATIVE_SCENARIOS)}</Text>
            <Fieldset legend={t(keys.METHANE_USAGE)}>
              <SSliderCnt>
                <SSSliderLabels>
                  <p className="natural-gas">
                    {100 - sliderVal} % {t(keys.BIOMETHANE)}
                  </p>
                  <p className="biomethane">
                    {sliderVal} % {t(keys.NATURAL_GAS)}
                  </p>
                </SSSliderLabels>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  min={0}
                  max={100}
                  onChange={handleSliderChange}
                  value={sliderVal}
                  renderThumb={ddd => <div {...ddd}></div>}
                />
              </SSliderCnt>
              <SSliderCalcs>
                <div>
                  <div className="d-flex">
                    <Text className="disposal-text">{t(keys.CO_EMMISION)} &nbsp;</Text>
                    <Text>
                      {roundNumb(ilośćCO2ZGazuZiemnego, 0)} {t(keys.MTCO)}
                    </Text>
                  </div>
                  <div className="d-flex">
                    <Text className="disposal-text">{t(keys.CURRENT_EMISSIVITY)} &nbsp;</Text>
                    <Text>
                      {roundNumb(ilośćCO2NaKwh, 0)} {t(keys.GCO_KWH)}
                    </Text>
                  </div>
                </div>
              </SSliderCalcs>
            </Fieldset>
            <Fieldset legend={t(keys.HYDROGEN_USAGE)}>
              <div className="d-flex">
                <Text className="disposal-text">{t(keys.ENERGY_NEEDED_FOR_ELECTROLYSIS)} &nbsp;</Text>
                <Text>
                  {roundNumb(ilośćWodoru, 0)} {t(keys.TWH)}
                </Text>
              </div>
            </Fieldset>
            <Fieldset legend={t(keys.COAL_USAGE)}>
              <SSSliderLabels>
                <div className="d-flex">
                  <Text className="disposal-text">{t(keys.CO_EMMISION)} &nbsp;</Text>
                  <Text>
                    {roundNumb(((naturalGasTWh * PRZELICZNIKI.emisyjnośćElektrowniGazowej) / 1000) * mnożnik, 0)}{' '}
                    {t(keys.MTCO)}
                    &nbsp;
                  </Text>
                </div>
                <div className="d-flex">
                  <Text className="disposal-text">{t(keys.CURRENT_EMISSIVITY)} &nbsp;</Text>
                  <Text>
                    {ilośćCO2ZWęgla} {t(keys.GCO_KWH)}
                  </Text>
                </div>
              </SSSliderLabels>
            </Fieldset>
            <SHydrogenUse
              dangerouslySetInnerHTML={{ __html: t(keys.CURRENT_HYDROGEN_USE_IN_POLAND_LABEL) }}
            ></SHydrogenUse>
          </SDisposalModal>
        </FocusTrap>
      )}
    </div>
  );
};
