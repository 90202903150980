import keys from '../../../../i18n/keys';
import React, { memo, useState } from 'react';
import { useAppSelector } from 'services/configuration/store/hooks';
import { SCloseBtnCnt, STile } from './styles';
import { ReactComponent as HydrogenIcon } from 'assets/Wodór.svg';
import { TileTitle } from 'components/Titles/TileTitle';
import { useDispatchAction } from 'hooks/useDispatchAction';
import { useTranslation } from 'react-i18next';
import { EditButton } from 'components/Button/EditButton';
import { Modal } from 'components/Modal/Modal';
import { HydrogenNeeds } from 'features/AdvancedOptionsTiles/HydrogenNeeds';
import { Button } from 'components/Button/Button';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';
import { getObliczeniaInputs, getWodor } from 'services/slices/obliczeniaSlice/selectors';
import { ObliczeniaTypeInputsPayload } from 'services/slices/obliczeniaSlice/obliczenia/types';
import { setObliczeniaInputs } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { Box, Spacer } from 'components/common';
import { DataInput } from 'components/DataInput/DataInput';
import { DataInputContext } from 'components/DataInput/dataInputContext';

export const HydrogenTile = memo(() => {
  const [hydrogenModal, setHydrogenModal] = useState(false);
  const { moc_elektrolizerow } = useAppSelector(getObliczeniaInputs);
  const { suma_zapotrzebowania } = useAppSelector(getWodor);

  const electrolysersInstalledSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'moc_elektrolizerow',
    value: moc_elektrolizerow,
  });

  const { t } = useTranslation();

  return (
    <STile id={SIMULATOR_INTRO_STEPS.HYDROGEN}>
      <TileTitle
        border="#dde1e6"
        id="hydrogen-tile"
        tooltipContent={t(keys.HYDROGEN_TOOLTIP)}
        tooltipProps={{ place: 'top' }}
      >
        <span>{t(keys.HYDROGEN)}</span>
      </TileTitle>
      <Spacer mb="6px">
        <DataInput
          label={t(keys.ELECTROLYZERS)}
          id={'hydrogen-production'}
          labelTooltipId="TOOLTIP_HEAT_TILE_ELECTROLYSERS_MAIN"
          labelTooltipContent={t(keys.TOOLTIP_HEAT_TILE_ELECTROLYSERS_MAIN)}
          unit={t(keys.GW)}
          {...electrolysersInstalledSet}
        />
      </Spacer>
      <Spacer>
        <DataInput
          label={t(keys.HYDROGEN)}
          id={'hydrogen-needs'}
          unit={t(keys.TWH)}
          value={suma_zapotrzebowania.toFixed()}
          hideControls
          editBtn={<EditButton onClick={() => setHydrogenModal(true)}> </EditButton>}
        />
      </Spacer>
      <Modal open={hydrogenModal} onClose={() => setHydrogenModal(false)}>
        <Box minWidth="600px">
          <DataInputContext.Provider value={null}>
            <HydrogenNeeds tileView="modal" />
          </DataInputContext.Provider>
        </Box>
      </Modal>
    </STile>
  );
});
