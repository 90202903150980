import styled from '@emotion/styled';
import { Card } from 'components/common';

export const SHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  .tile-title {
    margin-bottom: 0;
  }
`;

export const SBarChartCnt = styled.div`
  height: 110px;
  width: 100%;
  /* width: 100% !important; */
  canvas {
    width: 100% !important;
  }
`;

export const SSection = styled.div`
  max-width: 100%;

  .bar-chart {
    width: 100%;
  }
`;
