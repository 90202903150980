import { ObliczeniaStateSlice } from '../../obliczeniaSlice';
import { ObliczeniaTypeTransport, ObliczeniaTypeTransportConsts } from '../types';
export const calcTransport = (state: ObliczeniaStateSlice) => {
  const {
    ilość_aut_osobowych,
    inne_rodzaje_transportu,
    liczba_pasażerokilometrów,
    udział_kolei_w_transporcie,
    wolumen_transportu_towarów,
    zużycie_energii_aut_osobowych,
    średni_przejeżdżany_dystans_aut_osobowych,
    procentowe_zapotrzebowanie_na_transport,
  } = state.obliczenia.inputs.transport;

  const ilosc_mocy_na_transport_do_100_proc: Record<keyof ObliczeniaTypeTransportConsts, number> = {
    ilość_aut_osobowych: 23,
    zużycie_energii_aut_osobowych: 22,
    średni_przejeżdżany_dystans_aut_osobowych: 10,

    liczba_pasażerokilometrów: 1,

    wolumen_transportu_towarów: 1,
    udział_kolei_w_transporcie: 0.12,

    inne_rodzaje_transportu: 1,
  } as const;

  const auta_osobowe =
    ((ilość_aut_osobowych * zużycie_energii_aut_osobowych * średni_przejeżdżany_dystans_aut_osobowych) / 100) * 1;
  state.obliczenia.inputs.transport.calculations.auta_osobowe = auta_osobowe;
  const auta_osobowe_przy_100_proc =
    ((ilosc_mocy_na_transport_do_100_proc.ilość_aut_osobowych *
      ilosc_mocy_na_transport_do_100_proc.zużycie_energii_aut_osobowych *
      ilosc_mocy_na_transport_do_100_proc.średni_przejeżdżany_dystans_aut_osobowych) /
      100) *
    1;

  const transport_zbiorowy = liczba_pasażerokilometrów * 3;
  const transport_zbiorowy_przy_100_proc = ilosc_mocy_na_transport_do_100_proc.liczba_pasażerokilometrów * 3;
  state.obliczenia.inputs.transport.calculations.transport_zbiorowy = transport_zbiorowy;

  const transport_towarowy = wolumen_transportu_towarów * (10 + 32 * (1 - udział_kolei_w_transporcie));
  const transport_towarowy_przy_100_proc =
    ilosc_mocy_na_transport_do_100_proc.wolumen_transportu_towarów *
    (10 + 32 * (1 - ilosc_mocy_na_transport_do_100_proc.udział_kolei_w_transporcie));
  state.obliczenia.inputs.transport.calculations.transport_towarowy = transport_towarowy;

  const inny_transport = inne_rodzaje_transportu * 11;
  const inny_transport_przy_100_proc = ilosc_mocy_na_transport_do_100_proc.inne_rodzaje_transportu * 11;
  state.obliczenia.inputs.transport.calculations.inny_transport = inny_transport;

  const suma_inputow = auta_osobowe + transport_zbiorowy + transport_towarowy + inny_transport;
  const suma_inputow_przy_100_proc =
    auta_osobowe_przy_100_proc +
    transport_zbiorowy_przy_100_proc +
    transport_towarowy_przy_100_proc +
    inny_transport_przy_100_proc;

  state.obliczenia.inputs.transport.calculations.calculated_procentowe_zapotrzebowanie_na_transport =
    suma_inputow / suma_inputow_przy_100_proc;
  if (procentowe_zapotrzebowanie_na_transport !== null) {
    state.obliczenia.inputs.transport.procentowe_zapotrzebowanie_na_transport_custom =
      procentowe_zapotrzebowanie_na_transport;

    return suma_inputow_przy_100_proc * procentowe_zapotrzebowanie_na_transport;
  }
  const result = suma_inputow / suma_inputow_przy_100_proc;

  state.obliczenia.inputs.transport.procentowe_zapotrzebowanie_na_transport = result;

  return suma_inputow;
};
