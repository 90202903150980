import { data } from 'data/2023';
import { RODZAJE_TERMOMODERNIZACJI, TEMPERATURA_CO_CWU } from './consts';

export const cieplo = {
  obliczaj_CO: true,
  selectOptions: {
    rodzaj_termomodernizacji: RODZAJE_TERMOMODERNIZACJI[2].value as typeof RODZAJE_TERMOMODERNIZACJI[number]['value'],

    temperatura_CO: TEMPERATURA_CO_CWU[1].value as typeof TEMPERATURA_CO_CWU[number]['value'],

    temperatura_CWU: TEMPERATURA_CO_CWU[2].value as typeof TEMPERATURA_CO_CWU[number]['value'],
  },

  inputs: {
    procent_pomp_ciepla_powietrznych: 1,
    procent_pomp_ciepla_gruntowych: 0,

    zapotrzebowanie_CWU_względem_2023: 0,

    odsetek_budynkow_z_odzyskiem_ciepla: 0,

    moc_pomp_ciepla: 0,

    stopien_zastapienia_starych_budynkow_nowymi: 0,

    zapotrzebowanie_CO_input: null as number | null,
    zapotrzebowanie_CO_input_custom: null as number | null,
    calculated_zapotrzebowanie_CO_input: 0,
  },

  calculations: {
    moc_pomp_ciepla_gruntowych: 0,
    moc_pomp_ciepla_powietrznych: 0,

    zuzycie_energii_CO: 0,
  },
};
