import ReactDOM from 'react-dom';
import React, { useEffect, useRef, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import { SModal, SOverlay } from './Drawer.styles';

const modalsContainer = document.getElementById('modals') as HTMLElement;
type DrawerProps = {
  /**
   * If true, the drawer will be stacked on top of the content.
   */
  stackedDrawer?: boolean;
  open: boolean;
  onClose: (arg: boolean) => void;
  children: React.ReactNode;
};

export const Drawer = ({ onClose, children, open, stackedDrawer }: DrawerProps) => {
  const state = useRef({ open });

  useEffect(() => {
    const keyUpEvent = e => {
      if (e.keyCode === 27) {
        if (state.current.open) {
          onClose(false);
        }
      }
    };
    window.addEventListener('keydown', keyUpEvent);
    return () => {
      window.removeEventListener('keydown', keyUpEvent);
    };
  }, []);

  useEffect(() => {
    if (stackedDrawer && !open) {
      return;
    }
    if (open) {
      document.querySelector('body')?.classList?.add('o-hidden');
    } else {
      document.querySelector('body')?.classList?.remove('o-hidden');
    }
  }, [open, stackedDrawer]);

  const handleClose = () => {
    onClose(false);
  };

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <SOverlay open={open} onClick={handleClose}>
      <FocusTrap
        active={open}
        focusTrapOptions={{
          allowOutsideClick: true,
        }}
      >
        <SModal open={open}>{children}</SModal>
      </FocusTrap>
    </SOverlay>,
    modalsContainer,
  );
};
