import { PageCnt } from 'components/Card/PageCnt';
import { WithSidebar } from 'components/Sidebar/Sidebar';
import { SimulatorHeader } from 'components/SimulatorHeader/SimulatorHeader';
import React from 'react';

import { EnergyExportCol } from 'features/DataFeatures/EnergyExportCol/EnergyExportCol';

import { useTranslation } from 'react-i18next';
import keys from 'i18n/keys';
import { useTitle } from 'react-use';
import { TileTitle } from 'components/Titles/TileTitle';
import { PartnersBanner } from 'components/PartnersBanner/PartnersBanner';
import { Spacer } from 'components/common';
import { PageGrid, Subtitle } from './Data.styles';
import { HeatExportCol } from 'features/DataFeatures/HeatExportCol/HeatExportCol';

export const Data = () => {
  const { t } = useTranslation();
  useTitle(t(keys.PAGE_TITLE_DATA));
  return (
    <div>
      <WithSidebar>
        <PageCnt>
          <Spacer mb="48px">
            <SimulatorHeader font="big" title={t(keys.PAGE_TITLE_DATA)} />
          </Spacer>
          <PageGrid>
            <Subtitle>{t(keys.DATA_PAGE_SUBTITLE)}</Subtitle>
            <EnergyExportCol />
            <HeatExportCol />
          </PageGrid>
          <Spacer mt="auto">
            <PartnersBanner />
          </Spacer>
        </PageCnt>
      </WithSidebar>
    </div>
  );
};
