import styled from '@emotion/styled';
import { PageCard } from 'components/Card/PageCard';
import { PartnersBanner } from 'components/PartnersBanner/PartnersBanner';
import { TileTitle } from 'components/Titles/TileTitle';

export const SPageCard = styled(PageCard)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SH2 = styled(TileTitle)`
  text-align: center;
  margin-bottom: 40px;
`;

export const SForm = styled.form`
  label,
  input,
  textarea {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212529;
    display: block;
  }
`;

export const SInput = styled.input`
  padding: 7px 12px;
  background: #ffffff;
  border: 1px solid #cfd4d9;
  box-shadow: 0px 0px 0px #cbdafc;
  border-radius: 5px;
  width: 100%;
  margin-top: 8px;
`;

export const STextarea = styled(SInput)`
  padding: 12px;
  resize: none;
`.withComponent('textarea');

export const SLabel = styled.label`
  display: block;
`;

export const SFooter = styled.footer`
  margin-top: auto;
  color: #4b4b4b;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;

  p {
    margin-left: 40px;
  }
`;

export const SPartnersBanner = styled(PartnersBanner)``;
