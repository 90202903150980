import styled from '@emotion/styled';
import { EditButton } from 'components/Button/EditButton';
import { Select } from 'components/Select/Select';

export const STile = styled.div`
  background-color: rgba(195, 212, 225, 0.26);
  padding: 8px 16px;
  border: 2px solid rgb(221, 225, 230);
  border-radius: 6px;
`;

export const SCloseBtnCnt = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

export const Label = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ModalTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

export const CieploSelectBox = styled('div')`
  min-width: 130px;
`;
