import classNames from 'classnames';
import React, { DetailedHTMLProps, ReactNode } from 'react';
import { EndIconCnt, SButton, SButtonProps, StartIconCnt } from './styles';
import { LinkProps } from 'react-router-dom';
import { Link } from '../Link/Link';
export type ButtonProps = {
  children?: ReactNode;
  to?: string;
  href?: string;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
} & DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
  SButtonProps &
  Omit<LinkProps, 'to'>;

export const Button = ({
  children,
  variant = 'contained',
  width,
  fullWidth,
  type = 'button',
  size = 'medium',
  className,
  dense,
  to,
  href,
  endIcon,
  startIcon,
  ...rest
}: ButtonProps) => {
  let Wrapper: any = to ? SButton.withComponent(Link) : SButton;
  if (href) {
    Wrapper = SButton.withComponent('a');
  }
  return (
    <Wrapper
      type={type}
      variant={variant}
      width={width}
      className={classNames(className, { 'btn-dense': dense })}
      to={to}
      href={href}
      size={size}
      fullWidth={fullWidth}
      {...rest}
    >
      {startIcon && <StartIconCnt>{startIcon}</StartIconCnt>}
      {children}
      {endIcon && <EndIconCnt>{endIcon}</EndIconCnt>}
    </Wrapper>
  );
};
