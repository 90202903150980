import styled from '@emotion/styled';
import { PartnersBanner } from 'components/PartnersBanner/PartnersBanner';

export const Cnt = styled.div`
  background-color: #f5f5f5;
  position: relative;
`;

export const SWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;

  @media (min-width: 1400px) {
    grid-template-columns: 360px 1fr;
    grid-gap: 40px;
  }
  @media (min-width: 1661px) {
    grid-template-columns: 450px 1fr;
  }
`;

export const SPartnersCnt = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SPartnersBanner = styled(PartnersBanner)`
  justify-content: space-between;
  flex-grow: 1;
`;

export const FirstCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SecondCol = styled.div`
  /* overflow: hidden; */
`;

export const BottomSpacer = styled.div`
  margin-bottom: 20px;
`;

export const ScenarioWithExport = styled.div`
  margin-bottom: 12px;

  .choose-scenario {
    min-width: 0;
  }
`;
