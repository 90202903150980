import styled from '@emotion/styled';

export const SContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }
`;
