import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'services/configuration/store/hooks';
import { getKoszty } from 'services/slices/obliczeniaSlice/selectors';
import { updatedDiff } from 'deep-object-diff';

import { koszty } from 'services/slices/obliczeniaSlice/koszty/koszty';
import { SearchPrams, deleteSearchParams } from '../AppParams/utils';
import { useEffect } from 'react';

export const useKosztyUpdate = (searchParams: URLSearchParams) => {
  const {
    O_M_paliwo,
    O_M_utrzymanie,
    aktualna_infrastruktura,
    koszt_mld_per_GW,
    nie_licz_kosztów_istniejącej_infrastruktury,
    stopa_dyskonta,
  } = useAppSelector(getKoszty);

  const { calculations, ...initialData } = koszty;

  const diff = updatedDiff(initialData, {
    O_M_paliwo,
    O_M_utrzymanie,
    aktualna_infrastruktura,
    koszt_mld_per_GW,
    nie_licz_kosztów_istniejącej_infrastruktury,
    stopa_dyskonta,
  });
  // deleteSearchParams(searchParams, SearchPrams.koszty);
  if (Object.keys(diff).length) {
    searchParams.append(SearchPrams.koszty, JSON.stringify(diff));
  }
};
