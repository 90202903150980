import { getBaseLog } from 'services/utils/getBaseLog';
import { ObliczeniaStateSlice } from '../obliczeniaSlice';
import { calcCieplo } from './cieplo/calcCieplo';
import { calcTransport } from './transport/calcTransport';
import { calcCieploPoElastycznosci } from './cieplo/calcCieploPoElastycznosci';
import { ObliczeniaTypeCalculations } from './types';
import { getYearHours, isLeapYear } from 'services/consts/months';
import { profileTransportu } from './transport/transportProfil';
import { cloneDeep } from 'lodash';

export const calcObliczenia = (state: ObliczeniaStateSlice) => {
  const {
    czas_ladowania_rozladowania_magazynu_pradu,
    sprawnosc_magazynow_ciepla,
    sprawnosc_elektrowni_gazowej,
    sprawnosc_magazynow_pradu,

    czas_ladowania_rozladowania_magazynu_ciepla,

    czas_ladowania_rozladowania_magazynu_ciepla_w_przemysle,
    roczne_zużycie_prądu_po_zamianie_silnikow_spalinowych_na_elektryczne,
    sprawnosc_magazynow_ciepla_w_przemysle,
  } = state.obliczenia.consts;

  const { zuzycie_pradu } = state.obliczenia.data;

  const {
    mnożnik_zapotrzebowania_KSE,
    wspolczynnik_elastyczności,
    pojemnosc_magazynu_ciepla,
    moc_elektrolizerow,
    baterie_litowe_stacjonarne,
    baterie_samochodowe,
    elektrownie_szczytowo_pompowe,
    odbiorcy_z_magazynami_ciepla_z_siecia_CO,
    odbiorcy_podlaczeni_do_sieci,
    odsetek_elektrowni_pracujacych_w_kogeneracji,
    strata_przy_przesyle,
    zapotrzebowanie_na_cieplo_w_przemysle,
    pojemnosc_magazynu_ciepla_w_przemysle,
    odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych,
    odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle,
  } = state.obliczenia.inputs;

  const {
    produkcja_pradu_per_day_po_stratach,
    PV_produkcja_per_day,
    atom_produkcja_per_day,
    wiatr_lad_produkcja_per_day,
    wiatr_offshore_produkcja_per_day,
  } = state.produkcjaEnergiiElektrycznej.calculations;

  const { inputs: cieploInputs } = state.obliczenia.cieplo;

  /**
   * @deprecated marking as deprecated to see assignment
   */
  const returnObj: ObliczeniaTypeCalculations = {
    transport_per_day: [],
    transport_per_year: 0,
    cieplo_per_day: [],
    cieplo_per_year: 0,
    energia_elektryczna_per_day: [],
    energia_elektryczna_per_year: 0,
    elektroliza_per_day: [],
    elektroliza_per_year: 0,
    źródła_dyspozycyjne_per_day: [],
    źródła_dyspozycyjne_per_year: 0,
    magazyn_ciepła_per_day: [],
    magazyn_ciepła_per_year: 0,
    magazyn_prądu_per_day: [],
    magazyn_prądu_per_year: 0,
    nadwyżka_prądu_per_year: 0,
    kogeneracja_per_year: 0,
    źródło_dyspozycyjne_max_moc: 0,
    źródło_dyspozycyjne_per_year_ze_stratami: 0,
    dostarczona_energia_per_day: [],
    ładowanie_magazynu_ciepła_per_day: [],
    ładowanie_magazynu_prądu_per_day: [],
    zapotrzebowanie_na_prąd_per_day: [],
    zapotrzebowanie_na_prąd_z_elastycznością_per_day: [],
    energia_z_magazynu_ciepła_per_day: [],
    energia_z_magazynu_prądu_per_day: [],
    cieplo_przemyslowe_per_day: [],
    cieplo_przemyslowe_per_year: 0,
    magazyn_ciepła_przemyslowy_per_day: [],
    magazyn_ciepła_przemyslowy_per_year: 0,
    ładowanie_magazynu_ciepla_przemyslowego_per_day: [],
    energia_z_magazynu_ciepla_przemyslowego_per_day: [],
    zapotrzebowanie_z_elastycznoscia_z_wodorem_per_day: [],

    pojemnosc_magazynu_pradu: 0,
    procent_podlaczenia_do_magazynow_ciepla: 0,
    wykorzystanie_wiatru_na_ladzie_per_year: 0,
    wykorzystanie_wiatru_na_morzu_per_year: 0,
    wykorzystanie_PV_per_year: 0,
    wykorzystanie_atomu_per_year: 0,
    magazyny_ciepla_domowe: 0,
    magazyny_ciepla_duze: 0,
    zapotrzebowanie_na_transport: 0,
    max_moc_grzalek_przy_magazynach: 0,
    cieplo: {
      CWU_cieplo_PC_suma: 0,
      CO_cieplo_PC_suma: 0,
      CO_energia_z_pc_po_elastycznosci_suma: 0,
      CO_moc_th_pc_po_elastycznosci_suma: 0,
      CO_reszta_ciepla_z_grzalek_po_elastycznosci_suma: 0,
      CWU_dostepna_moc_el_pc_po_elastycznosci_suma: 0,
      CWU_moc_th_pc_po_elastycznosci_suma: 0,
      CWU_reszta_ciepla_z_grzalek_po_elastycznosci_suma: 0,
    },
  };
  const pojemnosc_magazynu_pradu = baterie_litowe_stacjonarne + baterie_samochodowe + elektrownie_szczytowo_pompowe;
  returnObj.pojemnosc_magazynu_pradu = pojemnosc_magazynu_pradu;

  returnObj.magazyny_ciepla_duze = pojemnosc_magazynu_ciepla * 0.8;
  returnObj.magazyny_ciepla_domowe = pojemnosc_magazynu_ciepla * 0.2;

  const odbiorcy_ciepla_wspolczynik = Math.max(
    odbiorcy_podlaczeni_do_sieci,
    odbiorcy_z_magazynami_ciepla_z_siecia_CO - odbiorcy_podlaczeni_do_sieci,
  );
  const procent_podlaczenia_do_magazynow_ciepla = odbiorcy_z_magazynami_ciepla_z_siecia_CO;
  returnObj.procent_podlaczenia_do_magazynow_ciepla = procent_podlaczenia_do_magazynow_ciepla;

  const max_moc_grzalek_przy_magazynach = pojemnosc_magazynu_ciepla / czas_ladowania_rozladowania_magazynu_ciepla;
  returnObj.max_moc_grzalek_przy_magazynach = max_moc_grzalek_przy_magazynach;
  /**
   * @deprecated marking as deprecated to see assignment
   */
  const prevItems = {
    energia_w_magazynie_ciepla: 0,
    cieplo_odpadowe_ze_zrodel_dyspozycyjnych_gw_el: 0,
    cieplo_odpadowe_ze_zrodel_dyspozycyjnych_gw_th: 0,
    z_magazynu_ciepla: 0,
    do_magazynu_ciepla_energia_termiczna: 0,
    z_magazynu_pradu: 0,
    do_magazynu_pradu: 0,
    energia_w_magazynie_pradu: 0,
    energia_w_magazynie_magazynie_ciepla_dla_przemyslu: 0,
    bilans_magazynu_ciepla_dla_przemyslu: 0,
    zapotrzebowanie_ze_zrodel_dyspozycyjnych: 0,
  };

  const ciepłoCalcs = calcCieplo(state, procent_podlaczenia_do_magazynow_ciepla);

  const transportSuma = calcTransport(state);
  returnObj.zapotrzebowanie_na_transport = transportSuma;

  const cieplo_w_przemysle_wskaźnik = (255 / 3.6) * zapotrzebowanie_na_cieplo_w_przemysle * 1000;

  // TODO: poprawic logike jak bedzie budzet
  // const zuzycie_pradu_po_stratach = zuzycie_pradu.map((prad, i) => {
  //   const stopnio_dzien = ciepłoCalcs[i].stopnio_dzien;

  //   const energia_do_celow_grzewczych = (0.1174 * stopnio_dzien * stopnio_dzien + 2.3248 * stopnio_dzien) / 24;
  //   const zuzycie_pradu_bez_ciepla = prad - energia_do_celow_grzewczych;

  //   return zuzycie_pradu_bez_ciepla * (1 - 0.05);
  // });
  const zuzycie_pradu_po_stratach = isLeapYear(state.obliczenia.selectedYear)
    ? state.obliczenia.data.zuzycie_pradu_2023_rok_przestepny
    : state.obliczenia.data.zuzycie_pradu_2023;
  const suma_zapotrzebowania_na_prąd: number = zuzycie_pradu_po_stratach.reduce((acc, curr) => acc + curr, 0);

  for (let index = 0; index < getYearHours(state.obliczenia.selectedYear); index++) {
    const ciepło = ciepłoCalcs[index];

    const prąd = zuzycie_pradu_po_stratach[index] * mnożnik_zapotrzebowania_KSE;

    const profilTransportu = profileTransportu[index % 24];

    const transport_lądowy =
      (transportSuma / 365) *
      1000 *
      profilTransportu *
      (0.0017 * ciepło.srednia_temp * ciepło.srednia_temp - 0.054 * ciepło.srednia_temp + 1.27);

    const cieplo_przemyslowe =
      (zuzycie_pradu_po_stratach[index] * cieplo_w_przemysle_wskaźnik) / suma_zapotrzebowania_na_prąd;

    const suma = ciepło.zapotrzebowanie_prądu_na_ciepło + prąd + transport_lądowy + cieplo_przemyslowe;

    returnObj.zapotrzebowanie_na_prąd_per_day.push(suma);

    const podaż_do_zapotrzebowania =
      produkcja_pradu_per_day_po_stratach[index] / suma < 0.25
        ? 0.25
        : produkcja_pradu_per_day_po_stratach[index] / suma;

    const wspolczynnik_przy_elastycznosci =
      wspolczynnik_elastyczności > 0 ? Math.exp(Math.log(2) / wspolczynnik_elastyczności) : 0;

    const prąd_po_uwzględnieniu_elastycznosci =
      (wspolczynnik_elastyczności > 0 ? 1 + getBaseLog(podaż_do_zapotrzebowania, wspolczynnik_przy_elastycznosci) : 1) *
      prąd;
    returnObj.energia_elektryczna_per_day.push(prąd_po_uwzględnieniu_elastycznosci);
    returnObj.energia_elektryczna_per_year += prąd_po_uwzględnieniu_elastycznosci;

    const transport_po_uwzględnieniu_elastycznosci =
      (wspolczynnik_elastyczności > 0 ? 1 + getBaseLog(podaż_do_zapotrzebowania, wspolczynnik_przy_elastycznosci) : 1) *
      transport_lądowy;
    returnObj.transport_per_day.push(transport_po_uwzględnieniu_elastycznosci);
    returnObj.transport_per_year += transport_po_uwzględnieniu_elastycznosci;

    const {
      pobor_pradu_po_elastycznosci: cieplo_po_uwzglednieniu_elastycznosci,
      wykorzystana_moc_el_pc_po_elastycznosci: ciepło_z_PC,
      dataToReturn,
    } = calcCieploPoElastycznosci({
      ciepło,
      wspolczynnik_elastyczności,
      podaż_do_zapotrzebowania,
      moc_pomp_ciepla: cieploInputs.moc_pomp_ciepla,
      procent_podlaczenia_do_magazynow_ciepla,
      wspolczynnik_przy_elastycznosci,
      index,
      returnObj,
    });
    returnObj.cieplo = dataToReturn;
    returnObj.cieplo_per_day.push(cieplo_po_uwzglednieniu_elastycznosci);
    returnObj.cieplo_per_year += cieplo_po_uwzglednieniu_elastycznosci;

    const reszta_ciepla_z_grzalek = cieplo_po_uwzglednieniu_elastycznosci - ciepło_z_PC;
    const reszta_ciepla_z_grzalek_magazyn = reszta_ciepla_z_grzalek * procent_podlaczenia_do_magazynow_ciepla;

    const cieplo_przemyslowe_po_uwzglednieniu_elastycznosci =
      (wspolczynnik_elastyczności > 0 ? 1 + getBaseLog(podaż_do_zapotrzebowania, wspolczynnik_przy_elastycznosci) : 1) *
      cieplo_przemyslowe;
    returnObj.cieplo_przemyslowe_per_day.push(cieplo_przemyslowe_po_uwzglednieniu_elastycznosci);
    returnObj.cieplo_przemyslowe_per_year += cieplo_przemyslowe_po_uwzglednieniu_elastycznosci;

    const suma_po_uwzglednieniu_elastycznosci =
      cieplo_po_uwzglednieniu_elastycznosci +
      prąd_po_uwzględnieniu_elastycznosci +
      transport_po_uwzględnieniu_elastycznosci +
      cieplo_przemyslowe_po_uwzglednieniu_elastycznosci;
    returnObj.zapotrzebowanie_na_prąd_z_elastycznością_per_day.push(suma_po_uwzglednieniu_elastycznosci);
    if (moc_elektrolizerow > 0) {
      returnObj.zapotrzebowanie_z_elastycznoscia_z_wodorem_per_day.push(
        suma_po_uwzglednieniu_elastycznosci + moc_elektrolizerow,
      );
    }
    const bilans = produkcja_pradu_per_day_po_stratach[index] - suma_po_uwzglednieniu_elastycznosci;
    const nadwyżka = bilans > 0 ? 1 : 0;

    const getStanEnergiiWMagazynieCiepla = () => {
      if (index === 0) {
        return pojemnosc_magazynu_ciepla / 2;
      }
      return Math.min(
        prevItems.energia_w_magazynie_ciepla +
          prevItems.do_magazynu_ciepla_energia_termiczna +
          prevItems.cieplo_odpadowe_ze_zrodel_dyspozycyjnych_gw_el -
          prevItems.z_magazynu_ciepla * ciepłoCalcs[index - 1].CWU_COP_temp,

        pojemnosc_magazynu_ciepla +
          prevItems.cieplo_odpadowe_ze_zrodel_dyspozycyjnych_gw_th * ciepłoCalcs[index - 1].CWU_COP_temp,
      );
    };
    const stan_energii_w_magazynie_ciepla = getStanEnergiiWMagazynieCiepla();

    const pobor_ciepla_z_magazynu_zamiast_grzalek = Math.min(
      reszta_ciepla_z_grzalek_magazyn,
      stan_energii_w_magazynie_ciepla,
      Math.abs(bilans),
    );

    const pierwszy_bilans_po_magazynie_ciepla = bilans + pobor_ciepla_z_magazynu_zamiast_grzalek;

    const getEnergiaWMagazyniePradu = () => {
      if (index === 0) {
        return pojemnosc_magazynu_pradu / 2;
      }
      return (
        prevItems.energia_w_magazynie_pradu +
        prevItems.do_magazynu_pradu * sprawnosc_magazynow_pradu -
        prevItems.z_magazynu_pradu
      );
    };
    const energia_w_magazynie_pradu = getEnergiaWMagazyniePradu();

    returnObj.magazyn_prądu_per_day.push(energia_w_magazynie_pradu);
    returnObj.magazyn_prądu_per_year += energia_w_magazynie_pradu;

    const getStanMagazynuPradu = () => {
      if (energia_w_magazynie_pradu >= pojemnosc_magazynu_pradu) {
        return 2;
      }
      if (energia_w_magazynie_pradu <= 0) {
        return 0;
      }
      return 1;
    };
    const stan_magazynu_pradu = getStanMagazynuPradu();

    const getDoMagazynuPradu = () => {
      const stan_aktualny_w_magazynie_pradu =
        bilans > 0 && stan_magazynu_pradu < 2
          ? Math.min(bilans, pojemnosc_magazynu_pradu - energia_w_magazynie_pradu)
          : 0;

      return Math.min(
        stan_aktualny_w_magazynie_pradu,
        pojemnosc_magazynu_pradu / czas_ladowania_rozladowania_magazynu_pradu,
      );
    };
    const do_magazynu_pradu = getDoMagazynuPradu();
    returnObj.ładowanie_magazynu_prądu_per_day.push(do_magazynu_pradu);

    const bilans_po_ladowaniu_magazynu_pradu = pierwszy_bilans_po_magazynie_ciepla - do_magazynu_pradu;

    const getEnergiaWMagazynieMagazynieCieplaDlaPrzemyslu = () => {
      if (index === 0) {
        return pojemnosc_magazynu_ciepla_w_przemysle / 2;
      }
      return (
        prevItems.energia_w_magazynie_magazynie_ciepla_dla_przemyslu + prevItems.bilans_magazynu_ciepla_dla_przemyslu
      );
    };
    const energia_w_magazynie_magazynie_ciepla_dla_przemyslu = getEnergiaWMagazynieMagazynieCieplaDlaPrzemyslu();
    returnObj.magazyn_ciepła_przemyslowy_per_day.push(energia_w_magazynie_magazynie_ciepla_dla_przemyslu);
    returnObj.magazyn_ciepła_przemyslowy_per_year += energia_w_magazynie_magazynie_ciepla_dla_przemyslu;
    const getStanMagazynuCieplaDlaPrzemyslu = () => {
      if (energia_w_magazynie_magazynie_ciepla_dla_przemyslu >= pojemnosc_magazynu_ciepla_w_przemysle) {
        return 2;
      }
      if (energia_w_magazynie_magazynie_ciepla_dla_przemyslu <= 0) {
        return 0;
      }
      return 1;
    };
    const stan_magazynu_cipla_w_przemysle = getStanMagazynuCieplaDlaPrzemyslu();

    const getDoMagazynuCieplaDlaPrzemyslu = () => {
      const cond = bilans_po_ladowaniu_magazynu_pradu > 0 && stan_magazynu_cipla_w_przemysle < 2;
      return Math.min(
        cond
          ? Math.min(
              bilans_po_ladowaniu_magazynu_pradu,
              pojemnosc_magazynu_ciepla_w_przemysle - energia_w_magazynie_magazynie_ciepla_dla_przemyslu,
            )
          : 0,
        pojemnosc_magazynu_ciepla_w_przemysle / czas_ladowania_rozladowania_magazynu_ciepla_w_przemysle,
      );
      //=MIN( JEŻELI(ORAZ(AO10>0;AQ10<2);MIN(AO10;AP$5-AP10);0);AP$5/AR$5)
    };
    const do_magazynu_ciepla_w_przemysle = getDoMagazynuCieplaDlaPrzemyslu();
    returnObj.ładowanie_magazynu_ciepla_przemyslowego_per_day.push(0);
    // returnObj.ładowanie_magazynu_ciepla_przemyslowego_per_day.push(do_magazynu_ciepla_w_przemysle);

    const cieplo_przemyslowe_po_uwzglednieniu_elastycznosci_bez_magazynow =
      cieplo_przemyslowe_po_uwzglednieniu_elastycznosci *
      (1 - odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle);
    const cieplo_przemyslowe_po_uwzglednieniu_elastycznosci_z_magazynami =
      cieplo_przemyslowe_po_uwzglednieniu_elastycznosci -
      cieplo_przemyslowe_po_uwzglednieniu_elastycznosci_bez_magazynow;
    const getZMagazynuCieplaDlaPrzemyslu = () => {
      const cond = bilans_po_ladowaniu_magazynu_pradu < 0 && stan_magazynu_cipla_w_przemysle > 0;
      if (cond) {
        return Math.min(
          cieplo_przemyslowe_po_uwzglednieniu_elastycznosci_z_magazynami,
          energia_w_magazynie_magazynie_ciepla_dla_przemyslu,
          energia_w_magazynie_magazynie_ciepla_dla_przemyslu / czas_ladowania_rozladowania_magazynu_ciepla_w_przemysle,
        );
      }
      return 0;
    };
    const z_magazynu_ciepla_w_przemysle = getZMagazynuCieplaDlaPrzemyslu();
    returnObj.energia_z_magazynu_ciepla_przemyslowego_per_day.push(0);
    // returnObj.energia_z_magazynu_ciepla_przemyslowego_per_day.push(z_magazynu_ciepla_w_przemysle);

    const bilans_magazynu_ciepla_dla_przemyslu = do_magazynu_ciepla_w_przemysle - z_magazynu_ciepla_w_przemysle;
    const bilans_prądu_po_ladowaniu_magazynu_ciepla_dla_przemyslu =
      bilans_po_ladowaniu_magazynu_pradu - bilans_magazynu_ciepla_dla_przemyslu;

    const ciepło_bezpośrednio = cieplo_po_uwzglednieniu_elastycznosci * (1 - procent_podlaczenia_do_magazynow_ciepla);
    const ciepło_z_siecią_i_magazynami =
      cieplo_po_uwzglednieniu_elastycznosci - ciepło_bezpośrednio - pobor_ciepla_z_magazynu_zamiast_grzalek;
    const energia_w_magazynie_ciepla = stan_energii_w_magazynie_ciepla - pobor_ciepla_z_magazynu_zamiast_grzalek;

    returnObj.magazyn_ciepła_per_day.push(Math.min(energia_w_magazynie_ciepla, pojemnosc_magazynu_ciepla));
    returnObj.magazyn_ciepła_per_year += energia_w_magazynie_ciepla;

    const getStanMagazynuCiepla = () => {
      if (energia_w_magazynie_ciepla >= pojemnosc_magazynu_ciepla) {
        return 2;
      }
      if (energia_w_magazynie_ciepla <= 0) {
        return 0;
      }
      return 1;
    };
    const stan_magazynu_ciepla = getStanMagazynuCiepla();
    const wolne_w_magazynie_ciepla = Math.max(pojemnosc_magazynu_ciepla - energia_w_magazynie_ciepla, 0);

    const getDoMagazynuCieplaAR = () => {
      if (bilans_prądu_po_ladowaniu_magazynu_ciepla_dla_przemyslu > 0 && stan_magazynu_ciepla < 2) {
        return Math.min(
          bilans_prądu_po_ladowaniu_magazynu_ciepla_dla_przemyslu,
          ciepło.dostepna_dla_magazynu_moc_el_pc,
          wolne_w_magazynie_ciepla / ciepło.CWU_COP_temp,
        );
      }
      return 0;
    };
    const do_magazynu_ciepla_GWH_el_z_PC = getDoMagazynuCieplaAR();

    const do_magazynu_ciepla_GWH_th_PC = do_magazynu_ciepla_GWH_el_z_PC * ciepło.CWU_COP_temp;

    const getDoMagazynuCieplaZGrzalek = () => {
      const getFirst = () => {
        if (
          bilans_prądu_po_ladowaniu_magazynu_ciepla_dla_przemyslu - do_magazynu_ciepla_GWH_el_z_PC > 0 &&
          bilans_prądu_po_ladowaniu_magazynu_ciepla_dla_przemyslu > 0 &&
          stan_magazynu_ciepla < 2
        ) {
          return bilans_prądu_po_ladowaniu_magazynu_ciepla_dla_przemyslu - do_magazynu_ciepla_GWH_el_z_PC;
        }
        return 0;
      };

      return Math.min(
        getFirst(),
        pojemnosc_magazynu_ciepla / czas_ladowania_rozladowania_magazynu_ciepla,
        wolne_w_magazynie_ciepla,
      );
    };
    const do_magazynu_ciepla_z_grzalek = getDoMagazynuCieplaZGrzalek();

    const ładowanie_magazynu_ciepła = Math.max(
      do_magazynu_ciepla_GWH_el_z_PC + do_magazynu_ciepla_z_grzalek - pobor_ciepla_z_magazynu_zamiast_grzalek,
      0,
    );

    returnObj.ładowanie_magazynu_ciepła_per_day.push(ładowanie_magazynu_ciepła + do_magazynu_ciepla_w_przemysle);
    const do_magazynu_ciepla_energia_termiczna =
      (do_magazynu_ciepla_GWH_th_PC + do_magazynu_ciepla_z_grzalek) * sprawnosc_magazynow_ciepla;

    const getZMagazynuCiepla = () => {
      if (bilans_prądu_po_ladowaniu_magazynu_ciepla_dla_przemyslu < 0 && stan_magazynu_ciepla > 0) {
        return Math.min(
          -bilans_prądu_po_ladowaniu_magazynu_ciepla_dla_przemyslu,
          energia_w_magazynie_ciepla / ciepło.CWU_COP_temp,
          ciepło_z_siecią_i_magazynami,
        );
      }
      return 0;
    };
    const z_magazynu_ciepla = getZMagazynuCiepla();

    const z_magazynu_ciepla_lacznie =
      z_magazynu_ciepla + (nadwyżka === 0 ? pobor_ciepla_z_magazynu_zamiast_grzalek : 0);

    returnObj.energia_z_magazynu_ciepła_per_day.push(z_magazynu_ciepla_lacznie + z_magazynu_ciepla_w_przemysle);
    const bilans_po_magazynie_ciepla =
      bilans_prądu_po_ladowaniu_magazynu_ciepla_dla_przemyslu -
      do_magazynu_ciepla_GWH_el_z_PC -
      do_magazynu_ciepla_z_grzalek +
      z_magazynu_ciepla;

    const getZMagazynuPradu = () => {
      const cond =
        bilans_po_magazynie_ciepla < 0 && energia_w_magazynie_pradu > 0
          ? Math.min(-bilans_po_magazynie_ciepla, energia_w_magazynie_pradu)
          : 0;
      return Math.min(cond, pojemnosc_magazynu_pradu / czas_ladowania_rozladowania_magazynu_pradu);
    };
    const z_magazynu_pradu = getZMagazynuPradu();

    returnObj.energia_z_magazynu_prądu_per_day.push(z_magazynu_pradu);

    const bilans_prądu_na_koniec = bilans_po_magazynie_ciepla + z_magazynu_pradu;

    const niedobor_pradu_zrodla_dysp = bilans_prądu_na_koniec < 0 ? Math.abs(bilans_prądu_na_koniec) : 0;
    returnObj.źródła_dyspozycyjne_per_day.push(niedobor_pradu_zrodla_dysp);
    const straty_ze_zrodel_dyspozycyjnych = niedobor_pradu_zrodla_dysp * (1 / (1 - strata_przy_przesyle) - 1);
    const zapotrzebowanie_ze_zrodel_dyspozycyjnych = niedobor_pradu_zrodla_dysp + straty_ze_zrodel_dyspozycyjnych;
    returnObj.źródło_dyspozycyjne_per_year_ze_stratami =
      returnObj.źródło_dyspozycyjne_per_year_ze_stratami + zapotrzebowanie_ze_zrodel_dyspozycyjnych;
    returnObj.źródło_dyspozycyjne_max_moc = Math.max(
      returnObj.źródło_dyspozycyjne_max_moc,
      zapotrzebowanie_ze_zrodel_dyspozycyjnych,
    );

    const dzialanie_zrodel_dysp = zapotrzebowanie_ze_zrodel_dyspozycyjnych > 0 ? 1 : 0;

    const cieplo_odpadowe_ze_zrodel_dyspozycyjnych_gw_th = Math.min(
      ((zapotrzebowanie_ze_zrodel_dyspozycyjnych * (1 - sprawnosc_elektrowni_gazowej)) / sprawnosc_elektrowni_gazowej) *
        odsetek_elektrowni_pracujacych_w_kogeneracji +
        cieplo_przemyslowe_po_uwzglednieniu_elastycznosci *
          odsetek_ciepla_odpadowego_z_przemyslu_oddawany_do_sieci_cieplowniczych,
      ciepło.zapotrzebowanie_prądu_na_ciepło,
    );

    const cieplo_odpadowe_ze_zrodel_dyspozycyjnych_gw_el =
      cieplo_odpadowe_ze_zrodel_dyspozycyjnych_gw_th / ciepło.real_COP;

    returnObj.kogeneracja_per_year += cieplo_odpadowe_ze_zrodel_dyspozycyjnych_gw_el;

    const nadwyzka_pradu_na_inne_cele = bilans_prądu_na_koniec > 0 ? bilans_prądu_na_koniec : 0;

    let result = 0;
    if (index > 0) {
      if (zapotrzebowanie_ze_zrodel_dyspozycyjnych < prevItems.zapotrzebowanie_ze_zrodel_dyspozycyjnych) {
        result += prevItems.zapotrzebowanie_ze_zrodel_dyspozycyjnych;
      } else {
        result +=
          (3 * prevItems.zapotrzebowanie_ze_zrodel_dyspozycyjnych + zapotrzebowanie_ze_zrodel_dyspozycyjnych) / 4;
      }

      returnObj.źródła_dyspozycyjne_per_year += result;

      if (index === getYearHours(state.obliczenia.selectedYear) - 1) {
        returnObj.źródła_dyspozycyjne_per_year += zapotrzebowanie_ze_zrodel_dyspozycyjnych;
      }
    }

    const praca_elektrolizerow = Math.min(moc_elektrolizerow, nadwyzka_pradu_na_inne_cele);
    returnObj.elektroliza_per_day.push(praca_elektrolizerow);
    returnObj.elektroliza_per_year += praca_elektrolizerow;
    returnObj.nadwyżka_prądu_per_year += nadwyzka_pradu_na_inne_cele - praca_elektrolizerow;

    const procent_wykorzystania =
      (produkcja_pradu_per_day_po_stratach[index] - nadwyzka_pradu_na_inne_cele + praca_elektrolizerow) /
      produkcja_pradu_per_day_po_stratach[index];

    returnObj.wykorzystanie_wiatru_na_ladzie_per_year += procent_wykorzystania * wiatr_lad_produkcja_per_day[index];
    returnObj.wykorzystanie_wiatru_na_morzu_per_year += procent_wykorzystania * wiatr_offshore_produkcja_per_day[index];
    returnObj.wykorzystanie_PV_per_year += procent_wykorzystania * PV_produkcja_per_day[index];
    returnObj.wykorzystanie_atomu_per_year += procent_wykorzystania * atom_produkcja_per_day[index];

    prevItems.bilans_magazynu_ciepla_dla_przemyslu = bilans_magazynu_ciepla_dla_przemyslu;
    prevItems.energia_w_magazynie_ciepla = energia_w_magazynie_ciepla;
    prevItems.do_magazynu_ciepla_energia_termiczna = do_magazynu_ciepla_energia_termiczna;
    prevItems.z_magazynu_ciepla = z_magazynu_ciepla;
    prevItems.z_magazynu_pradu = z_magazynu_pradu;
    prevItems.energia_w_magazynie_pradu = energia_w_magazynie_pradu;
    prevItems.do_magazynu_pradu = do_magazynu_pradu;
    prevItems.energia_w_magazynie_magazynie_ciepla_dla_przemyslu = energia_w_magazynie_magazynie_ciepla_dla_przemyslu;
    prevItems.cieplo_odpadowe_ze_zrodel_dyspozycyjnych_gw_el = cieplo_odpadowe_ze_zrodel_dyspozycyjnych_gw_el;
    prevItems.cieplo_odpadowe_ze_zrodel_dyspozycyjnych_gw_th = cieplo_odpadowe_ze_zrodel_dyspozycyjnych_gw_th;
    prevItems.zapotrzebowanie_ze_zrodel_dyspozycyjnych = zapotrzebowanie_ze_zrodel_dyspozycyjnych;
  }
  return returnObj;
};
