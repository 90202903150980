export const transport = {
  /**wartość w mln */
  ilość_aut_osobowych: 0,

  /**wartość w kWh/100km */
  zużycie_energii_aut_osobowych: 0,

  /** tys. km rocznie */
  średni_przejeżdżany_dystans_aut_osobowych: 8,

  /**Liczba pasażeroklometrów wzgl. 2023 */
  liczba_pasażerokilometrów: 0,

  /**wzgl. 2023 */
  wolumen_transportu_towarów: 0,

  udział_kolei_w_transporcie: 0,

  inne_rodzaje_transportu: 0,

  procentowe_zapotrzebowanie_na_transport: null as number | null,

  procentowe_zapotrzebowanie_na_transport_custom: null as number | null,

  calculations: {
    auta_osobowe: 0,
    transport_zbiorowy: 0,
    transport_towarowy: 0,
    inny_transport: 0,
    calculated_procentowe_zapotrzebowanie_na_transport: 0,
  },
};
