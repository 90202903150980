export enum SIMULATOR_INTRO_STEPS {
  START = 'start-step',
  ENERGY_SOURCES = 'energy-sources-step',
  DEMAND = 'demand-step',
  STORAGE = 'storage-step',
  HYDROGEN = 'hydrogen-step',
  DISPOSAL_SOURCES = 'disposal-sources-step',
  TIME_BALANCE = 'time-balance-step',
  HYDROGEN_BALANCE = 'hydrogen-balance-step',
  CONSTRUCTION_COSTS = 'construction-costs-step',
  ENERGY_BALANCE = 'energy-balance-step',
  LINE_CHART = 'line-chart-step',
  EXPORT_PDF = 'export-pdf-step',
  EXPORT_DATA = 'export-data-step',
  BILANS_OGRZEWANIA = 'bilans-ogrzewania-step',
  ELEKTROLIZA = 'elektroliza-step',
  CHOOSE_SCENARIO = 'choose-scenario-step',
  ADVANCED_DATA = 'advanced-data-step',
  SAVE_SIMULATION = 'save-simulation-step',
  CHOOSE_YEAR = 'choose-year-step',
}
