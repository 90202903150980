import styled from '@emotion/styled';
import { ReactComponent as LoadingText } from 'assets/loading_text.svg';
import { ReactComponent as EnLogo } from '../../assets/en-logo.svg';
export const SLoadingCnt = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #012633;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SText = styled.p`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
`;

export const SLoadingText = styled(LoadingText)`
  margin-bottom: 80px;
`;

export const SEnLogo = styled(EnLogo)`
  width: 350px;
  height: 155px;
  margin-bottom: 60px;
`;
