import '@emotion/react';

function hexToRGB(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

export const chartJsTooltip = {
  usePointStyle: true,
  backgroundColor: '#FFFFFF',
  bodyColor: '#0E0F11',
  titleFont: { family: 'Inter', size: 12, weight: 700, lineHeight: 1.5 },
  titleColor: '#0E0F11',
  titleMarginBottom: 12,
  bodyFont: { family: 'Inter', size: 12, weight: 500, lineHeight: 1.5 },
  multiKeyBackground: '#FFFFFF',
  boxWidth: 10,
  boxHeight: 10,
  boxPadding: 5,
  bodySpacing: 5,
  cornerRadius: 8,
  padding: 16,
};

export const appTheme = {
  primary: '#0E0F11',
  bodyBg: '#fafafa',
  errorColor: '#FF1654',
  addAlpha: hexToRGB,
  disabledColor: '#748F9C',
  dsDark: '#212529',
  secondary: '#297f94',
  chartTooltipBg: '#FFFFFF',
  simulatorBg: 'rgb(1, 38, 51)',
  chartJsTooltip,
};
