import React, { memo, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {
  ChartWrapper,
  CloseBtn,
  SChooseVariant,
  SCnt,
  Sdiv,
  SExpandBtn,
  ShrinkBtn,
  SPeriod,
  SToggleTooltipBtn,
} from './styles';

import { selectOptions } from './consts';
import { Tooltip as IconTooltip } from '../../../components/Tooltip/Tooltip';
import { Legend as LegendCnt, LegendOption } from '../../../components/Legend/Legend';

import { useDataset } from './hooks/useDataset/useDataset';
import { useLineOptions } from './hooks/useLineOptions/useLineOptions';
import { useTranslation } from 'react-i18next';
import keys from 'i18n/keys';
import { ReactComponent as ExpandIcon } from 'assets/Expand_icon.svg';
import { ReactComponent as ShrinkIcon } from 'assets/Shrink_icon.svg';
import { ReactComponent as CloseIcon } from 'assets/close_icon.svg';
import { ReactComponent as CursorIcon } from 'assets/Cursor.svg';
import moment from 'moment';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';
import { PeriodType } from 'features/Period/types';
import { PeriodEnum } from 'services/types/PeriodEnum';
import { getObliczenia } from 'services/slices/obliczeniaSlice/selectors';
import { useAppSelector } from 'services/configuration/store/hooks';
import { getYearHours, isLeapYear } from 'services/consts/months';
import { Select } from 'components/Select/Select';
import { Box, Card, Spacer } from 'components/common';
import { Button } from 'components/Button/Button';
import { LegendButton } from 'components/Legend/LegendButton';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler);
type LineEnergyProductionProps = {
  index?: number;
  isInModalView?: boolean;
  onOpenInModalMode?: () => void;
  onCloseModal?: (arg: any) => void;
  isPdfView?: boolean;
  id?: string;
  period?: PeriodType;
  legendExpanded?: boolean;
  currNumb?: number;
};

export const LineEnergyProduction = memo(
  ({
    index = 0,
    isInModalView = false,
    onOpenInModalMode,
    onCloseModal,
    id,
    period,
    currNumb,
    isPdfView,
  }: LineEnergyProductionProps) => {
    const [selectedOption, setSelectedOption] = useState(selectOptions[index]);
    const [currentPeriod, setCurrentPeriod] = useState<PeriodType>({
      start: 0,
      end: 743,
      target: PeriodEnum.MONTTH,
      targetValue: 1,
    });

    const { selectedYear } = useAppSelector(getObliczenia);

    const labels = useMemo(
      () =>
        Array.from(
          { length: getYearHours(selectedYear) },
          (_, i) => moment.utc(`01-01-${selectedYear}`, 'DD-MM-YYYY').add(i, 'hour').format('DD-MM HH:mm'),
          // (_, i) => i.toString(),
        ),
      [selectedYear],
    );

    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isLegendExpanded, setIsLegendExpanded] = useState(false);

    const filterByPeriod = (_, i) => {
      if (period) {
        return i >= period.start && i <= period.end;
      }
      return i >= currentPeriod.start && i <= currentPeriod.end;
    };

    const dataset = useDataset({ selectedOption, filterByPeriod });
    const { t } = useTranslation();

    const data: ChartData<'line', number[], string> = {
      labels: labels.filter(filterByPeriod),
      datasets: dataset,
    };

    const options = useLineOptions({
      period: period || currentPeriod,
      isInModalView,
      selectedOption,
      isTooltipVisible,
    });

    const legendOptions: LegendOption[] = dataset.map(el => ({
      color: el.backgroundColor as string,
      name: t(el.label!),
      borderColor: el.borderColor as string,
    }));

    const handleSelectChange = (val: typeof selectOptions[0]) => {
      const found = selectOptions.find(el => el.value === val.value)!;
      setSelectedOption(found);
    };
    const handleOpenModalMode = () => {
      onOpenInModalMode?.();
    };
    const handleCloseModal = () => {
      onCloseModal?.(currNumb);
    };

    const toggleTooltipVisibility = () => {
      setIsTooltipVisible(!isTooltipVisible);
    };

    return (
      <div>
        <SCnt id={SIMULATOR_INTRO_STEPS.LINE_CHART} className="line-section">
          <SChooseVariant>
            <Box d="flex" gap="12px">
              <Select
                variant="outlined"
                options={selectOptions}
                value={selectedOption}
                onChange={handleSelectChange}
                startIcon={<IconTooltip id="line-energy-production" content={t(keys.LINE_CHART_SECTION_TOOLTIP)} />}
              />
              <LegendButton expanded={isLegendExpanded} onClick={() => setIsLegendExpanded(prev => !prev)} />
            </Box>
            <SPeriod currentPeriod={currentPeriod} updatePeriod={setCurrentPeriod} />
            {currNumb === 1 && (
              <ShrinkBtn variant="text" onClick={handleCloseModal}>
                <span>{t(keys.SHOW_SMALLER)}</span> <ShrinkIcon />
              </ShrinkBtn>
            )}
            {currNumb && currNumb > 1 && (
              <CloseBtn variant="outlined" onClick={handleCloseModal}>
                <span>{t(keys.REMOVE_FROM_VIEW)}</span>
                <CloseIcon />
              </CloseBtn>
            )}
          </SChooseVariant>
          <Sdiv id={id} className="line-chart-wrapper">
            <ChartWrapper className="chart-cnt">
              {/* {!isInModalView && (
                <SExpandBtn onClick={handleOpenModalMode}>
                  <ExpandIcon />
                </SExpandBtn>
              )} */}
              {!isPdfView && (
                <SToggleTooltipBtn tooltipVisible={isTooltipVisible} onClick={toggleTooltipVisibility}>
                  <CursorIcon />
                </SToggleTooltipBtn>
              )}
              <Line
                redraw={isInModalView}
                aria-label={t(selectedOption.label)}
                aria-describedby={`Wykres przedstawia dane zapotrzebowanie/produkcję elementu. Elementy przedstawione na wykresie: ${legendOptions
                  .map(el => el.name)
                  .join(', ')}`}
                options={options}
                data={data}
              />
            </ChartWrapper>
            {isLegendExpanded && (
              <Box mt="12px">
                <LegendCnt options={legendOptions} />
              </Box>
            )}
          </Sdiv>
        </SCnt>
      </div>
    );
  },
);
