import React, { useEffect } from 'react';
import { addSearchParams, deleteAllSearchParams, deleteSearchParams, getSearchParam } from 'App/AppParams/utils';
import { AVAILABLE_SCENARIOS, defaultScenario } from 'services/slices/obliczeniaSlice/scenarios';
import { useProdukcjaUpdate } from './useProdukcjaUpdate';
import { useObliczeniaUpdate } from './useObliczeniaUpdate';
import { useKosztyUpdate } from './useKosztyUpdate';
import { useWodorUpdate } from './useWodorUpdate';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'services/configuration/store/hooks';

export const AppChangedParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentScenario } = useAppSelector(state => state.period);
  const selectedScenarioIndex = AVAILABLE_SCENARIOS.findIndex((el, i) => el.title === currentScenario.title);

  useProdukcjaUpdate(currentScenario, searchParams);
  useObliczeniaUpdate(currentScenario, searchParams);
  useKosztyUpdate(searchParams);
  useWodorUpdate(currentScenario, searchParams);
  useEffect(() => {
    deleteSearchParams(searchParams, 'scenario');
    if (selectedScenarioIndex !== -1 && selectedScenarioIndex !== 0) {
      addSearchParams(searchParams, 'scenario', selectedScenarioIndex.toString());
    }
    setSearchParams(searchParams);
  }, []);
  return null;
};
