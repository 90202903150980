/** @jsx jsx */
import keys from '../../../../i18n/keys';
import { jsx, css } from '@emotion/react';
import React, { useEffect } from 'react';
import { useAppSelector } from 'services/configuration/store/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { roundNumb } from 'services/utils/roundNumb';
import { TileTitle } from 'components/Titles/TileTitle';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';
import { getObliczenia, getWodor } from 'services/slices/obliczeniaSlice/selectors';
import { getYearHours } from 'services/consts/months';
import { Box, Spacer } from 'components/common';
import { Text } from '../GroupedData.styles';
import { Grid, HydrogenText } from './Hydrogen.styles';
import { PRZELICZNIKI } from 'services/slices/obliczeniaSlice/koszty/constans';
type HydrogenPieProps = {
  setWodorStats: (arg: { produkcja: number; zapotrzebowanie: number }) => void;
};
export const HydrogenPie = ({ setWodorStats }: HydrogenPieProps) => {
  const { suma_zapotrzebowania } = useAppSelector(getWodor);
  const {
    calculations: { elektroliza_per_year },
    inputs: { moc_elektrolizerow },
    selectedYear,
  } = useAppSelector(getObliczenia);
  const needed = roundNumb(suma_zapotrzebowania);
  const produced = roundNumb(elektroliza_per_year / 1000);
  const balance = roundNumb(Number(produced) - Number(needed));

  const disposalTime =
    moc_elektrolizerow > 0
      ? `${roundNumb((produced / ((moc_elektrolizerow * getYearHours(selectedYear)) / 1000)) * 100, 1)} %`
      : 'N/A';
  const isWystarajaca = balance >= 0;

  const neededMlnTon = roundNumb(
    needed / (PRZELICZNIKI.energia1MlnTonWodoru / PRZELICZNIKI.sprawnośćElektrolizerów),
    1,
  );
  const producedMlnTon = roundNumb(
    produced / (PRZELICZNIKI.energia1MlnTonWodoru / PRZELICZNIKI.sprawnośćElektrolizerów),
    1,
  );

  useEffect(() => {
    setWodorStats({
      produkcja: producedMlnTon,
      zapotrzebowanie: neededMlnTon,
    });
  }, [producedMlnTon, neededMlnTon, setWodorStats]);

  const { t } = useTranslation();
  return (
    <div id={SIMULATOR_INTRO_STEPS.HYDROGEN_BALANCE}>
      <Spacer mb="16px">
        <HydrogenText>
          <Trans
            i18nKey={keys.HYDROGEN_DATA_DESC}
            t={t}
            values={{
              moc_elektrolizerow: `${moc_elektrolizerow} ${t(keys.GW)}`,
              wyk_mocy: disposalTime,
            }}
          />
        </HydrogenText>
      </Spacer>
      <Grid>
        <HydrogenText color="#647C53">{t(keys.HYDROGEN_DATA_DOSTEPNOSC)}</HydrogenText>
        <HydrogenText color="#647C53">
          {produced} {t(keys.TWH)}
        </HydrogenText>
        <HydrogenText color="#647C53">
          {producedMlnTon} {t(keys.HYDROGEN_DATA_MLN_TON)}
        </HydrogenText>
      </Grid>
      <Grid border>
        <HydrogenText color="#FF6969">{t(keys.HYDROGEN_DATA_ZAPOTRZ)}</HydrogenText>
        <HydrogenText color="#FF6969">
          {needed > 0 ? `-${needed}` : needed} {t(keys.TWH)}
        </HydrogenText>
        <HydrogenText color="#FF6969">
          {neededMlnTon} {t(keys.HYDROGEN_DATA_MLN_TON)}
        </HydrogenText>
      </Grid>
      <Grid>
        <HydrogenText>{t(keys.HYDROGEN_DATA_BIALNS)}</HydrogenText>
        <HydrogenText color={isWystarajaca ? '#647C53' : '#FF6969'}>
          {roundNumb(produced - needed)} {t(keys.TWH)}
        </HydrogenText>
        <HydrogenText color={isWystarajaca ? '#647C53' : '#FF6969'}>
          {isWystarajaca ? t(keys.HYDROGEN_DATA_WYST) : t(keys.HYDROGEN_DATA_NIE_WYST)}
        </HydrogenText>
      </Grid>
    </div>
  );
};
