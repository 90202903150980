import React, { useEffect, useRef, useState } from 'react';
import Select, { components, ContainerProps } from 'react-select';
import { TextSelectProps } from './types';

import { SCarretDownIcon, SSelectContainer, TextSelectStyles } from './styles';

export const TextSelect = ({ onChange, value, options, displayValue, color, menuPlacement }: TextSelectProps) => {
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const selectRef = useRef<any>(null);
  const selectContainerRef = useRef<any>(null);
  const state = useRef({ focused, open });
  const toggleOpen = () => {
    if (!open) {
      selectRef.current.focus();
    }
    setOpen(!open);
  };
  const SelectContainer = ({ children, ...props }: ContainerProps<any>) => {
    return (
      <components.SelectContainer {...props}>
        {children}
        <SSelectContainer
          className="select-container"
          ref={selectContainerRef}
          focused={props.isFocused}
          color={color}
          onClick={toggleOpen}
          role="button"
        >
          {displayValue}
          <SCarretDownIcon />
        </SSelectContainer>
      </components.SelectContainer>
    );
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  const handleChange = val => {
    onChange(val);
    setOpen(false);
  };

  const onMenuClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      state.current = { open, focused };
    }, 100);
  }, [open, focused]);

  useEffect(() => {
    const onKeyDown = e => {
      if (!state.current.focused) {
        return;
      }

      if (['ArrowDown', 'Space'].includes(e.code) && !state.current.open) {
        setOpen(true);
      }
      if (state.current.open && ['Escape', 'Tab'].includes(e.code)) {
        setOpen(false);
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <div>
      <Select
        openMenuOnFocus
        ref={selectRef}
        styles={TextSelectStyles}
        options={options}
        isClearable={false}
        isSearchable={false}
        menuIsOpen={open}
        components={{ SelectContainer }}
        onChange={handleChange}
        onFocus={onFocus}
        value={value}
        onBlur={onBlur}
        onMenuClose={onMenuClose}
        menuPlacement={menuPlacement}
        isOptionDisabled={option => option.disabled}
        menuPortalTarget={document.body}
      />
    </div>
  );
};
