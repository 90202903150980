import React, { ReactNode } from 'react';
import { Cnt, SLabel } from './styles';
type CheckboxProps = {
  id: string;
  label: ReactNode;
  name?: string;
  className?: string;
  value: boolean;
  onChange: (arg: boolean) => void;
};

export const Checkbox = ({ id, label, name, className, value, onChange }: CheckboxProps) => {
  const handleChange = () => {
    onChange(!value);
  };
  return (
    <Cnt className={className}>
      <input type="checkbox" name={name} id={id} checked={value} onChange={handleChange} />
      <SLabel htmlFor={id}>{label}</SLabel>
    </Cnt>
  );
};
