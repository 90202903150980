import { ChartData } from 'chart.js';
import { useAppSelector } from 'services/configuration/store/hooks';

import { SelectOption, SelectOptionsValues } from '../../types';
import { consumtpionVsProductionData } from './options/consumtpionVsProductionData';
import { energyDemandVsProduction } from './options/energyDemandVsProduction';
import { energyStorage } from './options/energyStorage';
import { heatStorage } from './options/heatStorage';
import { getObliczeniaReducer } from 'services/slices/obliczeniaSlice/selectors';
import { magazynCieplaPrzemyslowego } from './options/magazynCieplaPrzemyslowego';
type UseDataset = {
  selectedOption: SelectOption;
  filterByPeriod: (_: any, i: number) => boolean;
};
export const useDataset = ({
  selectedOption,
  filterByPeriod,
}: UseDataset): ChartData<'line', number[], string>['datasets'] => {
  const {
    produkcjaEnergiiElektrycznej: { inputs: productionInputs, calculations: productionCalculations },
    obliczenia: { inputs: obliczeniaInputs, calculations: obliczeniaCalc, cieplo },
  } = useAppSelector(getObliczeniaReducer);
  const heatInputs = cieplo.inputs;
  const datasets = {
    // [SelectOptionsValues.All]: all,
    [SelectOptionsValues.EnergyDemandVsProduction]: consumtpionVsProductionData({
      filterByPeriod,
      heatInputs,
      productionCalculations,
      obliczeniaCalc,
      obliczeniaInputs,
    }),
    [SelectOptionsValues.EnergyProductionVsDemand]: energyDemandVsProduction({
      productionInputs,
      productionCalculations,
      obliczeniaInputs,
      obliczeniaCalc,
      filterByPeriod,
    }),
    [SelectOptionsValues.EnergyStorage]: energyStorage({ filterByPeriod, obliczeniaCalc, obliczeniaInputs }),
    [SelectOptionsValues.HeatStorage]: heatStorage({ filterByPeriod, obliczeniaCalc, obliczeniaInputs }),
    [SelectOptionsValues.MagazynCieploPrzemyslowe]: magazynCieplaPrzemyslowego({
      filterByPeriod,
      obliczeniaCalc,
      obliczeniaInputs,
    }),
  };
  return datasets[selectedOption.value]();
};
