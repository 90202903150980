import { ChartData } from 'chart.js';
import { DATASET_LABELS } from 'features/Charts/LineEnergyProduction/consts';
import { ENERGY_STORAGE_COLORS } from './colors';
import { ObliczeniaTypeCalculations, ObliczeniaTypeInputs } from 'services/slices/obliczeniaSlice/obliczenia/types';
import { NEW_COLORS } from 'services/consts/colors';

type EnergyStorageType = {
  filterByPeriod: (_: number, i: number) => boolean;
  obliczeniaInputs: ObliczeniaTypeInputs;
  obliczeniaCalc: ObliczeniaTypeCalculations;
};
export const energyStorage =
  ({ filterByPeriod, obliczeniaCalc, obliczeniaInputs }: EnergyStorageType) =>
  (): ChartData<'line', number[], string>['datasets'] => {
    const energyStorageState = obliczeniaCalc.pojemnosc_magazynu_pradu
      ? obliczeniaCalc.magazyn_prądu_per_day.filter(filterByPeriod)
      : [];
    return [
      {
        data: energyStorageState,
        showLine: false,
        label: DATASET_LABELS.magazyn_prądu_per_day,
        fill: true,
        backgroundColor: NEW_COLORS.energia_w_magazynie_pradu,
      },
    ];
  };
