import { LegendOption } from 'components/Legend/Legend';
import { externalTooltipHandler } from 'features/Charts/LineEnergyProduction/hooks/useLineOptions/externalTooltipHandler';
import keys from 'i18n/keys';
import { ChartProps } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { getObliczenia } from 'services/slices/obliczeniaSlice/selectors';
import { useAppSelector } from 'services/configuration/store/hooks';
import { roundNumb } from 'services/utils/roundNumb';
import { useTheme } from '@emotion/react';
ChartJS.register(ArcElement, Tooltip);

export const useChartData = () => {
  const { t } = useTranslation();
  const {
    calculations: {
      cieplo: {
        CO_energia_z_pc_po_elastycznosci_suma,
        CO_moc_th_pc_po_elastycznosci_suma,
        CO_reszta_ciepla_z_grzalek_po_elastycznosci_suma,
        CWU_dostepna_moc_el_pc_po_elastycznosci_suma,
        CWU_moc_th_pc_po_elastycznosci_suma,
        CWU_reszta_ciepla_z_grzalek_po_elastycznosci_suma,
      },
    },
  } = useAppSelector(getObliczenia);
  const CO_cieplo_PC = CO_moc_th_pc_po_elastycznosci_suma - CO_energia_z_pc_po_elastycznosci_suma;
  const CWU_cieplo_PC = CWU_moc_th_pc_po_elastycznosci_suma - CWU_dostepna_moc_el_pc_po_elastycznosci_suma;
  const dataToRender = [
    {
      color: '#fbe5d6',
      borderColor: '#fff',
      name: t(keys.CO_CIEPLO_PC),
      value: roundNumb(CO_cieplo_PC / 1000),
      type: 'CO',
    },
    {
      color: '#f2a068',
      borderColor: '#fff',
      name: t(keys.CO_PRAD_PC),
      value: roundNumb(CO_energia_z_pc_po_elastycznosci_suma / 1000),
      type: 'CO',
    },
    {
      color: '#f78181',
      borderColor: '#fff',
      name: t(keys.CO_GRZALKI_KOGENERACJA),
      value: roundNumb(CO_reszta_ciepla_z_grzalek_po_elastycznosci_suma / 1000),
      type: 'CO',
    },

    {
      color: '#fbe5d6',
      borderColor: '#ff0000',
      name: t(keys.CWU_CIEPLO_PC),
      value: roundNumb(CWU_cieplo_PC / 1000),
      type: 'CWU',
    },
    {
      color: '#f2a068',
      borderColor: '#ff0000',
      name: t(keys.CWU_PRAD_PC),
      value: roundNumb(CWU_dostepna_moc_el_pc_po_elastycznosci_suma / 1000),
      type: 'CWU',
    },
    {
      color: '#f78181',
      borderColor: '#ff0000',
      name: t(keys.CWU_GRZALKI_KOGENERACJA),
      value: roundNumb(CWU_reszta_ciepla_z_grzalek_po_elastycznosci_suma / 1000),
      type: 'CWU',
    },
  ] as const;
  const dataToRenderWithValues = dataToRender.filter(data => data.value > 0);
  const legendOptions = dataToRenderWithValues
    .filter(el => el.value > 0)
    .map(option => ({
      color: option.color,
      name: option.name,
      borderColor: option.borderColor,
    }));

  const data: ChartProps<'doughnut'>['data'] = {
    labels: legendOptions.map(option => option.name),
    datasets: [
      {
        data: dataToRenderWithValues.map(option => option.value),
        backgroundColor: dataToRenderWithValues.map(option => option.color),
        borderColor: dataToRenderWithValues.map((option, index) => option.borderColor),
        borderWidth: 3,
        datalabels: {
          anchor: 'center',
          backgroundColor: null,
          borderWidth: 0,
        },
      },
    ],
  };
  const { chartJsTooltip, primary } = useTheme();
  const options: ChartProps<'doughnut'>['options'] = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: primary,
        formatter: (value: number) => {
          if (value > 2) {
            return `${roundNumb(value)}`;
          }
          return '';
        },
      },
      tooltip: {
        ...chartJsTooltip,
        position: 'average',
        enabled: false,
        external: externalTooltipHandler,
        callbacks: {
          label: function (tooltipItem: any) {
            const { datasetIndex, dataIndex } = tooltipItem;
            const value = data.datasets[datasetIndex].data[dataIndex];
            return `${value}`;
          },
        },
      },
    },
  };
  return { data, legendOptions, options };
};
