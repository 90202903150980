import React, { useContext } from 'react';
import { DataInputContext } from './dataInputContext';
import { OtherCnt, SCnt, SLabel } from './styles';

type WithInputContextProps = {
  children: React.ReactNode;
  label?: React.ReactNode;
};

export const WithInputContext = ({ children, label }: WithInputContextProps) => {
  const inputWidth = useContext(DataInputContext);

  return (
    <SCnt>
      {label && <SLabel>{label}</SLabel>}
      <OtherCnt inputWidth={inputWidth}>{children}</OtherCnt>
    </SCnt>
  );
};
