import React from 'react';
import { ButtonProps } from './Button';
import { SEditButton, SEditDataIcon } from './styles';

export const EditButton = ({ children, ...props }: ButtonProps) => {
  return (
    <SEditButton {...props}>
      <SEditDataIcon />
      {children}
    </SEditButton>
  );
};
