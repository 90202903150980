import styled from '@emotion/styled/macro';
import { ReactComponent as EditDataIcon } from 'assets/new/edit_note.svg';

export type SButtonProps = {
  width?: string | number;
  variant?: 'outlined' | 'contained' | 'text';
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'inherit';
  dense?: boolean;
  fullWidth?: boolean;
};
export const SButton = styled('button', { shouldForwardProp: prop => prop !== 'fullWidth' })<SButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: ${({ width }) => width};
  cursor: pointer;
  text-decoration: none;
  border-radius: 12px;

  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

  ${({ size, theme }) => {
    if (size === 'small') {
      return {
        padding: '8px 12px',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 600,
      };
    }
    // if (size === 'large') {
    //   return {
    //     padding: '16px 24px',
    //     fontSize: '16px',
    //   };
    // }
    return {
      padding: '10px 12px',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 600,
    };
  }}

  ${({ variant, theme }) => {
    if (variant === 'contained') {
      return {
        background: '#EBEAE6',
        color: theme.primary,
        // boxShadow: `0px 4px 4px ${theme.addAlpha(theme.primary, 0.25)}`,
        '&:hover': {
          background: theme.addAlpha('#EBEAE6', 0.7),
        },
      };
    }
    if (variant === 'outlined') {
      return {
        color: theme.primary,
        border: `1px solid #DDE1E6`,
        '&:hover': {
          background: theme.addAlpha('#DDE1E6', 0.7),
        },
      };
    }
    if (variant === 'text') {
      return {
        color: '#636B75',
        border: 'none',
        background: 'transparent',
        '&:hover': {
          background: 'rgba(41, 127, 148, 0.1)',
        },
      };
    }
  }}
`;

export const SEditButton = styled(SButton)`
  padding: 0;
  &:hover {
    background-color: ${({ theme }) => theme.addAlpha(theme.primary, 0.03)};
  }
`;

export const SEditDataIcon = styled(EditDataIcon)``;

export const EndIconCnt = styled.div`
  margin-left: 4px;
  display: flex;
  align-items: center;
`;
export const StartIconCnt = styled.div`
  margin-right: 4px;
  display: flex;
  align-items: center;
`;
