import { DataInput } from 'components/DataInput/DataInput';
import { Box, Spacer } from 'components/common';
import { useDispatchAction } from 'hooks/useDispatchAction';
import keys from 'i18n/keys';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';

import {
  setCOCieploDefaultValues,
  setCieploInputs,
  setCieploNullableInputs,
  setCieploSelectOptions,
} from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { getObliczenia } from 'services/slices/obliczeniaSlice/selectors';
import { CieploSelectBox, Label, ModalTitle } from '../styles';
import { RODZAJE_TERMOMODERNIZACJI } from 'services/slices/obliczeniaSlice/obliczenia/cieplo/consts';
import { useSelectDispatchAction } from 'hooks/useSelectDispatchAction';
import {
  CieploInputsPayload,
  CieploSelectOptionsPayload,
} from 'services/slices/obliczeniaSlice/obliczenia/cieplo/types';
import { Select } from 'components/Select/Select';
import { Modal } from 'components/Modal/Modal';
import { EditButton } from 'components/Button/EditButton';
import { Button } from 'components/Button/Button';
import { roundNumb } from 'services/utils/roundNumb';
import { Tooltip } from 'components/Tooltip/Tooltip';

export const COCieplo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    cieplo: {
      selectOptions: { rodzaj_termomodernizacji },
      inputs: {
        odsetek_budynkow_z_odzyskiem_ciepla,
        stopien_zastapienia_starych_budynkow_nowymi,
        zapotrzebowanie_CO_input,
        zapotrzebowanie_CO_input_custom,
        calculated_zapotrzebowanie_CO_input,
      },
      calculations: { zuzycie_energii_CO },
    },
  } = useAppSelector(getObliczenia);

  const { currentScenario } = useAppSelector(state => state.period);

  const budynkiZOdzyskiemCieplaSet = useDispatchAction<CieploInputsPayload>({
    action: setCieploInputs,
    name: 'odsetek_budynkow_z_odzyskiem_ciepla',
    value: odsetek_budynkow_z_odzyskiem_ciepla,
    percentage: true,
  });

  const rodzajTermomodernizacjiSet = useSelectDispatchAction<CieploSelectOptionsPayload<'rodzaj_termomodernizacji'>>({
    action: setCieploSelectOptions,
    options: RODZAJE_TERMOMODERNIZACJI,
    name: 'rodzaj_termomodernizacji',
    selectedValue: rodzaj_termomodernizacji,
  });

  const stopienZastapieniaStarychBudynkowNowymiSet = useDispatchAction<CieploInputsPayload>({
    action: setCieploInputs,
    name: 'stopien_zastapienia_starych_budynkow_nowymi',
    value: stopien_zastapienia_starych_budynkow_nowymi,
    percentage: true,
  });

  const zapotrzebowanieNaCOInputSet = useDispatchAction<CieploInputsPayload>({
    action: setCieploInputs,
    name: 'zapotrzebowanie_CO_input',
    value: zapotrzebowanie_CO_input as number,
    percentage: true,
    max: 200,
  });
  const handleClose = () => {
    if (zapotrzebowanie_CO_input_custom === null) {
      dispatch(
        setCOCieploDefaultValues({
          odsetek_budynkow_z_odzyskiem_ciepla: currentScenario.data.cieplo.inputs.odsetek_budynkow_z_odzyskiem_ciepla,
          rodzaj_termomodernizacji: currentScenario.data.cieplo.selectOptions.rodzaj_termomodernizacji,
          stopien_zastapienia_starych_budynkow_nowymi:
            currentScenario.data.cieplo.inputs.stopien_zastapienia_starych_budynkow_nowymi,
          obliczaj_CO: currentScenario.data.cieplo.obliczaj_CO,
        }),
      );
    } else {
      dispatch(
        setCieploNullableInputs({
          name: 'zapotrzebowanie_CO_input',
          value: zapotrzebowanie_CO_input_custom,
        }),
      );
    }
    setModalOpen(false);
  };

  return (
    <>
      <DataInput
        label={t(keys.BASIC_REQ_FOR_CENTRAL_HEAT)}
        id={'zapotrzebowanieNaCOInputSet'}
        labelTooltipId="TOOLTIP_HEAT_CO_ADVANCED_TOOLTIP"
        labelTooltipContent={t(keys.TOOLTIP_HEAT_CO_ADVANCED_TOOLTIP)}
        unit={'%'}
        unitColor="#AD6800"
        editBtn={<EditButton onClick={() => setModalOpen(true)}> </EditButton>}
        {...zapotrzebowanieNaCOInputSet}
      />
      <Modal
        open={modalOpen}
        onClose={handleClose}
        footer={
          <Box d="flex" justify="space-around">
            <Button
              onClick={() => {
                dispatch(
                  setCieploNullableInputs({
                    name: 'zapotrzebowanie_CO_input_custom',
                    value: zapotrzebowanie_CO_input,
                  }),
                );
                setModalOpen(false);
              }}
            >
              <Tooltip id="zapotrzebowanie_CO_input_custom-save" content={t(keys.MODAL_CIEPLO_SAVE_BTN)} />
              <Box ml="4px" as="span">
                {t(keys.SAVE)} ({roundNumb((calculated_zapotrzebowanie_CO_input ?? 0) * 100)}%)
              </Box>
            </Button>
            <Button
              onClick={() => {
                dispatch(
                  setCOCieploDefaultValues({
                    odsetek_budynkow_z_odzyskiem_ciepla:
                      currentScenario.data.cieplo.inputs.odsetek_budynkow_z_odzyskiem_ciepla,
                    rodzaj_termomodernizacji: currentScenario.data.cieplo.selectOptions.rodzaj_termomodernizacji,
                    stopien_zastapienia_starych_budynkow_nowymi:
                      currentScenario.data.cieplo.inputs.stopien_zastapienia_starych_budynkow_nowymi,
                    obliczaj_CO: currentScenario.data.cieplo.obliczaj_CO,
                  }),
                );
                setModalOpen(false);
              }}
            >
              {t(keys.RESET_DATA)}
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              <Tooltip id="zapotrzebowanie_CO_input_custom-cancel" content={t(keys.MODAL_CIEPLO_CANCEL_BTN)} />
              <Box ml="4px" as="span">
                {t(keys.CANCEL)} ({roundNumb((zapotrzebowanie_CO_input_custom ?? 0) * 100)}
                %)
              </Box>
            </Button>
          </Box>
        }
      >
        <ModalTitle>
          <span>{t(keys.CIEPLO_DO_OGRZEWANIA)}</span>
          <span>
            {roundNumb(zuzycie_energii_CO)} {t(keys.TWH)}
          </span>
        </ModalTitle>
        <Spacer mb="6px">
          <DataInput
            label={t(keys.STOPIEN_ZASTAPIENIA_NOWYC_BUD)}
            labelTooltipId="TOOLTIP_STOPIEN_ZASTAPIENIA_NOWYC_BUD"
            labelTooltipContent={t(keys.TOOLTIP_STOPIEN_ZASTAPIENIA_NOWYC_BUD)}
            id={'stopienZastapieniaStarychBudynkowNowymiSet'}
            unit={'%'}
            unitColor="#AD6800"
            {...stopienZastapieniaStarychBudynkowNowymiSet}
          />
        </Spacer>
        <Box d="flex" align="center" justify="space-between" mb="6px">
          <Label>
            <Tooltip id="INSULATION_QUALITY_TOOLTIP" content={t(keys.INSULATION_QUALITY_TOOLTIP_CONTENT)} />
            <span>{t(keys.INSULATION_QUALITY)}</span>
          </Label>
          <CieploSelectBox>
            <Select components={undefined} menuPortalTarget={document.body} {...rodzajTermomodernizacjiSet} />
          </CieploSelectBox>
        </Box>
        <Spacer mb="6px">
          <DataInput
            label={t(keys.BUILDINGS_WITH_RECOVERY)}
            labelTooltipId="BUDYNKI_Z_ODZYSKIEM_CIEPLA_TOOLTIP"
            labelTooltipContent={t(keys.BUDYNKI_Z_ODZYSKIEM_CIEPLA_TOOLTIP)}
            id={'budynkiZOdzyskiemCieplaSet'}
            unit={'%'}
            unitColor="#AD6800"
            {...budynkiZOdzyskiemCieplaSet}
          />
        </Spacer>
      </Modal>
    </>
  );
};
