import keys from './../../i18n/keys';
import { Input } from 'components/Input/Input';
import { Table, TBody, Td, THead, Tr } from 'components/Table/Transparent';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';
import { roundNumb } from 'services/utils/roundNumb';
import { useTranslation } from 'react-i18next';
import { TileTitle } from 'components/Titles/TileTitle';
import { getKoszty } from 'services/slices/obliczeniaSlice/selectors';
import { setKosztyMldPerGW, setO_M_paliwo, setO_M_utrzymanie } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { O_M_paliwoType, O_M_utrzymanieType } from 'services/slices/obliczeniaSlice/koszty/types';

export const Hydrogen = () => {
  const {
    calculations: { wodór },
    koszt_mld_per_GW: { elektrolizery },
    O_M_utrzymanie,
    O_M_paliwo,
  } = useAppSelector(getKoszty);
  const dispatch = useAppDispatch();
  const handleChange = (val: string) => {
    dispatch(setKosztyMldPerGW({ name: 'elektrolizery', value: Math.max(0, +val) }));
  };
  const handlePaliwoChange = (name: keyof O_M_paliwoType) => (val: string) => {
    dispatch(setO_M_paliwo({ name, value: Math.max(0, +val) }));
  };

  const handleUtrzymanieChange = (name: keyof O_M_utrzymanieType) => (val: string) => {
    dispatch(setO_M_utrzymanie({ name, value: Math.max(0, +val) }));
  };
  const { t } = useTranslation();
  return (
    <Table>
      <THead>
        <Tr cols={6}>
          <Td>
            <TileTitle variant="small">{t(keys.HYDROGEN)}</TileTitle>
          </Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.POWER) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.MLD_Z_GW) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.O_M_MAINATANCE) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.O_M_FUEL) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.SUM_MLD_ZL) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.LCOE_UNIT) }}></Td>
        </Tr>
      </THead>
      <TBody>
        <Tr cols={6}>
          <Td>{t(keys.ELECTROLYZERS)}</Td>
          <Td>{wodór.nowa_moc_zainstalowana}</Td>
          <Td>
            <Input hiddenSpinners withInputMask onChange={handleChange} value={elektrolizery} />
          </Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleUtrzymanieChange('elektrolizery')}
              step="0.1"
              value={O_M_utrzymanie.elektrolizery}
            />
          </Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handlePaliwoChange('elektrolizery')}
              step="0.1"
              value={O_M_paliwo.elektrolizery}
            />
          </Td>
          <Td>{roundNumb(wodór.koszt, 2)}</Td>
          <Td>-</Td>
        </Tr>
      </TBody>
    </Table>
  );
};
