import i18n from 'i18next';
import keys from '../../../../../i18n/keys';
import React, { memo, useState } from 'react';
import { DataInput } from 'components/DataInput/DataInput';
import { useAppSelector } from 'services/configuration/store/hooks';
import { SCloseBtnCnt, STile } from '../styles';
import { TileTitle } from 'components/Titles/TileTitle';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/Modal/Modal';
import { HeatStorageTile } from 'features/AdvancedOptionsTiles/HeatStorageTile';
import { EnergyStorageTile } from 'features/AdvancedOptionsTiles/EnergyStorageTile';
import { Button } from 'components/Button/Button';
import { SIMULATOR_INTRO_STEPS } from 'pages/Simulator/TourSteps/consts';
import { getObliczenia, getObliczeniaInputs } from 'services/slices/obliczeniaSlice/selectors';
import { useDispatchAction } from 'hooks/useDispatchAction';
import { CieploInputsPayload } from 'services/slices/obliczeniaSlice/obliczenia/cieplo/types';
import { ObliczeniaTypeInputsPayload } from 'services/slices/obliczeniaSlice/obliczenia/types';
import { setObliczeniaInputs } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { Spacer } from 'components/common';
import { EditButton } from 'components/Button/EditButton';
import { Fieldset } from 'components/Fieldset/Fieldset';
import { MagazynowanieModal } from './MagazynowanieModal';

type AvailableModals = 'heat' | 'energy' | null;

export const StorageTile = memo(() => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    calculations: { pojemnosc_magazynu_pradu },
    inputs: { pojemnosc_magazynu_ciepla, pojemnosc_magazynu_ciepla_w_przemysle },
  } = useAppSelector(getObliczenia);
  const energyStorageCapacity = pojemnosc_magazynu_pradu.toFixed(0);
  const { t } = useTranslation();
  const magazynyCieplaSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'pojemnosc_magazynu_ciepla',
    value: pojemnosc_magazynu_ciepla,
    max: 2000,
  });
  const magazynyCieplaWPrzemysleSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'pojemnosc_magazynu_ciepla_w_przemysle',
    value: pojemnosc_magazynu_ciepla_w_przemysle,
    max: 2000,
  });

  return (
    <STile id={SIMULATOR_INTRO_STEPS.STORAGE}>
      <TileTitle border="#dde1e6" id="heat-storage-tile" tooltipContent={t(keys.STORAGES_GWHTH_TOOLTIP)}>
        <span>{t(keys.STORAGES_GWHTH)}</span>
      </TileTitle>
      <Spacer mb="6px">
        <DataInput
          label={t(keys.ENERGY_STORAGE_SIMUALTOR)}
          id={'energy-electrocity-storage'}
          labelTooltipId="TOOLTIP_ENERGY_STORAGE_SIMUALTOR"
          labelTooltipContent={t(keys.TOOLTIP_ENERGY_STORAGE_SIMUALTOR)}
          unit={t(keys.GWh)}
          value={energyStorageCapacity}
          onChange={() => {}}
          hideControls
          editBtn={<EditButton onClick={() => setModalOpen(true)}> </EditButton>}
        />
      </Spacer>
      <Spacer mb="6px">
        <DataInput
          label={t(keys.CIEPLO_W_PRZEMYSLE_MAGAZYNY)}
          id={'heat-storage'}
          labelTooltipId="TOOLTIP_HEAT_IN_INDUSTRY"
          labelTooltipContent={t(keys.TOOLTIP_HEAT_IN_INDUSTRY)}
          unit={t(keys.GWh)}
          {...magazynyCieplaWPrzemysleSet}
          hideControls
        />
      </Spacer>
      <Spacer>
        <DataInput
          label={t(keys.HEAT_STORAGE_SIMUALTOR)}
          id={'heat-storage'}
          labelTooltipId="TOOLTIP_HEAT_STORAGE_SIMUALTOR"
          labelTooltipContent={t(keys.TOOLTIP_HEAT_STORAGE_SIMUALTOR)}
          unit={t(keys.GWh)}
          {...magazynyCieplaSet}
          hideControls
        />
      </Spacer>
      <MagazynowanieModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </STile>
  );
});
