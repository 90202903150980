import keys from 'i18n/keys';
import React from 'react';
import { Border, Cell, CellText, Grid, HeadRow, Row, RowGroup, RowTitle, STooltip, TableTitle } from './sharedStyles';

import { useAppSelector } from 'services/configuration/store/hooks';

import { useDispatchAction } from 'hooks/useDispatchAction';

import { useTranslation } from 'react-i18next';
import {
  getKoszty,
  getObliczenia,
  getObliczeniaCalculations,
  getProdukcjaEnergiiElektrycznej,
} from 'services/slices/obliczeniaSlice/selectors';
import {
  setKosztyAktualnaInfrastruktura,
  setObliczeniaConsts,
  setObliczeniaInputs,
  setProdukcjiEnergiiInputs,
} from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { ProdukcjaEnergiiElektrycznejInputsPayload } from 'services/slices/obliczeniaSlice/produkcjaEnergiiElektrycznej/types';
import { KosztyAktualnaInfrastrukturaPayload } from 'services/slices/obliczeniaSlice/koszty/types';
import {
  ObliczeniaTypeConstsPayload,
  ObliczeniaTypeInputsPayload,
} from 'services/slices/obliczeniaSlice/obliczenia/types';

import { DataInput } from 'components/DataInput/DataInput';
export const EnergySourceTile = () => {
  const {
    inputs: {
      atom_zainstalowano,
      PV_zainstalowano,
      wiatr_lad_zainstalowano,
      wiatr_offshore_zainstalowano,
      udzial_nowoczesnych_turbin_wiatrowych,
      udzial_bifacji_EW_w_PV,
    },
  } = useAppSelector(getProdukcjaEnergiiElektrycznej);

  const {
    consts: { sprawnosc_elektrowni_gazowej },
    inputs: { odsetek_elektrowni_pracujacych_w_kogeneracji, strata_przy_przesyle },
  } = useAppSelector(getObliczenia);

  const { aktualna_infrastruktura } = useAppSelector(getKoszty);

  const { źródło_dyspozycyjne_max_moc } = useAppSelector(getObliczeniaCalculations);

  const { t } = useTranslation();

  const windOffShoreSet = useDispatchAction<ProdukcjaEnergiiElektrycznejInputsPayload>({
    value: wiatr_offshore_zainstalowano,
    action: setProdukcjiEnergiiInputs,
    name: 'wiatr_offshore_zainstalowano',
  });

  const windOffShoreInfrastructure = useDispatchAction<KosztyAktualnaInfrastrukturaPayload>({
    value: aktualna_infrastruktura.wiatr_na_morzu,
    action: setKosztyAktualnaInfrastruktura,
    name: 'wiatr_na_morzu',
  });

  const windSet = useDispatchAction<ProdukcjaEnergiiElektrycznejInputsPayload>({
    value: wiatr_lad_zainstalowano,
    action: setProdukcjiEnergiiInputs,
    name: 'wiatr_lad_zainstalowano',
  });

  const windInfrastructure = useDispatchAction<KosztyAktualnaInfrastrukturaPayload>({
    value: aktualna_infrastruktura.wiatr_na_ladzie,
    action: setKosztyAktualnaInfrastruktura,
    name: 'wiatr_na_ladzie',
  });

  const photovoltaicSet = useDispatchAction<ProdukcjaEnergiiElektrycznejInputsPayload>({
    value: PV_zainstalowano,
    action: setProdukcjiEnergiiInputs,
    name: 'PV_zainstalowano',
  });

  const photovoltaicInfrastructure = useDispatchAction<KosztyAktualnaInfrastrukturaPayload>({
    value: aktualna_infrastruktura.PV,
    action: setKosztyAktualnaInfrastruktura,
    name: 'PV',
  });

  const atomSet = useDispatchAction<ProdukcjaEnergiiElektrycznejInputsPayload>({
    value: atom_zainstalowano,
    action: setProdukcjiEnergiiInputs,
    name: 'atom_zainstalowano',
  });

  const orientacjaPVSet = useDispatchAction<ProdukcjaEnergiiElektrycznejInputsPayload>({
    action: setProdukcjiEnergiiInputs,
    name: 'udzial_bifacji_EW_w_PV',
    value: udzial_bifacji_EW_w_PV,
    percentage: true,
  });

  const rodzajTurbinyWiatrLadSet = useDispatchAction<ProdukcjaEnergiiElektrycznejInputsPayload>({
    action: setProdukcjiEnergiiInputs,
    name: 'udzial_nowoczesnych_turbin_wiatrowych',
    value: udzial_nowoczesnych_turbin_wiatrowych,
    percentage: true,
  });

  // const atomDisposalSet = useDispatchAction<NuclearPowerPlantsDisposalPayload>({
  //   value: nuclearPowerPlantsDisposal,
  //   action: setEnergyProductionConsts,
  //   name: 'nuclearPowerPlantsDisposal',
  //   percentage: true,
  // });

  const atomInfrastructure = useDispatchAction<KosztyAktualnaInfrastrukturaPayload>({
    value: aktualna_infrastruktura.atom,
    action: setKosztyAktualnaInfrastruktura,
    name: 'atom',
  });

  const coogeneration = useDispatchAction<ObliczeniaTypeInputsPayload>({
    value: odsetek_elektrowni_pracujacych_w_kogeneracji,
    action: setObliczeniaInputs,
    name: 'odsetek_elektrowni_pracujacych_w_kogeneracji',
    percentage: true,
  });

  const naturalGasInfrastructure = useDispatchAction<KosztyAktualnaInfrastrukturaPayload>({
    value: aktualna_infrastruktura.elektrownie_gazowe,
    action: setKosztyAktualnaInfrastruktura,
    name: 'elektrownie_gazowe',
  });

  const strataPrzyPesyleSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'strata_przy_przesyle',
    value: strata_przy_przesyle,
    percentage: true,
  });

  //TODO: przedystkutować, czy jest nadal potrzebne
  const gasEfficenySet = useDispatchAction<ObliczeniaTypeConstsPayload>({
    value: sprawnosc_elektrowni_gazowej,
    action: setObliczeniaConsts,
    name: 'sprawnosc_elektrowni_gazowej',
    percentage: true,
  });

  return (
    <Grid>
      <HeadRow>
        <TableTitle id="advanced-energy-demand" tooltipContent={t(keys.TOOLTIP_ENERGY_SOURCE_TILE_CONTENT)} reverseFlex>
          {t(keys.ENERGY_SOURCE)}
        </TableTitle>
        <RowTitle color="#358F86">{t(keys.INSTALLED_POWER)}</RowTitle>
        <RowTitle color="#AD6800">{t(keys.EXISTING_GW)}</RowTitle>
      </HeadRow>
      <RowGroup>
        <Row>
          <Cell>
            <STooltip id="wind-offshore-cell" content={t(keys.TOOLTIP_ENERGY_SOURCE_TILE_OFSHORE_WIND)} />
            <CellText>{t(keys.OFFSHORE_WIND_LABEL)}</CellText>
          </Cell>
          <Cell>
            <DataInput {...windOffShoreSet} id="windOffShoreSet" />
          </Cell>
          <Cell>
            <DataInput {...windOffShoreInfrastructure} id="windOffShoreInfrastructure" />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <STooltip id="wind-on-land-cell" content={t(keys.TOOLTIP_ENERGY_SOURCE_TILE_ONSHORE_WIND)} />
            <CellText>{t(keys.ONSHORE_WIND_LABEL)}</CellText>
          </Cell>
          <Cell>
            <DataInput {...windSet} id="windSet" />
          </Cell>
          <Cell>
            <DataInput {...windInfrastructure} id="windInfrastructure" />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <STooltip id="photovoltaic-cell" content={t(keys.TOOLTIP_ENERGY_SOURCE_TILE_PHOTOVOLTAICS)} />
            <CellText>{t(keys.PHOTOVOLTAICS)}</CellText>
          </Cell>
          <Cell>
            <DataInput {...photovoltaicSet} id="photovoltaicSet" />
          </Cell>
          <Cell>
            <DataInput {...photovoltaicInfrastructure} id="photovoltaicInfrastructure" />
          </Cell>
          <Cell>{/* <DataInput value="55" type="text"  /> */}</Cell>
        </Row>

        <Row>
          <Cell>
            <STooltip id="atom-cell" content={t(keys.TOOLTIP_ENERGY_SOURCE_TILE_NUCLEAR_POWER_PLANTS)} />
            <CellText>{t(keys.NUCLEAR_POWER_PLANTS)}</CellText>
          </Cell>
          <Cell>
            <DataInput {...atomSet} id="atomSet" />
          </Cell>
          <Cell>
            <DataInput {...atomInfrastructure} id="atomInfrastructure" />
          </Cell>
          <Cell>{/* <DataInput {...atomDisposalSet} id="atomDisposalSet" /> */}</Cell>
        </Row>
        <Row>
          <Cell>
            <STooltip id="disposal-sources-cell" content={t(keys.TOOLTIP_ENERGY_SOURCE_TILE_DYSP_GAS_PLANTS)} />
            <CellText>{t(keys.DYSP_GAS_PLANTS)}</CellText>
          </Cell>
          <Cell>
            <DataInput disabled value={źródło_dyspozycyjne_max_moc.toFixed()} id="źródło_dyspozycyjne_max_moc" />
          </Cell>
          <Cell>
            <DataInput {...naturalGasInfrastructure} id="naturalGasDisabled" />
          </Cell>
          <Cell>{/* <DataInput value="55"  /> */}</Cell>
        </Row>
        <Row columns={1}>
          <Border />
          <Border />
        </Row>
        <Row columns={1}>
          <Cell>
            <STooltip id="coogeneration-cell" content={t(keys.TOOLTIP_ENERGY_SOURCE_TILE_WASTE_HEAT_LABEL)} />
            <span>{t(keys.WASTE_HEAT_LABEL)}</span>
          </Cell>
          <Cell>
            <DataInput {...coogeneration} id="coogeneration" />
          </Cell>
        </Row>
        <Row columns={1}>
          <Cell>
            <STooltip id="orientacja-pv-cell" content={t(keys.TOOLTIP_ENERGY_SOURCE_TILE_PV_BIFACJA_ADVANCED)} />
            <CellText>{t(keys.PV_BIFACJA_ADVANCED)}</CellText>
          </Cell>
          <Cell>
            <DataInput {...orientacjaPVSet} id="orientacjaPVSet" />
          </Cell>
        </Row>
        <Row columns={1}>
          <Cell>
            <STooltip
              id="onshore-wind-turbines-cell"
              content={t(keys.TOOLTIP_ONSHORE_WIND_NEW_TURBINES_LABEL_ADVANCED)}
            />
            <CellText>{t(keys.ONSHORE_WIND_NEW_TURBINES_LABEL_ADVANCED)}</CellText>
          </Cell>
          <Cell>
            <DataInput {...rodzajTurbinyWiatrLadSet} id="rodzajTurbinyWiatrLadSet" />
          </Cell>
        </Row>
        <Row columns={1}>
          <Cell>
            <STooltip id="loss-in-transit-cell" content={t(keys.TOOLTIP_LOSS_IN_TRANSIT_ADVANCED)} />
            <CellText>{t(keys.LOSS_IN_TRANSIT)}</CellText>
          </Cell>
          <Cell>
            <DataInput {...strataPrzyPesyleSet} id="strata-przy-przesyle" />
          </Cell>
        </Row>
        <Row columns={1}>
          <Cell>
            <STooltip id="natural-gas-efficency" content={t(keys.NATURAL_GAS_EFFICENCY_TOOLTIP)} />
            <CellText>
              <span>{t(keys.NATURAL_GAS_EFFICENCY)}</span>
            </CellText>
          </Cell>
          <Cell>
            <DataInput {...gasEfficenySet} id="gasEfficenySet" />
          </Cell>
        </Row>
      </RowGroup>
    </Grid>
  );
};
