import React from 'react';
import { Global, css } from '@emotion/react';
import { appTheme } from './theme';

const globalStyles = css`
  body {
    background-color: ${appTheme.bodyBg};
    color: ${appTheme.primary};
  }
`;

export const GlobalStyles = () => (
  <Global
    styles={css`
      ${globalStyles}
    `}
  />
);
