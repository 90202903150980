import i18n from 'i18next';
import keys from 'i18n/keys';
import React from 'react';
import { COLORS } from 'services/consts/colors';
import {
  Border,
  Cell,
  CellText,
  Grid,
  HeadRow,
  Row,
  RowGroup,
  RowTitle,
  STooltip,
  TableTitle,
  TileView,
} from './sharedStyles';

import { Input } from 'components/Input/Input';
import { useAppSelector } from 'services/configuration/store/hooks';
import { useDispatchAction } from 'hooks/useDispatchAction';

import { useTranslation } from 'react-i18next';
import { roundNumb } from 'services/utils/roundNumb';
import { getObliczeniaInputs, getWodor } from 'services/slices/obliczeniaSlice/selectors';
import {
  BazoweZapotrzebowanieNaWodorPayload,
  ProcentoweZapotrzebowanieNaWodorPayload,
  WodorConstsPayload,
} from 'services/slices/obliczeniaSlice/wodor/types';
import {
  setObliczeniaInputs,
  setProcentoweZapotrzebowanieNaWodor,
  setWodorConsts,
  setWodorZapotrzebowanie,
} from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { DataInput } from 'components/DataInput/DataInput';
import classNames from 'classnames';
import { ObliczeniaTypeInputsPayload } from 'services/slices/obliczeniaSlice/obliczenia/types';

type HydrogenNeedsProps = {
  tileView?: TileView;
};

export const HydrogenNeeds = ({ tileView = 'default' }: HydrogenNeedsProps) => {
  const { bazowe_zapotrzebowanie, procentowe_zapotrzebowanie, suma_zapotrzebowania } = useAppSelector(getWodor);

  const aircraftNeed = useDispatchAction<BazoweZapotrzebowanieNaWodorPayload>({
    action: setWodorZapotrzebowanie,
    name: 'lotnictwo',
    value: bazowe_zapotrzebowanie.lotnictwo,
  });
  const aircraftPercentage = useDispatchAction<ProcentoweZapotrzebowanieNaWodorPayload>({
    action: setProcentoweZapotrzebowanieNaWodor,
    name: 'lotnictwo',
    value: procentowe_zapotrzebowanie.lotnictwo,
    percentage: true,
    max: 2,
  });

  const nitrogenRefineriesNeed = useDispatchAction<BazoweZapotrzebowanieNaWodorPayload>({
    action: setWodorZapotrzebowanie,
    name: 'rafinerie',
    value: bazowe_zapotrzebowanie.rafinerie,
  });
  const nitrogenRefineriesPercentage = useDispatchAction<ProcentoweZapotrzebowanieNaWodorPayload>({
    action: setProcentoweZapotrzebowanieNaWodor,
    name: 'rafinerie',
    value: procentowe_zapotrzebowanie.rafinerie,
    percentage: true,
    max: 2,
  });

  const shippingNeed = useDispatchAction<BazoweZapotrzebowanieNaWodorPayload>({
    action: setWodorZapotrzebowanie,
    name: 'żegluga',
    value: bazowe_zapotrzebowanie.żegluga,
  });
  const shippingPercentage = useDispatchAction<ProcentoweZapotrzebowanieNaWodorPayload>({
    action: setProcentoweZapotrzebowanieNaWodor,
    name: 'żegluga',
    value: procentowe_zapotrzebowanie.żegluga,
    percentage: true,
    max: 2,
  });

  const metalurgyNeed = useDispatchAction<BazoweZapotrzebowanieNaWodorPayload>({
    action: setWodorZapotrzebowanie,
    name: 'hutnictwo',
    value: bazowe_zapotrzebowanie.hutnictwo,
  });
  const metalurgyPercentage = useDispatchAction<ProcentoweZapotrzebowanieNaWodorPayload>({
    action: setProcentoweZapotrzebowanieNaWodor,
    name: 'hutnictwo',
    value: procentowe_zapotrzebowanie.hutnictwo,
    percentage: true,
    max: 2,
  });

  const armyNeed = useDispatchAction<BazoweZapotrzebowanieNaWodorPayload>({
    action: setWodorZapotrzebowanie,
    name: 'wojsko',
    value: bazowe_zapotrzebowanie.wojsko,
  });
  const armyPercentage = useDispatchAction<ProcentoweZapotrzebowanieNaWodorPayload>({
    action: setProcentoweZapotrzebowanieNaWodor,
    name: 'wojsko',
    value: procentowe_zapotrzebowanie.wojsko,
    percentage: true,
    max: 2,
  });

  const cementNeed = useDispatchAction<BazoweZapotrzebowanieNaWodorPayload>({
    action: setWodorZapotrzebowanie,
    name: 'cement',
    value: bazowe_zapotrzebowanie.cement,
  });
  const cementPercentage = useDispatchAction<ProcentoweZapotrzebowanieNaWodorPayload>({
    action: setProcentoweZapotrzebowanieNaWodor,
    name: 'cement',
    value: procentowe_zapotrzebowanie.cement,
    percentage: true,
    max: 2,
  });

  const otherNeed = useDispatchAction<BazoweZapotrzebowanieNaWodorPayload>({
    action: setWodorZapotrzebowanie,
    name: 'inne',
    value: bazowe_zapotrzebowanie.inne,
  });
  const otherPercentage = useDispatchAction<ProcentoweZapotrzebowanieNaWodorPayload>({
    action: setProcentoweZapotrzebowanieNaWodor,
    name: 'inne',
    value: procentowe_zapotrzebowanie.inne,
    percentage: true,
    max: 2,
  });
  const { moc_elektrolizerow } = useAppSelector(getObliczeniaInputs);
  const {
    consts: { electrolysersEfficency, hydrogenStorageEfficency },
  } = useAppSelector(getWodor);
  const electrolysersInstalledSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'moc_elektrolizerow',
    value: moc_elektrolizerow,
  });

  const electrolysersEfficencySet = useDispatchAction<WodorConstsPayload>({
    action: setWodorConsts,
    name: 'electrolysersEfficency',
    value: electrolysersEfficency,
    percentage: true,
  });
  const { t } = useTranslation();
  return (
    <Grid
      className={classNames({
        'modal-view': tileView === 'modal',
      })}
    >
      {tileView === 'default' && (
        <div>
          <HeadRow>
            <TableTitle
              reverseFlex
              id="electrolysers-production"
              tooltipContent={t(keys.TOOLTIP_HEAT_TILE_ELECTROLYSERS_CONTENT)}
            >
              {t(keys.HYDROGEN_PRODUCTION_ELECTROLYSIS)}
            </TableTitle>
            <RowTitle color="#358F86">{t(keys.POWER)}</RowTitle>
            <RowTitle color="#AD6800">{t(keys.ELECTROLYSIS_EFFICIENCY)}</RowTitle>
            <RowTitle color="#358F86">{t(keys.STORAGE_EFFICIENCY)}</RowTitle>
          </HeadRow>
          <Row>
            <Cell>
              <STooltip id="electrolysers-cell" content={t(keys.TOOLTIP_HEAT_TILE_ELECTROLYSERS_MAIN)} />
              <CellText>{t(keys.ELECTROLYZERS)}</CellText>
            </Cell>
            <Cell>
              <DataInput
                {...electrolysersInstalledSet}
                disabled={!suma_zapotrzebowania}
                id="electrolysersInstalledSet"
              />
            </Cell>
            <Cell>
              <DataInput {...electrolysersEfficencySet} id="electrolysersEfficencySet" />
            </Cell>
            <Cell>
              <DataInput
                value={(hydrogenStorageEfficency * 100).toFixed()}
                id="hydrogenStorageEfficency"
                hideControls
              />
            </Cell>
          </Row>
          <Row>
            <Border mb="12px" mt="4px" />
            <Border mt="4px" />
            <Border mt="4px" />
            <Border mt="4px" />
          </Row>
        </div>
      )}
      <HeadRow>
        <TableTitle reverseFlex id="hydrogen-needs" tooltipContent={t(keys.TOOLTIP_HYDROGEN_NEEDS_CONTENT)}>
          {t(keys.DEMAND_HYDROGEN)}
        </TableTitle>
        <RowTitle color="#AD6800">{t(keys.DEMAND_BASIC_HYDROGEN)} </RowTitle>
        <RowTitle
          color="#358F86"
          dangerouslySetInnerHTML={{ __html: t(keys.CURRENT_NEED_FOR_POWER_HYDROGEN) }}
        ></RowTitle>
        <RowTitle color="#AD6800" dangerouslySetInnerHTML={{ __html: t(keys.ELECTRICITY_LABEL) }}></RowTitle>
      </HeadRow>
      <RowGroup>
        <Row>
          <Cell>
            <STooltip id="nitrogen-refinery-cell" content={t(keys.TOOLTIP_HYDROGEN_NEEDS_REFINES)} />
            <span>{t(keys.NITROGEN_AND_OTHER_PLANTS_LABEL)}</span>
          </Cell>
          <Cell>
            <DataInput {...nitrogenRefineriesNeed} id="nitrogenRefineriesNeed" />
          </Cell>
          <Cell>
            <DataInput {...nitrogenRefineriesPercentage} id="nitrogenRefineriesPercentage" />
          </Cell>
          <Cell>
            <DataInput
              value={roundNumb(bazowe_zapotrzebowanie.rafinerie * procentowe_zapotrzebowanie.rafinerie)}
              hideControls
              id="bazowe_zapotrzebowanienitrogenRefineries"
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <STooltip id="sea-żegluga-cell" content={t(keys.TOOLTIP_HYDROGEN_NEEDS_SHIPPING)} />
            <span>{t(keys.SHIPPING_LABEL)}</span>
          </Cell>
          <Cell>
            <DataInput {...shippingNeed} id="shippingNeed" />
          </Cell>
          <Cell>
            <DataInput {...shippingPercentage} id="shippingPercentage" />
          </Cell>
          <Cell>
            <DataInput
              value={roundNumb(bazowe_zapotrzebowanie.żegluga * procentowe_zapotrzebowanie.żegluga)}
              hideControls
              id="bazowe_zapotrzebowanie-żegluga"
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <STooltip id="aviation-cell" content={t(keys.TOOLTIP_HYDROGEN_NEEDS_AURONAUTICS)} />
            <span>{t(keys.AERONAUTICS_LABEL)}</span>
          </Cell>
          <Cell>
            <DataInput {...aircraftNeed} id="aircraftNeed" />
          </Cell>
          <Cell>
            <DataInput {...aircraftPercentage} id="aircraftPercentage" />
          </Cell>
          <Cell>
            <DataInput
              value={roundNumb(bazowe_zapotrzebowanie.lotnictwo * procentowe_zapotrzebowanie.lotnictwo)}
              hideControls
              id="sum-lotnictwo"
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <STooltip id="steel-production-cell" content={t(keys.TOOLTIP_HYDROGEN_NEEDS_STEEL_MELTING)} />
            <span>{t(keys.STEEL_MELTING_AND_PRODUCTION_LABEL)}</span>
          </Cell>
          <Cell>
            <DataInput {...metalurgyNeed} id="metalurgyNeed" />
          </Cell>
          <Cell>
            <DataInput {...metalurgyPercentage} id="metalurgyPercentage" />
          </Cell>
          <Cell>
            <DataInput
              value={roundNumb(bazowe_zapotrzebowanie.hutnictwo * procentowe_zapotrzebowanie.hutnictwo)}
              hideControls
              id="sum-hutnictwo"
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <STooltip id="military-cell" content={t(keys.TOOLTIP_HYDROGEN_NEEDS_GAS_FOR_ARMY_LABEL)} />
            <span>{t(keys.GAS_FOR_ARMY_LABEL)}</span>
          </Cell>
          <Cell>
            <DataInput {...armyNeed} id="armyNeed" />
          </Cell>
          <Cell>
            <DataInput {...armyPercentage} id="armyPercentage" />
          </Cell>
          <Cell>
            <DataInput
              value={roundNumb(bazowe_zapotrzebowanie.wojsko * procentowe_zapotrzebowanie.wojsko)}
              id="sum-wojsko"
              hideControls
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <STooltip id="cement-cell" content={t(keys.TOOLTIP_HYDROGEN_NEEDS_CEMENT)} />
            <span>{t(keys.CEMENT)}</span>
          </Cell>
          <Cell>
            <DataInput {...cementNeed} id="cementNeed" />
          </Cell>
          <Cell>
            <DataInput {...cementPercentage} id="cementPercentage" />
          </Cell>
          <Cell>
            <DataInput
              value={roundNumb(bazowe_zapotrzebowanie.cement * procentowe_zapotrzebowanie.cement)}
              id="cementNeedSum"
              hideControls
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <STooltip id="different-cell" content={t(keys.TOOLTIP_HYDROGEN_NEEDS_OTHER)} />
            <span>{t(keys.OTHER_LABEL)}</span>
          </Cell>
          <Cell>
            <DataInput {...otherNeed} id="otherNeed" />
          </Cell>
          <Cell>
            <DataInput {...otherPercentage} id="otherPercentage" />
          </Cell>
          <Cell>
            <DataInput
              value={roundNumb(bazowe_zapotrzebowanie.inne * procentowe_zapotrzebowanie.inne)}
              id="otherNeedSum"
              hideControls
            />
          </Cell>
        </Row>
        <Row>
          <div></div>
          <Border />
          <Border />
          <Border />
        </Row>
        <Row columns={1}>
          <Cell className="bigger-font ">
            <STooltip id="sum-hydrogen-cell" content={t(keys.TOOLTIP_HYDROGEN_NEEDS_SUM)} />
            <span>{t(keys.SUM)}</span>
          </Cell>
          <Cell>
            <DataInput value={roundNumb(suma_zapotrzebowania)} id="suma_zapotrzebowania" hideControls />
          </Cell>
        </Row>
      </RowGroup>
    </Grid>
  );
};
