import styled from '@emotion/styled';

export const SSourceWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  @media (min-width: 1400px) {
    grid-template-columns: 1fr;
  }
`;

export const SSection = styled.section`
  position: relative;
  flex-grow: 1;
  z-index: 15;
`;

export const STileSpacer = styled.div`
  margin-bottom: 30px;
`;
