import keys from 'i18n/keys';
import { AvailableYear } from './availableYears';
import moment from 'moment';
const months = [
  {
    name: keys.MONTH_1,
    days: 31,
    duration: { start: 0, end: 743 },
    quater: 'I',
  },

  {
    name: keys.MONTH_2,
    days: 28,
    duration: { start: 744, end: 1415 },
    quater: 'I',
  },

  {
    name: keys.MONTH_3,
    days: 31,
    duration: { start: 1416, end: 2159 },
    quater: 'I',
  },

  {
    name: keys.MONTH_4,
    days: 30,
    duration: { start: 2160, end: 2879 },
    quater: 'II',
  },

  {
    name: keys.MONTH_5,
    days: 31,
    duration: { start: 2880, end: 3623 },
    quater: 'II',
  },

  {
    name: keys.MONTH_6,
    days: 30,
    duration: { start: 3624, end: 4343 },
    quater: 'II',
  },

  {
    name: keys.MONTH_7,
    days: 31,
    duration: { start: 4344, end: 5087 },
    quater: 'III',
  },

  {
    name: keys.MONTH_8,
    days: 31,
    duration: { start: 5088, end: 5831 },
    quater: 'III',
  },

  {
    name: keys.MONTH_9,
    days: 30,
    duration: { start: 5832, end: 6551 },
    quater: 'III',
  },

  {
    name: keys.MONTH_10,
    days: 31,
    duration: { start: 6552, end: 7295 },
    quater: 'IV',
  },

  {
    name: keys.MONTH_11,
    days: 30,
    duration: { start: 7296, end: 8015 },
    quater: 'IV',
  },

  {
    name: keys.MONTH_12,
    days: 31,
    duration: { start: 8016, end: 8759 },
    quater: 'IV',
  },
];
export const isLeapYear = (year: AvailableYear) => {
  return moment(`01-01-${year} 00:00:00`).isLeapYear();
};
export const getYearHours = (selectedYear: AvailableYear) => {
  if (isLeapYear(selectedYear)) {
    return 8784;
  }
  return 8760;
};
export const getYearDays = (selectedYear: AvailableYear) => {
  if (isLeapYear(selectedYear)) {
    return 365;
  }
  return 366;
};
export const getMonths = (selectedYear: AvailableYear) => {
  if (isLeapYear(selectedYear)) {
    return months.map((el, index) => {
      if (index === 0) {
        return el;
      }
      if (index === 1) {
        return {
          ...el,
          days: 29,
          duration: {
            start: el.duration.start,
            end: el.duration.end + 24,
          },
        };
      }
      return {
        ...el,
        duration: {
          start: el.duration.start + 24,
          end: el.duration.end + 24,
        },
      };
    });
  }
  return months;
};
