import { Button } from 'components/Button/Button';
import keys from 'i18n/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';

type LegendButtonProps = {
  onClick: () => void;
  expanded: boolean;
};
export const LegendButton = ({ expanded, onClick }: LegendButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button variant="outlined" size="small" onClick={onClick}>
      {expanded ? t(keys.HIDE_LEGEND) : t(keys.EXPAND_LEGEND)}
    </Button>
  );
};
