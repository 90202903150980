export const bilansEnergii = {
  produkcja: {
    wiatr_na_ladzie: 0,
    wiatr_na_morzu: 0,
    PV: 0,
    atom: 0,
    produkcja_suma: 0,
    ciepło_otoczenia: 0,
    kogeneracja: 0,
    gaz: 0,
    straty: 0,
  },
  zużycie: {
    ciepło_przmyslowe: 0,
    prąd_inne: 0,
    transport: 0,
    ciepło: 0,
    wodór: 0,
    nadwyżka: 0,
    magazyn: 0,
    ciepło_otoczenia: 0,
    zużycie_suma: 0,
  },
};
