import { Input } from 'components/Input/Input';
import { Table, TBody, Td, THead, Tr } from 'components/Table/Transparent';
import { TileTitle } from 'components/Titles/TileTitle';
import keys from 'i18n/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'services/configuration/store/hooks';
import { KosztMldPerGW } from 'services/slices/obliczeniaSlice/koszty/types';
import { setKosztyMldPerGW } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { getKoszty } from 'services/slices/obliczeniaSlice/selectors';
import { roundNumb } from 'services/utils/roundNumb';

export const CieploCosts = () => {
  const {
    calculations: { pompyCieplaGruntowe, pompyCieplaPowietrzne },
    koszt_mld_per_GW: { pompy_ciepla_gruntowe, pompy_ciepla_powietrzne },
  } = useAppSelector(getKoszty);
  const dispatch = useAppDispatch();
  const handleChange = (name: keyof KosztMldPerGW) => (val: string) => {
    dispatch(setKosztyMldPerGW({ name, value: Math.max(0, +val) }));
  };
  // const handlePaliwoChange = (name: keyof O_M_paliwoType) => (val: string) => {
  //   dispatch(setO_M_paliwo({ name, value: Math.max(0, +val) }));
  // };

  // const handleUtrzymanieChange = (name: keyof O_M_utrzymanieType) => (val: string) => {
  //   dispatch(setO_M_utrzymanie({ name, value: Math.max(0, +val) }));
  // };
  const { t } = useTranslation();
  return (
    <Table>
      <THead>
        <Tr cols={6}>
          <Td>
            <TileTitle variant="small">{t(keys.CIEPLO)}</TileTitle>
          </Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.CAPACITY_GWTH) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.MLD_Z_GWTH) }}></Td>
          <Td className="empty"></Td>
          <Td className="empty"></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.SUM_MLD_ZL) }}></Td>
          <Td dangerouslySetInnerHTML={{ __html: t(keys.LCOE_UNIT) }}></Td>
        </Tr>
      </THead>
      <TBody>
        <Tr cols={6}>
          <Td>{t(keys.HEAT_PUMPS_GROUND_COSTS)}</Td>
          <Td>{roundNumb(pompyCieplaGruntowe.nowa_moc_zainstalowana)}</Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleChange('pompy_ciepla_gruntowe')}
              value={pompy_ciepla_gruntowe}
            />
          </Td>
          <Td></Td>
          <Td></Td>
          <Td>{roundNumb(pompyCieplaGruntowe.koszt)}</Td>
          <Td>-</Td>
        </Tr>
        <Tr cols={6}>
          <Td>{t(keys.HEAT_PUMPS_AIR_COSTS)}</Td>
          <Td>{roundNumb(pompyCieplaPowietrzne.nowa_moc_zainstalowana)}</Td>
          <Td>
            <Input
              hiddenSpinners
              withInputMask
              onChange={handleChange('pompy_ciepla_powietrzne')}
              value={pompy_ciepla_powietrzne}
            />
          </Td>
          <Td></Td>
          <Td></Td>
          <Td>{roundNumb(pompyCieplaPowietrzne.koszt)}</Td>
          <Td>-</Td>
        </Tr>
      </TBody>
    </Table>
  );
};
