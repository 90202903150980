import { DataInput } from 'components/DataInput/DataInput';
import { Fieldset } from 'components/Fieldset/Fieldset';
import { Modal } from 'components/Modal/Modal';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { Box, Spacer } from 'components/common';
import { useDispatchAction } from 'hooks/useDispatchAction';
import keys from 'i18n/keys';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'services/configuration/store/hooks';
import { ObliczeniaTypeInputsPayload } from 'services/slices/obliczeniaSlice/obliczenia/types';
import { setObliczeniaInputs } from 'services/slices/obliczeniaSlice/obliczeniaSlice';
import { getObliczenia } from 'services/slices/obliczeniaSlice/selectors';
import { roundNumb } from 'services/utils/roundNumb';
type MagazynowanieModalProps = {
  onClose: () => void;
  open: boolean;
};
export const MagazynowanieModal = ({ onClose, open }: MagazynowanieModalProps) => {
  const { t } = useTranslation();
  const {
    inputs: {
      baterie_litowe_stacjonarne,
      elektrownie_szczytowo_pompowe,
      baterie_samochodowe,
      pojemnosc_magazynu_ciepla_w_przemysle,
      odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle,
      pojemnosc_magazynu_ciepla,
      odbiorcy_z_magazynami_ciepla_z_siecia_CO,
    },
    calculations: { pojemnosc_magazynu_pradu },
  } = useAppSelector(getObliczenia);

  const baterieSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'baterie_litowe_stacjonarne',
    value: baterie_litowe_stacjonarne,
    max: 9999,
  });
  const elektrownieSzczytoweSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'elektrownie_szczytowo_pompowe',
    value: elektrownie_szczytowo_pompowe,
    max: 9999,
  });
  const carBatteriesSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'baterie_samochodowe',
    value: baterie_samochodowe,
    max: 9999,
  });

  const pojemnoscMagazynuWPrzemysleSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'pojemnosc_magazynu_ciepla_w_przemysle',
    value: pojemnosc_magazynu_ciepla_w_przemysle,
    max: 9999,
  });
  const odbiorcyZMagazynamiWPrzemysleSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle',
    value: odsetek_odbiorcow_ciepla_podlaczeni_do_sieci_w_przemysle,
    percentage: true,
  });

  const pojemnoscMagazynuCieplaSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'pojemnosc_magazynu_ciepla',
    value: pojemnosc_magazynu_ciepla,
    max: 9999,
  });
  const odbiorcyZMagazynamiZSieciaSet = useDispatchAction<ObliczeniaTypeInputsPayload>({
    action: setObliczeniaInputs,
    name: 'odbiorcy_z_magazynami_ciepla_z_siecia_CO',
    value: odbiorcy_z_magazynami_ciepla_z_siecia_CO,
    percentage: true,
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Box minWidth="400px">
        <Fieldset legend={t(keys.FIELDSET_ELECTRICITY_TITLE)}>
          <Spacer mb="6px">
            <DataInput
              unit={t(keys.GWh)}
              label={t(keys.MODAL_BATTERRIES)}
              labelTooltipId="MODAL_BATTERRIES_TOOLTIP"
              labelTooltipContent={t(keys.MODAL_BATTERRIES_TOOLTIP)}
              id={'baterieSet'}
              {...baterieSet}
            />
          </Spacer>
          <Spacer mb="6px">
            <DataInput
              unit={t(keys.GWh)}
              label={t(keys.MODAL_CAR_BATTERRIES)}
              labelTooltipId="MODAL_CAR_BATTERRIES_TOOLTIP"
              labelTooltipContent={t(keys.MODAL_CAR_BATTERRIES_TOOLTIP)}
              id={'carBatteriesSet'}
              {...carBatteriesSet}
            />
          </Spacer>
          <Spacer mb="6px">
            <DataInput
              unit={t(keys.GWh)}
              label={t(keys.MODAL_SZCZYT_POMP)}
              labelTooltipId="MODAL_SZCZYT_POMP_TOOLTIP"
              labelTooltipContent={t(keys.MODAL_SZCZYT_POMP_TOOLTIP)}
              id={'elektrownieSzczytoweSet'}
              {...elektrownieSzczytoweSet}
            />
          </Spacer>
          <Spacer>
            <DataInput
              unit={t(keys.GWh)}
              label={t(keys.SUM)}
              id={'SUM'}
              hideControls
              value={roundNumb(pojemnosc_magazynu_pradu)}
            />
          </Spacer>
        </Fieldset>

        <Fieldset legend={t(keys.FIELDSET_MAGAZYN_PRZEMYSL_TITLE)}>
          <Spacer mb="6px">
            <DataInput
              unit={t(keys.GWTH)}
              label={t(keys.CAPACITY_ONLY_MAGAZYN_PRZEYMSL)}
              labelTooltipId="CAPACITY_ONLY_MAGAZYN_PRZEYMSL_TOOLTIP"
              labelTooltipContent={t(keys.CAPACITY_ONLY_MAGAZYN_PRZEYMSL_TOOLTIP)}
              id={'pojemnoscMagazynuWPrzemysleSet'}
              {...pojemnoscMagazynuWPrzemysleSet}
            />
          </Spacer>
          <Spacer>
            <DataInput
              unit="%"
              label={t(keys.MAGAZYN_PRZEMYSL_ODBIORCY_Z_MAGAZYNAMI)}
              labelTooltipId="MAGAZYN_PRZEMYSL_ODBIORCY_Z_MAGAZYNAMI_TOOLTIP"
              labelTooltipContent={t(keys.MAGAZYN_PRZEMYSL_ODBIORCY_Z_MAGAZYNAMI_TOOLTIP)}
              id={'odbiorcyZMagazynamiWPrzemysleSet'}
              {...odbiorcyZMagazynamiWPrzemysleSet}
            />
          </Spacer>
        </Fieldset>

        <Fieldset legend={t(keys.FIELDSET_MAGAZYN_CIEPLA_TITLE)}>
          <Spacer mb="6px">
            <DataInput
              unit={t(keys.GWTH)}
              label={t(keys.CAPACITY_ONLY_MAGAZYN_CIEPLA)}
              labelTooltipId="CAPACITY_ONLY_MAGAZYN_CIEPLA_TOOLTIP"
              labelTooltipContent={t(keys.CAPACITY_ONLY_MAGAZYN_CIEPLA_TOOLTIP)}
              id={'pojemnoscMagazynuCieplaSet'}
              {...pojemnoscMagazynuCieplaSet}
            />
          </Spacer>
          <Spacer>
            <DataInput
              unit="%"
              label={t(keys.MAGAZYN_PRZEMYSL_ODBIORCY_Z_MAGAZYNAMI)}
              labelTooltipId="MAGAZYN_PRZEMYSL_ODBIORCY_Z_MAGAZYNAMI_TOOLTIP"
              labelTooltipContent={t(keys.MAGAZYN_PRZEMYSL_ODBIORCY_Z_MAGAZYNAMI_TOOLTIP)}
              id={'odbiorcyZMagazynamiZSieciaSet'}
              {...odbiorcyZMagazynamiZSieciaSet}
            />
          </Spacer>
        </Fieldset>
      </Box>
    </Modal>
  );
};
