import { data } from 'data/2023';
import { DEFAULT_ORIENTACJA_PV, DEFAULT_RODZAJ_WIATR_LAD_TURBINY } from './consts';

export const produkcjaEnergiiElektrycznej = {
  inputs: {
    /** Moc zainstalowana z wiatru na lądzie [GW]*/
    wiatr_lad_zainstalowano: 0,

    /**Moc zainstalowana z wiatru na morzu [GW] */
    wiatr_offshore_zainstalowano: 0,

    /**Moc zainstalowana fotowoltaiki [GW] */
    PV_zainstalowano: 0,

    /**Moc zainstalowana elektrowni atomowych [GW] */
    atom_zainstalowano: 0,

    udzial_bifacji_EW_w_PV: 0,

    udzial_nowoczesnych_turbin_wiatrowych: 0,
  },
  calculations: {
    /** Produkcja prądu z wiatru na lądzie [GW]*/
    wiatr_lad_produkcja_po_stratach_per_day: [] as number[],
    wiatr_lad_produkcja_per_day: [] as number[],

    /** Produkcja prądu z wiatru na morzu [GW]*/
    wiatr_offshore_produkcja_po_stratach_per_day: [] as number[],
    wiatr_offshore_produkcja_per_day: [] as number[],

    /** Produkcja prądu z fotowoltaiki [GW] */
    PV_produkcja_po_stratach_per_day: [] as number[],
    PV_produkcja_per_day: [] as number[],

    /** Produkcja prądu z elektrowni atomowych [GW] */
    atom_produkcja_po_stratach_per_day: [] as number[],
    atom_produkcja_per_day: [] as number[],

    /** Suma Produkcji prądu */
    produkcja_pradu_per_day_po_stratach: [] as number[],

    /** Prąd z wiatru, w roku */
    wiatr_lad_po_stratach_per_year: 0,

    wiatr_lad_per_year: 0,

    wiatr_na_morzu_per_year: 0,

    /** Prąd z wiatru na morzu, w roku */
    wiatr_na_morzu_po_stratach_per_year: 0,

    /** Prąd z fotowoltaiki, w roku */
    PV_per_year: 0,
    PV_po_stratach_per_year: 0,

    /** Prąd z energii atomowej, w roku */
    atom_po_stratach_per_year: 0,
    atom_per_year: 0,

    /** Suma wszystkich w roku */
    produkcja_pradu_w_calym_roku: 0,
  },
  consts: {
    atom_dyspozycyjnosc: 0.85,
  },
  data: {
    PV: data.PV,
    PV_wsch_zach: data.PV_wsch_zach,
    wiatr_lad: data.wiatr_lad,
    wiatr_lad_6MGW: data.wiatr_lad_6MGW,
    wiatr_offshore: data.wiatr_offshore,
  },
};
