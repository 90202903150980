export enum SelectOptionsValues {
  EnergyDemandVsProduction = '2',
  EnergyProductionVsDemand = '3',
  EnergyStorage = '4',
  HeatStorage = '5',
  MagazynCieploPrzemyslowe = '6',
}

export type SelectOption = {
  label: string;
  value: SelectOptionsValues;
};
